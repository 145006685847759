import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import _get from "lodash/get";
import _isEmpty from "lodash/isEmpty";
import _isEqual from "lodash/isEqual";
import _isString from "lodash/isString";

import ReferralSources from "../../components/referral_sources";
import ReferralSourcesProperties from "../../components/referral_sources_properties";
import { withLogin } from "../shared/login";
import {
  analyticsActions,
  propertyActions,
  propertyGroup,
  referralSourcesActions
} from "../../redux_base/actions";
import { dateFormat } from "../../utils/dates";
import { TabViews } from "../../components/referral_sources/constants";

class ReferralSourcesContainer extends React.PureComponent {
  static propTypes = {
    propertyGroup: PropTypes.arrayOf(
      PropTypes.shape({
        group_id: PropTypes.string.isRequired,
        group_name: PropTypes.string.isRequired
      })
    ),
    selectedPropertyGroup: PropTypes.object,
    properties: ReferralSourcesProperties.propertiesType,
    fetchingProperties: PropTypes.bool,
    leadSources: PropTypes.array,
    fetchingLeadSources: PropTypes.bool,
    fetchingLeadSourcesOptions: PropTypes.bool,
    nwvs: PropTypes.array,
    fetchingNWVs: PropTypes.bool,
    matchedNWVs: PropTypes.arrayOf(PropTypes.string),
    fetchingAttributions: PropTypes.bool,
    saveCompleted: PropTypes.bool,
    analyticsProvider: PropTypes.object,
    leadSourcesAttribution: PropTypes.object,
    matchedLeads: PropTypes.array,
    unmatchedLeads: PropTypes.array,
    distributedLeads: PropTypes.array,
    undistributedLeads: PropTypes.array,
    distSourceSelectionOptions: PropTypes.instanceOf(Set)
  };

  static initialState = {
    nwvs: [],
    property: null,
    leadSourceIdOrIds: null,
    unbouncedVisitorsOnly: false,
    nwvSortFilter: []
  };

  state = {
    ...ReferralSourcesContainer.initialState,
    saveCompleted: false,
    currentTab: TabViews.Match
  };

  componentDidMount() {
    const tab = this.props.match.params.tab;
    this.setState({ currentTab: tab });
    if (_isEmpty(this.props.propertyGroup)) {
      this.props.dispatch(propertyGroup.requestGroupsList());
    }
    if (this.props.selectedPropertyGroup?.group_id) {
      this.props.dispatch(
        propertyGroup.requestGroupProperties(
          this.props.selectedPropertyGroup.group_id
        )
      );
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const tab = this.props.match.params.tab;
    if (tab !== prevProps.match.params.tab) {
      this.setState({ currentTab: tab });
    }
    if (
      _isEmpty(this.props.selectedPropertyGroup) &&
      !_isEmpty(this.props.propertyGroup)
    ) {
      const selectedPropertyGroup = {
        group_id: _get(this.props.propertyGroup, [0, "group_id"]),
        group_name: _get(this.props.propertyGroup, [0, "group_name"])
      };
      this.props.dispatch(
        propertyGroup.update({
          selected_property_group: selectedPropertyGroup
        })
      );
    }
    if (
      this.props.selectedPropertyGroup?.group_id !==
      prevProps.selectedPropertyGroup?.group_id
    ) {
      this.props.dispatch(
        propertyGroup.requestGroupProperties(
          this.props.selectedPropertyGroup.group_id
        )
      );
    }
    if (
      !_isEqual(prevProps.analyticsProvider, this.props.analyticsProvider) ||
      (!prevProps.saveCompleted && this.props.saveCompleted)
    ) {
      if (this.state.property) {
        this.props.dispatch(
          referralSourcesActions.getReferralSourcesMatching(
            this.state.property.public_id
          )
        );
      }
      setTimeout(() => {
        this.props.dispatch(propertyActions.setAttributionsSave(false));
      }, 3000);
    }
    if (
      !_isEqual(
        prevProps.leadSourcesAttribution,
        this.props.leadSourcesAttribution
      )
    ) {
      this.selectLeadSource(this.state.property, this.state.leadSourceIdOrIds);
    }
    if (
      this.state.property &&
      !_isEqual(this.state.property, prevState.property)
    ) {
      this.props.dispatch(
        referralSourcesActions.getReferralSourcesMatching(
          this.state.property.public_id
        )
      );
    }
    if (!_isEqual(this.props.nwvs, prevProps.nwvs)) {
      this.fetchNWV(this.state.leadSourceIdOrIds);
    }
    if (
      this.state.property &&
      this.state.leadSourceIdOrIds &&
      !_isEqual(this.state.nwvSortFilter, prevState.nwvSortFilter)
    ) {
      this.props.dispatch(
        referralSourcesActions.getReferralSourcesNwvOptions(
          this.state.property.public_id,
          this.state.leadSourceIdOrIds,
          this.state.currentTab,
          { filter: this.state.nwvSortFilter }
        )
      );
    }
  }

  clearPropertyState = () => {
    this.props.dispatch(propertyActions.clearLeadSources());
    this.props.dispatch(propertyActions.clearNWVs());
    this.props.dispatch(referralSourcesActions.clearReferralSourcesMatching());
    this.props.dispatch(analyticsActions.clearPropertyAnalyticsProvider());
    this.setState({ ...ReferralSourcesContainer.initialState });
  };

  fetchProperties = property => {
    const selectedPropertyGroup = {
      group_id: property.value,
      group_name: property.label
    };
    this.props.dispatch(
      propertyGroup.update({
        selected_property_group: selectedPropertyGroup
      })
    );
    this.clearPropertyState();
  };

  selectProperty = property => {
    this.setState({
      ...ReferralSourcesContainer.initialState,
      property
    });
    // for getting data for all period
    const fakeStart = dateFormat(new Date(1970, 0, 1), "yyyy-MM-dd");
    const end = dateFormat(new Date(), "yyyy-MM-dd");
    this.props.dispatch(
      propertyActions.fetchLeadSources(property.public_id, fakeStart, end)
    );
    this.props.dispatch(
      propertyActions.fetchNWVs(property.public_id, fakeStart, end)
    );
    this.props.dispatch(
      analyticsActions.requestPropertyAnalyticsProvider(property.public_id)
    );
  };

  formatNwvs = sources => {
    const formattedSources = sources.map(e => JSON.parse(e));
    const matchedNWVs = new Set(this.props.matchedNWVs || []);
    const distributedNWVs = new Set(this.props.distributedNwvs || []);
    const nwvs = this.props.nwvs.map(nwv => {
      const isMatched = matchedNWVs.has(nwv.id);
      const isChecked = formattedSources.some(
        e =>
          e.source_name === nwv.value.source_name &&
          e.medium_name === nwv.value.medium_name
      );
      const isDistributed = distributedNWVs.has(nwv.id);
      return {
        value: nwv.value,
        label: nwv.label,
        id: nwv.id,
        checked: isChecked,
        distributed: isDistributed,
        matched: isMatched,
        matchedToDifferentLead: isMatched && !isChecked,
        distributedToDifferentLead: isDistributed && !isChecked
      };
    });
    return nwvs;
  };

  selectLeadSource = (property, leadSourceIdOrIds) => {
    if (leadSourceIdOrIds && _isString(leadSourceIdOrIds)) {
      this.props.dispatch(
        referralSourcesActions.getReferralSourcesNwvOptions(
          property.public_id,
          leadSourceIdOrIds,
          this.state.currentTab,
          { filter: this.state.nwvSortFilter }
        )
      );
      this.fetchNWV(leadSourceIdOrIds);
    }
  };

  fetchNWV = leadSourceId => {
    const attribution = this.props.leadSourcesAttribution[leadSourceId]
      ?.attribution;
    let referralSources = [];
    if (attribution?.referral_sources?.length) {
      referralSources = attribution?.referral_sources || [];
    } else if (attribution?.distributed_sources?.length) {
      referralSources = attribution?.distributed_sources || [];
    }
    const nwvs = this.formatNwvs(referralSources);
    this.setState({
      nwvs,
      leadSourceIdOrIds: leadSourceId,
      unbouncedVisitorsOnly: attribution?.unbounced_visitors_only || false
    });
  };

  onSelectFilter = filters => {
    this.setState({ nwvSortFilter: filters });
  };

  onSaveReferralSources = (
    property,
    leadSourceId,
    nwvs,
    unbouncedVisitorsOnly
  ) => {
    const attribution = this.getAttribution(leadSourceId);
    let params = this.getSavePayload(
      attribution,
      leadSourceId,
      unbouncedVisitorsOnly
    );
    const distributedSources = attribution?.distributed_sources || [];
    params["distributed_sources"] = distributedSources;
    params["referral_sources"] = this.formatSources(nwvs);
    this.props.dispatch(
      propertyActions.saveAttributions(property.public_id, [params])
    );
  };

  onSaveDistribution = (
    property,
    leadSourceId,
    nwvs,
    unbouncedVisitorsOnly
  ) => {
    const attribution = this.getAttribution(leadSourceId);
    let params = this.getSavePayload(
      attribution,
      leadSourceId,
      unbouncedVisitorsOnly
    );
    let referralSources = attribution?.referral_sources || [];
    params["referral_sources"] = referralSources;
    params["distributed_sources"] = this.formatSources(nwvs);
    params["analytics_provider"] = this.props.analyticsProvider;
    this.props.dispatch(
      propertyActions.saveAttributions(property.public_id, [params])
    );
  };

  formatSources = nwvs => nwvs.map(nwv => JSON.stringify(nwv.value));

  getSavePayload = (attribution, leadSourceId, unbouncedVisitorsOnly) => {
    const params = {
      lead_name: leadSourceId,
      attribution_id: attribution.attribution_id,
      unbounced_visitors_only: unbouncedVisitorsOnly
    };
    return params;
  };

  getAttribution = leadSourceId =>
    this.props.leadSourcesAttribution[leadSourceId]?.attribution.project_id ===
    this.state.property.public_id
      ? this.props.leadSourcesAttribution[leadSourceId]?.attribution
      : {};

  onToggleTab = tab => {
    this.props.history.push(`/referral-sources/${tab}`);
    this.setState({ currentTab: tab });
  };

  render() {
    const portfolios = this.props.propertyGroup.map(g => ({
      label: g.group_name,
      value: g.group_id
    }));
    const selectedPortfolio = {
      label: this.props.selectedPropertyGroup.group_name,
      value: this.props.selectedPropertyGroup.group_id
    };
    const properties = this.props.properties.filter(p => p.is_admin);
    return (
      <ReferralSources
        portfolios={portfolios}
        properties={properties}
        fetchingProperties={this.props.fetchingProperties}
        selectedPortfolio={selectedPortfolio}
        unmatchedSources={this.props.unmatchedLeads}
        matchedSources={this.props.matchedLeads}
        undistributedSources={this.props.undistributedLeads}
        distributedSources={this.props.distributedLeads}
        leadSources={this.props.leadSources}
        leadSourcesOptions={Object.keys(this.props.leadSourcesAttribution)}
        fetchingLeadSources={this.props.fetchingLeadSources}
        fetchingLeadSourcesOptions={this.props.fetchingLeadSourcesOptions}
        nwv={this.state.nwvs}
        fetchingNWVs={this.props.fetchingNWVs}
        unbouncedVisitorsOnly={this.state.unbouncedVisitorsOnly}
        saveCompleted={this.props.saveCompleted}
        fetchProperties={this.fetchProperties}
        selectProperty={this.selectProperty}
        selectLeadSource={this.selectLeadSource}
        fetchDistributionNWV={this.fetchNWV}
        clearPropertyState={this.clearPropertyState}
        onSelectFilter={this.onSelectFilter}
        onSaveReferralSources={this.onSaveReferralSources}
        onSaveDistribution={this.onSaveDistribution}
        leadSourcesAttribution={this.props.leadSourcesAttribution}
        distributedNwvs={this.props.distributedNwvs}
        distSourceSelectionOptions={this.props.distSourceSelectionOptions}
        currentTab={this.state.currentTab}
        onToggleTab={this.onToggleTab}
      />
    );
  }
}

const mapState = state => ({
  propertyGroup: state.propertyGroup.property_groups,
  selectedPropertyGroup: state.propertyGroup.selected_property_group,
  properties: state.propertyGroup.properties,
  propertiesAvailableLeads: state.propertyGroup.propertiesAvailableLeads,
  fetchingProperties: state.propertyGroup.fetchingProperties,
  leadSources: state.property.leads,
  fetchingLeadSourcesOptions: state.referral_sources.fetchingLeadSourcesOptions,
  fetchingLeadSources: state.property.fetchingLeads,
  nwvs: state.referral_sources.nwvs,
  fetchingNWVs: state.referral_sources.fetchingNWVs,
  matchedNWVs: state.referral_sources.matchedNWVs,
  fetchingAttributions: state.properties.fetchingAttributions,
  saveCompleted: state.properties.attributionsSaveCompleted,
  analyticsProvider: state.analytics.analyticsProvider,
  leadSourcesAttribution: state.referral_sources.leadSourcesAttribution,
  matchedLeads: state.referral_sources.matchedLeads,
  unmatchedLeads: state.referral_sources.unmatchedLeads,
  undistributedLeads: state.referral_sources.undistributedLeads,
  distributedLeads: state.referral_sources.distributedLeads,
  distributedNwvs: state.referral_sources.distributedNwvs,
  distSourceSelectionOptions: state.referral_sources.distSourceSelectionOptions
});

export default withRouter(
  withLogin(connect(mapState)(ReferralSourcesContainer))
);
