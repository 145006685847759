import { createAjaxAction, URLS } from "./helpers";

const actions = {
  getNarratives: data =>
    createAjaxAction(
      "AJAX_POST_NARRATIVES",
      `${URLS.narratives}/`
    )({ body: data })
};

export default actions;
