import React from "react";
import PropTypes from "prop-types";

import { Table } from "concrete-ui";

import { formatDateWithTokens } from "../../utils/formatters";

class AdminPropertiesTable extends React.PureComponent {
  static propTypes = {
    loading: PropTypes.bool,
    tableColumns: PropTypes.arrayOf(PropTypes.object),
    hiddenColumns: PropTypes.array,
    propertiesDetailsData: PropTypes.arrayOf(PropTypes.object)
  };

  getCsvFilename = () => {
    let today = new Date();
    today = formatDateWithTokens(today, "yyyy-MM-dd");
    return `Remarkably_Properties_${today}`;
  };

  render() {
    return (
      <Table
        mt={5}
        loading={this.props.loading}
        columns={this.props.tableColumns}
        data={this.props.propertiesDetailsData}
        initialSortBy={[{ id: "last_updated", desc: true }]}
        pageSizes={[
          { label: "10", value: 10 },
          { label: "20", value: 20 },
          { label: "All", value: "all" }
        ]}
        hiddenColumns={this.props.hiddenColumns}
        csvFileName={this.getCsvFilename()}
      />
    );
  }
}

export default AdminPropertiesTable;
