import { createAjaxAction, URLS } from "./helpers";

export default {
  updateState: state => ({
    type: "CONNECTIONS_UPDATE_STATE",
    state
  }),
  requestPMSInstances: createAjaxAction(
    "AJAX_GET_PMS_INSTANCES",
    `${URLS.connections}/pms/`
  ),
  postPMS: data =>
    createAjaxAction(
      "AJAX_POST_PMS",
      `${URLS.connections}/pms/`
    )({ body: data }),
  patchPMS: (data, id) =>
    createAjaxAction(
      "AJAX_PATCH_PMS",
      `${URLS.connections}/pms/${id}`
    )({ body: data }),
  requestDataConnections: createAjaxAction(
    "AJAX_GET_DATA_CONNECTIONS",
    `${URLS.connections}/data-connections/`
  ),
  refreshDataConnections: createAjaxAction(
    "AJAX_GET_REFRESH_DATA_CONNECTIONS",
    `${URLS.connections}/data-connections/`
  ),
  deletePMS: pmsId =>
    createAjaxAction("AJAX_DELETE_PMS", `${URLS.connections}/pms/${pmsId}`)(),
  postDataConnection: (data, history) => ({
    type: "DATA_CONNECTION",
    data: data,
    history: history
  }),
  deleteConnection: (connection_id, integration) =>
    createAjaxAction(
      "AJAX_DELETE_CONNECTION",
      `${URLS.connections}/delete-connection/${connection_id}/${integration}`
    )(),
  testPMSConnection: projectId =>
    createAjaxAction(
      "AJAX_POST_TEST_CONNECTION",
      `${URLS.connections}/yardi-test/`
    )({ body: { project_id: projectId } }),
  backfillYardiPMS: (projectId, startDate, endDate) =>
    createAjaxAction(
      "AJAX_POST_BACKFILL_YARDI_PMS",
      `${URLS.connections}/yardi/backfill-date-range/`
    )({
      body: { project_id: projectId, start_date: startDate, end_date: endDate }
    })
};
