import _cloneDeep from "lodash/cloneDeep";
import _isEmpty from "lodash/isEmpty";
import _get from "lodash/get";

import { ETL_ALTERNATE_KPI_NAME } from "../../constants";
import { getNameFromAlert } from "../../utils/alerts";
import { subDays, subWeeks, subMonths } from "../../utils/dates";
import React from "react";

const initialState = {
  newEventsCount: 0,
  events: [],
  notifications: {},
  propertyNotifications: [],
  propertyGroupNotifications: [],
  alerts: {},
  selectedEvents: new Set(),
  isCreatingSuccessful: false
};

const getPeriodByDate = (date, alertSpan) => {
  const end = new Date(date);
  let start;
  if (alertSpan.includes("month")) {
    start = subMonths(date, 1);
  } else if (alertSpan.includes("week")) {
    start = subWeeks(date, 1);
  } else {
    start = subDays(date, 1);
  }
  return { start, end };
};

const getNewEventsCount = state => {
  const portfolioNotificationCount = state.propertyGroupNotifications.filter(
    entry => entry.isNew === true
  ).length;
  const propertyNotificationCount = state.propertyNotifications.filter(
    entry => entry.isNew === true
  ).length;
  return portfolioNotificationCount + propertyNotificationCount;
};

const structureData = state => {
  // assemble alerts and alert notification data for property groups and properties
  let propertyNotifications = [];
  let propertyGroupNotifications = [];
  if (Object.entries(state.alerts).length > 0) {
    for (const [alertId, alertData] of Object.entries(state.alerts)) {
      let notificationData = {};
      let nData = state.notifications[alertId];

      if (nData) {
        // we have a notification for this alert, fill it out.
        const dates = getPeriodByDate(nData.stamp, alertData.time_span);
        notificationData = {
          value: nData.kpi_value,
          eventId: nData.activity_id,
          date: new Date(nData.stamp),
          createdDate: new Date(alertData.created_stamp),
          startDate: dates.start,
          endDate: dates.end,
          isNew: true
        };
      } else {
        // default values
        notificationData = {
          value: null,
          eventId: null,
          date: null,
          createdDate: null,
          startDate: null,
          endDate: null,
          isNew: false
        };
      }
      let comparator;
      if (alertData.comparator == "!=") {
        comparator = "\u2260";
      } else {
        comparator = alertData.comparator;
      }
      let emailEnabled;
      let notificationEnabled;
      const currentSubscriber = alertData.subscribers[0][1];
      if (currentSubscriber == "both") {
        emailEnabled = true;
        notificationEnabled = true;
      } else if (currentSubscriber == "email") {
        emailEnabled = true;
        notificationEnabled = false;
      } else if (currentSubscriber == "web") {
        emailEnabled = false;
        notificationEnabled = true;
      } else if (currentSubscriber == "disabled") {
        emailEnabled = false;
        notificationEnabled = false;
      }
      let kpiId;
      if (alertData.kpiId in ETL_ALTERNATE_KPI_NAME) {
        kpiId = ETL_ALTERNATE_KPI_NAME[alertData.kpiId];
      } else {
        kpiId = alertData.kpiId;
      }
      let creator;
      let isName;
      if (alertData.creator.first_name && alertData.creator.last_name) {
        creator = `${alertData.creator.first_name} ${alertData.creator.last_name}`;
        isName = true;
      } else if (alertData.creator.email) {
        creator = `${alertData.creator.email}`;
        isName = false;
      } else {
        creator = "";
        isName = false;
      }
      let alertNotificationData = {
        ...notificationData,
        alertId: alertId,
        name: alertData.subject_name,
        entityId: alertData.alert_subject,
        kpi: alertData.kpi,
        creator: {
          avatar: alertData.creator.avatar,
          name: creator,
          isName: isName
        },
        subscriptions: {
          email: emailEnabled,
          enabled: true,
          notification: notificationEnabled
        },
        period: <></>,
        kpiId: kpiId,
        rule: `${comparator} ${alertData.trigger_value}`,
        alertProjectId: alertData.alert_project_id ?? ""
      };
      if (alertData.alert_scope === "property") {
        propertyNotifications.push(alertNotificationData);
      } else if (alertData.alert_scope === "group") {
        propertyGroupNotifications.push(alertNotificationData);
      } else {
        console.log("alert scope is odd");
      }
    }
  }
  return [propertyNotifications, propertyGroupNotifications];
};

const reducer = (state = initialState, action) => {
  let newState = {};
  switch (action.type) {
    case "ALERT_UPDATE_STATE": {
      newState = {
        ...state,
        ...action.state
      };
      break;
    }
    case "AJAX_POST_CREATE_ALERT_SUCCESS": {
      newState = {
        ...state,
        isCreatingSuccessful: true
      };
      break;
    }
    case "GET_SISENSE_EVENTS_FAILURE": {
      newState = {
        ...state,
        events: [],
        newEventsCount: 0
      };
      break;
    }
    case "GET_SISENSE_EVENTS_SUCCESS": {
      const events = action.payload || [];
      newState = {
        ...state,
        events
      };
      break;
    }
    case "GET_SISENSE_ALERTS_SUCCESS": {
      const alerts = {};
      (action.payload || []).map(a => {
        alerts[a._id] = a;
      });
      newState = {
        ...state,
        alerts
      };
      break;
    }
    case "ALERT_UPDATE_SELECTED_EVENTS": {
      const selectedEvents = action.selectedEvents;
      newState = {
        ...state,
        selectedEvents
      };
      break;
    }
    case "PATCH_SISENSE_ALERT_RECEIVE_NOTIFICATION_SUCCESS":
    case "AJAX_PATCH_UPDATE_ALERT_SUCCESS": {
      const alerts = _cloneDeep(state.alerts);
      alerts[action.payload._id] = action.payload;
      newState = {
        ...state,
        alerts
      };
      break;
    }
    case "DELETE_ALERT_SUCCESS": {
      const { alertId } = action;
      delete state.alerts[alertId];
      const notificationData = structureData(state);
      const newEventsCount = getNewEventsCount(state);
      newState = {
        ...state,
        propertyNotifications: notificationData[0],
        propertyGroupNotifications: notificationData[1],
        newEventsCount: newEventsCount
      };

      break;
    }
    case "DELETE_ALERT_FAILURE": {
      console.log("DELETE_ALERT_FAILURE");
      break;
    }
    case "ALERTS_NOTIFICATION_SEEN_SUCCESS": {
      console.log("ALERTS NOTIFICATION SEEN SUCCESS");
      const { eventIds } = action;
      const updateState = _cloneDeep(state);
      updateState.propertyNotifications.forEach(notification => {
        if (notification.eventId && eventIds.includes(notification.eventId)) {
          notification.isNew = false;
          delete updateState.notifications[notification.alertId];
        }
      });
      updateState.propertyGroupNotifications.forEach(notification => {
        if (notification.eventId && eventIds.includes(notification.eventId)) {
          notification.isNew = false;
          delete updateState.notifications[notification.alertId];
        }
      });
      updateState.newEventsCount = getNewEventsCount(updateState);
      updateState.selectedEvents = initialState.selectedEvents;

      newState = {
        ...updateState
      };
      break;
    }
    case "PATCH_SISENSE_EVENTS_MARK_AS_SEE_SUCCESS": {
      const event = action.payload;
      event.seen = true;
      const events = _cloneDeep(state.events);
      const foundIndex = events.findIndex(e => e._id === event._id);
      events[foundIndex] = event;
      const selectedEvents = initialState.selectedEvents;
      newState = { ...state, events, selectedEvents };
      break;
    }
    case "ALERTS_NOTIFICATION_SEEN_FAILURE": {
      console.log("ALERTS_NOTIFICATION_SEEN_FAILURE");
      break;
    }
    case "AJAX_GET_ALERTS_SUCCESS": {
      const alerts = {};
      (action.payload || []).map(a => {
        alerts[a.id] = a;
      });
      const notificationData = structureData({
        ...state,
        alerts
      });
      newState = {
        ...state,
        alerts: alerts,
        propertyNotifications: notificationData[0],
        propertyGroupNotifications: notificationData[1]
      };
      break;
    }
    case "AJAX_GET_ALERTS_FAILURE": {
      console.log("AJAX_GET_ALERTS_FAILURE");
      break;
    }
    case "AJAX_GET_ALERTS_NOTIFICATIONS_SUCCESS": {
      const notifications = {};
      (action.payload || []).map(n => {
        notifications[n.alert_id] = n;
      });
      const notificationData = structureData({
        ...state,
        notifications
      });
      const newEventsCount = getNewEventsCount(state);
      newState = {
        ...state,
        newEventsCount: newEventsCount,
        notifications: notifications,
        propertyNotifications: notificationData[0],
        propertyGroupNotifications: notificationData[1]
      };
      break;
    }
    case "AJAX_GET_ALERTS_NOTIFICATIONS_FAILURE": {
      console.log("AJAX_GET_ALERTS_NOTIFICATIONS_FAILURE");
      break;
    }
    case "ALERT_UPDATE_SUBSCRIPTION_PREFERENCE_SUCCESS": {
      console.log("ALERT_UPDATE_SUBSCRIPTION_PREFERENCE_SUCCESS");
      const { alertId, scope, emailOn, notificationOn } = action;
      const propertyNotifications = _cloneDeep(state.propertyNotifications);
      const propertyGroupNotifications = _cloneDeep(
        state.propertyGroupNotifications
      );
      if (scope === "group") {
        propertyGroupNotifications.find(function(value, index) {
          if (value.alertId === alertId) {
            propertyGroupNotifications[index].subscriptions = {
              email: emailOn,
              enabled: propertyGroupNotifications[index].subscriptions.enabled,
              notification: notificationOn
            };
          }
        });
      } else if (scope === "property") {
        propertyNotifications.find(function(value, index) {
          if (value.alertId === alertId) {
            propertyNotifications[index].subscriptions = {
              email: emailOn,
              enabled: propertyNotifications[index].subscriptions.enabled,
              notification: notificationOn
            };
          }
        });
      }
      newState = {
        ...state,
        propertyNotifications,
        propertyGroupNotifications
      };
      break;
    }
    case "ALERT_UPDATE_SUBSCRIPTION_PREFERENCE_FAILURE": {
      console.log("ALERT_UPDATE_SUBSCRIPTION_PREFERENCE_FAILURE");
      break;
    }

    // FOR FILTER ALERTS
    // TODO: REMOVE AFTER REMOVING SISENSE
    case "AJAX_GET_ALERT_PROPERTIES_SUCCESS": {
      const { property_groups, properties } = action.payload;

      newState = {
        ...state,
        propertyGroups: property_groups,
        properties: properties
      };
      break;
    }
    case "AJAX_GET_ALERT_PROPERTIES_ERROR": {
      const x = action.payload;
      break;
    }
    default:
      newState = state;
  }

  // FOR FILTER ALERTS
  // TODO: REMOVE AFTER REMOVING SISENSE
  switch (action.type) {
    case "AJAX_GET_ALERT_PROPERTIES_SUCCESS":
    case "GET_SISENSE_ALERTS_SUCCESS":
    case "GET_SISENSE_EVENTS_SUCCESS":
      if (
        !_isEmpty(newState.alerts) &&
        !_isEmpty(newState.events) &&
        !_isEmpty(newState.propertyGroups) &&
        !_isEmpty(newState.properties)
      ) {
        const eventsObj = {};
        newState.events.forEach(e => (eventsObj[e.alert] = e));
        const propertiesNames = newState.properties.map(p => p.property_name);
        const groupsNames = newState.propertyGroups.map(p => p.group_name);
        const nameList = [...propertiesNames, ...groupsNames];
        const newEvents = Object.values(eventsObj).filter(event => {
          const alertName = _get(newState.alerts, [event.alert, "name"], "");
          const propertyName = getNameFromAlert(alertName, nameList);
          return !event.seen && propertyName;
        });
        newState.newEventsCount = newEvents.length;
      } else {
        newState.newEventsCount = getNewEventsCount(state);
      }
      break;
  }

  return newState;
};

export default reducer;
