import React from "react";

import IconBase from "./icon_base";

const Alert = props => (
  <IconBase viewBox="0 0 16 16" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 14.6667C8.73334 14.6667 9.33334 14.0667 9.33334 13.3334H6.66667C6.66667 14.0667 7.26 14.6667 8 14.6667ZM12 10.6667V7.33335C12 5.28669 10.9067 3.57335 9 3.12002V2.66669C9 2.11335 8.55334 1.66669 8 1.66669C7.44667 1.66669 7 2.11335 7 2.66669V3.12002C5.08667 3.57335 4 5.28002 4 7.33335V10.6667L3.14 11.5267C2.72 11.9467 3.01334 12.6667 3.60667 12.6667H12.3867C12.98 12.6667 13.28 11.9467 12.86 11.5267L12 10.6667Z"
      fill="currentColor"
    />
  </IconBase>
);

export default Alert;
