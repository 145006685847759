import React from "react";
import PropTypes from "prop-types";

import Breadcrumbs from "../breadcrumbs";
import { AlertsCrumb, CreateAlertCrumb } from "../alerts_crumb";
import RenderIf from "../render_if";
import TabCSS from "../tab_css";
import AlertsTable from "../alerts_table";

import "./alerts_page.scss";

export default class AlertsPage extends React.PureComponent {
  static propTypes = {
    propertyGroupNotifications: AlertsTable.eventsType,
    propertyNotifications: AlertsTable.eventsType,
    selected: PropTypes.object,
    onSelect: PropTypes.func,
    onDelete: PropTypes.func,
    onMarkAsRead: PropTypes.func,
    toggleSubscription: PropTypes.func
  };

  static defaultProps = {
    propertyGroupNotifications: [],
    propertyNotifications: [],
    selected: new Set(),
    onSelect: () => {},
    onDelete: () => {},
    onMarkAsRead: () => {},
    toggleSubscription: () => {}
  };

  state = {
    newEvents: {
      property: 0,
      portfolio: 0
    }
  };

  breadcrumbs = [
    {
      text: <AlertsCrumb />
    },
    {
      text: <CreateAlertCrumb />,
      link: "/alerts/add"
    }
  ];

  static getDerivedStateFromProps(props, state) {
    const portfolio = props.propertyGroupNotifications.filter(
      entry => entry.isNew === true
    ).length;
    const property = props.propertyNotifications.filter(
      entry => entry.isNew === true
    ).length;
    return {
      newEvents: {
        property,
        portfolio
      }
    };
  }

  get tabsTheme() {
    let theme = null;
    if (this.state.newEvents.portfolio) {
      theme = "icon";
    }
    return theme;
  }

  getPortfolioLabel = () => {
    const newEventCount = this.getNewEventCount("portfolio");
    return (
      <div className="alerts-page__tab">
        <div id="rmb-alerts-page__sub-portfolio-label">Portfolio</div>
        <RenderIf condition={!!newEventCount}>
          <div className="alerts-page__tab-icon">{newEventCount}</div>
        </RenderIf>
      </div>
    );
  };

  getPropertyLabel = () => {
    const newEventCount = this.getNewEventCount("property");
    return (
      <div className="alerts-page__tab">
        <div id="rmb-alerts-page__sub-property-label">Property</div>
        <RenderIf condition={!!newEventCount}>
          <div className="alerts-page__tab-icon">{newEventCount}</div>
        </RenderIf>
      </div>
    );
  };

  getNewEventCount(type) {
    const newEventCount = this.state.newEvents[type];
    if (!newEventCount) {
      return null;
    }
    if (newEventCount > 99) {
      return "+";
    }
    return newEventCount;
  }

  switchTab = () => {
    this.props.onSelect(new Set());
  };

  render() {
    return (
      <div className="alerts-page">
        <Breadcrumbs
          className="alerts-page__breadcrumbs"
          breadcrumbs={this.breadcrumbs}
        />
        <TabCSS
          className="alerts-page__tables"
          theme={this.tabsTheme}
          lazyRender={false}
          withoutContainer={true}
          onChange={this.switchTab}
        >
          <TabCSS.Tab label={this.getPortfolioLabel()}>
            <AlertsTable
              id="rmb-alerts-page__sub-portfolio-table"
              title="Portfolio"
              type="property-group"
              data={this.props.propertyGroupNotifications}
              selected={this.props.selected}
              onSelect={this.props.onSelect}
              onDelete={this.props.onDelete}
              onMarkAsRead={this.props.onMarkAsRead}
              toggleSubsciption={this.props.toggleSubscription}
            />
          </TabCSS.Tab>
          <TabCSS.Tab label={this.getPropertyLabel()}>
            <AlertsTable
              id="rmb-alerts-page__sub-property-table"
              title="Property"
              type="property"
              data={this.props.propertyNotifications}
              selected={this.props.selected}
              onSelect={this.props.onSelect}
              onDelete={this.props.onDelete}
              onMarkAsRead={this.props.onMarkAsRead}
              toggleSubsciption={this.props.toggleSubscription}
            />
          </TabCSS.Tab>
        </TabCSS>
      </div>
    );
  }
}
