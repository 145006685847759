import React, { Component } from "react";
import PropTypes from "prop-types";
import cn from "classnames";

import { GraphLine, Grid } from "../../icons";

import "./toggle_button.scss";

export default class ToggleButton extends Component {
  static propTypes = {
    value: PropTypes.any,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.any.isRequired,
        icon: PropTypes.func.isRequired,
        text: PropTypes.string.isRequired
      })
    ),
    onChange: PropTypes.func
  };

  static defaultProps = {
    value: "grid",
    options: [
      {
        id: "grid",
        icon: Grid,
        text: "Grid"
      },
      {
        id: "charts",
        icon: GraphLine,
        text: "Charts"
      }
    ],
    onChange: () => {}
  };

  handleChange = value => () => {
    this.props.onChange(value);
  };

  render() {
    const { options, value } = this.props;
    return (
      <div className="toggle-button">
        {options.map(option => {
          const Icon = option.icon;
          return (
            <div
              key={option.id}
              className={cn("toggle-button__item", {
                "toggle-button__item--selected": option.id === value
              })}
              onClick={this.handleChange(option.id)}
            >
              <Icon className="toggle-button__icon" />
              <div className="toggle-button__text">{option.text}</div>
            </div>
          );
        })}
      </div>
    );
  }
}
