const initialState = { companies: [], suggestion: {}, isLoading: false };

export default (state = initialState, action) => {
  switch (action.type) {
    case "AJAX_POST_COMPANY_SEARCH_REQUEST": {
      return { ...state, isLoading: true };
    }
    case "AJAX_POST_COMPANY_SEARCH_SUCCESS": {
      const companies = action.payload.company;
      return { ...state, isLoading: false, companies };
    }
    case "AJAX_POST_COMPANY_SEARCH_ERROR": {
      return { ...state, isLoading: false };
    }
    case "COMPANY_MODAL_CLEAR": {
      return { ...initialState };
    }
    case "AJAX_GET_RELATED_COMPANY_SUCCESS": {
      const suggestion = action.payload.suggestion;
      return { ...state, suggestion };
    }
    case "AJAX_GET_RELATED_COMPANY_ERROR": {
      return { ...state, suggestion: {} };
    }
    default:
      return state;
  }
};
