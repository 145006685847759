import React from "react";
import { Button } from "concrete-ui";
import { connect } from "react-redux";

import AccountForm from "../account_form";
import PageAuth from "../page_auth";
import { resetPasswordActions } from "../../redux_base/actions";

import "./reset_password_done.scss";

export class ResetPasswordDone extends React.Component {
  resendResetPassword = () => {
    let email = this.props.data.payload.email;
    this.props.dispatch(resetPasswordActions.resetPassword({ email: email }));
  };
  render() {
    return (
      <PageAuth backLink="/">
        <AccountForm
          className="reset-password"
          title="Check your inbox"
          subtitle="We've sent you a special link to set your password."
        >
          <Button
            variant="primary"
            size="large"
            fullWidth={true}
            onClick={this.resendResetPassword}
          >
            Re-send Link
          </Button>
        </AccountForm>
      </PageAuth>
    );
  }
}

const mapState = state => ({
  data: state.resetPassword
});

export default connect(mapState)(ResetPasswordDone);
