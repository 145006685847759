import React from "react";
import { Flex, Divider, Text } from "concrete-ui";
import { showDate, getLabel } from "../utils";
import { ActualExpenseReview } from "../review-actual-expense";

const OneTimeExpenseReview = ({ formItems, propertyOpts, leadSourcesOpts }) => {
  const {
    property_id,
    vendor,
    lead_sources,
    expense_name,
    actual_expenses,
    start_date,
    end_date,
    cost_budgeted
  } = formItems;

  const expensesToDisplay = actual_expenses.filter(entry => !entry.to_delete);

  return (
    <Flex flexDirection="column" width="100%">
      <Flex flexDirection="column">
        <Flex flexDirection="row">
          <Flex flexDirection="column" flex="2" mt={5}>
            <Text textSize={2} $color="text.gray.1">
              Property
            </Text>
            <Text textSize={1} $fontWeight="medium">
              {getLabel(propertyOpts, property_id)}
            </Text>
          </Flex>
          <Flex flexDirection="column" flex="2" mt={5}>
            <Text textSize={2} $color="text.gray.1">
              Expense Name
            </Text>
            <Text textSize={1} $fontWeight="medium">
              {expense_name}
            </Text>
          </Flex>
        </Flex>
        <Flex flexDirection="row">
          <Flex flexDirection="column" flex="2" mt={5}>
            <Text textSize={2} $color="text.gray.1">
              Vendor name
            </Text>
            <Text textSize={1} $fontWeight="medium">
              {vendor?.label}
            </Text>
          </Flex>
          <Flex flexDirection="column" flex="2" mt={5}>
            <Text textSize={2} $color="text.gray.1">
              Associated Lead Sources
            </Text>
            <Text textSize={1} $fontWeight="medium">
              {}
              {lead_sources
                .map(lead => getLabel(leadSourcesOpts, lead))
                .filter(Boolean)
                .join(", ")}
            </Text>
          </Flex>
        </Flex>
      </Flex>

      <Divider variant="horizontal" bg="gray.4" mt={5} mb={5} />

      <Flex flexDirection="column">
        <Flex flexDirection="row">
          <Flex flexDirection="column" flex="2">
            <Text textSize={2} $color="text.gray.1">
              Budgeted Expense
            </Text>
            <Text textSize={1} $fontWeight="medium">
              {cost_budgeted && `$${cost_budgeted}`}
            </Text>
          </Flex>
          <Flex flexDirection="column" flex="2">
            <Text textSize={2} $color="text.gray.1">
              Expense Start and End Dates
            </Text>
            <Text textSize={1} $fontWeight="medium">
              {showDate(start_date)} to {showDate(end_date)}
            </Text>
          </Flex>
        </Flex>
      </Flex>
      <ActualExpenseReview actual_expenses={actual_expenses} />
    </Flex>
  );
};

export default OneTimeExpenseReview;
