import { Icon, Text } from "concrete-ui";
import React from "react";

const AdminBreadcrumb = () => (
  <>
    <Icon.Settings size={4} $color="inherit" mr={1} />
    <Text textSize={3} $fontWeight="regular" $color="inherit">
      Admin
    </Text>
  </>
);

export default AdminBreadcrumb;
