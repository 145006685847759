import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { Box, Button, Flex, Icon, Text } from "concrete-ui";
import _intersection from "lodash/intersection";

import useValidation from "../../utils/validator_hook";
import Breadcrumbs from "../breadcrumbs";
import {
  CreateForm,
  CreateFormHeader,
  CreateFormSection
} from "../create_form";
import Loader from "../loader";
import { ErrorItem, SuccessItem } from "../message_items";
import MultiSelect from "../multi_select";
import { createBudgetConfig } from "./validators";

export const CreateBudgetBreadcrumb = () => {
  return (
    <>
      <Icon.Grid size={4} $color="inherit" />
      <Text ml={1} textSize={3} $fontWeight="regular" $color="inherit">
        Create Budget
      </Text>
    </>
  );
};

const CommonMessage = ({ success, isFormErrors }) => {
  let item = null;
  if (isFormErrors) {
    item = (
      <ErrorItem message="Please correct the errors above before continuing." />
    );
  } else if (success === true) {
    item = <SuccessItem message="Your budget report was successfully sent" />;
  } else if (success === false) {
    item = (
      <ErrorItem message="Oops, something went wrong. Please contact Support." />
    );
  }
  return item;
};

const CreateBudgetForm = ({
  userEmail,
  properties = [],
  confirm = v => v,
  initialData = {},
  isLoading = false,
  success = null
}) => {
  const defaultData = {
    property_ids: [],
    year: 2023
  };
  const { errors, getFormProps, getItemProps } = useValidation(
    createBudgetConfig,
    {
      ...defaultData,
      ...initialData
    },
    confirm
  );

  const breadcrumbs = [
    {
      text: <CreateBudgetBreadcrumb />
    }
  ];

  const sortedProperties = useMemo(
    () =>
      properties.sort((a, b) =>
        a.label > b.label ? 1 : b.label > a.label ? -1 : 0
      ),
    [properties]
  );

  const propertyProps = getItemProps("property_ids");
  const fieldsContainerProps = { width: "31rem" };

  return (
    <>
      <Loader isVisible={isLoading} />
      <Box bg="gray.1" width="100%" display="block">
        <Breadcrumbs breadcrumbs={breadcrumbs} />
      </Box>
      <Box width="62.5rem" mx="auto" mb="12rem">
        <CreateForm formProps={getFormProps()}>
          <CreateFormHeader>
            <Text textSize={4} $color="text.white.1" $fontWeight="medium">
              Create Budget
            </Text>
          </CreateFormHeader>
          <CreateFormSection
            number="1"
            titleText="Choose Property"
            titleSummary="Choose one or more Properties from your Portfolio to create a budget for."
            fieldsContainerProps={fieldsContainerProps}
          >
            <MultiSelect
              width="100%"
              placeholder="Select..."
              value={propertyProps.value}
              options={sortedProperties}
              onChange={propertyProps.onChange}
              onBlur={propertyProps.onBlur}
              errorMessage={errors.property_ids}
            />
          </CreateFormSection>
          <CreateFormSection
            number="2"
            titleText="Send Budget"
            titleSummary="Click the 'Send Budget' button to send the marketing budget to your email address."
            fieldsContainerProps={{
              ...fieldsContainerProps,
              flexDirection: "column"
            }}
          >
            <Box>
              <Text textSize={2} $color="text.gray.1">
                A report will be sent to:
              </Text>
              <Text ml={2} textSize={2} $color="text.white.1">
                {userEmail}
              </Text>
            </Box>
            <Flex mt={4} alignItems="center" justifyContent="flex-end">
              <CommonMessage
                success={success}
                isFormErrors={Object.keys(errors).length > 0}
              />
              <Button
                size="large"
                variant="primary"
                type="submit"
                style={{ flex: "0 0 auto" }}
              >
                Send Budget
              </Button>
            </Flex>
          </CreateFormSection>
        </CreateForm>
      </Box>
    </>
  );
};

CreateBudgetForm.propTypes = {
  userEmail: PropTypes.string.isRequired,
  initialData: PropTypes.object,
  properties: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any.isRequired,
      label: PropTypes.any
    })
  ).isRequired,
  confirm: PropTypes.func,
  isLoading: PropTypes.bool,
  success: PropTypes.bool
};

export default CreateBudgetForm;
