import React from "react";
import PropTypes from "prop-types";
import { Button, Dropdown, Icon, Item, Menu, Tooltip } from "concrete-ui";
import { connect } from "react-redux";
import AlertButton from "./alert_button";
import AdminButton from "./admin_button";
import HelpButton from "./help_button";

import EnhancedLink from "./../enhanced_link";
import checkFlag from "../../utils/posthog";

import { user as userActions, alertsActions } from "../../redux_base/actions";
import "./top_navigation.scss";

const TOOLTIP_MSG =
  "Creating and viewing Alerts is temporarily unavailable. Thanks for your patience while we resolve the issue.";

class TopNavigation extends React.PureComponent {
  static propTypes = {
    hideNav: PropTypes.bool,
    newEventsCount: PropTypes.number
  };

  static defaultProps = {
    hideNav: false
  };

  componentDidMount() {
    this.props.dispatch(alertsActions.getNotifications());
  }

  get newEventsCount() {
    if (!this.props.newEventsCount) {
      return null;
    }
    if (this.props.newEventsCount > 99) {
      return "+";
    }
    return this.props.newEventsCount;
  }

  render() {
    return (
      !this.props.hideNav && (
        <div className="top-navigation">
          <EnhancedLink className="top-navigation__button" to="/create-budget">
            <Button
              variant="secondary"
              size="small"
              ml={4}
              style={{ whiteSpace: "nowrap" }}
            >
              <Icon.Grid color="white.1" />
              Create Budget
            </Button>
          </EnhancedLink>
          <EnhancedLink className="top-navigation__button" to="/create-report">
            <Button
              variant="secondary"
              size="small"
              ml={4}
              style={{ whiteSpace: "nowrap" }}
            >
              <Icon.Report color="white.1" />
              Create Report
            </Button>
          </EnhancedLink>
          <AlertButton />
          {this.props.isPropertyAdmin ? (
            <AdminButton />
          ) : (
            <EnhancedLink className="top-navigation__button" to="/alerts/add">
              <Button variant="secondary" size="small" ml={4}>
                <Icon.Plus color="white.1" />
                Create Alert
              </Button>
            </EnhancedLink>
          )}
          <HelpButton />
        </div>
      )
    );
  }
}

const mapState = ({ posthog, user, alerts }) => {
  return {
    newEventsCount: alerts?.newEventsCount,
    flagsLoaded: posthog.flagsLoaded,
    isPropertyAdmin: user.is_property_admin
  };
};

export default connect(mapState)(TopNavigation);
