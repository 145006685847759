import endOfDayFns from "date-fns/endOfDay";
import endOfMonthFns from "date-fns/endOfMonth";
import endOfQuarterFns from "date-fns/endOfQuarter";
import endOfWeekFns from "date-fns/endOfWeek";
import endOfYearFns from "date-fns/endOfYear";
import startOfDayFns from "date-fns/startOfDay";
import startOfMonthFns from "date-fns/startOfMonth";
import startOfQuarterFns from "date-fns/startOfQuarter";
import startOfWeekFns from "date-fns/startOfWeek";
import startOfYearFns from "date-fns/startOfYear";
import subQuartersFns from "date-fns/subQuarters";
import subYearsFns from "date-fns/subYears";
import addYearsFns from "date-fns/addYears";
import addMonthsFns from "date-fns/addMonths";
import addWeeksFns from "date-fns/addWeeks";
import addDaysFns from "date-fns/addDays";
import addHoursFns from "date-fns/addHours";
import addMinutesFns from "date-fns/addMinutes";
import addSecondsFns from "date-fns/addSeconds";
import isAfterFns from "date-fns/isAfter";
import isFutureFns from "date-fns/isFuture";
import isValidFns from "date-fns/isValid";
import formatFns from "date-fns/format";
import parseISO from "date-fns/parseISO";
import subHours from "date-fns/subHours";
import subDaysFns from "date-fns/subDays";
import subWeeksFns from "date-fns/subWeeks";
import subMonthsFns from "date-fns/subMonths";
import startOfYesterdayFns from "date-fns/startOfYesterday";
import endOfYesterdayFns from "date-fns/endOfYesterday";
import differenceInCalendarDaysFns from "date-fns/differenceInCalendarDays";
import differenceInCalendarMonthsFns from "date-fns/differenceInCalendarMonths";
import differenceInCalendarWeeksFns from "date-fns/differenceInCalendarWeeks";
import differenceInCalendarYearsFns from "date-fns/differenceInCalendarYears";
import differenceInHoursFns from "date-fns/differenceInHours";
import differenceInMillisecondsFns from "date-fns/differenceInMilliseconds";
import differenceInMinutesFns from "date-fns/differenceInMinutes";
import differenceInSecondsFns from "date-fns/differenceInSeconds";
import formatISO from "date-fns/formatISO";

import { REPORTING_DAY_TO_NUM } from "../constants";

function parseIfStr(date) {
  if (typeof date === "string" || date instanceof String) {
    return parseISO(date);
  }
  return date;
}

function exceptionHandler(func, ...params) {
  try {
    return func(...params);
  } catch (e) {
    return "Invalid Date";
  }
}

export function getStartDatesByPreset(reportingDay = "M", baseDate) {
  const day = REPORTING_DAY_TO_NUM[reportingDay];
  const weekOptions = { weekStartsOn: day };
  const baseDateValue = !!baseDate ? baseDate : new Date();
  const prevDate = startOfDay(subDays(baseDateValue, 1));
  return {
    last_week: subWeeks(startOfWeek(baseDateValue, weekOptions), 1),
    last_two_weeks: subWeeks(startOfWeek(baseDateValue, weekOptions), 2),
    month_to_date: startOfMonth(prevDate),
    last_30_days: subDays(prevDate, 30),
    last_month: startOfMonth(subMonths(baseDateValue, 1)),
    quarter_to_date: startOfQuarter(prevDate),
    last_quarter: startOfQuarter(subQuarters(baseDateValue, 1)),
    year_to_date: startOfYear(prevDate),
    last_year: startOfYear(subYears(baseDateValue, 1))
  };
}

export function getEndDatesByPreset(reportingDay = "M", baseDate) {
  const day = REPORTING_DAY_TO_NUM[reportingDay];
  const weekOptions = { weekStartsOn: day };
  const baseDateValue = !!baseDate ? baseDate : new Date();
  const prevDate = startOfDay(subDays(baseDateValue, 1));
  return {
    last_week: subHours(subWeeks(endOfWeek(baseDateValue, weekOptions), 1), 12),
    last_two_weeks: subHours(
      subWeeks(endOfWeek(baseDateValue, weekOptions), 1),
      12
    ),
    month_to_date: prevDate,
    last_30_days: prevDate,
    last_month: subHours(endOfMonth(subMonths(baseDateValue, 1)), 12),
    quarter_to_date: prevDate,
    last_quarter: subHours(endOfQuarter(subQuarters(baseDateValue, 1)), 12),
    year_to_date: prevDate,
    last_year: subHours(endOfYear(subYears(baseDateValue, 1)), 12)
  };
}

export function addToDate(date, duration) {
  const funcs = {
    years: addYears,
    months: addMonths,
    weeks: addWeeks,
    days: addDays,
    hours: addHours,
    minutes: addMinutes,
    seconds: addSeconds
  };
  let result = date;
  Object.keys(duration).forEach(period => {
    result = funcs[period](result, duration[period]);
  });
  return result;
}

export function isExpired(date, duration) {
  let result = false;
  if (date && duration) {
    const now = new Date();
    const expirationDate = addToDate(date, duration);
    result = expirationDate < now;
  }
  return result;
}

export function getPeriodByURL(searchStr) {
  const searchParams = new URLSearchParams(searchStr);
  if (!searchParams.has("start") || !searchParams.has("end")) {
    return null;
  }
  const start = dateParse(searchParams.get("start"));
  const end = dateParse(searchParams.get("end"));
  if (!isValidFns(start) || !isValidFns(end)) {
    return null;
  }
  const startDate = startOfDay(start);
  const endDate = endOfDay(end);
  if (isAfter(start, end) || isFuture(start)) {
    return null;
  }
  return {
    preset: "custom",
    start_date: startDate,
    end_date: endDate
  };
}

export function dateFormat(date, format) {
  date = parseIfStr(date);
  return exceptionHandler(formatFns, date, format);
}

export function dateParse(date) {
  return exceptionHandler(parseISO, date);
}

export function subDays(date, amount) {
  date = parseIfStr(date);
  return exceptionHandler(subDaysFns, date, amount);
}

export function subWeeks(date, amount) {
  date = parseIfStr(date);
  return exceptionHandler(subWeeksFns, date, amount);
}

export function subMonths(date, amount) {
  date = parseIfStr(date);
  return exceptionHandler(subMonthsFns, date, amount);
}

export function differenceInCalendarDays(dateLeft, dateRight) {
  dateLeft = parseIfStr(dateLeft);
  dateRight = parseIfStr(dateRight);
  return exceptionHandler(differenceInCalendarDaysFns, dateLeft, dateRight);
}

export function differenceInCalendarMonths(dateLeft, dateRight) {
  dateLeft = parseIfStr(dateLeft);
  dateRight = parseIfStr(dateRight);
  return exceptionHandler(differenceInCalendarMonthsFns, dateLeft, dateRight);
}

export function differenceInCalendarWeeks(dateLeft, dateRight) {
  dateLeft = parseIfStr(dateLeft);
  dateRight = parseIfStr(dateRight);
  return exceptionHandler(differenceInCalendarWeeksFns, dateLeft, dateRight);
}

export function differenceInCalendarYears(dateLeft, dateRight) {
  dateLeft = parseIfStr(dateLeft);
  dateRight = parseIfStr(dateRight);
  return exceptionHandler(differenceInCalendarYearsFns, dateLeft, dateRight);
}

export function differenceInHours(dateLeft, dateRight) {
  dateLeft = parseIfStr(dateLeft);
  dateRight = parseIfStr(dateRight);
  return exceptionHandler(differenceInHoursFns, dateLeft, dateRight);
}

export function differenceInMilliseconds(dateLeft, dateRight) {
  dateLeft = parseIfStr(dateLeft);
  dateRight = parseIfStr(dateRight);
  return exceptionHandler(differenceInMillisecondsFns, dateLeft, dateRight);
}

export function differenceInMinutes(dateLeft, dateRight) {
  dateLeft = parseIfStr(dateLeft);
  dateRight = parseIfStr(dateRight);
  return exceptionHandler(differenceInMinutesFns, dateLeft, dateRight);
}

export function differenceInSeconds(dateLeft, dateRight) {
  dateLeft = parseIfStr(dateLeft);
  dateRight = parseIfStr(dateRight);
  return exceptionHandler(differenceInSecondsFns, dateLeft, dateRight);
}

export function isFuture(date) {
  date = parseIfStr(date);
  return exceptionHandler(isFutureFns, date);
}

export function startOfYesterday(date) {
  date = parseIfStr(date);
  return exceptionHandler(startOfYesterdayFns, date);
}

export function endOfYesterday(date) {
  date = parseIfStr(date);
  return exceptionHandler(endOfYesterdayFns, date);
}

export function isAfter(date, date2) {
  date = parseIfStr(date);
  date2 = parseIfStr(date2);
  return exceptionHandler(isAfterFns, date, date2);
}

export function addYears(date, amount) {
  date = parseIfStr(date);
  return exceptionHandler(addYearsFns, date, amount);
}

export function addMonths(date, amount) {
  date = parseIfStr(date);
  return exceptionHandler(addMonthsFns, date, amount);
}

export function addWeeks(date, amount) {
  date = parseIfStr(date);
  return exceptionHandler(addWeeksFns, date, amount);
}

export function addDays(date, amount) {
  date = parseIfStr(date);
  return exceptionHandler(addDaysFns, date, amount);
}

export function addHours(date, amount) {
  date = parseIfStr(date);
  return exceptionHandler(addHoursFns, date, amount);
}

export function addMinutes(date, amount) {
  date = parseIfStr(date);
  return exceptionHandler(addMinutesFns, date, amount);
}

export function addSeconds(date, amount) {
  date = parseIfStr(date);
  return exceptionHandler(addSecondsFns, date, amount);
}

export function subQuarters(date, amount) {
  date = parseIfStr(date);
  return exceptionHandler(subQuartersFns, date, amount);
}
export function subYears(date, amount) {
  date = parseIfStr(date);
  return exceptionHandler(subYearsFns, date, amount);
}

export function startOfDay(date) {
  date = parseIfStr(date);
  return exceptionHandler(startOfDayFns, date);
}
export function startOfMonth(date) {
  date = parseIfStr(date);
  return exceptionHandler(startOfMonthFns, date);
}
export function startOfQuarter(date) {
  date = parseIfStr(date);
  return exceptionHandler(startOfQuarterFns, date);
}
export function startOfWeek(date, amount) {
  date = parseIfStr(date);
  return exceptionHandler(startOfWeekFns, date, amount);
}
export function startOfYear(date) {
  date = parseIfStr(date);
  return exceptionHandler(startOfYearFns, date);
}

export function endOfDay(date) {
  date = parseIfStr(date);
  return exceptionHandler(endOfDayFns, date);
}

export function endOfMonth(date) {
  date = parseIfStr(date);
  return exceptionHandler(endOfMonthFns, date);
}

export function endOfQuarter(date) {
  date = parseIfStr(date);
  return exceptionHandler(endOfQuarterFns, date);
}

export function endOfWeek(date, amount) {
  date = parseIfStr(date);
  return exceptionHandler(endOfWeekFns, date, amount);
}

export function endOfYear(date) {
  date = parseIfStr(date);
  return exceptionHandler(endOfYearFns, date);
}

export function isValid(date) {
  date = parseIfStr(date);
  return exceptionHandler(isValidFns, date);
}

export function getDaysInMonth(date) {
  const parsedDate = parseIfStr(date);
  const year = parsedDate.getFullYear();
  const monthIndex = parsedDate.getMonth();
  let lastDayOfMonth = new Date(0);
  lastDayOfMonth.setFullYear(year, monthIndex + 1, 0);
  lastDayOfMonth.setHours(0, 0, 0, 0);

  return lastDayOfMonth.getDate();
}

export function dateToISO(date) {
  date = parseIfStr(date);
  return formatISO(date, { representation: "date" });
}

export function formatDateToURLParam(date) {
  return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
}
