import React from "react";
import { BrowserRouter as Router, Redirect, Switch } from "react-router-dom";
import { connect } from "react-redux";

import checkFlag from "../utils/posthog";
import CompleteAccountView from "../components/complete_account_view";
import AccountSettingsContainer from "../containers/account_settings";
import PropertyGroupContainer from "../containers/property_group";
import CreateReportFormContainer from "../containers/create_report_form";
import CreateBudgetFormContainer from "../containers/create_budget_form";
import AuthContainer from "../containers/auth";
import CreatePasswordContainer from "../containers/create_password";
import ErrorContainer from "../containers/error";
import PropertyPageContainer from "../containers/property_page";
import PropertyKPIPageContainer from "../containers/property_kpi_page";
import ResetPasswordDone from "../components/reset_password_done";
import ResetPasswordSuccess from "../components/reset_password_success";
import CreateAlertContainer from "../containers/create_alert";
import AlertsPageContainer from "../containers/alerts_page";
import { TrackedRoute as Route } from "./gaTracked";
import ReferralSources from "../containers/referral_sources";
import ExpensesContainer from "../containers/expenses";
import ExpenseFormContainer from "../containers/expense_form";
import AdminPropertiesContainer from "../containers/admin_properties";
import AdminPropertiesFormContainer from "../containers/admin_properties_form";
import AdminPropertyAttributesForm from "../containers/admin_property_attributes_form";
import AdminConnectionsContainer from "../containers/admin_connections";
import AdminPMSInstanceFormContainer from "../containers/admin_pms_instance_form";
import AdminDataConnectionFormContainer from "../containers/admin_data_connection_form";
import ResetPasswordContainer from "../containers/reset_password";
import ExpiredPasswordContainer from "../containers/reset_password/expired";

function RemarkableRouter(props) {
  return (
    <Router>
      <Switch>
        {/*TODO: PropertyGroupContainer fires all the actions to get starting state for app. For the future, we should grab all core state once user logs in rather than through a component */}
        <Route path="/" component={PropertyGroupContainer} exact={true} />
        <Route path="/referral-sources/:tab">
          {props.isPropertyAdmin ? (
            <ReferralSources />
          ) : (
            <Redirect to="/property-group" />
          )}
        </Route>
        <Route path="/expenses/add/:expense_id?">
          {props.isPropertyAdmin ? (
            <ExpenseFormContainer />
          ) : (
            <Redirect to="/property-group" />
          )}
        </Route>
        <Route path="/expenses" exact={true}>
          {props.isPropertyAdmin ? (
            <ExpensesContainer />
          ) : (
            <Redirect to="/property-group" />
          )}
        </Route>
        <Route path="/alerts/add" component={CreateAlertContainer} />
        <Route path="/alerts" component={AlertsPageContainer} />
        <Route path="/create-report">
          <CreateReportFormContainer />
        </Route>
        <Route path="/create-budget">
          <CreateBudgetFormContainer />
        </Route>
        <Route
          path="/users/create-password/:hash"
          component={CreatePasswordContainer}
        />
        <Route
          path="/users/reset/:uid/:token"
          component={CreatePasswordContainer}
        />
        <Route
          path="/users/password-reset/"
          component={ResetPasswordContainer}
        />
        <Route path="/users/password-resend/" component={ResetPasswordDone} />
        <Route
          path="/users/password-success/"
          component={ResetPasswordSuccess}
        />
        <Route
          path="/users/password-expired/"
          component={ExpiredPasswordContainer}
        />
        <Route path="/users/complete-account" component={CompleteAccountView} />
        <Route
          path="/property-group/:groupId/:tabId"
          component={PropertyGroupContainer}
        />
        <Route
          path="/property/:publicId/:category/:kpi"
          component={PropertyKPIPageContainer}
        />
        <Route
          path="/property/:publicId"
          component={PropertyPageContainer}
          exact={true}
        />
        <Route
          path="/property/:publicId/:tabId"
          component={PropertyPageContainer}
        />
        <Route path="/auth" component={AuthContainer} />
        <Route path="/error" component={ErrorContainer} />
        <Route path="/account-settings" component={AccountSettingsContainer} />
        <Route path="/admin-properties/add-attributes/:publicId?">
          {props.isPropertyAdmin ? (
            <AdminPropertyAttributesForm />
          ) : (
            <Redirect to="/property-group" />
          )}
        </Route>
        <Route path="/admin-properties/add/:publicId?">
          {props.isPropertyAdmin ? (
            <AdminPropertiesFormContainer />
          ) : (
            <Redirect to="/property-group" />
          )}
        </Route>
        <Route path="/admin-properties" exact={true}>
          {props.isPropertyAdmin ? (
            <AdminPropertiesContainer />
          ) : (
            <Redirect to="/property-group" />
          )}
        </Route>
        <Route path="/admin-properties/:tabId">
          {props.isPropertyAdmin ? (
            <AdminPropertiesContainer />
          ) : (
            <Redirect to="/property-group" />
          )}
        </Route>
        <Route path="/admin-connections/add-pms-instance/:id?">
          {props.isPropertyAdmin ? (
            <AdminPMSInstanceFormContainer />
          ) : (
            <Redirect to="/property-group" />
          )}
        </Route>
        <Route path="/admin-connections/add-data-connection/:integration_id?">
          {props.isPropertyAdmin ? (
            <AdminDataConnectionFormContainer />
          ) : (
            <Redirect to="/property-group" />
          )}
        </Route>
        <Route path="/admin-connections" exact={true}>
          {props.isPropertyAdmin ? (
            <AdminConnectionsContainer />
          ) : (
            <Redirect to="/property-group" />
          )}
        </Route>
        <Route path="/admin-connections/:tabId">
          {props.isPropertyAdmin ? (
            <AdminConnectionsContainer />
          ) : (
            <Redirect to="/property-group" />
          )}
        </Route>
        <Redirect to="/" />
      </Switch>
    </Router>
  );
}

const mapState = ({ posthog, user }) => {
  return {
    flagsLoaded: posthog.flagsLoaded,
    isPropertyAdmin: user.is_property_admin
  };
};

export default connect(mapState)(RemarkableRouter);
