import React, { useState } from "react";
import {
  Flex,
  Divider,
  Box,
  TextInput,
  DatePicker,
  DateRangeSelector,
  Button,
  Icon,
  Text,
  Tooltip
} from "concrete-ui";

import {
  dateToDatePicker,
  dateToValidation,
  inputNumberFormatter,
  inputStringFormatter,
  inputTextWithTooltip
} from "../utils";
import { concreteErrorVariant } from "../../../constants";

import ConfirmationDeleteExpenseModal from "../confirmation-delete-modal";
import { expenseTooltips } from "../tooltips";

export const ActualExpenseItem = ({
  onExpenseChange,
  onDeleteExpenseClick,
  index,
  automated,
  // form items
  invoice_date,
  expense_date,
  expense_date_end,
  cost,
  suggested_expense,
  itemErrors = {}
}) => {
  const [deleteConfirmationModal, setDeleteConfirmationModal] = useState(false);

  function checkInputExists() {
    return invoice_date || expense_date_end || expense_date || cost;
  }

  const onModalOpenClick = () => {
    setDeleteConfirmationModal(true);
  };

  const onModalCloseClick = () => {
    setDeleteConfirmationModal(false);
  };

  return (
    <Flex flexDirection="column">
      {index === 0 && <Divider variant="horizontal" bg="gray.4" mb={4} />}
      {deleteConfirmationModal && (
        <ConfirmationDeleteExpenseModal
          closeHandler={onModalCloseClick}
          deleteHandler={onDeleteExpenseClick}
          title={"Delete Actual Expense?"}
          children={
            <Text textSize={2}>
              Are you sure you want to permanently delete this information?
            </Text>
          }
        />
      )}
      <Flex position="relative">
        <Box position="absolute" left={-48} top={0}>
          <Button
            variant="icon"
            size="large"
            type="button"
            onClick={
              checkInputExists() ? onModalOpenClick : onDeleteExpenseClick
            }
          >
            <Icon.Trash />
          </Button>
        </Box>
        <Flex flexWrap="wrap" alignItems="flex-end">
          <Box mt={1} mb={4} flexBasis="100%">
            <Text textSize={2} $fontWeight="medium">
              Actual Expense #{index + 1}
            </Text>
            {automated && (
              <Tooltip
                my="auto"
                ml={2}
                title={expenseTooltips["actualExpense"]["automated"]}
                size="small"
              />
            )}
          </Box>
          <DatePicker
            flexBasis="calc(50% - 0.75rem)"
            mr={5}
            mb={7}
            initDate={dateToDatePicker(invoice_date)}
            setDate={date =>
              onExpenseChange("invoice_date", dateToValidation(date))
            }
            label="Invoice Date (Optional)"
            errorMessage={itemErrors.invoice_date}
          />
          <TextInput
            size="large"
            flexBasis="calc(50% - 0.75rem)"
            mb={4}
            iconLeft={<Icon.Dollar />}
            value={inputStringFormatter(cost)}
            onChange={e =>
              onExpenseChange(
                "cost",
                inputNumberFormatter(e.currentTarget.value)
              )
            }
            label={inputTextWithTooltip(
              "Actual Expense",
              expenseTooltips["actualExpense"]["amount"]
            )}
            help={`Suggested Value: $${suggested_expense}`}
            variant={itemErrors.cost && concreteErrorVariant}
            message={itemErrors.cost}
          />
          <DateRangeSelector
            flexBasis="100%"
            startOptions={{
              initialDate: dateToDatePicker(expense_date),
              onDateSelect: date =>
                onExpenseChange("expense_date", dateToValidation(date)),
              label: "Expense Start Date",
              startOrEnd: "start",
              ml: -3,
              errorMessage: itemErrors.expense_date
            }}
            endOptions={{
              initialDate: dateToDatePicker(expense_date_end),
              onDateSelect: date =>
                onExpenseChange("expense_date_end", dateToValidation(date)),
              label: "Expense End Date",
              startOrEnd: "end",
              mr: -3,
              errorMessage: itemErrors.expense_date_end
            }}
          />
        </Flex>
      </Flex>
      <Divider variant="horizontal" bg="gray.4" my={6} />
    </Flex>
  );
};
