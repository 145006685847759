import React from "react";

export const COUNTRY_FIELDS = {
  USA: {
    full_name: "United States of America",
    short_name: "USA",
    iso2: "US",
    address_fields: {
      city: "city",
      state: "state",
      zip: "zipcode"
    },
    phone_code: "1"
  },
  GBR: {
    full_name: "United Kingdom",
    short_name: "GBR",
    iso2: "GB",
    address_fields: {
      city: "postal town",
      state: "county",
      zip: "postcode"
    },
    phone_code: "44"
  }
};

export const COUNTRY_CODE_REGEX = /^[0-9]{1,4}$/;

export const TYPING_TIMEOUT = 300;

export const REPORTING_DAY_TO_NUM = {
  M: 1,
  T: 2,
  W: 3,
  Th: 4,
  F: 5,
  Sa: 6,
  Su: 0
};

export const PROPERTY_GROUP_FILTERS = {
  members: {
    dim: "[projects_project.public_id]"
  },
  selectedOwners: {
    dim: "[projects_project.property_owner_id]"
  },
  selectedCities: {
    dim: "[geo_address.city]"
  },
  selectedStates: {
    dim: "[geo_address.state]"
  },
  selectedLeaseStages: {
    dim: "[projects_leasestage.id]"
  }
};

// Concrete-ui constants
export const concreteErrorVariant = "error";
export const concreteSuccessVariant = "success";

export const ETL_ALTERNATE_KPI_NAME = {
  occupied_units_to_total_units: "occupied_rate",
  leased_units_to_total_units: "leased_rate"
};

export const KPI_CATEGORY = {
  property_group: {
    leased_rate: "performance",
    occupied_rate: "performance",
    exposure_rate: "performance",
    approved_lease: "funnel",
    leases_executed: "funnel",
    leads: "funnel",
    tours: "funnel",
    lease_applications: "funnel"
  },
  property: {
    leased_rate: "performance",
    occupied_rate: "performance",
    exposure_rate: "performance",
    lease_cds: "performance",
    nwv: "upper-funnel",
    leads: "upper-funnel",
    nwv_leads: "upper-funnel",
    leads_to_tours: "upper-funnel",
    tours: "middle-funnel",
    lease_applications: "middle-funnel",
    tours_to_applications: "middle-funnel",
    lease_applications_to_approved: "middle-funnel",
    new_leases: "lower-funnel",
    move_ins: "lower-funnel",
    new_leases_to_move_ins: "lower-funnel",
    approved_lease: "lower-funnel",
    approved_leases_to_new_leases: "lower-funnel",
    notices_to_vacate: "retention",
    renewals: "retention",
    move_outs: "retention"
  }
};

export const KPI_URL_LABEL = {
  property: {
    leased_rate: "leased",
    occupied_rate: "occupied",
    nwv: "new_website_visitors",
    leads: "leads",
    tours: "tours",
    lease_applications: "lease_applications",
    leases_executed: "new_leases",
    move_ins: "move_ins",
    nwv_leads: "new_website_visitors_to_leads",
    leads_to_tours: "leads_to_tours",
    tours_to_applications: "tours_to_applications",
    new_leases_to_move_ins: "new_leases_to_move_ins",
    lease_cds: "cancellation_and_denial",
    notices_to_vacate: "notices_to_vacate",
    renewals: "renewals",
    move_outs: "move_outs",
    exposure_rate: "exposure",
    app_approvals: "lease_applications_to_approved",
    approved_leases: "approved_leases",
    approved_leases_to_new_leases: "approved_leases_to_new_leases",
    leads_new_leases: "leads_to_lease"
  }
};

// Matches kpi labels between frontend and backend
export const KPI_MATCHER = {
  occupied_rate: "occupancy_rate",
  leased_rate: "leased_rate",
  exposure_rate: "exposure_rate",
  leads_to_lease: "inq_exe",
  cancellation_and_denial: "lease_cd_rate",
  nwv: "usvs",
  nwv_leads: "usv_inq",
  leads: "inquiries",
  leads_to_tours: "inq_tou",
  tours: "tours",
  tours_to_applications: "tou_app",
  lease_applications: "lease_applications",
  lease_applications_to_approved: "app_approvals",
  approved_leases: "approvals",
  approved_leases_to_new_leases: "approved_exe",
  new_leases: "leases_executed",
  new_leases_to_move_ins: "exe_ins",
  move_ins: "move_ins",
  renewals: "lease_renewals",
  notices_to_vacate: "lease_vacation_notices",
  move_outs: "move_outs",
  approved_exe: "approved_exe",
  app_exe_perc: "app_exe_perc",
  app_approvals: "app_approvals",
  tours_to_applications_perc: "tou_app",
  // URL Matching KPIs
  occupied: "occupancy_rate",
  leased: "leased_rate",
  exposure: "exposure_rate",
  new_website_visitors: "usvs",
  new_website_visitors_to_leads: "usv_inq"
};

export const KPI_HUMAN_LABEL = {
  // Performance
  occupied: "Occupied",
  occupancy_rate: "Occupied",
  occupied_rate: "Occupied",
  leased_rate: "Leased",
  leased: "Leased",
  exposure_rate: "Exposed",
  exposure: "Exposed",
  inq_exe: "Leads → New Leases",
  leads_to_lease: "Leads → Leases %",
  lease_cd_rate: "Cancellations & Denials",
  cancellation_and_denial: "Cancellations & Denials",
  // Upper funnel
  usvs: "New Website Visitors",
  new_website_visitors: "New Website Visitors",
  nwv: "New Website Visitors",
  usv_inq: "New Website Visitors → Leads",
  new_website_visitors_to_leads: "New Website Visitors → Leads",
  inquiries: "Leads",
  leads: "Leads",
  inq_tou: "Leads → Tours",
  leads_to_tours: "Leads → Tours",
  // Middle funnel
  tours: "Tours",
  tou_app: "Tours → Lease Applications",
  tou_app_perc: "Tours → Lease Applications",
  tours_to_applications: "Tours → Lease Applications",
  lease_applications: "Lease Applications",
  app_approvals: "Lease Applications → Approved Leases",
  // Lower funnel
  approvals: "Approved Leases",
  approved_leases: "Approved Leases",
  app_exe: "Approved Leases → New Leases",
  approved_leases_to_new_leases: "Approved Leases → New Leases",
  approved_exe: "Approved Leases → New Leases",
  leases_executed: "New Leases",
  new_leases: "New Leases",
  exe_ins: "New Leases → Move Ins",
  new_leases_to_move_ins: "New Leases → Move Ins",
  move_ins: "Move-Ins",
  // Retention
  lease_renewals: "Renewals",
  renewals: "Renewals",
  lease_vacation_notices: "Notices to Vacate",
  notices_to_vacate: "Notices to Vacate",
  move_outs: "Move-Outs"
};

export const getHumanReadableKPI = kpi =>
  KPI_HUMAN_LABEL[kpi] ? KPI_HUMAN_LABEL[kpi] : kpi;

export const getHumanReadableKpiForCsv = kpi => {
  let humanLabel = getHumanReadableKPI(kpi);
  if (humanLabel.includes("→")) {
    let response = humanLabel.replaceAll("→", ">");
    return response;
  }
  return humanLabel;
};

export const CHART_TYPE = {
  app_exe: "percentage",
  app_exe_perc: "percentage",
  app_approvals: "percentage",
  approvals: "number",
  approved_exe: "percentage",
  approved_leases: "number",
  approved_leases_to_new_leases: "percentage",
  cancellation_and_denial: "percentage",
  lease_cd_rate: "percentage",
  exe_ins: "percentage",
  exposure: "percentage",
  exposure_rate: "percentage",
  inq_exe: "percentage",
  inq_tou: "percentage",
  inquiries: "number",
  leads: "number",
  leads_to_lease: "percentage",
  leads_to_tours: "percentage",
  lease_applications: "number",
  lease_applications_to_approved: "percentage",
  lease_renewals: "number",
  lease_vacation_notices: "number",
  leased: "percentage",
  leased_rate: "percentage",
  leases_executed: "number",
  move_ins: "number",
  move_outs: "number",
  new_leases: "number",
  new_leases_to_move_ins: "percentage",
  new_website_visitors: "number",
  new_website_visitors_to_leads: "percentage",
  notices_to_vacate: "number",
  nwv_leads: "percentage",
  occupancy_rate: "percentage",
  occupied: "percentage",
  occupied_rate: "percentage",
  renewals: "number",
  tou_app_perc: "percentage",
  tou_app: "percentage",
  tours: "number",
  tours_to_applications: "percentage",
  usv_inq: "percentage",
  usvs: "number"
};

export const PROPERTY_CHART_PROPS = {
  approved_leases: { nullAsZero: false },
  approvals: { nullAsZero: false },
  occupancy_rate: {
    yAxisOverflow: true,
    yDomain: { min: 0, max: 100 }
  },
  leased_rate: {
    yAxisOverflow: true,
    yDomain: { min: 0, max: 100 }
  }
};

export const DATE_FORMAT = "MM/dd/yyyy";

export const DATETIME_FORMAT = "MM/dd/yyyy h:mm aa";

export const RATE_KPI = {
  inq_exe: {
    num: "leases_executed",
    den: "inquiries"
  },
  lease_cd_rate: {
    num: "lease_cds",
    den: "lease_applications"
  },
  usv_inq: {
    num: "inquiries",
    den: "usvs"
  },
  inq_tou: {
    num: "tours",
    den: "inquiries"
  },
  tou_app: {
    num: "lease_applications",
    den: "tours"
  },
  app_approvals: {
    num: "approvals",
    den: "lease_applications"
  },
  approved_exe: {
    num: "leases_executed",
    den: "approvals"
  },
  exe_ins: {
    num: "move_ins",
    den: "leases_executed"
  }
};

export const PERFORMANCE_KPI_LIST = [
  "occupancy_rate",
  "exposure_rate",
  "leased_rate"
];

// Expenses
export const expenseCopySuffix = " (COPY)";
export const nullDashDisplay = "—";

export const SUPPORT_EMAIL = "support@remarkably.io";

export const INFINITY_STRING = "∞";

export const GOOGLE_MAPS_API_LIBRARIES = "places";
export const GOOGLE_MAPS_BASE_URL = "https://maps.googleapis.com/maps/api/js";

// Status codes
export const UNPROCESSABLE_ENTITY_CODE = 422;
