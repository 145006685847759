import React from "react";
import IconBase from "./icon_base";

const Tick = props => (
  <IconBase viewBox="0 0 13 11" {...props}>
    <path
      d="M11.1689 1.03225C11.2971 0.904127 11.5048 0.904127 11.633 1.03225L12.4063 1.80564C12.5345 1.93379 12.5345 2.14156 12.4063 2.26968L4.20837 10.4677C4.0802 10.5958 3.87245 10.5958 3.74433 10.4677L0.0961101 6.81947C-0.0320367 6.6913 -0.0320367 6.48355 0.0961101 6.3554L0.869477 5.58203C0.997651 5.45389 1.2054 5.45389 1.33355 5.58203L3.97635 8.22484L11.1689 1.03225Z"
      fill="currentColor"
    />
  </IconBase>
);

export default Tick;
