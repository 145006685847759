import { data as summaryData } from "../../components/channel_analysis_summary/props";
import { dateParse, dateToISO } from "../../utils/dates";
import _isEmpty from "lodash/isEmpty";
import {
  exposureRankingsEmptyData,
  rankingsEmptyData
} from "../../components/channel_analysis_rankings/data";

const initialState = {
  property: {},
  groupProperties: [],
  members: [],
  membersSearch: [],
  isAdmin: false,
  isInviteOpen: false,
  isRemoveMemberOpen: false,
  isChangeRoleOpen: false,
  propertyIsFetching: false,
  isMembersLoading: false,
  kpiData: {},
  nwvs: [],
  leads: [],
  fetchingLeads: false,
  custom_tags: [],
  suggestedTags: {},
  isAddTagInput: false,
  new_widget_data: [],
  loaderState: true,
  isCATableLoading: false,
  CATableData: [],
  CADistributedTableData: { summary: {} },
  CATableSummaryData: {},
  CASummaryData: summaryData,
  CARankingsData: rankingsEmptyData,
  CADistributedRankingsData: rankingsEmptyData,
  CAExposureRankingsData: exposureRankingsEmptyData,
  newWidgetData: [{ data: [], forecast_data: [] }],
  expensesLastUpdated: null,
  attributionsLastUpdated: null,
  byExpenseFilters: [],
  inviteErrorMessages: [],
  inviteCount: null,
  inviteRequestError: false,
  propertiesDetails: [],
  isLoadingPropDetails: false,
  isPostSuccessful: false,
  apiError: false,
  isLoadingPropDeactivation: false,
  propertyOwners: [],
  isLoadingPropertyOwners: false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "PROPERTY_UPDATE_STATE": {
      return {
        ...state,
        ...action.state
      };
    }
    case "GET_NEW_WIDGET_DATA_FOR_PROPERTY_SUCCESS":
      return {
        ...state,
        newWidgetData: action.data,
        loaderState: false
      };
    case "NEW_WIDGET_DATA_FOR_PROPERTY_LOADER":
      return {
        ...state,
        loaderState: true
      };
    case "CLOSE_NEW_WIDGET_DATA_FOR_PROPERTY":
    case "GET_NEW_WIDGET_DATA_FOR_PROPERTY_ERROR":
      return {
        ...state,
        loaderState: false
      };
    case "AJAX_GET_PROPERTY_REQUEST": {
      return { ...state, propertyIsFetching: true };
    }
    case "AJAX_GET_PROPERTY_SUCCESS": {
      const {
        group_properties: groupProperties,
        is_admin: isAdmin,
        members,
        ...property
      } = action.payload;
      return {
        ...state,
        propertyIsFetching: false,
        property,
        groupProperties,
        isAdmin,
        members
      };
    }
    case "AJAX_POST_PROPERTY_GET_USERS_REQUEST": {
      return { ...state, isMembersLoading: true };
    }
    case "AJAX_POST_PROPERTY_GET_USERS_ERROR": {
      return { ...state, isMembersLoading: false };
    }
    case "AJAX_POST_PROPERTY_GET_USERS_SUCCESS": {
      const members = action.payload?.members || [];
      const membersSearch = members.map(u => ({
        ...u,
        label: u.account_name || u.user_id,
        value: u.user_id
      }));
      return { ...state, membersSearch, isMembersLoading: false };
    }
    case "AJAX_POST_PROPERTY_INVITE_MEMBER_SUCCESS": {
      const p = action.payload.properties[0];
      const { is_admin: isAdmin, members } = p;
      const inviteCount = action.payload.invite_count;
      const inviteErrorMessages = action.payload.invite_errors;
      const inviteRequestError = action.payload.internal_error;
      const isInviteOpen = true;
      return {
        ...state,
        isInviteOpen,
        isAdmin,
        members,
        inviteErrorMessages,
        inviteCount,
        inviteRequestError
      };
    }
    case "AJAX_POST_PROPERTY_INVITE_MEMBER_ERROR": {
      const inviteErrorMessages = [
        "There was an issue with your invitation request(s)."
      ];
      return { ...state, inviteErrorMessages, inviteRequestError: true };
    }
    case "AJAX_POST_PROPERTY_UPDATE_MEMBER_SUCCESS": {
      const { is_admin: isAdmin, members } = action.payload;
      let { isInviteOpen } = state;
      if (!isAdmin) {
        isInviteOpen = false;
      }
      return {
        ...state,
        isInviteOpen,
        isChangeRoleOpen: false,
        members,
        isAdmin
      };
    }
    case "AJAX_POST_PROPERTY_REMOVE_MEMBER_SUCCESS": {
      const { is_admin: isAdmin, members } = action.payload;
      let { isInviteOpen } = state;
      if (!isAdmin) {
        isInviteOpen = false;
      }
      return {
        ...state,
        isRemoveMemberOpen: false,
        isInviteOpen,
        members,
        isAdmin
      };
    }
    case "PROPERTY_OPEN_INVITE_MEMBER": {
      return { ...state, isInviteOpen: true };
    }
    case "PROPERTY_CLOSE_INVITE_MEMBER": {
      return {
        ...state,
        inviteErrorMessages: [],
        inviteCount: null,
        isInviteOpen: false,
        inviteRequestError: false
      };
    }
    case "PROPERTY_OPEN_REMOVE_MEMBER": {
      return { ...state, isRemoveMemberOpen: true };
    }
    case "PROPERTY_CLOSE_REMOVE_MEMBER": {
      return { ...state, isRemoveMemberOpen: false };
    }
    case "PROPERTY_OPEN_CHANGE_ROLE": {
      return { ...state, isChangeRoleOpen: true };
    }
    case "PROPERTY_CLOSE_CHANGE_ROLE": {
      return { ...state, isChangeRoleOpen: false };
    }
    case "AJAX_GET_PROPERTY_DATA_SOURCE_SUCCESS": {
      const dataSource = action.payload.data_source;
      return { ...state, dataSource };
    }
    case "AJAX_GET_PROPERTY_DATA_SOURCE_ERROR": {
      const dataSource = null;
      return { ...state, dataSource };
    }
    case "AJAX_GET_PROPERTY_KPI_DATA_SUCCESS": {
      return { ...state, kpiData: action.payload };
    }
    case "AJAX_GET_PROPERTY_KPI_DATA_ERROR": {
      return { ...state, kpiData: { error: "Server Error" } };
    }
    case "AJAX_GET_PROPERTY_NWVS_REQUEST": {
      return { ...state, nwvs: [] };
    }
    case "AJAX_GET_PROPERTY_NWVS_SUCCESS": {
      return { ...state, nwvs: action.payload?.data || [] };
    }
    case "AJAX_GET_PROPERTY_NWVS_ERROR": {
      return { ...state, nwvs: [] };
    }
    case "PROPERTY_CLEAR_NWVS": {
      return { ...state, nwvs: [] };
    }
    case "AJAX_GET_PROPERTY_LEADS_REQUEST": {
      return { ...state, leads: [], fetchingLeads: true };
    }
    case "AJAX_GET_PROPERTY_LEADS_SUCCESS": {
      return {
        ...state,
        leads: action.payload?.table_data || [],
        fetchingLeads: false
      };
    }
    case "AJAX_GET_PROPERTY_LEADS_ERROR": {
      return { ...state, leads: [], fetchingLeads: false };
    }
    case "PROPERTY_CLEAR_LEADS": {
      return { ...state, leads: [] };
    }
    case "PROPERTY_SHOW_TAG_INPUT": {
      return { ...state, isAddTagInput: true };
    }
    case "PROPERTY_HIDE_TAG_INPUT": {
      return { ...state, suggestedTags: {}, isAddTagInput: false };
    }
    case "AJAX_POST_PROPERTY_CREATE_TAG_SUCCESS": {
      const tag = { ...action.payload };
      const custom_tags = [...state.custom_tags];
      if (!custom_tags.find(t => t.tag_id === tag.tag_id)) {
        custom_tags.push(tag);
      }
      return { ...state, custom_tags, suggestedTags: {}, isAddTagInput: false };
    }
    case "AJAX_POST_PROPERTY_REMOVE_TAG_SUCCESS": {
      const { tagId } = action.params;
      const custom_tags = state.custom_tags.filter(tag => tag.tag_id !== tagId);
      return { ...state, custom_tags };
    }
    case "AJAX_GET_PROPERTY_TAGS_REQUEST": {
      return { ...state, custom_tags: [] };
    }
    case "AJAX_GET_PROPERTY_TAGS_SUCCESS": {
      const custom_tags = [...action.payload];
      return { ...state, custom_tags };
    }
    case "AJAX_GET_PROPERTY_SEARCH_TAGS_SUCCESS": {
      const suggestedTags = action.payload;
      return { ...state, suggestedTags };
    }
    case "SHOW_CA_TABLE_LOADER": {
      return {
        ...state,
        isCATableLoading: true
      };
    }
    case "CLOSE_CA_TABLE_LOADER": {
      return {
        ...state,
        isCATableLoading: false
      };
    }
    case "AJAX_POST_PROPERTY_CA_KPIS_REQUEST": {
      return {
        ...state,
        loaderState: true
      };
    }
    case "AJAX_POST_PROPERTY_CA_KPIS_SUCCESS": {
      if (!_isEmpty(action.payload)) {
        const {
          summary: CATableSummaryData,
          distributed: CADistributedTableData,
          rankings: CARankingsData,
          distributed_rankings: CADistributedRankingsData,
          exposure_rankings: CAExposureRankingsData,
          by_expense_filters: byExpenseFilters,
          ...CATableData
        } = action.payload;
        const attributionsLastUpdated = !!action.payload
          .attributions_last_updated
          ? dateToISO(action.payload.attributions_last_updated)
          : null;
        return {
          ...state,
          loaderState: false,
          byExpenseFilters,
          CATableData,
          CADistributedTableData,
          CATableSummaryData,
          attributionsLastUpdated,
          CARankingsData,
          CADistributedRankingsData,
          CAExposureRankingsData
        };
      } else {
        return {
          ...state,
          loaderState: false,
          CARankingsData: rankingsEmptyData,
          CADistributedRankingsData: rankingsEmptyData,
          CAExposureRankingsData: exposureRankingsEmptyData
        };
      }
    }
    case "AJAX_POST_PROPERTY_CA_KPIS_ERROR": {
      return {
        ...state,
        loaderState: false,
        CATableData: [],
        CADistributedTableData: { summary: {} },
        CATableSummaryData: {}
      };
    }
    case "AJAX_GET_PROPERTY_EXPENSES_LAST_UPDATED_SUCCESS": {
      const expensesLastUpdated = !!action.payload.last_updated
        ? dateParse(action.payload.last_updated)
        : null;
      return {
        ...state,
        expensesLastUpdated
      };
    }
    case "AJAX_PATCH_PROPERTIES_DETAILS_REQUEST":
    case "AJAX_POST_PROPERTIES_DETAILS_REQUEST": {
      return {
        ...state,
        isLoadingPropDetails: true,
        apiError: false,
        isPostSuccessful: false
      };
    }
    case "AJAX_PATCH_PROPERTIES_DETAILS_SUCCESS":
    case "AJAX_POST_PROPERTIES_DETAILS_SUCCESS": {
      return {
        ...state,
        actual_expenses: [],
        vendors: [],
        isLoadingPropDetails: false,
        isPostSuccessful: true
      };
    }
    case "AJAX_PATCH_PROPERTIES_DETAILS_ERROR":
    case "AJAX_POST_PROPERTIES_DETAILS_ERROR": {
      return {
        ...state,
        isLoadingPropDetails: false,
        apiError: true
      };
    }
    case "AJAX_GET_PROPERTIES_DETAILS_REQUEST": {
      return {
        ...state,
        isLoadingPropDetails: true
      };
    }
    case "AJAX_GET_PROPERTIES_DETAILS_SUCCESS": {
      return {
        ...state,
        propertiesDetails: action.payload,
        isLoadingPropDetails: false
      };
    }
    case "AJAX_GET_PROPERTIES_DETAILS_ERROR": {
      return {
        ...state,
        isLoadingPropDetails: false
      };
    }
    case "AJAX_GET_PROPERTY_OWNERS_REQUEST": {
      return {
        ...state,
        isLoadingPropertyOwners: true
      };
    }
    case "AJAX_GET_PROPERTY_OWNERS_SUCCESS": {
      return {
        ...state,
        propertyOwners: action.payload,
        isLoadingPropertyOwners: false
      };
    }
    case "AJAX_GET_PROPERTY_OWNERS_ERROR": {
      return {
        ...state,
        isLoadingPropertyOwners: false
      };
    }
    case "AJAX_POST_DEACTIVATE_PROPERTY_REQUEST": {
      return {
        ...state,
        isLoadingPropDeactivation: true
      };
    }
    case "AJAX_POST_DEACTIVATE_PROPERTY_SUCCESS": {
      return {
        ...state,
        isLoadingPropDeactivation: false
      };
    }
    case "AJAX_POST_DEACTIVATE_PROPERTY_ERROR": {
      return {
        ...state,
        isLoadingPropDeactivation: false
      };
    }
    default:
      return state;
  }
};

export default reducer;
