import React from "react";
import { Box, Flex, Button, ModalSmall, Text } from "concrete-ui";

const ConfirmationDeleteExpenseModal = props => {
  const { closeHandler, deleteHandler, title, children } = props;

  return (
    <>
      <Box
        position="fixed"
        top={0}
        bottom={0}
        left={0}
        right={0}
        zIndex={1000}
        bg="gray.1"
        opacity="0.8"
      />
      <Box
        position="fixed"
        top={0}
        bottom={0}
        left={0}
        right={0}
        zIndex={1001}
        justifyContent="center"
        alignItems="center"
      >
        <ModalSmall
          title={title}
          secondaryButtonOnClick={closeHandler}
          secondaryButtonText="Cancel"
          primaryButtonComponent={
            <Button
              variant="danger"
              size="large"
              type="button"
              onClick={deleteHandler}
            >
              Delete
            </Button>
          }
          children={children}
          closeButtonOnClick={closeHandler}
        />
      </Box>
    </>
  );
};

export default ConfirmationDeleteExpenseModal;
