import PropTypes from "prop-types";
import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";

import { validatePassword } from "../../api/password";
import { getEmail } from "../../api/account_settings";
import AccountForm from "../account_form";
import { Button, TextInput } from "concrete-ui";
import FormField from "../form_field";
import PageAuth from "../page_auth";
import PasswordOverlay from "../password_tooltip";
import RMBTooltip from "../rmb_tooltip";
import Loader from "../loader";
import { createPassword } from "../../redux_base/actions";
import "./create_password_view.scss";
import useValidation from "../../utils/validator_hook";
import { configCreatePassword } from "./validators";
import { concreteErrorVariant, concreteSuccessVariant } from "../../constants";

const TYPING_TIMEOUT = 300;

export const CreatePasswordView = ({
  isFetching,
  hash,
  rules,
  back_link = "/",
  dispatch,
  match
}) => {
  const [state, setState] = useState({
    isCreateForm: true,
    email: ""
  });

  const timeoutRef = useRef(null);

  const initialValues = {
    password_1: "",
    password_2: "",
    email: ""
  };

  const steps = [
    { name: "Set Password", isActive: true },
    { name: "Complete Account" }
  ];

  useEffect(() => {
    const initFetch = async () => {
      const {
        params: { uid, token }
      } = match;
      const user_id = hash;
      const userId = user_id ? hash : uid;

      if (uid && token) {
        setState(prevState => ({ ...prevState, isCreateForm: false }));
      }
      try {
        const email = await getEmail(userId);
        setState(prevState => ({ ...prevState, email }));
      } catch (error) {
        console.log(error);
      }
    };

    initFetch();
  }, []);

  const onSubmit = values => {
    const {
      params: { uid, token }
    } = match;

    if (state.isCreateForm) {
      dispatch(
        createPassword.setPassword({
          hash: hash,
          data: {
            password: values.password_1
          }
        })
      );
    } else {
      dispatch(
        createPassword.resetPassword({
          email: state.email,
          uid: uid,
          token: token,
          new_password1: values.password_1,
          new_password2: values.password_2
        })
      );
    }
  };

  const validate = values => {
    clearTimeout(timeoutRef.current);
    if (!values.password_1) {
      return;
    }
    const userId = hash ? hash : match.params.uid;
    return new Promise(resolve => {
      timeoutRef.current = setTimeout(() => resolve(), TYPING_TIMEOUT);
    })
      .then(() => validatePassword(values.password_1, userId))
      .then(errors => {
        if (Object.keys(errors).length) {
          throw {
            password_1: errors
          };
        }
      });
  };

  const {
    touched,
    formItems,
    errors,
    getFormProps,
    getItemProps
  } = useValidation(configCreatePassword, initialValues, onSubmit, values =>
    validate(values)
  );

  const titlePrefix = state.isCreateForm
    ? "Set your password"
    : "Reset my password";
  const subtitlePrefix = state.isCreateForm
    ? "Enter a password to gain access to your account."
    : "Enter your new password  to regain  entry.";

  return (
    <PageAuth backLink={back_link}>
      <Loader isVisible={isFetching} />
      <div className="create-password">
        <AccountForm
          steps={state.isCreateForm ? steps : null}
          title={titlePrefix}
          subtitle={subtitlePrefix}
        >
          <form method="post" {...getFormProps()}>
            <div className={AccountForm.fieldClass}>
              <TextInput
                color="highlight"
                size="large"
                label="Your Email"
                type="input"
                name="email"
                value={state.email}
                disabled
              />
            </div>
            <div className={AccountForm.fieldClass}>
              <RMBTooltip
                theme="highlight"
                trigger={["focus"]}
                overlay={
                  <PasswordOverlay
                    rules={rules}
                    password={formItems.password_1}
                    errors={errors.password_1}
                    theme="highlight"
                  />
                }
              >
                <TextInput
                  label="Password"
                  message={errors["confirm_password"]}
                  color="highlight"
                  size="large"
                  variant={
                    (!errors.password_1 &&
                      touched.password_1 &&
                      concreteSuccessVariant) ||
                    (!!errors.password_1 && concreteErrorVariant) ||
                    "default"
                  }
                  type="password"
                  showPasswordOption={true}
                  {...getItemProps("password_1")}
                />
              </RMBTooltip>
            </div>
            <div className={AccountForm.fieldClass}>
              <TextInput
                size="large"
                color="highlight"
                label="Confirm Password"
                variant={
                  (!errors.password_2 && touched.password_2 && "success") ||
                  (!!errors.password_2 && concreteErrorVariant) ||
                  "default"
                }
                type="password"
                showPasswordOption={true}
                message={errors["confirm_password"]}
                {...getItemProps("password_2")}
              />
            </div>
            <Button
              variant="primary"
              size="large"
              disabled={Object.keys(errors).length}
              fullWidth={true}
              type="submit"
              mt={5}
            >
              Set Password
            </Button>
          </form>
        </AccountForm>
      </div>
    </PageAuth>
  );
};

CreatePasswordView.propTypes = {
  isFetching: PropTypes.bool,
  hash: PropTypes.string,
  rules: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      key: PropTypes.string.isRequired
    })
  ),
  back_link: PropTypes.string,
  validate: PropTypes.func,
  match: PropTypes.object
};

const mapState = state => ({
  ...state.createPassword
});

export default connect(mapState)(CreatePasswordView);
