const initialState = {
  expenses: [],
  actual_expenses: [],
  vendors: [],
  isPostSuccessful: false,
  isPosting: false,
  properties: [],
  fetchingExpenses: false,
  fetchingProperties: false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "EXPENSES_UPDATE_STATE": {
      return {
        ...state,
        ...action.state
      };
    }
    // get expense
    case "AJAX_GET_EXPENSES_REQUEST":
      return {
        ...state,
        fetchingExpenses: true
      };
    case "AJAX_GET_EXPENSES_SUCCESS":
      return {
        ...state,
        expenses: action.payload,
        fetchingExpenses: false
      };
    case "AJAX_GET_EXPENSES_ERROR":
      return {
        ...state,
        expenses: [],
        error: action.payload,
        fetchingExpenses: false
      };
    // get vendors
    case "AJAX_GET_VENDORS_SUCCESS":
      return {
        ...state,
        vendors: action.payload
      };
    case "AJAX_GET_VENDORS_ERROR":
      return {
        ...state,
        vendors: [],
        error: action.payload
      };

    // get properties

    case "AJAX_GET_EXPENSE_PROPERTIES_REQUEST": {
      return {
        ...state,
        fetchingProperties: true
      };
    }
    case "AJAX_GET_EXPENSE_PROPERTIES_SUCCESS":
      return {
        ...state,
        properties: action.payload.properties,
        fetchingProperties: false
      };
    case "AJAX_GET_EXPENSE_PROPERTIES_ERROR":
      return {
        ...state,
        properties: [],
        error: action.payload,
        fetchingProperties: false
      };

    // post expense

    case "AJAX_POST_EXPENSE_REQUEST": {
      return {
        ...state,
        isPosting: true
      };
    }

    case "AJAX_POST_EXPENSE_SUCCESS": {
      return {
        ...state,
        actual_expenses: [],
        vendors: [],
        isPosting: false,
        isPostSuccessful: true
      };
    }

    case "AJAX_POST_EXPENSE_ERROR": {
      return {
        ...state,
        isPosting: false
      };
    }

    // delete expense
    case "AJAX_DELETE_DELETE_EXPENSE_SUCCESS": {
      const expense_id = action.payload.expense_id;
      const new_expenses = state.expenses.filter(
        expense => expense.id !== expense_id
      );
      return {
        ...state,
        expenses: new_expenses
      };
    }

    default:
      return state;
  }
};

export default reducer;
