export const RANKINGS_NO_ITEMS_STRING =
  "There aren’t enough sources available to rank during this date range. Consider expanding the date range.";

export const EXPOSURE_RANKINGS_NO_EXPOSED_UNITS =
  "Congratulations! There are no exposed units to rank against.";

export const EXPOSED_RANKINGS_NO_ITEMS =
  "Sorry, none of your Sources have generated leases for your most exposed unit types during this date range.";

export const RANKINGS_TOP_SOURCES_STRINGS = {
  title: "Top 3 Sources Overall",
  tooltip:
    "Based on Source performance across volumes, conversion rates, cost pers, and revenue and ROI during the date range selected.",
  noItems: RANKINGS_NO_ITEMS_STRING
};

export const RANKINGS_BOTTOM_SOURCES_STRINGS = {
  title: "Bottom 3 Sources Overall",
  tooltip:
    "Based on Source performance across volumes, conversion rates, cost pers, and revenue and ROI during the date range selected.",
  noItems: RANKINGS_NO_ITEMS_STRING
};

export const RANKINGS_EXPOSURE_TOP_SOURCES_STRINGS = {
  title: "Best Sources for Exposure",
  tooltip:
    "Based on the most currently exposed unit types and the Sources with the highest number of new leases associated with those unit types.",
  message: {
    no_leases: EXPOSED_RANKINGS_NO_ITEMS,
    no_exposed_units: EXPOSURE_RANKINGS_NO_EXPOSED_UNITS
  }
};
