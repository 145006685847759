import React from "react";
import PropTypes from "prop-types";
import _isEmpty from "lodash/isEmpty";
import Container from "../container";
import InsightsButton from "../details_button/insights_button";
import PropertyGroupTable from "../property_group_table";
import RenderIf from "../render_if";
import TabNavigator from "../tab_navigator";
import ToggleButton from "../toggle_button";
import FunnelAnalysis from "../funnel_analysis";

import { Filters } from "./filters";
import { PropertyGroupPerformanceWidgets } from "./widgets";
import "./property_group_page.scss";

export class PropertyGroupPage extends React.PureComponent {
  static propTypes = {
    date_selection: PropTypes.shape({
      preset: PropTypes.string.isRequired,
      start_date: PropTypes.instanceOf(Date).isRequired,
      end_date: PropTypes.instanceOf(Date).isRequired
    }),
    kpi_order_performance: PropTypes.array,
    table_data: PropTypes.arrayOf(PropTypes.object),
    table_summary: PropTypes.object,
    selected_property_group: PropTypes.object,
    loadingProperties: PropTypes.bool,
    loadingWidget: PropTypes.bool,
    new_widget_data: PropTypes.object,
    tabId: PropTypes.string,
    onChangeDateRange: PropTypes.func,
    onChangeFilters: PropTypes.func,
    onChangeTab: PropTypes.func,
    filters: PropTypes.object,
    owners: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        public_id: PropTypes.string.isRequired
      })
    ),
    cities: PropTypes.array,
    states: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired
      })
    ),
    lease_stages: PropTypes.array,
    classes: PropTypes.array,
    categories: PropTypes.array,
    styles: PropTypes.array,
    tags: PropTypes.array
  };

  static defaultProps = {
    selected_property_group: {},
    owners: []
  };

  state = {
    isInsightsActive: false,
    selectedIndex: 0,
    viewType: "grid",
    funnelAnalysisTab: "volumes"
  };

  componentDidMount() {
    if (this.props.tabId === "funnel") {
      this.setState({ selectedIndex: 1 });
    }
  }

  componentDidUpdate() {
    if (this.props.tabId === "funnel") {
      this.setState({ selectedIndex: 1 });
    } else if (this.props.tabId === "performance") {
      this.setState({ selectedIndex: 0 });
    }
  }

  get selectedProperty() {
    return {
      label: this.props.selected_property_group?.group_name,
      value: this.props.selected_property_group?.group_id
    };
  }

  onChangeDateRange = (preset, startDate, endDate) => {
    const { start_date, end_date } = this.props.date_selection;
    if (start_date === startDate && end_date === endDate) {
      return;
    }
    const date_selection = {
      preset: preset,
      start_date: startDate,
      end_date: endDate
    };
    this.props.onChangeDateRange(date_selection);
  };

  toggleInsights = () => {
    const isInsightsActive = !this.state.isInsightsActive;
    this.setState({ isInsightsActive });
  };

  onApplyFilterHandler = filters => {
    this.props.onChangeFilters(filters);
  };

  onChangeView = viewType => {
    this.setState({ viewType });
  };

  onChangeTabHandler = selectedIndex => {
    this.setState({ selectedIndex }, () => {
      let tabId = "performance";
      if (selectedIndex === 1) {
        tabId = "funnel";
      }
      this.props.onChangeTab(tabId);
    });
  };

  selectFunnelAnalysisTab = tab => {
    this.setState({ funnelAnalysisTab: tab });
  };

  get accountName() {
    if (!_isEmpty(this.props.selected_property_group)) {
      if (this.props.selected_property_group.account_name) {
        return `${this.props.selected_property_group.account_name}`;
      } else {
        return null;
      }
    } else {
      return "Account Name";
    }
  }

  get propertyGroupName() {
    if (!_isEmpty(this.props.selected_property_group)) {
      if (this.props.selected_property_group.group_name) {
        return `${this.props.selected_property_group.group_name}`;
      } else {
        return this.props.selected_property_group.group_name;
      }
    } else {
      return "Property Group Name";
    }
  }

  get filtersComponent() {
    return (
      <Filters
        date_selection={this.props.date_selection}
        onChangeDateRange={this.onChangeDateRange}
        onApplyFilterHandler={this.onApplyFilterHandler}
        owners={this.props.owners}
        cities={this.props.cities}
        states={this.props.states}
        lease_stages={this.props.lease_stages}
        classes={this.props.classes}
        categories={this.props.categories}
        styles={this.props.styles}
        tags={this.props.tags}
        propertyCount={this.props.table_data.length}
        showPropertyCount={!this.props.loadingProperties}
        filters={this.props.filters}
      />
    );
  }

  render() {
    const {
      date_selection,
      kpi_order_performance,
      table_data,
      table_summary,
      selected_property_group,
      loadingProperties,
      loadingWidget,
      new_widget_data
    } = this.props;

    return (
      <div className="property-group">
        <div className="property__wrapper">
          <Container className="property-group__top">
            <div className="property-group__banner">
              <div className="property-group__header">
                <div className="property-group__text-info">
                  <div className="property-group__company">
                    {this.accountName}
                  </div>
                  <div className="property-group__title">
                    {this.propertyGroupName}
                  </div>
                </div>
                <InsightsButton
                  id="rmb-property-group__insights"
                  className="property__insights"
                  active={this.state.isInsightsActive}
                  disabled={this.state.viewType === "grid"}
                  onClick={this.toggleInsights}
                />
              </div>
            </div>
          </Container>
        </div>
        <TabNavigator
          className="property-group__tabs"
          withoutContainer={true}
          selectedIndex={this.state.selectedIndex}
          onChange={this.onChangeTabHandler}
        >
          <TabNavigator.Tab label="Performance">
            <div className="property-group__tab-content">
              {this.filtersComponent}
              <Container className="property-group__sub-header">
                <div className="property-group__title">Performance</div>
                <ToggleButton
                  value={this.state.viewType}
                  onChange={this.onChangeView}
                />
              </Container>

              <RenderIf condition={this.state.viewType === "grid"}>
                <Container>
                  <div className="property-group__table">
                    <PropertyGroupTable
                      data={table_data}
                      summaryData={table_summary}
                      kpi_order={kpi_order_performance}
                      date_selection={date_selection}
                      selected_property_group={selected_property_group}
                      tab_view={"Performance"}
                      loadingState={loadingProperties}
                    />
                  </div>
                </Container>
              </RenderIf>
              <RenderIf condition={this.state.viewType === "charts"}>
                <PropertyGroupPerformanceWidgets
                  date_selection={date_selection}
                  isInsightsActive={this.state.isInsightsActive}
                  selected_property_group={selected_property_group}
                  loaderVisible={loadingWidget}
                  new_widget_data={this.props.new_widget_data}
                />
              </RenderIf>
            </div>
          </TabNavigator.Tab>

          <TabNavigator.Tab label="Funnel Analysis">
            <div className="property-group__tab-content">
              {this.filtersComponent}
              <Container className="property-group__sub-header">
                <div className="property-group__title">Funnel Analysis</div>
                {this.state.funnelAnalysisTab === "volumes" ? (
                  <ToggleButton
                    value={this.state.viewType}
                    onChange={this.onChangeView}
                  />
                ) : null}
              </Container>

              <Container className="container--fixed">
                <FunnelAnalysis
                  viewType={this.state.viewType}
                  defaultTab={this.state.funnelAnalysisTab}
                  summaryData={table_summary}
                  tableData={table_data}
                  widgetData={new_widget_data}
                  dateSelection={date_selection}
                  selectedPropertyGroup={selected_property_group}
                  loadingProperties={loadingProperties}
                  loadingWidget={loadingWidget}
                  isInsightsActive={this.state.isInsightsActive}
                  onSelectTab={this.selectFunnelAnalysisTab}
                />
              </Container>
            </div>
          </TabNavigator.Tab>
        </TabNavigator>
      </div>
    );
  }
}

export default PropertyGroupPage;
