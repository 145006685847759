import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import posthog from "posthog-js";
import { Button } from "concrete-ui";
import ModalWindow from "../../components/modal_window";
import {
  BellOff,
  BellOn,
  Meatballs,
  EmailOff,
  EmailOn,
  Trash
} from "../../icons";
import checkFlag from "../../utils/posthog";
import "./alert_mini_settings.scss";

class AlertMiniSettings extends React.PureComponent {
  static propTypes = {
    alertId: PropTypes.string,
    eventId: PropTypes.string,
    email: PropTypes.bool,
    notification: PropTypes.bool,
    toggleSubscription: PropTypes.func,
    onDelete: PropTypes.func
  };

  static defaultProps = {
    alertId: "",
    email: false,
    enabled: false,
    toggleSubscription: () => {},
    onDelete: () => {}
  };

  constructor(props) {
    super(props);
    this.wrapperRef = React.createRef();
  }

  state = {
    modalIsOpen: false,
    menuIsOpen: false
  };

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  onClickEmail = e => {
    e.stopPropagation();
    this.setState({ menuIsOpen: false }, () => {
      this.props.toggleSubscription(
        this.props.alertId,
        !this.props.email,
        this.props.notification
      );
    });
  };

  onClickNotification = e => {
    e.stopPropagation();
    this.setState({ menuIsOpen: false }, () => {
      this.props.toggleSubscription(
        this.props.alertId,
        this.props.email,
        !this.props.notification
      );
    });
  };

  handleClickOutside = e => {
    if (this.wrapperRef && !this.wrapperRef.current.contains(e.target)) {
      this.setState({ menuIsOpen: false });
    }
  };

  onClickDelete = e => {
    e.stopPropagation();
    this.setState({ modalIsOpen: true, menuIsOpen: false });
  };

  onCloseModal = e => {
    e.stopPropagation();
    this.setState({ modalIsOpen: false });
  };

  onDelete = e => {
    e.stopPropagation();
    this.setState({ modalIsOpen: false }, () =>
      this.props.onDelete(this.props.alertId)
    );
  };

  onOpen = () => {
    this.setState({ menuIsOpen: !this.state.menuIsOpen });
  };

  get emailMsg() {
    let msg;
    if (this.props.email) {
      msg = (
        <>
          <EmailOff className="alert-mini-settings__item-icon" />
          Turn Off Email
        </>
      );
    } else {
      msg = (
        <>
          <EmailOn className="alert-mini-settings__item-icon" />
          Turn On Email
        </>
      );
    }
    return msg;
  }

  get notificationMsg() {
    let msg;
    if (this.props.notification) {
      msg = (
        <>
          <BellOff className="alert-mini-settings__item-icon" />
          Turn Off Notifications
        </>
      );
    } else {
      msg = (
        <>
          <BellOn className="alert-mini-settings__item-icon" />
          Turn On Notifications
        </>
      );
    }
    return msg;
  }

  render() {
    const classes = cn("alert-mini-settings", {
      "alert-mini-settings--open": this.state.menuIsOpen
    });
    return (
      <div className={classes} ref={this.wrapperRef} onClick={this.onOpen}>
        <ModalWindow
          className="alert-mini-settings__modal"
          open={this.state.modalIsOpen}
          onClose={this.onCloseModal}
        >
          <ModalWindow.Head className="alert-mini-settings__modal-header">
            Delete Alert Type
          </ModalWindow.Head>
          <ModalWindow.Body className="alert-mini-settings__modal-body">
            Are you sure you want to delete this alert setting?
            <br />
            You will not receive future alerts of this type for this data set.
            <div className="alert-mini-settings__modal-buttons">
              <Button
                variant="secondary"
                size="large"
                onClick={this.onCloseModal}
              >
                Cancel
              </Button>
              <Button
                ml="5"
                variant="danger"
                size="large"
                onClick={this.onDelete}
              >
                Delete
              </Button>
            </div>
          </ModalWindow.Body>
        </ModalWindow>
        <Meatballs className="alert-mini-settings__icon" />
        <div className="alert-mini-settings__menu">
          <div className="alert-mini-settings__group">
            <div
              className="alert-mini-settings__menu-item"
              onClick={this.onClickEmail}
            >
              {this.emailMsg}
            </div>
            <div
              className="alert-mini-settings__menu-item"
              onClick={this.onClickNotification}
            >
              {this.notificationMsg}
            </div>
          </div>
          <div className="alert-mini-settings__group">
            <div
              className="alert-mini-settings__menu-item alert-mini-settings__menu-item--danger"
              onClick={this.onClickDelete}
            >
              <Trash className="alert-mini-settings__item-icon" />
              Delete Alert Type
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AlertMiniSettings;
