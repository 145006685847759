import React, { useEffect, useRef } from "react";
import { loadGoogleMapScript } from "./load_google_maps_script";

export const useAutoCompleteWidget = props => {
  const {
    ref,
    onPlaceSelected,
    countries = ["us", "ca"],
    types = ["address"],
    fields = ["address_components", "place_id"]
  } = props;

  const inputRef = useRef(null);
  const event = useRef(null);
  const autocompleteRef = useRef(null);

  useEffect(() => {
    const config = {
      componentRestrictions: { country: countries },
      fields,
      types
    };

    if (autocompleteRef.current || !inputRef.current) return;

    if (ref && !ref.current) ref.current = inputRef.current;

    const handleAutoComplete = () => {
      if (typeof google === "undefined")
        return console.error(
          "Google has not been found. Make sure google maps api key is set in ENV."
        );

      if (!google.maps.places)
        return console.error("Google maps places API must be loaded.");

      if (!inputRef.current instanceof HTMLInputElement)
        return console.error("Input ref must be HTMLInputElement.");

      autocompleteRef.current = new google.maps.places.Autocomplete(
        inputRef.current,
        config
      );

      event.current = autocompleteRef.current.addListener(
        "place_changed",
        () => {
          if (onPlaceSelected && autocompleteRef && autocompleteRef.current) {
            onPlaceSelected(
              autocompleteRef.current.getPlace(),
              inputRef.current,
              autocompleteRef.current
            );
          }
        }
      );
    };

    loadGoogleMapScript().then(() => handleAutoComplete());

    return () => (event.current ? event.current.remove() : undefined);
  }, []);

  useEffect(() => {
    if (autocompleteRef.current) {
      autocompleteRef.current.setFields(fields);
    }
  }, [fields]);

  useEffect(() => {
    if (autocompleteRef.current) {
      autocompleteRef.current.setComponentRestrictions({ country: countries });
    }
  }, [countries]);

  return {
    ref: inputRef,
    autocompleteRef
  };
};
