import React from "react";
import PropTypes from "prop-types";

import Narratives from "../narratives";
import Container from "../container";
import ErrorBoundary from "../error_boundary";
import { ChartHOC, tooltipDateFormatter } from "../property_page/widget-utils";
import { PORTFOLIO_CHART_TEXT } from "./chart_text";

export class PropertyGroupPerformanceWidgets extends React.PureComponent {
  static propTypes = {
    date_selection: PropTypes.shape({
      preset: PropTypes.string.isRequired,
      start_date: PropTypes.instanceOf(Date).isRequired,
      end_date: PropTypes.instanceOf(Date).isRequired
    }),
    loaderVisible: PropTypes.bool,
    isInsightsActive: PropTypes.bool.isRequired,
    selected_property_group: PropTypes.object
  };

  render() {
    const {
      loaderVisible,
      selected_property_group,
      isInsightsActive,
      new_widget_data
    } = this.props;
    const { start_date, end_date } = this.props.date_selection;

    return (
      <Container>
        <div className="property-group__widgets--wrapper">
          <div className="property-group__widgets three_column">
            <ErrorBoundary>
              <div>
                <ChartHOC
                  kpi="occupancy_rate"
                  newWidgetData={new_widget_data}
                  loaderState={loaderVisible}
                  yDomain={{ min: 0, max: 100 }}
                  tooltipDateFormatter={tooltipDateFormatter}
                  titleTooltipDescription={
                    PORTFOLIO_CHART_TEXT["occupancy_rate"]["tooltip"]
                  }
                  chartLabel={
                    PORTFOLIO_CHART_TEXT["occupancy_rate"]["chartLabel"]
                  }
                  dateRange={{ start: start_date, end: end_date }}
                />
                <div className="widget__container">
                  <Narratives
                    kpiId="occupied"
                    startDate={start_date}
                    endDate={end_date}
                    type="narration"
                    view="property_group"
                    isShowWidget={isInsightsActive}
                    name={selected_property_group.group_name}
                  />
                </div>
              </div>
            </ErrorBoundary>
            <ErrorBoundary>
              <div>
                <ChartHOC
                  kpi="leased_rate"
                  newWidgetData={new_widget_data}
                  loaderState={loaderVisible}
                  yDomain={{ min: 0, max: 100 }}
                  tooltipDateFormatter={tooltipDateFormatter}
                  chartLabel={PORTFOLIO_CHART_TEXT["leased_rate"]["chartLabel"]}
                  titleTooltipDescription={
                    PORTFOLIO_CHART_TEXT["leased_rate"]["tooltip"]
                  }
                  dateRange={{ start: start_date, end: end_date }}
                />
                <div className="widget__container">
                  <Narratives
                    kpiId="leased"
                    startDate={start_date}
                    endDate={end_date}
                    type="narration"
                    view="property_group"
                    isShowWidget={isInsightsActive}
                    name={selected_property_group.group_name}
                  />
                </div>
              </div>
            </ErrorBoundary>
            <ErrorBoundary>
              <div>
                <ChartHOC
                  kpi="exposure_rate"
                  newWidgetData={new_widget_data}
                  loaderState={loaderVisible}
                  tooltipDateFormatter={tooltipDateFormatter}
                  chartLabel={
                    PORTFOLIO_CHART_TEXT["exposure_rate"]["chartLabel"]
                  }
                  titleTooltipDescription={
                    PORTFOLIO_CHART_TEXT["exposure_rate"]["tooltip"]
                  }
                  dateRange={{ start: start_date, end: end_date }}
                />
                <div className="widget__container">
                  <Narratives
                    kpiId="exposure"
                    startDate={start_date}
                    endDate={end_date}
                    type="narration"
                    view="property_group"
                    isShowWidget={isInsightsActive}
                    name={selected_property_group.group_name}
                  />
                </div>
              </div>
            </ErrorBoundary>
          </div>
          <div className="property-group__widgets two_column">
            <ErrorBoundary>
              <div>
                <ChartHOC
                  kpi="inq_exe"
                  newWidgetData={new_widget_data}
                  loaderState={loaderVisible}
                  tooltipDateFormatter={tooltipDateFormatter}
                  chartLabel={PORTFOLIO_CHART_TEXT["inq_exe"]["chartLabel"]}
                  dateRange={{ start: start_date, end: end_date }}
                />
                <div className="widget__container">
                  <Narratives
                    kpiId="leads_to_lease"
                    view="property_group"
                    isShowWidget={isInsightsActive}
                    name={selected_property_group.group_name}
                  />
                </div>
              </div>
            </ErrorBoundary>
            <ErrorBoundary>
              <div>
                <ChartHOC
                  kpi="lease_cd_rate"
                  newWidgetData={new_widget_data}
                  loaderState={loaderVisible}
                  tooltipDateFormatter={tooltipDateFormatter}
                  chartLabel={
                    PORTFOLIO_CHART_TEXT["lease_cd_rate"]["chartLabel"]
                  }
                  dateRange={{ start: start_date, end: end_date }}
                />
                <div className="widget__container">
                  <Narratives
                    kpiId="cancellation_and_denial"
                    view="property_group"
                    isShowWidget={isInsightsActive}
                    name={selected_property_group.group_name}
                  />
                </div>
              </div>
            </ErrorBoundary>
          </div>
        </div>
      </Container>
    );
  }
}

export class PropertyGroupFunnelWidgets extends React.PureComponent {
  static propTypes = {
    date_selection: PropTypes.shape({
      preset: PropTypes.string.isRequired,
      start_date: PropTypes.instanceOf(Date).isRequired,
      end_date: PropTypes.instanceOf(Date).isRequired
    }),
    isInsightsActive: PropTypes.bool.isRequired,
    loaderVisible: PropTypes.bool,
    selected_property_group: PropTypes.object
  };

  render() {
    const {
      loaderVisible,
      selected_property_group,
      isInsightsActive,
      new_widget_data
    } = this.props;
    const { start_date, end_date } = this.props.date_selection;

    return (
      <div className="property-group__widgets--wrapper">
        <div className="property-group__widgets two_column">
          <ErrorBoundary>
            <div>
              <ChartHOC
                kpi="usvs"
                newWidgetData={new_widget_data}
                loaderState={loaderVisible}
                decimalPlaces={0}
                tooltipDateFormatter={tooltipDateFormatter}
                chartLabel={PORTFOLIO_CHART_TEXT["usvs"]["chartLabel"]}
                titleTooltipDescription={
                  PORTFOLIO_CHART_TEXT["usvs"]["tooltip"]
                }
                dateRange={{ start: start_date, end: end_date }}
              />
              <div className="widget__container">
                <Narratives
                  kpiId="nwv"
                  view="property_group"
                  isShowWidget={isInsightsActive}
                  name={selected_property_group.group_name}
                />
              </div>
            </div>
          </ErrorBoundary>
          <ErrorBoundary>
            <div>
              <ChartHOC
                kpi="inquiries"
                newWidgetData={new_widget_data}
                loaderState={loaderVisible}
                decimalPlaces={0}
                tooltipDateFormatter={tooltipDateFormatter}
                chartLabel={PORTFOLIO_CHART_TEXT["inquiries"]["chartLabel"]}
                titleTooltipDescription={
                  PORTFOLIO_CHART_TEXT["inquiries"]["tooltip"]
                }
                dateRange={{ start: start_date, end: end_date }}
              />
              <div className="widget__container">
                <Narratives
                  kpiId="leads"
                  view="property_group"
                  isShowWidget={isInsightsActive}
                  name={selected_property_group.group_name}
                />
              </div>
            </div>
          </ErrorBoundary>
        </div>
        <div className="property-group__widgets two_column">
          <ErrorBoundary>
            <div>
              <ChartHOC
                kpi="tours"
                decimalPlaces={0}
                newWidgetData={new_widget_data}
                loaderState={loaderVisible}
                tooltipDateFormatter={tooltipDateFormatter}
                chartLabel={PORTFOLIO_CHART_TEXT["tours"]["chartLabel"]}
                titleTooltipDescription={
                  PORTFOLIO_CHART_TEXT["tours"]["tooltip"]
                }
                dateRange={{ start: start_date, end: end_date }}
              />
              <div className="widget__container">
                <Narratives
                  kpiId="tours"
                  view="property_group"
                  isShowWidget={isInsightsActive}
                  name={selected_property_group.group_name}
                />
              </div>
            </div>
          </ErrorBoundary>
          <ErrorBoundary>
            <div>
              <ChartHOC
                kpi="lease_applications"
                newWidgetData={new_widget_data}
                loaderState={loaderVisible}
                decimalPlaces={0}
                tooltipDateFormatter={tooltipDateFormatter}
                chartLabel={
                  PORTFOLIO_CHART_TEXT["lease_applications"]["chartLabel"]
                }
                titleTooltipDescription={
                  PORTFOLIO_CHART_TEXT["lease_applications"]["tooltip"]
                }
                dateRange={{ start: start_date, end: end_date }}
              />
              <div className="widget__container">
                <Narratives
                  kpiId="lease_applications"
                  view="property_group"
                  isShowWidget={isInsightsActive}
                  name={selected_property_group.group_name}
                />
              </div>
            </div>
          </ErrorBoundary>
        </div>
        <div className="property-group__widgets two_column">
          <ErrorBoundary>
            <div>
              <ChartHOC
                kpi="approvals"
                newWidgetData={new_widget_data}
                loaderState={loaderVisible}
                decimalPlaces={0}
                tooltipDateFormatter={tooltipDateFormatter}
                chartLabel={PORTFOLIO_CHART_TEXT["approvals"]["chartLabel"]}
                titleTooltipDescription={
                  PORTFOLIO_CHART_TEXT["approvals"]["tooltip"]
                }
                dateRange={{ start: start_date, end: end_date }}
              />
              <div className="widget__container">
                <Narratives
                  kpiId="approved_leases"
                  view="property_group"
                  isShowWidget={isInsightsActive}
                  name={selected_property_group.group_name}
                />
              </div>
            </div>
          </ErrorBoundary>
          <ErrorBoundary>
            <div>
              <ChartHOC
                kpi="leases_executed"
                newWidgetData={new_widget_data}
                loaderState={loaderVisible}
                decimalPlaces={0}
                tooltipDateFormatter={tooltipDateFormatter}
                chartLabel={
                  PORTFOLIO_CHART_TEXT["leases_executed"]["chartLabel"]
                }
                titleTooltipDescription={
                  PORTFOLIO_CHART_TEXT["leases_executed"]["tooltip"]
                }
                dateRange={{ start: start_date, end: end_date }}
              />
              <div className="widget__container">
                <Narratives
                  kpiId="new_leases"
                  view="property_group"
                  isShowWidget={isInsightsActive}
                  name={selected_property_group.group_name}
                />
              </div>
            </div>
          </ErrorBoundary>
        </div>
      </div>
    );
  }
}
