import React, { useRef, useEffect } from "react";
import qs from "qs";
import _isBoolean from "lodash/isBoolean";
import _isEmpty from "lodash/isEmpty";
import _isFunction from "lodash/isFunction";
import _isNumber from "lodash/isNumber";
import _mapValues from "lodash/mapValues";

import {
  differenceInCalendarDays,
  differenceInCalendarMonths,
  differenceInCalendarWeeks,
  differenceInCalendarYears,
  differenceInHours,
  differenceInMilliseconds,
  differenceInMinutes,
  differenceInSeconds
} from "./dates";

export const convertToKebabCase = (string = "") => {
  return string.replace(/\s+/g, "-").toLowerCase();
};

export const convertToSnakeCase = (string = "") => {
  return string.replace(/\s+/g, "_").toLowerCase();
};

export const convertToMeter = (distance, unit) => {
  if (unit === "mi") {
    return distance * 1609.34;
  }
  if (unit === "km") {
    return distance * 1000;
  }
  return distance;
};

/*
 * Explanation of *calendar* thing
 *
 * from: 11 March 2019 23:00:00
 *   to: 12 March 2019 00:00:00
 *
 * differenceInCalendarDays(to, from) => 1
 * differenceInDays(to, from) => 0
 */
export const getDateDiff = (startDateStr, endDateStr, unit = "month") => {
  const startDate = new Date(startDateStr);
  const endDate = new Date(endDateStr);

  const diffFunctionsMap = {
    millisecond: differenceInMilliseconds,
    second: differenceInSeconds,
    minute: differenceInMinutes,
    hour: differenceInHours,
    day: differenceInCalendarDays,
    week: differenceInCalendarWeeks,
    month: differenceInCalendarMonths,
    year: differenceInCalendarYears
  };

  const differ = diffFunctionsMap[unit] || differenceInMilliseconds;

  return differ(endDate, startDate);
};

export const getDefaultDirection = value => {
  if (value > 0) {
    return 1;
  }
  if (value < 0) {
    return -1;
  }
  return 0;
};

export const getPercentageDirection = value =>
  Math.sign(Math.round(value * 1000));

export const objectFromEntries = iterable => {
  return [...iterable].reduce(
    (obj, { 0: key, 1: val }) => Object.assign(obj, { [key]: val }),
    {}
  );
};

/*
 * Converts backend errors to show them on UI
 */
export function convertBackendErrors(errors) {
  return _mapValues(errors, item => item[0].message);
}

/*
 * Shallow wrapper of "qs" plugin's parse() / stringify() methods
 */
export const qsParse = (queryString, hasLeadingPrefix = true) => {
  return qs.parse(queryString, { ignoreQueryPrefix: hasLeadingPrefix });
};

export const qsStringify = (
  queryParams,
  appendLeadingPrefix = true,
  arrayFormat = "repeat" // "a=1&a=2" instead of "a[0]=1&a[1]=2"
) => {
  return qs.stringify(queryParams, {
    addQueryPrefix: appendLeadingPrefix,
    arrayFormat: arrayFormat
  });
};

/*
 * Strips protocol part and end slash from url string
 */
export const stripURL = url => {
  const r = /^(\w+:\/\/)(www\.)?/i;
  return url.replace(r, "").replace(/\/$/, "");
};

/*
 * Returns true if parameter is valid url
 */
export const isValidURL = string => {
  var res =
    string &&
    string.match(
      /^(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/g
    );
  return res !== null;
};

/*
 * Returns true if parameter is valid url with required HTTP or HTTPS prefix
 */
export const isValidHTTPURL = string => {
  try {
    const url = new URL(string);
    return url.protocol === "http:" || url.protocol === "https:";
  } catch (_) {
    return false;
  }
};

/*
 * Checks if all values in object are not blank
 */
export const isNotBlankValues = obj => {
  if (_isEmpty(obj)) {
    return false;
  }
  for (let v of Object.values(obj)) {
    if (!_isBoolean(v) && !_isNumber(v)) {
      if (_isEmpty(v)) {
        return false;
      }
    }
  }
  return true;
};

/**
 * If parameter is a function then returns its result of a call
 * otherwise just return parameter.
 * @param v
 * @returns {*}
 */
export const callIfFunction = v => {
  return _isFunction(v) ? v() : v;
};

/**
 * Returns name of component if it has one or "Component" by default
 * @param WrappedComponent
 * @returns {*|string}
 */
export const getDisplayName = WrappedComponent =>
  WrappedComponent.displayName || WrappedComponent.name || "Component";

export const usePrevious = value => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

/**
 * Returns parsed payload from jwt string
 * @param jwt {string}
 * @returns {Object}
 */
export const getPayloadFromJWT = jwt => {
  const payloadStr = jwt.split(".")[1];
  return JSON.parse(atob(payloadStr));
};

/**
 * Returns parsed JSON object with NaN and Infinity values from JSON string
 * @param data {string}
 * @returns {Object}
 */
export const parseNaNsFromString = data => {
  let response;

  try {
    response = JSON.parse(data, (_, value) => {
      if (value === "Infinity") {
        return Infinity;
      } else if (value === "-Infinity") {
        return -Infinity;
      }

      return value;
    });
  } catch (error) {
    throw Error(
      `Error parsing NaNs from JSON string - ${JSON.stringify(error)}`
    );
  }

  return response;
};

/**
 * Checks if current enviroments is a browser
 * @returns {boolean}
 */
export const isBrowser = typeof window !== "undefined" && window.document;
