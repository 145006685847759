import React from "react";
import PropTypes from "prop-types";
import _find from "lodash/find";

import BackfillModal from "../backfill_modal";
import EnhancedLink from "./../enhanced_link";
import AdminBreadcrumb from "../admin_breadcrumb";
import Breadcrumbs from "../breadcrumbs";
import { Container, Flex, Icon, Text, Box, Table, Button } from "concrete-ui";
import TabNavigator from "../tab_navigator";
import adminConnectionsTabs from "../../containers/admin_connections/tabs";
import adminConnectionsColumns from "../../containers/admin_connections/columns";
import { formatDateWithTokens } from "../../utils/formatters";

import "./admin_connections.scss";

export default class AdminConnections extends React.Component {
  static propTypes = {
    pmsLoading: PropTypes.bool,
    pmsData: PropTypes.arrayOf(PropTypes.object).isRequired,
    dataConnectionsLoading: PropTypes.bool,
    dataConnections: PropTypes.arrayOf(PropTypes.object).isRequired,
    selectedIndex: PropTypes.number,
    onChangeTab: PropTypes.func,
    onEditPMS: PropTypes.func,
    onDeletePMS: PropTypes.func,
    onEditConnection: PropTypes.func,
    onDeleteConnection: PropTypes.func,
    onTestConnection: PropTypes.func,
    onRefreshDataConnections: PropTypes.func.isRequired,
    onBackfill: PropTypes.func.isRequired
  };

  static defaultProps = {
    onChangeTab() {},
    selectedIndex: 0,
    onEditPMS: () => {},
    onDeletePMS: () => {},
    onEditConnection: () => {},
    onBackfill: () => {},
    onDeleteConnection: () => {},
    onRefreshDataConnections: () => {}
  };

  constructor(props) {
    super(props);
    this.state = {
      selectedConnection: null
    };
  }

  get pmsMiniMenuProps() {
    return {
      onEdit: this.props.onEditPMS,
      onDelete: this.props.onDeletePMS
    };
  }

  get connectionMiniMenuProps() {
    return {
      onEdit: this.props.onEditConnection,
      onDelete: this.props.onDeleteConnection
    };
  }

  get statusDropdownProps() {
    return {
      onTestConnection: this.props.onTestConnection,
      onBackfill: this.onOpenBackfillModal
    };
  }

  get ConnectionStatusCustomHeader() {
    return ({ textProps }) => (
      <>
        <Icon.Refresh
          ml={3}
          size={3}
          mr={2}
          $color="blue.1"
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            this.props.onRefreshDataConnections();
          }}
        />
        <Text {...textProps}>Connection Status</Text>
      </>
    );
  }

  onOpenBackfillModal = (projectId, integrationSource) => {
    this.setState({
      selectedConnection: {
        public_id: projectId,
        integration_source: integrationSource
      }
    });
  };

  onCloseBackfillModal = () => {
    this.setState({
      selectedConnection: null
    });
  };

  onBackfill = (projectId, integrationSource, startDate, endDate) => {
    this.setState({
      selectedConnection: null
    });

    this.props.onBackfill(projectId, integrationSource, startDate, endDate);
  };

  onChangeTabHandler = selectedIndex => {
    this.props.onChangeTab(selectedIndex);
  };

  getPMSCsvFilename = () => {
    let today = new Date();
    today = formatDateWithTokens(today, "yyyy-MM-dd");
    return `Remarkably_PMS_${today}`;
  };

  getPropertyConnectionCsvFilename = () => {
    let today = new Date();
    today = formatDateWithTokens(today, "yyyy-MM-dd");
    return `Remarkably_Property_Connection_${today}`;
  };

  render() {
    const breadcrumbs = [
      { text: <AdminBreadcrumb />, link: "" },
      { text: "Connections", link: "" }
    ];
    const tableColumns = adminConnectionsColumns(
      this.props.selectedIndex,
      this.pmsMiniMenuProps,
      this.connectionMiniMenuProps,
      this.statusDropdownProps,
      this.ConnectionStatusCustomHeader
    );

    return (
      <>
        <Box display="block" mb="6rem">
          <Container>
            <Flex alignItems="center" height={13}>
              <Breadcrumbs
                breadcrumbs={breadcrumbs}
                containerProps={{ variant: "fullwidth", borderBottom: "none" }}
              />
              <EnhancedLink
                to={adminConnectionsTabs[this.props.selectedIndex]["pageLink"]}
              >
                <Button
                  textDecoration="none"
                  variant="secondary"
                  size="small"
                  ml={5}
                >
                  <Icon.Plus $color="text.white.1" />
                  {
                    adminConnectionsTabs[this.props.selectedIndex][
                      "addButtonText"
                    ]
                  }
                </Button>
              </EnhancedLink>
              <Flex ml="auto" mr={0}>
                <EnhancedLink to="/">
                  <Text
                    textSize={2}
                    $fontWeight="regular"
                    $color="text.white.1"
                    mr={2}
                  >
                    Go to my Portfolio
                  </Text>
                  <Icon.ArrowRight size={3} $color="text.white.1" />
                </EnhancedLink>
              </Flex>
            </Flex>
          </Container>
          <TabNavigator
            className="admin-connections-tabs"
            selectedIndex={this.props.selectedIndex}
            onChange={this.onChangeTabHandler}
          >
            <TabNavigator.Tab
              label={<Text textSize={3}>{adminConnectionsTabs[0].label}</Text>}
            >
              <Table
                mt={5}
                loading={this.props.pmsLoading}
                data={this.props.pmsData}
                columns={tableColumns["tableColumns"]}
                hiddenColumns={tableColumns["hiddenColumns"]}
                initialSortBy={[{ id: "last_updated", desc: true }]}
                pageSizes={[
                  { label: "10", value: 10 },
                  { label: "20", value: 20 },
                  { label: "All", value: "all" }
                ]}
                csvFileName={this.getPMSCsvFilename()}
              />
            </TabNavigator.Tab>
            <TabNavigator.Tab
              label={<Text textSize={3}>{adminConnectionsTabs[1].label}</Text>}
            >
              <Table
                mt={5}
                loading={this.props.dataConnectionsLoading}
                data={this.props.dataConnections}
                columns={tableColumns["tableColumns"]}
                hiddenColumns={tableColumns["hiddenColumns"]}
                initialSortBy={[{ id: "last_updated", desc: true }]}
                pageSizes={[
                  { label: "10", value: 10 },
                  { label: "20", value: 20 },
                  { label: "All", value: "all" }
                ]}
                csvFileName={this.getPropertyConnectionCsvFilename()}
                autoResetPage={false}
                autoResetSortBy={false}
                autoResetRowState={false}
              />
            </TabNavigator.Tab>
          </TabNavigator>
        </Box>
        {this.state.selectedConnection && (
          <BackfillModal
            connectionData={_find(
              this.props.dataConnections,
              this.state.selectedConnection
            )}
            onClose={this.onCloseBackfillModal}
            onBackfill={this.onBackfill}
          />
        )}
      </>
    );
  }
}
