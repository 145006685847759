import React from "react";
import PropTypes from "prop-types";
import {
  Button,
  Box,
  Checkbox,
  Divider,
  Flex,
  Icon,
  Text,
  TextInput,
  Tooltip
} from "concrete-ui";
import _isEmpty from "lodash/isEmpty";

import RenderIf from "../render_if";
import ReferralSourcesSave from "../referral_sources_save";
import {
  MATCHING_NWV_ALREADY_DISTRIBUTED,
  MATCHING_NWV_ALREADY_MATCHED
} from "../referral_sources/tooltips";
import { isValidURL } from "../../utils/misc";

const SelectAllCheckbox = ({ name, value, onChange }) => {
  return (
    <Flex pb={2}>
      <Checkbox
        label="Select All"
        name={name}
        value={value}
        onChange={onChange}
        py={0}
      >
        <Text
          textSize={2}
          ml={2}
          mr={1}
          $fontWeight="regular"
          $color="text.gray.1"
        >
          Select All
        </Text>
      </Checkbox>
    </Flex>
  );
};

class ReferralSourcesNWV extends React.PureComponent {
  static nwvType = PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
      value: PropTypes.object,
      checked: PropTypes.bool,
      distributed: PropTypes.bool,
      matched: PropTypes.bool,
      matchedToDifferentLead: PropTypes.bool
    })
  );

  static propTypes = {
    sources: ReferralSourcesNWV.nwvType,
    headers: PropTypes.object,
    isSelectLeadSource: PropTypes.bool,
    matchedOnTop: PropTypes.bool,
    unbouncedVisitorsOnly: PropTypes.bool,
    saveCompleted: PropTypes.bool,
    onSave: PropTypes.func
  };

  static defaultProps = {
    sources: [],
    headers: {},
    isSelectLeadSource: false,
    matchedOnTop: true,
    unbouncedVisitorsOnly: false,
    onSave: () => {}
  };

  _getMatchedSourcesIds = () => {
    const matchedSources = this.props.sources.filter(
      s => !(s.matchedToDifferentLead || s.distributed) && s.checked
    );
    matchedSources.sort(
      (s1, s2) => +!!s1.matchedToDifferentLead - +!!s2.matchedToDifferentLead
    );
    return matchedSources.map(s => s.id);
  };

  _filterSourcesObjs = (sourcesObj, sourceIds) => {
    return sourceIds.reduce(
      (sources, sId) => [...sources, sourcesObj[sId]],
      []
    );
  };

  _filterSourcesToUnmatch = sources =>
    sources.filter(
      s => !s.distributed && !s.checked && !s.matchedToDifferentLead
    );

  _getUnMatchedSourcesIds = () =>
    this._filterSourcesToUnmatch(this.props.sources).map(s => s.id);

  _getDisabledTooltipMessage = source => {
    if (source.matchedToDifferentLead) {
      return MATCHING_NWV_ALREADY_MATCHED;
    } else if (source.distributed) {
      return MATCHING_NWV_ALREADY_DISTRIBUTED;
    }
    return "";
  };

  _unSelectSourcesForIds = ids => {
    const idsSet = new Set(ids);
    const sourcesValues = Object.values(this.state.sourcesObj);
    return sourcesValues.reduce(
      (sObj, s) => ({
        ...sObj,
        [s.id]: {
          ...s,
          checked: idsSet.has(s.id) ? false : s.checked
        }
      }),
      {}
    );
  };

  _selectSourcesForIds = ids => {
    const idsSet = new Set(ids);
    const sourcesValues = Object.values(this.state.sourcesObj);
    return sourcesValues.reduce(
      (sObj, s) => ({
        ...sObj,
        [s.id]: {
          ...s,
          checked: idsSet.has(s.id) ? true : s.checked
        }
      }),
      {}
    );
  };

  state = {
    searchStr: "",
    searchResult: [],
    sourcesObj: this.props.sources.reduce(
      (acc, s) => ({ ...acc, [s.id]: { ...s } }),
      {}
    ),
    matchedSources: this._getMatchedSourcesIds(),
    unmatchedSources: this._getUnMatchedSourcesIds(),
    selectAll: this._filterSourcesToUnmatch(this.props.sources).every(
      s => s.checked
    ),
    selectMatchedAll: this._getMatchedSourcesIds().length > 0,
    selectSearchAll: false
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    let newState = {};
    if (prevProps.sources !== this.props.sources) {
      newState.searchStr = "";
      newState.searchResult = [];
      newState.sourcesObj = this.props.sources.reduce(
        (acc, s) => ({ ...acc, [s.id]: { ...s } }),
        {}
      );
      newState.matchedSources = this._getMatchedSourcesIds();
      newState.unmatchedSources = this._getUnMatchedSourcesIds();
      newState.selectAll = this._filterSourcesToUnmatch(
        this.props.sources
      ).every(s => s.checked);
      newState.selectMatchedAll = this._getMatchedSourcesIds().length > 0;
    }
    if (!_isEmpty(newState)) {
      this.setState(newState);
    }
  }

  renderWebsiteSources = () => {
    let content;
    if (this.state.searchStr && !this.state.searchResult.length) {
      content = this.renderNoResult();
    } else if (this.props.matchedOnTop) {
      content = this.renderMatchedSourcesTop();
    } else {
      content = this.renderMatchedSourcesBottom();
    }
    return content;
  };

  renderMatchedSourcesTop = () => {
    if (this.state.searchResult.length) {
      return this.renderSearchSources(this.state.searchResult);
    }
    const matchedSourcesDisplayList = this.getSourcesToDisplay(
      this.state.matchedSources
    );
    const unmatchedSourcesDisplayList = this.getSourcesToDisplay(
      this.state.unmatchedSources
    );
    return (
      <>
        <RenderIf condition={!!this.state.matchedSources.length}>
          <Text pt={4} textSize={2} $fontWeight="regular" $color="text.gray.1">
            {this.props.headers.selected}
          </Text>
          <SelectAllCheckbox
            name="SelectMatchedAll"
            value={this.state.selectMatchedAll}
            onChange={this.toggleSelectMatchedAll}
          />
          {this.renderList(matchedSourcesDisplayList)}
        </RenderIf>
        <RenderIf
          condition={
            matchedSourcesDisplayList.length &&
            unmatchedSourcesDisplayList.length
          }
        >
          <Flex mx={3} pt={2}>
            <Divider variant="horizontal" bg="gray.4" flexShrink={0} />
          </Flex>
        </RenderIf>
        <RenderIf condition={!!unmatchedSourcesDisplayList.length}>
          <Text pt={2} textSize={2} $fontWeight="regular" $color="text.gray.1">
            {this.props.headers.unselected}
          </Text>
          <SelectAllCheckbox
            name="SelectAll"
            value={this.state.selectAll}
            onChange={this.toggleSelectAll}
          />
          {this.renderList(unmatchedSourcesDisplayList)}
        </RenderIf>
      </>
    );
  };

  renderMatchedSourcesBottom = () => {
    if (this.state.searchResult.length) {
      return this.renderSearchSources(this.state.searchResult);
    }
    const matchedSourcesDisplayList = this.getSourcesToDisplay(
      this.state.matchedSources
    );
    const unmatchedSourcesDisplayList = this.getSourcesToDisplay(
      this.state.unmatchedSources
    );
    return (
      <>
        <RenderIf condition={!!this.state.unmatchedSources.length}>
          <Text pt={5} textSize={2} $fontWeight="regular" $color="text.gray.1">
            {this.props.headers.unselected}
          </Text>
          <SelectAllCheckbox
            name="SelectAll"
            value={this.state.selectAll}
            onChange={this.toggleSelectAll}
          />
          {this.renderList(unmatchedSourcesDisplayList)}
        </RenderIf>
        <RenderIf
          condition={
            matchedSourcesDisplayList.length &&
            unmatchedSourcesDisplayList.length
          }
        >
          <Flex mx={3} pt={2}>
            <Divider variant="horizontal" bg="gray.4" flexShrink={0} />
          </Flex>
        </RenderIf>
        <RenderIf condition={!!matchedSourcesDisplayList.length}>
          <Text pt={5} textSize={2} $fontWeight="regular" $color="text.gray.1">
            {this.props.headers.selected}
          </Text>
          <SelectAllCheckbox
            name="SelectMatchedAll"
            value={this.state.selectMatchedAll}
            onChange={this.toggleSelectMatchedAll}
          />
          {this.renderList(matchedSourcesDisplayList)}
        </RenderIf>
      </>
    );
  };

  renderSearchSources = () => {
    const searchSourcesDisplayList = this.getSourcesToDisplay(
      this.state.searchResult
    );
    return (
      <>
        <SelectAllCheckbox
          name="SelectSearchAll"
          value={this.state.selectSearchAll}
          onChange={this.toggleSelectSearchAll}
        />
        {this.renderList(searchSourcesDisplayList)}
      </>
    );
  };

  getSourcesToDisplay = sourceIds => {
    let availableSources = sourceIds.filter(sourceId => {
      const s = this.state.sourcesObj[sourceId];
      const disabled = s.matchedToDifferentLead || s.distributed;
      if (!disabled) {
        return sourceId;
      }
    });
    return availableSources;
  };

  renderList = sourcesIds =>
    sourcesIds.map(sId => {
      const s = this.state.sourcesObj[sId];
      const checked = (s.checked && !s.distributed) || s.matchedToDifferentLead;
      const disabled = s.matchedToDifferentLead || s.distributed;

      let checkbox = (
        <Checkbox
          label={s.label}
          name={s.id}
          value={checked}
          disabled={disabled}
          onChange={() => this.toggleSource(s.id)}
          py={0}
        />
      );
      if (s.matchedToDifferentLead || s.distributed) {
        const title = this._getDisabledTooltipMessage(s);
        checkbox = (
          <Tooltip title={title} size="small" placement="right">
            {checkbox}
          </Tooltip>
        );
      } else if (isValidURL(s?.value?.source_name)) {
        checkbox = (
          <Tooltip title={s.id} size="mini">
            {checkbox}
          </Tooltip>
        );
      }

      return (
        <div key={s.id}>
          <Flex py={0}>{checkbox}</Flex>
        </div>
      );
    });

  renderNoResult = () => (
    <Text py={5} textSize={2} $fontWeight="regular" $color="text.gray.1">
      No search results found.
    </Text>
  );

  getSearchResult = (sources, searchStr) =>
    sources
      .filter(x => x.label.toLowerCase().includes(searchStr.toLowerCase()))
      .map(s => s.id);

  get haveChanges() {
    const haveSourcesChanges = this.props.sources.some(
      s => s.checked !== this.state.sourcesObj[s.id]?.checked
    );
    return haveSourcesChanges;
  }

  onSearchHandler = e => {
    const searchStr = e.currentTarget.value;
    let searchResult = [];
    if (searchStr) {
      const sources = Object.values(this.state.sourcesObj);
      searchResult = this.getSearchResult(sources, searchStr);
    }
    this.setState({
      searchStr,
      searchResult
    });
  };

  onSearchCancelHandler = () => {
    this.setState({
      searchStr: "",
      searchResult: [],
      selectSearchAll: false
    });
  };

  onSaveHandler = unbouncedVisitorsOnly => {
    const selectedSources = Object.values(this.state.sourcesObj).filter(
      s => s.checked
    );
    this.props.onSave({
      sources: selectedSources,
      unbouncedVisitorsOnly
    });
  };

  toggleSource = id => {
    this.setState(state => {
      const sources = { ...state.sourcesObj };
      sources[id].checked = !sources[id].checked;
      return {
        sourcesObj: sources,
        selectAll: this._filterSourcesObjs(
          sources,
          this._getUnMatchedSourcesIds()
        ).every(s => s.checked),
        selectMatchedAll: this._filterSourcesObjs(
          sources,
          this._getMatchedSourcesIds()
        ).every(s => s.checked),
        selectSearchAll: this._filterSourcesObjs(
          sources,
          this.state.searchResult
        ).every(s => s.checked)
      };
    });
  };

  toggleSelectAll = () => {
    const unMatchedIds = this._getUnMatchedSourcesIds();
    if (this.state.selectAll) {
      this.setState({
        selectAll: false,
        selectSearchAll: false,
        sourcesObj: this._unSelectSourcesForIds(unMatchedIds)
      });
    } else {
      this.setState({
        selectAll: true,
        selectSearchAll: true,
        sourcesObj: this._selectSourcesForIds(unMatchedIds)
      });
    }
  };

  toggleSelectMatchedAll = () => {
    const matchedIds = this._getMatchedSourcesIds();
    if (this.state.selectMatchedAll) {
      this.setState({
        selectMatchedAll: false,
        sourcesObj: this._unSelectSourcesForIds(matchedIds)
      });
    } else {
      this.setState({
        selectMatchedAll: true,
        sourcesObj: this._selectSourcesForIds(matchedIds)
      });
    }
  };

  toggleSelectSearchAll = () => {
    const searchIds = this.state.searchResult;
    if (this.state.selectSearchAll) {
      this.setState({
        selectSearchAll: false,
        sourcesObj: this._unSelectSourcesForIds(searchIds)
      });
    } else {
      this.setState({
        selectSearchAll: true,
        sourcesObj: this._selectSourcesForIds(searchIds)
      });
    }
  };

  toggleNoWebsiteSources = () => {
    this.setState(state => ({ _noWebsiteSources: !state._noWebsiteSources }));
  };

  _getSelectedSourcesIDs = sourcesIDs =>
    sourcesIDs.filter(id => this.state.sourcesObj[id].checked);

  render() {
    return (
      <Flex flexDirection="column" minWidth="16.5rem" width="100%">
        <Flex flexDirection="column" py={4} px={5}>
          <Flex pb={3} alignItems="center">
            <Text textSize={2} $fontWeight="medium" mr={2}>
              New Website Visitor Sources
            </Text>
            <Tooltip
              size="small"
              title="Website referral source selection options reflect property-specific, all-time referral source data from Google Analytics."
              placement="top"
            >
              <Icon.Tooltip size="1rem" color="text.white.1" />
            </Tooltip>
          </Flex>
          <Flex pb={3} alignItems="center">
            <Text textSize={2} $fontWeight="regular" $color="text.gray.2">
              Select the New Website Visitor referral sources you wish to match
              the Lead Source to.
            </Text>
          </Flex>
        </Flex>
        <Divider variant="horizontal" bg="gray.4" flexShrink={0} />
        <RenderIf condition={this.props.isSelectLeadSource}>
          <Flex pt={5} pb={5} px={5} flexDirection="column">
            <TextInput
              size="large"
              placeholder="Search"
              iconLeft={<Icon.Search color="text.white.1" size={4} />}
              iconRight={
                !!this.state.searchStr && (
                  <Button variant="icon" size="small">
                    <Icon.Close
                      color="text.gray.1"
                      size={3}
                      onClick={this.onSearchCancelHandler}
                    />
                  </Button>
                )
              }
              value={this.state.searchStr}
              onChange={this.onSearchHandler}
              icon={<Icon.Search color="text.white.1" />}
            />
          </Flex>
          <Divider variant="horizontal" bg="gray.4" flexShrink={0} />
          <Box
            className="concrete-scrollbar"
            pl={5}
            pb={1}
            flexDirection="column"
            overflow="auto"
          >
            {this.renderWebsiteSources()}
          </Box>
          <Flex mt="auto" flexDirection="column">
            <Divider variant="horizontal" bg="gray.4" flexShrink={0} />
            <ReferralSourcesSave
              haveChanges={this.haveChanges}
              checked={this.props.unbouncedVisitorsOnly}
              saveCompleted={this.props.saveCompleted}
              saveTxt="Selected sources have been matched."
              onSave={this.onSaveHandler}
            />
          </Flex>
        </RenderIf>
        <RenderIf condition={!this.props.isSelectLeadSource}>
          <Flex
            width="100%"
            height="100%"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            <Box width="14.5rem">
              <Text
                textSize={2}
                $fontWeight="regular"
                $color="text.gray.1"
                textAlign="center"
              >
                Select a Lead Source to show available website referral sources
                to match.
              </Text>
            </Box>
          </Flex>
        </RenderIf>
      </Flex>
    );
  }
}

export default ReferralSourcesNWV;
