import PropTypes from "prop-types";
import React from "react";
import { TabContainer } from "concrete-ui";
import {
  onCsvDownload,
  prepareCsvDataTabs
} from "concrete-ui/dist/blocks/Table/utils/utils";

import RenderIf from "../render_if";
import { dateFormat } from "../../utils/dates";
import PaginationTable from "../pagination_table";
import { PropertyGroupFunnelWidgets } from "../property_group_page/widgets";
import {
  allFunnelAnalysisColumns,
  funnelAnalysisTabColumns,
  getFunnelAnalysisChartsTabs,
  getFunnelAnalysisGridTabs
} from "./config";
import { TableDataGroup } from "./utils";

const FUNNEL_ANALYSIS_TABS_GETTERS = {
  grid: getFunnelAnalysisGridTabs,
  charts: getFunnelAnalysisChartsTabs
};
const DEFAULT_TAB = "volumes";

class FunnelAnalysis extends React.Component {
  static propTypes = {
    viewType: PropTypes.oneOf(["grid", "charts"]),
    defaultTab: PropTypes.oneOf([
      "volumes",
      "conversion_rates",
      "cost_pers",
      "revenue_roi",
      "units"
    ]),

    summaryData: PropTypes.object,
    tableData: PropTypes.array.isRequired,
    widgetData: PropTypes.array.isRequired,

    dateSelection: PropTypes.shape({
      start_date: PropTypes.instanceOf(Date).isRequired,
      end_date: PropTypes.instanceOf(Date).isRequired
    }).isRequired,
    selectedPropertyGroup: PropTypes.object.isRequired,
    loadingProperties: PropTypes.bool,
    loadingWidget: PropTypes.bool,
    isInsightsActive: PropTypes.bool,
    onSelectTab: PropTypes.func
  };

  static defaultProps = {
    defaultTab: DEFAULT_TAB,
    summaryData: {},
    tableData: [],
    widgetData: {},
    onSelectTab: () => {}
  };

  constructor(props) {
    super(props);
    this.state = {
      tab: props.defaultTab
    };
  }

  get tableColumns() {
    return this.state.tab
      ? funnelAnalysisTabColumns[this.state.tab]
      : funnelAnalysisTabColumns[DEFAULT_TAB];
  }

  selectTab = tab => {
    this.setState({ tab });
    this.props.onSelectTab(tab);
  };

  getCsvFileName = () => {
    const { selectedPropertyGroup, dateSelection } = this.props;
    if (!dateSelection || !selectedPropertyGroup)
      return "Remarkably_Funnel_Analysis_Report";

    const name = selectedPropertyGroup["group_name"]?.replace(/ /g, "_");
    const start = dateFormat(dateSelection["start_date"], "yyyy-MM-dd");
    const end = dateFormat(dateSelection["end_date"], "yyyy-MM-dd");

    const tabName = this.state.tab;
    return `Remarkably_${name}_Funnel_Analysis_${tabName}_${start}-${end}`;
  };

  getTabs = () => FUNNEL_ANALYSIS_TABS_GETTERS[this.props.viewType]();

  downloadCSV = () => {
    const tabs = this.getTabs();
    const dataGroup = new TableDataGroup(
      this.props.tableData,
      this.props.summaryData,
      tabs
    );
    const data = dataGroup.getAllDataByTabs();
    const csvData = prepareCsvDataTabs(tabs, allFunnelAnalysisColumns, data);
    return onCsvDownload(this.getCsvFileName(), ",", csvData, {});
  };

  render() {
    return (
      <TabContainer
        onSelect={this.selectTab}
        tabs={this.getTabs()}
        selectedTab={this.state.tab}
        tabProps={{
          borderRadius: 1,
          borderStyle: "solid",
          borderWidth: 1,
          borderColor: "gray.4",
          backgroundColor: "gray.3",
          px: 5,
          py: 0
        }}
      >
        <RenderIf condition={this.props.viewType === "grid"}>
          <PaginationTable
            tableType="sub_portfolio"
            columns={this.tableColumns}
            data={this.props.tableData}
            summaryData={this.props.summaryData}
            loadingState={this.props.loadingProperties}
            csvFileName={this.getCsvFileName()}
            onCsvDownloadTable={this.downloadCSV}
            mt={4}
          />
        </RenderIf>
        <RenderIf condition={this.props.viewType === "charts"}>
          <PropertyGroupFunnelWidgets
            date_selection={this.props.dateSelection}
            isInsightsActive={this.props.isInsightsActive}
            selected_property_group={this.props.selectedPropertyGroup}
            loaderVisible={this.props.loadingWidget}
            new_widget_data={this.props.widgetData}
          />
        </RenderIf>
      </TabContainer>
    );
  }
}

export default FunnelAnalysis;
