import React from "react";
import { __RouterContext as RouterContext } from "react-router";
import { Link } from "react-router-dom";

/**
 * An enhanced version of Link that fixes redirecting when react event
 * accidentally has `defaultPrevented == true`.
 * NOTE: This only fixes redirecting for cases when `to` is string type.
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const EnhancedLink = props => {
  const { to } = props;
  const handleClick = history => e => {
    if (e.defaultPrevented) {
      history.push(to);
    }
  };
  return (
    <RouterContext.Consumer>
      {context => <Link {...props} onClick={handleClick(context.history)} />}
    </RouterContext.Consumer>
  );
};

export default EnhancedLink;
