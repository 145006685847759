import React from "react";

import IconBase from "./icon_base";

const Calendar = props => (
  <IconBase viewBox="0 0 13 12" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.1001 1.2H10.7001C11.3601 1.2 11.9001 1.74 11.9001 2.4V10.8C11.9001 11.46 11.3601 12 10.7001 12H2.3001C1.6341 12 1.1001 11.46 1.1001 10.8L1.1031 6.6H2.3001H10.7001H11.9001V5.4H10.7001H2.3001H1.10395L1.1061 2.4C1.1061 1.74 1.6341 1.2 2.3001 1.2H2.90009V2.4H4.10009V1.2H2.9001V0H4.1001V1.2H8.90009V2.4H10.1001V1.2H8.9001V0H10.1001V1.2Z"
      fill="currentColor"
    />
  </IconBase>
);

export default Calendar;
