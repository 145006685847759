import React from "react";
import IconBase from "./icon_base";

const ChevronRight = props => (
  <IconBase viewBox="0 0 24 24" {...props}>
    <path
      d="M9 6.71a.996.996 0 000 1.41L12.88 12 9 15.88a.996.996 0 101.41 1.41L15 12.7a.996.996 0 000-1.41L10.41 6.7c-.38-.38-1.02-.38-1.41.01z"
      fill="currentColor"
    />
  </IconBase>
);

export default ChevronRight;
