import React from "react";
import { useHistory } from "react-router-dom";

import { PROPERTY_CHART_PROPS } from "../../constants";
import { _exploreHandler } from "../../utils/widget";
import Narratives from "../narratives";

import { PROPERTY_CHART_TEXT } from "./chart_text";
import { ChartHOC } from "./widget-utils";
import "./property_page.scss";

const category = "retention";
const exploreHandler = _exploreHandler(category);

const PropertyRetention = React.forwardRef((props, ref) => {
  const history = useHistory();
  const {
    date_selection,
    isShowInsights,
    propertyId,
    propertyName,
    newWidgetData,
    loaderState
  } = props;
  const exploreOnClick = exploreHandler(propertyId)(history);
  const { start_date, end_date } = date_selection;
  return (
    <div className="property__widgets-container" ref={ref}>
      <div className="property__title">Retention</div>
      <div className="property__widgets three_column">
        <div>
          <ChartHOC
            kpi="lease_renewals"
            decimalPlaces={0}
            exploreArgs={["property", "renewals"]}
            newWidgetData={newWidgetData}
            loaderState={loaderState}
            exploreOnClick={exploreOnClick}
            chartLabel={PROPERTY_CHART_TEXT["lease_renewals"]["chartLabel"]}
            titleTooltipDescription={
              PROPERTY_CHART_TEXT["lease_renewals"]["tooltip"]
            }
            dateRange={{ start: start_date, end: end_date }}
            {...PROPERTY_CHART_PROPS["lease_renewals"]}
          />

          <div className="widget__container">
            <Narratives
              kpiId="renewals"
              view="property"
              isShowWidget={isShowInsights}
              name={propertyName}
            />
          </div>
        </div>
        <div>
          <ChartHOC
            decimalPlaces={0}
            kpi="lease_vacation_notices"
            exploreArgs={["property", "notices_to_vacate"]}
            newWidgetData={newWidgetData}
            loaderState={loaderState}
            exploreOnClick={exploreOnClick}
            chartLabel={
              PROPERTY_CHART_TEXT["lease_vacation_notices"]["chartLabel"]
            }
            titleTooltipDescription={
              PROPERTY_CHART_TEXT["lease_vacation_notices"]["tooltip"]
            }
            dateRange={{ start: start_date, end: end_date }}
            {...PROPERTY_CHART_PROPS["lease_vacation_notices"]}
          />

          <div className="widget__container">
            <Narratives
              kpiId="notices_to_vacate"
              view="property"
              isShowWidget={isShowInsights}
              name={propertyName}
            />
          </div>
        </div>
        <div>
          <ChartHOC
            kpi="move_outs"
            decimalPlaces={0}
            exploreArgs={["property", "move_outs"]}
            newWidgetData={newWidgetData}
            loaderState={loaderState}
            exploreOnClick={exploreOnClick}
            chartLabel={PROPERTY_CHART_TEXT["move_outs"]["chartLabel"]}
            titleTooltipDescription={
              PROPERTY_CHART_TEXT["move_outs"]["tooltip"]
            }
            dateRange={{ start: start_date, end: end_date }}
            {...PROPERTY_CHART_PROPS["move_outs"]}
          />

          <div className="widget__container">
            <Narratives
              kpiId="move_outs"
              view="property"
              isShowWidget={isShowInsights}
              name={propertyName}
            />
          </div>
        </div>
      </div>
    </div>
  );
});

export default PropertyRetention;
