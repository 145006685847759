import React from "react";
import { components } from "react-select";

import { AddUserLeft } from "../../icons";
import UserRow from "../user_row";

const OptionUsers = props => {
  if (props.data.__isNew__) {
    return (
      <components.Option {...props}>
        <div className="select-add-user invite-modal__add-user">
          <div className="select-add-user__avatar invite-modal__add-user-avatar">
            <AddUserLeft className="invite-modal__add-user-icon" />
          </div>
          <div className="select-add-user__title invite-modal__add-user-title">
            Add New User Email
          </div>
        </div>
      </components.Option>
    );
  }
  return (
    <components.Option {...props}>
      <UserRow className="invite-modal__user-row" {...props.data} />
    </components.Option>
  );
};

export default React.memo(OptionUsers);
