import PropTypes from "prop-types";
import React from "react";

import { Text } from "concrete-ui";
import ConfirmModal from "../confirm_modal";
import { SUPPORT_EMAIL } from "../../constants";

export default class ContactSupportModal extends React.PureComponent {
  static propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func
  };

  static defaultProps = {
    isOpen: false,
    onClose: () => ({})
  };

  render() {
    const { isOpen, onClose } = this.props;

    return (
      isOpen && (
        <ConfirmModal
          title="Contact Support"
          content={
            <Text
              textSize={2}
              $fontWeight="regular"
              $color="text.white.1"
              mr={2}
            >
              Experiencing an issue?
              <br />
              <br />
              Email{" "}
              <Text
                as="a"
                textSize={2}
                href={`mailto:${SUPPORT_EMAIL}`}
                $color="text.blue.0"
              >
                Support
              </Text>{" "}
              and we’ll get back to you.
              <br />
              <br />
              Please include helpful details such as the page you were on, what
              you were trying to do, and what happened. Attachments (e.g. screen
              grabs, PDF reports) are also very useful and appreciated. Thank
              you!
            </Text>
          }
          addBackground={true}
          hidePrimaryButtonComponent={true}
          hideSecondaryButtonComponent={true}
          onClose={onClose}
          modalProps={{
            maxWidth: "37.5rem"
          }}
        />
      )
    );
  }
}
