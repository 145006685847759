import { qsStringify } from "../../utils/misc";
import { createAjaxAction, URLS } from "./helpers";

const actions = {
  updateState: state => ({
    type: "EXPENSES_UPDATE_STATE",
    state
  }),
  // expense
  requestExpenses: data =>
    createAjaxAction(
      "AJAX_GET_EXPENSES",
      `${URLS.expenses}`,
      qs => qs && qsStringify(qs)
    )(data),
  requestPostExpense: data =>
    createAjaxAction("AJAX_POST_EXPENSE", `${URLS.expenses}`)({ body: data }),
  requestDeleteExpense: expense_id =>
    createAjaxAction(
      "AJAX_DELETE_DELETE_EXPENSE",
      `${URLS.expenses}${expense_id}/`
    )(),
  // vendors
  requestVendors: data =>
    createAjaxAction(
      "AJAX_GET_VENDORS",
      `${URLS.vendors}`,
      qs => qs && qsStringify(qs)
    )(data),
  // properties
  requestProperties: data =>
    createAjaxAction(
      "AJAX_GET_EXPENSE_PROPERTIES",
      `${URLS.property_group}/`,
      qs => qs && qsStringify(qs)
    )(data)
};

export default actions;
