const initState = {};

const reducer = (state = initState, action) => {
  let newState;

  switch (action.type) {
    case "UPDATE_CACHE": {
      newState = { ...state };
      newState[action.cacheKey] = {
        payload: action.payload,
        creationDate: new Date()
      };
      break;
    }
    default: {
      newState = state;
    }
  }
  return newState;
};

export default reducer;
