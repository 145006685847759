import React from "react";
import IconBase from "./icon_base";

const AddUserLeft = props => (
  <IconBase viewBox="0 0 16 16" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.0002 7.99996C11.4735 7.99996 12.6669 6.80663 12.6669 5.33329C12.6669 3.85996 11.4735 2.66663 10.0002 2.66663C8.52685 2.66663 7.33352 3.85996 7.33352 5.33329C7.33352 6.80663 8.52685 7.99996 10.0002 7.99996ZM4.0002 6.66663V5.33329C4.0002 4.96663 3.7002 4.66663 3.33354 4.66663C2.96687 4.66663 2.66687 4.96663 2.66687 5.33329V6.66663H1.33354C0.96687 6.66663 0.66687 6.96663 0.66687 7.33329C0.66687 7.69996 0.96687 7.99996 1.33354 7.99996H2.66687V9.33329C2.66687 9.69996 2.96687 9.99996 3.33354 9.99996C3.7002 9.99996 4.0002 9.69996 4.0002 9.33329V7.99996H5.33354C5.7002 7.99996 6.0002 7.69996 6.0002 7.33329C6.0002 6.96663 5.7002 6.66663 5.33354 6.66663H4.0002ZM4.66687 12C4.66687 10.2266 8.2202 9.33329 10.0002 9.33329C11.7802 9.33329 15.3335 10.2266 15.3335 12V12.6666C15.3335 13.0333 15.0335 13.3333 14.6669 13.3333H5.33353C4.96687 13.3333 4.66687 13.0333 4.66687 12.6666V12Z"
      fill="currentColor"
    />
  </IconBase>
);

export default AddUserLeft;
