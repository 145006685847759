import React from "react";
import { Card } from "concrete-ui";

export default ({ children, containerProps, formProps }) => {
  return (
    <Card flexBasis="100%" flexDirection="column" mt={10} {...containerProps}>
      <form {...formProps}>{children}</form>
    </Card>
  );
};
