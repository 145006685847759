import React, { Component } from "react";
import PropTypes from "prop-types";

import "./page_footer.scss";

/**
 * @class PageFooter
 *
 * @classdesc Render site-wide generic footer content (copyright, etc)
 */
export default class PageFooter extends Component {
  render() {
    // For now, we render nothing, per spec.
    return <></>;
  }
}
