import cn from "classnames";
import PropTypes from "prop-types";
import React from "react";

import { Button } from "concrete-ui";
import ModalWindow from "../modal_window";
import RenderIf from "../render_if";

import "./modal_form.scss";

const ModalForm = ({
  isOpen,
  onCancel,
  onClose,
  title,
  cancelButton,
  cancelText,
  confirmText,
  theme,
  values,
  className,
  children,
  formProps
}) => {
  const onCancelModal = values => () => {
    onCancel(values);
  };

  const classes = cn("modal-form", className, {
    [`modal-form--${theme}`]: theme !== "gray"
  });

  return (
    <ModalWindow className={classes} open={isOpen} onClose={onClose}>
      <ModalWindow.Head className="modal-form__title">{title}</ModalWindow.Head>
      <ModalWindow.Body>
        <form method="post" autoComplete="off" {...formProps}>
          <div className="modal-form__content">{children}</div>
          <div className="modal-form__controls">
            <RenderIf condition={cancelButton}>
              <Button
                variant="light"
                size="large"
                type="button"
                onClick={onCancelModal(values)}
              >
                {cancelText}
              </Button>
            </RenderIf>
            <Button ml="5" variant="primary" size="large" type="submit">
              {confirmText}
            </Button>
          </div>
        </form>
      </ModalWindow.Body>
    </ModalWindow>
  );
};

ModalForm.propTypes = {
  children: PropTypes.object.isRequired,
  theme: PropTypes.oneOf(["gray", "highlight"]),
  title: PropTypes.string.isRequired,
  isOpen: PropTypes.bool,
  cancelButton: PropTypes.bool,
  onClose: PropTypes.func,
  onCancel: PropTypes.func,
  formProps: PropTypes.object,
  values: PropTypes.object,
  confirmText: PropTypes.string,
  cancelText: PropTypes.string,
  className: PropTypes.string
};

ModalForm.defaultProps = {
  theme: "gray",
  isOpen: false,
  cancelButton: false,
  onClose() {},
  onCancel() {},
  confirmText: "Save",
  cancelText: "Cancel"
};

export default ModalForm;
