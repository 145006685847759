import { all, call, put, takeEvery } from "redux-saga/effects";

import {
  networking,
  token,
  auth,
  user,
  sisense
} from "../../redux_base/actions";
import { axiosDelete, axiosGet, axiosPatch, axiosPost } from "../../utils/api";
import { qsStringify } from "../../utils/misc";

/**
 * checkStatus
 *
 * note: this provides a surface for reacting to token errors (401)
 *       and builds on the asumption that the API behavior is static
 *       ...despite my dislike of the conventions :p
 *
 * @param {Obj} response - axios response object (raw)
 */
export const checkStatus = response =>
  new Promise((resolve, reject) => {
    if (response.status >= 200 && response.status <= 299) {
      resolve();
    } else if (response.status === 401) {
      const { code } = response.data;
      const { messages } = response.data;
      let isAccessToken = false;
      for (let i = 0; i < messages.length; i++) {
        if (messages[i].token_type === "access") {
          isAccessToken = true;
        }
      }
      switch (code) {
        case "token_not_valid": {
          isAccessToken
            ? reject({ code: "access_token_invalid" })
            : reject({ code });
          break;
        }
        default:
          resolve();
      }
    } else {
      reject(response);
    }
  });

/**
 * handleError
 *
 * note:  the primary purpose is to either request a refresh token,
 *        or "logout" the user if the refresh is invalid (thus the session)
 *        is expired...
 * @param {Obj} action - redux action
 * @param {Obj} e - error object (custom)
 */
export function handleError(action) {
  return function* handleError(e) {
    if (e.code) {
      switch (e.code) {
        case "token_not_valid": {
          yield put(auth.logout());
          break;
        }
        default:
          yield put(token.refresh(action));
      }
    } else if (
      // catch sisense action which happens before sisense login
      action.type === "FETCH_SISENSE_API" &&
      e.response?.status === 403
    ) {
      yield put(sisense.storeAction(action));
    } else {
      console.log("something was wrong!!!", e);
      const { type, url } = action;
      const errorCode = e.response?.status || "fail";
      yield put(networking.fail({ message: e.message, type, url, errorCode }));

      // The request was made but no response was received
      if (e.request) {
        const errorContext = {
          code: "",
          title: "Service Temporarily Unavailable",
          description: "There was a problem communicating with our servers!"
        };
        // NOTE: direct routing from within this function is
        //       a massively bad pattern. take that in, let it
        //       settle. afraid yet?

        // disabling this -jc
        // window.location.replace(`/error${qsStringify(errorContext)}`);
        put(user.collectError({ errorContext, e }));
      }
    }
  };
}

function* get(action) {
  try {
    yield put({ type: `${action.baseActionType}_REQUEST` });
    yield put(networking.startFetching(action.branch));
    const response = yield call(
      axiosGet,
      action.url,
      undefined,
      action.parseNaNInfinity
    );
    yield checkStatus(response);
    yield put({
      type: `${action.baseActionType}_SUCCESS`,
      payload: response.data
    });
    yield put(networking.success());
  } catch (e) {
    yield handleError(action)(e);
    yield put({ type: `${action.baseActionType}_ERROR`, payload: e });
  }
}

function* _delete(action) {
  try {
    yield put({ type: `${action.baseActionType}_REQUEST` });
    yield put(networking.startFetching(action.branch));
    const response = yield call(axiosDelete, action.url);
    yield checkStatus(response);
    yield put({
      type: `${action.baseActionType}_SUCCESS`,
      payload: response.data
    });
    yield put(networking.success());
  } catch (e) {
    yield handleError(action)(e);
    yield put({ type: `${action.baseActionType}_ERROR`, payload: e });
  }
}

function* post(action) {
  try {
    yield put({
      type: `${action.baseActionType}_REQUEST`,
      params: action.params
    });
    const response = yield call(
      axiosPost,
      action.url,
      action.body,
      undefined,
      undefined,
      action.parseNaNInfinity
    );
    yield checkStatus(response);
    yield put({
      type: `${action.baseActionType}_SUCCESS`,
      payload: response.data,
      params: action.params,
      body: action.body
    });
    yield put(networking.success());
  } catch (e) {
    if (e.response?.data?.errors) {
      yield put({
        type: `${action.baseActionType}_FORM_ERRORS`,
        payload: e.response.data.errors
      });
    } else {
      yield handleError(action)(e);
      yield put({ type: `${action.baseActionType}_ERROR`, payload: e });
    }
  }
}

function* patch(action) {
  try {
    yield put({
      type: `${action.baseActionType}_REQUEST`,
      params: action.params
    });
    const response = yield call(axiosPatch, action.url, action.body);
    yield checkStatus(response);
    yield put({
      type: `${action.baseActionType}_SUCCESS`,
      payload: response.data,
      params: action.params
    });
    yield put(networking.success());
  } catch (e) {
    if (e.response?.data?.errors) {
      yield put({
        type: `${action.baseActionType}_FORM_ERRORS`,
        payload: e.response.data.errors
      });
    } else {
      yield handleError(action)(e);
      yield put({ type: `${action.baseActionType}_ERROR`, payload: e });
    }
  }
}

function* deleteSaga() {
  yield takeEvery("FETCH_API_DELETE", _delete);
}

function* getSaga() {
  yield takeEvery("FETCH_API_GET", get);
}

function* patchSaga() {
  yield takeEvery("FETCH_API_PATCH", patch);
}

function* postSaga() {
  yield takeEvery("FETCH_API_POST", post);
}

export default function*() {
  yield all([deleteSaga(), getSaga(), patchSaga(), postSaga()]);
}
