const initialState = {
  error: false,
  errors: [],
  users: {}
};

const reducer = (state = initialState, action) => {
  let newState = undefined;

  switch (action.type) {
    case "LOGIN_ERROR":
      newState = { ...state, error: true };
      break;
    case "AJAX_USER_IS_ACTIVATED_SUCCESS":
      newState = {
        ...state,
        ...action.payload
      };
      break;
    case "AJAX_USER_IS_ACTIVATED_FAILURE":
      newState = { ...state };
      break;
    case "AJAX_GET_USER_PROFILE_SUCCESS":
      newState = { ...state, ...action.payload };
      break;
    case "AJAX_GET_PORTFOLIO_GROUPS_SUCCESS":
    case "AJAX_GET_DASHBOARD_PROPERTIES_SUCCESS": {
      //  TODO: Remove user from dashboard/ request
      //  TODO: Remove user from portfolio/table/ request
      newState = {
        ...state,
        ...action.payload.user
      };
      break;
    }
    case "AJAX_GET_PROPERTY_GROUP_LIST_SUCCESS":
    case "AJAX_GET_PROPERTY_GROUP_SUCCESS": {
      newState = {
        ...state,
        ...action.payload.user
      };
      break;
    }
    case "AJAX_GET_ACCOUNT_SETTINGS_SUCCESS": {
      newState = { ...state, ...action.payload.user };
      break;
    }
    case "USER_COLLECT_ERROR": {
      newState = { ...state, errors: state.errors.push(action.err) };
      break;
    }
    case "AJAX_GET_USER_ASSOCIATES_FAILURE": {
      break;
    }
    case "AJAX_GET_USER_ASSOCIATES_SUCCESS": {
      const userPropsGroups = action.payload || {};
      const isPropsGroupsLoading = false;
      newState = {
        ...state,
        userPropsGroups,
        isPropsGroupsLoading
      };
      break;
    }
    default:
      newState = state;
  }
  return newState;
};

export default reducer;
