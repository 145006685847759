import React from "react";
import { Box, Text } from "concrete-ui";

import Container from "../container";
import PaginationTable from "../pagination_table";
import { formatPercent, valueOr } from "../../utils/formatters";
import {
  csvPercentageFormat,
  getCsvFilename,
  structurePropertyKpiData
} from "./utils";

export default function NewWebsiteVisitors(props) {
  const { kpiData } = props;
  const columns = [
    {
      Header: "Source",
      accessor: "source_name"
    },
    {
      Header: "New Leases",
      accessor: "count"
    },
    {
      Header: "Percentage of Total",
      accessor: "percentage",
      csvFormatter: csvPercentageFormat,
      ValueComponent: ({ data, textProps }) => (
        <Box alignItems="center">
          <Text {...textProps}>
            {valueOr(data.percentage, "\u2011%", formatPercent)}
          </Text>
        </Box>
      )
    }
  ];
  return (
    <Container>
      <div className="property-group__table--middle kpi-table">
        <PaginationTable
          data={structurePropertyKpiData(kpiData.table_data) || []}
          columns={columns}
          className="property-group-table"
          tableType="kpi"
          csvFileName={getCsvFilename(
            props.property.name,
            props.kpi,
            props.startDate,
            props.endDate,
            "Referral_Sources"
          )}
        />
      </div>
    </Container>
  );
}
