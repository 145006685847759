import React from "react";
import PropTypes from "prop-types";
import { Checkbox, ComboBox, Item, Menu, Text } from "concrete-ui";

const MultiSelect = ({
  placeholder,
  options,
  value,
  onChange,
  onBlur,
  errorMessage = "",
  ...otherProps
}) => {
  return (
    <ComboBox
      {...otherProps}
      multi={true}
      placeholder={placeholder}
      value={value}
      items={options}
      onSelect={onChange}
      onBlur={onBlur}
      error={errorMessage}
      errorMessage={errorMessage}
    >
      {({ props, items, selected, selectItem, getUIValue }) => (
        <Menu {...props}>
          {items.length ? (
            items.map(item => (
              <Item
                size="large"
                key={item.value}
                onClick={e => {
                  e.preventDefault();
                  selectItem(item.value);
                }}
              >
                <Checkbox
                  key={item.value}
                  name={item.value}
                  value={selected.has(item.value)}
                  mr={2}
                  onChange={() => {}}
                />
                {getUIValue(item.value)}
              </Item>
            ))
          ) : (
            <Text textSize={2} ml={3} py={1}>
              No results found.
            </Text>
          )}
        </Menu>
      )}
    </ComboBox>
  );
};

MultiSelect.propTypes = {
  placeholder: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any.isRequired,
      label: PropTypes.any
    })
  ),
  value: PropTypes.any,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  errorMessage: PropTypes.string
};

export default MultiSelect;
