import React from "react";
import { Box, Flex, Button, ModalLarge, Text } from "concrete-ui";
import ConfirmExpenses from "../review-recurring-expense";
import ConfirmMeteredExpenses from "../review-pay-for-performance-expense";
import OneTimeExpenseReview from "../review-one-time-expense";

const ConfirmationExpenseModal = props => {
  const {
    closeHandler,
    formItems,
    option,
    title,
    propertyOpts,
    leadSourcesOpts,
    submitExpense
  } = props;

  const expenseModalSwitch = () => {
    switch (option) {
      case "recurring":
        return (
          <ConfirmExpenses
            formItems={formItems}
            propertyOpts={propertyOpts}
            leadSourcesOpts={leadSourcesOpts}
          />
        );
      case "pay_for_performance":
        return (
          <ConfirmMeteredExpenses
            formItems={formItems}
            propertyOpts={propertyOpts}
            leadSourcesOpts={leadSourcesOpts}
          />
        );
      case "one_time":
        return (
          <OneTimeExpenseReview
            formItems={formItems}
            propertyOpts={propertyOpts}
            leadSourcesOpts={leadSourcesOpts}
          />
        );
    }
  };

  return (
    <>
      <Box
        position="fixed"
        top={0}
        bottom={0}
        left={0}
        right={0}
        zIndex={1000}
        bg="gray.1"
        opacity="0.8"
      />
      <Box
        position="fixed"
        top={0}
        bottom={0}
        left={0}
        right={0}
        zIndex={1001}
        justifyContent="center"
        alignItems="center"
      >
        <ModalLarge
          title={title}
          secondaryButtonOnClick={closeHandler}
          secondaryButtonText="Edit"
          primaryButtonComponent={
            <Button
              variant="primary"
              size="large"
              type="button"
              onClick={submitExpense}
            >
              Confirm Expenses
            </Button>
          }
          closeButtonOnClick={closeHandler}
        >
          <Flex width="37.5rem" flexDirection="column">
            {expenseModalSwitch()}
          </Flex>
        </ModalLarge>
      </Box>
    </>
  );
};

export default ConfirmationExpenseModal;
