import _set from "lodash/set";
import _unset from "lodash/unset";

const initialState = {
  narratives: {}
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "AJAX_POST_NARRATIVES_REQUEST": {
      const newState = { ...state };
      _set(newState, "loading", true);
      return newState;
    }
    case "AJAX_POST_NARRATIVES_SUCCESS": {
      const newState = { ...state };
      _unset(newState, ["errorCode"]);
      _unset(newState, ["narratives"]);
      if ("narratives" in action.payload && action.body) {
        const urlParams = JSON.stringify(action.body);
        _set(newState, ["narratives"], {
          ...state.narratives,
          [urlParams]: action.payload.narratives
        });
      } else if ("error" in action.payload) {
        _set(newState, ["errorCode"], action.payload.code);
      }
      _set(newState, ["loading"], false);
      return newState;
    }
    default:
      return state;
  }
};

export default reducer;
