import React from "react";
import { TextInput, Icon, DateRangeSelector, Tooltip } from "concrete-ui";
import {
  dateToDatePicker,
  dateToValidation,
  inputNumberFormatter,
  inputStringFormatter,
  inputTextWithTooltip,
  formatSuggestedValue
} from "../utils";
import { expenseTooltips } from "../tooltips";
import { concreteErrorVariant } from "../../../constants";
import { ActualExpenseList } from "../actual-expense-list";

export const OneTimeExpense = ({ getItemProps, formItems, errors }) => {
  const costBudgetedProps = getItemProps("cost_budgeted", {
    formatInputToValidator: inputNumberFormatter,
    formatValidatorToInput: inputStringFormatter
  });
  const startDateProps = getItemProps("start_date", {
    formatInputToValidator: dateToValidation,
    formatValidatorToInput: dateToDatePicker
  });
  const endDateProps = getItemProps("end_date", {
    formatInputToValidator: dateToValidation,
    formatValidatorToInput: dateToDatePicker
  });
  const actualExpenseProps = getItemProps("actual_expenses");
  const suggestedExpenseValue = formatSuggestedValue(formItems);

  return (
    <>
      <TextInput
        size="large"
        iconLeft={<Icon.Dollar />}
        label={inputTextWithTooltip(
          "Budgeted Expense",
          expenseTooltips["budgetedExpense"]["one_time"]
        )}
        {...costBudgetedProps}
        variant={errors.cost_budgeted && concreteErrorVariant}
        message={errors.cost_budgeted}
      />
      <DateRangeSelector
        mt={5}
        startOptions={{
          initialDate: startDateProps.value,
          onDateSelect: startDateProps.onChange,
          label: "Expense Start Date",
          startOrEnd: "start",
          ml: -3,
          errorMessage: errors.start_date
        }}
        endOptions={{
          initialDate: endDateProps.value,
          onDateSelect: endDateProps.onChange,
          label: "Expense End Date",
          startOrEnd: "end",
          mr: -3,
          errorMessage: errors.end_date
        }}
        help={{
          mr: -3,
          textSize: 1,
          $color: "text.gray.1",
          message: "Expense will be applied across duration selected."
        }}
        mb={6}
      />
      <ActualExpenseList
        onChange={actualExpenseProps.onChange}
        formItems={formItems}
        suggestedExpenseValue={suggestedExpenseValue}
        errors={errors}
      />
    </>
  );
};
