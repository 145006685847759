import React from "react";
import { Tooltip } from "concrete-ui";
import {
  REVENUE_ROI_DATA_LIMITATION_MSG,
  ZENDESK_REVENUE_ROI_DATA_LIMITATION
} from "./../../constants";

export const expenseDiffMapper = {
  budget: "budget_diff",
  actual_budget: "budget_diff",
  cost_per_nwv: "cost_per_nwv_diff",
  cost_per_nwv_actual: "cost_per_nwv_diff",
  cost_per_leads: "cost_per_leads_diff",
  cost_per_leads_actual: "cost_per_leads_diff",
  cost_per_tours: "cost_per_tours_diff",
  cost_per_tours_actual: "cost_per_tours_diff",
  cost_per_lease_applications: "cost_per_lease_applications_diff",
  cost_per_lease_applications_actual: "cost_per_lease_applications_diff",
  cost_per_approved_leases: "cost_per_approved_leases_diff",
  cost_per_approved_leases_actual: "cost_per_approved_leases_diff",
  cost_per_new_leases: "cost_per_new_leases_diff",
  cost_per_new_leases_actual: "cost_per_new_leases_diff"
};

export const channelAnalysisTableTabs = expensesFilter => ({
  volumes: {
    event: {
      id: "volumes",
      label: "Volumes",
      columns: [
        "source",
        "nwv",
        "leads",
        "tours",
        "lease_applications",
        "approved_leases",
        "new_leases"
      ]
    },
    leadCohort: {
      id: "volumes",
      label: "Volumes",
      columns: [
        "source",
        "leads",
        "tours",
        "lease_applications",
        "approved_leases",
        "new_leases"
      ]
    }
  },
  conversion_rates: {
    event: {
      id: "conversion_rates",
      label: "Conversion Rates",
      columns: [
        "source",
        "leads_new_leases",
        "nwv_leads",
        "leads_tours",
        "tours_lease_applications",
        "lease_apps_app_leases",
        "app_leases_new_leases"
      ]
    },
    leadCohort: {
      id: "conversion_rates",
      label: "Conversion Rates",
      columns: [
        "source",
        "leads_new_leases",
        "leads_tours",
        "tours_lease_applications",
        "lease_apps_app_leases",
        "app_leases_new_leases"
      ]
    }
  },
  cost_pers: {
    event: {
      id: "cost_pers",
      label: "Expenses & Cost Pers",
      columns:
        expensesFilter === "actualOnly"
          ? [
              "source",
              "actual_budget",
              "cost_per_nwv_actual",
              "cost_per_leads_actual",
              "cost_per_tours_actual",
              "cost_per_lease_applications_actual",
              "cost_per_approved_leases_actual",
              "cost_per_new_leases_actual"
            ]
          : [
              "source",
              "budget",
              "cost_per_nwv",
              "cost_per_leads",
              "cost_per_tours",
              "cost_per_lease_applications",
              "cost_per_approved_leases",
              "cost_per_new_leases"
            ]
    },
    leadCohort: {
      id: "cost_pers",
      label: "Expenses & Cost Pers",
      columns: [
        "source",
        "budget",
        "cost_per_leads",
        "cost_per_tours",
        "cost_per_lease_applications",
        "cost_per_approved_leases",
        "cost_per_new_leases"
      ]
    }
  },
  revenue_roi: {
    event: {
      id: "revenue_roi",
      label: (
        <>
          {"Revenue & ROI"}{" "}
          {
            <Tooltip
              title={REVENUE_ROI_DATA_LIMITATION_MSG}
              linkText="Learn more."
              link={ZENDESK_REVENUE_ROI_DATA_LIMITATION}
              placement="bottom"
              size="small"
              mt="0.2rem"
              ml={2}
              tooltipProps={{
                onClick: e => e.stopPropagation()
              }}
              linkProps={{
                target: "_blank"
              }}
            />
          }
        </>
      ),
      columns: [
        "source",
        "new_leases",
        "gross_rent",
        "avg_lease_length",
        "lease_revenue",
        "projected_marketing_return"
      ]
    },
    leadCohort: {
      id: "revenue_roi",
      label: "Revenue & ROI",
      columns: [
        "source",
        "gross_rent",
        "new_leases",
        "avg_lease_length",
        "lease_revenue",
        "projected_marketing_return"
      ],
      columnProps: { new_leases: { colWidth: "430px", paddingX: 2 } }
    }
  },
  units: {
    event: {
      id: "units",
      label: "Units",
      columns: [
        "source",
        "new_leases",
        "bedrooms",
        "bathrooms",
        "square_feet",
        "rent_per_sq_feet"
      ]
    },
    leadCohort: {
      id: "units",
      label: "Units",
      columns: [
        "source",
        "new_leases",
        "bedrooms",
        "bathrooms",
        "square_feet",
        "rent_per_sq_feet"
      ]
    }
  }
});
