import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import Notifications from "../../components/notifications";
import { notifications as actions } from "../../redux_base/actions";

class NotificationsContainer extends React.PureComponent {
  static propTypes = {
    notifications: PropTypes.array
  };

  onCloseHandler = notificationId => {
    const notifications = this.props.notifications.filter(
      notification => notification.id !== notificationId
    );
    this.props.dispatch(actions.update(notifications));
  };

  render() {
    return (
      <Notifications
        notifications={this.props.notifications}
        onClose={this.onCloseHandler}
      />
    );
  }
}

const mapState = state => ({
  ...state.notifications
});

export default connect(mapState)(NotificationsContainer);
