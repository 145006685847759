import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import _isEmpty from "lodash/isEmpty";

import { validatePassword } from "../../api/password";
import { Button, TextInput, Flex, Icon, Box, Text } from "concrete-ui";
import PasswordOverlay from "../password_tooltip";
import Tooltip from "../rmb_tooltip";
import { securityConfig, passwordsNotMatchMessage } from "./validators";
import useValidation from "../../utils/validator_hook";
import { concreteErrorVariant } from "../../constants";

const TYPING_TIMEOUT = 300;

export const AccountSecurity = ({
  updateSuccessMessage,
  rules,
  updateError,
  user = {},
  onSave = () => ({})
}) => {
  const [state, setState] = useState({ message: null });

  const timeoutRef = useRef(null);

  useEffect(() => {
    if (updateSuccessMessage) {
      setSuccessMessage(updateSuccessMessage);
    }
    if (!_isEmpty(updateError)) {
      setErrorMessages(updateError);
    }
  }, [updateError, updateSuccessMessage]);

  const unsetMessage = () => {
    if (state.message) {
      setState({ message: null });
    }
  };

  const initialValues = {
    old_password: "",
    password: "",
    confirm_password: ""
  };

  const onSaveUser = data => {
    unsetMessage();
    onSave(data);
  };

  const validate = (values, userId) => {
    clearTimeout(timeoutRef.current);
    if (!values.password) {
      return;
    }
    return new Promise(resolve => {
      timeoutRef.current = setTimeout(() => resolve(), TYPING_TIMEOUT);
    })
      .then(() => validatePassword(values.password, userId))
      .then(validatePasswordErrors => {
        let response = {};
        if (!_isEmpty(validatePasswordErrors)) {
          response["password"] = validatePasswordErrors;
        }
        if (values.password !== values.confirm_password) {
          response["confirm_password"] = passwordsNotMatchMessage;
        }
        return response;
      })
      .then(errors => {
        if (Object.keys(errors).length) {
          throw errors;
        }
      });
  };

  const {
    formItems,
    errors,
    setErrors,
    getFormProps,
    getItemProps,
    setFormItems,
    submitDisabled
  } = useValidation(securityConfig, initialValues, onSaveUser, values =>
    validate(values, user.user_id)
  );

  const showErrorMessage = errors => {
    let message;
    if (Object.keys(errors).length) {
      message = "Please review highlighted fields above.";
    }
    if (!message) {
      return;
    }
    return (
      <Flex alignItems="center" mx={6}>
        <Text textSize={2} $fontWeight="regular" $color="text.orange.0">
          {message}
        </Text>
      </Flex>
    );
  };

  const showMessage = errors => {
    if (state.message) {
      return showSuccessMessage();
    } else if (errors) {
      return showErrorMessage(errors);
    }
  };

  const showSuccessMessage = () => {
    return (
      <Flex alignItems="center" mx={6}>
        <Icon.Check width={4} height={4} mr={2} $color="green.1" />
        <Text textSize={2} $fontWeight="regular">
          {state.message}
        </Text>
      </Flex>
    );
  };

  const setSuccessMessage = message => {
    if (formItems.password) {
      setFormItems({
        old_password: "",
        password: "",
        confirm_password: ""
      });
    }
    setState({ message });
  };

  const setErrorMessages = errors => {
    const updateErrors = {};
    for (let k of Object.keys(errors)) {
      updateErrors[k] = errors[k][0].message;
    }
    setErrors(updateErrors);
  };

  const isSaveButtonDisabled = () => {
    if (
      !_isEmpty(errors) ||
      !formItems["password"] ||
      !formItems["old_password"] ||
      !formItems["confirm_password"] ||
      submitDisabled
    ) {
      return true;
    }
    return false;
  };

  return (
    <Box display="block" flexGrow="1">
      <form autoComplete="off" {...getFormProps()}>
        <Flex px={7} pt={5} pb={6} flexDirection="column">
          <Flex flex="1" width="calc(50% - 0.5rem)" my={5}>
            <TextInput
              size="large"
              type="password"
              showPasswordOption={true}
              label="Current Password"
              {...getItemProps("old_password")}
              variant={errors["old_password"] && concreteErrorVariant}
              message={errors["old_password"]}
            />
          </Flex>
          <Flex flex="1" my={5}>
            <Tooltip
              placement="bottom"
              theme="dark"
              overlay={
                <PasswordOverlay
                  password={formItems.password}
                  errors={errors.password}
                  rules={rules}
                />
              }
              trigger={["focus"]}
            >
              <TextInput
                size="large"
                type="password"
                showPasswordOption={true}
                mr={4}
                label="New Password"
                {...getItemProps("password")}
                variant={errors["password"] && concreteErrorVariant}
              />
            </Tooltip>
            <TextInput
              size="large"
              type="password"
              showPasswordOption={true}
              label="Confirm Password"
              {...getItemProps("confirm_password")}
              variant={errors["confirm_password"] && concreteErrorVariant}
              message={errors["confirm_password"]}
            />
          </Flex>
          <Flex alignItems="center" justifyContent="flex-end" my={5}>
            {showMessage(errors)}
            <Button
              variant="primary"
              size="large"
              type="submit"
              disabled={isSaveButtonDisabled()}
            >
              Save
            </Button>
          </Flex>
        </Flex>
      </form>
    </Box>
  );
};

export default AccountSecurity;

AccountSecurity.propTypes = {
  rules: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      label: PropTypes.string
    })
  ).isRequired,
  user: PropTypes.object,
  validate: PropTypes.func,
  updateSuccessMessage: PropTypes.string,
  updateError: PropTypes.object
};
