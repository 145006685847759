import React from "react";
import PropTypes from "prop-types";

import RenderIf from "../render_if";
import { Copy, Tick } from "../../icons";

class NarrationCopyButton extends React.PureComponent {
  static propTypes = {
    text: PropTypes.string
  };

  constructor(props) {
    super(props);
    this.textAreaRef = React.createRef();
    this.state = {
      copied: false
    };
  }

  updateState = () => {
    this.setState({ copied: true }, () => {
      clearTimeout(this.timeoutId);
      this.timeoutId = setTimeout(this.backState, 2000);
    });
  };

  backState = () => {
    this.setState({ copied: false });
  };

  onCopy = () => {
    /* Get the text field */
    // const copyText = document.getElementById(this.props.widgetId);
    const copyText = this.textAreaRef.current;
    /* Select the text field */
    copyText.select();
    copyText.setSelectionRange(0, 99999); /* For mobile devices */
    /* Copy the text inside the text field */
    document.execCommand("copy");
    this.updateState();
  };

  render() {
    return (
      <div className="narration__copy" onClick={this.onCopy}>
        <textarea
          className="narration__textarea"
          ref={this.textAreaRef}
          readOnly={true}
          value={this.props.text}
        />
        <RenderIf condition={!this.state.copied}>
          <Copy className="narration__copy-icon" />
          <div className="narration__copy-text">Copy</div>
        </RenderIf>
        <RenderIf condition={this.state.copied}>
          <Tick className="narration__copy-icon" />
          <div className="narration__copy-text">Copied</div>
        </RenderIf>
      </div>
    );
  }
}

export default NarrationCopyButton;
