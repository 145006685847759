import React from "react";
import PropTypes from "prop-types";
import {
  Button,
  Box,
  Checkbox,
  Divider,
  Flex,
  Icon,
  Text,
  TextInput,
  Tooltip,
  ValueLoader,
  Link
} from "concrete-ui";
import _isEmpty from "lodash/isEmpty";

import RenderIf from "../render_if";
import ReferralSourcesSave from "../referral_sources_save";
import {
  MATCHING_NWV_ALREADY_DISTRIBUTED,
  MATCHING_NWV_ALREADY_MATCHED
} from "../referral_sources/tooltips";
import { isValidURL } from "../../utils/misc";
import { ZENDESK_DISTRIBUTION_SUGGESTION } from "../../constants";
import NwvFilters from "./filters";
import { TabViews } from "../referral_sources/constants";
import { MANAGE_REFERRAL_SOURCES_MESSAGES } from "../referral_sources/messages";

const SelectAllCheckbox = ({ name, value, onChange }) => {
  return (
    <Flex pb={2}>
      <Checkbox
        label="Select All"
        name={name}
        value={value}
        onChange={onChange}
        py={0}
      >
        <Text
          textSize={2}
          ml={2}
          mr={1}
          $fontWeight="regular"
          $color="text.gray.1"
        >
          Select All
        </Text>
      </Checkbox>
    </Flex>
  );
};

/**
 * This is a copy of referral_sources_nwv component that is hidden
 * behind a feature flag 'matching-new-filter-sort'.
 *
 * Component is intended to replace referral_sources_nwv and distribution_nwv
 * components after new filtering and sorting epic is completed.
 */
class ReferralSourcesNWV2 extends React.PureComponent {
  static nwvType = PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
      value: PropTypes.object,
      matched: PropTypes.bool,
      distributed: PropTypes.bool,
      checked: PropTypes.bool,
      matchedToDifferentLead: PropTypes.bool,
      distributedToDifferentLead: PropTypes.bool
    })
  );

  static propTypes = {
    sources: ReferralSourcesNWV2.nwvType,
    fetchingNWVs: PropTypes.bool,
    headers: PropTypes.object,
    nwvTitle: PropTypes.string,
    leadSourceTitle: PropTypes.string,
    isSelectLeadSource: PropTypes.bool,
    selectedOnTop: PropTypes.bool,
    distributedOnTop: PropTypes.bool,
    unbouncedVisitorsOnly: PropTypes.bool,
    saveCompleted: PropTypes.bool,
    onSave: PropTypes.func,
    onSelectFilter: PropTypes.func.isRequired,
    tabView: PropTypes.string,
    match: PropTypes.bool,
    distribute: PropTypes.bool,
    isSelectedDistributionLead: PropTypes.bool
  };

  static defaultProps = {
    sources: [],
    headers: {},
    nvwTitle: "",
    leadSourceTitle: "",
    isSelectLeadSource: false,
    selectedOnTop: true,
    distributedOnTop: true,
    unbouncedVisitorsOnly: false,
    tabView: TabViews.Match,
    match: false,
    distribute: false,
    isSelectedDistributionLead: false,
    onSave: () => {}
  };

  _getSelectedSourcesIds = () => {
    const selectedSources = this.props.sources.filter(s =>
      this.props.match
        ? !(s.matchedToDifferentLead || s.distributed) && s.checked
        : !(s.distributedToDifferentLead || s.matched) && s.checked
    );
    selectedSources.sort((s1, s2) =>
      this.props.match
        ? +!!s1.matchedToDifferentLead - +!!s2.matchedToDifferentLead
        : +!!s1.distributedToDifferentLead - +!!s2.distributedToDifferentLead
    );
    return selectedSources.map(s => s.id);
  };

  _filterSourcesObjs = (sourcesObj, sourceIds) => {
    return sourceIds.reduce(
      (sources, sId) => [...sources, sourcesObj[sId]],
      []
    );
  };

  _filterSourcesToUnselect = sources =>
    sources.filter(s =>
      this.props.match
        ? !s.distributed && !s.checked && !s.matchedToDifferentLead
        : !s.matched && !s.checked && !s.distributedToDifferentLead
    );

  _getUnSelectedSourcesIds = () =>
    this._filterSourcesToUnselect(this.props.sources).map(s => s.id);

  _getDisabledTooltipMessage = source => {
    if (source.matchedToDifferentLead) {
      return MATCHING_NWV_ALREADY_MATCHED;
    } else if (source.distributed) {
      return MATCHING_NWV_ALREADY_DISTRIBUTED;
    }
    return "";
  };

  _unSelectSourcesForIds = ids => {
    const idsSet = new Set(ids);
    const sourcesValues = Object.values(this.state.sourcesObj);
    return sourcesValues.reduce(
      (sObj, s) => ({
        ...sObj,
        [s.id]: {
          ...s,
          checked: idsSet.has(s.id) ? false : s.checked
        }
      }),
      {}
    );
  };

  _selectSourcesForIds = ids => {
    const idsSet = new Set(ids);
    const sourcesValues = Object.values(this.state.sourcesObj);
    return sourcesValues.reduce(
      (sObj, s) => ({
        ...sObj,
        [s.id]: {
          ...s,
          checked: idsSet.has(s.id) ? true : s.checked
        }
      }),
      {}
    );
  };

  state = {
    searchStr: "",
    searchResult: [],
    sourcesObj: this.props.sources.reduce(
      (acc, s) => ({ ...acc, [s.id]: { ...s } }),
      {}
    ),
    selectedSources: this._getSelectedSourcesIds(),
    unselectedSources: this._getUnSelectedSourcesIds(),
    selectAll: this._filterSourcesToUnselect(this.props.sources).every(
      s => s.checked
    ),
    selectSelectedAll: this._getSelectedSourcesIds().length > 0,
    selectSearchAll: false,
    nwvFilters: []
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    let newState = {};
    if (prevProps.sources !== this.props.sources) {
      newState.searchStr = "";
      newState.searchResult = [];
      newState.sourcesObj = this.props.sources.reduce(
        (acc, s) => ({ ...acc, [s.id]: { ...s } }),
        {}
      );
      newState.selectedSources = this._getSelectedSourcesIds();
      newState.unselectedSources = this._getUnSelectedSourcesIds();
      newState.selectAll = this._filterSourcesToUnselect(
        this.props.sources
      ).every(s => s.checked);
      newState.selectSelectedAll = this._getSelectedSourcesIds().length > 0;
    }
    if (!_isEmpty(newState)) {
      this.setState(newState);
    }
  }

  checkForDistributionSuggestion = () =>
    this.props.tabView === "match" &&
    this.state.nwvFilters.indexOf("suggestions") !== -1 &&
    this.props.isSelectedDistributionLead;

  renderWebsiteSources = () => {
    let content;
    if (this.checkForDistributionSuggestion()) {
      content = this.renderDistributionSuggestion();
    } else if (this.state.searchStr && !this.state.searchResult.length) {
      content = this.renderNoSearchResult();
    } else if (
      (!this.getAvailableNwvs().length && this.state.nwvFilters.length) ||
      !this.props.sources?.length
    ) {
      content = this.renderNoNwvs();
    } else if (this.props.matchedOnTop) {
      content = this.renderMatchedSourcesTop();
    } else if (this.props.selectedOnTop) {
      content = this.renderSelectedSourcesTop();
    } else {
      content = this.renderSelectedSourcesBottom();
    }
    return content;
  };

  getAvailableNwvs = () => {
    const availableSources = Object.values(this.props.sources).filter(
      s => !s.matchedToDifferentLead && !s.distributedToDifferentLead
    );
    return availableSources;
  };

  renderSelectedSourcesTop = () => {
    if (this.state.searchResult.length) {
      return this.renderSearchSources(this.state.searchResult);
    }
    const selectedSourcesDisplayList = this.getSourcesToDisplay(
      this.state.selectedSources
    );
    const unselectedSourcesDisplayList = this.getSourcesToDisplay(
      this.state.unselectedSources
    );
    return (
      <>
        <RenderIf condition={!!this.state.selectedSources.length}>
          <Text pt={4} textSize={2} $fontWeight="regular" $color="text.gray.1">
            {this.props.headers.selected}
          </Text>
          <SelectAllCheckbox
            name="SelectMatchedAll"
            value={this.state.selectSelectedAll}
            onChange={this.toggleSelectMatchedAll}
          />
          {this.renderList(selectedSourcesDisplayList)}
        </RenderIf>
        <RenderIf
          condition={
            selectedSourcesDisplayList.length &&
            unselectedSourcesDisplayList.length
          }
        >
          <Flex mx={3} pt={2}>
            <Divider variant="horizontal" bg="gray.4" flexShrink={0} />
          </Flex>
        </RenderIf>
        <RenderIf condition={!!unselectedSourcesDisplayList.length}>
          <Text pt={2} textSize={2} $fontWeight="regular" $color="text.gray.1">
            {this.props.headers.unselected}
          </Text>
          <SelectAllCheckbox
            name="SelectAll"
            value={this.state.selectAll}
            onChange={this.toggleSelectAll}
          />
          {this.renderList(unselectedSourcesDisplayList)}
        </RenderIf>
      </>
    );
  };

  renderSelectedSourcesBottom = () => {
    if (this.state.searchResult.length) {
      return this.renderSearchSources(this.state.searchResult);
    }
    const selectedSourcesDisplayList = this.getSourcesToDisplay(
      this.state.selectedSources
    );
    const unselectedSourcesDisplayList = this.getSourcesToDisplay(
      this.state.unselectedSources
    );
    return (
      <>
        <RenderIf condition={!!this.state.unselectedSources.length}>
          <Text pt={5} textSize={2} $fontWeight="regular" $color="text.gray.1">
            {this.props.headers.unselected}
          </Text>
          <SelectAllCheckbox
            name="SelectAll"
            value={this.state.selectAll}
            onChange={this.toggleSelectAll}
          />
          {this.renderList(unselectedSourcesDisplayList)}
        </RenderIf>
        <RenderIf
          condition={
            selectedSourcesDisplayList.length &&
            unselectedSourcesDisplayList.length
          }
        >
          <Flex mx={3} pt={2}>
            <Divider variant="horizontal" bg="gray.4" flexShrink={0} />
          </Flex>
        </RenderIf>
        <RenderIf condition={!!selectedSourcesDisplayList.length}>
          <Text pt={5} textSize={2} $fontWeight="regular" $color="text.gray.1">
            {this.props.headers.selected}
          </Text>
          <SelectAllCheckbox
            name="SelectMatchedAll"
            value={this.state.selectSelectedAll}
            onChange={this.toggleSelectMatchedAll}
          />
          {this.renderList(selectedSourcesDisplayList)}
        </RenderIf>
      </>
    );
  };

  renderSearchSources = () => {
    const searchSourcesDisplayList = this.getSourcesToDisplay(
      this.state.searchResult
    );
    return (
      <>
        <SelectAllCheckbox
          name="SelectSearchAll"
          value={this.state.selectSearchAll}
          onChange={this.toggleSelectSearchAll}
        />
        {this.renderList(searchSourcesDisplayList)}
      </>
    );
  };

  getSourcesToDisplay = sourceIds => {
    let availableSources = sourceIds.filter(sourceId => {
      const s = this.state.sourcesObj[sourceId];
      const disabled = this.props.match
        ? s.matchedToDifferentLead || s.distributed
        : s.distributedToDifferentLead || s.matched;
      if (!disabled) {
        return sourceId;
      }
    });
    return availableSources;
  };

  renderList = sourcesIds =>
    sourcesIds.map(sId => {
      const s = this.state.sourcesObj[sId];
      const checked = this.props.match
        ? (s.checked && !s.distributed) || s.matchedToDifferentLead
        : (s.checked && !s.matched) || s.distributedToDifferentLead;
      const disabled = this.props.match
        ? s.matchedToDifferentLead || s.distributed
        : s.distributedToDifferentLead || s.matched;

      let checkbox = (
        <Checkbox
          label={s.label}
          name={s.id}
          value={checked}
          disabled={disabled}
          onChange={() => this.toggleSource(s.id)}
          py={0}
        />
      );
      if (disabled) {
        const title = this._getDisabledTooltipMessage(s);
        checkbox = (
          <Tooltip title={title} size="small" placement="right">
            {checkbox}
          </Tooltip>
        );
      } else if (isValidURL(s?.value?.source_name)) {
        checkbox = (
          <Tooltip title={s.id} size="mini">
            {checkbox}
          </Tooltip>
        );
      }

      return (
        <div key={s.id}>
          <Flex py={0}>{checkbox}</Flex>
        </div>
      );
    });

  renderDistributionSuggestion = () => (
    <Text
      textSize={2}
      $fontWeight="regular"
      $color="text.gray.1"
      display="inline"
      py={5}
    >
      {
        MANAGE_REFERRAL_SOURCES_MESSAGES[this.props.tabView][
          "distributionSuggestion"
        ]
      }
      <Link
        $color="text.gray.1"
        variant="underline"
        target="_blank"
        href={ZENDESK_DISTRIBUTION_SUGGESTION}
        ml={1}
      >
        Learn more.
      </Link>
    </Text>
  );

  renderNoSearchResult = () => (
    <Text py={5} textSize={2} $fontWeight="regular" $color="text.gray.1">
      No search results found.
    </Text>
  );

  renderNoNwvs = () => (
    <Text py={5} textSize={2} mr={2} $fontWeight="regular" $color="text.gray.1">
      {MANAGE_REFERRAL_SOURCES_MESSAGES[this.props.tabView]["no_nwvs_result"]}
    </Text>
  );

  getSearchResult = (sources, searchStr) =>
    sources
      .filter(x => x.label.toLowerCase().includes(searchStr.toLowerCase()))
      .map(s => s.id);

  get haveChanges() {
    const haveSourcesChanges = this.props.sources.some(
      s => s.checked !== this.state.sourcesObj[s.id]?.checked
    );
    return haveSourcesChanges;
  }

  onSearchHandler = e => {
    const searchStr = e.currentTarget.value;
    let searchResult = [];
    if (searchStr) {
      const sources = Object.values(this.state.sourcesObj);
      searchResult = this.getSearchResult(sources, searchStr);
    }
    this.setState({
      searchStr,
      searchResult
    });
  };

  onSearchCancelHandler = () => {
    this.setState({
      searchStr: "",
      searchResult: [],
      selectSearchAll: false
    });
  };

  onSaveHandler = unbouncedVisitorsOnly => {
    const selectedSources = Object.values(this.state.sourcesObj).filter(
      s => s.checked
    );
    this.props.onSave({
      sources: selectedSources,
      unbouncedVisitorsOnly
    });
  };

  toggleSource = id => {
    this.setState(state => {
      const sources = { ...state.sourcesObj };
      sources[id].checked = !sources[id].checked;
      return {
        sourcesObj: sources,
        selectAll: this._filterSourcesObjs(
          sources,
          this._getUnSelectedSourcesIds()
        ).every(s => s.checked),
        selectMatchedAll: this._filterSourcesObjs(
          sources,
          this._getSelectedSourcesIds()
        ).every(s => s.checked),
        selectSearchAll: this._filterSourcesObjs(
          sources,
          this.state.searchResult
        ).every(s => s.checked)
      };
    });
  };

  toggleSelectAll = () => {
    const unMatchedIds = this._getUnSelectedSourcesIds();
    if (this.state.selectAll) {
      this.setState({
        selectAll: false,
        selectSearchAll: false,
        sourcesObj: this._unSelectSourcesForIds(unMatchedIds)
      });
    } else {
      this.setState({
        selectAll: true,
        selectSearchAll: true,
        sourcesObj: this._selectSourcesForIds(unMatchedIds)
      });
    }
  };

  toggleSelectMatchedAll = () => {
    const selectedIds = this._getSelectedSourcesIds();
    if (this.state.selectSelectedAll) {
      this.setState({
        selectSelectedAll: false,
        sourcesObj: this._unSelectSourcesForIds(selectedIds)
      });
    } else {
      this.setState({
        selectSelectedAll: true,
        sourcesObj: this._selectSourcesForIds(selectedIds)
      });
    }
  };

  toggleSelectSearchAll = () => {
    const searchIds = this.state.searchResult;
    if (this.state.selectSearchAll) {
      this.setState({
        selectSearchAll: false,
        sourcesObj: this._unSelectSourcesForIds(searchIds)
      });
    } else {
      this.setState({
        selectSearchAll: true,
        sourcesObj: this._selectSourcesForIds(searchIds)
      });
    }
  };

  toggleNoWebsiteSources = () => {
    this.setState(state => ({ _noWebsiteSources: !state._noWebsiteSources }));
  };

  _getSelectedSourcesIDs = sourcesIDs =>
    sourcesIDs.filter(id => this.state.sourcesObj[id].checked);

  applyNwvFilter = filters => {
    this.setState({ nwvFilters: filters });
    this.props.onSelectFilter(filters);
  };

  isFetching = () =>
    this.props.fetchingNWVs && !this.checkForDistributionSuggestion();

  render() {
    const saveText =
      MANAGE_REFERRAL_SOURCES_MESSAGES[this.props.tabView]["on_save_text"];
    const allAllocated =
      this.state.unselectedSources.length == 0 &&
      !this.isFetching() &&
      this.props.sources?.length &&
      !this.state.nwvFilters?.length;
    return (
      <Flex flexDirection="column" minWidth="16.5rem" width="100%">
        <Flex flexDirection="column" py={4} px={5}>
          <Flex pb={3} alignItems="center">
            <Text textSize={2} $fontWeight="medium" mr={2}>
              New Website Visitor Sources
            </Text>
            <Tooltip
              size="small"
              title="Website referral source selection options reflect property-specific, all-time referral source data from Google Analytics."
              placement="top"
            >
              <Icon.Tooltip size="1rem" color="text.white.1" />
            </Tooltip>
          </Flex>
          <Flex pb={3} alignItems="center">
            <Text textSize={2} $fontWeight="regular" $color="text.gray.2">
              {
                MANAGE_REFERRAL_SOURCES_MESSAGES[this.props.tabView][
                  "select_nwv_message"
                ]
              }
            </Text>
          </Flex>
        </Flex>
        <Divider variant="horizontal" bg="gray.4" flexShrink={0} />
        <RenderIf condition={this.props.isSelectLeadSource}>
          <RenderIf
            condition={
              !_isEmpty(this.state.nwvFilters) ||
              this.state.unselectedSources.length > 0
            }
          >
            <Flex pt={5} pb={5} px={5} flexDirection="row">
              <NwvFilters
                onApplyFilterHandler={this.applyNwvFilter}
                containerProps={{
                  style: { whiteSpace: "nowrap" },
                  marginRight: "1rem"
                }}
                filters={this.state.nwvFilters}
              />
              <TextInput
                size="large"
                placeholder="Search"
                iconLeft={<Icon.Search color="text.white.1" size={4} />}
                iconRight={
                  !!this.state.searchStr && (
                    <Button variant="icon" size="small">
                      <Icon.Close
                        color="text.gray.1"
                        size={3}
                        onClick={this.onSearchCancelHandler}
                      />
                    </Button>
                  )
                }
                value={this.state.searchStr}
                onChange={this.onSearchHandler}
                icon={<Icon.Search color="text.white.1" />}
                flexBasis="100%"
                width="auto"
              />
            </Flex>
          </RenderIf>
          <RenderIf condition={allAllocated}>
            <Flex pt={5} pb={5} px={5} flexDirection="row">
              <Text
                textSize={2}
                $fontWeight="regular"
                $color="text.gray.1"
                textAlign="center"
              >
                Great job! All website referral sources have been matched or
                distributed.
              </Text>
            </Flex>
          </RenderIf>
          <Divider variant="horizontal" bg="gray.4" flexShrink={0} />
          <RenderIf condition={this.isFetching()}>
            <Flex py={4} px={4} flexDirection="column">
              <ValueLoader mb={2} textSize={3} />
              <ValueLoader mb={2} textSize={3} />
              <ValueLoader mb={2} textSize={3} />
            </Flex>
          </RenderIf>
          <RenderIf condition={!this.isFetching()}>
            <Box
              className="concrete-scrollbar"
              pl={5}
              pb={1}
              flexDirection="column"
              overflow="auto"
            >
              {this.renderWebsiteSources()}
            </Box>
            <Flex mt="auto" flexDirection="column">
              <Divider variant="horizontal" bg="gray.4" flexShrink={0} />
              <ReferralSourcesSave
                haveChanges={this.haveChanges}
                checked={this.props.unbouncedVisitorsOnly}
                saveCompleted={this.props.saveCompleted}
                saveTxt={saveText}
                onSave={this.onSaveHandler}
              />
            </Flex>
          </RenderIf>
        </RenderIf>
        <RenderIf condition={!this.props.isSelectLeadSource}>
          <Flex
            width="100%"
            height="100%"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            <Box width="14.5rem">
              <Text
                textSize={2}
                $fontWeight="regular"
                $color="text.gray.1"
                textAlign="center"
              >
                {
                  MANAGE_REFERRAL_SOURCES_MESSAGES[this.props.tabView][
                    "nwv_body_message"
                  ]
                }
              </Text>
            </Box>
          </Flex>
        </RenderIf>
      </Flex>
    );
  }
}

export default ReferralSourcesNWV2;
