import React, { useEffect } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import _cloneDeep from "lodash/cloneDeep";
import _find from "lodash/find";

import Expenses from "../../components/expenses";
import { withLogin } from "../shared/login";

import { expenseActions } from "../../redux_base/actions";
import { expenseCopySuffix } from "../../constants";

const ExpensesContainer = ({
  isPostSuccessful,
  fetchingExpenses,
  fetchingProperties,
  dispatch,
  expenses,
  history,
  properties
}) => {
  // TODO: get these props from Django

  useEffect(() => {
    if (!properties.length) {
      dispatch(expenseActions.requestProperties({}));
    }
    if (!expenses.length) {
      dispatch(expenseActions.requestExpenses({}));
    }
  }, []);

  useEffect(() => {
    if (isPostSuccessful) {
      dispatch(expenseActions.updateState({ isPostSuccessful: false }));
      dispatch(expenseActions.requestExpenses({}));
    }
  }, [isPostSuccessful]);

  const onCopy = id => {
    // ids to null
    const newData = _cloneDeep(_find(expenses, ["id", id]));
    newData.id = null;
    newData.actual_expenses?.map(exp => {
      exp.id = null;
      return exp;
    });
    // copy name
    newData.expense_name = newData.expense_name + expenseCopySuffix;
    dispatch(expenseActions.requestPostExpense(newData));
  };

  const onEdit = id => {
    history.push(`/expenses/add/${id}`);
  };

  const onDelete = id => {
    dispatch(expenseActions.requestDeleteExpense(id));
  };

  const checkEditingEnabled = id => {
    const data = _find(expenses, ["id", id]);
    if (data) {
      const property = _find(properties, ["property_id", data.property_id]);
      return property && property.is_admin;
    }
    return false;
  };

  return (
    <Expenses
      expenses={expenses}
      loading={fetchingExpenses || fetchingProperties}
      properties={Object.values(properties)}
      onCopy={onCopy}
      onEdit={onEdit}
      onDelete={onDelete}
      checkEditingEnabled={checkEditingEnabled}
    />
  );
};

const mapState = state => ({
  ...state.expenses
});

export default withRouter(connect(mapState)(withLogin(ExpensesContainer)));
