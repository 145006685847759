import React from "react";
import PropTypes from "prop-types";
import { TransitionMotion, presets, spring } from "react-motion";

import { Alert, Close } from "../../icons";

import "./notifications.scss";

const DEFAULT_NETWORK_ERROR_MESSAGE =
  "There was a brief problem communicating with the server. If your request failed, please try again in a moment.";

class NotificationsTemplate extends React.PureComponent {
  static propTypes = {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    message: PropTypes.string.isRequired,
    lifetime: PropTypes.number,
    onClose: PropTypes.func.isRequired
  };

  static defaultProps = {
    lifetime: 10000
  };

  componentDidMount() {
    if (this.props.lifetime) {
      this.timeoutId = setTimeout(this.onCLoseHandler, this.props.lifetime);
    }
  }

  onCLoseHandler = () => {
    clearTimeout(this.timeoutId);
    this.props.onClose(this.props.id);
  };

  hackedMessageParser = message => {
    let res = message;
    if (
      message.indexOf("A request to") > -1 &&
      message.indexOf("failed") > -1
    ) {
      res = process.env.NETWORK_ERROR_MESSAGE || DEFAULT_NETWORK_ERROR_MESSAGE;
    }
    return res;
  };

  render() {
    const { message, onClose, id, ...otherProps } = this.props;
    return (
      <div className="notification" {...otherProps}>
        <div className="notification__tone" />
        <Alert className="notification__icon" />
        <div className="notification__content">
          <div className="notification__title">Error!</div>
          <div className="notification__message">
            {this.hackedMessageParser(message)}
          </div>
        </div>
        <div className="notification__close">
          <Close
            className="notification__close-icon"
            onClick={this.onCLoseHandler}
          />
        </div>
      </div>
    );
  }
}

class Notifications extends React.PureComponent {
  static propTypes = {
    notifications: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        lifetime: PropTypes.number,
        message: PropTypes.string
      })
    ),
    onClose: PropTypes.func
  };

  static defaultProps = {
    notifications: []
  };

  getDefaultStyles = () => {
    return this.props.notifications.map(data => ({
      key: `notification-${data.id}`,
      data,
      style: { maxHeight: 0, opacity: 1, marginTop: 0 }
    }));
  };

  getStyles = () => {
    return this.props.notifications.map(data => ({
      key: `notification-${data.id}`,
      data,
      style: {
        marginTop: spring(8, presets.gentle),
        maxHeight: spring(100, presets.gentle),
        opacity: spring(1, presets.gentle)
      }
    }));
  };

  willEnter() {
    return {
      marginTop: 0,
      maxHeight: 0,
      opacity: 1
    };
  }

  willLeave() {
    return {
      marginTop: spring(0),
      maxHeight: spring(0),
      opacity: spring(0)
    };
  }

  getNotifications = styles => (
    <>
      {styles.map(({ key, style, data: { id, message, lifetime } }) => (
        <NotificationsTemplate
          id={id}
          key={key}
          message={message}
          lifetime={lifetime}
          style={style}
          onClose={this.onCLoseHandler}
        />
      ))}
    </>
  );

  onCLoseHandler = notificationId => {
    this.props.onClose(notificationId);
  };

  render() {
    return (
      <div className="notifications">
        <TransitionMotion
          defaultStyles={this.getDefaultStyles()}
          styles={this.getStyles()}
          willLeave={this.willLeave}
          willEnter={this.willEnter}
        >
          {this.getNotifications}
        </TransitionMotion>
      </div>
    );
  }
}

export default Notifications;
