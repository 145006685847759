export const createReportConfig = {
  fields: {
    // Section 1
    property_id: {
      type: "select",
      validators: {
        isRequired: {
          message: "Property selection is required."
        }
      }
    },
    content: {
      type: "select",
      validators: {
        minLength: {
          message: "1+ content selection is required.",
          value: 1
        }
      }
    }
  }
};
