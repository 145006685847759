import React from "react";

import IconBase from "./icon_base";

const Close = props => (
  <IconBase viewBox="0 0 13 14" {...props}>
    <path
      d="M1.43532 1.92201L11.3649 12.0613"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M11.4774 1.90854L1.83659 12.0504"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </IconBase>
);

export default Close;
