import cn from "classnames";
import PropTypes from "prop-types";
import React from "react";

import "./alert_label.scss";

export default class AlertLabel extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    type: PropTypes.oneOf(["rect", "circle"]),
    size: PropTypes.oneOf(["big"]),
    position: PropTypes.oneOf(["top-right", "top-left"])
  };

  static defaultProps = {
    labelType: "rect",
    onClick: () => {},
    labelPosition: "top-right"
  };

  render() {
    const { position, type, size, className, label } = this.props;
    const classes = cn(
      "alert-label",
      {
        [`alert-label-${position}`]: position,
        [`alert-label-${type}`]: type,
        [`alert-label-${size}`]: size
      },
      className
    );
    return (label && <div className={classes}>{label}</div>) || null;
  }
}
