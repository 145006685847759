import { Link } from "concrete-ui";
import React from "react";

const TooltipLink = ({ link, linkText, ...linkProps }) => (
  <Link
    variant="underline"
    $color="text.gray.1"
    textSize={1}
    href={link}
    {...linkProps}
  >
    {linkText}
  </Link>
);

export default TooltipLink;
