import PropTypes from "prop-types";
import React from "react";
import _isEmpty from "lodash/isEmpty";

import ModalForm from "../modal_form";
import { SelectSearch } from "../select";
import { CreateLabelFactory } from "../select/select_components";
import FormField from "../form_field";

import { configCompany } from "./validators";
import "./company_modal.scss";
import useValidation from "../../utils/validator_hook";

const CompanyModal = ({
  data,
  onChangeCompany,
  suggestion,
  onClose,
  onAddNew,
  onSave,
  onSuccess,
  onError,
  onConfirmSuggestion,
  theme,
  loadCompany,
  companies,
  isLoading,
  isOpen
}) => {
  const onSuccessHandle = values => {
    onSaveHandle(onSuccess, onError || onErrorHandle)(values);
  };

  const initialValues = {
    ...data,
    companies: companies
  };

  const {
    formItems,
    errors,
    setErrors,
    getFormProps,
    getItemProps,
    setFormItems
  } = useValidation(configCompany, initialValues, onSuccessHandle);

  const formatCreateLabel = CreateLabelFactory("Add Company");

  const onCreateCompany = value => {
    const option = { label: value, value, isNew: true };
    setFormItems({ company: option });
  };

  const onChangeCompanyHandle = company => {
    setFormItems({ company: company });
    onChangeCompany(company);
  };

  const companySuggestion = () => {
    return (
      <div className="company-modal__suggestion">
        <div className="company-modal__suggestion-text">
          You typed{" "}
          <strong className="company-modal__suggestion-strong">
            {formItems?.company?.label}
          </strong>
          , did you mean{" "}
          <strong className="company-modal__suggestion-strong">
            {suggestion?.name}
          </strong>
          ?
        </div>
        <div className="company-modal__suggestion-title">Suggestion</div>
        <strong className="company-modal__suggestion-strong">
          {suggestion?.name}
        </strong>
      </div>
    );
  };

  const companySelect = () => (
    <>
      <FormField
        label="Company Name"
        showError={!!errors.company}
        showIcon={false}
        theme="modern"
        {...(!!errors.company && { error: errors.company })}
      >
        <SelectSearch
          className="account-settings-field__input company-modal__select"
          theme={theme}
          isAsync={false}
          placeholder=""
          components={{ DropdownIndicator: () => null }}
          onInputChange={loadCompany}
          options={companies}
          isLoading={isLoading}
          isCreatable={true}
          formatCreateLabel={formatCreateLabel}
          {...getItemProps("company")}
          onChange={onChangeCompanyHandle}
          onCreateOption={onCreateCompany}
        />
      </FormField>
    </>
  );

  const getParams = () => {
    const params = {};
    if (_isEmpty(suggestion)) {
      params["content"] = companySelect;
      params["title"] = "Company Info";
      params["onCancel"] = onClose;
      params["onSaveHandle"] = onSave;
    } else {
      params["content"] = companySuggestion;
      params["title"] = "Company Name";
      params["confirmText"] = "Yes, Confirm Suggestion";
      params["cancelText"] = "No, Add New";
      params["onCancel"] = onAddNew;
      params["onSaveHandle"] = onConfirmSuggestion;
    }
    return params;
  };

  const {
    content,
    title,
    confirmText,
    cancelText,
    onSaveHandle,
    onCancel
  } = getParams();

  const onErrorHandle = errors => {
    const validationErrors = {};
    for (let k of Object.keys(errors)) {
      validationErrors[k] = errors[k][0].message;
    }
    setErrors(validationErrors);
  };

  return (
    <ModalForm
      className="company-modal"
      values={formItems}
      theme={theme}
      title={title}
      isOpen={isOpen}
      cancelButton={true}
      onClose={onClose}
      onCancel={onCancel}
      formProps={getFormProps()}
      confirmText={confirmText}
      cancelText={cancelText}
    >
      {content()}
    </ModalForm>
  );
};

CompanyModal.propTypes = {
  isOpen: PropTypes.bool,
  theme: PropTypes.oneOf(["gray", "highlight"]),
  data: PropTypes.shape({
    company: PropTypes.object
  }),
  suggestion: PropTypes.object,
  isLoading: PropTypes.bool,
  companies: PropTypes.array,
  loadCompany: PropTypes.func,
  onChangeCompany: PropTypes.func,
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
  onSave: PropTypes.func,
  onAddNew: PropTypes.func,
  onConfirmSuggestion: PropTypes.func
};

CompanyModal.defaultProps = {
  theme: "gray",
  companies: [],
  suggestion: {},
  loadCompany() {},
  onChangeCompany() {},
  onSave() {},
  onSuccess() {},
  onAddNew() {},
  onConfirmSuggestion() {}
};

export default CompanyModal;
