import React from "react";
import { useHistory } from "react-router-dom";

import { PROPERTY_CHART_PROPS } from "../../constants";
import { _exploreHandler } from "../../utils/widget";
import Narratives from "../narratives";

import { PROPERTY_CHART_TEXT } from "./chart_text";
import { ChartHOC } from "./widget-utils";
import "./property_page.scss";

const category = "middle_funnel";

const exploreHandler = _exploreHandler(category);

const PropertyMiddleFunnel = React.forwardRef((props, ref) => {
  const history = useHistory();
  const {
    date_selection,
    isShowInsights,
    propertyId,
    propertyName,
    newWidgetData,
    loaderState
  } = props;

  const exploreOnClick = exploreHandler(propertyId)(history);
  const { start_date, end_date } = date_selection;
  return (
    <div className="property__widgets-container" ref={ref}>
      <div className="property__title">Middle Funnel</div>
      <div className="property__widgets two_column">
        <div>
          <ChartHOC
            kpi="tours"
            decimalPlaces={0}
            exploreArgs={["property", "tours"]}
            newWidgetData={newWidgetData}
            loaderState={loaderState}
            exploreOnClick={exploreOnClick}
            titleTooltipDescription={PROPERTY_CHART_TEXT["tours"]["tooltip"]}
            chartLabel={PROPERTY_CHART_TEXT["tours"]["chartLabel"]}
            dateRange={{ start: start_date, end: end_date }}
            {...PROPERTY_CHART_PROPS["tours"]}
          />
          <div className="widget__container">
            <Narratives
              kpiId="tours"
              view="property"
              isShowWidget={isShowInsights}
              name={propertyName}
            />
          </div>
        </div>
        <div>
          <ChartHOC
            kpi="tou_app"
            exploreArgs={["property", "tours_to_applications"]}
            newWidgetData={newWidgetData}
            loaderState={loaderState}
            exploreOnClick={exploreOnClick}
            titleTooltipDescription={PROPERTY_CHART_TEXT["tou_app"]["tooltip"]}
            chartLabel={PROPERTY_CHART_TEXT["tou_app"]["chartLabel"]}
            dateRange={{ start: start_date, end: end_date }}
            {...PROPERTY_CHART_PROPS["tou_app"]}
          />
          <div className="widget__container">
            <Narratives
              kpiId="tours_to_applications"
              view="property"
              isShowWidget={isShowInsights}
              name={propertyName}
            />
          </div>
        </div>
      </div>
      <div className="property__widgets two_column">
        <div>
          <ChartHOC
            decimalPlaces={0}
            kpi="lease_applications"
            exploreArgs={["property", "lease_applications"]}
            newWidgetData={newWidgetData}
            loaderState={loaderState}
            exploreOnClick={exploreOnClick}
            chartLabel={PROPERTY_CHART_TEXT["lease_applications"]["chartLabel"]}
            titleTooltipDescription={
              PROPERTY_CHART_TEXT["lease_applications"]["tooltip"]
            }
            dateRange={{ start: start_date, end: end_date }}
            {...PROPERTY_CHART_PROPS["lease_applications"]}
          />
          <div className="widget__container">
            <Narratives
              kpiId="lease_applications"
              view="property"
              isShowWidget={isShowInsights}
              name={propertyName}
            />
          </div>
        </div>
        <div>
          <ChartHOC
            kpi="app_approvals"
            exploreArgs={["property", "app_approvals"]}
            newWidgetData={newWidgetData}
            loaderState={loaderState}
            exploreOnClick={exploreOnClick}
            chartLabel={PROPERTY_CHART_TEXT["app_approvals"]["chartLabel"]}
            titleTooltipDescription={
              PROPERTY_CHART_TEXT["app_approvals"]["tooltip"]
            }
            dateRange={{ start: start_date, end: end_date }}
            {...PROPERTY_CHART_PROPS["app_approvals"]}
          />
          <div className="widget__container">
            <Narratives
              kpiId="lease_applications_to_approved"
              view="property"
              isShowWidget={isShowInsights}
              name={propertyName}
            />
          </div>
        </div>
      </div>
    </div>
  );
});

export default PropertyMiddleFunnel;
