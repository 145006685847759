export const configCompany = {
  fields: {
    company: {
      type: "object",
      validators: {
        value: {
          maxLength: {
            value: 255,
            message: "Company name is too long."
          },
          isRequired: {
            message: "Field is required."
          }
        }
      }
    }
  }
};
