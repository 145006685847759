import React from "react";
import PropTypes from "prop-types";
import _get from "lodash/get";
import { NavLink } from "react-router-dom";
import { Button, Icon } from "concrete-ui";

import AlertMiniSettings from "../../components/alert_mini_settings";
import Checkbox from "../../components/checkbox";
import Container from "../../components/container";
import Select from "../../components/select";
import UserIcon from "../../components/user_icon";
import PaginationTable from "../pagination_table";
import { KPI_CATEGORY, KPI_MATCHER, KPI_URL_LABEL } from "../../constants";
import KPI_NAMES_TABLE from "../../constants/kpi_names_table.json";
import { formatDateWithTokens } from "../../utils/formatters";
import { dateFormat } from "../../utils/dates";

import "./alerts_table.scss";

class AlertsTable extends React.PureComponent {
  static eventsType = PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      kpi: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      rule: PropTypes.string,
      period: PropTypes.node,
      creator: PropTypes.shape({
        avatar: PropTypes.string,
        name: PropTypes.string,
        isName: PropTypes.bool
      }),
      subscriptions: PropTypes.shape({
        email: PropTypes.bool,
        enabled: PropTypes.bool,
        // notification was not declared but appears to be required
        notification: PropTypes.bool
      }),
      alertId: PropTypes.string,
      isNew: PropTypes.bool,
      eventId: PropTypes.string,
      date: PropTypes.instanceOf(Date),
      // the following are dependencies that our app uses but were not declared here.
      // it cost me a lot of time to find these and capture them
      kpiId: PropTypes.string,
      entityId: PropTypes.string,
      startDate: PropTypes.instanceOf(Date),
      endDate: PropTypes.instanceOf(Date),
      alertProjectId: PropTypes.string,
      createdDate: PropTypes.instanceOf(Date)
    })
  );

  static propTypes = {
    title: PropTypes.string,
    type: PropTypes.string,
    pages: PropTypes.number,
    data: AlertsTable.eventsType,
    selected: PropTypes.object,
    loading: PropTypes.bool,
    onSelect: PropTypes.func,
    onDelete: PropTypes.func,
    onMarkAsRead: PropTypes.func,
    toggleSubscription: PropTypes.func
  };

  static defaultProps = {
    title: "Property",
    pages: 0,
    data: [],
    selected: new Set(),
    loading: false,
    onSelect: () => {},
    onDelete: () => {},
    onMarkAsRead: () => {},
    toggleSubscription: () => {}
  };

  constructor(props) {
    super(props);
    this.state = {
      filter: this.selectAllOption
    };
  }

  selectAllOption = { label: `All ${this.props.title}`, value: "all" };

  pageSize = 10;

  defaultSorted = [
    {
      id: "name",
      desc: false
    }
  ];

  get isHaveNew() {
    return this.props.data.some(x => x.isNew);
  }

  get isSelectAll() {
    const numberNew = this.props.data.filter(alert => alert.isNew).length;
    const numberSelected = this.props.selected.size;
    return numberNew === numberSelected && numberNew !== 0;
  }

  get tableColumns() {
    return [
      {
        id: "selection",
        minWidth: "32px",
        disableSortBy: true,
        Header: () => {
          if (this.isHaveNew) {
            return (
              <Checkbox
                className="alerts-table__checkbox"
                isSelected={this.isSelectAll}
                onClick={this.onSelectAll}
              />
            );
          } else {
            return null;
          }
        },
        className: "alerts-table__cell-checkbox",
        headerClassName: "alerts-table__cell-checkbox",
        ValueComponent: ({ data }) => {
          if (!data?.isNew) {
            return null;
          }
          const eventId = data.eventId;
          return (
            <Checkbox
              className="alerts-table__checkbox"
              isSelected={this.props.selected.has(eventId)}
              onClick={e => this.onSelect(e, eventId)}
            />
          );
        }
      },
      {
        minWidth: "196px",
        Header: this.props.title,
        accessor: "name"
      },
      {
        minWidth: "270px",
        Header: "Key Performance Indicator",
        accessor: "kpi",
        ValueComponent: ({ data, value }) => {
          let kpiUrl = "";
          if (data.eventId) {
            if (this.props.type === "property") {
              kpiUrl = this.getPropertyKPIUrl(data);
            } else {
              kpiUrl = this.getPortfolioKPIUrl(data);
            }
          }
          if (kpiUrl) {
            return (
              <NavLink className="alerts-table__link" to={kpiUrl}>
                {value} →
              </NavLink>
            );
          } else {
            if (KPI_NAMES_TABLE[value]) {
              return KPI_NAMES_TABLE[value]["title"];
            } else {
              return value || "";
            }
          }
        }
      },
      {
        minWidth: "165px",
        Header: "Actual Value",
        accessor: "value",
        className: "alerts-table__cell-border",
        headerClassName: "alerts-table__cell-border",
        csvFormatter: value => value || "N/A",
        ValueComponent: ({ data }) => {
          if (data.value) {
            return data.value;
          } else {
            return <span className="alerts-table__text-gray"> N/A </span>;
          }
        }
      },
      {
        minWidth: "220px",
        Header: "Trigger Rule",
        accessor: "rule",
        disableSortBy: true
      },
      {
        minWidth: "245px",
        Header: "Time Period",
        accessor: "date",
        className: "alerts-table__cell-border",
        headerClassName: "alerts-table__cell-border",
        csvFormatter: (value, dataPoint) => {
          const start = dataPoint.startDate;
          const end = dataPoint.endDate;
          if (start && end) {
            return `${start} to ${end}`;
          } else if (dataPoint.date) {
            return formatDateWithTokens(dataPoint.date, "yyyy-MM-dd");
          }
          return "Not Triggered";
        },
        ValueComponent: ({ data }) => {
          if (data.startDate && data.endDate) {
            return (
              <>
                {dateFormat(data.startDate, "MM/dd/yyyy")}
                <span className="alerts-table__text-gray"> to </span>
                {dateFormat(data.endDate, "MM/dd/yyyy")}
              </>
            );
          } else {
            return (
              <>
                <span className="alerts-table__text-gray"> Not Triggered </span>
              </>
            );
          }
        }
      },
      {
        minWidth: "217px",
        Header: "Creator",
        accessor: "creator.name",
        ValueComponent: ({ data }) => (
          <div className="alerts-table__cell-avatar">
            <UserIcon
              className="alerts-table__avatar"
              profile_image_url={data.creator.avatar}
              account_name={data.creator.name}
            />
            <div
              className="alerts-table__name"
              style={
                !data.creator.isName ? { "text-transform": "lowercase" } : {}
              }
            >
              {data.creator.name}
            </div>
          </div>
        )
      },
      {
        id: "settings",
        minWidth: "65px",
        Header: null,
        disableSortBy: true,
        className: "alerts-table__cell-menu",
        ValueComponent: ({ data }) => (
          <AlertMiniSettings
            alertId={data.alertId}
            eventId={data.id}
            email={data.subscriptions.email}
            notification={data.subscriptions.notification}
            onDelete={this.props.onDelete}
            toggleSubscription={this.props.toggleSubsciption}
          />
        )
      }
    ];
  }

  getTrGroupProps = cell => {
    const props = {};
    if (cell?.row?.original.isNew) {
      props.style = { border: "1px solid #006eff" };
      if (cell.column.id === "selection") {
        props.style.borderRight = "none";
      } else if (cell.column.id === "settings") {
        props.style.borderLeft = "none";
      } else {
        props.style.borderRight = "none";
        props.style.borderLeft = "none";
      }
    }
    return props;
  };

  getPropertyKPIUrl = data => {
    let kpiUrl = "";
    const start = dateFormat(data.startDate, "yyyy-MM-dd");
    const end = dateFormat(data.endDate, "yyyy-MM-dd");
    const kpiLabelConversion = Object.keys(KPI_MATCHER).find(
      key => KPI_MATCHER[key] === data.kpiId
    );
    const kpiCategory = _get(
      KPI_CATEGORY,
      ["property", kpiLabelConversion],
      "performance"
    );
    const kpiLabel = _get(
      KPI_URL_LABEL,
      ["property", kpiLabelConversion],
      null
    );
    if (
      this.props.type &&
      data.entityId &&
      kpiCategory &&
      kpiLabel &&
      start &&
      end
    ) {
      kpiUrl = `${this.props.type}/${data.alertProjectId}/${kpiCategory}/${kpiLabel}?start=${start}&end=${end}`;
    }
    return kpiUrl;
  };

  getPortfolioKPIUrl = data => {
    let kpiUrl = "";
    const start = dateFormat(data.startDate, "yyyy-MM-dd");
    const end = dateFormat(data.endDate, "yyyy-MM-dd");
    const kpiCategory = _get(
      KPI_CATEGORY,
      ["property_group", data.kpiId],
      "performance"
    );
    if (
      this.props.type &&
      data.entityId &&
      kpiCategory &&
      data.kpiId &&
      start &&
      end
    ) {
      kpiUrl = `${this.props.type}/${data.entityId}/${kpiCategory}?start=${start}&end=${end}`;
    }
    return kpiUrl;
  };

  onSelect = (e, eventId) => {
    const selected = new Set(this.props.selected);
    if (selected.has(eventId)) {
      selected.delete(eventId);
    } else {
      selected.add(eventId);
    }
    this.props.onSelect(selected);
  };

  onSelectAll = () => {
    const selected = new Set();
    if (!this.isSelectAll) {
      this.props.data
        .filter(alert => alert.isNew)
        .forEach(alert => {
          selected.add(alert.eventId);
        });
    }
    this.props.onSelect(selected);
  };

  onChangeFilter = option => {
    this.setState({ filter: option });
  };

  getFilterOptions = () => {
    const options = [this.selectAllOption];
    const names = new Set();
    this.props.data.forEach(({ name }) => names.add(name));
    names.forEach(name => {
      options.push({
        label: name,
        value: name
      });
    });
    return options;
  };

  sortData = data => {
    data.sort(function(x, y) {
      return y.createdDate - x.createdDate;
    });
    data.sort(function(x, y) {
      return x.isNew === y.isNew ? 0 : x.isNew ? -1 : 1;
    });
    return data;
  };

  getData = () => {
    let data;
    const filterName = this.state.filter.value;
    if (filterName === this.selectAllOption.value) {
      data = this.props.data;
    } else {
      data = this.props.data.filter(({ name }) => name === filterName);
    }
    data = this.sortData(data);
    return data;
  };

  getCsvFilename = () => {
    let today = new Date();
    today = formatDateWithTokens(today, "yyyy-MM-dd");
    return `Remarkably_Alerts_${today}`;
  };

  csvPeriodFormat = (accessor, dataPoint) => {
    let start = dataPoint.startDate;
    let end = dataPoint.endDate;
    if (start && end) {
      return `${start} to ${end}`;
    } else if (dataPoint.date) {
      formatDateWithTokens(dataPoint.date, "yyyy-MM-dd");
    }
    return "Not Triggered";
  };

  render() {
    return (
      <div className="alerts-table">
        <Container className="alerts-table__controls">
          <Button
            variant="secondary"
            size="small"
            disabled={!this.props.selected.size}
            onClick={this.props.onMarkAsRead}
          >
            <Icon.Check color="white.1" />
            Mark as Read
          </Button>
          <Select
            className="select--small alerts-table__filter"
            value={this.state.filter}
            onChange={this.onChangeFilter}
            options={this.getFilterOptions()}
          />
        </Container>
        <div className="alerts-table__divider" />
        <div className="alerts-table__background">
          <Container>
            <PaginationTable
              loading={this.props.loading}
              className="alerts-table__table"
              columns={this.tableColumns}
              data={this.getData()}
              paginationSize={this.pageSize}
              getCellProps={this.getTrGroupProps}
              tableType="alerts"
              csvFileName={this.getCsvFilename()}
            />
          </Container>
        </div>
      </div>
    );
  }
}

export default AlertsTable;
