import React from "react";
import { useHistory } from "react-router-dom";

import { PROPERTY_CHART_PROPS } from "../../constants";
import { _exploreHandler } from "../../utils/widget";
import Narratives from "../narratives";

import { PROPERTY_CHART_TEXT } from "./chart_text";
import { ChartHOC } from "./widget-utils";
import "./property_page.scss";

const category = "performance";

const exploreHandler = _exploreHandler(category);

const PropertyPerformance = React.forwardRef((props, ref) => {
  const history = useHistory();
  const {
    date_selection,
    isShowInsights,
    propertyId,
    propertyName,
    newWidgetData,
    loaderState
  } = props;
  const exploreOnClick = exploreHandler(propertyId)(history);

  const { start_date, end_date } = date_selection;
  return (
    <div className="property__widgets-container" ref={ref}>
      <div className="property__title">Performance</div>
      <div className="property__widgets three_column">
        <div>
          <ChartHOC
            kpi="occupancy_rate"
            exploreArgs={["property", "occupied_rate"]}
            newWidgetData={newWidgetData}
            loaderState={loaderState}
            exploreOnClick={exploreOnClick}
            chartLabel={PROPERTY_CHART_TEXT["occupancy_rate"]["chartLabel"]}
            titleTooltipDescription={
              PROPERTY_CHART_TEXT["occupancy_rate"]["tooltip"]
            }
            dateRange={{ start: start_date, end: end_date }}
            {...PROPERTY_CHART_PROPS["occupancy_rate"]}
          />

          <div className="widget__container">
            <Narratives
              kpiId="occupied_rate"
              view="property"
              isShowWidget={isShowInsights}
              name={propertyName}
            />
          </div>
        </div>
        <div>
          <ChartHOC
            kpi="leased_rate"
            exploreArgs={["property", "leased_rate"]}
            newWidgetData={newWidgetData}
            loaderState={loaderState}
            exploreOnClick={exploreOnClick}
            chartLabel={PROPERTY_CHART_TEXT["leased_rate"]["chartLabel"]}
            titleTooltipDescription={
              PROPERTY_CHART_TEXT["leased_rate"]["tooltip"]
            }
            dateRange={{ start: start_date, end: end_date }}
            {...PROPERTY_CHART_PROPS["leased_rate"]}
          />
          <div className="widget__container">
            <Narratives
              kpiId="leased_rate"
              view="property"
              isShowWidget={isShowInsights}
              name={propertyName}
            />
          </div>
        </div>
        <div>
          <ChartHOC
            kpi="exposure_rate"
            exploreArgs={["property", "exposure_rate"]}
            newWidgetData={newWidgetData}
            loaderState={loaderState}
            exploreOnClick={exploreOnClick}
            chartLabel={PROPERTY_CHART_TEXT["exposure_rate"]["chartLabel"]}
            titleTooltipDescription={
              PROPERTY_CHART_TEXT["exposure_rate"]["tooltip"]
            }
            dateRange={{ start: start_date, end: end_date }}
            {...PROPERTY_CHART_PROPS["exposure_rate"]}
          />
          <div className="widget__container">
            <Narratives
              kpiId="exposure_rate"
              view="property"
              isShowWidget={isShowInsights}
              name={propertyName}
            />
          </div>
        </div>
      </div>
      <div className="property__widgets two_column">
        <div>
          <ChartHOC
            kpi="inq_exe"
            exploreArgs={["property", "leads_new_leases"]}
            newWidgetData={newWidgetData}
            loaderState={loaderState}
            exploreOnClick={exploreOnClick}
            chartLabel={PROPERTY_CHART_TEXT["inq_exe"]["chartLabel"]}
            titleTooltipDescription={PROPERTY_CHART_TEXT["inq_exe"]["tooltip"]}
            dateRange={{ start: start_date, end: end_date }}
            {...PROPERTY_CHART_PROPS["inq_exe"]}
          />
          <div className="widget__container">
            <Narratives
              kpiId="leads_to_lease"
              view="property"
              isShowWidget={isShowInsights}
              name={propertyName}
            />
          </div>
        </div>
        <div>
          <ChartHOC
            kpi="lease_cd_rate"
            exploreArgs={["property", "lease_cds"]}
            newWidgetData={newWidgetData}
            loaderState={loaderState}
            exploreOnClick={exploreOnClick}
            chartLabel={PROPERTY_CHART_TEXT["lease_cd_rate"]["chartLabel"]}
            titleTooltipDescription={
              PROPERTY_CHART_TEXT["lease_cd_rate"]["tooltip"]
            }
            dateRange={{ start: start_date, end: end_date }}
            {...PROPERTY_CHART_PROPS["lease_cd_rate"]}
          />
          <div className="widget__container">
            <Narratives
              kpiId="cancellation_and_denial"
              view="property"
              isShowWidget={isShowInsights}
              name={propertyName}
            />
          </div>
        </div>
      </div>
    </div>
  );
});

export default PropertyPerformance;
