export const adminPropertiesConfig = {
  fields: {
    public_id: {
      validators: {
        isRequired: {
          message: "Please select a property."
        }
      }
    },
    year_built: {
      validators: {
        minLength: {
          value: 4,
          message: "Year must contain 4 digits."
        },
        maxLength: {
          value: 4,
          message: "Year must contain 4 digits."
        }
      }
    }
  }
};
