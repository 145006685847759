let noNwvResult =
  "Sorry, there are no suggested or available website referral sources for the selected Lead Source.";

export const MANAGE_REFERRAL_SOURCES_MESSAGES = {
  match: {
    select_lead_source_message:
      "Select a Lead Source to show available website referral sources to match.",
    select_nwv_message:
      "Select the New Website Visitor referral sources you wish to match the Lead Source to.",
    nwv_body_message:
      "Select a Lead Source to show available website referral sources to match.",
    on_save_text: "Selected sources have been matched.",
    no_nwvs_result: noNwvResult,
    distributionSuggestion:
      "We suggest you distribute property website Lead Sources rather than matching them."
  },
  distribution: {
    select_lead_source_message:
      "Select a property to show available Lead Sources to distribute.",
    select_nwv_message:
      "Select any website referral sources that should be included in distribution.",
    nwv_body_message:
      "Select a Lead Source to distribute to a New Website Visitor Source. Distributing a Lead Source enables the Distributed Source Attribution toggle in Channel Analysis.",
    on_save_text: "Selected sources have been distributed.",
    no_nwvs_result: noNwvResult
  }
};
