import {
  configuredFormatCurrency,
  configuredFormatNumber,
  configuredFormatPercent,
  configuredFormatCurrencyShorthand,
  formatProjectedMarketingReturn,
  formatCondDecimal,
  formatCSVProjectedMarketingReturn
} from "./formatters";

import _logger from "./logger";
const error = _logger({});
export const KPI_FORMAT = {
  // Investment
  acq_reputation_building: configuredFormatCurrency(),
  acq_demand_creation: configuredFormatCurrency(),
  acq_leasing_enablement: configuredFormatCurrency(),
  acq_market_intelligence: configuredFormatCurrency(),
  ret_reputation_building: configuredFormatCurrency(),
  ret_demand_creation: configuredFormatCurrency(),
  ret_leasing_enablement: configuredFormatCurrency(),
  ret_market_intelligence: configuredFormatCurrency(),

  // Funnel Volumes
  usvs: configuredFormatNumber(),
  inquiries: configuredFormatNumber(),
  tours: configuredFormatNumber(),
  lease_applications: configuredFormatNumber(),
  leases_executed: configuredFormatNumber(),

  // Leasing
  occupiable_units: configuredFormatNumber(),
  leased_units: configuredFormatNumber(),
  leased_rate: configuredFormatPercent(2),
  renewal_rate: configuredFormatPercent(),
  lease_cd_rate: configuredFormatPercent(2),
  lease_renewal_notices: configuredFormatNumber(),
  lease_renewals: configuredFormatNumber(),
  lease_vacation_notices: configuredFormatNumber(),
  leases_ended: configuredFormatNumber(),

  // Activity
  move_outs: configuredFormatNumber(),
  move_ins: configuredFormatNumber(),
  occupancy_rate: configuredFormatPercent(2),
  occupied_units: configuredFormatNumber(),

  // Investment
  acq_investment: configuredFormatCurrency(),
  ret_investment: configuredFormatCurrency(),
  investment: configuredFormatCurrency(),

  // Revenue
  estimated_acq_revenue_gain: configuredFormatCurrencyShorthand(true),
  estimated_ret_revenue_gain: configuredFormatCurrencyShorthand(true),
  estimated_revenue_gain: configuredFormatCurrencyShorthand(true),

  // ROMI
  romi: configuredFormatNumber(),
  ret_romi: configuredFormatNumber(),
  acq_romi: configuredFormatNumber(),

  // Rent
  exe_to_lowest_rent: configuredFormatPercent(),

  // Funnel Conversions
  inq_exe: configuredFormatPercent(2),
  usv_exe: configuredFormatPercent(2),
  usv_inq: configuredFormatPercent(2),
  inq_tou: configuredFormatPercent(2),
  tou_app: configuredFormatPercent(2),
  app_approvals: configuredFormatPercent(2),
  app_exe: configuredFormatPercent(2),

  // Cost Pers
  usv_cost: configuredFormatCurrency(true),
  inq_cost: configuredFormatCurrency(),
  tou_cost: configuredFormatCurrency(),
  app_cost: configuredFormatCurrency(),
  exe_cost: configuredFormatCurrency(),

  leads: configuredFormatNumber(),
  occupied_rate: configuredFormatPercent(2),
  nwv: configuredFormatNumber(),
  nwv_leads: configuredFormatPercent(1),
  leads_tou: configuredFormatPercent(1),
  exe_ins: configuredFormatPercent(1),
  lease_cds: configuredFormatPercent(1),
  vacation_notices: configuredFormatNumber(),
  renewals: configuredFormatNumber(),
  exposure_rate: configuredFormatPercent(2),

  // Units
  bedrooms: configuredFormatNumber(0, 1),
  bathrooms: configuredFormatNumber(0, 1),
  square_feet: configuredFormatNumber(0, 2),
  rent_per_sq_feet: configuredFormatCurrency(true),

  app_approved: configuredFormatPercent(1),
  approved_exe: configuredFormatPercent(2),

  // Funnel Cost Pers
  budget: configuredFormatCurrency(true),
  cost_per_usvs: configuredFormatCurrency(true),
  cost_per_inquiries: configuredFormatCurrency(true),
  cost_per_tours: configuredFormatCurrency(true),
  cost_per_lease_applications: configuredFormatCurrency(true),
  cost_per_approvals: configuredFormatCurrency(true),
  cost_per_leases_executed: configuredFormatCurrency(true),

  // Funnel Revenue & ROI tab
  gross_rent: configuredFormatCurrency(true),
  avg_lease_length: formatCondDecimal,
  lease_revenue: configuredFormatCurrency(true),
  projected_marketing_return: formatProjectedMarketingReturn,
  csv_projected_marketing_return: formatCSVProjectedMarketingReturn
};

export function formatKPI(name, value) {
  let ret = value;
  if (!KPI_FORMAT[name]) {
    error("formatKpi")(`Key ${name} provided to formatKPI is invalid`);
  } else {
    ret = KPI_FORMAT[name](value);
  }
  return ret;
}
