import _get from "lodash/get";
import { clamp } from "lodash";
import {
  KPI_URL_LABEL,
  getHumanReadableKPI,
  getHumanReadableKpiForCsv,
  KPI_MATCHER,
  RATE_KPI
} from "../constants";
import { getCsvFilename } from "../components/property_kpi_page/utils";

// Filter data points from new widget data by one kpi
export const filterWidgetData = ({ data, kpi, nullAsZero = false }) => {
  const values = [];
  for (let i = 0; i < data.length; i++) {
    if (data[i].kpi === kpi) {
      if (data[i].value === null && nullAsZero) {
        data[i].value = 0;
      }
      values.push(data[i]);
    }
  }
  const out = { data: values, name: getHumanReadableKPI(kpi) };
  return out;
};

export const filterFunnelWidgetData = ({
  data,
  forecast_data = [],
  kpi,
  chart_type,
  nullAsZero = false,
  scale = 100
}) => {
  const values = [];
  const forecast_values = [];

  let cp_forecast_data = JSON.parse(JSON.stringify(forecast_data));
  let cp_data = JSON.parse(JSON.stringify(data));

  for (let i = 0; i < data.length; i++) {
    if (data[i].kpi === kpi) {
      if (data[i].value === null && nullAsZero) {
        cp_data[i].value = 0;
      }
      if (!(data[i].value === null) && chart_type === "percentage") {
        cp_data[i].value = parseFloat((data[i].value * scale).toFixed(2));
      }
      values.push(cp_data[i]);
    }
  }
  for (let i = 0; i < forecast_data.length; i++) {
    if (forecast_data[i].kpi === kpi) {
      if (forecast_data[i].forecast === null && nullAsZero) {
        cp_forecast_data[i].forecast = 0;
      }

      if (chart_type === "percentage") {
        cp_forecast_data[i].forecast = parseFloat(
          (forecast_data[i].forecast * scale).toFixed(2)
        );
        cp_forecast_data[i].range[0] = parseFloat(
          (forecast_data[i].range[0] * scale).toFixed(2)
        );
        cp_forecast_data[i].range[1] = parseFloat(
          (forecast_data[i].range[1] * scale).toFixed(2)
        );
      } else {
        cp_forecast_data[i].forecast = parseFloat(
          forecast_data[i].forecast.toFixed(2)
        );
        cp_forecast_data[i].range[0] = parseFloat(
          forecast_data[i].range[0].toFixed(2)
        );
        cp_forecast_data[i].range[1] = parseFloat(
          forecast_data[i].range[1].toFixed(2)
        );
      }
      forecast_values.push(cp_forecast_data[i]);
    }
  }
  const out = {
    data: values,
    forecast: forecast_values,
    name: getHumanReadableKPI(kpi)
  };
  return out;
};

// Count data points by kpi, skipping null values if needed
export const countWidgetData = ({ data, kpi, skipNull = true }) => {
  let i = 0;
  for (let j = 0; j < data.length; j++) {
    if (data[j].kpi === kpi) {
      if (skipNull) {
        if (data[j].value !== null) {
          i++;
        }
      } else {
        i++;
      }
    }
  }
  return i;
};

// Add up values by kpi
export const sumWidgetData = ({ data, kpi, asFloat = false }) => {
  let i = 0;
  if (data) {
    for (let j = 0; j < data.length; j++) {
      if (data[j].kpi === kpi) {
        if (data[j].value !== null) {
          i += asFloat ? parseFloat(data[j].value) : parseInt(data[j].value);
        }
      }
    }
  }
  return i;
};

// Avg values by kpi
export const avgWidgetData = ({ data, kpi, asFloat = false }) => {
  let i = 0;
  let count = 0;
  const isRate = !!RATE_KPI[kpi];
  const { num, den } = RATE_KPI[kpi]
    ? RATE_KPI[kpi]
    : {
        num: "",
        den: ""
      };
  let i_num = 0;
  let i_den = 0;
  if (data) {
    for (let j = 0; j < data.length; j++) {
      if (isRate) {
        if (data[j].kpi === num) {
          if (data[j].value !== null) {
            i_num += data[j].value;
          }
        }
        if (data[j].kpi === den) {
          if (data[j].value !== null) {
            i_den += data[j].value;
          }
        }
      } else {
        if (data[j].kpi === kpi) {
          if (data[j].value !== null) {
            i += asFloat ? parseFloat(data[j].value) : parseInt(data[j].value);
            count++;
          }
        }
      }
    }
  }
  return isRate && i_den > 0
    ? i_num / i_den
    : i > 0 && count > 0
    ? i / count
    : 0;
};

// clamp values
export const clampValues = ({ data, clampTo = 0 }) => {
  let out = data;
  if (data.map) {
    out = data.map(x => {
      if (x.value === null || x.value < clampTo) {
        return x;
      } else {
        return { ...x, value: clampTo };
      }
    });
  }
  return out;
};

export const clampForecast = ({
  data,
  chart_type,
  clampMin = 0,
  clampMax = 1,
  clampConversionRate = 3,
  clampConversionRateRange = 3.5,
  kpis = ["exposure_rate", "leased_rate", "occupancy_rate"]
}) =>
  data.map(x => {
    return {
      ...x,
      forecast:
        chart_type === "number"
          ? clamp(x.forecast, clampMin, x.forecast)
          : kpis.includes(x.kpi)
          ? clamp(x.forecast, clampMin, clampMax)
          : x.forecast > clampConversionRate
          ? clamp(x.forecast, clampMin, clampConversionRate)
          : x.forecast > x.range[0] || x.forecast < x.range[1]
          ? clamp(x.forecast, clampMin, x.forecast)
          : 0,
      range: [
        chart_type == "number"
          ? clamp(x.range[0], clampMin, x.range[0])
          : kpis.includes(x.kpi)
          ? clamp(x.range[0], clampMin, clampMax)
          : x.range[0] > clampConversionRate
          ? clamp(x.range[0], clampMin, clampMin)
          : x.range[0] <= x.range[1] || x.forecast >= x.range[0]
          ? clamp(x.range[0], clampMin, x.range[0])
          : 0,
        chart_type == "number"
          ? clamp(x.range[1], clampMin, x.range[1])
          : kpis.includes(x.kpi)
          ? clamp(x.range[1], clampMin, clampMax)
          : x.range[1] > clampConversionRate
          ? clamp(x.range[1], clampMin, clampConversionRateRange)
          : x.range[1] >= x.range[0] || x.forecast <= x.range[1]
          ? clamp(x.range[1], clampMin, x.range[1])
          : 0
      ]
    };
  });

export const _exploreHandler = category => propertyId => history => kpiTarget => {
  return history.push(
    `/property/${propertyId}/${category}/${_get(KPI_URL_LABEL, kpiTarget, "")}`
  );
};

export const scale = kpi => (scale = 100) => {
  let out = kpi;
  const { data } = kpi[0];
  if (data?.map) {
    let scaledData = data.map(x => ({
      ...x,
      value: x.value !== null ? parseFloat((x.value * scale).toFixed(2)) : null
    }));
    out[0].data = scaledData;
  }
  return out;
};

export const generateWidgetCSV = ({ data, project, kpi = "all" }) => {
  const titleRow = `${project || ""},${getHumanReadableKpiForCsv(
    KPI_MATCHER[kpi]
  )},Generated by Remarkably on ${new Date().toString()}\n`;
  let headerRow = "\nDate,KPI,Value";

  let body = "";
  for (let i = 0; i < data.length; i++) {
    let x = data[i];
    if (kpi === "all" || x.kpi === KPI_MATCHER[kpi]) {
      body += `\n${x.date},${getHumanReadableKpiForCsv(x.kpi)},${x.value}`;
    }
  }
  return `${titleRow}${headerRow}${body}`;
};

// stolen from concrete-ui since i couldnt figure out how to
// import this utility function...
export const downloadFile = (fileName, content) => {
  let element = document.createElement("a");
  element.setAttribute(
    "href",
    "data:text/csv;charset=utf-8," + encodeURIComponent(content)
  );
  element.setAttribute("download", fileName + ".csv");

  element.style.display = "none";
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
};

export const csvDownloadHandler = project => data => (
  kpi,
  startDate,
  endDate
) =>
  data &&
  downloadFile(
    getCsvFilename(project, kpi, startDate, endDate),
    generateWidgetCSV({ data, kpi, project })
  );
