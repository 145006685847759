let featureFlags = {};

const _checkFlag = flag => {
  if (
    flag == "alerts-anomaly" ||
    flag == "forecast" ||
    flag == "channel-analysis-lead-cohort-toggle" ||
    flag == "channel-analysis-summary-cards" ||
    flag == "retention"
  ) {
    console.log(`Flag ${flag} not implemented yet`);
    return false;
  }
  return true;
};

// get the flag state for each flag in our list
const bootstrapFeatureFlags = flags => {
  const flagStates = {};
  for (let i = 0; i < flags.length; i++) {
    const flag = flags[i];
    flagStates[`${flag}`] = _checkFlag(flag);
  }
  return flagStates;
};

export const initFeatureFlags = flags => {
  featureFlags = bootstrapFeatureFlags(flags);
};

export const _omg = flag =>
  process.env.FEATURE_FLAGS
    ? process.env.FEATURE_FLAGS.indexOf(flag) > -1
    : false;

export default flag => (featureFlags[flag] ? featureFlags[flag] : false);
