import React from "react";

import IconBase from "./icon_base";

const Copy = props => (
  <IconBase viewBox="0 0 12 12" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.90909 0H1.90909C1.30909 0 0.818176 0.490909 0.818176 1.09091V8.18182C0.818176 8.48182 1.06363 8.72727 1.36363 8.72727C1.66363 8.72727 1.90909 8.48182 1.90909 8.18182V1.63636C1.90909 1.33636 2.15454 1.09091 2.45454 1.09091H7.90909C8.20909 1.09091 8.45454 0.845455 8.45454 0.545455C8.45454 0.245455 8.20909 0 7.90909 0ZM10.0909 2.18188H4.09093C3.49093 2.18188 3.00002 2.67279 3.00002 3.27279V10.9092C3.00002 11.5092 3.49093 12.0001 4.09093 12.0001H10.0909C10.6909 12.0001 11.1818 11.5092 11.1818 10.9092V3.27279C11.1818 2.67279 10.6909 2.18188 10.0909 2.18188ZM4.63637 10.909H9.54546C9.84546 10.909 10.0909 10.6636 10.0909 10.3636V3.8181C10.0909 3.5181 9.84546 3.27264 9.54546 3.27264H4.63637C4.33637 3.27264 4.09092 3.5181 4.09092 3.8181V10.3636C4.09092 10.6636 4.33637 10.909 4.63637 10.909Z"
      fill="currentColor"
    />
  </IconBase>
);

export default Copy;
