import { getStartDatesByPreset, getEndDatesByPreset } from "../../utils/dates";

const initialState = {
  fetchingMemberGroup: false,
  fetchingProperties: false,
  fetchingPropertyGroups: false,
  properties: [],
  property_groups: [],
  table_data: [],
  table_summary: {},
  selected_property_group: {},
  kpi_order: [],
  date_selection: {
    preset: "last_30_days",
    start_date: getStartDatesByPreset()["last_30_days"],
    end_date: getEndDatesByPreset()["last_30_days"]
  },
  selected_property: {},
  owners: [],
  cities: [],
  states: [],
  lease_stages: [],
  classes: [],
  categories: [],
  styles: [],
  tags: [],
  filters: {
    selectedCategories: [],
    selectedCities: [],
    selectedClasses: [],
    selectedLeaseStages: [],
    selectedOwners: [],
    selectedStates: [],
    selectedStyles: [],
    selectedTags: []
  }
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "UPDATE_PROPERTY_GROUP":
      return {
        ...state,
        ...action.payload
      };
    case "AJAX_GET_PORTFOLIO_TAGS_REQUEST":
      return { ...state, tags: [] };
    case "AJAX_GET_PORTFOLIO_TAGS_SUCCESS":
      return { ...state, tags: action.payload };
    case "AJAX_GET_PROPERTY_GROUP_REQUEST":
      return {
        ...state,
        fetchingProperties: true
      };
    case "AJAX_GET_PROPERTY_GROUP_SUCCESS": {
      return {
        ...state,
        ...action.payload,
        fetchingProperties: false
      };
    }
    case "AJAX_GET_PROPERTY_GROUP_ERROR":
      return {
        ...state,
        fetchingProperties: false
      };
    case "AJAX_GET_PROPERTY_GROUP_LIST_REQUEST": {
      return {
        ...state,
        ...action.payload,
        fetchingPropertyGroups: true
      };
    }
    case "AJAX_GET_PROPERTY_GROUP_LIST_SUCCESS": {
      return {
        ...state,
        ...action.payload,
        fetchingPropertyGroups: false
      };
    }
    case "AJAX_GET_PROPERTY_GROUP_LIST_ERROR": {
      return {
        ...state,
        ...action.payload,
        fetchingPropertyGroups: false
      };
    }
    case "AJAX_GET_ALERT_PROPERTIES_REQUEST": {
      return {
        ...state,
        propertyGroupsIsLoading: false
      };
    }
    case "AJAX_GET_ALERT_PROPERTIES_SUCCESS": {
      const { property_groups, groups_kpis } = action.payload;
      return {
        ...state,
        property_groups: property_groups,
        kpi_order: groups_kpis,
        propertyGroupsIsLoading: true
      };
    }
    case "AJAX_GET_ALERT_PROPERTIES_FAILURE": {
      return {
        ...state,
        propertyGroupsIsLoading: false
      };
    }
    case "AJAX_POST_PROPERTY_GROUP_MEMBERS_REQUEST": {
      return {
        ...state,
        fetchingMemberGroup: true
      };
    }
    case "AJAX_POST_PROPERTY_GROUP_MEMBERS_SUCCESS": {
      const { id, members } = action.payload;
      const propertyGroups = [...state.property_groups];
      const group = propertyGroups.find(g => g.group_id == id);
      group.members = members;
      return {
        ...state,
        property_groups: propertyGroups,
        fetchingMemberGroup: false
      };
    }
    case "AJAX_POST_PROPERTY_GROUP_MEMBERS_FAILURE": {
      return {
        ...state,
        fetchingMemberGroup: false
      };
    }
    case "AJAX_GET_GROUP_PROPERTIES_REQUEST": {
      return { ...state, fetchingProperties: true, properties: [] };
    }
    case "AJAX_GET_GROUP_PROPERTIES_SUCCESS": {
      return {
        ...state,
        fetchingProperties: false,
        properties: action.payload
      };
    }
    case "AJAX_GET_GROUP_PROPERTIES_FAILURE": {
      return { ...state, fetchingProperties: false, properties: [] };
    }
    default:
      return state;
  }
};

export default reducer;
