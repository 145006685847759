import React from "react";
import PropTypes from "prop-types";
import _find from "lodash/find";

import EnhancedLink from "./../enhanced_link";
import AdminBreadcrumb from "../admin_breadcrumb";
import Breadcrumbs from "../breadcrumbs";
import { Container, Flex, Icon, Text, Button, Box, Tooltip } from "concrete-ui";
import AdminPropertiesTable from "../admin_properties_table";
import TabNavigator from "../tab_navigator";
import {
  adminPropertiesTabs,
  adminPropertiesColumns
} from "../../containers/admin_properties/tabs";
import "./admin_properties.scss";
import {
  ZENDESK_FILTER_PORTFOLIO,
  PROPERTY_ATTRIBUTES_TAB_TOOLTIP,
  PROPERTY_LEASE_STAGES,
  PROPERTY_BUILDING_CLASSES,
  PROPERTY_TYPES,
  PROPERTY_STYLES
} from "../../constants";

export default class AdminProperties extends React.Component {
  static propTypes = {
    loading: PropTypes.bool,
    tabId: PropTypes.string,
    selectedIndex: PropTypes.number,
    onChangeTab: PropTypes.func,
    onDeactivateProperty: PropTypes.func,
    propertiesDetails: PropTypes.arrayOf(PropTypes.object),
    onEditPropertiesDetails: PropTypes.func,
    onEditAttributes: PropTypes.func
  };

  mapTableData = data => {
    return {
      public_id: data.public_id,
      name: data.name,
      city: data.city,
      state: data.state,
      added_updated_by: data.added_updated_by,
      last_updated: data.last_updated,
      status: data.status,
      stage: _find(PROPERTY_LEASE_STAGES, ["value", data.stage])?.label,
      building_class: _find(PROPERTY_BUILDING_CLASSES, [
        "value",
        data.building_class
      ])?.label,
      type: _find(PROPERTY_TYPES, ["value", data.type])?.label,
      style: _find(PROPERTY_STYLES, ["value", data.style])?.label
    };
  };

  static defaultProps = {
    onChangeTab() {},
    selectedIndex: 0,
    propertiesDetails: []
  };

  onChangeTabHandler = selectedIndex => {
    this.props.onChangeTab(selectedIndex);
  };

  render() {
    const breadcrumbs = [
      { text: <AdminBreadcrumb />, link: "" },
      { text: "Properties", link: "" }
    ];
    const tableColumns = adminPropertiesColumns(
      this.props.selectedIndex,
      this.props.onEditPropertiesDetails,
      this.props.onEditAttributes,
      this.props.onDeactivateProperty
    );
    return (
      <Box display="block" mb="6rem">
        <Container>
          <Flex alignItems="center" height={13}>
            <Breadcrumbs
              breadcrumbs={breadcrumbs}
              containerProps={{ variant: "fullwidth" }}
            />
            <EnhancedLink
              to={adminPropertiesTabs[this.props.selectedIndex]["pageLink"]}
            >
              <Button
                textDecoration="none"
                variant="secondary"
                size="small"
                ml={5}
              >
                <Icon.Plus $color="text.white.1" />
                {adminPropertiesTabs[this.props.selectedIndex]["addButtonText"]}
              </Button>
            </EnhancedLink>
            <Flex className="title-return-nav">
              <EnhancedLink to="/">
                <Text
                  textSize={2}
                  $fontWeight="regular"
                  $color="text.white.1"
                  mr={2}
                >
                  Go to my Portfolio
                </Text>
                <Icon.ArrowRight size={3} $color="text.white.1" />
              </EnhancedLink>
            </Flex>
          </Flex>
        </Container>
        <TabNavigator
          className="admin-properties__tabs-link"
          selectedIndex={this.props.selectedIndex}
          onChange={this.onChangeTabHandler}
        >
          <TabNavigator.Tab label={<Text textSize={3}>Properties</Text>}>
            <AdminPropertiesTable
              onDeactivate={this.props.onDeactivateProperty}
              propertiesDetailsData={this.props.propertiesDetailsData}
              loading={this.props.loading}
              tableColumns={tableColumns["tableColumns"]}
              hiddenColumns={tableColumns["hiddenColumns"]}
            />
          </TabNavigator.Tab>
          <TabNavigator.Tab
            label={
              <>
                <Text textSize={3} mr={2}>
                  Property Attributes
                </Text>
                <Tooltip
                  title={PROPERTY_ATTRIBUTES_TAB_TOOLTIP}
                  placement="right"
                  display="inline-block"
                  size="small"
                  iconSize="small"
                  linkText="Learn more."
                  link={ZENDESK_FILTER_PORTFOLIO}
                  tooltipProps={{
                    onClick: e => e.stopPropagation()
                  }}
                  linkProps={{
                    target: "_blank"
                  }}
                />
              </>
            }
          >
            <AdminPropertiesTable
              propertiesDetailsData={this.props.propertiesDetails.map(
                this.mapTableData
              )}
              loading={this.props.loading}
              tableColumns={tableColumns["tableColumns"]}
              hiddenColumns={tableColumns["hiddenColumns"]}
            />
          </TabNavigator.Tab>
        </TabNavigator>
      </Box>
    );
  }
}
