import { createStore, applyMiddleware, compose } from "redux";
import storage from "redux-persist/lib/storage";
import { persistStore, persistReducer } from "redux-persist";
import createSagaMiddleware from "redux-saga";

import reducers from "./reducers";
import {
  cacheApiCalls,
  fetchCreatePassword,
  fetchResetPassword,
  fetchPasswordRules,
  sendGaEvent,
  startNetworkFetch,
  logoutMiddleware,
  fetchInviteModal,
  fetchUIString,
  fetchAccountProperties,
  updateAccountProfile,
  updateReportsSettings,
  refreshToken,
  fetchNewWidgetData,
  login,
  trackUser,
  trackFilterChange,
  waitSisenseDownloads,
  trackActions,
  deleteAlert,
  markAlertsNotificationSeen,
  updateAlertSubscriptionPreference
} from "./middleware";
import {
  addOrUpdateDataConnection,
  afterYardiBackfill
} from "./middleware/connections";
import rootSaga from "./sagas";
import { afterAnalyticsBackfill } from "./middleware/analytics";
import { fetchWidgetData } from "./middleware/fetch_widget_data";

const cfg = {
  key: "rmb",
  storage,
  whitelist: [
    "token",
    "user",
    "uiStrings",
    "resendEmail",
    "filters",
    "cacheApiCalls"
  ] // NOTE: this is where we elect what to persist
};

// TODO: contextually enable devtools based on prod or not
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const presistedReducer = persistReducer(cfg, reducers);

const sagaMiddleware = createSagaMiddleware();

export default () => {
  const store = createStore(
    presistedReducer,
    composeEnhancers(
      applyMiddleware(
        cacheApiCalls,
        fetchCreatePassword,
        fetchResetPassword,
        sendGaEvent,
        startNetworkFetch,
        logoutMiddleware,
        fetchInviteModal,
        fetchUIString,
        fetchPasswordRules,
        fetchAccountProperties,
        updateAccountProfile,
        updateReportsSettings,
        refreshToken,
        login,
        fetchWidgetData,
        fetchNewWidgetData,
        trackUser,
        trackFilterChange,
        waitSisenseDownloads,
        trackActions,
        deleteAlert,
        markAlertsNotificationSeen,
        updateAlertSubscriptionPreference,
        addOrUpdateDataConnection,
        afterYardiBackfill,
        afterAnalyticsBackfill,
        sagaMiddleware
      )
    )
  );
  const persistor = persistStore(store);
  sagaMiddleware.run(rootSaga);
  return { store, persistor };
};
