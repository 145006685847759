import React, { useEffect, useMemo } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";

import CreateReportForm from "../../components/create_report_form";
import { withLogin } from "../shared/login";
import { propertyActions, reportActions } from "../../redux_base/actions";
import { dateToISO } from "../../utils/dates";

const CreateReportFormContainer = ({
  dispatch,
  properties,
  propertyKPIsIsLoading,
  user,
  isLoading,
  success,
  distributedSourcesByProject
}) => {
  useEffect(() => {
    if (!propertyKPIsIsLoading) {
      dispatch(propertyActions.requestProperties());
    }
    dispatch(reportActions.getReportSettings());
  }, []);

  const mapPropertySelect = ({ property_id, property_name }) => ({
    value: property_id,
    label: property_name
  });
  const propertyList = useMemo(
    () => Object.values(properties).map(mapPropertySelect),
    [properties]
  );

  const sendReport = formData => {
    const data = {
      ...formData,
      start_date: dateToISO(formData.start_date),
      end_date: dateToISO(formData.end_date)
    };
    dispatch(reportActions.createReport(data));
  };

  return (
    <CreateReportForm
      userEmail={user.email}
      properties={propertyList}
      confirm={sendReport}
      isLoading={isLoading}
      success={success}
      distributedSourcesByProject={distributedSourcesByProject}
    />
  );
};

const mapState = state => ({
  properties: state.properties.items,
  propertyKPIsIsLoading: state.properties.propertyKPIsIsLoading,
  user: state.user,
  isLoading: state.report.isLoading,
  success: state.report.success,
  distributedSourcesByProject: state.report.distributedSourcesByProject
});

export default withRouter(
  connect(mapState)(withLogin(CreateReportFormContainer))
);
