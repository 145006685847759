import React from "react";
import cn from "classnames";
import PropTypes from "prop-types";
import posthog from "posthog-js";
import Container from "../container";
import "./tab_css.scss";

class Tab extends React.PureComponent {
  static propTypes = {
    label: PropTypes.node.isRequired
  };

  render() {
    return this.props.children;
  }
}

export class TabCSS extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    children: PropTypes.arrayOf(function(
      propValue,
      key,
      componentName,
      location,
      propFullName
    ) {
      if (propValue[key].type !== Tab) {
        return new Error(
          `Invalid prop \`${propFullName}\`` +
            ` of type \`${typeof propValue[key]}\`` +
            ` supplied to \`${componentName}\`, expected instance of \`Tab\`.`
        );
      }
    }).isRequired,
    onChange: PropTypes.func,
    selectedIndex: PropTypes.number,
    lazyRender: PropTypes.bool,
    withoutContainer: PropTypes.bool,
    theme: PropTypes.oneOf(["", "icon"])
  };

  static defaultProps = {
    theme: "",
    lazyRender: false,
    withoutContainer: false,
    onChange: () => {}
  };

  state = {
    selectedIndex: 0,
    renderedTabs: {}
  };

  static getDerivedStateFromProps(props, state) {
    let selectedIndex = state.selectedIndex;
    if ("selectedIndex" in props) {
      selectedIndex = props.selectedIndex;
    }
    const renderedTabs = { ...state.renderedTabs };
    renderedTabs[selectedIndex] = true;
    return { selectedIndex, renderedTabs };
  }

  get childrenArray() {
    const { children } = this.props;
    return Array.isArray(children) ? children : [children];
  }

  handleNavClick = e => {
    e.preventDefault();
    const selectedIndex = e.currentTarget.tabIndex;
    if (posthog?.capture) {
      const TABS = ["Performance", "Marketing", "Leasing", "Retention"];
    }
    this.setState({ selectedIndex }, () => this.props.onChange(selectedIndex));
  };

  getTabsLinks = () => {
    return this.childrenArray.map((child, index) => {
      const classes = cn("tab-css__nav-item", {
        "tab-css__nav-item--active": index === this.state.selectedIndex
      });
      return (
        <a
          className={classes}
          href="#"
          key={`tab-link-${index}`}
          tabIndex={index}
          onClick={this.handleNavClick}
        >
          {child.props.label}
        </a>
      );
    });
  };

  getTabsContent = () => {
    const { selectedIndex, renderedTabs } = this.state;
    const tabs = this.childrenArray.map((child, index) => {
      const classes = cn("tab-css__content", {
        "tab-css__content--hide": index !== selectedIndex
      });
      if (
        this.props.lazyRender &&
        index !== selectedIndex &&
        !renderedTabs[index]
      ) {
        return null;
      }
      return (
        <div className={classes} key={`tab-${index}`}>
          {child}
        </div>
      );
    });
    if (this.props.withoutContainer) {
      return tabs;
    } else {
      return <Container className="tab-css__container">{tabs}</Container>;
    }
  };

  render() {
    const { className, theme } = this.props;
    const classes = cn("tab-css", className, {
      [`tab-css--${theme}`]: theme
    });
    return (
      <div className={classes}>
        <Container className="tab-css__navs">{this.getTabsLinks()}</Container>
        <div className="tab-css__divider" />
        {this.getTabsContent()}
      </div>
    );
  }
}

TabCSS.Tab = Tab;

export default TabCSS;
