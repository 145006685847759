import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import AddTagInput from "../../components/add_tag_input";
import { TYPING_TIMEOUT } from "../../constants";
import { propertyActions } from "../../redux_base/actions";

import AddButton from "./add";

class AddTagField extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    property: PropTypes.object,
    user: PropTypes.object,
    isAddTagInput: PropTypes.bool,
    suggestedTags: PropTypes.object
  };

  showAddTagInput = () =>
    this.props.dispatch(propertyActions.showAddTagInput());

  onChangeTag = tag => {
    if (this.addTagTimeout) {
      clearTimeout(this.addTagTimeout);
    }
    this.addTagTimeout = setTimeout(
      () =>
        this.props.dispatch(
          propertyActions.searchTags(this.props.property.public_id)({ tag })
        ),
      TYPING_TIMEOUT
    );
  };

  createTag = word => {
    if (!word) {
      return;
    }
    const { property } = this.props;
    const data = {
      tag_name: word
    };
    this.props.dispatch(
      propertyActions.createTag(property.public_id)({ body: data })
    );
  };

  hideInput = value => {
    if (!value) {
      this.props.dispatch(propertyActions.hideTagInput());
    }
  };

  render() {
    let component;
    if (this.props.isAddTagInput) {
      component = (
        <AddTagInput
          className={this.props.className}
          suggestedTags={this.props.suggestedTags}
          onChange={this.onChangeTag}
          onCreateTag={this.createTag}
          onBlur={this.hideInput}
        />
      );
    } else {
      component = <AddButton onClick={this.showAddTagInput} />;
    }
    return <div className="add-tag-field tag tag--add-btn">{component}</div>;
  }
}

const mapState = state => ({
  ...state.property
});

export default connect(mapState)(AddTagField);
