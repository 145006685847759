import React, { useEffect, useMemo } from "react";
import _find from "lodash/find";
import { Flex } from "concrete-ui";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { withLogin } from "../shared/login";
import Loader from "../../components/loader";
import { propertyActions } from "../../redux_base/actions";
import AdminPropertyAttributesForm from "../../components/admin_property_attributes_form";

const AdminPropertyAttributesFormContainer = ({
  match,
  history,
  // reducer props
  isPostSuccessful,
  isLoadingPropDetails,
  apiError,
  dispatch,
  // data
  propertiesDetails = [],
  propertyOwners = []
}) => {
  const { publicId } = match.params;
  const initialPropertyData = useMemo(
    () => _find(propertiesDetails, ["public_id", publicId]) || {},
    [propertiesDetails, publicId]
  );

  // property functions

  const mapPropertyOwners = po => ({
    id: po.id,
    label: po.name
  });

  useEffect(() => {
    if (!propertiesDetails.length) {
      dispatch(propertyActions.requestPropertiesDetails());
    }
    if (!propertyOwners.length) {
      dispatch(propertyActions.getPropertyOwners());
    }
  }, []);

  useEffect(() => {
    if (isPostSuccessful) {
      dispatch(propertyActions.updateState({ isPostSuccessful: false }));
      history.push("/admin-properties/property_attributes");
    }
  }, [isPostSuccessful]);

  // cancel
  const onCancel = () => history.push("/admin-properties/property_attributes");

  // submit
  const onSubmit = formData => {
    const propertyData = _find(propertiesDetails, [
      "public_id",
      formData.public_id
    ]);
    const patchData = { ...propertyData, ...formData };
    dispatch(
      propertyActions.patchPropertyDetails(patchData, formData.public_id)
    );
  };

  return (
    <Flex flexDirection="column" pb={"12rem"}>
      <AdminPropertyAttributesForm
        initialPropertyId={initialPropertyData.public_id}
        propertyOwners={propertyOwners.map(mapPropertyOwners)}
        propertiesDetails={propertiesDetails}
        onConfirm={onSubmit}
        onCancel={onCancel}
        apiError={apiError}
      />
      <Loader isVisible={isLoadingPropDetails} />
    </Flex>
  );
};

const mapState = state => ({
  ...state.network,
  ...state.property
});

export default withRouter(
  connect(mapState)(withLogin(AdminPropertyAttributesFormContainer))
);
