import React, { useState } from "react";
import _find from "lodash/find";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  Flex,
  Text,
  TextInput,
  Select,
  InputWrapper,
  Link
} from "concrete-ui";
import _intersection from "lodash/intersection";

import EnhancedLink from "./../enhanced_link";
import useValidation from "../../utils/validator_hook";
import Breadcrumbs from "../breadcrumbs";
import {
  CreateForm,
  CreateFormHeader,
  CreateFormSection
} from "../create_form";
import { ErrorItem } from "../message_items";
import { adminPropertiesConfig } from "./validators";
import { SelectSearch } from "../select";
import { CreateLabelFactory } from "../select/select_components";
import AdminBreadcrumb from "../admin_breadcrumb";
import {
  concreteErrorVariant,
  PROPERTY_BUILDING_CLASSES,
  PROPERTY_LEASE_STAGES,
  PROPERTY_STYLES,
  PROPERTY_TYPES
} from "../../constants";

const ErrorMessage = ({ apiError, formError, allFalseError }) => {
  if (formError) {
    return (
      <ErrorItem message="Please correct the errors above before continuing." />
    );
  } else if (allFalseError) {
    return (
      <ErrorItem message="Please make attribution selections or entries to save." />
    );
  } else if (apiError) {
    return (
      <ErrorItem message="Oops, something went wrong. Please contact Support." />
    );
  }
  return null;
};

const AdminPropertyAttributesForm = ({
  initialPropertyId = null,
  propertiesDetails = [],
  propertyOwners = [],
  onCancel = v => v,
  onConfirm = v => v,
  apiError = false
}) => {
  const [allFalse, setAllFalse] = useState(false);

  const mapFormFields = property => ({
    public_id: property.public_id,
    stage: property.stage,
    building_class: property.building_class,
    type: property.type,
    style: property.style,
    year_built: property.year_built,
    url: property.url,
    property_owner_id: property.property_owner_id,
    property_owner_name: property.property_owner_name
  });

  const defaultData = {
    public_id: null,
    stage: null,
    building_class: null,
    type: null,
    style: null,
    year_built: null,
    url: null,
    property_owner_id: null,
    property_owner_name: null
  };

  const getProperty = publicId => {
    const prop = _find(propertiesDetails, ["public_id", publicId]);
    return prop ? mapFormFields(prop) : {};
  };

  const onSave = formData => {
    const allFalse = Object.keys(formItems).every(
      key => key === "public_id" || !formItems[key]
    );
    setAllFalse(allFalse);
    if (!allFalse) {
      onConfirm(formData);
    }
  };

  const {
    errors,
    getFormProps,
    getItemProps,
    setFormItems,
    formItems,
    touched
  } = useValidation(
    adminPropertiesConfig,
    {
      ...defaultData,
      ...getProperty(initialPropertyId)
    },
    onSave
  );

  const mapPropertySelect = ({ public_id, name }) => ({
    value: public_id,
    label: name
  });

  const propertyProps = getItemProps("public_id");
  const onChangePropertySelect = value => {
    propertyProps.onChange(value);
    setFormItems({
      ...defaultData,
      ...getProperty(value)
    });
  };

  const formatCreateLabel = CreateLabelFactory("Add Owner");
  const getOwnerValue = () => ({
    id: formItems.property_owner_id,
    label: formItems.property_owner_name
  });
  const onCreateOwner = value => {
    setFormItems({ property_owner_id: null, property_owner_name: value });
  };
  const onChangeOwnerHandle = owner => {
    setFormItems({
      property_owner_id: owner?.id,
      property_owner_name: owner?.label
    });
  };

  const leaseStageProps = getItemProps("stage");
  const classProps = getItemProps("building_class");
  const categoryProps = getItemProps("type");
  const styleProps = getItemProps("style");

  const breadcrumbs = [
    { text: <AdminBreadcrumb />, link: "" },
    { text: "Properties", link: "/admin-properties" },
    { text: "Add Property Attributes", link: "" }
  ];
  const fieldsContainerProps = { width: "31rem" };
  return (
    <>
      <Box bg="gray.1" width="100%" display="block">
        <Breadcrumbs breadcrumbs={breadcrumbs} />
      </Box>
      <Box width="62.5rem" mx="auto" mb="12rem">
        <CreateForm formProps={getFormProps()}>
          <CreateFormHeader>
            <Text textSize={4} $color="text.white.1" $fontWeight="medium">
              Add Property Attributes
            </Text>
          </CreateFormHeader>
          <CreateFormSection
            number="1"
            titleText="Choose Property"
            titleSummary="Select a property you’ve already added to Remarkably."
            fieldsContainerProps={fieldsContainerProps}
          >
            <Flex flexBasis="100%" alignItems="center">
              <Select
                size="large"
                label="Property"
                items={propertiesDetails.map(mapPropertySelect)}
                {...propertyProps}
                onSelect={onChangePropertySelect}
                error={!!errors.public_id}
                errorMessage={errors.public_id}
                width="auto"
                flexBasis="calc(50% - 0.5rem)"
                mr={4}
              />
              <Flex flexDirection="column">
                <Text textSize={2} $color="text.white.1" $fontWeight="regular">
                  Don't see a property listed?
                </Text>

                <EnhancedLink to="/admin-properties">
                  <Link variant="underline" $color="text.blue.0" textSize={1}>
                    Add a property
                  </Link>
                </EnhancedLink>
              </Flex>
            </Flex>
          </CreateFormSection>
          <CreateFormSection
            number="2"
            titleText="Choose Property Attributes"
            titleSummary="Choose one attribute from each list for enhanced filtering and reporting."
            fieldsContainerProps={fieldsContainerProps}
          >
            <Flex flexWrap="wrap" flexGrow="1">
              <Flex flexBasis="100%" mt={3}>
                <Select
                  size="large"
                  placeholder="Select Stage"
                  items={PROPERTY_LEASE_STAGES}
                  {...leaseStageProps}
                  onSelect={leaseStageProps.onChange}
                  error={!!errors.stage}
                  errorMessage={errors.stage}
                  flexBasis="calc(50% - 0.5rem)"
                  mr={4}
                />
                <Select
                  size="large"
                  placeholder="Select Class"
                  items={PROPERTY_BUILDING_CLASSES}
                  {...classProps}
                  onSelect={classProps.onChange}
                  error={!!errors.building_class}
                  errorMessage={errors.building_class}
                  flexBasis="calc(50% - 0.5rem)"
                />
              </Flex>
              <Flex flexBasis="100%" mt={3}>
                <Select
                  size="large"
                  placeholder="Select Category"
                  items={PROPERTY_TYPES}
                  {...categoryProps}
                  onSelect={categoryProps.onChange}
                  error={!!errors.type}
                  errorMessage={errors.type}
                  flexBasis="calc(50% - 0.5rem)"
                  mr={4}
                />
                <Select
                  size="large"
                  placeholder="Select Style"
                  items={PROPERTY_STYLES}
                  {...styleProps}
                  onSelect={styleProps.onChange}
                  error={!!errors.style}
                  errorMessage={errors.style}
                  flexBasis="calc(50% - 0.5rem)"
                />
              </Flex>
            </Flex>
          </CreateFormSection>
          <CreateFormSection
            number="3"
            titleText="Add Custom Property Attributes"
            titleSummary="Add custom details about the property."
            fieldsContainerProps={{
              ...fieldsContainerProps,
              flexDirection: "column"
            }}
          >
            <Flex flexWrap="wrap">
              <Flex flexBasis="100%" mt={3}>
                <TextInput
                  size="large"
                  label="Year Built"
                  {...getItemProps("year_built")}
                  variant={errors.year_built && concreteErrorVariant}
                  message={errors.year_built}
                  width="auto"
                  flexBasis="calc(50% - 0.5rem)"
                  mr={4}
                />
                <InputWrapper
                  label={{ message: "Owner Name" }}
                  width="auto"
                  flexGrow="1"
                >
                  <SelectSearch
                    isAsync={false}
                    placeholder="Choose or create new owner"
                    components={{ DropdownIndicator: () => null }}
                    options={propertyOwners}
                    isCreatable={true}
                    isClearable={true}
                    formatCreateLabel={formatCreateLabel}
                    {...getItemProps("property_owner_id")}
                    value={getOwnerValue()}
                    onChange={onChangeOwnerHandle}
                    onCreateOption={onCreateOwner}
                  />
                </InputWrapper>
              </Flex>
              <Flex flexBasis="100%" mt={3}>
                <TextInput
                  size="large"
                  label="Website URL"
                  {...getItemProps("url")}
                  variant={errors.url && concreteErrorVariant}
                  message={errors.url}
                  width="auto"
                  flexBasis="calc(50% - 0.5rem)"
                  mr={4}
                />
              </Flex>
            </Flex>
            <Flex mt={6} alignItems="center" justifyContent="flex-end">
              <ErrorMessage
                apiError={apiError}
                isFormErrors={Object.keys(errors).length > 0}
                allFalseError={allFalse}
              />
              <Button
                variant="secondary"
                size="large"
                type="button"
                mr={3}
                onClick={onCancel}
                style={{ flex: "0 0 auto" }}
              >
                Cancel
              </Button>
              <Button
                size="large"
                variant="primary"
                type="submit"
                style={{ flex: "0 0 auto" }}
              >
                Save
              </Button>
            </Flex>
          </CreateFormSection>
        </CreateForm>
      </Box>
    </>
  );
};

AdminPropertyAttributesForm.propTypes = {
  initialData: PropTypes.object,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  apiError: PropTypes.bool,
  propertiesDetails: PropTypes.array,
  propertyOwners: PropTypes.array
};

export default AdminPropertyAttributesForm;
