import React, { useEffect } from "react";
import { Button, TextInput } from "concrete-ui";
import Checkbox from "../checkbox";
import "./login.scss";
import { connect } from "react-redux";
import { auth } from "../../redux_base/actions";
import useValidation from "../../utils/validator_hook";

const LOGIN_ERROR_MSG =
  "Oops! There was a problem with your login info, please try again.";

const config = {
  fields: {
    email: {
      validators: {
        isRequired: {
          message: "Email is required."
        },
        isEmail: {
          message: "Enter a correct email format."
        }
      }
    },
    password: {
      validators: {
        isRequired: {
          message: "Password is required."
        }
      }
    }
  }
};

export const LoginView = ({ next, authenticated, dispatch, loginError }) => {
  const initialValues = {
    email: "",
    password: "",
    remember: false
  };

  const onSubmit = values => {
    // Some logic to process the values once middleware is built out
    dispatch(
      auth.login({
        email: values.email,
        password: values.password,
        rememberMe: values.remember
      })
    );
  };

  const {
    formItems,
    errors,
    getFormProps,
    getItemProps,
    setFormItems
  } = useValidation(config, initialValues, onSubmit);

  const getErrorMessages = errors => {
    let messages = [];
    if (errors.email) {
      messages.push(errors.email);
    }
    if (errors.password) {
      messages.push(errors.password);
    }
    if (loginError) {
      messages.push(LOGIN_ERROR_MSG);
    }
    return (
      <p>
        {messages.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </p>
    );
  };

  const getFieldMessage = () => {
    if (next) {
      if (authenticated) {
        return (
          <p>
            Your account doesn't have access to this page. To proceed, please
            login with an account that has access.
          </p>
        );
      } else {
        return <p>Please login to see this page.</p>;
      }
    }
  };

  const onRemember = () => {
    setFormItems({ remember: !formItems.remember });
  };

  return (
    <div className="auth-form">
      <div className="auth-form__content">
        <div className="auth-form__content-inner">
          <div className="auth-form__logo-wrap">
            <div className="remarkably-logo">&nbsp;</div>
          </div>
          <form method="post" {...getFormProps()}>
            <div className="auth-form__messages">
              {getFieldMessage()}
              {getErrorMessages(errors)}
            </div>
            <div className="auth-form__fields">
              <div className="auth-form__field">
                <div className="auth-form__field-label">Email</div>
                <TextInput
                  size="large"
                  labelColor="white.1"
                  autoComplete="username"
                  {...getItemProps("email")}
                />
              </div>
              <div className="auth-form__field">
                <div className="auth-form__field-label">Password</div>
                <TextInput
                  size="large"
                  labelColor="white.1"
                  type="password"
                  showPasswordOption={true}
                  autoComplete="current-password"
                  {...getItemProps("password")}
                />
              </div>
              <div className="auth-form__actions">
                <Button
                  fullWidth={true}
                  variant="primary"
                  size="large"
                  type="submit"
                >
                  LOG IN
                </Button>
              </div>
            </div>
            <div className="auth-form__extras">
              <p>
                By logging in, I accept Remarkably’s{" "}
                <a target="_blank" href="https://remarkably.io/terms/">
                  terms of use{" "}
                </a>{" "}
                and{" "}
                <a target="_blank" href="https://remarkably.io/privacy/">
                  privacy policy
                </a>
                .
              </p>
              <div className="auth-form__extras break"></div>
              <Checkbox
                className="auth-form__checkbox"
                isSelected={formItems.remember}
                onClick={onRemember}
              />
              <input
                type="checkbox"
                name="remember"
                hidden={true}
                checked={formItems.remember}
                readOnly={true}
              />
              <span>Remember me</span>
              <div className="auth-form__extras break"></div>
              <p>
                <a href="/users/password-reset/">
                  Never set a password? Or forgot it?
                </a>
              </p>
            </div>
          </form>
        </div>
      </div>
      <div className="auth-form__side">
        <div className="auth-form__side-bg">
          <footer className="auth-form content-footer">
            <div className="copyright">
              <p>&copy; Remarkably. All Rights Reserved</p>
            </div>
          </footer>
        </div>
      </div>
    </div>
  );
};

const mapState = state => ({
  loginError: state.user.error
});

export default connect(mapState)(LoginView);
