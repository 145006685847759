export default [
  {
    name: "pms_instances",
    label: "PMS Instances",
    addButtonText: "Add PMS Instance",
    pageLink: "/admin-connections/add-pms-instance"
  },
  {
    name: "data_connections",
    label: "Property Data Connections",
    addButtonText: "Add Property Data Connection",
    pageLink: "/admin-connections/add-data-connection"
  }
];
