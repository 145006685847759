import React from "react";
import { Dropdown, Icon, Item, Menu, Text, Tooltip } from "concrete-ui";
import {
  DATA_CONNECTION_BACKFILL_FAILED,
  DATA_CONNECTION_TEST_FAILED,
  DATA_CONNECTION_TEST_PENDING,
  DATA_CONNECTION_TEST_SUCCESS,
  ZENDESK_DATA_CONNECTION_TEST_HOW_TO,
  ZENDESK_PROPERTY_BACKFILL
} from "../../../constants";
import TooltipLink from "../../tooltip_link";

export const CONNECTION_STATUS_LABELS = {
  testing: "Testing...",
  test_success: "Test Successful",
  test_failed: "Test Failed",
  backfilling: "Backfilling...",
  backfill_success: "Backfill Successful",
  backfill_failed: "Backfill Failed",
  test_pending: "Testing Required"
};

export const MENU_ITEM_LABELS = {
  test_success: "Backfill Data",
  test_failed: "Try Again",
  backfill_success: "Backfill More",
  backfill_failed: "Try Again",
  test_pending: "Test Connection"
};

const ConnectionStatusText = ({ text }) => (
  <Text textSize={2} style={{ whiteSpace: "nowrap" }}>
    {text}
  </Text>
);

const StatusMenu = ({
  connectionData,
  itemText,
  setShowDropdown,
  menuProps,
  onClick
}) => (
  <Menu {...menuProps}>
    <Item
      size="large"
      onClick={() => {
        if (onClick) {
          onClick(connectionData.public_id, connectionData.integration_source);
        }
        setShowDropdown(false);
      }}
    >
      {itemText}
    </Item>
  </Menu>
);

const getMenuItems = (
  connectionData,
  onTestConnection,
  onBackfill,
  connectionStatus
) => ({ props, setShowDropdown }) => {
  const onClick = [
    "test_success",
    "backfill_success",
    "backfill_failed"
  ].includes(connectionStatus)
    ? onBackfill
    : onTestConnection;

  return (
    <StatusMenu
      connectionData={connectionData}
      itemText={MENU_ITEM_LABELS[connectionStatus]}
      onClick={onClick}
      setShowDropdown={setShowDropdown}
      menuProps={props}
    />
  );
};

function getStatusDropdownProps(status) {
  const label = CONNECTION_STATUS_LABELS[status];
  const statusProps = {
    test_pending: {
      label: (
        <>
          <ConnectionStatusText text={label} />
          <Tooltip
            title={DATA_CONNECTION_TEST_PENDING}
            placement="top"
            size="small"
            iconSize="small"
            linkText="Learn more."
            link={ZENDESK_DATA_CONNECTION_TEST_HOW_TO}
            linkProps={{
              target: "_blank"
            }}
            ml={2}
          />
        </>
      ),
      icon: <Icon.Error color="orange.1" />
    },
    testing: {
      label: label,
      icon: <Icon.Sync color="cyan.0" />,
      showDropdown: false,
      style: { opacity: 0.5 }
    },
    test_success: {
      label: (
        <>
          <ConnectionStatusText text={label} />
          <Tooltip
            title={DATA_CONNECTION_TEST_SUCCESS}
            placement="top"
            size="small"
            iconSize="small"
            linkText="Learn more."
            link={ZENDESK_PROPERTY_BACKFILL}
            linkProps={{
              target: "_blank"
            }}
            ml={2}
          />
        </>
      ),
      icon: <Icon.CheckCircle color="green.1" />
    },
    test_failed: {
      label: (
        <>
          <ConnectionStatusText text={label} />
          <Tooltip
            title={DATA_CONNECTION_TEST_FAILED}
            placement="top"
            size="small"
            iconSize="small"
            linkText="view troubleshooting tips."
            link={ZENDESK_DATA_CONNECTION_TEST_HOW_TO}
            linkProps={{
              target: "_blank"
            }}
            ml={2}
          />
        </>
      ),
      icon: <Icon.Error color="orange.1" />
    },
    backfilling: {
      label: label,
      icon: <Icon.Sync color="cyan.0" />,
      showDropdown: false,
      style: { opacity: 0.5 }
    },
    backfill_success: {
      label: <ConnectionStatusText text={label} />,
      icon: <Icon.CheckCircle color="green.1" />
    },
    backfill_failed: {
      label: (
        <>
          <ConnectionStatusText text={label} />
          <Tooltip
            title={
              <>
                {DATA_CONNECTION_BACKFILL_FAILED}{" "}
                <TooltipLink
                  linkText="view troubleshooting tips."
                  link={ZENDESK_PROPERTY_BACKFILL}
                  target="_blank"
                />
              </>
            }
            placement="top"
            size="small"
            iconSize="small"
            ml={2}
          />
        </>
      ),
      icon: <Icon.Error color="orange.1" />
    }
  };
  return statusProps[status] || {};
}

const StatusDropdown = ({ connectionData, onTestConnection, onBackfill }) => {
  const connectionStatus = connectionData.connection_status || "test_pending";
  return (
    <Dropdown size="large" {...getStatusDropdownProps(connectionStatus)}>
      {getMenuItems(
        connectionData,
        onTestConnection,
        onBackfill,
        connectionStatus
      )}
    </Dropdown>
  );
};

export default StatusDropdown;
