import React, { useEffect, useState, useRef, useMemo } from "react";
import _isEqual from "lodash/isEqual";
import _mapValues from "lodash/mapValues";
import { validateFields } from "./validator_helpers";

const getInitialState = (config, initialValues) => {
  if (typeof config === "function") {
    config = config({});
  }

  const initialTouched = {};
  for (let item in config.fields) {
    initialTouched[item] = config.fields[item].type === "file";
  }

  return {
    formItems: {
      ...initialValues
    },
    asyncErrors: {},
    errors: {},
    submitDisabled: false,
    touched: initialTouched,
    changed: {},
    hasChanges: false
  };
};

const getErrors = state => ({
  ...Object.entries(state.touched)
    .filter(([, touched]) => touched)
    .reduce(
      (acc, [name]) =>
        state.errors[name]
          ? { ...acc, [name]: state.errors[name] }
          : { ...acc },
      {}
    ),
  ...state.asyncErrors
});

/**
 * Validation return object type.
 * @typedef {Object} ValidationReturn
 * @property {boolean} hasChanges - If there are changes to initial data.
 * @property {Object} touched - Object with touched items.
 * @property {Object} formItems - Object with item values.
 * @property {Object} errors - Object with errors.
 * @property {function} setErrors - Function for custom error setting.
 * @property {function} setFormItems - Function for custom value setting.
 * @property {object} getFormProps - Object that contains form onSubmit prop. Use like <form {...getFormProps()}>
 * @property {object} getItemProps - Object that contains item props onChange, name, value and onBlur. Use like <input {...getItemProps("item_name", options = {})}>
 */

/**
 * @description Validation hook functionality. Uses validation functions from validator_helpers file to validate fields.
 * Which fields to validate should be passed trough config parameter in following format:
 * {
 *  fields:
 *    field: {
 *      validators: {
 *        exampleValidator: {
 *          message: "Example message"
 *        }
 *      }
 *    }
 *  }
 * }
 * Config can also be passed as a function that receives an object with all values and returns config object. Useful for cross-validation.
 *
 * @param {object|function} config Config parameter.
 * @param {object} initialValues Initial form values.
 * @param {function} onSubmit Function that is called on submit.
 * @param {function} asyncValidation Additional asynchronous validation to additionaly validate fields. Has to throw exception to register error.
 * @returns {ValidationReturn}
 */
const useValidation = (config, initialValues, onSubmit, asyncValidation) => {
  const [state, setState] = useState(getInitialState(config, initialValues));
  if (typeof config === "function") {
    config = config(state.formItems);
  }

  const initialValuesRef = useRef(initialValues);

  const setFormItems = formItems =>
    setState(prevState => ({
      ...prevState,
      formItems: {
        ...prevState.formItems,
        ...formItems
      }
    }));

  const setAsyncErrors = asyncErrors =>
    setState(prevState => ({
      ...prevState,
      asyncErrors: {
        ...asyncErrors
      }
    }));

  const setErrors = (errors, update = true) =>
    setState(prevState => ({
      ...prevState,
      errors: {
        ...(update ? prevState.errors : {}),
        ...errors
      }
    }));

  const setTouched = touched =>
    setState(prevState => ({
      ...prevState,
      touched: {
        ...prevState.touched,
        ...touched
      }
    }));

  const changeEvent = (item, value) => {
    setFormItems({
      [item]: value
    });
  };

  const updateHasChanges = () => {
    const changed = Object.keys(initialValuesRef.current).reduce(
      (dict, key) => {
        return Object.assign(dict, {
          [key]: state.formItems[key] !== initialValuesRef.current[key]
        });
      },
      {}
    );

    const hasChanges = Object.values(changed).reduce(
      (cur, val) => cur || val,
      false
    );

    setState(prevState => ({
      ...prevState,
      changed: changed,
      hasChanges: hasChanges
    }));
  };

  const touchedEvent = item => {
    if (!config.fields[item] || state.touched[item]) {
      return;
    }

    setTouched({ [item]: true });
  };

  const getValueFromEvent = (e, fieldType) => {
    let value;
    switch (fieldType) {
      case "file":
        value = typeof e === "object" ? e.target.files[0] : e;
        break;
      case "checkbox":
        value = typeof e === "object" ? e.target.checked : e;
        break;
      default:
        value =
          e !== null &&
          typeof e === "object" &&
          "target" in e &&
          typeof e.target === "object"
            ? e.target.value
            : e;
        break;
    }
    return value;
  };

  useEffect(() => {
    const errors = validateFields(state.formItems, config.fields);
    updateHasChanges();
    setErrors(errors, false);
    if (asyncValidation) {
      setState(prevState => ({
        ...prevState,
        submitDisabled: true
      }));

      Promise.resolve(asyncValidation(state.formItems))
        .then(() => setAsyncErrors({}))
        .catch(errors => setAsyncErrors(errors))
        .finally(() =>
          setState(prevState => ({
            ...prevState,
            submitDisabled: false
          }))
        );
    }
  }, [state.formItems]);

  useEffect(() => {
    if (!_isEqual(initialValues, initialValuesRef.current)) {
      initialValuesRef.current = initialValues;
      setFormItems({ ...initialValuesRef.current });
    }
  }, [initialValues]);

  const errors = useMemo(() => getErrors(state), [
    state.asyncErrors,
    state.errors,
    state.touched
  ]);

  return {
    touched: state.touched,
    hasChanges: state.hasChanges,
    errors: errors,
    setErrors: setErrors,
    formItems: state.formItems,
    setFormItems: setFormItems,
    submitDisabled: state.submitDisabled,
    getFormProps: () => ({
      onSubmit: e => {
        if (Object.keys(state.errors).length) {
          e.preventDefault();
          setTouched(_mapValues(state.touched, () => true));
          return false;
        }
        if (!state.submitDisabled && onSubmit) {
          e.preventDefault();
          onSubmit(state.formItems, state.changed);
          return false;
        }
        return true;
      }
    }),
    getItemProps: (
      item,
      { formatInputToValidator = v => v, formatValidatorToInput = v => v } = {}
    ) => {
      if (config.fields[item] && config.fields[item].type === "file") {
        return {
          onChange: e =>
            changeEvent(
              item,
              formatInputToValidator(
                getValueFromEvent(e, config.fields[item].type)
              )
            ),
          name: item
        };
      }

      return {
        onChange: e =>
          changeEvent(
            item,
            formatInputToValidator(
              getValueFromEvent(e, config.fields[item]?.type)
            )
          ),
        onBlur: e => touchedEvent(item),
        name: item,
        value: formatValidatorToInput(state.formItems[item])
      };
    }
  };
};

export default useValidation;
