import React, { useEffect } from "react";
import { Route } from "react-router-dom";
import ReactGA from "react-ga";
import posthog from "posthog-js";
import logger from "../utils/logger";

const loggerFactory = logger({})("gaTracked.js");
const error = loggerFactory("error");
const info = loggerFactory("info");

// https://github.com/react-ga/react-ga/issues/122#issuecomment-526944210
const TrackedRoute = props => {
  useEffect(() => {
    const page = props.location.pathname;
    ReactGA.set({ page });
    ReactGA.pageview(page);
    try {
      if (posthog?.capture !== undefined) {
        // using "_pageview" as the event
        // name so as not to collide with
        // the auto-captured event name..
        // posthog.capture("_pageview");
      }
    } catch (e) {
      error("Error capturing route info for posthog");
      info(e);
    }

    // lets track the page in segment...
    // note that we don't care if a valid
    // key was supplied to the sdk...
    if (props.path.includes("/projects/") == false) {
      // only track non-projects this way due to reqs
      window.analytics?.page(page);
    }
  }, [props.location.pathname]);
  return <Route {...props} />;
};

export { TrackedRoute };
