import { createAjaxAction, URLS } from "./helpers";

export default {
  requestPropertyAnalyticsProvider: publicId =>
    createAjaxAction(
      "AJAX_GET_PROPERTY_ANALYTICS_PROVIDER",
      `${URLS.analytics}analytics-provider/?project=${publicId}`
    )(),
  clearPropertyAnalyticsProvider: () => ({
    type: "CLEAR_PROPERTY_ANALYTICS_PROVIDER"
  }),
  testAnalyticsConnection: projectId =>
    createAjaxAction(
      "AJAX_POST_TEST_CONNECTION",
      `${URLS.analytics}test-connection/`
    )({ body: { project_id: projectId } }),
  backfillGoogleAnalytics: (projectId, startDate, endDate) =>
    createAjaxAction(
      "AJAX_POST_BACKFILL_GOOGLE_ANALYTICS",
      `${URLS.analytics}backfill-date-range/`
    )({
      body: { project_id: projectId, start_date: startDate, end_date: endDate }
    })
};
