import { GOOGLE_MAPS_API_LIBRARIES, GOOGLE_MAPS_BASE_URL } from "../constants";
import { isBrowser } from "./misc";

export const loadGoogleMapScript = () => {
  if (!isBrowser || !process.env.GOOGLE_MAP_API_KEY) return Promise.resolve();

  if (typeof google !== "undefined") {
    if (google.maps && google.maps.api) return Promise.resolve();
  }

  const scriptElements = document.querySelectorAll(
    `script[src*="${GOOGLE_MAPS_BASE_URL}"]`
  );

  if (scriptElements && scriptElements.length) {
    return new Promise(resolve => {
      if (typeof google !== "undefined") return resolve();

      scriptElements[0].addEventListener("load", () => resolve());
    });
  }

  const el = document.createElement("script");
  el.src = `${GOOGLE_MAPS_BASE_URL}?libraries=${GOOGLE_MAPS_API_LIBRARIES}&key=${process.env.GOOGLE_MAP_API_KEY}`;

  document.body.appendChild(el);

  return new Promise(resolve => {
    el.addEventListener("load", () => resolve());
  });
};
