import { qsStringify } from "../../utils/misc";
import checkFlag from "../../utils/posthog";
import { createAjaxAction, URLS } from "./helpers";

const actions = {
  requestGroups: data =>
    createAjaxAction(
      "AJAX_GET_PROPERTY_GROUP",
      `${URLS.property_group}/`,
      qs => qs && qsStringify(qs),
      true
    )(data),
  requestGroupsList: createAjaxAction(
    "AJAX_GET_PROPERTY_GROUP_LIST",
    `${URLS.property_group}/groups`
  ),
  requestPortfolioTags: groupId =>
    createAjaxAction(
      "AJAX_GET_PORTFOLIO_TAGS",
      `${URLS.property_group}/${groupId}/tags`
    )(),
  update(payload) {
    return { type: "UPDATE_PROPERTY_GROUP", payload };
  },
  requestPropertyGroupMembers: data =>
    createAjaxAction(
      "AJAX_POST_PROPERTY_GROUP_MEMBERS",
      `${URLS.project}/users/`
    )({ body: data }),
  requestGroupProperties: groupId =>
    createAjaxAction(
      "AJAX_GET_GROUP_PROPERTIES",
      `${URLS.property_group}/${groupId}/properties`
    )()
};

export default actions;
