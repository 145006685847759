import React from "react";
import IconBase from "./icon_base";

const Trash = props => (
  <IconBase viewBox="0 0 10 12" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.33301 0.666667H8.99967C9.36634 0.666667 9.66634 0.966667 9.66634 1.33333C9.66634 1.7 9.36634 2 8.99967 2H0.999674C0.633008 2 0.333008 1.7 0.333008 1.33333C0.333008 0.966667 0.633008 0.666667 0.999674 0.666667H2.66634L3.13967 0.193333C3.25967 0.0733333 3.43301 0 3.60634 0H6.39301C6.56634 0 6.73967 0.0733333 6.85967 0.193333L7.33301 0.666667ZM2.33333 12C1.6 12 1 11.4 1 10.6667V4C1 3.26667 1.6 2.66667 2.33333 2.66667H7.66667C8.4 2.66667 9 3.26667 9 4V10.6667C9 11.4 8.4 12 7.66667 12H2.33333Z"
      fill="currentColor"
    />
  </IconBase>
);

export default Trash;
