import { Box, Flex, Icon, TextChart, Tooltip } from "concrete-ui";
import PropTypes from "prop-types";
import React from "react";

import { formatCurrency, valueOr } from "../../utils/formatters";

const TextChartTitle = ({ title, tooltip }) => (
  <Box alignItems="center">
    {title}{" "}
    <Tooltip title={tooltip} size="small" placement="bottom">
      <Box ml={2}>
        <Icon.Tooltip size={3} ml={2} />
      </Box>
    </Tooltip>
  </Box>
);
TextChartTitle.propTypes = {
  title: PropTypes.string.isRequired,
  tooltip: PropTypes.string.isRequired
};

const TextChartSummary = props => {
  const { title, tooltip, data, altData, ...other } = props;
  const defaultValue = "N/A";
  return (
    <TextChart
      ml={6}
      pt={3}
      size="small"
      data={valueOr(data, defaultValue, formatCurrency)}
      altData={valueOr(altData, defaultValue, formatCurrency)}
      title={<TextChartTitle title={title} tooltip={tooltip} />}
      {...other}
    />
  );
};
TextChartSummary.propTypes = {
  title: PropTypes.string.isRequired,
  tooltip: PropTypes.string.isRequired,
  data: PropTypes.number,
  altData: PropTypes.number
};

export const dataItemType = PropTypes.shape({
  budget: PropTypes.number,
  actual: PropTypes.number
});

class ChannelAnalysisSummary extends React.PureComponent {
  static propTypes = {
    data: PropTypes.shape({
      total_investment: dataItemType,
      new_lease_revenue: dataItemType,
      avg_cpl: dataItemType,
      avg_cpt: dataItemType,
      avg_cpla: dataItemType,
      avg_cpnl: dataItemType
    }),
    loading: PropTypes.bool
  };

  static defaultProps = {
    data: {}
  };

  render() {
    const { data, loading, ...otherProps } = this.props;
    return (
      <Flex flexDirection="column" {...otherProps}>
        <Flex ml={-6} mb={6}>
          <TextChartSummary
            title="Total Marketing Investment"
            tooltip="Tooltip Text"
            data={data?.total_investment?.budget}
            altData={data?.total_investment?.actual}
            context="Budgeted"
            altContext="Actual"
            loading={loading}
          />
          <TextChartSummary
            title="Total New Lease Revenue"
            tooltip="Tooltip Text"
            data={data?.new_lease_revenue?.budget}
            altData={data?.new_lease_revenue?.actual}
            context="Projected"
            altContext="Actual"
            loading={loading}
          />
          <TextChartSummary
            title="Average Cost per Lead"
            tooltip="Tooltip Text"
            data={data?.avg_cpl?.budget}
            altData={data?.avg_cpl?.actual}
            context="Budgeted"
            altContext="Actual"
            loading={loading}
          />
        </Flex>
        <Flex ml={-6}>
          <TextChartSummary
            title="Average Cost per Tour"
            tooltip="Tooltip Text"
            data={data?.avg_cpt?.budget}
            altData={data?.avg_cpt?.actual}
            context="Budgeted"
            altContext="Actual"
            loading={loading}
          />
          <TextChartSummary
            title="Average Cost per Lease Application"
            tooltip="Tooltip Text"
            data={data?.avg_cpla?.budget}
            altData={data?.avg_cpla?.actual}
            context="Budgeted"
            altContext="Actual"
            loading={loading}
          />
          <TextChartSummary
            title="Average Cost per New Lease"
            tooltip="Tooltip Text"
            data={data?.avg_cpnl?.budget}
            altData={data?.avg_cpnl?.actual}
            context="Budgeted"
            altContext="Actual"
            loading={loading}
          />
        </Flex>
      </Flex>
    );
  }
}

export default ChannelAnalysisSummary;
