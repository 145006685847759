import PropTypes from "prop-types";
import React from "react";

import IconBase from "./icon_base";

const User = props => {
  const { bgColor, pathColor, ...otherProps } = props;
  return (
    <IconBase viewBox="0 0 40 40" fill="none" {...otherProps}>
      <mask
        id="mask0"
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="40"
        height="40"
      >
        <circle cx="20" cy="20" r="20" fill={bgColor} />
      </mask>
      <g mask="url(#mask0)">
        <circle cx="20" cy="20" r="20" fill={bgColor} />
        <path
          d="M20.5 9C18.673 9 16.9207 9.72579 15.6288 11.0177C14.3369 12.3096 13.6111 14.0618 13.6111 15.8889C13.6111 17.7159 14.3369 19.4682 15.6288 20.7601C16.9207 22.052 18.673 22.7778 20.5 22.7778C22.327 22.7778 24.0793 22.052 25.3712 20.7601C26.6631 19.4682 27.3889 17.7159 27.3889 15.8889C27.3889 14.0618 26.6631 12.3096 25.3712 11.0177C24.0793 9.72579 22.327 9 20.5 9ZM20.5 27.9444C15.3264 27.9444 5 30.5416 5 35.6944V40H36V35.6944C36 30.5416 25.6736 27.9444 20.5 27.9444Z"
          fill={pathColor}
        />
      </g>
    </IconBase>
  );
};
User.propTypes = {
  bgColor: PropTypes.string,
  pathColor: PropTypes.string
};
User.defaultProps = {
  bgColor: "#20272e",
  pathColor: "#798796"
};

export default User;
