import { createAjaxAction, URLS } from "./helpers";

const actions = {
  updateState: state => ({
    type: "ALERT_UPDATE_STATE",
    state
  }),
  updateSelected: selectedEvents => ({
    type: "ALERT_UPDATE_SELECTED_EVENTS",
    selectedEvents
  }),
  updateSubscriptionPreference: (alertId, alert, emailOn, notificationOn) => ({
    type: "ALERT_UPDATE_SUBSCRIPTION_PREFERENCE",
    alertId,
    alert,
    emailOn,
    notificationOn
  }),
  createAlert: data =>
    createAjaxAction(
      "AJAX_POST_CREATE_ALERT",
      `${URLS.alerts}`
    )({ body: data }),
  update: (alertId, alert) =>
    createAjaxAction(
      "AJAX_PATCH_UPDATE_ALERT",
      `${URLS.alerts}${alertId}/`
    )({ body: alert }),
  deleteAlert: alertId => ({
    type: "DELETE_ALERT",
    alertId: alertId
  }),
  markAlertsNotificationSeen: eventIds => ({
    type: "ALERTS_NOTIFICATION_SEEN",
    eventIds: eventIds
  }),
  getAlerts: createAjaxAction("AJAX_GET_ALERTS", URLS.alerts),
  getNotifications: createAjaxAction(
    "AJAX_GET_ALERTS_NOTIFICATIONS",
    "/api/alerts/notifications/"
  ),
  setSubscriptionTypes: (alertId, subscriptionTypes) =>
    createAjaxAction(
      "AJAX_POST_ALERTS_SUBSCRIPTIONS",
      `/api/alerts/${alertId}/subscribers`
    )({ body: { subscriptionTypes } })
};

export default actions;
