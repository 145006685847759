import React from "react";
import PropTypes from "prop-types";
import { Box, Button, ModalSmall, Text } from "concrete-ui";

const ConfirmModal = ({
  title,
  content,
  hyperlink,
  addBackground,
  confirmButtonText,
  cancelButtonText,
  onClose,
  onConfirm,
  hidePrimaryButtonComponent,
  hideSecondaryButtonComponent,
  modalProps = {}
}) => {
  return (
    <>
      <Box
        position="fixed"
        top={0}
        right={0}
        bottom={0}
        left={0}
        bg={addBackground ? "gray.0" : ""}
        opacity="0.85"
        zIndex={1000}
      />
      <Box
        position="fixed"
        top={0}
        right={0}
        bottom={0}
        left={0}
        alignItems="center"
        justifyContent="center"
        zIndex={1001}
      >
        <ModalSmall
          title={title}
          secondaryButtonOnClick={onClose}
          secondaryButtonText={cancelButtonText}
          closeButtonOnClick={onClose}
          primaryButtonComponent={
            hidePrimaryButtonComponent || (
              <Button
                variant="danger"
                size="large"
                type="button"
                onClick={onConfirm}
              >
                {confirmButtonText}
              </Button>
            )
          }
          secondaryButtonComponent={hideSecondaryButtonComponent}
          {...modalProps}
        >
          {React.isValidElement(content) ? (
            React.cloneElement(content)
          ) : (
            <Text textSize={2} $color="text.gray.2">
              {Array.isArray(content)
                ? content.reduce((con, elem) => {
                    con.push(elem);
                    con.push(<br />);
                    return con;
                  }, [])
                : content}
              {hyperlink ? (
                <Text textSize={2} as={"a"}>
                  {hyperlink}
                </Text>
              ) : (
                ""
              )}
            </Text>
          )}
        </ModalSmall>
      </Box>
    </>
  );
};
ConfirmModal.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
    PropTypes.array
  ]).isRequired,
  hyperlink: PropTypes.object,
  confirmButtonText: PropTypes.string.isRequired,
  cancelButtonText: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  modalProps: PropTypes.object
};

export default ConfirmModal;
