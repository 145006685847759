export const expenseOccurrenceOptions = [
  {
    id: "one_time",
    value: "one_time",
    label: "One Time",
    name: "one_time"
  },
  {
    id: "recurring",
    value: "recurring",
    label: "Recurring",
    name: "recurring"
  },
  {
    id: "pay_for_performance",
    value: "pay_for_performance",
    label: "Pay for Performance",
    name: "pay_for_performance"
  }
];

export const repeatsOpts = [
  {
    id: "monthly",
    value: "monthly",
    label: "Monthly",
    name: "monthly"
  },
  {
    id: "yearly",
    value: "yearly",
    label: "Yearly",
    name: "yearly"
  }
];

export const frequencyOpts = [
  ...repeatsOpts,
  {
    value: "one_time",
    label: "One Time"
  }
];

export const contractTermsOpts = [
  {
    id: "month_to_month",
    value: "month_to_month",
    label: "Month-to-Month",
    name: "month_to_month"
  },
  {
    id: "fixed_length",
    value: "fixed_length",
    label: "Fixed Length",
    name: "fixed_length"
  }
];

export const expenseTypeOpts = [
  {
    id: "variable_expense",
    value: "variable_expense",
    label: "Variable Expense",
    name: "variable_expense"
  },
  {
    id: "fixed_expense",
    value: "fixed_expense",
    label: "Fixed Expense",
    name: "fixed_expense"
  }
];

export const paidEventTypeOpts = [
  {
    id: "leads",
    value: "leads",
    label: "Leads",
    name: "leads"
  },
  {
    id: "applications",
    value: "applications",
    label: "Lease Applications",
    name: "applications"
  },
  {
    id: "new_lease",
    value: "new_lease",
    label: "New Leases",
    name: "new_lease"
  }
];

export const monthOpts = [
  { label: "January", value: 1 },
  { label: "February", value: 2 },
  { label: "March", value: 3 },
  { label: "April", value: 4 },
  { label: "May", value: 5 },
  { label: "June", value: 6 },
  { label: "July", value: 7 },
  { label: "August", value: 8 },
  { label: "September", value: 9 },
  { label: "October", value: 10 },
  { label: "November", value: 11 },
  { label: "December", value: 12 }
];
