import React, { forwardRef } from "react";
import DropdownFilters from "../dropdown_filters";
import { Flex, Icon, Text, Tooltip } from "concrete-ui";
import { FILTER_BY_EXPENSES_TOOLTIP } from "../../constants/channel_analysis";
import { ZENDESK_CHANNEL_ANALYSIS_FILTER_BY_EXPENSES } from "../../constants";

const ChannelAnalysisFilter = forwardRef(
  ({ data, byExpensesFilters, onSetFilter }, ref) => {
    // We only show filter button if there are budgeted expenses for the property
    const hasBudgeted = byExpensesFilters.includes("hasBudgeted");
    const hasActual = byExpensesFilters.includes("hasActual");
    const hasMissing = byExpensesFilters.includes("hasMissing");

    // If there are no actuals or missing, then option not available, respectively.
    const getByExpenseFilterOptions = () => {
      let expenseFilter = {
        id: "byExpenses",
        title: (
          <Flex>
            <Text textSize={2} $fontWeight="regular" $color="text.white.1">
              By Expenses
            </Text>
            <Tooltip
              ml={2}
              title={FILTER_BY_EXPENSES_TOOLTIP}
              linkText={"Learn more."}
              link={ZENDESK_CHANNEL_ANALYSIS_FILTER_BY_EXPENSES}
              size="small"
              placement="right"
              linkProps={{
                target: "_blank"
              }}
            >
              <Icon.Tooltip size={3} my="auto" />
            </Tooltip>
          </Flex>
        ),
        name: "byExpenses",
        options: [
          {
            value: "budgetedOnly",
            label: "Budgeted Only",
            id: "budgetedOnly",
            name: "budgetedOnly"
          }
        ]
      };

      if (hasActual) {
        expenseFilter["options"].push({
          id: "actualOnly",
          value: "actualOnly",
          label: "Actual Only",
          name: "actualOnly"
        });
      }

      if (hasMissing) {
        expenseFilter["options"].push({
          id: "missingOnly",
          value: "missingOnly",
          label: "Missing Only",
          name: "missingOnly"
        });
      }
      return expenseFilter;
    };

    const applyFilter = filters => {
      onSetFilter(filters);
    };

    const getFilterCategories = () => {
      let expenseFilters = getByExpenseFilterOptions();
      return [expenseFilters];
    };

    return (
      <DropdownFilters
        ref={ref}
        menuPlacement="left"
        onApply={applyFilter}
        sections={getFilterCategories()}
        selected_property_group={{}}
        selectType={"radio"}
        disabled={!hasBudgeted}
      />
    );
  }
);

export default ChannelAnalysisFilter;
