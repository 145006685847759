import _find from "lodash/find";

const initState = {
  banners: []
};

const reducer = (state = initState, action) => {
  switch (action.type) {
    case "BANNERS_UPDATE": {
      return {
        ...state,
        banners: action.banners
      };
    }
    case "AJAX_GET_BANNERS_REQUEST": {
      return {
        ...state
      };
    }
    case "AJAX_GET_BANNERS_SUCCESS": {
      // merge new banners and old banners to retain those not closed
      const banners = [...state.banners, ...(action.payload || [])].reduce(
        (list, banner) => {
          if (!_find(list, ["id", banner.id])) {
            list.push(banner);
          }
          return list;
        },
        []
      );
      return {
        ...state,
        banners: banners
      };
    }
    case "AJAX_GET_BANNERS_ERROR": {
      return {
        ...state
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
