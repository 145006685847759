import React from "react";
import PropTypes from "prop-types";
import { Box, Button, Flex, Icon, Menu } from "concrete-ui";

function MiniMenu(props) {
  const [isOpen, setOpen] = React.useState(props.isOpen);
  const buttonRef = React.useRef(null);
  const handleClickOutside = e => {
    if (buttonRef && !buttonRef.current.contains(e.target)) {
      setOpen(false);
    }
  };
  React.useEffect(() => {
    document.addEventListener("mouseup", handleClickOutside);
    return () => {
      document.removeEventListener("mouseup", handleClickOutside);
    };
  });
  const display = isOpen ? "block" : "none";
  return (
    <Flex position="relative">
      <Button
        variant="icon"
        size="large"
        mr={2}
        onClick={() => setOpen(!isOpen)}
        ref={buttonRef}
        disabled={props.disabled}
      >
        <Icon.MoreHoriz />
      </Button>
      <Box
        position="absolute"
        right="12px"
        top="28px"
        minWidth="220px"
        zIndex={1}
        display={display}
      >
        <Menu width="100%">{props.children}</Menu>
      </Box>
    </Flex>
  );
}
MiniMenu.Divider = Menu.Divider;
MiniMenu.propTypes = {
  isOpen: PropTypes.bool,
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool
};
MiniMenu.defaultProps = {
  isOpen: false
};

export default MiniMenu;
