import cn from "classnames";
import PropTypes from "prop-types";
import React from "react";

import { Flash } from "../../icons";
import DetailsButton from "./index";
import "./insights_button.scss";

export default class InsightsButton extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    active: PropTypes.bool,
    disabled: PropTypes.bool,
    onClick: PropTypes.func
  };

  static defaultProps = {
    active: false,
    disabled: false
  };

  render() {
    const props = this.props;
    const classes = cn("insights-button", props.className);
    return (
      <DetailsButton {...props} className={classes}>
        <Flash className="insights-button__icon" />
        Insights
      </DetailsButton>
    );
  }
}
