import PropTypes from "prop-types";
import React from "react";

import { Button } from "concrete-ui";
import ModalWindow from "../modal_window";
import RenderIf from "../render_if";
import UserRow, { UserRowPending } from "../user_row";

import InviteModal from "./invite";
import "./members_modal.scss";

export default class ViewMembersModal extends React.PureComponent {
  static propTypes = {
    property_name: PropTypes.string.isRequired,
    members: PropTypes.array.isRequired,
    isOpen: PropTypes.bool,
    onClose: PropTypes.func
  };

  static defaultProps = {
    isOpen: false,
    onClose() {}
  };

  renderMembers = () => {
    const { members } = this.props;
    if (!members) {
      return null;
    }
    return members.map(member => {
      let roleLabel;
      const role = InviteModal.roleOptions.find(r => r.value === member.role);
      if (role) {
        roleLabel = role.label;
      }
      const { is_active, ...memberData } = member;
      return (
        <div className="invite-modal__member" key={member.user_id}>
          <UserRow
            className="invite-modal__user-row invite-modal__user-row--large"
            {...memberData}
          />
          {/* Commenting out until we distinguish between inactive and pending */}
          {/*<RenderIf condition={!is_active}>*/}
          {/*  <UserRowPending*/}
          {/*    className="invite-modal__user-row invite-modal__user-row--large"*/}
          {/*    {...memberData}*/}
          {/*  />*/}
          {/*</RenderIf>*/}
          <div className="invite-modal__role">{roleLabel}</div>
        </div>
      );
    });
  };

  render() {
    const { property_name, isOpen, onClose } = this.props;
    return (
      <ModalWindow className="invite-modal" open={isOpen} onClose={onClose}>
        <ModalWindow.Head className="invite-modal__header">
          <>
            <div className="invite-modal__title invite-modal__title--name">
              &nbsp;{property_name} Team
            </div>
          </>
        </ModalWindow.Head>
        <ModalWindow.Body>
          <div className="invite-modal__container invite-modal__container--users">
            {this.renderMembers()}
          </div>
          <div className="invite-modal__container invite-modal__container--button">
            <Button variant="primary" size="large" onClick={onClose}>
              Okay
            </Button>
          </div>
        </ModalWindow.Body>
      </ModalWindow>
    );
  }
}
