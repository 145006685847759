import React from "react";
import { Button, Text } from "concrete-ui";
import _isNil from "lodash/isNil";

import EnhancedLink from "../enhanced_link";
import { formatKPI } from "../../utils/kpi_formatters";
import { formatDateWithTokens } from "../../utils/formatters";
import checkFlag from "../../utils/posthog";

import "./property_group_table.scss";
import PaginationTable from "../pagination_table";

const getValueFormatter = kpi => value =>
  !_isNil(value) ? formatKPI(kpi, value) : "—";

const getSummarySubtitle = kpi => {
  if (
    [
      "occupancy_rate",
      "leased_rate",
      "exposure_rate",
      "inq_exe",
      "lease_cd_rate"
    ].includes(kpi)
  ) {
    return "Average";
  }
  return "Total";
};

export function PropertyGroupTable({
  data,
  kpi_order,
  date_selection,
  selected_property_group,
  tab_view,
  loadingState,
  summaryData
}) {
  const columnWidth = new Map([
    ["occupancy_rate", "160px"],
    ["leased_rate", "160px"],
    ["exposure_rate", "160px"],
    ["inq_exe", "160px"],
    ["lease_cd_rate", "160px"]
  ]);
  const kpiOrder = kpi_order || [];
  let columns = kpiOrder.map(kpi => ({
    Header: kpi.label,
    accessor: kpi.value,
    colWidth: columnWidth.get(kpi.value),
    valueFormatter: getValueFormatter(kpi.value),
    csvFormatter: getValueFormatter(kpi.value),
    aggregateSubtitle: getSummarySubtitle(kpi.value),
    ValueComponent: ({ value, textProps }) => (
      <Text {...textProps}>{getValueFormatter(kpi.value)(value)}</Text>
    ),
    sortType: "number"
  }));

  const propertyTab = checkFlag("channel-analysis")
    ? "channel_analysis"
    : "performance";
  columns.unshift({
    colWidth: "570px",
    Header: "Properties",
    accessor: "name",
    aggregateSubtitle: "Total",
    ValueComponent: ({ data, textProps, rowState }) => (
      <>
        <Text {...textProps}>
          {data.name}
          {data.type === "SyntheticProject" && (
            <Text
              style={{
                color: "#006EFF",
                fontStyle: "italic",
                marginLeft: "5px"
              }}
            >
              ({data.sub_name})
            </Text>
          )}
        </Text>
        {rowState.hover && (
          <EnhancedLink
            style={{
              color: "inherit",
              marginLeft: "32px",
              textDecoration: "inherit"
            }}
            to={`/property/${data.id}/${propertyTab}`}
          >
            <Button borderColor="gray.5" size="small" variant="secondary">
              View Property
            </Button>
          </EnhancedLink>
        )}
      </>
    )
  });

  const getCsvFileName = () => {
    let start = formatDateWithTokens(
      date_selection["start_date"],
      "yyyy-MM-dd"
    );
    let end = formatDateWithTokens(date_selection["end_date"], "yyyy-MM-dd");
    let response = `Remarkably_${selected_property_group["group_name"]}_${tab_view}_${start}-${end}.csv`;
    response = response.replace(/\s/g, "");
    return response;
  };

  return (
    <PaginationTable
      data={data}
      summaryData={summaryData}
      columns={columns}
      tableType="sub_portfolio"
      className="property-group-table"
      csvFileName={getCsvFileName()}
      loadingState={loadingState}
    />
  );
}

export default PropertyGroupTable;
