const initialState = {
  viewMembers: false,
  inviteMembers: false,
  address: false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "VIEW_MEMBERS_MODAL_SHOW": {
      return {
        ...state,
        viewMembers: true
      };
    }
    case "VIEW_MEMBERS_MODAL_HIDE": {
      return {
        ...state,
        viewMembers: false
      };
    }
    case "INVITE_MODAL_SHOW": {
      return {
        ...state,
        inviteMembers: true
      };
    }

    case "INVITE_MODAL_HIDE":
    case "ADDRESS_MODAL_SHOW": {
      return { ...state, address: true };
    }
    case "ADDRESS_MODAL_HIDE": {
      return { ...state, address: false };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
