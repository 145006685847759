const initialState = {
  properties: [],
  hasNextPage: false,
  pageNum: 1,
  updateSuccessMessage: "",
  updateError: {}
};

export default (state = initialState, action) => {
  let newState = {};
  switch (action.type) {
    case "SET_ACCOUNT_REPORTS_PROPERTIES": {
      const { properties, has_next_page, page_num } = action.data;
      let newProperties;
      if (page_num > 1) {
        newProperties = [...state.properties, ...properties];
      } else {
        newProperties = [...properties];
      }
      newState = {
        ...state,
        properties: newProperties,
        hasNextPage: has_next_page,
        pageNum: page_num
      };
      break;
    }
    case "CLEAR_ACCOUNT_REPORTS_PROPERTIES": {
      newState = { ...state, ...initialState };
      break;
    }
    case "AJAX_GET_ACCOUNT_SETTINGS_SUCCESS": {
      const {
        user,
        us_state_list,
        gb_county_list,
        office_countries,
        ...otherProps
      } = action.payload;
      newState = { ...state, ...otherProps };
      break;
    }
    case "AJAX_POST_SECURITY_DATA_REQUEST": {
      newState = {
        ...state,
        updateSuccessMessage: initialState.updateSuccessMessage,
        updateError: initialState.updateError
      };
      break;
    }
    case "AJAX_POST_SECURITY_DATA_SUCCESS": {
      if (action?.payload?.errors) {
        newState = {
          ...state,
          updateSuccessMessage: initialState.updateSuccessMessage,
          updateError: action.payload.errors
        };
      } else {
        newState = {
          ...state,
          updateSuccessMessage: action?.payload?.message,
          updateError: initialState.updateError
        };
      }
      break;
    }
    default:
      newState = state;
  }
  return newState;
};
