import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import { TextInput } from "concrete-ui";

import { useAutoCompleteWidget } from "../../utils/use_autocomplete_widget";

const GoogleAutocomplete = props => {
  const { refProp, onPlaceSelected, countries, types, fields, ...rest } = props;

  const { ref } = useAutoCompleteWidget({
    ref: refProp,
    onPlaceSelected,
    countries,
    types,
    fields
  });

  return <TextInput ref={ref} {...rest} />;
};

GoogleAutocomplete.propTypes = {
  refProp: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any })
  ]),
  onPlaceSelected: PropTypes.func,
  countries: PropTypes.arrayOf(PropTypes.string),
  types: PropTypes.arrayOf(PropTypes.string),
  fields: PropTypes.arrayOf(PropTypes.string)
};

export default forwardRef((props, ref) => (
  <GoogleAutocomplete {...props} refProp={ref} />
));
