const initialState = { isPostSuccessful: false, apiError: false, payload: {} };

export default (state = initialState, action) => {
  switch (action.type) {
    case "AJAX_POST_RESET_PASSWORD_REQUEST": {
      return { ...state };
    }
    case "AJAX_POST_RESET_PASSWORD_SUCCESS": {
      const payload = action.payload;
      return { ...state, payload, apiError: false, isPostSuccessful: true };
    }
    case "AJAX_POST_RESET_PASSWORD_ERROR": {
      const payload = action.payload;
      return {
        ...state,
        payload,
        apiError: true,
        isPostSuccessful: false
      };
    }
    default:
      return state;
  }
};
