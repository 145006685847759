import React from "react";
import PropTypes from "prop-types";
import {
  Box,
  DateRangeSelector,
  Flex,
  Link,
  ModalLarge,
  Text
} from "concrete-ui";

import { dateParse, dateToISO, isValid } from "../../utils/dates";
import { ZENDESK_PROPERTY_BACKFILL } from "../../constants";
import useValidation from "../../utils/validator_hook";
import backfillModalValidators from "./validators";

export const dateToValidation = date =>
  isValid(date) ? dateToISO(date) : null;

export const dateToDatePicker = date =>
  isValid(date) ? dateParse(date) : null;

const BackfillModal = ({ connectionData, onClose, onBackfill }) => {
  const defaultData = {
    start_date: null,
    end_date: null
  };

  const onSubmit = data => {
    onBackfill(
      connectionData.public_id,
      connectionData.integration_source,
      data.start_date,
      data.end_date
    );
  };

  const { getFormProps, getItemProps, errors } = useValidation(
    backfillModalValidators,
    defaultData,
    onSubmit
  );

  const startDateProps = getItemProps("start_date", {
    formatInputToValidator: dateToValidation,
    formatValidatorToInput: dateToDatePicker
  });

  const endDateProps = getItemProps("end_date", {
    formatInputToValidator: dateToValidation,
    formatValidatorToInput: dateToDatePicker
  });

  return (
    <>
      <Box
        position="fixed"
        top={0}
        bottom={0}
        left={0}
        right={0}
        zIndex={1000}
        bg="gray.1"
        opacity="0.8"
      />
      <Box
        position="fixed"
        top={0}
        bottom={0}
        left={0}
        right={0}
        zIndex={1001}
        justifyContent="center"
        alignItems="center"
      >
        <ModalLarge
          title={`Backfill ${connectionData.property} Data?`}
          secondaryButtonText="Cancel"
          primaryButtonText="Backfill"
          primaryButtonOnClick={getFormProps().onSubmit}
          closeButtonOnClick={onClose}
          secondaryButtonOnClick={onClose}
          mainContainerProps={{ overflow: "visible" }}
        >
          <Flex flexDirection="column">
            <Text textSize={2} $color="text.gray.2">
              Backfilling populates or refreshes historical data. Select a date
              range to backfill {connectionData.integration} data with. You can
              backfill up to 18000 days at a time.{" "}
              <Link
                textSize={2}
                href={ZENDESK_PROPERTY_BACKFILL}
                target="_blank"
              >
                Learn more.
              </Link>
            </Text>
            <Box mt={5} mb={6}>
              <DateRangeSelector
                startOptions={{
                  initialDate: startDateProps.value,
                  onDateSelect: startDateProps.onChange,
                  onBlur: startDateProps.onBlur,
                  label: "Start Date",
                  startOrEnd: "start",
                  minDate: new Date(2022, 0, 1),
                  errorMessage: errors.start_date
                }}
                endOptions={{
                  initialDate: endDateProps.value,
                  onDateSelect: endDateProps.onChange,
                  onBlur: endDateProps.onBlur,
                  label: "End Date",
                  startOrEnd: "end",
                  errorMessage: errors.end_date
                }}
              />
            </Box>
            <Text fontSize={2} $color="text.gray.2">
              Please note this action will overwrite existing data during date
              range selected, and can't be undone. Also, some data can't be
              backfilled.{" "}
              <Link
                textSize={2}
                href={ZENDESK_PROPERTY_BACKFILL}
                target="_blank"
              >
                Learn more.
              </Link>
            </Text>
          </Flex>
        </ModalLarge>
      </Box>
    </>
  );
};

BackfillModal.Proptypes = {
  connectionData: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onBackfill: PropTypes.func.isRequired
};

export default BackfillModal;
