const initialState = { companyAddresses: [], isLoading: false };

export default (state = initialState, action) => {
  switch (action.type) {
    case "AJAX_POST_COMPANY_ADDRESS_SUCCESS": {
      const companyAddresses = action.payload.addresses || [];
      return { ...state, isLoading: false, companyAddresses };
    }
    case "AJAX_POST_COMPANY_ADDRESS_REQUEST": {
      return { ...state, isLoading: true };
    }
    case "AJAX_POST_COMPANY_ADDRESS_ERROR": {
      return { ...state, isLoading: false };
    }
    case "CLEAR_COMPANY_ADDRESS": {
      return { ...initialState };
    }
    default:
      return state;
  }
};
