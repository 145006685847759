import React from "react";
import { Box, Flex, Divider, Text } from "concrete-ui";

export default ({
  children,
  number,
  titleText,
  titleSummary,
  fieldsContainerProps
}) => (
  <Flex flexDirection="column">
    <Divider variant="horizontal" bg="gray.4" mb={2} />
    <Flex px={4} pt={5} pb={11}>
      <Flex flexBasis="33%" mr={12}>
        <Flex flex="1" justifyContent="flex-end" px={3}>
          <Box
            bg="gray.5"
            height="5"
            width="5"
            justifyContent="center"
            alignItems="center"
            borderRadius="50%"
          >
            <Text textSize={2} $color="text.white.1" $fontWeight="medium">
              {number}
            </Text>
          </Box>
        </Flex>
        <Flex flex="8" flexDirection="column">
          <Text textSize={3} $color="text.white.1" $fontWeight="medium">
            {titleText}
          </Text>
          <Text textSize={2} $color="text.gray.1">
            {titleSummary}
          </Text>
        </Flex>
      </Flex>
      <Flex {...fieldsContainerProps}>{children}</Flex>
    </Flex>
  </Flex>
);
