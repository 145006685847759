import React from "react";
import IconBase from "./icon_base";

const NavLink = props => (
  <IconBase viewBox="0 0 12 12" {...props}>
    <path
      d="M11.4634 0.000217284C11.4439 0.000831441 11.4246 0.00253172 11.4053 0.00531014H8.3444C8.2753 0.00433281 8.20668 0.0171 8.14255 0.0428697C8.07842 0.0686394 8.02005 0.106898 7.97083 0.155421C7.92162 0.203944 7.88254 0.261765 7.85586 0.325524C7.82919 0.389282 7.81545 0.457705 7.81545 0.526818C7.81545 0.595931 7.82919 0.664355 7.85586 0.728113C7.88254 0.791871 7.92162 0.849692 7.97083 0.898216C8.02005 0.946739 8.07842 0.984997 8.14255 1.01077C8.20668 1.03654 8.2753 1.0493 8.3444 1.04833H10.2146L4.84652 6.41619C4.79646 6.46425 4.7565 6.52181 4.72897 6.5855C4.70145 6.64919 4.6869 6.71773 4.6862 6.78712C4.68549 6.8565 4.69863 6.92532 4.72486 6.98956C4.75109 7.0538 4.78987 7.11216 4.83893 7.16122C4.888 7.21029 4.94636 7.24907 5.0106 7.27529C5.07484 7.30152 5.14367 7.31466 5.21305 7.31396C5.28244 7.31325 5.35098 7.29871 5.41468 7.27118C5.47837 7.24365 5.53593 7.20369 5.58399 7.15364L10.952 1.78577V3.65587C10.9511 3.72497 10.9638 3.79359 10.9896 3.85771C11.0154 3.92184 11.0536 3.98021 11.1021 4.02943C11.1507 4.07864 11.2085 4.11772 11.2723 4.14439C11.336 4.17107 11.4044 4.1848 11.4736 4.1848C11.5427 4.1848 11.6111 4.17107 11.6749 4.14439C11.7386 4.11772 11.7964 4.07864 11.845 4.02943C11.8935 3.98021 11.9317 3.92184 11.9575 3.85771C11.9833 3.79359 11.9961 3.72497 11.9951 3.65587V0.593026C12.0055 0.517942 11.9994 0.441496 11.9772 0.369012C11.955 0.296527 11.9173 0.229746 11.8667 0.173319C11.8161 0.116891 11.7538 0.0721731 11.6841 0.0422781C11.6145 0.0123831 11.5391 -0.00197035 11.4634 0.000217284ZM1.04305 2.09134C0.472991 2.09134 0 2.56432 0 3.13436V10.957C0 11.527 0.472991 12 1.04305 12H8.86593C9.43599 12 9.90898 11.527 9.90898 10.957V7.05698V6.11508H8.86593V8.1V10.957H1.04305V3.13436H4.01525H5.0583H6V2.09134H5.0583H4.01525H1.04305Z"
      fill="currentColor"
    />
  </IconBase>
);

export default NavLink;
