import React from "react";

import "./top_navigation.scss";
import { Dropdown, Icon, Item, Menu, Link, Text } from "concrete-ui";
import ConfirmModal from "../confirm_modal";
import { ZENDESK_HELP } from "../../constants";
import ContactSupportModal from "../contact_support_modal";

class HelpButton extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      openContentModal: false,
      openSupportModal: false
    };
  }

  onOpenSupportModal = () => {
    this.setState({ openSupportModal: true });
  };

  onCloseSupportModal = () => {
    this.setState({ openSupportModal: false });
  };

  render() {
    return (
      <>
        <Dropdown
          size="small"
          label="Help"
          ml={4}
          width={"5.5rem"}
          maxHeight={6}
          icon={<Icon.Question $color="white.1" />}
        >
          {({ props }) => (
            <Menu {...props} width="8.5rem">
              <Item size="small" onClick={this.onOpenSupportModal}>
                Contact Support
              </Item>
              <Item
                size="small"
                onClick={() => this.setState({ openContentModal: true })}
              >
                Browse Content
              </Item>
            </Menu>
          )}
        </Dropdown>
        {this.state.openContentModal && (
          <ConfirmModal
            title={"Browse Help Content"}
            content={
              <Text
                textSize={2}
                $fontWeight="regular"
                $color="text.white.1"
                mr={2}
              >
                Need a little help? {<br />} Browse our{" "}
                <Link
                  textSize={2}
                  href={ZENDESK_HELP}
                  target="_blank"
                  $color="text.blue.0"
                >
                  Help Section
                </Link>{" "}
                to learn more about Remarkably features.
              </Text>
            }
            addBackground={true}
            hidePrimaryButtonComponent={true}
            hideSecondaryButtonComponent={true}
            onClose={() => this.setState({ openContentModal: false })}
          />
        )}
        <ContactSupportModal
          isOpen={this.state.openSupportModal}
          onClose={this.onCloseSupportModal}
        />
      </>
    );
  }
}

export default HelpButton;
