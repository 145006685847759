import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import _intersection from "lodash/intersection";

import DropdownFilters from "../dropdown_filters";
import _find from "lodash/find";
import { nullDashDisplay } from "../../constants";
import {
  frequencyOpts,
  expenseOccurrenceOptions
} from "../expense_entry_form/options";

function getOptions(values, getLabel = v => v) {
  return [...new Set(values.sort())].map(v => ({
    value: v,
    label: getLabel(v)
  }));
}

function getObjectOptions(values, fieldValue, fieldLabel) {
  const seen = new Set();
  const filteredArr = values.filter(el => {
    const duplicate = seen.has(el && el[fieldValue]);
    seen.add(el && el[fieldValue]);
    return !duplicate;
  });
  return filteredArr.map(v => ({
    value: v && v[fieldValue],
    label: v ? v[fieldLabel] : nullDashDisplay
  }));
}

const ExpensesFilter = forwardRef(
  ({ expenses, properties, onSetFilters, onUpdateExpenses }, ref) => {
    const getFilterCategories = () => {
      const property_ids = [];
      const expenseNames = [];
      const expenseType = [];
      const frequencies = [];
      const vendors = [];
      const users = [];
      const leadSources = [];
      expenses.forEach(data => {
        property_ids.push(data.property_id);
        expenseNames.push(data.expense_name);
        expenseType.push(data.expense_occurrence);
        frequencies.push(data.frequency);
        vendors.push(data.vendor);
        users.push(data.user_id);
        Array.prototype.push.apply(leadSources, data.lead_sources);
      });
      return [
        {
          id: "property_id",
          title: "Property",
          name: "property",
          options: getOptions(
            property_ids,
            v =>
              _find(properties, ["property_id", v])?.property_name ||
              nullDashDisplay
          )
        },
        {
          id: "expense_name",
          title: "Expense Name",
          name: "expenseName",
          options: getOptions(expenseNames)
        },
        {
          id: "lead_source",
          title: "Lead Source",
          name: "leadSource",
          options: getOptions(leadSources)
        },
        {
          id: "vendor",
          title: "Vendor",
          name: "Vendor",
          options: getObjectOptions(vendors, "id", "label") || nullDashDisplay
        },
        {
          id: "expense_type",
          title: "Expense Type",
          name: "Expense Type",
          options: getOptions(
            expenseType,
            v =>
              _find(expenseOccurrenceOptions, ["value", v])?.label ||
              nullDashDisplay
          )
        },
        {
          id: "frequency",
          title: "Frequency",
          name: "Frequency",
          options: getOptions(
            frequencies,
            v => _find(frequencyOpts, ["value", v])?.label || nullDashDisplay
          )
        },
        {
          id: "user_id",
          title: "Creator",
          name: "Creator",
          options: getObjectOptions(users, "value", "label")
        }
      ];
    };

    const applyFilter = filters => {
      const leadSourceFilter = filters.lead_source.map(o => o.value);
      const newData = expenses.filter(td => {
        return (
          (!filters.property_id.length ||
            filters.property_id.findIndex(x => x.value === td.property_id) !==
              -1) &&
          (!filters.expense_name.length ||
            filters.expense_name.findIndex(x => x.value === td.expense_name) !==
              -1) &&
          (!filters.expense_type.length ||
            filters.expense_type.findIndex(
              x => x.value === td.expense_occurrence
            ) !== -1) &&
          (!filters.frequency.length ||
            filters.frequency.findIndex(x => x.value === td.frequency) !==
              -1) &&
          (!filters.vendor.length ||
            filters.vendor.findIndex(x => x.value === td.vendor?.id) !== -1) &&
          (!filters.user_id.length ||
            filters.user_id.findIndex(x => x.value === td.user_id?.value) !==
              -1) &&
          (!leadSourceFilter.length ||
            !!_intersection(leadSourceFilter, td.lead_sources).length)
        );
      });
      onUpdateExpenses(newData);
      onSetFilters(filters);
    };

    return (
      <DropdownFilters
        ref={ref}
        menuPlacement="left"
        onApply={applyFilter}
        sections={getFilterCategories(expenses)}
        selected_property_group={{}}
      />
    );
  }
);

ExpensesFilter.propTypes = {
  expenses: PropTypes.arrayOf(PropTypes.object).isRequired,
  properties: PropTypes.arrayOf(PropTypes.object).isRequired,
  onSetFilters: PropTypes.func.isRequired,
  onUpdateExpenses: PropTypes.func.isRequired
};
ExpensesFilter.displayName = "ForwardRef(ExpensesFilter)";

export default ExpensesFilter;
