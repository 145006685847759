import { Text } from "concrete-ui";
import _find from "lodash/find";
import React from "react";
import AdminPropertiesMiniMenu from "../../components/admin_properties_mini_menu";

export function adminPropertiesColumns(
  selectedIndex,
  onEditProperty,
  onEditAttributes,
  onDeactivateProperty
) {
  const result = [
    {
      tableColumns: [
        {
          Header: "Property Name",
          colWidth: "20rem",
          accessor: "name"
        },
        {
          Header: "Street Address 1",
          colWidth: "14rem",
          accessor: "address_1"
        },
        {
          Header: "Street Address 2",
          colWidth: "14rem",
          accessor: "address_2"
        },
        {
          Header: "Country",
          colWidth: "14rem",
          accessor: "country"
        },
        {
          Header: "City",
          colWidth: "14rem",
          accessor: "city"
        },
        {
          Header: "State or Province",
          colWidth: "14rem",
          accessor: "state"
        },
        {
          Header: "Zip or Postal Code",
          colWidth: "14rem",
          accessor: "zip"
        },
        {
          Header: "Billing email",
          colWidth: "14rem",
          accessor: "billing_email"
        },
        {
          Header: "Status",
          colWidth: "14rem",
          accessor: "status"
        },
        {
          Header: "Added or Updated By",
          colWidth: "16rem",
          accessor: "added_updated_by"
        },
        {
          Header: "Last Updated",
          colWidth: "14rem",
          accessor: "last_updated",
          ValueComponent: ({ data }) => {
            return (
              <AdminPropertiesMiniMenu
                onEdit={() => onEditProperty && onEditProperty(data.public_id)}
                disableDeactivate={data.status == "Active" ? false : true}
                onDeactivateProperty={onDeactivateProperty}
                editButtonLabel="Edit Property Information"
                includeDeactivateOption={true}
                data={data}
              />
            );
          }
        }
      ],
      hiddenColumns: [
        "address_1",
        "address_2",
        "country",
        "zip",
        "billing_email"
      ]
    },
    {
      tableColumns: [
        {
          Header: "Property Name",
          colWidth: "20rem",
          accessor: "name"
        },
        {
          Header: "Stage",
          colWidth: "14rem",
          accessor: "stage",
          csvFormatter: value =>
            value
              ? value.replace(/(^|[\s-])\S/g, function(match) {
                  return match.toUpperCase();
                })
              : "—",
          ValueComponent: ({ data }) => {
            let stageClass = "—";
            if (data.stage) {
              stageClass = data.stage.replace(/(^|[\s-])\S/g, function(match) {
                return match.toUpperCase();
              });
            }
            return <Text textSize={2}>{stageClass}</Text>;
          }
        },
        {
          Header: "Class",
          colWidth: "14rem",
          accessor: "building_class"
        },
        {
          Header: "Category",
          colWidth: "14rem",
          accessor: "type"
        },
        {
          Header: "Style",
          colWidth: "14rem",
          accessor: "style"
        },
        {
          Header: "Added or Updated By",
          colWidth: "16rem",
          accessor: "added_updated_by"
        },
        {
          Header: "Last Updated",
          colWidth: "14rem",
          accessor: "last_updated",
          ValueComponent: ({ data }) => {
            return (
              <AdminPropertiesMiniMenu
                onEdit={() =>
                  onEditAttributes && onEditAttributes(data.public_id)
                }
                disableDeactivate={data.status == "Active" ? false : true}
                onDeactivateProperty={onDeactivateProperty}
                editButtonLabel="Edit Property Attributes"
                includeDeactivateOption={false}
                data={data}
              />
            );
          }
        }
      ],
      hiddenColumns: []
    }
  ];

  return result[selectedIndex];
}

export const adminPropertiesTabs = [
  {
    name: "required_property_information",
    addButtonText: "Add Property",
    pageLink: "/admin-properties/add"
  },
  {
    name: "property_attributes",
    addButtonText: "Add Property Attributes",
    pageLink: "/admin-properties/add-attributes"
  }
];
