import { createAjaxAction, URLS } from "./helpers";

export default {
  getReportSettings: createAjaxAction(
    "AJAX_GET_REPORT_SETTINGS",
    `${URLS.create_report}`
  ),
  createReport: data =>
    createAjaxAction(
      "AJAX_POST_CREATE_REPORT",
      `${URLS.create_report}`
    )({ body: data })
};
