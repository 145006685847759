import React from "react";
import PropTypes from "prop-types";
import { Button } from "concrete-ui";

import AccountForm from "../account_form";
import PageAuth from "../page_auth";

class ResetPasswordExpired extends React.Component {
  static propTypes = {
    resendResetPassword: PropTypes.func.isRequired
  };

  render() {
    return (
      <PageAuth backLink="/">
        <AccountForm
          className="reset-password"
          title="Password Link Expired"
          subtitle="Your special link has expired. Please request a new link."
        >
          <Button
            variant="primary"
            size="large"
            fullWidth={true}
            onClick={this.props.resendResetPassword}
          >
            Re-send Link
          </Button>
        </AccountForm>
      </PageAuth>
    );
  }
}

export default ResetPasswordExpired;
