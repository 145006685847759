import React from "react";
import IconBase from "./icon_base";

const EmailOff = props => (
  <IconBase viewBox="0 0 16 16" {...props}>
    <rect
      width="17.9719"
      height="1.40578"
      transform="matrix(0.708424 0.705787 -0.708424 0.705787 1.17871 1.59937)"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.11968 2.78564C1.66 2.99584 1.34065 3.46208 1.34065 4.00008L1.33398 12.0001C1.33398 12.7334 1.93398 13.3334 2.66732 13.3334H12.6674L2.11968 2.78564ZM14.4248 12.7646C14.5774 12.5477 14.6673 12.2839 14.6673 12.0001V4.00008C14.6673 3.26675 14.0673 2.66675 13.334 2.66675H4.02086L14.4248 12.7646Z"
      fill="currentColor"
    />
  </IconBase>
);

export default EmailOff;
