import React from "react";

import { BellOn } from "../../icons";
import { Button, Icon } from "concrete-ui";
import "./alerts_crumb.scss";

export const AlertsCrumb = () => (
  <div className="alerts-crumb">
    <BellOn className="alerts-crumb__icon" />
    <span className="alerts-crumb__text">Alerts</span>
  </div>
);

export const CreateAlertCrumb = () => (
  <Button variant="secondary" size="small">
    <Icon.Plus color="white.1" />
    Create Alert
  </Button>
);
