import React from "react";
import { filterFunnelWidgetData, clampForecast } from "../../utils/widget";
import { LineChart, Icon, Tooltip, Flex, Text } from "concrete-ui";
import {
  CHART_TYPE,
  getHumanReadableKPI,
  KPI_MATCHER,
  SUPPORT_EMAIL
} from "../../constants";
import { dateToISO } from "../../utils/dates";
import checkFlag from "../../utils/posthog";
import { formatNumber } from "../../utils/formatters";

const MONTHS = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec"
];

const DAYS = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

export const tooltipDateFormatter = date => {
  const dateObj = new Date(date);
  return `${DAYS[dateObj.getUTCDay()]}, ${
    MONTHS[dateObj.getUTCMonth()]
  } ${dateObj.getUTCDate()}`;
};

export const getTitleValue = (kpi, widgetData) => {
  const titles = widgetData[0]?.titles;
  const titleValue = (titles && titles[kpi]) || 0;
  const isNumberChart = CHART_TYPE[kpi] === "number";
  return isNumberChart ? titleValue : `${(titleValue * 100).toFixed(2)}`;
};

export const formatTitleValue = (kpi, value) => {
  const isNumberChart = CHART_TYPE[kpi] === "number";
  return isNumberChart ? formatNumber(value, 0, 2) : formatNumber(value, 2);
};

const defaultMissingDataTooltip = (
  <Tooltip
    linkText="Contact Support"
    link={`mailto:${SUPPORT_EMAIL}`}
    placement="top"
    size="small"
  >
    <Icon.Error size={3} />
  </Tooltip>
);

const defaultEmptyDataTooltip = (
  <Tooltip
    maxWidth="none"
    width="15.3125rem"
    title="No data found. Try adjusting the date range and/or filter preferences."
    linkText="Contact Support"
    link={`mailto:${SUPPORT_EMAIL}`}
    size="small"
  />
);

export const ChartHOC = ({
  kpi,
  exploreArgs,
  newWidgetData = [{ data: [], forecast_data: [] }],
  loaderState,
  exploreOnClick,
  onDownloadHandler,
  decimalPlaces,
  tooltipDateFormatter,
  nullAsZero = true,
  yAxisOverflow = false,
  yDomain = { min: 0, max: "auto", emptyMax: 4 },
  chartLabel = null,
  enableMissingDataHighlight = true,
  missingDataTooltip = defaultMissingDataTooltip,
  titleTooltipDescription = null,
  emptyDataTooltip = defaultEmptyDataTooltip,
  dateRange
}) => {
  // we have so many different labels for each datum its insane...
  const _kpi = kpi in KPI_MATCHER ? KPI_MATCHER[kpi] : kpi;

  const data = getTitleValue(_kpi, newWidgetData);
  let series = [];
  if (Array.isArray(newWidgetData) && newWidgetData.length > 0) {
    let filteredWidgetData = filterFunnelWidgetData({
      nullAsZero,
      kpi,
      forecast_data: clampForecast({
        data: newWidgetData[0].forecast_data,
        chart_type: CHART_TYPE[kpi]
      }),
      data: newWidgetData[0].data
        ? newWidgetData[0].data
        : { data: [], name: "" },
      chart_type: CHART_TYPE[kpi]
    });
    series = [filteredWidgetData];

    // If the data is daily, we add a 7-day rolling average
    // If it's weekly, add a monthly rolling average:
    // Look at the filterWidgetData.data[].date (which looks like "2023-08-14", "2023-08-15", etc.)
    // to determine if it's daily or weekly:

    // This determines the rolling average period. We can't rely on the length of data, we must look at the distance between the CLOSEST two dates.
    try {
      if (filteredWidgetData.data.length >= 2) {
        const dataFrequency =
          (new Date(filteredWidgetData.data[1].date) -
            new Date(filteredWidgetData.data[0].date)) /
          (1000 * 60 * 60 * 24);

        if (dataFrequency === 1) {
          const rollingAveragePeriod = 7;
          const rollingAveragePeriodName = "7 days";
          const rollingAveragePeriodData = [];
          let rollingAveragePeriodSum = 0;
          let rollingAveragePeriodCount = 0;
          let rollingAveragePeriodDate = null;
          for (let i = 0; i < filteredWidgetData.data.length; i++) {
            if (i < rollingAveragePeriod) {
              rollingAveragePeriodSum += filteredWidgetData.data[i].value;
              rollingAveragePeriodCount++;
              rollingAveragePeriodDate = filteredWidgetData.data[i].date;
            }

            if (i >= rollingAveragePeriod) {
              rollingAveragePeriodSum -=
                filteredWidgetData.data[i - rollingAveragePeriod].value;
              rollingAveragePeriodSum += filteredWidgetData.data[i].value;
              rollingAveragePeriodDate = filteredWidgetData.data[i].date;
            }

            rollingAveragePeriodData.push({
              date: rollingAveragePeriodDate,
              value: rollingAveragePeriodSum / rollingAveragePeriodCount
            });
          }

          series[0].color = "gray[5]";
          series.push({
            name: `Rolling Average (${rollingAveragePeriodName})`,
            data: rollingAveragePeriodData,
            color: "blue[2]"
          });
        }
      }
    } catch (e) {
      console.error("Error in Chart:", e);
    }
  }

  function getChartTitleWithToolTip(kpi, description) {
    return (
      <Flex>
        <Text textSize={3} mr={2}>
          {getHumanReadableKPI(kpi)}
        </Text>
        <Tooltip
          description={description}
          children={
            <Flex alignItems={"center"}>
              <Icon.Tooltip size={3} />
            </Flex>
          }
        />
      </Flex>
    );
  }

  const range = {
    start: dateToISO(dateRange.start),
    end: dateToISO(dateRange.end)
  };
  return (
    <LineChart
      onDownload={onDownloadHandler}
      tooltipDateFormatter={tooltipDateFormatter}
      decimalPlaces={decimalPlaces}
      onExplore={exploreOnClick && (() => exploreOnClick(exploreArgs))}
      title={
        titleTooltipDescription
          ? getChartTitleWithToolTip(kpi, titleTooltipDescription)
          : getHumanReadableKPI(kpi)
      }
      loading={loaderState}
      data={data}
      mb={3}
      type={CHART_TYPE[kpi]}
      xAxisLabelKey="date"
      yAxisLabelKey="value"
      yAxisOverflow={yAxisOverflow}
      yDomain={yDomain}
      grid={{
        strokeDashArray: "0",
        horizontal: true
      }}
      enableTooltips
      missingDataTooltip={missingDataTooltip}
      enableMissingDataHighlight={enableMissingDataHighlight}
      emptyDataTooltip={emptyDataTooltip}
      series={series}
      context={chartLabel}
      dateRange={range}
    />
  );
};
