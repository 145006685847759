import React from "react";
import { Flex } from "concrete-ui";

export default ({ containerProps, children }) => {
  return (
    <Flex py={5} px={6} {...containerProps}>
      {children}
    </Flex>
  );
};
