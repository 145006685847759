import { createAjaxAction, URLS } from "./helpers";

const actions = {
  requestPropertyMembers: data =>
    createAjaxAction(
      "AJAX_POST_PROPERTY_MEMBERS",
      `${URLS.project}/users/`
    )({ body: data })
};

export default actions;
