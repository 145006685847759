import PropTypes from "prop-types";
import React from "react";

import { AddWhite } from "../../icons";
import "./add.scss";

const AddButton = ({ onClick }) => {
  return (
    <div className="add_tag_field__add" onClick={onClick}>
      <AddWhite />
      <div className="add_tag_field__add-text">Add Tag</div>
    </div>
  );
};
AddButton.propTypes = { onClick: PropTypes.func };
AddButton.defaultProps = { onClick() {} };

export default AddButton;
