export const dataConnectionConfig = formItems => ({
  fields: {
    public_id: {
      validators: {
        isRequired: {
          message: "This field is required."
        }
      }
    },
    integration_source: {
      validators: {
        isRequired: {
          message: "This field is required."
        }
      }
    },
    version_instance: {
      validators: {
        isRequired: {
          message: "This field is required."
        }
      }
    },
    id_code: {
      validators: {
        isRequired: {
          message: "This field is required."
        },
        condition: {
          when: () => formItems.integration_source === "google_analytics",
          then: {
            regex: {
              value: /^[0-9]{9}$/,
              message: "ID should be 9 digits."
            }
          }
        }
      }
    },
    setup_done: {
      type: "checkbox"
    }
  }
});
