import React from "react";
import { Button } from "concrete-ui";

import AccountForm from "../account_form";
import PageAuth from "../page_auth";

import "./reset_password_success.scss";

export default function ResetPasswordSuccess() {
  return (
    <PageAuth backLink="/">
      <AccountForm
        className="reset-password-success"
        title="Password has been reset!"
        subtitle="Log into your account with your new password."
      >
        <Button
          variant="primary"
          size="large"
          fullWidth={true}
          onClick={() => (window.location.href = "/")}
        >
          Go to Log in
        </Button>
      </AccountForm>
    </PageAuth>
  );
}
