import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Flex,
  Divider,
  Text,
  Card,
  ComboBox,
  Select,
  TextInput,
  RadioGroup,
  Checkbox,
  Button,
  Icon,
  Menu,
  Item,
  Tooltip,
  Link
} from "concrete-ui";

import AdminBreadcrumb from "../admin_breadcrumb";
import Breadcrumbs from "../breadcrumbs";
import useValidation from "../../utils/validator_hook";

import ConfirmationExpenseModal from "./confirmation-expense-modal";
import { expenseOccurrenceOptions } from "./options";
import { OneTimeExpense } from "./one-time-expense";
import { RecurringExpense } from "./recurring-expense";
import { PayForPerformanceExpense } from "./pay-for-performance-expense";
import { expenseEntryConfig } from "./validators";
import { SelectSearch } from "../select";
import { CreateLabelFactory } from "../select/select_components";
import { expenseModalProps, inputTextWithTooltip } from "./utils";
import { concreteErrorVariant } from "../../constants";
import FormField from "../form_field";
import { expenseTooltips, YARDI_CONNECTION_MISSING } from "./tooltips";

export const ExpenseEntryForm = ({
  initialData = {},
  properties = [],
  leadSources = [],
  paidLeadSources = [],
  vendors = [],
  confirmExpense = v => v,
  onChangeProperty = v => v
}) => {
  const [expenseModalVisible, setExpenseModalVisible] = useState(false);

  const defaultExpenseData = {
    expense_name: "",
    lead_sources: [],
    vendor: null,
    property_id: null,
    expense_occurrence: null
  };

  const defaultExpenseDetails = {
    cost_budgeted: null,
    start_date: null,
    end_date: null,

    budgeted_events_number: null,
    paid_event_type: null,
    terms: null,
    type: null,

    actual_expenses: []
  };

  const defaultData = {
    ...defaultExpenseData,
    ...defaultExpenseDetails
  };

  const onModalOpenClick = () => {
    setExpenseModalVisible(true);
  };

  const onModalCloseClick = () => {
    setExpenseModalVisible(false);
  };

  const {
    formItems,
    errors,
    getFormProps,
    getItemProps,
    setFormItems
  } = useValidation(
    expenseEntryConfig,
    {
      ...defaultData,
      ...initialData
    },
    onModalOpenClick
  );

  const expenseDetailsSwitch = () => {
    switch (formItems.expense_occurrence) {
      case "one_time":
        return (
          <OneTimeExpense
            formItems={formItems}
            getItemProps={getItemProps}
            errors={errors}
          />
        );
      case "recurring":
        return (
          <RecurringExpense
            formItems={formItems}
            getItemProps={getItemProps}
            errors={errors}
          />
        );
      case "pay_for_performance":
        return (
          <PayForPerformanceExpense
            formItems={formItems}
            getItemProps={getItemProps}
            errors={errors}
          />
        );
      default:
        return (
          <Text textSize={2} $color="text.gray.1">
            Choose expense type above.
          </Text>
        );
    }
  };

  const propertyProps = getItemProps("property_id");
  const onChangePropertySelect = value => {
    onChangeProperty(value);
    propertyProps.onChange(value);
    setFormItems({ lead_sources: [] });
  };
  const leadSourcesProps = getItemProps("lead_sources");

  const expenseOccurrenceProps = getItemProps("expense_occurrence");
  const onChangeExpenseOccurrence = value => () => {
    expenseOccurrenceProps.onChange(value);
    const resetActualExpenses = formItems.actual_expenses.filter(expense => {
      if (expense.id) {
        expense.to_delete = true;
        return true;
      }
      return false;
    });
    setFormItems({
      ...defaultExpenseDetails,
      actual_expenses: resetActualExpenses
    });
  };

  const expenseDetailsOptions = expenseOccurrenceOptions.map(item => {
    const opt = { ...item };
    if (opt.value === "pay_for_performance") {
      opt.isDisabled = formItems.lead_sources.length >= 2;
      if (
        opt.isDisabled &&
        expenseOccurrenceProps.value === "pay_for_performance"
      ) {
        onChangeExpenseOccurrence(null)();
      }
    }
    return opt;
  });

  const breadcrumbs = [
    { text: <AdminBreadcrumb />, link: "" },
    {
      text: "Expenses",
      link: "/expenses"
    },
    {
      text: "Add Expenses",
      link: ""
    }
  ];

  const formatCreateLabel = CreateLabelFactory("Add Vendor");
  const onCreateVendor = value => {
    const option = { label: value, id: null };
    setFormItems({ vendor: option });
  };
  const onChangeVendorHandle = vendor => {
    setFormItems({ vendor: vendor });
  };

  return (
    <>
      <Box bg="gray.1" width="100%" display="block">
        <Breadcrumbs breadcrumbs={breadcrumbs} />
      </Box>
      <Box width="62.5rem" mx="auto">
        <form {...getFormProps()}>
          <Card flexDirection="column" mt={10} minWidth="62.5rem">
            {expenseModalVisible && (
              <ConfirmationExpenseModal
                {...expenseModalProps[formItems.expense_occurrence]}
                closeHandler={onModalCloseClick}
                formItems={formItems}
                propertyOpts={properties}
                leadSourcesOpts={leadSources}
                submitExpense={() => confirmExpense(formItems)}
              />
            )}
            <Flex width="100%" py={5} px={6}>
              <Text textSize={4} $color="text.white.1" $fontWeight="medium">
                Add Expenses
              </Text>
            </Flex>

            <Divider variant="horizontal" bg="gray.4" mb={2} />

            <Flex px={4} pt={5} pb={11}>
              <Flex flexBasis="21rem" mr={12}>
                <Flex flex="1" justifyContent="flex-end" px={3}>
                  <Box
                    bg="gray.5"
                    height="5"
                    width="5"
                    justifyContent="center"
                    alignItems="center"
                    borderRadius="50%"
                  >
                    <Text
                      textSize={2}
                      $color="text.white.1"
                      $fontWeight="medium"
                    >
                      1
                    </Text>
                  </Box>
                </Flex>
                <Flex flex="8" flexDirection="column">
                  <Text textSize={3} $color="text.white.1" $fontWeight="medium">
                    Choose Property
                  </Text>
                  <Text textSize={2} $color="text.gray.1">
                    Chose a Property from your Portfolio to add an expense to.
                  </Text>
                </Flex>
              </Flex>
              <Flex flexBasis="23rem">
                <Select
                  size="large"
                  label="Property"
                  items={properties}
                  {...propertyProps}
                  onSelect={onChangePropertySelect}
                  error={!!errors.property_id}
                  errorMessage={errors.property_id}
                >
                  {({
                    props,
                    items,
                    selected,
                    selectItem,
                    noItemsComponent
                  }) => (
                    <Menu {...props}>
                      {items.length
                        ? items.map(item =>
                            item.disabled ? (
                              <Tooltip
                                size="small"
                                title={
                                  <>
                                    {YARDI_CONNECTION_MISSING + " "}
                                    <Link
                                      href={
                                        "/admin-connections/data_connections"
                                      }
                                      $color="text.gray.1"
                                      textSize={1}
                                      variant="underline"
                                      target="_blank"
                                    >
                                      Connect now.
                                    </Link>
                                  </>
                                }
                                placement="right"
                                style={{ display: "block" }}
                              >
                                <Item size="large" key={item.value} disabled>
                                  <Text
                                    textSize={2}
                                    ellipsis={true}
                                    style={{ backgroundColor: "transparent" }}
                                  >
                                    {item.label}
                                  </Text>
                                </Item>
                              </Tooltip>
                            ) : (
                              <Item
                                key={item.value}
                                size="large"
                                selected={selected === item.value}
                                onClick={() => selectItem(item.value)}
                              >
                                {item.label}
                              </Item>
                            )
                          )
                        : noItemsComponent}
                    </Menu>
                  )}
                </Select>
              </Flex>
            </Flex>

            <Divider variant="horizontal" bg="gray.4" mb={2} />

            <Flex px={4} pt={5} pb={8}>
              <Flex flexBasis="21rem" mr={12}>
                <Flex flex="1" justifyContent="flex-end" px={3}>
                  <Box
                    bg="gray.5"
                    height="5"
                    width="5"
                    justifyContent="center"
                    alignItems="center"
                    borderRadius="50%"
                  >
                    <Text
                      textSize={2}
                      $color="text.white.1"
                      $fontWeight="medium"
                    >
                      2
                    </Text>
                  </Box>
                </Flex>
                <Flex flex="8" flexDirection="column">
                  <Text textSize={3} $color="text.white.1" $fontWeight="medium">
                    Add Expense Information
                  </Text>
                  <Text textSize={2} $color="text.gray.1">
                    Add contextual information about the expense.
                  </Text>
                </Flex>
              </Flex>
              <Flex flexBasis="23rem" flexDirection="column">
                <TextInput
                  size="large"
                  label={inputTextWithTooltip(
                    "Expense Name",
                    expenseTooltips["expenseName"]
                  )}
                  {...getItemProps("expense_name")}
                  variant={errors.expense_name && concreteErrorVariant}
                  message={errors.expense_name}
                />
                <ComboBox
                  label="Associated Lead Source(s)"
                  help="If multiple are chosen, expense will be split evenly across sources."
                  helpTooltip={expenseTooltips.paidLeadSources}
                  multi
                  mt={5}
                  items={leadSources}
                  value={leadSourcesProps.value}
                  onBlur={leadSourcesProps.onBlur}
                  onSelect={leadSourcesProps.onChange}
                  error={!!errors.lead_sources}
                  errorMessage={errors.lead_sources}
                >
                  {({ props, items, selected, selectItem }) => (
                    <Menu {...props}>
                      {items.length ? (
                        items.map(item => (
                          <Item
                            size="large"
                            key={`expense_lead_item_${item.value}`}
                            name={`expense_lead_item_${item.value}`}
                            onClick={e => {
                              e.preventDefault();
                              selectItem(item.value);
                            }}
                          >
                            <Checkbox
                              key={`expense_lead_${item.value}`}
                              name={`expense_lead_${item.value}`}
                              value={selected.has(item.value)}
                              mr={2}
                            />
                            {item.label}
                            {paidLeadSources.includes(item.value) && (
                              <Box ml={2}>
                                <Icon.Expenses size={3} />
                              </Box>
                            )}
                          </Item>
                        ))
                      ) : (
                        <Text textSize={2} ml={3} py={1}>
                          No results found.
                        </Text>
                      )}
                    </Menu>
                  )}
                </ComboBox>
                <Box display="block" mt={5}>
                  <FormField
                    label={inputTextWithTooltip(
                      "Vendor Name",
                      expenseTooltips["vendorName"]
                    )}
                    showError={!!errors.vendor}
                    showIcon={false}
                    theme="modern"
                    {...(!!errors.vendor && { error: errors.vendor })}
                  >
                    <SelectSearch
                      isAsync={false}
                      placeholder="Choose or create new vendor"
                      components={{ DropdownIndicator: () => null }}
                      options={vendors}
                      isCreatable={true}
                      isClearable={true}
                      formatCreateLabel={formatCreateLabel}
                      {...getItemProps("vendor")}
                      onChange={onChangeVendorHandle}
                      onCreateOption={onCreateVendor}
                    />
                  </FormField>
                </Box>
              </Flex>
            </Flex>

            <Divider variant="horizontal" bg="gray.4" mb={2} />

            <Flex px={4} pt={5} pb={11}>
              <Flex flexBasis="21rem" mr={12}>
                <Flex flex="1" justifyContent="flex-end" px={3}>
                  <Box
                    bg="gray.5"
                    height="5"
                    width="5"
                    justifyContent="center"
                    alignItems="center"
                    borderRadius="50%"
                  >
                    <Text
                      textSize={2}
                      $color="text.white.1"
                      $fontWeight="medium"
                    >
                      3
                    </Text>
                  </Box>
                </Flex>
                <Flex flex="8" flexDirection="column">
                  <Text textSize={3} $color="text.white.1" $fontWeight="medium">
                    Enter Expense Details
                  </Text>
                  <Text textSize={2} $color="text.gray.1">
                    Choose a kind of expense and add important details about it.
                  </Text>
                </Flex>
              </Flex>
              <Box display="block" flexBasis="34.4375rem">
                <Flex alignItems={"center"}>
                  <RadioGroup
                    title=""
                    options={expenseDetailsOptions}
                    onChange={onChangeExpenseOccurrence}
                    activeRadioId={expenseOccurrenceProps.value}
                    errorMessage={errors.expense_occurrence}
                  />
                  <Tooltip
                    title={
                      expenseTooltips["budgetedExpense"]["pay_for_performance"][
                        "description"
                      ]
                    }
                    size="small"
                  />
                </Flex>
                <Divider variant="horizontal" bg="gray.4" my={2} />
                <Flex flexDirection="column" width="23rem" mt={4}>
                  {expenseDetailsSwitch()}
                </Flex>
              </Box>
            </Flex>

            <Divider variant="horizontal" bg="gray.4" />

            <Flex alignItems="center" justifyContent="flex-end" mx={5} my={5}>
              {Object.keys(errors).length ? (
                <>
                  <Icon.Error width={4} height={4} $color="orange.1" />
                  <Text
                    textSize={2}
                    $color="text.orange.0"
                    $fontWeight="regular"
                    ml={2}
                    mr={5}
                  >
                    Please correct the errors above before continuing.
                  </Text>
                </>
              ) : null}
              <Button variant="primary" size="large" type="submit">
                Review Expense
              </Button>
            </Flex>
          </Card>
        </form>
      </Box>
    </>
  );
};

ExpenseEntryForm.Proptypes = {
  initialData: PropTypes.object,
  properties: PropTypes.array.isRequired,
  leadSources: PropTypes.array.isRequired,
  confirmExpense: PropTypes.func,
  user: PropTypes.object
};

export default ExpenseEntryForm;
