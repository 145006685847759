import _isFunction from "lodash/isFunction";
import PropTypes from "prop-types";
import React from "react";

const RenderIf = ({ condition, children }) => {
  if (!condition) {
    return null;
  }
  return _isFunction(children) ? children() : children;
};
RenderIf.propTypes = {
  condition: PropTypes.bool.isRequired,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired
};

export default RenderIf;
