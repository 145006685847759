import { calendarDayRegex } from "../../utils/validator_helpers";

export const expenseEntryConfig = formItems => ({
  fields: {
    // Section 1
    property_id: {
      type: "select",
      validators: {
        isRequired: {
          message: "Property is required."
        }
      }
    },
    // Section 2
    expense_name: {
      validators: {
        isRequired: {
          message: "Expense name is required."
        }
      }
    },
    lead_sources: {
      type: "select",
      validators: {
        minLength: {
          message: "Choose at least one source.",
          value: 1
        }
      }
    },
    vendor: {
      type: "object",
      validators: {
        label: {
          isRequired: {
            message: "Vendor Name is required."
          }
        }
      }
    },
    // Section 3
    expense_occurrence: {
      validators: {
        isRequired: {
          message: "Choose an expense occurence."
        }
      }
    },
    start_date: {
      validators: {
        isRequired: {
          message: "Expense start date is required."
        }
      }
    },
    end_date: {
      validators: {
        isRequired: {
          message: "Expense end date is required."
        }
      }
    },
    cost_budgeted: {
      validators: {
        isRequired: {
          message: "Budgeted expense is required."
        },
        isNumberWithDecimals: {
          message:
            "Budgeted expense must be a number with up to 2 decimal places.",
          precision: 2
        }
      }
    },
    budgeted_events_number: {
      validators: {
        condition: {
          when: () => formItems.expense_occurrence === "pay_for_performance",
          then: {
            isRequired: {
              message: "Budgeted events number is required."
            },
            isNumberWithDecimals: {
              message:
                "Budgeted events number must be a number with up to 2 decimal places.",
              precision: 2
            }
          }
        }
      }
    },
    // Recurring
    type: {
      type: "select",
      validators: {
        condition: {
          when: () => formItems.expense_occurrence === "recurring",
          then: {
            isRequired: {
              message: "Expense type is required."
            }
          }
        }
      }
    },
    actual_expenses: {
      type: "object_array",
      validators: {
        expense_date: {
          isRequired: {
            message: "Expense start date is required."
          }
        },
        expense_date_end: {
          isRequired: {
            message: "Expense end date is required."
          }
        }
      }
    },
    terms: {
      validators: {
        condition: {
          when: () =>
            formItems.expense_occurrence === "recurring" ||
            formItems.expense_occurrence === "pay_for_performance",
          then: {
            isRequired: {
              message: "Contract terms are required."
            }
          }
        }
      }
    },
    // Pay for performance
    paid_event_type: {
      validators: {
        condition: {
          when: () => formItems.expense_occurrence === "pay_for_performance",
          then: {
            isRequired: {
              message: "Paid event type is required."
            }
          }
        }
      }
    }
  }
});
