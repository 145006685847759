import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _isEqual from "lodash/isEqual";

import AlertsPage from "../../components/alerts_page";
import { withLogin } from "../shared/login";
import { alertsActions, propertyActions } from "../../redux_base/actions";
import Loader from "../../components/loader";

class AlertsPageContainer extends React.PureComponent {
  static propTypes = {
    alerts: PropTypes.objectOf(
      PropTypes.shape({
        id: PropTypes.string,
        alert_scope: PropTypes.string,
        alert_subject: PropTypes.string,
        alert_type: PropTypes.string,
        comparator: PropTypes.string,
        creator_id: PropTypes.string,
        created_stamp: PropTypes.string,
        is_active: PropTypes.bool,
        is_deleted: PropTypes.bool,
        kpi: PropTypes.string,
        modifier_id: PropTypes.string,
        modified_stamp: PropTypes.string,
        owner_id: PropTypes.string,
        subscribers: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
        time_span: PropTypes.string,
        title: PropTypes.string,
        trigger_value: PropTypes.number,
        version_id: PropTypes.string
      })
    ),
    sisenseAlerts: PropTypes.objectOf(
      PropTypes.shape({
        name: PropTypes.string,
        _id: PropTypes.string,
        message: PropTypes.string,
        context: PropTypes.shape({
          condition: PropTypes.shape({
            leftOperand: PropTypes.number,
            rightOperand: PropTypes.number,
            operator: PropTypes.string,
            type: PropTypes.string
          })
        })
      })
    ),
    notifications: PropTypes.objectOf(
      PropTypes.shape({
        alert_id: PropTypes.string,
        activity_id: PropTypes.string,
        kpi_value: PropTypes.number,
        stamp: PropTypes.string
      })
    ),
    selected: PropTypes.object,
    user: PropTypes.shape({ email: PropTypes.string }),
    propertyNotifications: PropTypes.array,
    propertyGroupNotifications: PropTypes.array,
    propertyGroups: PropTypes.array.isRequired,
    propertyGroupsById: PropTypes.object,
    propertyGroupsIsLoading: PropTypes.bool.isRequired,
    propertyGroupsKPIOptions: PropTypes.array.isRequired,
    kpiPropertiesOptions: PropTypes.array.isRequired,
    properties: PropTypes.object.isRequired,
    propertiesById: PropTypes.object,
    propertyKPIsIsLoading: PropTypes.bool.isRequired,
    isFetching: PropTypes.bool
  };

  static defaultProps = {
    notifications: [],
    propertyNotifications: [],
    propertyGroupNotifications: [],
    propertiesById: {},
    propertyGroupsById: {},
    alerts: {},
    selected: new Set(),
    user: {},
    isFetching: false
  };

  componentDidMount() {
    if (
      !this.props.propertyGroupsIsLoading ||
      !this.props.propertyKPIsIsLoading
    ) {
      this.props.dispatch(propertyActions.requestProperties());
    }
    this.props.dispatch(alertsActions.getAlerts());
    this.props.dispatch(alertsActions.getNotifications());
  }

  componentDidUpdate(prevProps, prevState) {
    if (!_isEqual(prevProps.alerts, this.props.alerts)) {
      this.props.dispatch(alertsActions.getNotifications());
    }
  }

  toggleSubscriptionSettings = (alertId, emailOn, notificationOn) => {
    const alert = this.props.alerts[alertId];
    this.props.dispatch(
      alertsActions.updateSubscriptionPreference(
        alertId,
        alert,
        emailOn,
        notificationOn
      )
    );
  };

  onMarkAsRead = () => {
    this.props.dispatch(
      alertsActions.markAlertsNotificationSeen(Array.from(this.props.selected))
    );
  };

  onDelete = alertId => {
    this.props.dispatch(alertsActions.deleteAlert(alertId));
  };

  onSelect = selected => {
    this.props.dispatch(alertsActions.updateSelected(selected));
  };

  render() {
    return (
      <>
        <Loader isVisible={this.props.isFetching} />
        <AlertsPage
          propertyGroupNotifications={this.props.propertyGroupNotifications}
          propertyNotifications={this.props.propertyNotifications}
          selected={this.props.selected}
          onSelect={this.onSelect}
          onDelete={this.onDelete}
          onMarkAsRead={this.onMarkAsRead}
          toggleSubscription={this.toggleSubscriptionSettings}
        />
      </>
    );
  }
}

const mapState = state => ({
  alerts: state.alerts.alerts,
  notifications: state.alerts.notifications,
  propertyNotifications: state.alerts.propertyNotifications,
  propertyGroupNotifications: state.alerts.propertyGroupNotifications,
  selected: state.alerts.selectedEvents,
  propertyKPIsIsLoading: state.properties.propertyKPIsIsLoading,
  kpiPropertiesOptions: state.properties.kpiPropertiesOptions,
  properties: state.properties.items,
  propertyGroups: state.propertyGroup.property_groups,
  propertiesById: state.propertiesById,
  propertyGroupsById: state.propertyGroupsById,
  propertyGroupsIsLoading: state.propertyGroup.propertyGroupsIsLoading,
  propertyGroupsKPIOptions: state.propertyGroup.kpi_order,
  user: state.user,
  isFetching: state.network.isFetching
});

export default connect(mapState)(withLogin(AlertsPageContainer));
