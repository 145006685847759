import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import _isEqual from "lodash/isEqual";
import _isEmpty from "lodash/isEmpty";
import _get from "lodash/get";

import RenderIf from "../render_if";
import { narrativesActions } from "../../redux_base/actions";
import { formatDateWithTokens } from "../../utils/formatters";
import { KPI_HUMAN_LABEL, KPI_MATCHER } from "../../constants";

import NarrativesLoader from "./widget_narration_loader";
import NarrationCopyButton from "./narration_copy_button";
import "./narratives.scss";
import { format } from "date-fns";

class Narratives extends React.PureComponent {
  static propTypes = {
    view: PropTypes.string.isRequired,
    size: PropTypes.oneOf(["small", "regular"]),
    isShowWidget: PropTypes.bool,
    kpiId: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    // reducer props
    startDate: PropTypes.object.isRequired,
    endDate: PropTypes.object.isRequired,
    projects: PropTypes.array,
    narratives: PropTypes.object,
    errorCode: PropTypes.string,
    loading: PropTypes.bool
  };

  static defaultProps = {
    isShowWidget: true,
    size: "small"
  };

  constructor(props) {
    super(props);
    if (this.props.isShowWidget && !this.narratives) {
      this.props.dispatch(narrativesActions.getNarratives(this.urlParams));
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { startDate, endDate, projects, isShowWidget } = this.props;
    if (
      isShowWidget &&
      !this.narratives &&
      (!_isEqual(startDate, prevProps.startDate) ||
        !_isEqual(endDate, prevProps.endDate) ||
        !_isEqual(projects, prevProps.projects) ||
        !prevProps.isShowWidget)
    ) {
      this.props.dispatch(narrativesActions.getNarratives(this.urlParams));
    }
  }

  renderNarration = () => {
    let textList = undefined;
    const narrationText = this.narrativesByKPI;
    if (narrationText?.length > 0) {
      textList = narrationText.map(function(val, i) {
        return (
          <li className="narration__list-item" key={i}>
            {val}
          </li>
        );
      });
    } else {
      textList = [
        <li className="narration__list-item" key={0}>
          No insights available for selected period.
        </li>
      ];
    }
    return <ul className="narration__list">{textList}</ul>;
  };

  setErrorMessage = errorCode => {
    if (errorCode === "date") {
      return (
        <div className="narration__no-data">
          No Available Data For Selected Date Range
        </div>
      );
    }
    return <div className="narration__no-data">No Available Data</div>;
  };

  get urlParams() {
    return {
      start: format(this.props.startDate, "yyyy-MM-dd"),
      end: format(this.props.endDate, "yyyy-MM-dd"),
      projects: this.props.projects
    };
  }

  get narratives() {
    return this.props.narratives[JSON.stringify(this.urlParams)];
  }

  get narrativesByKPI() {
    const { kpiId } = this.props;
    if (kpiId in KPI_MATCHER) {
      let kpi_label = KPI_MATCHER[kpiId];
      if (this.narratives && kpi_label in this.narratives) {
        return this.narratives[kpi_label];
      }
    }
    return [];
  }

  get narrationText() {
    const { startDate, endDate, kpiId, view, name } = this.props;
    let text = name ? name : "";
    let start;
    if (startDate.getFullYear() === endDate.getFullYear()) {
      start = formatDateWithTokens(startDate, "MMM. d");
    } else {
      start = formatDateWithTokens(startDate, "MMM. d, yyyy");
    }
    const end = formatDateWithTokens(endDate, "MMM. d, yyyy");
    text += `\n${start} – ${end}`;

    const kpiName = _get(KPI_HUMAN_LABEL, [kpiId], "");
    text += `\n${kpiName}\n\n`;
    text += this.narrativesByKPI.reduce((res, nar) => {
      res += `${nar}\n`;
      return res;
    }, "");
    const currentDate = formatDateWithTokens(Date.now(), "MMM. d, yyyy");
    text += `\nCopied from Remarkably on ${currentDate}`;
    return text;
  }

  render() {
    const { narratives, isShowWidget, size, errorCode, loading } = this.props;
    const noData = _isEmpty(narratives);
    return (
      <div className="narration" hidden={!isShowWidget}>
        <div className="narration__container">
          <RenderIf condition={!noData && !loading}>
            {this.renderNarration()}
          </RenderIf>
          <RenderIf condition={noData && !loading}>
            {this.setErrorMessage(errorCode)}
          </RenderIf>
          <NarrativesLoader isVisible={loading} size={size} />
        </div>
        <RenderIf condition={!_isEmpty(this.narrativesByKPI) && !loading}>
          <NarrationCopyButton text={this.narrationText} />
        </RenderIf>
      </div>
    );
  }
}

const mapState = ({ filters, narratives, propertyGroup }) => ({
  projects: filters?.filters?.members || [],
  narratives: narratives["narratives"],
  errorCode: narratives["errorCode"],
  loading: narratives["loading"],
  startDate: propertyGroup.date_selection?.start_date,
  endDate: propertyGroup.date_selection?.end_date
});

export default connect(mapState)(Narratives);
