import React from "react";
import { Button, Box, Icon, Text, TextInput } from "concrete-ui";
import PropTypes from "prop-types";

import AccountForm from "../account_form";
import PageAuth from "../page_auth";
import useValidation from "../../utils/validator_hook";
import {
  concreteErrorVariant,
  SUPPORT_EMAIL,
  UNPROCESSABLE_ENTITY_CODE
} from "../../constants";

const linkStyles = {
  "text-decoration-line": "underline",
  "text-underline-offset": "5px"
};

const config = {
  fields: {
    email: {
      validators: {
        isRequired: {
          message: "Email is required."
        },
        isEmail: {
          message: "Enter a correct email format."
        }
      }
    }
  }
};

const ResetPasswordForm = ({ onSubmit, apiError, data }) => {
  const initialValues = {
    email: ""
  };

  const { errors, getFormProps, getItemProps } = useValidation(
    config,
    initialValues,
    onSubmit
  );

  return (
    <PageAuth backLink="/">
      <AccountForm
        className="reset-password"
        title="Reset my password"
        subtitle="Enter your email address below and we’ll send a special link to get you back on track."
      >
        <form method="post" {...getFormProps()}>
          <TextInput
            size="large"
            label="Email:"
            color="highlight"
            {...getItemProps("email")}
            message={errors["email"]}
            variant={errors["email"] && concreteErrorVariant}
          />
          <Button
            mt={20}
            variant="primary"
            size="large"
            fullWidth={true}
            type="submit"
          >
            Send Link
          </Button>
          {apiError && data.status == UNPROCESSABLE_ENTITY_CODE && (
            <Box alignItems="center">
              <Icon.Error width={4} height={4} $color="orange.1" />
              <Text
                ml={2}
                mr={5}
                mt={2}
                textSize={2}
                $color="orange.1"
                $fontWeight="regular"
              >
                Looks like we can't find {data.data.email} in our records.
                Please contact{" "}
                <Text
                  as="a"
                  textSize={2}
                  style={linkStyles}
                  href={`mailto:${SUPPORT_EMAIL}`}
                  $color="text.orange.0"
                >
                  Support
                </Text>
                .
              </Text>
            </Box>
          )}
          {apiError && data.status != UNPROCESSABLE_ENTITY_CODE && (
            <Box alignItems="center">
              <Icon.Error width={4} height={4} $color="orange.1" />
              <Text
                ml={2}
                mr={5}
                mt={2}
                textSize={2}
                $color="orange.1"
                $fontWeight="regular"
              >
                Oops, something went wrong. Please contact{" "}
                <Text
                  as="a"
                  textSize={2}
                  style={linkStyles}
                  href={`mailto:${SUPPORT_EMAIL}`}
                  $color="text.orange.0"
                >
                  Support
                </Text>
                .
              </Text>
            </Box>
          )}
        </form>
      </AccountForm>
    </PageAuth>
  );
};

ResetPasswordForm.propTypes = {
  onSubmit: PropTypes.func,
  apiError: PropTypes.bool,
  data: PropTypes.object
};

export default ResetPasswordForm;
