import React, { useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { Flex } from "concrete-ui";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import _find from "lodash/find";

import { withLogin } from "../shared/login";
import Loader from "../../components/loader";
import { pmsDataPropTypes } from "../admin_connections";
import AdminPMSInstanceForm from "../../components/admin_pms_instance_form";
import { connectionsActions } from "../../redux_base/actions";

const AdminPMSInstanceFormContainer = ({
  match,
  history,
  dispatch,
  // reducer props
  isPostSuccessful,
  pmsDataLoading,
  apiError,
  // data
  pmsData
}) => {
  const { id } = match.params;
  const initialPMSData = useMemo(
    () => _find(pmsData, ["id", parseInt(id)]) || {},
    [pmsData, id]
  );

  useEffect(() => {
    if (!pmsData.length) {
      dispatch(connectionsActions.requestPMSInstances());
    }
  }, []);

  useEffect(() => {
    if (isPostSuccessful) {
      dispatch(connectionsActions.updateState({ isPostSuccessful: false }));
      history.push("/admin-connections");
    }
  }, [isPostSuccessful]);

  const connectionsCount = initialPMSData.connections
    ? initialPMSData.connections.length
    : 0;

  // cancel
  const onCancel = () => history.push("/admin-connections");

  // submit
  const onSubmit = data => {
    if (id) {
      dispatch(connectionsActions.patchPMS(data, id));
    } else {
      dispatch(connectionsActions.postPMS(data));
    }
  };

  return (
    <Flex flexDirection="column" pb={"12rem"}>
      <AdminPMSInstanceForm
        initialData={initialPMSData}
        onConfirm={onSubmit}
        onCancel={onCancel}
        apiError={apiError}
        connectionsCount={connectionsCount}
        editing={Object.keys(initialPMSData).length !== 0}
      />
      <Loader isVisible={pmsDataLoading} />
    </Flex>
  );
};

AdminPMSInstanceFormContainer.propTypes = {
  pmsData: pmsDataPropTypes.isRequired,
  apiError: PropTypes.bool,
  pmsDataLoading: PropTypes.bool
};

const mapState = state => ({
  ...state.network,
  ...state.connections
});

export default withRouter(
  connect(mapState)(withLogin(AdminPMSInstanceFormContainer))
);
