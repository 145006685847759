import { differenceInCalendarDays } from "../../utils/dates";

export default formItems => ({
  fields: {
    start_date: {
      validators: {
        isRequired: {
          message: "This field is required."
        },
        customValidation: {
          function: startDate => {
            const dateDifference = differenceInCalendarDays(
              formItems.end_date,
              startDate
            );
            if (
              startDate &&
              formItems.end_date &&
              (dateDifference > 18000 || dateDifference < 0)
            ) {
              return "Maximum date difference is 18000 days.";
            }
            return null;
          }
        }
      }
    },

    end_date: {
      validators: {
        isRequired: {
          message: "This field is required."
        }
      }
    }
  }
});
