import React from "react";
import PropTypes from "prop-types";
import _get from "lodash/get";
import { Button, TextInput } from "concrete-ui";

import Breadcrumbs from "../../components/breadcrumbs";
import Panel from "../../components/panel";
import TabNavigator, { Tab } from "../../components/tab_navigator";
import BoxRow from "../../components/box_row";
import FormField from "../../components/form_field";
import Select from "../../components/select";
import ButtonToggle, {
  STATE_ENUM as TOGGLE_STATE
} from "../../components/button_toggle";
import SelectTextarea from "../../components/select_textarea";
import { AlertsCrumb } from "../alerts_crumb";
import { alertConfig } from "./validators";
import "./create_alert.scss";
import Loader from "../loader";
import RenderIf from "../render_if";
import checkFlag from "../../utils/posthog";
import { validateFields } from "../../utils/validator_helpers";

// This is the shape of what's needed from the frontend to create an alert with the alerts engine:
// alertCriteria: PropTypes.objectOf(
//   PropTypes.shape({
//     alert_scope: PropTypes.string.isRequired,
//     alert_subject: PropTypes.string.isRequired,
//     alert_type: PropTypes.string.isRequired,
//     comparator: PropTypes.string.isRequired,
//     kpi: PropTypes.string.isRequired,
//     subscribers: PropTypes.arrayOf(PropTypes.string),
//     time_span: PropTypes.string.isRequired,
//     title: PropTypes.string,
//     trigger_value: PropTypes.number.isRequired,
//     subscription_type: PropTypes.string.isRequired
//   })
// )

class CreateAlert extends React.PureComponent {
  static propTypes = {
    properties: PropTypes.objectOf(
      PropTypes.shape({
        property_name: PropTypes.string.isRequired,
        property_id: PropTypes.string.isRequired,
        ext_id: PropTypes.string,
        members: PropTypes.object
      })
    ),
    propertyGroups: PropTypes.objectOf(
      PropTypes.shape({
        group_name: PropTypes.string.isRequired,
        group_id: PropTypes.string.isRequired
      })
    ),
    kpiGroupOptions: Select.optionsType,
    kpiPropertiesOptions: Select.optionsType,
    isUsersLoading: PropTypes.bool,
    isPropsGroupsLoading: PropTypes.bool,
    getMember: PropTypes.func,
    user: PropTypes.object.isRequired,
    userPropsGroups: PropTypes.object,
    createAlertHandler: PropTypes.func,
    isFetching: PropTypes.bool
  };

  static defaultProps = {
    properties: {},
    propertyGroups: {},
    kpiGroupOptions: [],
    kpiPropertiesOptions: [],
    isUsersLoading: false,
    isPropsGroupsLoading: false,
    getMember: () => {},
    user: {},
    userPropsGroups: {},
    createAlertHandler: () => {},
    isFetching: false
  };

  constructor(props) {
    super(props);
    const timeRangeValues = this.timeRangeOptions.map(o => o.value);
    const anomalyTimeRangeValues = this.anomalyTimeRangeOptions.map(
      o => o.value
    );
    const thresholdValues = this.thresholdOptions.map(o => o.value);
    this.alertConfig = alertConfig(
      timeRangeValues,
      anomalyTimeRangeValues,
      thresholdValues
    );
  }

  state = {
    propertyTabIndex: 0,
    alertTabIndex: 0,
    receiveAlerts: TOGGLE_STATE.CHECKED,
    users: [],
    propertyGroup: null,
    propertyGroupKPI: null,
    property: null,
    propertyKPI: null,
    timeRange: null,
    threshold: null,
    thresholdValue: "",
    thresholdValue2: "",
    isDisabledTimeRange: false,
    alertCriteria: {
      alert_scope: "property",
      alert_subject: null,
      alert_type: "threshold",
      comparator: null,
      kpi: null,
      subscribers: [],
      time_span: "month",
      title: null,
      trigger_value: null,
      subscription_type: "both"
    }
  };

  breadcrumbs = [
    {
      text: <AlertsCrumb />,
      link: "/alerts"
    },
    {
      text: "Create Alert"
    }
  ];

  timeRangeOptions = [
    { value: "day", label: "Day" },
    { value: "week", label: "Week" },
    { value: "month", label: "Month" }
  ];

  normalTimeRangeOptions = this.timeRangeOptions;

  anomalyTimeRangeOptions = [
    { value: "week", label: "Week" },
    { value: "month", label: "Month" }
  ];

  thresholdOptions = [
    { value: "<", label: "Smaller than" },
    { value: ">", label: "Greater than" },
    { value: "<=", label: "Equal or smaller than" },
    { value: ">=", label: "Equal or greater than" },
    { value: "=", label: "Equal to" },
    { value: "!=", label: "Not equal to" }
    // TODO: Pending implementation to support "between" alerts
    // { value: "between", label: "Between" }
  ];

  get data() {
    const data = {
      alertTabIndex: this.state.alertTabIndex,
      propertyTabIndex: this.state.propertyTabIndex,
      propertyGroup: this.state.propertyGroup?.value,
      propertyGroupKPI: this.state.propertyGroupKPI?.value,
      property: this.state.property?.value,
      propertyKPI: this.state.propertyKPI?.value,
      timeRange: this.state.timeRange?.value,
      threshold: this.state.threshold?.value,
      thresholdValue: this.state.thresholdValue,
      thresholdValue2: this.state.thresholdValue2,
      receiveAlerts: this.state.receiveAlerts,
      users: this.state.users
    };
    if (this.state.propertyTabIndex === 0) {
      delete data["property"];
      delete data["propertyKPI"];
    } else {
      delete data["propertyGroup"];
      delete data["propertyGroupKPI"];
    }
    if (this.state.alertTabIndex === 1) {
      delete data["threshold"];
      delete data["thresholdValue"];
      delete data["thresholdValue2"];
    }
    if (this.state.threshold?.value !== "between") {
      delete data["thresholdValue2"];
    }
    return data;
  }

  get dataForRequest() {
    const data = this.data;
    const alertData = {};
    const criteria = {};

    if ("property" in data) {
      alertData["type"] = "property";
      alertData["propertyName"] = this.state.property.label;
      alertData["propertyId"] = this.state.property.value;
      alertData["kpi"] = this.state.propertyKPI.value;
      alertData["kpiLabel"] = this.state.propertyKPI.label;
      criteria.kpi = this.state.propertyKPI.value;
      criteria.alert_scope = "property";
      criteria.alert_subject = this.state.property.value;
    } else {
      alertData["type"] = "group";
      alertData["propertyName"] = this.state.propertyGroup.label;
      alertData["propertiesIds"] = this.state.propertyGroup.properties;
      alertData["kpi"] = this.state.propertyGroupKPI.value;
      alertData["kpiLabel"] = this.state.propertyGroupKPI.label;
      criteria.kpi = this.state.propertyGroupKPI.value;
      criteria.alert_scope = "group";
      criteria.alert_subject = this.state.propertyGroup.value;
    }
    criteria.alert_type = "threshold";
    criteria.comparator = data["threshold"];
    criteria.time_span = data["timeRange"];
    criteria.subscribers = this.state.users.map(u => u.value);
    criteria.title = "";
    criteria.trigger_value = data["thresholdValue"];
    criteria.subscription_type = "both";
    if (!data["receiveAlerts"]) {
      criteria.subscription_type = "web";
    }
    alertData["timeRange"] = data["timeRange"];
    if ("threshold" in data) {
      alertData["threshold"] = data["threshold"];
      alertData["thresholdValue"] = data["thresholdValue"];
      if (data["threshold"] === "between") {
        alertData["thresholdValue2"] = data["thresholdValue2"];
      }
    }
    alertData["actionEmail"] = data["receiveAlerts"];
    alertData["users"] = this.state.users.map(u => u.value);
    return criteria;
  }

  get isDisabledSubmit() {
    const data = this.data;
    const errors = validateFields(data, this.alertConfig(data));
    return Object.keys(errors).length;
  }

  get propertiesOptions() {
    return Object.keys(this.props.properties)
      .map(pId => {
        const p = this.props.properties[pId];
        return {
          label: p.property_name,
          value: p.ext_id
        };
      })
      .sort((a, b) => a.label.localeCompare(b.label));
  }

  get propertyGroupsOptions() {
    return Object.keys(this.props.propertyGroups)
      .map(gId => {
        const g = this.props.propertyGroups[gId];
        return {
          label: g.group_name,
          value: g.group_id,
          properties: g.properties
        };
      })
      .sort((a, b) => a.label.localeCompare(b.label));
  }

  get usersOptions() {
    let members;
    if (this.state.propertyTabIndex === 0) {
      const pGroupId = this.state.propertyGroup?.value;
      members = _get(
        this.props.userPropsGroups,
        ["propertyGroups", pGroupId],
        {}
      );
    } else {
      const propertyId = this.state.property?.value;
      members = _get(
        this.props.userPropsGroups,
        ["properties", propertyId],
        {}
      );
    }

    const uOptions = Object.values(members).map(member => {
      let accountName = "";
      if (member["firstName"] && member["lastName"]) {
        accountName = `${member["firstName"]} ${member["lastName"]}`;
      }
      return {
        value: member["ext_id"],
        label: member["email"],
        account_name: accountName,
        email: member["email"]
      };
    });

    return uOptions;
  }

  getTimeRangeState = kpi => {
    let kpiName;
    let isDisabledTimeRange = false;
    let timeRange;
    if (kpi) {
      kpiName = kpi;
    } else if (this.state.propertyTabIndex === 0) {
      kpiName = this.state.propertyKPI?.value;
    } else {
      kpiName = this.state.propertyGroupKPI?.value;
    }
    let kpi_without_day_option = [
      "lease_cds",
      "lead_new_lease",
      "nwv_leads",
      "leads_tou",
      "tou_app",
      "app_exe",
      "exe_ins",
      "app_approved",
      "approved_exe",
      "inq_exe",
      "lease_cd_rate"
    ];
    if (kpi_without_day_option.includes(kpiName)) {
      this.timeRangeOptions = this.anomalyTimeRangeOptions;
    } else {
      this.timeRangeOptions = this.normalTimeRangeOptions;
    }
    if (isDisabledTimeRange) {
      timeRange = this.timeRangeOptions[0];
    } else if (kpi_without_day_option.includes(kpiName)) {
      timeRange = null;
    } else {
      timeRange = this.state.timeRange;
    }
    if (
      this.state.alertTabIndex === 1 &&
      timeRange === this.timeRangeOptions[0]
    ) {
      timeRange = this.anomalyTimeRangeOptions[0];
    }
    return { isDisabledTimeRange, timeRange };
  };

  onChangePropertyTab = propertyTabIndex => {
    const { isDisabledTimeRange, timeRange } = this.getTimeRangeState();
    this.setState({
      propertyTabIndex,
      isDisabledTimeRange,
      timeRange,
      users: []
    });
  };

  onChangeAlertTab = tabIndex => {
    let timeRange;
    if (tabIndex === 1) {
      timeRange = this.anomalyTimeRangeOptions[0];
    } else {
      timeRange = this.timeRangeOptions[0];
    }
    this.setState({
      alertTabIndex: tabIndex,
      timeRange
    });
  };

  onChangeReceiveAlerts = receiveAlerts => {
    this.setState({ receiveAlerts });
  };

  onChangePropertyGroup = propertyGroup => {
    this.setState({ propertyGroup, users: [] });
  };

  onChangePropertyGroupKPI = propertyGroupKPI => {
    const { isDisabledTimeRange, timeRange } = this.getTimeRangeState(
      propertyGroupKPI.value
    );
    this.setState({ propertyGroupKPI, isDisabledTimeRange, timeRange });
  };

  onChangeProperty = property => {
    this.setState({ property, users: [] });
  };

  onChangePropertyKPI = propertyKPI => {
    const { isDisabledTimeRange, timeRange } = this.getTimeRangeState(
      propertyKPI.value
    );
    this.setState({ propertyKPI, isDisabledTimeRange, timeRange });
  };

  onChangeThreshold = threshold => {
    let thresholdValue2 = this.state.thresholdValue2;
    if (threshold !== "between") {
      thresholdValue2 = "";
    }
    this.setState({ threshold, thresholdValue2 });
  };

  onChangeTimeRange = timeRange => {
    this.setState({ timeRange });
  };

  onChangeThresholdValue = e => {
    let value = e.target.value;
    if (value) {
      value = Number(value);
    }
    const name = e.target.name;
    this.setState({ [name]: value });
  };

  selectUsers = users => {
    this.setState({
      users: users || []
    });
  };

  createAlert = () => {
    if (!this.isDisabledSubmit) {
      const data = this.dataForRequest;
      this.props.createAlertHandler(data);
    }
  };

  get ThresholdFields() {
    return (
      <>
        <FormField
          label="You will be notified when the value is:"
          theme="modern"
        >
          <BoxRow spacing="small">
            <Select
              size="middle"
              options={this.thresholdOptions}
              value={this.state.threshold}
              onChange={this.onChangeThreshold}
            />
            <div className="create-alert__input-container">
              <TextInput
                size="large"
                type="number"
                name="thresholdValue"
                placeholder="e.g. 150, 0.85 (%)"
                value={this.state.thresholdValue}
                onChange={this.onChangeThresholdValue}
              />
              {this.state.threshold?.value === "between" && (
                <>
                  <div className="create-alert__input-separator">to</div>
                  <TextInput
                    size="large"
                    type="number"
                    name="thresholdValue2"
                    placeholder="e.g. 150, 0.85 (%)"
                    value={this.state.thresholdValue2}
                    onChange={this.onChangeThresholdValue}
                  />
                </>
              )}
            </div>
          </BoxRow>
        </FormField>
        <div className="create-alert__indent" />
        <FormField
          label="For any of the following time periods:"
          theme="modern"
        >
          <Select
            size="middle"
            options={this.timeRangeOptions}
            value={this.state.timeRange}
            isDisabled={this.state.isDisabledTimeRange}
            onChange={this.onChangeTimeRange}
          />
        </FormField>
      </>
    );
  }

  render() {
    return (
      <div className="create-alert">
        <Loader isVisible={this.props.isFetching} />
        <Breadcrumbs breadcrumbs={this.breadcrumbs} />
        <div className="create-alert__container">
          <Panel className="create-alert__panel">
            <div className="create-alert__title">Create Alert</div>
            <div className="create-alert__section">
              <div className="create-alert__step">1</div>
              <div className="create-alert__description">
                <div className="create-alert__section-title">Choose KPI</div>
                <div className="create-alert__description-text">
                  Choose a KPI from either a Portfolio or Property to be alerted
                  alerted from.
                </div>
              </div>
              <div className="create-alert__form">
                <TabNavigator
                  selectedIndex={this.state.propertyTabIndex}
                  theme="small"
                  onChange={this.onChangePropertyTab}
                >
                  <Tab label="Portfolio">
                    <BoxRow spacing="small">
                      <FormField label="Portfolio" theme="modern">
                        <Select
                          size="middle"
                          options={this.propertyGroupsOptions}
                          value={this.state.propertyGroup}
                          onChange={this.onChangePropertyGroup}
                        />
                      </FormField>
                      <FormField label="KPI" theme="modern">
                        <Select
                          size="middle"
                          options={this.props.kpiGroupOptions}
                          value={this.state.propertyGroupKPI}
                          onChange={this.onChangePropertyGroupKPI}
                        />
                      </FormField>
                    </BoxRow>
                  </Tab>
                  <Tab label="Property">
                    <BoxRow spacing="small">
                      <FormField label="Property" theme="modern">
                        <Select
                          size="middle"
                          options={this.propertiesOptions}
                          value={this.state.property}
                          onChange={this.onChangeProperty}
                        />
                      </FormField>
                      <FormField label="KPI" theme="modern">
                        <Select
                          size="middle"
                          options={this.props.kpiPropertiesOptions}
                          value={this.state.propertyKPI}
                          onChange={this.onChangePropertyKPI}
                        />
                      </FormField>
                    </BoxRow>
                  </Tab>
                </TabNavigator>
              </div>
            </div>
            <div className="create-alert__section strict-height">
              <div className="create-alert__step">2</div>
              <div className="create-alert__description">
                <div className="create-alert__section-title">
                  Set Alert Conditions
                </div>
                <div className="create-alert__description-text">
                  Set your alert conditions to trigger based on specific rules.
                </div>
              </div>
              <div className="create-alert__form">
                <RenderIf condition={checkFlag("alerts-anomaly")}>
                  <TabNavigator
                    selectedIndex={this.state.alertTabIndex}
                    theme="small"
                    onChange={this.onChangeAlertTab}
                  >
                    <Tab label="Threshold">{this.ThresholdFields}</Tab>
                    <Tab label="Anomaly">
                      <FormField
                        label="For any of the following time periods:"
                        theme="modern"
                      >
                        <Select
                          size="middle"
                          options={this.anomalyTimeRangeOptions}
                          value={this.state.timeRange}
                          isDisabled={this.state.isDisabledTimeRange}
                          onChange={this.onChangeTimeRange}
                        />
                      </FormField>
                    </Tab>
                  </TabNavigator>
                </RenderIf>
                <RenderIf condition={!checkFlag("alerts-anomaly")}>
                  {this.ThresholdFields}
                </RenderIf>
              </div>
            </div>
            <div className="create-alert__section">
              <div className="create-alert__step">3</div>
              <div className="create-alert__description">
                <div className="create-alert__section-title">
                  Manage Alert Delivery
                </div>
                <div className="create-alert__description-text">
                  Choose how to recieve the alert and anyone else that should as
                  well.
                </div>
              </div>
              <div className="create-alert__form">
                <div className="create-alert__field">
                  <div>
                    <div className="create-alert__field-label">
                      Receive alerts via email?
                    </div>
                    <div className="create-alert__field-description">
                      ({this.props.user.email})
                    </div>
                  </div>
                  <ButtonToggle
                    theme="modern"
                    checked={this.state.receiveAlerts}
                    onChange={this.onChangeReceiveAlerts}
                  />
                </div>
                <FormField
                  label="Add other Remarkably users to this alert? (optional)"
                  theme="modern"
                >
                  <SelectTextarea
                    isDisabled={this.props.isPropsGroupsLoading}
                    isLoading={this.props.isPropsGroupsLoading}
                    options={this.usersOptions}
                    value={this.state.users}
                    openMenuOnClick={false}
                    onChange={this.selectUsers}
                  />
                </FormField>
                <div className="create-alert__field-description">
                  Additional alert recipients must have existing Remarkably
                  accounts.
                </div>
              </div>
            </div>
            <Button
              variant="primary"
              size="large"
              mr="6"
              my="6"
              ml="auto"
              display="block"
              disabled={this.isDisabledSubmit}
              onClick={this.createAlert}
            >
              Create Alert
            </Button>
          </Panel>
        </div>
      </div>
    );
  }
}

export default CreateAlert;
