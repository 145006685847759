import "core-js/shim"; // core-js@2
import "regenerator-runtime/runtime";
import "url-search-params-polyfill";

import React from "react";
import ReactDOM from "react-dom";
/**
 * Import our master CSS to force our bundler to build it
 */
import "css/main.scss";

/*
 * Import the root application here.
 */
import App from "./App.js";

/**
 * @description Render our application at the document's "root"
 */
const renderApp = () => {
  const root = document.querySelector("#root");
  const app = React.createElement(App, {});

  ReactDOM.render(app, root);
};

/**
 * @description Fires callback exactly once, after the document is loaded.
 */
const ready = cb => {
  if (document.readyState != "loading") {
    cb();
    return;
  }

  const handleContentLoaded = () => {
    cb();
    document.removeEventListener("DOMContentLoaded", handleContentLoaded);
  };

  document.addEventListener("DOMContentLoaded", handleContentLoaded);
};

/* Run our page. */
ready(() => {
  renderApp();
});
