import React from "react";

import IconBase from "./icon_base";

const Grid = props => (
  <IconBase viewBox="0 0 16 16" fill="none" {...props}>
    <path
      d="M13.3333 1.33334H2.66666C1.93333 1.33334 1.33333 1.93334 1.33333 2.66668V13.3333C1.33333 14.0667 1.93333 14.6667 2.66666 14.6667H13.3333C14.0667 14.6667 14.6667 14.0667 14.6667 13.3333V2.66668C14.6667 1.93334 14.0667 1.33334 13.3333 1.33334ZM5.33333 13.3333H2.66666V10.6667H5.33333V13.3333ZM5.33333 9.33334H2.66666V6.66668H5.33333V9.33334ZM5.33333 5.33334H2.66666V2.66668H5.33333V5.33334ZM9.33333 13.3333H6.66666V10.6667H9.33333V13.3333ZM9.33333 9.33334H6.66666V6.66668H9.33333V9.33334ZM9.33333 5.33334H6.66666V2.66668H9.33333V5.33334ZM13.3333 13.3333H10.6667V10.6667H13.3333V13.3333ZM13.3333 9.33334H10.6667V6.66668H13.3333V9.33334ZM13.3333 5.33334H10.6667V2.66668H13.3333V5.33334Z"
      fill="currentColor"
    />
  </IconBase>
);

export default Grid;
