import React from "react";

import EnhancedLink from "../enhanced_link";
import "./remarkably_logo.scss";

const RemarkablyLogo = () => {
  return (
    <EnhancedLink
      style={{ color: "inherit", textDecoration: "inherit" }}
      to="/"
    >
      <div className="remarkably-logo" />
    </EnhancedLink>
  );
};

export default React.memo(RemarkablyLogo);
