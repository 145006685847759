import React from "react";
import PropTypes from "prop-types";
import { Box, Button, Flex, Select, Text, TextInput } from "concrete-ui";
import _intersection from "lodash/intersection";

import useValidation from "../../utils/validator_hook";
import Breadcrumbs from "../breadcrumbs";
import {
  CreateForm,
  CreateFormHeader,
  CreateFormSection
} from "../create_form";
import { ErrorItem } from "../message_items";
import { adminPropertiesConfig } from "./validators";
import AdminBreadcrumb from "../admin_breadcrumb";
import {
  concreteErrorVariant,
  PROPERTY_ATTRIBUTES_AVAILABLE_COUNTRIES
} from "../../constants";
import GoogleAutocomplete from "../google_autocomplete";

const ErrorMessage = ({ apiError, formError }) => {
  if (formError) {
    return (
      <ErrorItem message="Please correct the errors above before continuing." />
    );
  } else if (apiError) {
    return (
      <ErrorItem message="Oops, something went wrong. Please contact Support." />
    );
  }
  return null;
};

const AdminPropertiesForm = ({
  onCancel = v => v,
  onConfirm = v => v,
  initialData = {},
  apiError = false
}) => {
  const defaultData = {
    name: "",
    address_1: "",
    address_2: "",
    country: "",
    state: "",
    city: "",
    zip: "",
    billing_email: ""
  };
  const {
    errors,
    getFormProps,
    getItemProps,
    setFormItems,
    formItems
  } = useValidation(
    adminPropertiesConfig,
    {
      ...defaultData,
      ...initialData
    },
    onConfirm
  );

  const onPlaceSelected = place => {
    let street_number = "",
      address1 = "",
      city = "",
      state = "",
      postalCode = "";

    for (const component of place.address_components) {
      const componentType = component.types[0];

      if (componentType === "street_number") {
        street_number = component.short_name;
      }
      if (componentType === "route") {
        address1 = component.short_name;
      }
      if (componentType === "sublocality_level_1" && city === "") {
        city = component.short_name;
      }
      if (componentType === "locality") {
        city = component.short_name;
      }
      if (componentType === "administrative_area_level_1") {
        state = component.short_name;
      }
      if (componentType === "postal_code") {
        postalCode = component.short_name;
      }
    }

    setFormItems({
      address_1: `${street_number} ${address1}`,
      state: state,
      city: city,
      zip: postalCode
    });
  };

  const countryProps = getItemProps("country");

  const breadcrumbs = [
    { text: <AdminBreadcrumb />, link: "" },
    { text: "Properties", link: "/admin-properties" },
    { text: "Add Required Property Information", link: "" }
  ];

  const fieldsContainerProps = { width: "31rem" };
  return (
    <>
      <Box bg="gray.1" width="100%" display="block">
        <Breadcrumbs breadcrumbs={breadcrumbs} />
      </Box>
      <Box width="62.5rem" mx="auto" mb="12rem">
        <CreateForm formProps={getFormProps()}>
          <CreateFormHeader>
            <Text textSize={4} $color="text.white.1" $fontWeight="medium">
              Add Required Property Information
            </Text>
          </CreateFormHeader>
          <CreateFormSection
            number="1"
            titleText="Add Property Name"
            titleSummary="Add the full name of the property, exactly how you want it to appear."
            fieldsContainerProps={fieldsContainerProps}
          >
            <TextInput
              size="large"
              label="Property Name"
              {...getItemProps("name")}
              variant={errors.name && concreteErrorVariant}
              message={errors.name}
              flexBasis="80%"
            />
          </CreateFormSection>
          <CreateFormSection
            number="2"
            titleText="Add Property Address"
            titleSummary="Add the address of the property located in the United States or Canada."
            fieldsContainerProps={fieldsContainerProps}
          >
            <Flex flexWrap="wrap">
              <Select
                size="large"
                label="Country"
                items={PROPERTY_ATTRIBUTES_AVAILABLE_COUNTRIES}
                {...countryProps}
                onSelect={countryProps.onChange}
                error={!!errors.country}
                errorMessage={errors.country}
                flexBasis="50%"
              />
              <GoogleAutocomplete
                size="large"
                label="Street Address (Line 1)"
                {...getItemProps("address_1")}
                variant={errors.address_1 && concreteErrorVariant}
                message={errors.address_1}
                flexBasis="100%"
                countries={formItems.country ? [formItems.country] : []}
                onPlaceSelected={onPlaceSelected}
                mt={3}
              />
              <TextInput
                size="large"
                label="Street Address (Line 2)"
                {...getItemProps("address_2")}
                variant={errors.address_2 && concreteErrorVariant}
                message={errors.address_2}
                flexBasis="100%"
                mt={3}
              />
              <Flex flexBasis="100%" mt={3}>
                <TextInput
                  size="large"
                  label="City"
                  {...getItemProps("city")}
                  variant={errors.city && concreteErrorVariant}
                  message={errors.city}
                  flexBasis="40%"
                  mr={4}
                />
                <TextInput
                  size="large"
                  label="State or Province"
                  {...getItemProps("state")}
                  variant={errors.state && concreteErrorVariant}
                  message={errors.state}
                  flexBasis="35%"
                  mr={4}
                />
                <TextInput
                  size="large"
                  label="Zip or Postal Code"
                  {...getItemProps("zip")}
                  variant={errors.zip && concreteErrorVariant}
                  message={errors.zip}
                  width="auto"
                  flexGrow="1"
                />
              </Flex>
            </Flex>
          </CreateFormSection>
          <CreateFormSection
            number="3"
            titleText="Add Billing Contact"
            titleSummary="Add the email address to send monthly invoices to, if it’s blank."
            fieldsContainerProps={{
              ...fieldsContainerProps,
              flexDirection: "column"
            }}
          >
            <Flex>
              <TextInput
                size="large"
                label="Billing Email"
                {...getItemProps("billing_email")}
                variant={errors.billing_email && concreteErrorVariant}
                message={errors.billing_email}
                flexBasis="80%"
              />
            </Flex>
            <Flex mt={6} alignItems="center" justifyContent="flex-end">
              <ErrorMessage
                apiError={apiError}
                isFormErrors={Object.keys(errors).length > 0}
              />
              <Button
                variant="secondary"
                size="large"
                type="button"
                mr={3}
                onClick={onCancel}
                style={{ flex: "0 0 auto" }}
              >
                Cancel
              </Button>
              <Button
                size="large"
                variant="primary"
                type="submit"
                style={{ flex: "0 0 auto" }}
              >
                Save
              </Button>
            </Flex>
          </CreateFormSection>
        </CreateForm>
      </Box>
    </>
  );
};

AdminPropertiesForm.propTypes = {
  initialData: PropTypes.object,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  apiError: PropTypes.bool
};

export default AdminPropertiesForm;
