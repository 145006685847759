import { URLS, createAPIUrl } from "../actions/helpers";
import { axiosPost } from "../../utils/api";
import { connectionsActions } from "../actions";

export const addOrUpdateDataConnection = store => next => async action => {
  if (action.type === "DATA_CONNECTION") {
    const { data, history } = action;
    try {
      const postURL = createAPIUrl(`${URLS.connections}/data-connections/`);
      let params = data;
      const response = await axiosPost(postURL, params);
      if (response.status === 200) {
        history.push("/admin-connections/data_connections");
      } else {
        next({
          type: "AJAX_POST_DATA_CONNECTION_ERROR"
        });
      }
    } catch (e) {
      console.log(e);
      next({
        type: "AJAX_POST_DATA_CONNECTION_ERROR"
      });
    }
  }
  next(action);
};

export const afterYardiBackfill = store => next => async action => {
  if (action.type === "AJAX_POST_BACKFILL_YARDI_PMS_SUCCESS") {
    next(connectionsActions.requestDataConnections());
  }
  next(action);
};
