import { createAjaxAction, URLS } from "./helpers";
import { qsStringify } from "../../utils/misc";

const actions = {
  getReferralSourcesMatching: project_id =>
    createAjaxAction(
      "AJAX_GET_REFERRAL_SOURCES_MATCHING",
      `${URLS.referral_sources_matching}${project_id}`
    )(),
  clearReferralSourcesMatching: () => ({
    type: "REFERRAL_SOURCES_CLEAR_MATCHING"
  }),
  getReferralSourcesNwvOptions: (
    project_id,
    lead_name,
    tab_view,
    filters = {}
  ) => {
    let cleaned_lead_name = lead_name.replace(/\s+/g, "_").replace(/\//g, "_");
    return createAjaxAction(
      "AJAX_GET_REFERRAL_SOURCES_NWV",
      `${URLS.proxy}/referral-sources/nwvs/${project_id}/${cleaned_lead_name}/${tab_view}`,
      qs => qs && qsStringify(qs, true, "comma")
    )(filters);
  }
};

export default actions;
