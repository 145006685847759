import React from "react";
import PropTypes from "prop-types";
import Container from "../container";
import DateRangeSelector from "../date_range_selector";
import DropdownFilters from "../dropdown_filters";
import { Tag } from "../tag";
import RenderIf from "../render_if";

export class Filters extends React.PureComponent {
  static propTypes = {
    date_selection: PropTypes.shape({
      preset: PropTypes.string.isRequired,
      start_date: PropTypes.instanceOf(Date).isRequired,
      end_date: PropTypes.instanceOf(Date).isRequired
    }),
    owners: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        public_id: PropTypes.string.isRequired
      })
    ),
    cities: PropTypes.array,
    states: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired
      })
    ),
    tags: PropTypes.array,
    lease_stages: PropTypes.array,
    classes: PropTypes.array,
    categories: PropTypes.array,
    styles: PropTypes.array,
    propertyCount: PropTypes.number,
    showPropertyCount: PropTypes.bool,
    onChangeDateRange: PropTypes.func,
    onApplyFilterHandler: PropTypes.func,
    filters: PropTypes.shape({
      selectedCategories: PropTypes.array,
      selectedCities: PropTypes.array,
      selectedClasses: PropTypes.array,
      selectedLeaseStages: PropTypes.array,
      selectedOwners: PropTypes.array,
      selectedStates: PropTypes.array,
      selectedStyles: PropTypes.array,
      selectedTags: PropTypes.array
    })
  };

  static defaultProps = {
    propertyCount: 0,
    showPropertyCount: true
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.selected_property_group?.group_id !==
      prevProps.selected_property_group?.group_id
    ) {
      this.dropdownFilters.clearHandler();
    }
  }

  get ownersOptions() {
    const owners = this.props.owners || [];
    return owners.map(m => ({
      label: m.name,
      value: m.public_id
    }));
  }

  get citiesOptions() {
    const cities = this.props.cities || [];
    return cities
      .map(c => ({
        label: c.name,
        value: c.id
      }))
      .sort((a, b) => (a.label > b.label ? 1 : b.label > a.label ? -1 : 0));
  }

  get statesOptions() {
    const states = this.props.states || [];
    return states
      .map(c => ({
        label: c.name,
        value: c.id
      }))
      .sort((a, b) => (a.label > b.label ? 1 : b.label > a.label ? -1 : 0));
  }

  get leaseStagesOptions() {
    const leaseStages = this.props.lease_stages || [];
    return leaseStages.map(c => ({
      label: c.full_name,
      value: c.short_name
    }));
  }

  get buildingClassOptions() {
    const buildingClasses = this.props.classes || [];
    return buildingClasses.map(c => ({
      label: c.name,
      value: c.id
    }));
  }

  get buildingCategoryOptions() {
    const buildingCategories = this.props.categories || [];
    return buildingCategories.map(c => ({
      label: c.name.replace(/(^\w)|([-\s]\w)/g, m => m.toUpperCase()),
      value: c.id
    }));
  }

  get stylesOptions() {
    const styles = this.props.styles || [];
    return styles.map(c => ({
      label: c.name.replace(/(^\w)|([-\s]\w)/g, m => m.toUpperCase()),
      value: c.id
    }));
  }

  get tagsOptions() {
    const tags = this.props.tags || [];
    return tags.map(t => ({ label: t.tag_name, value: t.tag_id }));
  }

  get dropdownFilterCategories() {
    const categories = [
      {
        id: "selectedCities",
        title: "City",
        options: this.citiesOptions
      },
      {
        id: "selectedStates",
        title: "State or Province",
        options: this.statesOptions
      },
      {
        id: "selectedLeaseStages",
        title: "Stage",
        options: this.leaseStagesOptions
      },
      {
        id: "selectedClasses",
        title: "Class",
        options: this.buildingClassOptions
      },
      {
        id: "selectedCategories",
        title: "Category",
        options: this.buildingCategoryOptions
      },
      {
        id: "selectedStyles",
        title: "Style",
        options: this.stylesOptions
      },
      {
        id: "selectedOwners",
        title: "Owner",
        options: this.ownersOptions
      },
      {
        id: "selectedTags",
        title: "Custom Tags",
        options: this.tagsOptions
      }
    ];

    return categories;
  }

  getTags = () => {
    const tags = [];
    this.dropdownFilterCategories.forEach(tag => {
      const selected = [];
      this.props.filters[tag.id]?.forEach(value => {
        const selectedOption = tag.options.find(o => o.value === value);
        if (selectedOption) {
          selected.push(selectedOption);
        }
      });
      selected.forEach(option =>
        tags.push(
          <Tag
            key={`tag_${tag.id}_${option.label}`}
            tagId={{ type: tag.id, value: option.value }}
            name={
              <>
                <span className="property-group__tag-name">{tag.title}: </span>
                <span className="property-group__tag-value">
                  {option.label}
                </span>
              </>
            }
            theme="small"
            isAdmin={true}
            onRemove={this.onRemove}
          />
        )
      );
    });
    return tags;
  };

  onRemove = ({ type, value }) => {
    this.dropdownFilters.onSelection(type, { value });
    this.dropdownFilters.onApplyHandler();
  };

  get initialFilters() {
    const result = {};
    Object.keys(this.props.filters).forEach(key => {
      result[key] = this.props.filters[key].map(item => ({ value: item }));
    });
    return result;
  }

  onApplyFilter = filters => {
    const result = {};
    Object.keys(filters).forEach(key => {
      result[key] = filters[key].map(item => item.value);
    });
    this.props.onApplyFilterHandler(result);
  };

  dropdownFiltersRef = dropdownFilters => {
    this.dropdownFilters = dropdownFilters;
  };

  render() {
    const {
      date_selection,
      onChangeDateRange,
      propertyCount,
      showPropertyCount
    } = this.props;
    const tags = this.getTags();
    return (
      <div className="property-group__filters">
        <div className="property-group__wrapper">
          <Container>
            <div className="property-group__control-row">
              <div className="property-group__controls" />
              <div className="property-group__controls">
                <DropdownFilters
                  size="large"
                  ref={this.dropdownFiltersRef}
                  onApply={this.onApplyFilter}
                  sections={this.dropdownFilterCategories}
                  initialFilters={this.initialFilters}
                />
                <div className="property-group__vertical-split" />
                <DateRangeSelector
                  startDate={date_selection.start_date}
                  endDate={date_selection.end_date}
                  presetValue={date_selection.preset}
                  onChange={onChangeDateRange}
                />
              </div>
            </div>
          </Container>
          <div className="property-group__separator" />
        </div>
        <RenderIf condition={!!tags.length}>
          <Container>
            <RenderIf condition={showPropertyCount}>
              <div className="property-group__filter-title">
                {propertyCount}{" "}
                {propertyCount === 1 ? "Property" : "Properties"} in:
              </div>
            </RenderIf>
            <div className="property-group__filter-tags">{tags}</div>
          </Container>
        </RenderIf>
      </div>
    );
  }
}
