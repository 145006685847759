import React from "react";
import { connect } from "react-redux";

import AuthContainer from "../../containers/auth";
import { callIfFunction } from "../../utils/misc";

class AuthWrapper extends React.PureComponent {
  render() {
    if (this.props.token && this.props.token.access) {
      const { children } = this.props;
      return callIfFunction(children);
    }
    return <AuthContainer />;
  }
}

const mapState = ({ token }) => ({ token });

export default connect(mapState)(AuthWrapper);
