import _findIndex from "lodash/findIndex";

const initState = {
  pmsData: [],
  pmsDataLoading: false,
  dataConnections: [],
  dataConnectionsLoading: false,
  apiError: false,
  isPostSuccessful: false,
  addNewConnectionError: false,
  connectionDeleteLoading: false
};

const reducer = (state = initState, action) => {
  switch (action.type) {
    case "CONNECTIONS_UPDATE_STATE": {
      return {
        ...state,
        ...action.state
      };
    }
    case "AJAX_GET_PMS_INSTANCES_REQUEST": {
      return {
        ...state,
        pmsDataLoading: true
      };
    }
    case "AJAX_GET_PMS_INSTANCES_SUCCESS": {
      return {
        ...state,
        pmsData: action.payload,
        pmsDataLoading: false
      };
    }
    case "AJAX_GET_PMS_INSTANCES_ERROR": {
      return {
        ...state,
        pmsDataLoading: false
      };
    }
    case "AJAX_PATCH_PMS_REQUEST":
    case "AJAX_POST_PMS_REQUEST": {
      return {
        ...state,
        pmsDataLoading: true,
        apiError: false,
        isPostSuccessful: false
      };
    }
    case "AJAX_PATCH_PMS_SUCCESS":
    case "AJAX_POST_PMS_SUCCESS": {
      return {
        ...state,
        pmsDataLoading: false,
        isPostSuccessful: true
      };
    }
    case "AJAX_PATCH_PMS_ERROR":
    case "AJAX_POST_PMS_ERROR": {
      return {
        ...state,
        pmsDataLoading: false,
        apiError: true
      };
    }
    case "AJAX_GET_DATA_CONNECTIONS_REQUEST": {
      return {
        ...state,
        dataConnectionsLoading: true
      };
    }
    case "AJAX_GET_DATA_CONNECTIONS_SUCCESS": {
      return {
        ...state,
        dataConnections: action.payload,
        dataConnectionsLoading: false,
        apiError: false
      };
    }
    case "AJAX_GET_DATA_CONNECTIONS_ERROR": {
      return {
        ...state,
        dataConnectionsLoading: false,
        apiError: true
      };
    }
    case "AJAX_GET_REFRESH_DATA_CONNECTIONS_SUCCESS": {
      return {
        ...state,
        dataConnections: action.payload,
        apiError: false
      };
    }
    case "AJAX_GET_REFRESH_DATA_CONNECTIONS_ERROR": {
      return {
        ...state,
        apiError: true
      };
    }
    case "AJAX_DELETE_PMS_SUCCESS": {
      const pmsIDs = action.payload;
      const pmsData = state.pmsData.filter(pms => pmsIDs.includes(pms.id));
      return { ...state, pmsData };
    }
    case "AJAX_DELETE_PMS_ERROR": {
      return {
        ...state,
        apiError: true
      };
    }
    case "AJAX_POST_DATA_CONNECTION_SUCCESS": {
      return {
        ...state,
        addNewConnectionError: false,
        dataConnectionsLoading: false
      };
    }
    case "AJAX_POST_DATA_CONNECTION_ERROR": {
      return {
        ...state,
        apiError: true,
        addNewConnectionError: true,
        dataConnectionsLoading: false
      };
    }
    case "AJAX_DELETE_CONNECTION_REQUEST": {
      return {
        ...state,
        connectionDeleteLoading: true
      };
    }
    case "AJAX_DELETE_CONNECTION_SUCCESS": {
      return { ...state, connectionDeleteLoading: false };
    }
    case "AJAX_DELETE_CONNECTION_ERROR": {
      return {
        ...state,
        apiError: true,
        connectionDeleteLoading: false
      };
    }
    case "AJAX_POST_TEST_CONNECTION_SUCCESS": {
      const { status, connection_id, integration_source } = action.payload;
      const dataConnections = [...state.dataConnections];
      const index = _findIndex(dataConnections, {
        id: connection_id,
        integration_source
      });
      const connection = {
        ...dataConnections[index],
        connection_status: status
      };
      dataConnections[index] = connection;
      return { ...state, dataConnections };
    }
    case "AJAX_POST_TEST_CONNECTION_ERROR": {
      return { ...state, apiError: true };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
