import React from "react";
import IconBase from "./icon_base";

const EmailOn = props => (
  <IconBase viewBox="0 0 16 16" {...props}>
    <path
      d="M13.334 2.66699H2.66732C1.93398 2.66699 1.34065 3.26699 1.34065 4.00033L1.33398 12.0003C1.33398 12.7337 1.93398 13.3337 2.66732 13.3337H13.334C14.0673 13.3337 14.6673 12.7337 14.6673 12.0003V4.00033C14.6673 3.26699 14.0673 2.66699 13.334 2.66699ZM13.334 5.33366L8.00065 8.66699L2.66732 5.33366V4.00033L8.00065 7.33366L13.334 4.00033V5.33366Z"
      fill="currentColor"
    />
  </IconBase>
);

export default EmailOn;
