import React, { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { resetPasswordActions } from "../../redux_base/actions";
import ResetPasswordForm from "../../components/reset_password_form";

const ResetPasswordContainer = ({
  dispatch,
  history,
  apiError,
  isPostSuccessful,
  data
}) => {
  useEffect(() => {
    if (isPostSuccessful) {
      dispatch(resetPasswordActions.updateState({ isPostSuccessful: false }));
      history.push("/users/password-resend");
    }
  }, [isPostSuccessful]);

  const onSubmit = data => {
    dispatch(resetPasswordActions.resetPassword(data));
  };

  return (
    <ResetPasswordForm onSubmit={onSubmit} apiError={apiError} data={data} />
  );
};

ResetPasswordContainer.propTypes = {
  apiError: PropTypes.bool,
  isPostSuccessful: PropTypes.bool,
  data: PropTypes.object
};

const mapState = state => ({
  apiError: state.resetPassword.apiError,
  isPostSuccessful: state.resetPassword.isPostSuccessful,
  data: state.resetPassword.payload
});

export default connect(mapState)(ResetPasswordContainer);
