import React from "react";
import PropTypes from "prop-types";
import { Flex, Text } from "concrete-ui";

import RenderIf from "../render_if";
import { Tag } from "../tag";

function FilterTags({ filters, tagTypes, onRemoveTag }) {
  const getTags = () => {
    const tags = [];
    tagTypes.forEach(tag => {
      filters[tag.id]?.forEach(item => {
        tags.push(
          <Tag
            key={`tag-${item.value}`}
            tagId={{ type: tag.id, value: item.value }}
            name={
              <>
                <Text $color="text.gray.1">{tag.title}: </Text>
                <Text $color="text.white.1">{item.label}</Text>
              </>
            }
            theme="small"
            isAdmin={true}
            onRemove={onRemoveTag}
          />
        );
      });
    });
    return tags;
  };

  const tags = getTags();
  return (
    <RenderIf condition={!!tags.length}>
      <Flex flexWrap="wrap">{tags}</Flex>
    </RenderIf>
  );
}

FilterTags.propTypes = {
  filters: PropTypes.object.isRequired,
  onRemoveTag: PropTypes.func.isRequired
};

export default FilterTags;
