import cn from "classnames";
import PropTypes from "prop-types";
import React from "react";

import Button from "./../button";
import "./details_button.scss";

export default class DetailsButton extends React.PureComponent {
  static propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    active: PropTypes.bool,
    disabled: PropTypes.bool,
    onClick: PropTypes.func
  };

  static defaultProps = {
    active: false,
    disabled: false
  };

  render() {
    const classes = cn(
      "details-button",
      { "details-button--active": this.props.active },
      { "details-button--disabled": this.props.disabled },
      this.props.className
    );
    return (
      <Button
        className={classes}
        onClick={this.props.onClick}
        disabled={this.props.disabled}
      >
        {this.props.children}
      </Button>
    );
  }
}
