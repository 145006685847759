export default editing => ({
  fields: {
    name: {
      validators: {
        isRequired: {
          message: "This field is required."
        }
      }
    },
    url: {
      validators: {
        isRequired: {
          message: "This field is required."
        },
        isURL: {
          message: "Please enter a valid website URL, beginning with http(s)://"
        }
      }
    },
    db_name: {
      validators: {
        isRequired: {
          message: "This field is required."
        }
      }
    },
    server_name: {
      validators: {
        isRequired: {
          message: "This field is required."
        }
      }
    },
    platform: {
      validators: {
        isRequired: {
          message: "This field is required."
        }
      }
    },
    username: {
      validators: {
        isRequired: {
          message: "This field is required."
        }
      }
    },
    setup_done: {
      type: "checkbox"
    },
    ...(editing
      ? {}
      : {
          password: {
            validators: {
              isRequired: {
                message: "This field is required."
              }
            }
          }
        })
  }
});
