export const PROPERTY_LEASE_STAGES = [
  {
    value: "lease-up",
    label: "Lease-Up"
  },
  {
    value: "stabilized",
    label: "Stabilized"
  },
  {
    value: "other",
    label: "Other"
  }
];

export const PROPERTY_BUILDING_CLASSES = [
  {
    value: "A",
    label: "Class A"
  },
  {
    value: "B",
    label: "Class B"
  },
  {
    value: "C",
    label: "Class C"
  },
  {
    value: "other",
    label: "Other"
  }
];

export const PROPERTY_TYPES = [
  {
    value: "conventional",
    label: "Conventional"
  },
  {
    value: "affordable",
    label: "Affordable"
  },
  {
    value: "mixed",
    label: "Mixed - Partially Affordable"
  },
  {
    value: "other",
    label: "Other"
  }
];

export const PROPERTY_STYLES = [
  {
    value: "low-rise",
    label: "Low Rise (1-4 Floors)"
  },
  {
    value: "mid-rise",
    label: "Mid Rise (5-9 Floors)"
  },
  {
    value: "high-rise",
    label: "High Rise (10+ Floors)"
  },
  {
    value: "other",
    label: "Other"
  }
];
