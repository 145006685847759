export const rankingsDummyData = {
  top: [
    {
      rank: 1,
      source: "Fantastic Source"
    },
    {
      rank: 2,
      source: "Great Source"
    },
    {
      rank: 3,
      source: "Good Source"
    }
  ],
  bottom: [
    {
      rank: 15,
      source: "Really Terrible Source"
    },
    {
      rank: 14,
      source: "Terrible Source"
    },
    {
      rank: 13,
      source: "Bad Source"
    }
  ],
  exposure_top: [
    {
      rank: 1,
      source: "Great Unit Source"
    },
    {
      rank: 2,
      source: "Good Unit Source"
    },
    {
      rank: 3,
      source: "OK Match Source"
    }
  ]
};

export const rankingsEmptyData = {
  top: [],
  bottom: [],
  exposure_top: []
};

export const exposureRankingsEmptyData = {
  items: [],
  message: null
};

export const exposureRankingsDummyData = {
  items: [
    {
      rank: 1,
      source: "Great Unit Source",
      config: {
        bedrooms: 1,
        bathrooms: 2
      },
      distributed_sources: [
        "Distributed Source 1",
        "Distributed Source 2",
        "Distributed Source 3"
      ]
    },
    {
      rank: 2,
      source: "Good Unit Source",
      config: {
        bedrooms: 2,
        bathrooms: 2
      },
      distributed_sources: []
    },
    {
      rank: 3,
      source: "OK Match Source",
      config: {
        bedrooms: 3,
        bathrooms: 2
      },
      distributed_sources: []
    }
  ],
  message: null
};
