import React from "react";
import IconBase from "./icon_base";

const BellOff = props => (
  <IconBase viewBox="0 0 13 14" {...props}>
    <path
      d="M5.99935 13.6665C6.73268 13.6665 7.33268 13.0665 7.33268 12.3332H4.66602C4.66602 13.0665 5.25935 13.6665 5.99935 13.6665ZM9.99935 6.33317C9.99935 4.2865 8.90602 2.57317 6.99935 2.11984V1.6665C6.99935 1.11317 6.55268 0.666504 5.99935 0.666504C5.44602 0.666504 4.99935 1.11317 4.99935 1.6665V2.11984C4.83935 2.15984 4.68602 2.21984 4.53935 2.27317L9.99935 7.73317V6.33317ZM1.60602 1.23317L0.666016 2.17317L2.53935 4.0465C2.19268 4.71317 1.99935 5.4865 1.99935 6.33317V9.6665L1.13935 10.5265C0.719349 10.9465 1.01268 11.6665 1.60602 11.6665H10.1593L11.3193 12.8265L12.2593 11.8865L1.60602 1.23317Z"
      fill="currentColor"
    />
  </IconBase>
);

export default BellOff;
