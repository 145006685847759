import { Table } from "concrete-ui";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { paginationSettings } from "../../redux_base/actions";

export class PaginationTable extends React.PureComponent {
  static propTypes = {
    data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
    columns: PropTypes.array.isRequired,
    tableType: PropTypes.oneOf(["sub_portfolio", "kpi"]).isRequired,
    paginationSize: PropTypes.number,
    csvFileName: PropTypes.string,
    loadingState: PropTypes.bool
  };

  static defaultProps = {
    csvFileName: undefined
  };

  onChangePageSize = value => {
    this.props.dispatch(
      paginationSettings.setPaginationAction(this.props.tableType, value)
    );
  };

  render() {
    return (
      <Table
        {...this.props}
        pageSizeValue={this.props.paginationSize || 5}
        pageSizes={this.props.paginationOptions || []}
        loading={this.props.loadingState}
        onChangePageSize={this.onChangePageSize}
      />
    );
  }
}

const mapState = (state, ownProps) => {
  const { tableType } = ownProps;
  return {
    paginationSize:
      tableType in state.paginationSettings &&
      state.paginationSettings[tableType].size,
    paginationOptions:
      tableType in state.paginationSettings &&
      state.paginationSettings[tableType].options
  };
};

export default connect(mapState)(PaginationTable);
