import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import { withLogin } from "../shared/login";
import adminConnectionsTabs from "./tabs";
import AdminConnections from "../../components/admin_connections";
import { analyticsActions, connectionsActions } from "../../redux_base/actions";

export const pmsDataPropTypes = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    url: PropTypes.string,
    db_name: PropTypes.string,
    server_name: PropTypes.string,
    platform: PropTypes.string,
    username: PropTypes.string,
    added_updated_by: PropTypes.string,
    last_updated: PropTypes.string
  })
);

export const dataConnectionsPropTypes = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.number,
    property: PropTypes.string,
    integration: PropTypes.string,
    code_or_id: PropTypes.string,
    status: PropTypes.string,
    last_backfill_start: PropTypes.string,
    last_backfill_end: PropTypes.string,
    added_updated_by: PropTypes.string,
    last_updated: PropTypes.string
  })
);

class AdminConnectionsContainer extends React.Component {
  static propTypes = {
    pmsData: pmsDataPropTypes.isRequired,
    pmsDataLoading: PropTypes.bool,
    dataConnections: dataConnectionsPropTypes.isRequired,
    dataConnectionsLoading: PropTypes.bool
  };

  componentDidMount() {
    let tabId = this.props.match.params.tabId;
    if (!tabId) {
      tabId = adminConnectionsTabs[0]["name"];
      this.props.history.push(`/admin-connections/${tabId}`);
    }
    this.props.dispatch(connectionsActions.requestPMSInstances());
    this.props.dispatch(connectionsActions.requestDataConnections());
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.connectionDeleteLoading !== this.props.connectionDeleteLoading
    ) {
      this.props.dispatch(connectionsActions.requestDataConnections());
    }
  }

  onChangeTab = selectedIndex => {
    const tabView =
      adminConnectionsTabs[selectedIndex]["name"] ||
      adminConnectionsTabs[0]["name"];
    this.props.history.push(`/admin-connections/${tabView}`);
  };

  onEditPMS = id =>
    this.props.history.push(`/admin-connections/add-pms-instance/${id}`);

  onDeletePMS = pmsId => {
    this.props.dispatch(connectionsActions.deletePMS(pmsId));
  };

  onEditConnection = (id, integration_source) => {
    this.props.history.push(
      `/admin-connections/add-data-connection/${integration_source}-${id}`
    );
  };

  onDeleteConnection = (id, integration_source) => {
    this.props.dispatch(
      connectionsActions.deleteConnection(id, integration_source)
    );
  };

  onTestConnection = (projectId, integrationSource) => {
    if (integrationSource === "yardi") {
      this.props.dispatch(connectionsActions.testPMSConnection(projectId));
    } else if (integrationSource === "google_analytics") {
      this.props.dispatch(analyticsActions.testAnalyticsConnection(projectId));
    }
  };

  onRefreshDataConnections = () => {
    this.props.dispatch(connectionsActions.requestDataConnections());
  };

  onBackfill = (projectId, integrationSource, startDate, endDate) => {
    if (integrationSource === "yardi") {
      this.props.dispatch(
        connectionsActions.backfillYardiPMS(projectId, startDate, endDate)
      );
    } else if (integrationSource === "google_analytics") {
      this.props.dispatch(
        analyticsActions.backfillGoogleAnalytics(projectId, startDate, endDate)
      );
    }
  };

  render() {
    const { tabId } = this.props.match.params;
    const selectedIndex = tabId
      ? adminConnectionsTabs.findIndex(t => t["name"] === tabId)
      : 0;
    return (
      <AdminConnections
        pmsLoading={this.props.pmsDataLoading}
        pmsData={this.props.pmsData}
        dataConnectionsLoading={this.props.dataConnectionsLoading}
        dataConnections={this.props.dataConnections}
        selectedIndex={selectedIndex}
        onChangeTab={this.onChangeTab}
        onEditPMS={this.onEditPMS}
        onDeletePMS={this.onDeletePMS}
        onEditConnection={this.onEditConnection}
        onDeleteConnection={this.onDeleteConnection}
        onTestConnection={this.onTestConnection}
        onRefreshDataConnections={this.onRefreshDataConnections}
        onBackfill={this.onBackfill}
      />
    );
  }
}

const mapState = state => {
  return {
    ...state.network,
    ...state.connections,
    connectionDeleteLoading: state.connections.connectionDeleteLoading
  };
};

export default withRouter(
  connect(mapState)(withLogin(AdminConnectionsContainer))
);
