import { qsStringify } from "../../utils/misc";
import { createAjaxAction, URLS } from "./helpers";

export default {
  updateState: state => ({
    type: "PROPERTY_UPDATE_STATE",
    state
  }),
  requestProperties: createAjaxAction(
    "AJAX_GET_PROPERTIES",
    `${URLS.properties}`
  ),
  requestProperty: publicId =>
    createAjaxAction("AJAX_GET_PROPERTY", `${URLS.properties}/${publicId}`)(),
  requestNewWidgetData: (public_id, date_selection) => ({
    type: "GET_NEW_WIDGET_DATA_FOR_PROPERTY",
    body: {
      publicId: public_id,
      dateSelection: date_selection
    }
  }),
  getUsers: value =>
    createAjaxAction(
      "AJAX_POST_PROPERTY_GET_USERS",
      "/search-members/"
    )({ body: { value } }),
  postPropertyDetails: data =>
    createAjaxAction(
      "AJAX_POST_PROPERTIES_DETAILS",
      `${URLS.properties_details}`
    )({ body: data }),
  patchPropertyDetails: (data, publicId) =>
    createAjaxAction(
      "AJAX_PATCH_PROPERTIES_DETAILS",
      `${URLS.properties_details}/${publicId}`
    )({ body: data }),
  getPropertyOwners: createAjaxAction(
    "AJAX_GET_PROPERTY_OWNERS",
    `${URLS.property_owners}`
  ),
  inviteMembers: createAjaxAction(
    "AJAX_POST_PROPERTY_INVITE_MEMBER",
    "/projects/add-members/"
  ),
  changeRole: (role, property, member) =>
    createAjaxAction(
      "AJAX_POST_PROPERTY_UPDATE_MEMBER",
      "",
      () => `/projects/${property.public_id}/member/${member.user_id}/`
    )({ body: { role } }),
  removeMember: (property, member) =>
    createAjaxAction(
      "AJAX_POST_PROPERTY_REMOVE_MEMBER",
      "",
      () => `/projects/${property.public_id}/remove-member/`
    )({ body: { member } }),
  openInvite: () => ({ type: "PROPERTY_OPEN_INVITE_MEMBER" }),
  closeInvite: () => ({ type: "PROPERTY_CLOSE_INVITE_MEMBER" }),
  clearInviteMessages: () => ({ type: "PROPERTY_RESET_INVITE_MESSAGES" }),
  openRemove: () => ({ type: "PROPERTY_OPEN_REMOVE_MEMBER" }),
  closeRemove: () => ({ type: "PROPERTY_CLOSE_REMOVE_MEMBER" }),
  openChangeRole: () => ({ type: "PROPERTY_OPEN_CHANGE_ROLE" }),
  closeChangeRole: () => ({ type: "PROPERTY_CLOSE_CHANGE_ROLE" }),
  getKPIDataSource: (publicId, kpiName) =>
    createAjaxAction(
      "AJAX_GET_PROPERTY_DATA_SOURCE",
      `/projects/${publicId}/${kpiName}/data-source/`
    )(),
  getKPIData: (publicId, kpi, start, end) =>
    createAjaxAction(
      "AJAX_GET_PROPERTY_KPI_DATA",
      `/projects/${publicId}/${kpi}/?start=${start}&end=${end}`
    )(),
  fetchNWVs: (publicId, start, end) =>
    createAjaxAction(
      "AJAX_GET_PROPERTY_NWVS",
      `/projects/${publicId}/new_website_visitors/?start=${start}&end=${end}`
    )(),
  clearNWVs: () => ({ type: "PROPERTY_CLEAR_NWVS" }),
  fetchLeadSources: (publicId, start, end) =>
    createAjaxAction(
      "AJAX_GET_PROPERTY_LEADS",
      `/projects/${publicId}/leads/?start=${start}&end=${end}`
    )(),
  clearLeadSources: () => ({ type: "PROPERTY_CLEAR_LEADS" }),
  getTags: publicId =>
    createAjaxAction(
      "AJAX_GET_PROPERTY_TAGS",
      `${URLS.project}/${publicId}/tags/`
    )(),
  createTag: publicId =>
    createAjaxAction(
      "AJAX_POST_PROPERTY_CREATE_TAG",
      `${URLS.project}/${publicId}/tags/`
    ),
  removeTag: (publicId, tagId) =>
    createAjaxAction(
      "AJAX_POST_PROPERTY_REMOVE_TAG",
      `${URLS.project}/${publicId}/tags/${tagId}`
    )({ body: {}, params: { tagId } }),
  searchTags: publicId =>
    createAjaxAction(
      "AJAX_GET_PROPERTY_SEARCH_TAGS",
      `${URLS.project}/${publicId}/tags-search`,
      qs => qsStringify(qs)
    ),
  showAddTagInput: () => ({ type: "PROPERTY_SHOW_TAG_INPUT" }),
  hideTagInput: () => ({ type: "PROPERTY_HIDE_TAG_INPUT" }),
  fetchAttributions: (publicId, analyticsProvider, analyticsIdentifier) =>
    createAjaxAction(
      "AJAX_GET_ATTRIBUTIONS",
      `${URLS.proxy}/projects/${publicId}/attributions/?analyticsProvider=${analyticsProvider}&analyticsIdentifier=${analyticsIdentifier}`
    )(),
  clearAttributions: () => ({ type: "PROPERTY_CLEAR_ATTRIBUTIONS" }),
  saveAttributions: (projectId, params) =>
    createAjaxAction(
      "AJAX_POST_ATTRIBUTIONS",
      `${URLS.proxy}/projects/${projectId}/attributions/`
    )({ body: params }),
  setAttributionsSave: save => ({
    type: "PROPERTY_SET_ATTRIBUTIONS_SAVE",
    save
  }),
  showWidgetLoader: () => ({ type: "NEW_WIDGET_DATA_FOR_PROPERTY_LOADER" }),
  closeWidgetLoader: () => ({ type: "CLOSE_NEW_WIDGET_DATA_FOR_PROPERTY" }),
  showCATableLoader: () => ({ type: "SHOW_CA_TABLE_LOADER" }),
  closeCATableLoader: () => ({ type: "CLOSE_CA_TABLE_LOADER" }),
  getChannelAnalysisKpis: (publicId, start, end, filters = {}) => {
    const params = {
      filters: filters,
      start: start,
      end: end
    };
    return createAjaxAction(
      "AJAX_POST_PROPERTY_CA_KPIS",
      `${URLS.project}/channel_analysis/${publicId}`,
      "",
      true
    )({ body: params });
  },
  getExpensesLastUpdated: projectId =>
    createAjaxAction(
      "AJAX_GET_PROPERTY_EXPENSES_LAST_UPDATED",
      `${URLS.proxy}/projects/${projectId}/expenses/last-updated`
    )(),
  requestPropertiesDetails: createAjaxAction(
    "AJAX_GET_PROPERTIES_DETAILS",
    `${URLS.properties_details}`
  ),
  requestDeactivateProperty: projectId =>
    createAjaxAction(
      "AJAX_POST_DEACTIVATE_PROPERTY",
      `${URLS.deactivate_property}/${projectId}`
    )({ body: {} }),
  requestWidgetData: data =>
    createAjaxAction(
      "AJAX_GET_WIDGET_DATA",
      `${URLS.widget_data}/`,
      qs => qs && qsStringify(qs),
      true
    )(data)
};
