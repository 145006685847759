export const configProperty = {
  fields: {
    first_name: {
      validators: {
        isRequired: {
          message: "Field is required."
        },
        maxLength: {
          value: 255,
          message: "Field is too long"
        }
      }
    },
    last_name: {
      validators: {
        isRequired: {
          message: "Field is required."
        },
        maxLength: {
          value: 255,
          message: "Field is too long"
        }
      }
    },
    title: {
      validators: {
        maxLength: {
          value: 255,
          message: "Field is too long"
        }
      }
    },
    company: {
      type: "object",
      validators: {
        value: {
          isRequired: {
            message: "Field is required."
          },
          maxLength: {
            value: 128,
            message: "Field is too long"
          }
        }
      }
    },
    terms: {
      validators: {
        isRequired: {
          message: "You have to accept terms and conditions."
        }
      }
    }
  }
};
