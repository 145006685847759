import cn from "classnames";
import _isEmpty from "lodash/isEmpty";
import _isEqual from "lodash/isEqual";
import _intersection from "lodash/intersection";
import _pick from "lodash/pick";
import PropTypes from "prop-types";
import React, { useRef, useState, useEffect } from "react";
import { connect } from "react-redux";

import CompanyModal from "../../containers/company_modal";
import LoaderContainer from "../../containers/loader";
import { withLogin } from "../../containers/shared/login";

import AccountForm from "../account_form";
import PageAuth from "../page_auth";
import { Button, TextInput } from "concrete-ui";
import Checkbox from "../checkbox";
import { companyActions } from "../../redux_base/actions";
import completeAccount from "../../redux_base/actions/complete_account";

import { CompanyInfo } from "./company";
import "./complete_account_view.scss";
import { usePrevious } from "../../utils/misc";
import { configProperty } from "./validators";
import useValidation from "../../utils/validator_hook";
import { concreteErrorVariant } from "../../constants";

const initialValues = {
  first_name: "",
  last_name: "",
  title: "",
  company: undefined,
  terms: false
};

export const CompleteAccountView = ({
  dispatch = {},
  is_completed,
  ...props
}) => {
  const [state, setState] = useState({
    isCompanyOpen: false,
    invalidAddress: false
  });
  const loadCompanyTimeout = useRef(null);
  const prevErrors = usePrevious(props.errors);

  useEffect(() => {
    dispatch(completeAccount.fetch());
  }, []);

  useEffect(() => {
    if (is_completed) {
      dispatch(completeAccount.redirect("/"));
    }
    if (!_isEmpty(props.errors) && !_isEqual(props.errors, prevErrors)) {
      setErrorMessages(props.errors);
      dispatch(completeAccount.clearErrors());
    }
  });

  const onSubmit = values => {
    const body = { ...values };
    body.company = values.company.value;
    dispatch(completeAccount.post(body));
  };

  const {
    formItems,
    setFormItems,
    errors,
    setErrors,
    getFormProps,
    getItemProps
  } = useValidation(configProperty, initialValues, onSubmit);

  const steps = [
    { name: "Set Password", isComplete: true },
    { name: "Complete Account", isActive: true }
  ];

  const getCompanyValues = () => {
    const initialValues = _pick(initialValues, ["company"]);
    return {
      company: formItems.company || initialValues
    };
  };

  const loadCompany = inputValue => {
    if (!!inputValue) {
      clearTimeout(loadCompanyTimeout.current);
      loadCompanyTimeout.current = setTimeout(() => {
        dispatch(companyActions.searchCompany(inputValue));
      }, 300);
    }
  };

  const setErrorMessages = errors => {
    const updateErrors = {};
    for (let k of Object.keys(errors)) {
      updateErrors[k] = errors[k][0]?.message;
    }
    setErrors(updateErrors);
  };

  const showMessage = errors => {
    if (Object.keys(errors).length) {
      return showErrorMessage(errors);
    }
  };

  const showErrorMessage = errors => {
    const errorFields = Object.keys(errors);
    if (!errorFields.length) {
      return;
    }
    let message = "Please review highlighted fields above.";
    if (state?.invalidAddress) {
      message =
        "Unable to verify the address. Please try again with a valid address.";
    }
    return <div className="complete-account__error">{message}</div>;
  };

  const showCompanyError = () => {
    if (errors.company) {
      return <div className="complete-account__error">is required.</div>;
    }
  };

  const onChangeTerms = () => {
    setFormItems({ terms: !formItems["terms"] });
  };

  const onOpenCompanyModal = () => {
    setState(prevState => ({ ...prevState, isCompanyOpen: true }));
  };

  const onCloseCompanyModal = () => {
    setState(prevState => ({ ...prevState, isCompanyOpen: false }));
  };

  const putCompanyValues = values => {
    setFormItems(values);
    onCloseCompanyModal();
  };

  const classes = cn("complete-account__field-set", AccountForm.fieldClass);
  const company = getCompanyValues();

  return (
    <PageAuth backLink="/">
      <LoaderContainer />
      <CompanyModal
        theme="highlight"
        isOpen={state.isCompanyOpen}
        data={company}
        loadCompany={loadCompany}
        onClose={onCloseCompanyModal}
        onSave={putCompanyValues}
      />
      <AccountForm
        className="complete-account"
        steps={steps}
        title="Complete your account"
        subtitle="We need just a bit more information about you to complete your account."
      >
        <form {...getFormProps()}>
          <div className={classes}>
            <TextInput
              color="highlight"
              mr="22px"
              size="large"
              label="First name"
              type="text"
              {...getItemProps("first_name")}
              variant={errors["first_name"] && concreteErrorVariant}
              message={errors["first_name"]}
            />
            <TextInput
              color="highlight"
              size="large"
              type="text"
              label="Last name"
              {...getItemProps("last_name")}
              variant={errors["last_name"] && concreteErrorVariant}
              message={errors["last_name"]}
            />
          </div>
          <TextInput
            color="highlight"
            size="large"
            type="text"
            label="Title (Optional)"
            {...getItemProps("title")}
            variant={errors["title"] && concreteErrorVariant}
            message={errors["title"]}
          />
          <div className="complete-account__company">
            <CompanyInfo
              data={formItems}
              onOpenCompanyModal={onOpenCompanyModal}
              showErrorMessage={showCompanyError}
            />
          </div>
          <div className="complete-account__terms">
            <Checkbox
              className="complete-account__checkbox"
              isSelected={formItems.terms}
              onClick={onChangeTerms}
            />
            <input
              type="checkbox"
              name="terms"
              hidden={true}
              checked={formItems.terms}
              readOnly={true}
            />
            Accept&nbsp;
            <a
              className="complete-account__link"
              href="https://www.remarkably.io/terms"
              target="_blank"
            >
              Terms and Conditions
            </a>
          </div>
          <Button
            variant="primary"
            size="large"
            type="submit"
            disabled={!Object.keys(errors).length}
            fullWidth={true}
            mt={5}
          >
            Complete account
          </Button>
          {showMessage(errors)}
        </form>
      </AccountForm>
    </PageAuth>
  );
};

CompleteAccountView.propTypes = {
  is_completed: PropTypes.bool,
  errors: PropTypes.object,
  dispatch: PropTypes.func
};

const mapState = state => {
  return {
    ...state.network,
    ...state.completeAccount
  };
};

export default connect(mapState)(withLogin(CompleteAccountView, true, false));
