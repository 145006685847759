import { Box, Text, Tooltip } from "concrete-ui";
import React from "react";

import {
  INCLUDES_AUTOMATED_ACTUAL_EXPENSES_MSG,
  ZENDESK_NWV_SUMMARY_DIFFERENCE,
  getNWVDistributedMsg,
  getNWVMatchedMsg,
  nwvSummaryDifferenceMsg
} from "../../constants";
import TooltipLink from "../tooltip_link";
import { expenseDiffMapper } from "./config";
import { formatCurrency, formatIntNumber } from "./formatters";
import { isValueExist } from "./utils";

const isDiffOver = diffValue => diffValue >= 0;

const getBudgetCostPersDiffString = diffValue =>
  isDiffOver(diffValue)
    ? `${formatCurrency(Math.abs(diffValue))} Over Actual`
    : `${formatCurrency(Math.abs(diffValue))} Under Actual`;

const getActualCostPersDiffString = diffValue =>
  diffValue > 0
    ? `${formatCurrency(Math.abs(diffValue))} Over Budgeted`
    : `${formatCurrency(Math.abs(diffValue))} Under Budgeted`;

const getDiffValueDefault = (data, columnId, type) => {
  let diffValue = data?.[expenseDiffMapper[columnId]];
  if (type === "actual" && isValueExist(diffValue)) {
    diffValue = diffValue * -1;
  }

  return diffValue;
};

export const ValueWithTooltip = ({
  value,
  textProps,
  highlightedTextProps,
  tooltipProps,
  formatValue = value => value,
  includeTooltip = true
}) => {
  const formattedValue = isValueExist(value) ? formatValue(value) : "—";
  return (
    <Box display="inline-flex">
      {includeTooltip && tooltipProps ? (
        <Tooltip size="mini" placement="bottom-right" {...tooltipProps}>
          <Text {...textProps} {...highlightedTextProps}>
            {formattedValue}
          </Text>
        </Tooltip>
      ) : (
        <Text {...textProps}>{formattedValue}</Text>
      )}
    </Box>
  );
};

export const CostPersValue = ({
  column,
  data,
  value,
  type,
  textProps,
  highlightedTextProps,
  tooltipTitle,
  includeTooltip = true,
  getDiffValue = getDiffValueDefault
}) => {
  const diffValue = getDiffValue(data, column.id, type);
  return (
    <ValueWithTooltip
      value={value}
      textProps={textProps}
      highlightedTextProps={highlightedTextProps}
      tooltipProps={{ title: tooltipTitle }}
      formatValue={formatCurrency}
      includeTooltip={includeTooltip && isValueExist(diffValue)}
    />
  );
};

export const BudgetCostPersValue = ({
  column,
  data,
  value,
  textProps,
  isHighlightText = false,
  includeTooltip = true,
  getDiffValue = getDiffValueDefault
}) => {
  const type = "budget";
  const diffValue = getDiffValue(data, column.id, type);
  const tooltipTitle = getBudgetCostPersDiffString(diffValue);
  const highlightedTextProps = {};
  if (isHighlightText) {
    highlightedTextProps.$color = isDiffOver(diffValue)
      ? "text.green.0"
      : "text.orange.0";
  }
  return (
    <CostPersValue
      column={column}
      data={data}
      value={value}
      type={type}
      textProps={textProps}
      highlightedTextProps={highlightedTextProps}
      tooltipTitle={tooltipTitle}
      includeTooltip={includeTooltip}
      getDiffValue={getDiffValue}
    />
  );
};

export const ActualCostPersValue = ({
  column,
  data,
  value,
  textProps,
  isHighlightText = false,
  includeTooltip = true,
  getDiffValue = getDiffValueDefault
}) => {
  const type = "actual";
  const diffValue = getDiffValue(data, column.id, type);
  const tooltipTitle = getActualCostPersDiffString(diffValue);
  const highlightedTextProps = {};
  if (isHighlightText) {
    highlightedTextProps.$color =
      diffValue > 0 ? "text.orange.0" : "text.green.0";
  }
  return (
    <CostPersValue
      column={column}
      data={data}
      value={value}
      type={type}
      textProps={textProps}
      highlightedTextProps={highlightedTextProps}
      tooltipTitle={tooltipTitle}
      includeTooltip={includeTooltip}
      getDiffValue={getDiffValue}
    />
  );
};

export const ActualExpenseValue = ({ data, ...props }) => {
  if (!!data.actual_budget && !!data.automated) {
    return (
      <Box display="inline-flex">
        <ActualCostPersValue data={data} {...props} isHighlightText />
        <Box ml={1} verticalAlign="middle" display="inline-block">
          <Tooltip
            title={INCLUDES_AUTOMATED_ACTUAL_EXPENSES_MSG}
            display="inline-block"
            size="small"
            iconSize="small"
          />
        </Box>
      </Box>
    );
  }
  return <ActualCostPersValue data={data} {...props} isHighlightText />;
};

export const NewWebsiteVisitorsSummaryValue = ({ data, value, textProps }) => {
  return (
    <Box display="inline-flex">
      <Text {...textProps}>
        {isValueExist(value) ? formatIntNumber(value) : "—"}
      </Text>
      {isValueExist(value) && (
        <Box ml={1} verticalAlign="middle" display="inline-block">
          <Tooltip
            title={nwvSummaryDifferenceMsg(formatIntNumber(data["nwv_diff"]))}
            display="inline-block"
            size="small"
            iconSize="small"
            linkText="Learn more."
            link={ZENDESK_NWV_SUMMARY_DIFFERENCE}
            tooltipProps={{
              onClick: e => e.stopPropagation()
            }}
            linkProps={{
              target: "_blank"
            }}
          />
        </Box>
      )}
    </Box>
  );
};

export const NWVValue = ({ value, textProps, data, isAdmin }) => {
  const highlightedTextProps = { $color: "text.green.0" };
  const matchedCount = data.matched_nwvs_list?.length;
  const distributedCount = data.distributed_nwvs_list?.length;

  let tooltipProps;
  if (matchedCount) {
    tooltipProps = {
      title: (
        <Box flexDirection="column">
          {getNWVMatchedMsg(matchedCount)}
          {isAdmin && (
            <TooltipLink
              link={"/referral-sources/match"}
              linkText={"View matches."}
            />
          )}
        </Box>
      )
    };
  } else if (distributedCount) {
    tooltipProps = {
      title: (
        <Box flexDirection="column">
          {getNWVDistributedMsg(distributedCount)}
          {isAdmin && (
            <TooltipLink
              link={"/referral-sources/distribution"}
              linkText={"View distribution."}
            />
          )}
        </Box>
      )
    };
  }
  const valueTextProps = !!data.lead_name
    ? { ...textProps, ...highlightedTextProps }
    : textProps;
  return (
    <ValueWithTooltip
      value={value}
      formatValue={formatIntNumber}
      includeTooltip={isValueExist(value)}
      tooltipProps={tooltipProps}
      textProps={valueTextProps}
      highlightedTextProps={highlightedTextProps}
    />
  );
};
