const initialState = {
  leadSourcesOptions: [],
  leadSourcesAttribution: {},
  nwvs: [],
  matchedNWVs: [],
  matchedLeads: undefined,
  unmatchedLeads: undefined,
  distributedLeads: undefined,
  undistributedLeads: undefined,
  distributedNwvs: [],
  fetchingLeadSourcesOptions: false,
  fetchingNWVs: false,
  distSourceSelectionOptions: new Set([])
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "AJAX_GET_REFERRAL_SOURCES_MATCHING_REQUEST": {
      return {
        ...state,
        fetchingLeadSourcesOptions: true
      };
    }
    case "AJAX_GET_REFERRAL_SOURCES_MATCHING_SUCCESS": {
      return {
        ...state,
        fetchingLeadSourcesOptions: false,
        leadSourcesAttribution: action.payload["leads"],
        // nwvs: action.payload["nwvs"],
        matchedNWVs: action.payload["matched_nwvs"],
        matchedLeads: action.payload["matched"],
        unmatchedLeads: action.payload["unmatched"],
        distributedLeads: action.payload["distributed"],
        undistributedLeads: action.payload["undistributed"],
        distributedNwvs: action.payload["distributed_nwvs"],
        distSourceSelectionOptions: new Set(
          action.payload["dist_source_selection_options"] || []
        )
      };
    }
    case "AJAX_GET_REFERRAL_SOURCES_MATCHING_ERROR": {
      return {
        ...state,
        fetchingLeadSourcesOptions: false
      };
    }
    case "REFERRAL_SOURCES_CLEAR_MATCHING": {
      return {
        ...state,
        leadSourcesOptions: [],
        leadSourcesAttribution: {},
        nwvs: [],
        matchedNWVs: [],
        matchedLeads: undefined,
        unmatchedLeads: undefined
      };
    }
    case "AJAX_GET_REFERRAL_SOURCES_NWV_REQUEST": {
      return {
        ...state,
        fetchingNWVs: true
      };
    }
    case "AJAX_GET_REFERRAL_SOURCES_NWV_SUCCESS": {
      return {
        ...state,
        nwvs: action.payload["nwvs"],
        fetchingNWVs: false
      };
    }

    default:
      return {
        ...state,
        fetchingLeadSourcesOptions: false
      };
  }
};

export default reducer;
