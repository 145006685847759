import cn from "classnames";
import PropTypes from "prop-types";
import React from "react";
import ReactSelect from "react-select";

import { DropdownIndicator } from "./select_components";
import "./select.scss";

export default function Select(props) {
  const {
    className,
    onChange,
    options,
    defaultValue,
    value,
    placeholder,
    name,
    components,
    theme,
    size,
    isMulti,
    isSearchable,
    isDisabled,
    ...otherProps
  } = props;
  const classes = cn("select", className, {
    "select--is-multi": isMulti,
    [`select--${size}`]: size,
    [`select--${theme}`]: theme,
    "select--is-searchable": isSearchable,
    "select--is-disabled": isDisabled
  });
  return (
    <ReactSelect
      className={classes}
      classNamePrefix="select"
      name={name}
      options={options}
      defaultValue={defaultValue}
      value={value}
      placeholder={placeholder}
      onChange={onChange}
      isSearchable={props.isSearchable}
      isMulti={isMulti}
      components={{ DropdownIndicator, ...components }}
      isDisabled={isDisabled}
      {...otherProps}
    />
  );
}

Select.optionsGroupType = PropTypes.arrayOf(
  PropTypes.shape({
    label: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired
      })
    ).isRequired
  })
);

Select.optionsType = PropTypes.oneOfType([
  PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    })
  ),
  Select.optionsGroupType
]);
Select.propTypes = {
  size: PropTypes.oneOf(["", "small", "middle"]),
  theme: PropTypes.oneOf([
    "",
    "default",
    "highlight",
    "transparent",
    "gray",
    "description",
    "form-field"
  ]),
  options: Select.optionsType,
  className: PropTypes.string,
  name: PropTypes.string,
  defaultValue: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  isMulti: PropTypes.bool,
  isSearchable: PropTypes.bool,
  components: PropTypes.object
};
Select.defaultProps = {
  size: "",
  theme: "",
  isMulti: false,
  components: {},
  isSearchable: false
};

export { SelectSearch } from "./select_search";
