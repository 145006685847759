import React from "react";
import PropTypes from "prop-types";
import {
  Divider,
  Flex,
  Item,
  Select,
  Text,
  ValueLoader,
  Tooltip,
  Link
} from "concrete-ui";
import RenderIf from "../render_if";
import {
  noPMSConnectionMessage,
  noAnalyticsOrPMSConnectionMessage,
  noAnalyticsConnectionMessage,
  sharedAnalyticsProviderMessage
} from "../referral_sources/tooltips";

function ReferralSourcesProperties(props) {
  const getDisabledTooltipMessage = property => {
    if (!property.has_pms_connection && !property.has_analytics_connection) {
      return noAnalyticsOrPMSConnectionMessage(props.tab);
    } else if (!property.has_pms_connection) {
      return noPMSConnectionMessage(props.tab);
    } else if (!property.has_analytics_connection) {
      return noAnalyticsConnectionMessage(props.tab);
    } else if (property.duplicate) {
      return sharedAnalyticsProviderMessage(props.tab);
    }
    return null;
  };
  const getPropertyList = () =>
    props.properties.map(property => {
      const selected = property.public_id === props.selectedProperty?.public_id;
      const disabled = Boolean(
        getDisabledTooltipMessage(property) || property.duplicate
      );
      return (
        <>
          <RenderIf condition={!disabled}>
            <Item
              size="large"
              selected={selected}
              key={property.public_id}
              onClick={() => props.onChangeProperty(property)}
            >
              <Text textSize={2} ellipsis={true} title={property.name}>
                {property.name}
              </Text>
            </Item>
          </RenderIf>
          <RenderIf condition={disabled}>
            <Tooltip
              size="small"
              title={
                <>
                  {getDisabledTooltipMessage(property)}
                  <RenderIf condition={!property.duplicate}>
                    <Link
                      href={"/admin-connections/data_connections"}
                      $color="text.gray.1"
                      textSize={1}
                      variant="underline"
                      target="_blank"
                    >
                      Connect now.
                    </Link>
                  </RenderIf>
                </>
              }
              placement="right"
              style={{ display: "block" }}
            >
              <Item
                size="large"
                selected={selected}
                key={property.public_id}
                disabled
              >
                <Text
                  textSize={2}
                  ellipsis={true}
                  title={property.name}
                  style={{ backgroundColor: "transparent" }}
                >
                  {property.name}
                </Text>
              </Item>
            </Tooltip>
          </RenderIf>
        </>
      );
    });

  const onSelectPortfolio = portfolioId => {
    if (portfolioId) {
      const portfolio = props.portfolios.find(p => p.value === portfolioId);
      props.onChangePortfolio(portfolio);
    }
  };

  const showNoPropertyText =
    props.properties.length === 0 ||
    props.properties.every(p => p.duplicate || p.disabled);

  return (
    <Flex flexDirection="column" flex="0 0 19rem" minWidth="19rem">
      <Flex flexDirection="column" px={6} pt={5} pb={6}>
        <Text textSize={2} $fontWeight="regular" $color="text.gray.1">
          Portfolio
        </Text>
        <Flex flexDirection="column" mt={2}>
          <Select
            size="large"
            placeholder="Choose a Portfolio"
            items={props.portfolios}
            value={props.selectedPortfolio?.value || null}
            onSelect={onSelectPortfolio}
          />
        </Flex>
      </Flex>
      <Divider variant="horizontal" bg="gray.4" flexShrink={0} />
      <Flex flexDirection="column" pt={5} pb={6} overflow="auto">
        <RenderIf condition={props.fetchingProperties}>
          <Flex flexDirection="column" px={6}>
            <Text textSize={2} $fontWeight="regular" $color="text.gray.1">
              Properties
            </Text>
          </Flex>
          <Flex flexDirection="column" mt={2} px={5}>
            <ValueLoader mb={2} textSize={5} />
          </Flex>
        </RenderIf>
        <RenderIf condition={!props.fetchingProperties}>
          <Flex flexDirection="column" px={6}>
            <Text textSize={2} $fontWeight="regular" $color="text.gray.1">
              {showNoPropertyText
                ? props.noPropertiesAvailableMessage
                : "Properties"}
            </Text>
          </Flex>
          <RenderIf condition={!showNoPropertyText}>
            <Flex flexDirection="column" mt={2} px={5}>
              {getPropertyList()}
            </Flex>
          </RenderIf>
        </RenderIf>
      </Flex>
    </Flex>
  );
}

ReferralSourcesProperties.portfoliosType = PropTypes.arrayOf(
  PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string
  })
);

ReferralSourcesProperties.propertiesType = PropTypes.arrayOf(
  PropTypes.shape({
    public_id: PropTypes.string,
    name: PropTypes.string,
    is_admin: PropTypes.bool
  })
);

ReferralSourcesProperties.propTypes = {
  portfolios: ReferralSourcesProperties.portfoliosType,
  properties: ReferralSourcesProperties.propertiesType,
  fetchingProperties: PropTypes.bool,
  selectedPortfolio: PropTypes.object,
  selectedProperty: PropTypes.object,
  onChangePortfolio: PropTypes.func,
  onChangeProperty: PropTypes.func,
  noPropertiesAvailableMessage: PropTypes.string
};

ReferralSourcesProperties.defaultProps = {
  portfolios: [],
  properties: [],
  fetchingProperties: false,
  selectedPortfolio: null,
  selectedProperty: null,
  onChangePortfolio: () => {},
  onChangeProperty: () => {}
};

export default ReferralSourcesProperties;
