import _find from "lodash/find";
import { dateParse, dateToISO, isValid } from "../../utils/dates";
import { Box, Icon, Tooltip } from "concrete-ui";
import React from "react";

export const getLabel = (object, value) =>
  _find(object, ["value", value])?.label;

export const expenseModalProps = {
  one_time: {
    option: "one_time",
    title: "Review One Time Expense"
  },
  pay_for_performance: {
    option: "pay_for_performance",
    title: "Review Pay For Performance Expense"
  },
  recurring: {
    option: "recurring",
    title: "Review Recurring Expense"
  }
};

export const showDate = date =>
  date ? dateParse(date).toLocaleDateString() : "";

export const dateToValidation = date =>
  isValid(date) ? dateToISO(date) : null;

export const dateToDatePicker = date =>
  isValid(date) ? dateParse(date) : null;

export const inputNumberFormatter = value => (!value ? null : value);

export const inputStringFormatter = value => (value == null ? "" : value);

export const inputTextWithTooltip = (title, tooltip) => (
  <Box alignItems="center">
    {title}{" "}
    <Tooltip title={tooltip} size="small">
      <Box ml={2}>
        <Icon.Tooltip size={3} ml={2} />
      </Box>
    </Tooltip>
  </Box>
);

export function formatSuggestedValue(value) {
  return typeof value.cost_budgeted !== "undefined"
    ? Number(value.cost_budgeted).toFixed(2)
    : 0;
}
