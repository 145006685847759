import React from "react";
import { Flex, Text, Divider } from "concrete-ui";
import { showDate } from "../utils";

export const ActualExpenseReview = ({ actual_expenses }) => {
  const expensesToDisplay = actual_expenses.filter(entry => !entry.to_delete);
  return (
    <>
      {expensesToDisplay.map((expense, index) => {
        return (
          <>
            <Divider variant="horizontal" bg="gray.4" mt={4} mb={5} />
            <Flex flexDirection="column">
              <Flex flexBasis="100%">
                <Text textSize={2} $fontWeight="medium">
                  Actual Expense #{index + 1}
                </Text>
              </Flex>
              <Flex flexDirection="row">
                <Flex flexDirection="column" flex="2" mt={4}>
                  <Text textSize={2} $color="text.gray.1">
                    Invoice Date
                  </Text>
                  <Text textSize={1} $fontWeight="medium">
                    {showDate(expense.invoice_date)}
                  </Text>
                </Flex>
                <Flex flexDirection="column" flex="2" mt={4}>
                  <Text textSize={2} $color="text.gray.1">
                    Actual Expense
                  </Text>
                  <Text textSize={1} $fontWeight="medium">
                    {expense.cost && `$${expense.cost}`}
                  </Text>
                </Flex>
              </Flex>
              <Flex flexDirection="row">
                <Flex flexDirection="column" flex="2" mt={4}>
                  <Text textSize={2} $color="text.gray.1">
                    Expense Start and End Dates
                  </Text>
                  <Text textSize={1} $fontWeight="medium">
                    {showDate(expense.expense_date)} to{" "}
                    {showDate(expense.expense_date_end)}
                  </Text>
                </Flex>
              </Flex>
            </Flex>
          </>
        );
      })}
    </>
  );
};
