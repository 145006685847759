import { createAjaxAction } from "./helpers";

export const companyActions = {
  fetchAddresses: data =>
    createAjaxAction(
      "AJAX_POST_COMPANY_ADDRESS",
      "/crm/office-address/"
    )({ body: data }),
  searchCompany: company =>
    createAjaxAction(
      "AJAX_POST_COMPANY_SEARCH",
      "/crm/company-search/"
    )({ body: { company } }),
  getRelatedCompany: createAjaxAction(
    "AJAX_GET_RELATED_COMPANY",
    `/crm/company-related/`,
    companyName => `?company_name=${companyName}`
  ),
  clearCompanyState: () => ({ type: "COMPANY_MODAL_CLEAR" }),
  clearAddressState: () => ({ type: "CLEAR_COMPANY_ADDRESS" })
};
