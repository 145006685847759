import React, { PureComponent } from "react";

import Container from "../../components/container";
import { Button } from "concrete-ui";
import { qsParse } from "../../utils/misc";
import PageChrome from "../../components/page_chrome";

class ErrorContainer extends PureComponent {
  render() {
    const { search } = this.props.location;
    const { code = "", title = "", description = "" } = qsParse(search);

    return (
      <PageChrome>
        <Container>
          <div style={{ paddingTop: "4rem" }}>
            <h3>
              {code} {title}
            </h3>
            <p style={{ marginTop: "1rem" }}>{description}</p>
          </div>
          <Button
            mt={20}
            variant="danger"
            size="small"
            fullWidth={false}
            onClick={() => {
              throw new Error("User Feedback Button Press");
            }}
          >
            Provide Feedback
          </Button>
        </Container>
      </PageChrome>
    );
  }
}

export default ErrorContainer;
