import React, { useState, useEffect } from "react";
import _isEmpty from "lodash/isEmpty";
import { Button, Flex, Icon, Tooltip } from "concrete-ui";
import {
  addMonths,
  addYears,
  dateToISO,
  getDaysInMonth,
  endOfDay
} from "../../../utils/dates";
import { ActualExpenseItem } from "./actual_expense_item";
import { calendarDayRegex } from "../../../utils/validator_helpers";
import { expenseTooltips } from "../tooltips";

export const ActualExpenseList = ({
  onChange,
  formItems,
  suggestedExpenseValue,
  errors
}) => {
  const [addExpenseButtonVisible, setAddExpenseButtonVisible] = useState(
    !(formItems.expense_occurence === "recurring")
  );
  const [nextDate, setNextDate] = useState(dateToISO(endOfDay(new Date())));

  const getLatestDate = () => {
    const latestDate = formItems.actual_expenses.length
      ? formItems.actual_expenses[formItems.actual_expenses.length - 1]
          .invoice_date
      : formItems.start_date;
    return latestDate ? new Date(endOfDay(latestDate)) : endOfDay(new Date());
  };

  const calculateNextDate = () => {
    let newInvoiceDate = getLatestDate();

    newInvoiceDate = addMonths(newInvoiceDate, 1);
    newInvoiceDate.setDate(getDaysInMonth(newInvoiceDate));

    setNextDate(dateToISO(newInvoiceDate));
  };

  useEffect(() => {
    if (formItems.expense_occurrence === "recurring") {
      calculateNextDate();
    }
  }, [formItems.actual_expenses, formItems.expense_occurrence]);

  const addButtonVisibleCheck = () => {
    const fieldCheck = !!formItems.terms;
    const dateCheck =
      formItems.type === "variable_expense" ||
      (formItems.type === "fixed_expense" &&
        formItems.end_date &&
        nextDate <= formItems.end_date);
    setAddExpenseButtonVisible(fieldCheck && dateCheck);
  };

  useEffect(() => {
    if (formItems.expense_occurence === "recurring") {
      addButtonVisibleCheck();
    } else {
      setAddExpenseButtonVisible(true);
    }
  }, [
    nextDate,
    formItems.start_date,
    formItems.end_date,
    formItems.type,
    formItems.terms,
    formItems.expense_occurrence
  ]);

  const onAddActualExpenseClick = () => {
    let expenseListItem;
    if (formItems.expense_occurrence === "recurring") {
      expenseListItem = {
        invoice_date: nextDate,
        expense_date: nextDate,
        cost: null,
        to_delete: false,
        automated: false
      };
    } else {
      expenseListItem = {
        invoice_date: null,
        expense_date: null,
        cost: null,
        to_delete: false,
        automated: false
      };
    }
    onChange([...formItems.actual_expenses, expenseListItem]);
  };

  const onDeleteActualExpenseClick = index => () => {
    if (formItems.actual_expenses[index]?.id) {
      onChangeFieldArray(index)("to_delete", true);
    } else {
      const newArr = [...formItems.actual_expenses];
      newArr.splice(index, 1);
      onChange(newArr);
    }
  };

  const onChangeFieldArray = index => (field, value) => {
    onChange(
      formItems.actual_expenses.map((exp, i) => {
        if (i === index) {
          return { ...exp, ...(exp.id && { to_change: true }), [field]: value };
        } else {
          return exp;
        }
      })
    );
  };

  const expenseDisplayList = () => {
    let response = [];
    if (!_isEmpty(formItems.actual_expenses)) {
      formItems.actual_expenses.map((item, index) => {
        if (!item.to_delete) {
          response.push({ expense: item, actualIndex: index });
        }
      });
    }
    return response;
  };

  const actualExpenseButtonTooltipText = () => {
    let response = "";
    if (
      formItems.expense_occurrence &&
      formItems.expense_occurrence in expenseTooltips["actualExpense"]
    ) {
      response =
        expenseTooltips["actualExpense"][formItems.expense_occurrence][
          "button"
        ];
    }
    return response;
  };

  return (
    <>
      {expenseDisplayList().map((entry, index) => {
        let item = entry["expense"];
        let actualIndex = entry["actualIndex"];
        return (
          <ActualExpenseItem
            key={`${formItems.invoice_date}:${actualIndex}`}
            onExpenseChange={onChangeFieldArray(actualIndex)}
            index={index}
            cost={item.cost}
            expense_date={item.expense_date}
            expense_date_end={item.expense_date_end}
            invoice_date={item.invoice_date}
            suggested_expense={suggestedExpenseValue}
            automated={item.automated}
            onDeleteExpenseClick={onDeleteActualExpenseClick(actualIndex)}
            itemErrors={
              errors.actual_expenses ? errors.actual_expenses[actualIndex] : {}
            }
          />
        );
      })}
      {addExpenseButtonVisible && (
        <Flex alignItems={"center"}>
          <Button
            variant="secondary"
            maxWidth="13.125rem"
            size="large"
            type="button"
            mr={3}
            onClick={onAddActualExpenseClick}
          >
            <Icon.Plus $color="white.1" />
            Add Actual Expense
          </Button>
          <Tooltip title={actualExpenseButtonTooltipText()} size="small" />
        </Flex>
      )}
    </>
  );
};
