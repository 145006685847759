import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _isEqual from "lodash/isEqual";
import { withRouter } from "react-router";
import AdminProperties from "../../components/admin_properties";
import { propertyActions } from "../../redux_base/actions";
import { withLogin } from "../shared/login";
import { adminPropertiesTabs } from "./tabs";

class AdminPropertiesContainer extends React.Component {
  static propTypes = {
    propertiesDetails: PropTypes.arrayOf(PropTypes.object),
    isLoadingPropDetails: PropTypes.bool
  };

  componentDidMount() {
    let tabId = this.props.match.params.tabId;
    if (!tabId) {
      tabId = adminPropertiesTabs[0]["name"];
      this.props.history.push(`/admin-properties/${tabId}`);
    }
    this.props.dispatch(propertyActions.requestPropertiesDetails());
  }

  componentDidUpdate(prevProps) {
    if (
      !_isEqual(
        prevProps.isLoadingPropDeactivation,
        this.props.isLoadingPropDeactivation
      )
    ) {
      this.props.dispatch(propertyActions.requestPropertiesDetails());
    }
  }

  onChangeTab = selectedIndex => {
    const tabView =
      adminPropertiesTabs[selectedIndex]["name"] ||
      adminPropertiesTabs[0]["name"];
    this.props.history.push(`/admin-properties/${tabView}`);
  };

  onEditPropertiesDetails = id => {
    this.props.history.push(`/admin-properties/add/${id}`);
  };

  onEditAttributes = id => {
    this.props.history.push(`/admin-properties/add-attributes/${id}`);
  };

  onDeactivate = id => {
    this.props.dispatch(propertyActions.requestDeactivateProperty(id));
  };

  render() {
    const { tabId } = this.props.match.params;
    const selectedIndex = tabId
      ? adminPropertiesTabs.findIndex(t => t["name"] === tabId)
      : 0;
    return (
      <AdminProperties
        {...this.props}
        onDeactivateProperty={this.onDeactivate}
        propertiesDetailsData={this.props.propertiesDetails}
        onEditPropertiesDetails={this.onEditPropertiesDetails}
        onEditAttributes={this.onEditAttributes}
        loading={this.props.isLoadingPropDetails}
        tabId={tabId}
        selectedIndex={selectedIndex}
        onChangeTab={this.onChangeTab}
      />
    );
  }
}

const mapState = state => {
  return {
    ...state.network,
    user: state.user,
    propertiesDetails: state.property.propertiesDetails,
    isLoadingPropDetails: state.property.isLoadingPropDetails,
    isLoadingPropDeactivation: state.property.isLoadingPropDeactivation
  };
};

export default withRouter(
  connect(mapState)(withLogin(AdminPropertiesContainer))
);
