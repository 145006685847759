import React from "react";
import { useHistory } from "react-router-dom";

import { PROPERTY_CHART_PROPS } from "../../constants";
import { _exploreHandler } from "../../utils/widget";
import Narratives from "../narratives";

import { PROPERTY_CHART_TEXT } from "./chart_text";
import { ChartHOC } from "./widget-utils";
import "./property_page.scss";

const category = "lower_funnel";

const exploreHandler = _exploreHandler(category);

const PropertyLowerFunnel = React.forwardRef((props, ref) => {
  const history = useHistory();
  const {
    date_selection,
    isShowInsights,
    propertyId,
    propertyName,
    newWidgetData,
    loaderState
  } = props;

  const exploreOnClick = exploreHandler(propertyId)(history);
  const { start_date, end_date } = date_selection;
  return (
    <div className="property__widgets-container" ref={ref}>
      <div className="property__title">Lower Funnel</div>
      <div className="property__widgets two_column">
        <div>
          <ChartHOC
            decimalPlaces={0}
            kpi="approvals"
            exploreArgs={["property", "approved_leases"]}
            newWidgetData={newWidgetData}
            loaderState={loaderState}
            exploreOnClick={exploreOnClick}
            chartLabel={PROPERTY_CHART_TEXT["approvals"]["chartLabel"]}
            titleTooltipDescription={
              PROPERTY_CHART_TEXT["approvals"]["tooltip"]
            }
            dateRange={{ start: start_date, end: end_date }}
            {...PROPERTY_CHART_PROPS["approvals"]}
          />
          <div className="widget__container">
            <Narratives
              kpiId="approved_leases"
              view="property"
              isShowWidget={isShowInsights}
              name={propertyName}
            />
          </div>
        </div>

        <div>
          <ChartHOC
            kpi="approved_exe"
            exploreArgs={["property", "approved_leases_to_new_leases"]}
            newWidgetData={newWidgetData}
            loaderState={loaderState}
            exploreOnClick={exploreOnClick}
            chartLabel={PROPERTY_CHART_TEXT["approved_exe"]["chartLabel"]}
            titleTooltipDescription={
              PROPERTY_CHART_TEXT["approved_exe"]["tooltip"]
            }
            dateRange={{ start: start_date, end: end_date }}
            {...PROPERTY_CHART_PROPS["approved_exe"]}
          />
          <div className="widget__container">
            <Narratives
              kpiId="approved_leases_to_new_leases"
              view="property"
              isShowWidget={isShowInsights}
              name={propertyName}
            />
          </div>
        </div>
      </div>
      <div className="property__widgets three_column">
        <div>
          <ChartHOC
            kpi="leases_executed"
            decimalPlaces={0}
            exploreArgs={["property", "leases_executed"]}
            newWidgetData={newWidgetData}
            loaderState={loaderState}
            exploreOnClick={exploreOnClick}
            chartLabel={PROPERTY_CHART_TEXT["leases_executed"]["chartLabel"]}
            titleTooltipDescription={
              PROPERTY_CHART_TEXT["leases_executed"]["tooltip"]
            }
            dateRange={{ start: start_date, end: end_date }}
            {...PROPERTY_CHART_PROPS["leases_executed"]}
          />
          <div className="widget__container">
            <Narratives
              kpiId="new_leases"
              view="property"
              isShowWidget={isShowInsights}
              name={propertyName}
            />
          </div>
        </div>
        <div>
          <ChartHOC
            kpi="exe_ins"
            exploreArgs={["property", "new_leases_to_move_ins"]}
            newWidgetData={newWidgetData}
            loaderState={loaderState}
            exploreOnClick={exploreOnClick}
            chartLabel={PROPERTY_CHART_TEXT["exe_ins"]["chartLabel"]}
            titleTooltipDescription={PROPERTY_CHART_TEXT["exe_ins"]["tooltip"]}
            dateRange={{ start: start_date, end: end_date }}
            {...PROPERTY_CHART_PROPS["exe_ins"]}
          />

          <div className="widget__container">
            <Narratives
              kpiId="new_leases_to_move_ins"
              view="property"
              isShowWidget={isShowInsights}
              name={propertyName}
            />
          </div>
        </div>
        <div>
          <ChartHOC
            kpi="move_ins"
            decimalPlaces={0}
            exploreArgs={["property", "move_ins"]}
            newWidgetData={newWidgetData}
            loaderState={loaderState}
            exploreOnClick={exploreOnClick}
            chartLabel={PROPERTY_CHART_TEXT["move_ins"]["chartLabel"]}
            titleTooltipDescription={PROPERTY_CHART_TEXT["move_ins"]["tooltip"]}
            dateRange={{ start: start_date, end: end_date }}
            {...PROPERTY_CHART_PROPS["move_ins"]}
          />
          <div className="widget__container">
            <Narratives
              kpiId="move_ins"
              view="property"
              isShowWidget={isShowInsights}
              name={propertyName}
            />
          </div>
        </div>
      </div>
    </div>
  );
});

export default PropertyLowerFunnel;
