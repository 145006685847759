import React from "react";
import { connect } from "react-redux";

import AccountSettings from "../../components/account_settings";
import { accountSettings as actions } from "../../redux_base/actions";
import { withLogin } from "../shared/login";

class AccountSettingsContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      profileSuccess: false,
      profileApiErrors: {}
    };
  }

  componentDidMount() {
    this.props.dispatch(actions.requestSettings());
  }

  setErrorMessages = errors => {
    const validationErrors = {};
    for (let k of Object.keys(errors)) {
      validationErrors[k] = errors[k][0].message;
    }
    this.setState({ profileApiErrors: validationErrors });
  };

  setUserDataSuccess = () => {
    this.setState({ profileSuccess: true });
    setTimeout(() => {
      this.setState({ profileSuccess: false });
    }, 3000);
    this.props.dispatch(actions.requestSettings());
  };

  onSaveProfile = values => {
    const data = { ...values };
    this.props.dispatch(
      actions.postUserData(data, this.setUserDataSuccess, this.setErrorMessages)
    );
  };

  onSaveSecurity = data => {
    this.props.dispatch(actions.updateSecurityData(data));
  };

  render() {
    return (
      <AccountSettings
        onSaveSecurity={this.onSaveSecurity}
        onSaveProfile={this.onSaveProfile}
        profileSuccess={this.state.profileSuccess}
        profileApiErrors={this.state.profileApiErrors}
        {...this.props}
      />
    );
  }
}

const mapState = state => {
  return {
    ...state.network,
    ...state.accountSettings,
    us_state_list: state.geo.us_state_list,
    gb_county_list: state.geo.gb_county_list,
    office_countries: state.geo.office_countries,
    user: state.user
  };
};

export default connect(mapState)(withLogin(AccountSettingsContainer));
