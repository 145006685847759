import React from "react";
import { Box, Text } from "concrete-ui";

import Container from "../container";
import {
  formatDateString,
  formatPercent,
  valueOr
} from "../../utils/formatters";
import DynamicBoxes from "./dynamic_boxes";
import PaginationTable from "../pagination_table";
import {
  csvPercentageFormat,
  getCsvFilename,
  structurePropertyKpiData
} from "./utils";
import { nullDashDisplay } from "../../constants";

function Tours(props) {
  const { kpiData } = props;
  const {
    error,
    flag,
    estimated_tours,
    estimated_days,
    tours_per_day,
    estimated_date,
    leases_to_tours,
    exposure_offset
  } = kpiData;
  let exposureOffset = exposure_offset || 0;
  exposureOffset = Math.round(exposureOffset);
  const date = valueOr(
    estimated_date,
    nullDashDisplay,
    formatDateString,
    "MMMM dd, yyyy"
  );
  const leasesToTours = valueOr(leases_to_tours, "\u2011%", formatPercent);

  const estimatedTours = valueOr(estimated_tours, nullDashDisplay);
  const estimatedDays = valueOr(estimated_days, nullDashDisplay);
  const toursPerDay = valueOr(tours_per_day, "-");
  const boxes = [
    {
      header: `Estimated Tours to Achieve ${0 + exposureOffset}% Exposure`,
      value: estimatedTours,
      footer: `Based on Average Tours → New Leases (${leasesToTours})`
    },
    {
      header: `Estimated Days to Achieve ${0 + exposureOffset}% Exposure`,
      value: estimatedDays,
      footer: `Based on Average Tours Per Day (${toursPerDay})`
    },
    {
      header: `Estimated Date of ${0 + exposureOffset}% Exposure`,
      value: date,
      footer: "Based on Average Tours Performance"
    }
  ];
  const columns = [
    {
      Header: "Source",
      accessor: "source_name"
    },
    {
      Header: "Tours",
      accessor: "count"
    },
    {
      Header: "Percentage of Total",
      accessor: "percentage",
      csvFormatter: csvPercentageFormat,
      ValueComponent: ({ data, textProps }) => (
        <Box alignItems="center">
          <Text {...textProps}>
            {valueOr(data.percentage, "\u2011%", formatPercent)}
          </Text>
        </Box>
      )
    }
  ];
  return (
    <div>
      <Container>
        <div className="property-group__table--middle kpi-table">
          <PaginationTable
            data={structurePropertyKpiData(kpiData.table_data) || []}
            columns={columns}
            className="property-group-table"
            tableType="kpi"
            csvFileName={getCsvFilename(
              props.property.name,
              props.kpi,
              props.startDate,
              props.endDate,
              "Referral_Sources"
            )}
          />
        </div>
      </Container>
      <DynamicBoxes error={error} boxes={boxes} flag={flag} />
    </div>
  );
}

export default Tours;
