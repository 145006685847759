import cn from "classnames";
import PropTypes from "prop-types";
import React from "react";

import LoaderContainer from "../../containers/loader/index";
import AccountSecurity from "./account_security";
import ProfileTab from "./profile";
import "./account_settings.scss";

const menuItemsData = {
  profile: {
    name: "Profile"
  },
  lock: {
    name: "Change Password"
  }
};

function MenuItems({ item, selectItem, itemsOrder }) {
  const tabsUI = itemsOrder.map(id => {
    const itemData = menuItemsData[id];
    const itemClass = cn("account-settings__menu-item", {
      "account-settings__menu-item--active": id === item
    });
    return (
      <div className={itemClass} key={id} onClick={() => selectItem(id)}>
        <span className="account-settings__menu-item-text">
          {itemData.name}
        </span>
      </div>
    );
  });
  return <>{tabsUI}</>;
}
MenuItems.propTypes = {
  item: PropTypes.string.isRequired,
  itemsOrder: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectItem: PropTypes.func.isRequired
};

export default class AccountSettings extends React.PureComponent {
  static propTypes = {
    initialItem: PropTypes.oneOf(Object.keys(menuItemsData)),
    user: PropTypes.object.isRequired,
    profile: PropTypes.object.isRequired,
    itemsOrder: PropTypes.arrayOf(PropTypes.string),
    onSaveSecurity: PropTypes.func.isRequired,
    onSaveProfile: PropTypes.func.isRequired,
    profileSuccess: PropTypes.bool,
    profileApiErrors: PropTypes.object
  };

  static defaultProps = {
    initialItem: "profile",
    itemsOrder: ["profile", "lock"]
  };

  constructor(props) {
    super(props);
    this.state = {
      item: props.initialItem
    };
  }

  renderMenuItems = () => {
    switch (this.state.item) {
      case "profile":
        return (
          <ProfileTab
            onSave={this.props.onSaveProfile}
            success={this.props.profileSuccess}
            apiErrors={this.props.profileApiErrors}
            user={this.props.user}
            profile={this.props.profile}
          />
        );
      case "lock":
        return (
          <AccountSecurity
            onSave={this.props.onSaveSecurity}
            updateSuccessMessage={this.props.updateSuccessMessage}
            updateError={this.props.updateError}
            rules={this.props.rules}
            user={this.props.user}
          />
        );
      default:
        return null;
    }
  };

  selectItem = item => this.setState({ item });

  render() {
    return (
      <section>
        <div className="container">
          <div className="account-settings">
            <div className="account-settings__header">
              <div className="account-settings__title">
                {this.props.user?.account_name}
              </div>
              <div className="account-settings__subtitle">
                View and change your user profile and password.
              </div>
            </div>
            <div className="account-settings__panel">
              <div className="account-settings__menu">
                <MenuItems
                  item={this.state.item}
                  itemsOrder={this.props.itemsOrder}
                  selectItem={this.selectItem}
                />
              </div>
              <LoaderContainer />
              {this.renderMenuItems()}
            </div>
          </div>
        </div>
      </section>
    );
  }
}
