import React from "react";
import PropTypes from "prop-types";
import {
  Box,
  Divider,
  Flex,
  Icon,
  ListBox,
  Text,
  Tooltip,
  ValueLoader,
  Item
} from "concrete-ui";
import _isEqual from "lodash/isEqual";

import RenderIf from "../render_if";
import { referralSourcesTooltips } from "../referral_sources/tooltips";

class DistributedSourceLeadSources extends React.PureComponent {
  static leadSourcesType = PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
      distributed: PropTypes.bool
    })
  );

  static propTypes = {
    undistributedSources: DistributedSourceLeadSources.leadSourcesType,
    distributedSources: DistributedSourceLeadSources.leadSourcesType,
    fetchingLeadSources: PropTypes.bool,
    fetchingLeadSourcesOptions: PropTypes.bool,
    onSelect: PropTypes.func,
    selectedProperty: PropTypes.object
  };

  static defaultProps = {
    fetchingLeadSources: false,
    fetchingLeadSourcesOptions: false,
    onSelect: () => {}
  };

  constructor(props) {
    super(props);
    this.state = {
      selected: "",
      undistributedOptions: this.getOptions(props.undistributedSources),
      distributedOptions: this.getOptions(props.distributedSources)
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      !_isEqual(
        prevProps.undistributedSources,
        this.props.undistributedSources
      ) ||
      !_isEqual(prevProps.distributedSources, this.props.distributedSources)
    ) {
      const newState = {
        undistributedOptions: this.getOptions(this.props.undistributedSources),
        distributedOptions: this.getOptions(this.props.distributedSources)
      };
      let undistributedSources = !!this.props.undistributedSources
        ? this.props.undistributedSources
        : [];
      let distributedSources = !!this.props.distributedSources
        ? this.props.distributedSources
        : [];
      const sourcesIds = new Set([
        ...undistributedSources?.map(s => s.id),
        ...distributedSources?.map(s => s.id)
      ]);
      if (!sourcesIds.has(this.state.selected)) {
        newState.selected = "";
      }
      this.setState(newState);
    }
    if (!_isEqual(prevProps.selectedProperty, this.props.selectedProperty)) {
      this.setState({ selected: "" });
    }
    if (prevState.selected !== this.state.selected) {
      this.props.onSelect(this.state.selected);
    }
  }

  getUndistributedLeadsList = () =>
    this.state.undistributedOptions.map(lead => {
      const selected = lead.value === this.state.selected;
      const disabled = lead.matched;
      return (
        <Flex
          flexDirection="column"
          minWidth="0"
          key={`list-item-${lead.value}`}
        >
          <RenderIf condition={!disabled}>
            <Item
              selected={selected}
              size="large"
              onClick={() => this.selectHandler(lead)}
            >
              <Text
                textSize={2}
                ellipsis={true}
                title={lead.label}
                style={{ backgroundColor: "transparent" }}
              >
                {lead.label}
              </Text>
              <Icon.ChevronRight size={4} />
            </Item>
          </RenderIf>
          <RenderIf condition={disabled}>
            <Tooltip
              size="small"
              title="This Lead source is not available for distribution because it’s already been matched."
              placement="right"
              style={{ display: "block" }}
            >
              <Item size="large" selected={selected} disabled>
                <Text
                  textSize={2}
                  ellipsis={true}
                  title={lead.label}
                  style={{ backgroundColor: "transparent" }}
                >
                  {lead.label}
                </Text>
                <Icon.ChevronRight size={4} />
              </Item>
            </Tooltip>
          </RenderIf>
        </Flex>
      );
    });

  hasLeadSourcesForDistribution = () =>
    !!this.state.distributedOptions.length ||
    !!this.state.undistributedOptions?.filter(lead => !lead.matched).length;

  getOptions(sources) {
    let response = [];
    if (sources) {
      response = sources.map(s => ({
        label: s.label,
        value: s.id,
        distributed: s.distributed,
        count: s.count
      }));
      response.sort((a, b) => b.count - a.count);
    }
    return response;
  }

  selectHandler = option => {
    this.setState({ selected: option.value });
  };

  render() {
    const {
      undistributedSources,
      distributedSources,
      selectedProperty
    } = this.props;
    const fetchingLeadsData =
      this.props.fetchingLeadSourcesOptions || this.props.fetchingLeadSources;
    const availableLeadSources = this.hasLeadSourcesForDistribution();
    return (
      <Flex flexDirection="column" minWidth="16.5rem" alignItems="center">
        <Flex flexDirection="column" py={4} px={5}>
          <Flex pb={3} alignItems="center">
            <Text textSize={2} $fontWeight="medium" mr={2}>
              Lead Sources
            </Text>
            <Tooltip
              size="small"
              title={referralSourcesTooltips["leadSources"]}
              placement="top"
            >
              <Icon.Tooltip size="1rem" color="text.white.1" />
            </Tooltip>
          </Flex>
          <Flex pb={3} alignItems="center">
            <Text textSize={2} $fontWeight="regular" $color="text.gray.2">
              Select the Lead Source(s) you wish to distribute.
            </Text>
          </Flex>
        </Flex>
        <Divider variant="horizontal" bg="gray.4" flexShrink={0} />
        <Box
          className="concrete-scrollbar"
          flexDirection="column"
          overflow="auto"
          width="100%"
        >
          <RenderIf condition={!selectedProperty && !fetchingLeadsData}>
            <Flex pt={4} px={5}>
              <Text textSize={2} $fontWeight="regular" $color="text.gray.1">
                Select a property to show available Lead Sources to distribute.
              </Text>
            </Flex>
          </RenderIf>
          <RenderIf condition={fetchingLeadsData}>
            <Flex py={4} px={5} flexDirection="column">
              <ValueLoader mb={2} textSize={5} />
            </Flex>
          </RenderIf>
          <RenderIf
            condition={
              !!selectedProperty &&
              !fetchingLeadsData &&
              !!undistributedSources &&
              !!distributedSources
            }
          >
            <RenderIf condition={!availableLeadSources}>
              <RenderIf condition={!!undistributedSources?.length}>
                <Flex pt={4} px={5}>
                  <Text textSize={2} $fontWeight="regular" $color="text.gray.1">
                    There are no Lead Sources available for distribution because
                    they’ve already been matched.
                  </Text>
                </Flex>
              </RenderIf>
              <RenderIf condition={!undistributedSources?.length}>
                <Flex pt={4} px={5}>
                  <Text textSize={2} $fontWeight="regular" $color="text.gray.1">
                    There are no Lead Sources available for distribution because
                    none have been associated with expenses yet.
                  </Text>
                </Flex>
              </RenderIf>
            </RenderIf>
            <RenderIf condition={!!availableLeadSources}>
              <RenderIf condition={!!undistributedSources?.length}>
                <Box flexDirection="column" p={4} mx={1}>
                  <Flex pb={2} px={1}>
                    <Text
                      textSize={2}
                      $fontWeight="regular"
                      $color="text.gray.1"
                    >
                      Undistributed
                    </Text>
                  </Flex>
                  {this.getUndistributedLeadsList()}
                </Box>
              </RenderIf>
              <RenderIf condition={!!distributedSources?.length}>
                <RenderIf condition={!!undistributedSources?.length}>
                  <Divider variant="horizontal" bg="gray.4" flexShrink={0} />
                </RenderIf>
                <ListBox
                  title="Distributed"
                  options={this.state.distributedOptions}
                  value={this.state.selected}
                  onClick={this.selectHandler}
                  p={4}
                  mx={1}
                />
              </RenderIf>
            </RenderIf>
          </RenderIf>
        </Box>
      </Flex>
    );
  }
}

export default DistributedSourceLeadSources;
