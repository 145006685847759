import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import CreateAlert from "../../components/create_alert";
import {
  alertsActions,
  projectActions,
  propertyGroup,
  propertyActions,
  user
} from "../../redux_base/actions";
import { withLogin } from "../shared/login";

class CreateAlertContainer extends React.PureComponent {
  static propTypes = {
    properties: PropTypes.object.isRequired,
    kpiPropertiesOptions: PropTypes.array.isRequired,
    isPropertyLoading: PropTypes.bool.isRequired,
    propertyGroups: PropTypes.array.isRequired,
    kpiGroupOptions: PropTypes.array.isRequired,
    isGroupLoading: PropTypes.bool.isRequired,
    isPropsGroupsLoading: PropTypes.bool.isRequired,
    userPropsGroups: PropTypes.object,
    isCreatingSuccessful: PropTypes.bool.isRequired
  };

  componentDidMount() {
    if (!this.props.isPropertyLoading || !this.props.isGroupLoading) {
      this.props.dispatch(propertyActions.requestProperties());
    }

    this.props.dispatch(user.requestAlertsAssociates());
  }

  componentDidUpdate() {
    if (this.props.isCreatingSuccessful) {
      this.props.dispatch(
        alertsActions.updateState({ isCreatingSuccessful: false })
      );
      this.props.history.push("/alerts");
    }
  }

  get propertyGroups() {
    const propertyGroups = {};
    this.props.propertyGroups.forEach(g => {
      propertyGroups[g.group_id] = g;
    });
    return propertyGroups;
  }

  getMember = (type, id) => {
    if (type === "property") {
      this.props.dispatch(projectActions.requestPropertyMembers({ type, id }));
    } else {
      this.props.dispatch(
        propertyGroup.requestPropertyGroupMembers({ type, id })
      );
    }
  };

  createAlertHandler = data => {
    this.props.dispatch(alertsActions.createAlert(data));
  };

  render() {
    return (
      <CreateAlert
        properties={this.props.properties}
        kpiPropertiesOptions={this.props.kpiPropertiesOptions}
        propertyGroups={this.propertyGroups}
        kpiGroupOptions={this.props.kpiGroupOptions}
        getMember={this.getMember}
        isPropsGroupsLoading={this.props.isPropsGroupsLoading}
        user={this.props.user}
        userPropsGroups={this.props.userPropsGroups}
        createAlertHandler={this.createAlertHandler}
        isFetching={this.props.isFetching}
      />
    );
  }
}

const mapState = state => ({
  properties: state.properties.items,
  kpiPropertiesOptions: state.properties.kpiPropertiesOptions,
  isPropsGroupsLoading: state.user.isPropsGroupsLoading,
  isPropertyLoading: state.properties.propertyKPIsIsLoading,
  fetchingMemberProperty: state.properties.fetchingMemberProperty,
  propertyGroups: state.propertyGroup.property_groups,
  kpiGroupOptions: state.properties.kpiGroupsOptions,
  isGroupLoading: state.propertyGroup.propertyGroupsIsLoading,
  fetchingMemberGroup: state.propertyGroup.fetchingMemberGroup,
  user: state.user,
  userPropsGroups: state.user.userPropsGroups,
  isFetching: state.network.isFetching,
  isCreatingSuccessful: state.alerts.isCreatingSuccessful
});

export default connect(mapState)(withLogin(CreateAlertContainer));
