export const configCreatePassword = formItems => ({
  fields: {
    password_1: {
      validators: {
        isRequired: {
          message: "Password is required."
        }
      }
    },
    password_2: {
      validators: {
        condition: {
          when: () => !!formItems.password_1,
          then: {
            isRequired: {
              message: "Confirmed password is required."
            },
            oneOf: {
              value: [formItems.password_1, null],
              message: "Passwords do not match."
            }
          }
        }
      }
    }
  }
});
