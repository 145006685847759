/**
 * This function was added because CircleCI adds trailing slashes to URLs that are put into ENV Vars
 * The function returns the BASE_URL if present in the environment without a trailing slash.
 * @returns {string | string}
 */
const get_base_url = () => {
  let base_url = process.env.BASE_URL || "http://localhost:8000";
  if (base_url[base_url.length - 1] == "/") {
    base_url = base_url.substring(0, base_url.length - 1);
  }
  return base_url;
};

const get_rails_base_url = () => {
  let base_url = process.env.RAILS_BASE_URL || "http://localhost:3000";
  if (base_url[base_url.length - 1] == "/") {
    base_url = base_url.substring(0, base_url.length - 1);
  }
  return base_url;
};

export const URLS = {
  base: get_base_url(),
  rails_base: get_rails_base_url(),
  ver: process.env.API_VERSION || "/api/v1",
  sisense_ver: "/api/v1",
  alerts: "/api/alerts/",
  analytics: "/analytics/",
  expenses: "/proxy/expenses/",
  vendors: "/proxy/vendors/",
  login: "/token/",
  user_profile: "/account-user",
  refresh: "/token/refresh/",
  portfolio: "/portfolio",
  property_group: "/property-group",
  locations: "/locations",
  asset_managers: "/asset_managers",
  market: "/market",
  narratives: "/narratives",
  kpi: "/kpi",
  project: "/projects",
  tutorial: "/tutorial",
  dashboard: "/dashboard/",
  sisense: "/sisense",
  properties: "/properties",
  users: "/users",
  proxy: "/proxy",
  widget_data: "/proxy/widget-data",
  referral_sources_matching: "/proxy/referral-sources/matching/",
  create_report: "/proxy/create-report",
  create_budget: "/proxy/create-budget",
  properties_details: "/projects/properties_details",
  deactivate_property: "/projects/deactivation_date",
  property_owners: "/projects/property_owners",
  banners: "/banners",
  connections: "/connections",
  reset_password: "/users/reset-password/"
};

export const API_URL_PREFIX = `${URLS.base}${URLS.ver}`;
export const RAILS_API_URL_PREFIX = `${URLS.rails_base}`;
export const SISENSE_API_URL_PREFIX = `${process.env.SISENSE_SERVER}${URLS.sisense_ver}`;

export const createAPIUrl = path => {
  if (
    path.includes("alerts") ||
    path.includes("tags")
    // path.includes("projects/properties_details")
  ) {
    return `${RAILS_API_URL_PREFIX}${path}`;
  } else {
    return `${URLS.base}${URLS.ver}${path}`;
  }
};

export const createFEUrl = path => {
  return `${window.location.origin}${path}`;
};

export const createAjaxAction = (
  baseActionType,
  endpoint,
  query = "",
  parseNaNInfinity = false
) => params => {
  let queryString = typeof query === "function" ? query(params) : query;
  let url;
  if (
    endpoint.includes("alerts") ||
    // endpoint.includes("projects/properties_details") ||
    endpoint.includes("tags")
  ) {
    url = `${RAILS_API_URL_PREFIX}${endpoint}${queryString}`;
  } else {
    url = `${API_URL_PREFIX}${endpoint}${queryString}`;
  }

  let actionType = "FETCH_API_GET";

  if (baseActionType.startsWith("AJAX_DELETE")) {
    actionType = "FETCH_API_DELETE";
  } else if (baseActionType.startsWith("AJAX_PATCH")) {
    actionType = "FETCH_API_PATCH";
  } else if (baseActionType.startsWith("AJAX_POST")) {
    actionType = "FETCH_API_POST";
  }
  return {
    ...(typeof params === "object" ? params : {}),
    type: actionType,
    url,
    baseActionType,
    parseNaNInfinity: parseNaNInfinity
  };
};
