import React from "react";
import cn from "classnames";

import Container from "../container";
import { Error2 } from "../../icons";

function Box(props) {
  const { header, value, footer } = props;
  return (
    <div className="property-kpi-page__box">
      <Error2 className="property-kpi-page__error-icon" />
      <div className="property-kpi-page__box-title">{header}</div>
      <div className="property-kpi-page__box-data">{value}</div>
      <div className="property-kpi-page__box-text">{footer}</div>
    </div>
  );
}

export default function DynamicBoxes(props) {
  const { error, boxes, flag } = props;
  const classes = cn("property-kpi-page__additional-data", {
    "property-kpi-page__additional-data--error": error,
    "property-kpi-page__additional-data--flag": flag
  });
  const boxesComponents = boxes.map(props => <Box {...props} />);
  return (
    <div className={classes}>
      <div className="property-kpi-page__divider" />
      <Container>
        <div className="property-kpi-page__error">
          <Error2 className="property-kpi-page__error-icon" />
          {error}
        </div>
        <div className={"property-kpi-page__flag"}>{flag}</div>
        <div className="property-kpi-page__boxes">{boxesComponents}</div>
        <div className="property-kpi-page__note">
          All Performance Estimations Based on Currently Selected Dates
        </div>
      </Container>
    </div>
  );
}
