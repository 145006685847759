import React from "react";
import { Provider } from "react-redux";
import storeFunc from "./redux_base/store";
import RemarkableRouter from "./router";
import { PersistGate } from "redux-persist/integration/react";
import GaGate from "./gates/ga";
import TitleGate from "./gates/title";
import UIStringsGate from "./gates/ui_strings";
import SessionGate from "./gates/session";
import NotificationsContainer from "./containers/notifications";
import PosthogContainer from "./containers/posthog";
import { ThemeProvider, GlobalStyle, theme } from "concrete-ui";
import { Provider as RollbarProvider, ErrorBoundary } from "@rollbar/react";

const rollbarEnabled =
  process.env.ROLLBAR_TOKEN !== undefined &&
  process.env.ROLLBAR_TOKEN.length > 0;

const RollbarEnabled = ({ children }) =>
  rollbarEnabled ? (
    <RollbarProvider
      config={{
        accessToken:
          process.env.ROLLBAR_ACTIVE == "YES" ? process.env.ROLLBAR_TOKEN : "",
        environment: process.env.ENVIRONMENT,
        enabled: process.env.ROLLBAR_ACTIVE == "YES" ? true : false
      }}
    >
      <ErrorBoundary>{children}</ErrorBoundary>
    </RollbarProvider>
  ) : (
    children
  );

const { store, persistor } = storeFunc();

export default class App extends React.Component {
  render() {
    return (
      <RollbarEnabled>
        <Provider store={store}>
          <ThemeProvider theme={theme}>
            <GlobalStyle />
            <PosthogContainer />
            <TitleGate>
              <GaGate>
                <PersistGate loading={null} persistor={persistor}>
                  <SessionGate>
                    <UIStringsGate>
                      <NotificationsContainer />
                      <RemarkableRouter />
                    </UIStringsGate>
                  </SessionGate>
                </PersistGate>
              </GaGate>
            </TitleGate>
          </ThemeProvider>
        </Provider>
      </RollbarEnabled>
    );
  }
}

export { store };
