import React from "react";
import ConfirmModal from "../confirm_modal";
import { Link } from "concrete-ui";
import { ZENDESK_MANAGE_PERMISSIONS } from "../../constants";

function MembersConfirmModal(props) {
  const { type, onCloseConfirm, confirm, user, property, role } = props;
  const ModalTexts = {
    remove: {
      title: "Remove User?",
      text:
        "Are you sure you want to remove" +
        " " +
        `${user}` +
        " access to " +
        `${property}` +
        "?"
    },
    changeRole: {
      title: "Change Role?",
      text:
        "Are you sure you want to" +
        " " +
        `${role == "admin" ? "grant" : "revoke"}` +
        " " +
        `${user}` +
        " access to " +
        `${property}` +
        " Admin features? ",
      roleHyperlink: (
        <Link href={ZENDESK_MANAGE_PERMISSIONS} target="_blank">
          Learn more.
        </Link>
      )
    }
  };

  return (
    <ConfirmModal
      title={ModalTexts[type].title}
      content={ModalTexts[type].text}
      hyperlink={ModalTexts[type].roleHyperlink}
      addBackground={false}
      confirmButtonText="Confirm"
      cancelButtonText="Cancel"
      onConfirm={() => {
        confirm();
        onCloseConfirm();
      }}
      onClose={() => onCloseConfirm()}
    />
  );
}

export default MembersConfirmModal;
