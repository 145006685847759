export const createBudgetConfig = {
  fields: {
    // Section 1
    property_ids: {
      validators: {
        minLength: {
          message: "Property selection is required.",
          value: 1
        }
      }
    }
  }
};
