import cn from "classnames";
import PropTypes from "prop-types";
import React from "react";
import DayPickerInput from "react-day-picker/DayPickerInput";

import { Button, Flex, Icon, Text } from "concrete-ui";
import { Calendar } from "../../icons";
import { formatDateWithTokens } from "../../utils/formatters";

import "./date_range.scss";
import {
  differenceInCalendarDays,
  endOfYesterday,
  subDays
} from "../../utils/dates";

export default class DateRange extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    startDate: PropTypes.object,
    endDate: PropTypes.object,
    onChange: PropTypes.func.isRequired,
    dateFormat: PropTypes.string,
    isDisabled: PropTypes.bool,
    disabledRange: PropTypes.object,
    minRange: PropTypes.number,
    theme: PropTypes.oneOf(["", "modern", "form-field"])
  };

  static defaultProps = {
    dateFormat: "MM/dd/yy",
    isDisabled: false
  };

  constructor(props) {
    super(props);
    this.dayPicker = React.createRef();
    this.node = React.createRef();
    this.state = {
      select: "from",
      isOpen: false,
      startDate: props.startDate,
      endDate: props.endDate
    };
  }

  getbelowMinRange = (startDate, endDate) => {
    return (
      this.props.minRange &&
      differenceInCalendarDays(endDate, startDate) < this.props.minRange - 1
    );
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.endDate !== this.props.endDate ||
      prevProps.startDate !== this.props.startDate
    ) {
      this.setState({
        startDate: this.props.startDate,
        endDate: this.props.endDate
      });
    }
    if (
      (prevState.endDate !== this.props.endDate ||
        prevState.startDate !== this.props.startDate ||
        prevProps.minRange !== this.props.minRange) &&
      this.getbelowMinRange(this.props.startDate, this.props.endDate)
    ) {
      const newStartDate = subDays(this.props.endDate, this.props.minRange);
      this.props.onChange(newStartDate, this.props.endDate);
    }
  }

  get componentInput() {
    if (this.props.theme === "modern") {
      return DateRangeInputModern;
    } else {
      return DateRangeInput;
    }
  }

  renderNavBar = ({ month, onPreviousClick, onNextClick }) => {
    const monthStr = formatDateWithTokens(month, "MMMM yyyy");
    return (
      <>
        <div className="date-range__nav-bar">
          <div
            className="date-range__nav-button date-range__nav-button--prev"
            onClick={() => onPreviousClick()}
          />
          <div className="date-range__current-month">{monthStr}</div>
          <div
            className="date-range__nav-button date-range__nav-button--next"
            onClick={() => onNextClick()}
          />
        </div>
        {this.getbelowMinRange(this.state.startDate, this.state.endDate) && (
          <Flex mt={5} mb={1} alignItems="center" justifyContent="center">
            <Icon.Error width={3} height={3} $color="orange.1" />
            <Text
              textSize={1}
              $color="text.orange.0"
              $fontWeight="regular"
              ml={2}
            >
              Must select at least 7 days for this view.
            </Text>
          </Flex>
        )}
      </>
    );
  };

  renderFooter = () => {
    return (
      <div className="date-range__footer">
        <Button size="small" variant="ghost" onClick={this.onCancel}>
          Cancel
        </Button>
        <Button
          disabled={this.getbelowMinRange(
            this.state.startDate,
            this.state.endDate
          )}
          size="small"
          variant="primary"
          onClick={this.onApply}
        >
          Apply
        </Button>
      </div>
    );
  };

  renderDay = (day, modifiers) => {
    const classes = cn("date-range__day", {
      "date-range__day--start": modifiers.start,
      "date-range__day--end": modifiers.end,
      "date-range__day--today": modifiers.today,
      "date-range__day--disabled": modifiers.disabled
    });
    return <div className={classes}>{day.getDate()}</div>;
  };

  getDisabledRange = () => {
    const response_range = {
      after: endOfYesterday(new Date())
    };
    if ("disabledRange" in this.props) {
      const start = new Date(this.props.disabledRange.campaign_start);
      const end = this.props.disabledRange.campaign_end
        ? new Date(this.props.disabledRange.campaign_end)
        : new Date();
      response_range.after = end;
      response_range.before = start;
    }
    return response_range;
  };

  handleClick = e => {
    if (!this.node.current.contains(e.target)) {
      this.onCancel();
    }
  };

  showDayPicker = () => {
    if (!this.props.isDisabled) {
      document.addEventListener("mousedown", this.handleClick, false);
      this.dayPicker.current.showDayPicker();
      this.setState({ isOpen: true });
    }
  };

  hideDayPicker = () => {
    document.removeEventListener("mousedown", this.handleClick, false);
    this.dayPicker.current.hideDayPicker();
    this.setState({ isOpen: false, select: "from" });
  };

  onCancel = () => {
    this.setState({
      startDate: this.props.startDate,
      endDate: this.props.endDate
    });
    this.hideDayPicker();
  };

  onApply = () => {
    this.props.onChange(this.state.startDate, this.state.endDate);
    this.hideDayPicker();
  };

  onDayClick = date => {
    let startDate = this.state.startDate;
    let endDate = this.state.endDate;
    if (this.state.select === "from") {
      startDate = date;
      if (date > this.state.endDate && this.state.endDate) {
        startDate = this.state.endDate;
        endDate = date;
      }
      this.setState({ startDate, endDate, select: "to" });
    }
    if (this.state.select === "to") {
      let endDate = date;
      if (date < this.state.startDate) {
        startDate = date;
        endDate = this.state.startDate;
      }
      this.setState({ startDate, endDate, select: "from" });
    }
  };

  render() {
    const { className } = this.props;
    const { startDate, endDate } = this.state;
    const modifiers = { start: startDate, end: endDate };
    const disabledRange = this.getDisabledRange();
    const classes = cn("date-range", className);
    return (
      <div className={classes} ref={this.node}>
        <DayPickerInput
          ref={this.dayPicker}
          classNames={{
            overlay: "",
            overlayWrapper: "date-range__overlay-wrapper"
          }}
          dayPickerProps={{
            selectedDays: [startDate, { from: startDate, to: endDate }],
            disabledDays: disabledRange,
            month: disabledRange.after,
            toMonth: disabledRange.after,
            fromMonth: disabledRange.before,
            modifiers,
            numberOfMonths: 1,
            onDayClick: this.onDayClick,
            navbarElement: this.renderNavBar,
            renderDay: this.renderDay,
            captionElement: this.renderFooter,
            classNames: {
              container: "date-range__day-picker",
              wrapper: "date-range__month-wrapper",
              month: "date-range__month",
              weekdaysRow: "date-range__weekdays",
              weekday: "date-range__weekday",
              weekdays: "",
              body: "date-range__days",
              week: "date-range__week",
              day: "date-range__day-wrapper",
              today: "today",
              selected: "date-range__day-wrapper--selected",
              start: "date-range__day-wrapper--start",
              disabled: "disabled",
              outside: "date-range__day-wrapper--outside"
            }
          }}
          hideOnDayClick={false}
          component={this.componentInput}
          inputProps={{
            dateFormat: this.props.dateFormat,
            isDisabled: this.props.isDisabled,
            startDate: this.state.startDate,
            endDate: this.state.endDate,
            select: this.state.select,
            isOpen: this.state.isOpen,
            showDayPicker: this.showDayPicker
          }}
        />
      </div>
    );
  }
}

class DateRangeInput extends React.PureComponent {
  render() {
    const {
      dateFormat,
      startDate,
      endDate,
      isOpen,
      select,
      isDisabled,
      showDayPicker
    } = this.props;
    let startDateStr = dateFormat;
    if (startDate) {
      startDateStr = formatDateWithTokens(startDate, dateFormat);
    }
    let endDateStr = dateFormat;
    if (endDate) {
      endDateStr = formatDateWithTokens(endDate, dateFormat);
    }
    const classNameFrom = cn("date-range__value", {
      "date-range__value--selecting": select === "from" && isOpen,
      "date-range__value--placeholder": !startDate
    });
    const classNameTo = cn("date-range__value", {
      "date-range__value--selecting": select === "to" && isOpen,
      "date-range__value--placeholder": !endDate
    });
    const classes = cn("date-range__input", {
      "date-range__input--disabled": isDisabled
    });
    return (
      <div className={classes} onClick={showDayPicker}>
        <span className={classNameFrom}>{startDateStr}</span>
        <span>to</span>
        <span className={classNameTo}>{endDateStr}</span>
      </div>
    );
  }
}

class DateRangeInputModern extends React.PureComponent {
  render() {
    const {
      dateFormat,
      startDate,
      endDate,
      isOpen,
      select,
      isDisabled,
      showDayPicker
    } = this.props;
    let startDateStr = dateFormat;
    if (startDate) {
      startDateStr = formatDateWithTokens(startDate, dateFormat);
    }
    let endDateStr = dateFormat;
    if (endDate) {
      endDateStr = formatDateWithTokens(endDate, dateFormat);
    }
    const classNameFrom = cn("date-range__value", {
      "date-range__value--selecting": select === "from" && isOpen,
      "date-range__value--placeholder": !startDate
    });
    const classNameTo = cn("date-range__value", {
      "date-range__value--selecting": select === "to" && isOpen,
      "date-range__value--placeholder": !endDate
    });
    const classes = cn("date-range__input date-range__input--modern", {
      "date-range__input--disabled": isDisabled
    });
    return (
      <div className={classes} onClick={showDayPicker}>
        <Calendar className="date-range__icon" />
        <div className="date-range__values">
          <span className={classNameFrom}>{startDateStr}</span>
          <span>to</span>
          <span className={classNameTo}>{endDateStr}</span>
        </div>
      </div>
    );
  }
}
