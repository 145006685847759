import { createAjaxAction, URLS } from "./helpers";

export default {
  updateState: state => ({
    type: "RESET_PASSWORD_UPDATE_STATE",
    state
  }),
  resetPassword: data =>
    createAjaxAction(
      "AJAX_POST_RESET_PASSWORD",
      `${URLS.reset_password}`
    )({ body: data })
};
