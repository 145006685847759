import React from "react";
import {
  Flex,
  TextInput,
  Icon,
  DateRangeSelector,
  RadioGroup,
  Select
} from "concrete-ui";
import {
  contractTermsOpts,
  expenseTypeOpts,
  monthOpts,
  repeatsOpts
} from "../options";
import { ActualExpenseList } from "../actual-expense-list";
import {
  dateToDatePicker,
  dateToValidation,
  inputNumberFormatter,
  inputStringFormatter,
  inputTextWithTooltip,
  formatSuggestedValue
} from "../utils";
import { expenseTooltips } from "../tooltips";
import { concreteErrorVariant } from "../../../constants";

export const RecurringExpense = ({ formItems, getItemProps, errors }) => {
  const costBudgetedProps = getItemProps("cost_budgeted", {
    formatInputToValidator: inputNumberFormatter,
    formatValidatorToInput: inputStringFormatter
  });
  const startDateProps = getItemProps("start_date", {
    formatInputToValidator: dateToValidation,
    formatValidatorToInput: dateToDatePicker
  });
  const endDateProps = getItemProps("end_date", {
    formatInputToValidator: dateToValidation,
    formatValidatorToInput: dateToDatePicker
  });
  const contractTermsProps = getItemProps("terms");
  const expenseTypeProps = getItemProps("type");
  const actualExpenseProps = getItemProps("actual_expenses");
  const suggestedExpenseValue = formatSuggestedValue(formItems);

  return (
    <>
      <TextInput
        size="large"
        iconLeft={<Icon.Dollar />}
        label={inputTextWithTooltip(
          "Monthly Budgeted Expense",
          expenseTooltips["budgetedExpense"]["recurring"]
        )}
        {...costBudgetedProps}
        variant={errors.cost_budgeted && concreteErrorVariant}
        message={errors.cost_budgeted}
      />
      <DateRangeSelector
        startOptions={{
          initialDate: startDateProps.value,
          onDateSelect: startDateProps.onChange,
          label: "Contract Start Date",
          startOrEnd: "start",
          ml: -3,
          errorMessage: errors.start_date
        }}
        endOptions={{
          initialDate: endDateProps.value,
          onDateSelect: endDateProps.onChange,
          label: "Contract End Date",
          startOrEnd: "end",
          mr: -3,
          errorMessage: errors.end_date
        }}
        // description="Expense will be applied across duration selected."
        mb={1}
        mt={5}
      />
      <RadioGroup
        mt={5}
        title="Contract Terms"
        options={contractTermsOpts}
        onChange={value => () => contractTermsProps.onChange(value)}
        activeRadioId={contractTermsProps.value}
        itemsProps={{ flexDirection: "column" }}
        errorMessage={errors.terms}
      />
      <RadioGroup
        title="Contract Type"
        options={expenseTypeOpts}
        onChange={value => () => expenseTypeProps.onChange(value)}
        activeRadioId={expenseTypeProps.value}
        itemsProps={{ flexDirection: "column" }}
        errorMessage={errors.type}
        mt={5}
        mb={8}
      />
      <ActualExpenseList
        onChange={actualExpenseProps.onChange}
        formItems={formItems}
        suggestedExpenseValue={suggestedExpenseValue}
        errors={errors}
      />
    </>
  );
};
