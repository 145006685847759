import {
  Box,
  Divider,
  Flex,
  Icon,
  ModeSwitch,
  Table,
  Text,
  ToggleSwitch,
  Tooltip
} from "concrete-ui";

import _isEmpty from "lodash/isEmpty";
import PropTypes from "prop-types";
import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import {
  DATE_FORMAT,
  INCLUDES_AUTOMATED_ACTUAL_EXPENSES_MSG,
  INFINITY_STRING,
  PROJECTED_MARKETING_RETURN_INFO,
  ZENDESK_ZERO_BUDGETED_EXPENSE_MARKETING_RETURN,
  ZERO_BUDGETED_EXPENSE_MARKETING_RETURN_MSG
} from "../../constants";
import { formatCondDecimal } from "../../utils/formatters";
import { dateFormat } from "../../utils/dates";
import { propertyActions } from "../../redux_base/actions";
import ChannelAnalysisSummary, {
  dataItemType as summaryDataType
} from "../channel_analysis_summary";
import checkFlag from "../../utils/posthog";
import RenderIf from "../render_if";
import ChannelAnalysisRankings from "../channel_analysis_rankings";
import ChannelAnalysisFilter from "../property_page/channel_analysis_filter";
import FilterTags from "../expenses/filter_tags";
import { channelAnalysisTableTabs } from "./config";
import {
  formatCurrency,
  formatIntNumber,
  formatPercent,
  getValueFormatter
} from "./formatters";
import {
  ActualCostPersValue,
  ActualExpenseValue,
  BudgetCostPersValue,
  NWVValue,
  NewWebsiteVisitorsSummaryValue
} from "./values";

const formatProjectedMarketingReturn = value => {
  if (value === Infinity) {
    return (
      <>
        <Text verticalAlign="middle" textSize={4}>
          {INFINITY_STRING}
        </Text>
        <Box ml={1} verticalAlign="middle" display="inline-block">
          <Tooltip
            title={ZERO_BUDGETED_EXPENSE_MARKETING_RETURN_MSG}
            display="inline-block"
            size="small"
            iconSize="small"
            linkText="Learn more."
            link={ZENDESK_ZERO_BUDGETED_EXPENSE_MARKETING_RETURN}
            tooltipProps={{
              onClick: e => e.stopPropagation()
            }}
            linkProps={{
              target: "_blank"
            }}
          />
        </Box>
      </>
    );
  }

  return formatCondDecimal(value) + "x";
};

class ChannelAnalysis extends React.Component {
  static propTypes = {
    loading: PropTypes.bool,
    tableLoading: PropTypes.bool,
    expensesLastUpdated: PropTypes.object, // date
    attributionsLastUpdated: PropTypes.string, // date
    onTabsToggle: PropTypes.func,
    onShowDistributedSource: PropTypes.func,
    summaryData: PropTypes.shape({
      total_investment: summaryDataType,
      new_lease_revenue: summaryDataType,
      avg_cpl: summaryDataType,
      avg_cpt: summaryDataType,
      avg_cpla: summaryDataType,
      avg_cpnl: summaryDataType
    }),
    tableData: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    distributedTableData: PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.object
    ]),
    tableSummaryData: PropTypes.object,
    propertyName: PropTypes.string,
    startDate: PropTypes.instanceOf(Date),
    endDate: PropTypes.instanceOf(Date),
    rankingsData: PropTypes.object,
    distributedRankingsData: PropTypes.object,
    exposureRankingsData: PropTypes.object,
    propertyId: PropTypes.string,
    byExpenseFilters: PropTypes.array,
    isAdmin: PropTypes.bool
  };

  static defaultProps = {
    onTabsToggle() {},
    onShowDistributedSource() {},
    summaryData: {},
    tableData: {},
    distributedTableData: {}
  };

  constructor(props) {
    super(props);
    this.state = {
      showDistributed: false,
      activeTab: "event",
      dummyLoader: false,
      filters: {}
    };
    this.dropdownFilters = React.createRef();
  }

  get tableColumns() {
    return [
      {
        Header: "Sources",
        accessor: "source",
        columnAggregate: "count",
        minWidth: "200px",
        aggregateSubtitle: "Total",
        ValueComponent: ({ data }) => {
          return (
            <Flex>
              <Text textSize={2} $fontWeight="regular" $color="text.white.1">
                {data.source}
              </Text>
              <RenderIf
                condition={
                  this.state.showDistributed && data.lead_name != false
                }
              >
                <Tooltip
                  ml={2}
                  title={"Distributed from " + data.lead_name}
                  size="mini"
                  placement="right"
                >
                  <Icon.Tooltip size={3} my="auto" />
                </Tooltip>
              </RenderIf>
            </Flex>
          );
        }
      },
      {
        Header: "New Website Visitors",
        accessor: "nwv",
        justify: "right",
        aggregateSubtitle: "Total",
        valueFormatter: formatIntNumber,
        ValueComponent: props => (
          <NWVValue isAdmin={this.props.isAdmin} {...props} />
        ),
        AggregatedValueComponent: NewWebsiteVisitorsSummaryValue,
        sortType: "number"
      },
      {
        Header: "Leads",
        accessor: "leads",
        justify: "right",
        aggregateSubtitle: "Total",
        valueFormatter: this.state.showDistributed
          ? formatCondDecimal
          : formatIntNumber,
        sortType: "number"
      },
      {
        Header: "Tours",
        accessor: "tours",
        justify: "right",
        aggregateSubtitle: "Total",
        valueFormatter: this.state.showDistributed
          ? formatCondDecimal
          : formatIntNumber,
        sortType: "number"
      },
      {
        Header: "Lease Applications",
        accessor: "lease_applications",
        justify: "right",
        aggregateSubtitle: "Total",
        valueFormatter: this.state.showDistributed
          ? formatCondDecimal
          : formatIntNumber,
        sortType: "number"
      },
      {
        Header: "Approved Leases",
        accessor: "approved_leases",
        justify: "right",
        aggregateSubtitle: "Total",
        valueFormatter: this.state.showDistributed
          ? formatCondDecimal
          : formatIntNumber,
        sortType: "number"
      },
      {
        Header: "Leads\xa0\u2192 New\xa0Leases",
        accessor: "leads_new_leases",
        paddingX: 2,
        colWidth: "130px",
        justify: "right",
        aggregateSubtitle: "Average",
        valueType: "percentage",
        valueFormatter: formatPercent,
        sortType: "number"
      },
      {
        Header: "New\xa0Website Visitors\xa0\u2192\xa0Leads",
        accessor: "nwv_leads",
        paddingX: 2,
        colWidth: "170px",
        justify: "right",
        aggregateSubtitle: "Average",
        valueType: "percentage",
        valueFormatter: formatPercent,
        sortType: "number"
      },
      {
        Header: "Leads\xa0\u2192\xa0Tours",
        accessor: "leads_tours",
        paddingX: 2,
        colWidth: "150px",
        justify: "right",
        aggregateSubtitle: "Average",
        valueType: "percentage",
        valueFormatter: formatPercent,
        sortType: "number"
      },
      {
        Header: "Tours\xa0\u2192 Lease\xa0Applications",
        accessor: "tours_lease_applications",
        paddingX: 2,
        justify: "right",
        aggregateSubtitle: "Average",
        valueType: "percentage",
        valueFormatter: formatPercent,
        sortType: "number"
      },
      {
        Header: "Lease\xa0Applications\xa0\u2192 Approved\xa0Leases",
        accessor: "lease_apps_app_leases",
        paddingX: 2,
        colWidth: "240px",
        justify: "right",
        aggregateSubtitle: "Average",
        valueType: "percentage",
        valueFormatter: formatPercent,
        sortType: "number"
      },
      {
        Header: "Approved\xa0Leases\xa0\u2192 New\xa0Leases",
        accessor: "app_leases_new_leases",
        paddingX: 2,
        colWidth: "210px",
        justify: "right",
        aggregateSubtitle: "Average",
        valueType: "percentage",
        valueFormatter: formatPercent,
        sortType: "number"
      },
      {
        Header: "Budgeted Expenses",
        accessor: "budget",
        paddingX: 1,
        justify: "right",
        aggregateSubtitle: "Total",
        valueFormatter: formatCurrency,
        ValueComponent: props => (
          <BudgetCostPersValue
            {...props}
            isHighlightText={!this.onlyMissingExpenses()}
            includeTooltip={!this.onlyMissingExpenses()}
          />
        ),
        AggregatedValueComponent: props => (
          <BudgetCostPersValue
            {...props}
            isHighlightText={!this.onlyMissingExpenses()}
            includeTooltip={!this.onlyMissingExpenses()}
          />
        )
      },
      {
        Header: "Actual Expenses",
        accessor: "actual_budget",
        paddingX: 1,
        justify: "right",
        aggregateSubtitle: "Total",
        valueFormatter: formatCurrency,
        aggregateTooltipProps: this.includesAutomatedActualExpenses(),
        ValueComponent: ActualExpenseValue,
        AggregatedValueComponent: ActualExpenseValue
      },
      {
        Header: "Cost Per\nNew\xa0Website\xa0Visitor",
        csvHeader: "Cost Per New Website Visitor (Budgeted)",
        csvAltHeader: "Cost Per New Website Visitor (Actual)",
        accessor: "cost_per_nwv",
        paddingX: 1,
        justify: "right",
        valueFormatter: formatCurrency,
        aggregateSubtitle: "Average",
        ValueComponent: BudgetCostPersValue,
        AggregatedValueComponent: BudgetCostPersValue
      },
      {
        Header: "Cost Per\nLead",
        csvHeader: "Cost Per Lead (Budgeted)",
        csvAltHeader: "Cost Per Lead (Actual)",
        accessor: "cost_per_leads",
        paddingX: 1,
        justify: "right",
        valueFormatter: formatCurrency,
        aggregateSubtitle: "Average",
        ValueComponent: BudgetCostPersValue,
        AggregatedValueComponent: BudgetCostPersValue
      },
      {
        Header: "Cost Per\nTour",
        csvHeader: "Cost Per Tour (Budgeted)",
        csvAltHeader: "Cost Per Tour (Actual)",
        accessor: "cost_per_tours",
        paddingX: 1,
        justify: "right",
        valueFormatter: formatCurrency,
        aggregateSubtitle: "Average",
        ValueComponent: BudgetCostPersValue,
        AggregatedValueComponent: BudgetCostPersValue
      },
      {
        Header: "Cost Per\nLease Application",
        csvHeader: "Cost Per Lease Application (Budgeted)",
        csvAltHeader: "Cost Per Lease Application (Actual)",
        accessor: "cost_per_lease_applications",
        paddingX: 1,
        justify: "right",
        valueFormatter: formatCurrency,
        aggregateSubtitle: "Average",
        ValueComponent: BudgetCostPersValue,
        AggregatedValueComponent: BudgetCostPersValue
      },
      {
        Header: "Cost Per\nApproved Lease",
        csvHeader: "Cost Per Approved Lease (Budgeted)",
        csvAltHeader: "Cost Per Approved Lease (Actual)",
        accessor: "cost_per_approved_leases",
        paddingX: 1,
        justify: "right",
        valueFormatter: formatCurrency,
        aggregateSubtitle: "Average",
        ValueComponent: BudgetCostPersValue,
        AggregatedValueComponent: BudgetCostPersValue
      },
      {
        Header: "Cost Per\nNew Lease",
        csvHeader: "Cost Per New Lease (Budgeted)",
        csvAltHeader: "Cost Per New Lease (Actual)",
        accessor: "cost_per_new_leases",
        paddingX: 1,
        justify: "right",
        valueFormatter: formatCurrency,
        aggregateSubtitle: "Average",
        ValueComponent: BudgetCostPersValue,
        AggregatedValueComponent: BudgetCostPersValue
      },
      {
        Header: "Cost Per\nNew\xa0Website\xa0Visitor",
        accessor: "cost_per_nwv_actual",
        paddingX: 1,
        justify: "right",
        valueFormatter: formatCurrency,
        aggregateSubtitle: "Average",
        ValueComponent: ActualCostPersValue,
        AggregatedValueComponent: ActualCostPersValue
      },
      {
        Header: "Cost Per\nLead",
        accessor: "cost_per_leads_actual",
        paddingX: 1,
        justify: "right",
        valueFormatter: formatCurrency,
        aggregateSubtitle: "Average",
        ValueComponent: ActualCostPersValue,
        AggregatedValueComponent: ActualCostPersValue
      },
      {
        Header: "Cost Per\nTour",
        accessor: "cost_per_tours_actual",
        paddingX: 1,
        justify: "right",
        valueFormatter: formatCurrency,
        aggregateSubtitle: "Average",
        ValueComponent: ActualCostPersValue,
        AggregatedValueComponent: ActualCostPersValue
      },
      {
        Header: "Cost Per\nLease Application",
        accessor: "cost_per_lease_applications_actual",
        paddingX: 1,
        justify: "right",
        valueFormatter: formatCurrency,
        aggregateSubtitle: "Average",
        ValueComponent: ActualCostPersValue,
        AggregatedValueComponent: ActualCostPersValue
      },
      {
        Header: "Cost Per\nApproved Lease",
        accessor: "cost_per_approved_leases_actual",
        paddingX: 1,
        justify: "right",
        valueFormatter: formatCurrency,
        aggregateSubtitle: "Average",
        ValueComponent: ActualCostPersValue,
        AggregatedValueComponent: ActualCostPersValue
      },
      {
        Header: "Cost Per\nNew Lease",
        accessor: "cost_per_new_leases_actual",
        paddingX: 1,
        justify: "right",
        valueFormatter: formatCurrency,
        aggregateSubtitle: "Average",
        ValueComponent: ActualCostPersValue,
        AggregatedValueComponent: ActualCostPersValue
      },
      {
        Header: "New Leases",
        accessor: "new_leases",
        justify: "right",
        aggregateSubtitle: "Total",
        valueFormatter: this.state.showDistributed
          ? formatCondDecimal
          : formatIntNumber,
        sortType: "number"
      },
      {
        Header: "Gross\xa0Rent\xa0/\xa0Mo",
        accessor: "gross_rent",
        paddingX: 2,
        justify: "right",
        valueFormatter: formatCurrency,
        aggregateSubtitle: "Average",
        aggregateAllowNaNs: false
      },
      {
        Header: "Lease Length",
        accessor: "avg_lease_length",
        paddingX: 1,
        justify: "right",
        aggregateSubtitle: "Average",
        aggregateAllowNaNs: false,
        valueFormatter: formatCondDecimal
      },
      {
        Header: "Projected\xa0New\nLease\xa0Revenue",
        accessor: "lease_revenue",
        colWidth: "185px",
        justify: "right",
        paddingX: 2,
        valueFormatter: formatCurrency,
        aggregateSubtitle: "Total"
      },
      {
        Header: "Projected\nMarketing\xa0Return",
        accessor: "projected_marketing_return",
        colWidth: "205px",
        justify: "right",
        paddingX: 2,
        valueType: "multi",
        sortType: "basic",
        valueFormatter: formatProjectedMarketingReturn,
        tooltipProps: {
          title: PROJECTED_MARKETING_RETURN_INFO,
          placement: "top-left"
        }
      },
      {
        Header: "Bedrooms",
        accessor: "bedrooms",
        paddingX: 1,
        justify: "right",
        aggregateSubtitle: "Average",
        aggregateAllowNaNs: false,
        valueFormatter: getValueFormatter("bedrooms"),
        csvFormatter: getValueFormatter("bedrooms")
      },
      {
        Header: "Bathrooms",
        accessor: "bathrooms",
        paddingX: 1,
        justify: "right",
        aggregateSubtitle: "Average",
        aggregateAllowNaNs: false,
        valueFormatter: getValueFormatter("bathrooms"),
        csvFormatter: getValueFormatter("bathrooms")
      },
      {
        Header: "Square Feet",
        accessor: "square_feet",
        paddingX: 1,
        justify: "right",
        aggregateSubtitle: "Average",
        aggregateAllowNaNs: false,
        valueFormatter: getValueFormatter("square_feet"),
        csvFormatter: getValueFormatter("square_feet")
      },
      {
        Header: "Gross Rent PSF",
        accessor: "rent_per_sq_feet",
        paddingX: 1,
        justify: "right",
        aggregateSubtitle: "Average",
        aggregateAllowNaNs: false,
        valueFormatter: getValueFormatter("rent_per_sq_feet"),
        csvFormatter: getValueFormatter("rent_per_sq_feet")
      }
    ];
  }

  get getExpensesFilter() {
    return this.state.filters["byExpenses"]?.[0]?.["id"];
  }

  get channelAnalysisTableTabs() {
    return channelAnalysisTableTabs(this.getExpensesFilter);
  }

  getEventTabs = () => {
    let response = [];
    response.push(this.channelAnalysisTableTabs["volumes"]["event"]);
    response.push(this.channelAnalysisTableTabs["conversion_rates"]["event"]);
    response.push(this.channelAnalysisTableTabs["cost_pers"]["event"]);
    response.push(this.channelAnalysisTableTabs["revenue_roi"]["event"]);
    if (checkFlag("channel-analysis-units-tab")) {
      response.push(this.channelAnalysisTableTabs["units"]["event"]);
    }
    return response;
  };

  getLeadCohortTabs = () => {
    let response = [];
    response.push(this.channelAnalysisTableTabs["volumes"]["leadCohort"]);
    response.push(
      this.channelAnalysisTableTabs["conversion_rates"]["leadCohort"]
    );
    response.push(this.channelAnalysisTableTabs["cost_pers"]["leadCohort"]);
    response.push(this.channelAnalysisTableTabs["revenue_roi"]["leadCohort"]);
    if (checkFlag("channel-analysis-units-tab")) {
      response.push(this.channelAnalysisTableTabs["units"]["leadCohort"]);
    }
    return response;
  };

  getTableTabs = () =>
    this.state.activeTab === "event"
      ? this.getEventTabs()
      : this.getLeadCohortTabs();

  getTableData = () =>
    this.state.showDistributed
      ? this.props.distributedTableData
      : this.props.tableData;

  getSummaryData = () =>
    this.state.showDistributed
      ? this.props.distributedTableData.summary
      : this.props.tableSummaryData;

  getRankingsData = () =>
    this.state.showDistributed
      ? this.props.distributedRankingsData
      : this.props.rankingsData;

  toggleTabs = tab => {
    this.setState({ activeTab: tab });
    this.props.onTabsToggle(tab);
    if (tab === "lead_cohort") {
      this.setState({ showDistributed: false });
      this.props.onShowDistributedSource(false);
    }
  };

  toggleDistributedSource = () => {
    const showDistributed = !this.state.showDistributed;
    this.setState({ dummyLoader: true });
    setTimeout(() => {
      this.setState({ dummyLoader: false });
    }, 1000);
    this.setState({ showDistributed });
    this.props.onShowDistributedSource(showDistributed);
  };

  getCsvFileName = (fileName, pageNumber, tab) => {
    const { propertyName, startDate, endDate } = this.props;
    const name = propertyName?.replaceAll(" ", "_");
    const start = dateFormat(startDate, "yyyy-MM-dd");
    const end = dateFormat(endDate, "yyyy-MM-dd");
    let scvName;
    if (tab) {
      const tabName = tab.replaceAll(" ", "_");
      scvName = `Remarkably_${name}_Channel_Analysis_${tabName}_${start}-${end}`;
    } else {
      scvName = `Remarkably_${name}_Channel_Analysis_Report_${start}-${end}`;
    }
    return scvName;
  };

  includesAutomatedActualExpenses = () => {
    let tableData = this.getSummaryData();
    if (tableData && tableData["includes_automated"]) {
      return { title: INCLUDES_AUTOMATED_ACTUAL_EXPENSES_MSG };
    }
    return null;
  };

  onlyMissingExpenses = () =>
    !_isEmpty(this.state.filters) &&
    "byExpenses" in this.state.filters &&
    this.state.filters["byExpenses"].length === 1 &&
    this.state.filters["byExpenses"][0]["id"] == "missingOnly";

  applyFilter = filters => {
    let filterParams = { ...filters };
    let filterKeys = Object.keys(filters);
    filterKeys.forEach(key => {
      filterParams[key] = filterParams[key].map(entry => entry["name"]);
    });
    this.setState({ filters: filters });
    this.props.dispatch(
      propertyActions.getChannelAnalysisKpis(
        this.props.propertyId,
        dateFormat(this.props.startDate, "yyyy-MM-dd"),
        dateFormat(this.props.endDate, "yyyy-MM-dd"),
        filterParams
      )
    );
  };

  removeTag = ({ type, value }) => {
    this.dropdownFilters.current.onSelection(type, value, true);
    this.dropdownFilters.current.onApplyHandler();
  };

  tagTypes = [
    {
      id: "byExpenses",
      title: "Expenses"
    }
  ];

  render() {
    const {
      expensesLastUpdated,
      loading,
      summaryData,
      tableLoading,
      attributionsLastUpdated,
      exposureRankingsData
    } = this.props;
    return (
      <>
        <Flex justifyContent="space-between">
          <Flex flexDirection="column">
            <Flex alignItems="center">
              <Text
                fontSize="1.25rem"
                lineHeight="2.625rem"
                $fontWeight="medium"
                mr={4}
              >
                Channel Analysis
              </Text>
              <Flex ml={4}>
                <FilterTags
                  filters={this.state.filters}
                  tagTypes={this.tagTypes}
                  onRemoveTag={this.removeTag}
                />
              </Flex>
            </Flex>
            {checkFlag("channel-analysis-lead-cohort-toggle") ? (
              <ModeSwitch
                tooltip1="Analyze all funnel events that occurred during the selected date range, from New Website Visitor through New Lease stages, regardless of when prospective renters became Leads in the funnel."
                tooltip2="Analyze only funnel events related to those who became Leads during the selected date range, from Lead through New Lease stages. New Website Visitors will be excluded from this view."
                onToggle={this.toggleTabs}
              />
            ) : null}
          </Flex>
          {checkFlag("channel-analysis-filter") ? (
            <Flex alignItems="flex-end" flexDirection="column">
              <ChannelAnalysisFilter
                ref={this.dropdownFilters}
                data={this.props.tableData}
                byExpensesFilters={this.props.byExpenseFilters}
                onSetFilter={this.applyFilter}
              />
            </Flex>
          ) : null}
        </Flex>
        {checkFlag("channel-analysis-summary-cards") ? (
          <ChannelAnalysisSummary
            loading={loading}
            data={summaryData}
            pt={6}
            pb={8}
          />
        ) : null}
        <Flex justifyContent="space-between" mt={5}>
          <Flex flexDirection="column">
            <Flex alignItems="center">
              <Text $color="text.white.1" textSize={2} $fontWeight="medium">
                Show Distributed Source Attribution
              </Text>
              <Flex mx={2} alignItems="center">
                <ToggleSwitch
                  name="show_distributed"
                  py={0}
                  initialValue={this.state.showDistributed}
                  hasLabel={false}
                  disabled={
                    this.state.activeTab === "lead_cohort" ||
                    !this.props.distributedTableData.has_sources
                  }
                  onChange={this.toggleDistributedSource}
                />
                <Tooltip
                  size="small"
                  title="Toggle ‘on’ to show website referral sources
                that Lead sources were distributed to, and hide distributed
                Lead sources."
                  ml={2}
                />
              </Flex>
            </Flex>
            <Text $color="text.gray.1" textSize={2} mt={1}>
              Show or hide distributed Lead source choices in the table below by
              adjusting the toggle.
            </Text>
          </Flex>
          <Flex alignItems="flex-end" flexDirection="column">
            <Text $color="text.gray.1" textSize={2}>
              Expenses Last Updated{" "}
              {expensesLastUpdated
                ? dateFormat(expensesLastUpdated, DATE_FORMAT)
                : "\u2014"}
            </Text>
            <Text $color="text.gray.1" textSize={2} mt={1}>
              Matches and Distributions Last Updated{" "}
              {attributionsLastUpdated
                ? dateFormat(attributionsLastUpdated, DATE_FORMAT)
                : "\u2014"}
            </Text>
          </Flex>
        </Flex>
        {checkFlag("source-rankings") ? (
          <>
            <ChannelAnalysisRankings
              loading={loading || this.state.dummyLoader}
              data={this.getRankingsData()}
              exposureData={exposureRankingsData}
              showDistributed={this.state.showDistributed}
              my={6}
            />
            <Divider mb={4} variant="horizontal" bg="gray.3" />
          </>
        ) : null}
        <RenderIf condition={this.getTableTabs().length > 0}>
          <Table
            columns={this.tableColumns}
            data={this.getTableData()}
            summaryData={this.getSummaryData()}
            loading={loading || tableLoading || this.state.dummyLoader}
            tabs={this.getTableTabs()}
            getCsvFilename={this.getCsvFileName}
            initialPageSize={10}
            pageSizes={[
              { label: "10", value: 10 },
              { label: "20", value: 20 },
              { label: "All", value: "all" }
            ]}
            mt={4}
          />
        </RenderIf>
      </>
    );
  }
}

const mapState = state => ({
  ...state.network,
  ...state.property
});

export default withRouter(connect(mapState)(ChannelAnalysis));
