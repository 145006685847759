import {
  configuredFormatCurrency,
  configuredFormatNumber,
  configuredFormatPercent
} from "../../utils/formatters";
import { formatKPI } from "../../utils/kpi_formatters";
import { isValueExist } from "./utils";

export const formatCurrency = configuredFormatCurrency(true);

export const formatIntNumber = configuredFormatNumber();

export const formatPercent = configuredFormatPercent(2, 0);

export const getValueFormatter = kpi => value =>
  isValueExist(value) ? formatKPI(kpi, value) : "—";
