import { createAjaxAction } from "./helpers";

const actions = {
  requestGroups: createAjaxAction(
    "AJAX_GET_PORTFOLIO_GROUPS",
    "/portfolio/table/",
    qs => qs
  )
};

export default actions;
