import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";

import { InviteModal } from "../../components/members_modal";
import { propertyActions } from "../../redux_base/actions";

class InviteModalContainer extends React.PureComponent {
  static propTypes = {
    property: PropTypes.object.isRequired,
    members: PropTypes.array.isRequired,
    isInviteOpen: PropTypes.bool,
    membersSearch: PropTypes.array,
    isMembersLoading: PropTypes.bool,
    isAdmin: PropTypes.bool,
    inviteErrorMessages: PropTypes.array,
    inviteCount: PropTypes.number
  };

  loadUsers = inputValue => {
    if (!!inputValue) {
      clearTimeout(this.loadUsersTimeOut);
      this.loadUsersTimeOut = setTimeout(() => {
        this.props.dispatch(propertyActions.getUsers(inputValue));
      }, 300);
    }
  };

  changeRole = (role, property, member) => {
    this.props.dispatch(propertyActions.changeRole(role, property, member));
  };

  removeMember = (property, member) => {
    this.props.dispatch(propertyActions.removeMember(property, member));
  };

  openRemoveModal = () => {
    this.props.dispatch(propertyActions.openRemove());
  };

  closeRemoveModal = () => {
    this.props.dispatch(propertyActions.closeRemove());
    this.props.dispatch(propertyActions.clearInviteMessages());
  };

  openChangeRoleModal = () => {
    this.props.dispatch(propertyActions.openChangeRole());
  };

  closeChangeRoleModal = () => {
    this.props.dispatch(propertyActions.closeChangeRole());
  };

  inviteMembers = (properties, members, role) => {
    this.props.dispatch(
      propertyActions.inviteMembers({
        body: {
          properties,
          members,
          role
        }
      })
    );
  };

  closeInvite = () => {
    this.props.dispatch(propertyActions.closeInvite());
  };

  render() {
    const { isInviteOpen, property, members, ...props } = this.props;
    return (
      <InviteModal
        {...props}
        isOpen={isInviteOpen}
        properties={[
          {
            public_id: property.public_id,
            name: property.name,
            members
          }
        ]}
        onLoadUsers={this.loadUsers}
        onChangeRole={this.changeRole}
        onRemoveMember={this.removeMember}
        onInviteMembers={this.inviteMembers}
        onOpenRemove={this.openRemoveModal}
        onCloseRemove={this.closeRemoveModal}
        onOpenChangeRole={this.openChangeRoleModal}
        onCloseChangeRole={this.closeChangeRoleModal}
        onClose={this.closeInvite}
      />
    );
  }
}

const mapState = state => ({ ...state.property });

export default connect(mapState)(InviteModalContainer);
