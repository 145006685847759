import React from "react";

import IconBase from "./icon_base";

const GraphLine = props => (
  <IconBase viewBox="0 0 16 16" fill="none" {...props}>
    <path
      d="M2.8 11.8533L6.33334 8.31998L8.5 10.4866C8.77334 10.76 9.21334 10.7466 9.46667 10.46L14.2467 5.07998C14.48 4.81998 14.4667 4.41998 14.22 4.16664C13.9533 3.89998 13.5067 3.90664 13.2533 4.19331L8.99334 8.97998L6.8 6.78665C6.54 6.52665 6.12 6.52665 5.86 6.78665L1.8 10.8533C1.54 11.1133 1.54 11.5333 1.8 11.7933L1.86 11.8533C2.12 12.1133 2.54667 12.1133 2.8 11.8533Z"
      fill="currentColor"
    />
  </IconBase>
);

export default GraphLine;
