import React from "react";
import { Button, Text } from "concrete-ui";
import _isNil from "lodash/isNil";
import _uniqBy from "lodash/uniqBy";

import { BudgetCostPersValue } from "../channel_analysis/values";
import EnhancedLink from "../enhanced_link";
import { formatKPI } from "../../utils/kpi_formatters";
import checkFlag from "../../utils/posthog";

export const getFunnelAnalysisGridTabs = () => {
  const tabs = [
    {
      id: "volumes",
      label: "Volumes",
      columns: volumesColumns.map(c => c.accessor)
    }
  ];
  if (checkFlag("funnel-analysis-grid-conversion-rates")) {
    tabs.push({
      id: "conversion_rates",
      label: "Conversion Rates",
      columns: conversionRatesColumns.map(c => c.accessor)
    });
  }
  if (checkFlag("funnel-analysis-grid-cost-pers")) {
    tabs.push({
      id: "cost_pers",
      label: "Expenses & Cost Pers",
      columns: costPersColumns.map(c => c.accessor)
    });
  }
  if (checkFlag("funnel-analysis-grid-revenue-roi")) {
    tabs.push({
      id: "revenue_roi",
      label: "Revenue & ROI",
      columns: revenueRoiColumns.map(c => c.accessor)
    });
  }
  if (checkFlag("funnel-analysis-grid-units")) {
    tabs.push({
      id: "units",
      label: "Units",
      columns: unitsColumns.map(c => c.accessor)
    });
  }
  return tabs;
};

export const getFunnelAnalysisChartsTabs = () => [
  {
    id: "volumes",
    label: "Volumes"
  }
];

const getValueFormatter = kpi => value =>
  !_isNil(value) ? formatKPI(kpi, value) : "—";

const getBudgetsDiffValue = (data, columnId) => {
  return data[`${columnId}_diff`];
};

const PropertyName = ({ data, textProps, rowState }) => (
  <>
    <Text {...textProps}>{data.name}</Text>
    {rowState.hover && (
      <EnhancedLink
        style={{
          color: "inherit",
          marginLeft: "32px",
          textDecoration: "inherit"
        }}
        to={`/property/${data.id}`}
      >
        <Button borderColor="gray.5" size="small" variant="secondary">
          View Property
        </Button>
      </EnhancedLink>
    )}
  </>
);

const CostPersValueWrap = props => (
  <BudgetCostPersValue {...props} getDiffValue={getBudgetsDiffValue} />
);

const volumesColumns = [
  {
    Header: "Properties",
    accessor: "name",
    columnAggregate: "count",
    minWidth: "480px",
    aggregateSubtitle: "Total",
    ValueComponent: PropertyName
  },
  {
    Header: "New Website Visitors",
    accessor: "usvs",
    justify: "right",
    aggregateSubtitle: "Total",
    valueFormatter: getValueFormatter("usvs"),
    csvFormatter: getValueFormatter("usvs"),
    sortType: "number"
  },
  {
    Header: "Leads",
    accessor: "inquiries",
    justify: "right",
    aggregateSubtitle: "Total",
    valueFormatter: getValueFormatter("inquiries"),
    csvFormatter: getValueFormatter("inquiries"),
    sortType: "number"
  },
  {
    Header: "Tours",
    accessor: "tours",
    justify: "right",
    aggregateSubtitle: "Total",
    valueFormatter: getValueFormatter("tours"),
    csvFormatter: getValueFormatter("tours"),
    sortType: "number"
  },
  {
    Header: "Lease Applications",
    accessor: "lease_applications",
    justify: "right",
    aggregateSubtitle: "Total",
    valueFormatter: getValueFormatter("lease_applications"),
    csvFormatter: getValueFormatter("lease_applications"),
    sortType: "number"
  },
  {
    Header: "Approved Leases",
    accessor: "approvals",
    justify: "right",
    aggregateSubtitle: "Total",
    valueFormatter: getValueFormatter("approvals"),
    csvFormatter: getValueFormatter("approvals"),
    sortType: "number"
  },
  {
    Header: "New Leases",
    accessor: "leases_executed",
    justify: "right",
    aggregateSubtitle: "Total",
    valueFormatter: getValueFormatter("leases_executed"),
    csvFormatter: getValueFormatter("leases_executed"),
    sortType: "number"
  }
];

const conversionRatesColumns = [
  {
    Header: "Properties",
    accessor: "name",
    columnAggregate: "count",
    minWidth: "450px",
    aggregateSubtitle: "Total",
    ValueComponent: PropertyName
  },
  {
    Header: "Leads\xa0\u2192 New\xa0Leases",
    accessor: "inq_exe",
    paddingX: 2,
    colWidth: "130px",
    justify: "right",
    aggregateSubtitle: "Average",
    valueType: "percentage",
    valueFormatter: getValueFormatter("inq_exe"),
    sortType: "number"
  },
  {
    Header: "New\xa0Website Visitors\xa0\u2192\xa0Leads",
    accessor: "usv_inq",
    paddingX: 2,
    colWidth: "170px",
    justify: "right",
    aggregateSubtitle: "Average",
    valueType: "percentage",
    valueFormatter: getValueFormatter("usv_inq"),
    sortType: "number"
  },
  {
    Header: "Leads\xa0\u2192 Tours",
    accessor: "inq_tou",
    paddingX: 2,
    colWidth: "150px",
    justify: "right",
    aggregateSubtitle: "Average",
    valueType: "percentage",
    valueFormatter: getValueFormatter("inq_tou"),
    sortType: "number"
  },
  {
    Header: "Tours\xa0\u2192 Lease\xa0Applications",
    accessor: "tou_app",
    paddingX: 2,
    justify: "right",
    aggregateSubtitle: "Average",
    valueType: "percentage",
    valueFormatter: getValueFormatter("tou_app"),
    sortType: "number"
  },
  {
    Header: "Lease\xa0Applications\xa0\u2192 Approved\xa0Leases",
    accessor: "app_approvals",
    paddingX: 2,
    justify: "right",
    aggregateSubtitle: "Average",
    valueType: "percentage",
    valueFormatter: getValueFormatter("app_approvals"),
    sortType: "number"
  },
  {
    Header: "Approved\xa0Leases\xa0\u2192 New\xa0Leases",
    accessor: "approved_exe",
    paddingX: 2,
    justify: "right",
    aggregateSubtitle: "Average",
    valueType: "percentage",
    valueFormatter: getValueFormatter("approved_exe"),
    sortType: "number"
  }
];

const costPersColumns = [
  {
    Header: "Properties",
    accessor: "name",
    columnAggregate: "count",
    minWidth: "400px",
    aggregateSubtitle: "Total",
    ValueComponent: PropertyName
  },
  {
    Header: "Budgeted Expenses",
    accessor: "budget",
    paddingX: 1,
    justify: "right",
    valueType: "currency",
    valueFormatter: getValueFormatter("budget"),
    ValueComponent: props => (
      <BudgetCostPersValue
        {...props}
        isHighlightText={true}
        getDiffValue={getBudgetsDiffValue}
      />
    ),
    AggregatedValueComponent: props => (
      <BudgetCostPersValue
        {...props}
        isHighlightText={true}
        getDiffValue={getBudgetsDiffValue}
      />
    ),
    aggregateSubtitle: "Total"
  },
  {
    Header: "Cost Per\nNew\xa0Website\xa0Visitor",
    accessor: "cost_per_usvs",
    paddingX: 1,
    justify: "right",
    valueType: "currency",
    valueFormatter: getValueFormatter("cost_per_usvs"),
    ValueComponent: CostPersValueWrap,
    AggregatedValueComponent: CostPersValueWrap,
    aggregateSubtitle: "Average"
  },
  {
    Header: "Cost Per\nLead",
    accessor: "cost_per_inquiries",
    paddingX: 1,
    justify: "right",
    valueType: "currency",
    valueFormatter: getValueFormatter("cost_per_inquiries"),
    ValueComponent: CostPersValueWrap,
    AggregatedValueComponent: CostPersValueWrap,
    aggregateSubtitle: "Average"
  },
  {
    Header: "Cost Per\nTour",
    accessor: "cost_per_tours",
    paddingX: 1,
    justify: "right",
    valueType: "currency",
    valueFormatter: getValueFormatter("cost_per_tours"),
    ValueComponent: CostPersValueWrap,
    AggregatedValueComponent: CostPersValueWrap,
    aggregateSubtitle: "Average"
  },
  {
    Header: "Cost Per\nLease Application",
    accessor: "cost_per_lease_applications",
    paddingX: 1,
    justify: "right",
    valueType: "currency",
    valueFormatter: getValueFormatter("cost_per_lease_applications"),
    ValueComponent: CostPersValueWrap,
    AggregatedValueComponent: CostPersValueWrap,
    aggregateSubtitle: "Average"
  },
  {
    Header: "Cost Per\nApproved Lease",
    accessor: "cost_per_approvals",
    paddingX: 1,
    justify: "right",
    valueType: "currency",
    valueFormatter: getValueFormatter("cost_per_approvals"),
    ValueComponent: CostPersValueWrap,
    AggregatedValueComponent: CostPersValueWrap,
    aggregateSubtitle: "Average"
  },
  {
    Header: "Cost Per\nNew Lease",
    accessor: "cost_per_leases_executed",
    paddingX: 1,
    justify: "right",
    valueType: "currency",
    valueFormatter: getValueFormatter("cost_per_leases_executed"),
    ValueComponent: CostPersValueWrap,
    AggregatedValueComponent: CostPersValueWrap,
    aggregateSubtitle: "Average"
  }
];

const revenueRoiColumns = [
  {
    Header: "Properties",
    accessor: "name",
    columnAggregate: "count",
    minWidth: "450px",
    aggregateSubtitle: "Total",
    ValueComponent: PropertyName
  },
  {
    Header: "New Leases",
    accessor: "leases_executed",
    justify: "right",
    aggregateSubtitle: "Total",
    sortType: "number",
    csvFormatter: getValueFormatter("leases_executed"),
    valueFormatter: getValueFormatter("leases_executed")
  },
  {
    Header: "Gross\xa0Rent\xa0/\xa0Mo",
    accessor: "gross_rent",
    paddingX: 2,
    justify: "right",
    aggregateSubtitle: "Average",
    aggregateAllowNaNs: false,
    csvFormatter: getValueFormatter("gross_rent"),
    valueFormatter: getValueFormatter("gross_rent")
  },
  {
    Header: "Lease Length",
    accessor: "avg_lease_length",
    paddingX: 1,
    justify: "right",
    aggregateSubtitle: "Average",
    aggregateAllowNaNs: false,
    csvFormatter: getValueFormatter("avg_lease_length"),
    valueFormatter: getValueFormatter("avg_lease_length")
  },
  {
    Header: "Projected\xa0New\nLease\xa0Revenue",
    accessor: "lease_revenue",
    paddingX: 2,
    justify: "right",
    colWidth: "185px",
    aggregateSubtitle: "Total",
    csvFormatter: getValueFormatter("lease_revenue"),
    valueFormatter: getValueFormatter("lease_revenue")
  },
  {
    Header: "Projected\nMarketing\xa0Return",
    accessor: "projected_marketing_return",
    paddingX: 2,
    justify: "right",
    colWidth: "205px",
    valueType: "multi",
    sortType: "basic",
    csvFormatter: getValueFormatter("csv_projected_marketing_return"),
    valueFormatter: getValueFormatter("projected_marketing_return")
  }
];

const unitsColumns = [
  {
    Header: "Properties",
    accessor: "name",
    columnAggregate: "count",
    minWidth: "450px",
    aggregateSubtitle: "Total",
    ValueComponent: PropertyName
  },
  {
    Header: "New Leases",
    accessor: "leases_executed",
    justify: "right",
    aggregateSubtitle: "Total",
    sortType: "number",
    csvFormatter: getValueFormatter("leases_executed"),
    valueFormatter: getValueFormatter("leases_executed")
  },
  {
    Header: "Bedrooms",
    accessor: "bedrooms",
    paddingX: 1,
    justify: "right",
    aggregateSubtitle: "Average",
    aggregateAllowNaNs: false,
    valueFormatter: getValueFormatter("bedrooms"),
    csvFormatter: getValueFormatter("bedrooms")
  },
  {
    Header: "Bathrooms",
    accessor: "bathrooms",
    paddingX: 1,
    justify: "right",
    aggregateSubtitle: "Average",
    aggregateAllowNaNs: false,
    valueFormatter: getValueFormatter("bathrooms"),
    csvFormatter: getValueFormatter("bathrooms")
  },
  {
    Header: "Square Feet",
    accessor: "square_feet",
    paddingX: 1,
    justify: "right",
    aggregateSubtitle: "Average",
    aggregateAllowNaNs: false,
    valueFormatter: getValueFormatter("square_feet"),
    csvFormatter: getValueFormatter("square_feet")
  },
  {
    Header: "Gross Rent PSF",
    accessor: "rent_per_sq_feet",
    paddingX: 1,
    justify: "right",
    aggregateSubtitle: "Average",
    aggregateAllowNaNs: false,
    valueFormatter: getValueFormatter("rent_per_sq_feet"),
    csvFormatter: getValueFormatter("rent_per_sq_feet")
  }
];

export const funnelAnalysisTabColumns = {
  volumes: volumesColumns,
  conversion_rates: conversionRatesColumns,
  cost_pers: costPersColumns,
  revenue_roi: revenueRoiColumns,
  units: unitsColumns
};

export const allFunnelAnalysisColumns = _uniqBy(
  [
    ...volumesColumns,
    ...conversionRatesColumns,
    ...costPersColumns,
    ...revenueRoiColumns,
    ...unitsColumns
  ],
  "accessor"
);
