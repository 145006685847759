import cn from "classnames";
import PropTypes from "prop-types";
import React from "react";
import ReactDOM from "react-dom";
import ReactSelect from "react-select";
import AsyncSelect from "react-select/async";

import {
  MultiValueComponents,
  OptionUsers
} from "../../components/select/select_components";

import "./select_textarea.scss";

class SelectTextarea extends React.PureComponent {
  constructor(props) {
    super(props);
    this.selectRef = React.createRef();
  }

  static propType = {
    className: PropTypes.string,
    isAsync: PropTypes.bool,
    placeholder: PropTypes.string
  };

  static defaultProps = {
    isAsync: false,
    placeholder: "Enter email addresses..."
  };

  componentDidMount() {
    const currentComponent = ReactDOM.findDOMNode(this);
    this.testRef = currentComponent.getElementsByClassName("select__input")[0];
    this.forceUpdate();
  }

  selectComponents = {
    ...MultiValueComponents,
    DropdownIndicator: () => null,
    Option: OptionUsers
  };

  onFocusSelect = () => {
    if (this.selectRef) {
      this.selectRef.current.focus();
    }
  };

  noOptionsMessage = () => <div>User not found</div>;

  render() {
    const {
      className,
      isAsync,
      isValidNewOption,
      placeholder,
      options,
      ...selectProps
    } = this.props;
    const classes = cn("select-textarea", className);
    const SelectComponent = isAsync ? AsyncSelect : ReactSelect;
    return (
      <div className={classes}>
        <textarea
          className="select-textarea__textarea"
          disabled
          onClick={this.onFocusSelect}
        />
        <div
          className="select-textarea__container"
          onClick={this.onFocusSelect}
        >
          <SelectComponent
            className="select select--transparent select-textarea__select"
            classNamePrefix="select"
            components={this.selectComponents}
            isClearable={false}
            isMulti={true}
            isSearchable={true}
            menuPortalTarget={this.testRef}
            noOptionsMessage={this.noOptionsMessage}
            openMenuOnFocus={false}
            options={options}
            placeholder={placeholder}
            ref={this.selectRef}
            {...selectProps}
          />
        </div>
      </div>
    );
  }
}

export default SelectTextarea;
