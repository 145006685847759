const initState = {
  us_state_list: [],
  gb_county_list: [],
  office_countries: []
};

const reducer = (state = initState, action) => {
  let newState = undefined;

  switch (action.type) {
    case "AJAX_GET_COMPLETE_ACCOUNT_SUCCESS":
    case "AJAX_GET_ACCOUNT_SETTINGS_SUCCESS": {
      newState = {
        ...state,
        us_state_list: action.payload.us_state_list,
        gb_county_list: action.payload.gb_county_list,
        office_countries: action.payload.office_countries
      };
      break;
    }
    default: {
      newState = state;
    }
  }
  return newState;
};

export default reducer;
