export default (opts = {}) => (
  file = window.document.namespaceURI
) => level => async msg => {
  let { callback, noPrint } = opts;

  if (process.env.ENVIRONMENT?.toLowerCase() === "production") {
    noPrint = true;
  }

  const timestamp = new Date().toUTCString();
  const callbackArgs = { timestamp, file, level, msg };
  let consoleFunc = undefined;
  switch (level) {
    case "info": {
      consoleFunc = console.info;
      break;
    }
    case "warn":
    case "warning": {
      consoleFunc = console.warn;
      break;
    }
    case "error": {
      consoleFunc = console.error;
      break;
    }
    case "log":
    default: {
      consoleFunc = console.log;
    }
  }

  if (callback) {
    if (callback.then) {
      await callback(callbackArgs);
    } else {
      callback(callbackArgs);
    }
  }
  const logString = `[${timestamp}][${level}] ${file}: `;
  if (!noPrint) {
    consoleFunc(logString, typeof msg == "string" ? msg : [...msg]);
  }
  return { logString, msg };
};
