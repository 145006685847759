import React from "react";

import IconBase from "./icon_base";

const IconChevronDown = props => (
  <IconBase viewBox="0 0 24 24" {...props} width="24" height="24">
    <path
      fill="#F5FAF7"
      d="M15.875 9l-3.88 3.88L8.115 9a.996.996 0 10-1.41 1.41l4.59 4.59c.39.39 1.02.39 1.41 0l4.59-4.59a.996.996 0 000-1.41c-.39-.38-1.03-.39-1.42 0z"
    ></path>
  </IconBase>
);

export default IconChevronDown;
