import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import _findKey from "lodash/findKey";

import PropertyKPIPage from "../../components/property_kpi_page";
import { KPI_MATCHER, KPI_URL_LABEL, VOLUME_KPIS } from "../../constants";
import {
  filters as filtersActions,
  propertyGroup as propertyGroupActions,
  propertyActions
} from "../../redux_base/actions";
import { getPeriodByURL, dateFormat } from "../../utils/dates";
import { withLogin } from "../shared/login";

class PropertyKPIPageContainer extends React.PureComponent {
  static propTypes = {
    date_selection: PropTypes.shape({
      preset: PropTypes.string.isRequired,
      start_date: PropTypes.instanceOf(Date).isRequired,
      end_date: PropTypes.instanceOf(Date).isRequired
    }),
    property: PropTypes.shape({
      public_id: PropTypes.string.isRequired,
      group_id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      group_name: PropTypes.string.isRequired
    }),
    dataSource: PropTypes.string,
    kpiData: PropTypes.object.isRequired,
    loaderState: PropTypes.bool
  };

  componentDidMount() {
    const { publicId, kpi } = this.props.match.params;
    const { start_date, end_date } = this.props.date_selection;
    let start = dateFormat(start_date, "yyyy-MM-dd");
    let end = dateFormat(end_date, "yyyy-MM-dd");
    const period = getPeriodByURL(this.props.location.search);
    if (period) {
      start = period.start_date;
      end = period.end_date;
      this.props.dispatch(
        propertyGroupActions.update({ date_selection: period })
      );
    }
    this.props.dispatch(filtersActions.update({ members: [publicId] }));
    this.props.dispatch(propertyActions.requestProperty(publicId));
    this.props.dispatch(propertyActions.getKPIDataSource(publicId, kpi));
    this.getKPIData(start, end);
  }

  componentDidUpdate(prevProps) {
    const { start_date, end_date } = this.props.date_selection;
    if (
      prevProps.date_selection.start_date !== start_date ||
      prevProps.date_selection.end_date !== end_date
    ) {
      const start = dateFormat(start_date, "yyyy-MM-dd");
      const end = dateFormat(end_date, "yyyy-MM-dd");
      this.getKPIData(start, end);
    }
  }

  getKPIData(start, end) {
    const { publicId, kpi } = this.props.match.params;
    let kpiLabel = kpi;
    const kpiUrlConversion = this.getKpiUrlMatch(kpi);
    if (kpiUrlConversion && kpiUrlConversion in KPI_MATCHER) {
      kpiLabel = KPI_MATCHER[kpiUrlConversion];
    }
    if (VOLUME_KPIS.includes(kpiLabel)) {
      this.props.dispatch(
        propertyActions.getKPIData(publicId, kpiLabel, start, end)
      );
    }
    this.props.dispatch(
      propertyActions.requestNewWidgetData(publicId, this.props.date_selection)
    );
  }

  getKpiUrlMatch(kpi) {
    const kpiUrlConversion = _findKey(KPI_URL_LABEL["property"], function(v) {
      return v === kpi;
    });
    return kpiUrlConversion;
  }

  onChangeDateRange = (preset, startDate, endDate) => {
    let date_selection = {
      preset: preset,
      start_date: startDate,
      end_date: endDate
    };
    this.props.dispatch(propertyGroupActions.update({ date_selection }));
  };

  render() {
    const { category, kpi } = this.props.match.params;
    const startDate = this.props.date_selection.start_date;
    const endDate = this.props.date_selection.end_date;
    const datePreset = this.props.date_selection.preset;
    return (
      <PropertyKPIPage
        kpi={kpi}
        category={category}
        startDate={startDate}
        endDate={endDate}
        datePreset={datePreset}
        property={this.props.property}
        dataSource={this.props.dataSource}
        kpiData={this.props.kpiData}
        onChangeDateRange={this.onChangeDateRange}
        widgetData={this.props.widgetData}
        loaderState={this.props.loaderState}
      />
    );
  }
}

const mapState = state => ({
  date_selection: state.propertyGroup.date_selection,
  property: state.property.property,
  dataSource:
    state.property.dataSource === "Yardi"
      ? "Yardi Systems"
      : state.property.dataSource,
  kpiData: state.property.kpiData,
  widgetData: state.property.newWidgetData,
  loaderState: state.property.loaderState
});

export default withRouter(
  connect(mapState)(withLogin(PropertyKPIPageContainer))
);
