import React, { useEffect, useMemo, useState } from "react";
import { Flex } from "concrete-ui";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import ExpenseForm from "../../components/expense_entry_form";
import { withLogin } from "../shared/login";
import { expenseActions, propertyActions } from "../../redux_base/actions";
import Loader from "../../components/loader";
import _find from "lodash/find";
import { dateFormat } from "../../utils/dates";

const ExpensesContainer = ({
  match,
  history,
  // reducer props
  isPostSuccessful,
  isPosting,
  dispatch,
  // data
  expenses = [],
  vendors = [],
  properties = [],
  leadSources = []
}) => {
  const { expense_id } = match.params;
  const initialExpense = useMemo(
    () => _find(expenses, ["id", expense_id]) || {},
    [expenses, expense_id]
  );
  const [propertyId, setPropertyId] = useState(initialExpense.property_id);

  const isPropertyEnabled = item => item.is_admin;

  const filteredProperties = useMemo(
    () => Object.values(properties).filter(isPropertyEnabled),
    [properties]
  );

  const getCompany = value =>
    _find(filteredProperties, ["property_id", value])?.tag?.group_company_id;

  // property functions

  const mapPropertySelect = ({
    property_id,
    property_name,
    disabled,
    tag = {}
  }) => ({
    value: property_id,
    label: property_name,
    company_id: tag.group_company_id,
    disabled: disabled
  });

  const onChangeProperty = property_id => {
    if (property_id) {
      const startDate = dateFormat(new Date(1970, 0, 1), "yyyy-MM-dd");
      const endDate = dateFormat(new Date(), "yyyy-MM-dd");
      dispatch(
        propertyActions.fetchLeadSources(property_id, startDate, endDate)
      );
      dispatch(
        expenseActions.requestVendors({
          company_id: getCompany(property_id)
        })
      );
    } else {
      dispatch(expenseActions.updateState({ vendors: [] }));
      dispatch(propertyActions.clearLeadSources());
    }
    setPropertyId(property_id);
  };

  useEffect(() => {
    if (!properties.length) {
      dispatch(expenseActions.requestProperties({}));
    }
    if (!expenses.length) {
      dispatch(expenseActions.requestExpenses({}));
    }
    if (expense_id && initialExpense.property_id) {
      onChangeProperty(initialExpense.property_id);
    }
  }, []);

  useEffect(() => {
    initialExpense.property_id && onChangeProperty(initialExpense.property_id);
  }, [expenses]);

  useEffect(() => {
    if (isPostSuccessful) {
      dispatch(expenseActions.updateState({ isPostSuccessful: false }));
      dispatch(expenseActions.requestExpenses({}));
      history.push("/expenses");
    }
  }, [isPostSuccessful]);

  const paidLeadSources = useMemo(() => {
    const sources = new Set();
    for (let e of expenses) {
      if (e.property_id === propertyId) {
        for (let s of e.lead_sources) {
          sources.add(s);
        }
      }
    }
    return sources;
  }, [expenses, propertyId]);

  // lead sources functions

  const mapLeadSources = ({ value, source_name }) => ({
    value: String(value),
    label: source_name
  });

  // submit

  const submitExpense = expense => {
    if (expense.vendor && !expense.vendor.id) {
      expense.vendor.company_id = getCompany(expense.property_id);
    }
    dispatch(expenseActions.requestPostExpense(expense));
  };

  return (
    <Flex flexDirection="column" pb={"12rem"}>
      <ExpenseForm
        initialData={initialExpense}
        vendors={vendors}
        onChangeProperty={onChangeProperty}
        properties={filteredProperties.map(mapPropertySelect)}
        leadSources={leadSources.map(mapLeadSources)}
        paidLeadSources={Array.from(paidLeadSources)}
        confirmExpense={submitExpense}
      />
      <Loader isVisible={isPosting} />
    </Flex>
  );
};

const mapState = state => ({
  ...state.network,
  ...state.expenses,
  leadSources: state.property.leads
});

export default withRouter(connect(mapState)(withLogin(ExpensesContainer)));
