import React from "react";
import PropTypes from "prop-types";
import { Card, Container, Flex, Item, Tab, Text, Divider } from "concrete-ui";

import AdminBreadcrumb from "../admin_breadcrumb";
import Breadcrumbs from "../breadcrumbs";
import DistributionTab from "../distribution_tab";
import ReferralSourcesTab from "../referral_sources_tab";
import RenderIf from "../render_if";
import { TabViews } from "./constants";

class ReferralSources extends React.PureComponent {
  static propTypes = {
    portfolios: PropTypes.array,
    selectedPortfolio: PropTypes.object,
    properties: PropTypes.array,
    fetchingProperties: PropTypes.bool,
    unmatchedSources: PropTypes.array,
    matchedSources: PropTypes.array,
    distributedSources: PropTypes.array,
    undistributedSources: PropTypes.array,
    leadSources: PropTypes.array,
    fetchingLeadSources: PropTypes.bool,
    fetchingLeadSourcesOptions: PropTypes.bool,
    nwv: PropTypes.array,
    fetchingNWVs: PropTypes.bool,
    unbouncedVisitorsOnly: PropTypes.bool,
    saveCompleted: PropTypes.bool,
    fetchProperties: PropTypes.func,
    selectProperty: PropTypes.func,
    selectLeadSource: PropTypes.func,
    fetchDistributionNWV: PropTypes.func,
    clearPropertyState: PropTypes.func,
    onSaveReferralSources: PropTypes.func,
    onSaveDistribution: PropTypes.func,
    leadSourcesOptions: PropTypes.array,
    leadSourcesAttribution: PropTypes.object,
    distSourceSelectionOptions: PropTypes.instanceOf(Set),
    onSelectFilter: PropTypes.func,
    currentTab: PropTypes.string,
    onToggleTab: PropTypes.func
  };

  static defaultProps = {
    portfolios: [],
    selectedPortfolio: null,
    properties: [],
    fetchingProperties: false,
    leadSources: [],
    leadSourcesOptions: [],
    fetchingLeadSources: false,
    fetchingLeadSourcesOptions: false,
    nwv: [],
    distSourceSelectionOptions: new Set([]),
    unbouncedVisitorsOnly: false,
    saveCompleted: false,
    fetchProperties: () => {},
    selectProperty: () => {},
    selectLeadSource: () => {},
    fetchDistributionNWV: () => {},
    clearPropertyState: () => {},
    onSaveReferralSources: () => {},
    onSaveDistribution: () => {}
  };

  render() {
    const breadcrumbs = [
      { text: <AdminBreadcrumb />, link: "" },
      { text: "Referral Sources", link: "" }
    ];
    return (
      <>
        <Container>
          <Flex alignItems="center" height={13}>
            <Breadcrumbs
              breadcrumbs={breadcrumbs}
              containerProps={{ variant: "fullwidth" }}
            />
          </Flex>
        </Container>
        <Divider variant="horizontal" bg="gray.3" />
        <Card mx="auto" mt={10} width={1024} height={825}>
          <Container variant="fullwidth" display="flex" flexDirection="column">
            <Flex pt={5} pb={6} px={6}>
              <Text textSize={4} $fontWeight="medium">
                Manage Referral Sources
              </Text>
            </Flex>
            <Tab borderColor="gray.4">
              <Container fullwidth display="flex">
                <Item
                  size="small"
                  variant="text"
                  selected={this.props.currentTab === TabViews.Match}
                  onClick={() => this.props.onToggleTab(TabViews.Match)}
                >
                  Match Referral Sources
                </Item>
                <Item
                  size="small"
                  variant="text"
                  selected={this.props.currentTab === TabViews.Distribution}
                  onClick={() => this.props.onToggleTab(TabViews.Distribution)}
                >
                  Distribute Lead Sources
                </Item>
              </Container>
            </Tab>
            <Flex flexDirection="column" flexGrow={1} minHeight={0}>
              <RenderIf condition={this.props.currentTab === TabViews.Match}>
                <ReferralSourcesTab
                  portfolios={this.props.portfolios}
                  selectedPortfolio={this.props.selectedPortfolio}
                  properties={this.props.properties}
                  fetchingProperties={this.props.fetchingProperties}
                  unmatchedSources={this.props.unmatchedSources}
                  matchedSources={this.props.matchedSources}
                  leadSourcesAttribution={this.props.leadSourcesAttribution}
                  fetchingLeadSources={this.props.fetchingLeadSources}
                  fetchingLeadSourcesOptions={
                    this.props.fetchingLeadSourcesOptions
                  }
                  nwv={this.props.nwv}
                  fetchingNWVs={this.props.fetchingNWVs}
                  unbouncedVisitorsOnly={this.props.unbouncedVisitorsOnly}
                  saveCompleted={this.props.saveCompleted}
                  distSourceSelectionOptions={
                    this.props.distSourceSelectionOptions
                  }
                  fetchProperties={this.props.fetchProperties}
                  selectProperty={this.props.selectProperty}
                  selectLeadSource={this.props.selectLeadSource}
                  clearPropertyState={this.props.clearPropertyState}
                  onSave={this.props.onSaveReferralSources}
                  onSelectFilter={this.props.onSelectFilter}
                />
              </RenderIf>
              <RenderIf
                condition={this.props.currentTab === TabViews.Distribution}
              >
                <DistributionTab
                  portfolios={this.props.portfolios}
                  selectedPortfolio={this.props.selectedPortfolio}
                  properties={this.props.properties}
                  fetchingProperties={this.props.fetchingProperties}
                  undistributedSources={this.props.undistributedSources}
                  distributedSources={this.props.distributedSources}
                  leadSources={this.props.leadSources}
                  leadSourcesAttribution={this.props.leadSourcesAttribution}
                  leadSourcesOptions={this.props.leadSourcesOptions}
                  nwvSources={this.props.nwv}
                  fetchingNWVs={this.props.fetchingNWVs}
                  unbouncedVisitorsOnly={this.props.unbouncedVisitorsOnly}
                  fetchProperties={this.props.fetchProperties}
                  fetchingLeadSources={this.props.fetchingLeadSources}
                  fetchingLeadSourcesOptions={
                    this.props.fetchingLeadSourcesOptions
                  }
                  selectProperty={this.props.selectProperty}
                  fetchLeadSources={this.props.selectProperty}
                  fetchNWV={this.props.fetchDistributionNWV}
                  onSave={this.props.onSaveDistribution}
                  selectLeadSource={this.props.selectLeadSource}
                  saveCompleted={this.props.saveCompleted}
                  clearPropertyState={this.props.clearPropertyState}
                  distSourceSelectionOptions={
                    this.props.distSourceSelectionOptions
                  }
                  onSelectFilter={this.props.onSelectFilter}
                />
              </RenderIf>
            </Flex>
          </Container>
        </Card>
      </>
    );
  }
}

export default ReferralSources;
