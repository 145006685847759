import React from "react";
import PropTypes from "prop-types";
import { Box, Flex, Icon, Item, Text, Tooltip } from "concrete-ui";

import { formatDateString } from "../../../utils/formatters";
import {
  DATETIME_FORMAT,
  ZENDESK_DELETE_PMS,
  getPMSDeleteTooltipText
} from "../../../constants";
import MiniMenu from "../../mini_menu";

const DeleteItem = ({ onClick, disabled, ...otherProps }) => (
  <Item
    size="small"
    variant="danger"
    onClick={onClick}
    disabled={disabled}
    {...otherProps}
  >
    <Icon.Trash size={3} $color="text.orange.0" />
    Delete PMS Instance
  </Item>
);
DeleteItem.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool
};
DeleteItem.defaultProps = {
  onClick: () => {},
  disabled: false
};

const AdminConnectionsPMSMiniMenu = ({ onEdit, onDelete, data }) => {
  return (
    <Flex justifyContent="space-between" alignItems="center">
      <Text textSize={2} $fontWeight="regular" $color="text.white.1">
        {data.last_updated
          ? formatDateString(data.last_updated, DATETIME_FORMAT)
          : "\u2014"}
      </Text>
      <MiniMenu>
        <Item size="small" onClick={() => onEdit(data.id)}>
          <Icon.Edit size={3} />
          Edit PMS Instance
        </Item>
        <MiniMenu.Divider />
        {data.connections.length ? (
          <Box alignItems="center">
            <DeleteItem mr={0} disabled={true} />
            <Tooltip
              title={getPMSDeleteTooltipText(data.connections.length)}
              placement="top"
              size="small"
              iconSize="small"
              linkText="Learn more."
              link={ZENDESK_DELETE_PMS}
              linkProps={{
                target: "_blank"
              }}
            />
          </Box>
        ) : (
          <DeleteItem onClick={() => onDelete(data.id)} />
        )}
      </MiniMenu>
    </Flex>
  );
};

AdminConnectionsPMSMiniMenu.propTypes = {
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  data: PropTypes.object
};

AdminConnectionsPMSMiniMenu.defaultProps = {
  onEdit: () => {},
  onDelete: () => {},
  data: {}
};

export default AdminConnectionsPMSMiniMenu;
