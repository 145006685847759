export const PROPERTY_CHART_TEXT = {
  // Performance
  exposure_rate: {
    chartLabel:
      "The most recent property exposure rate in date range selected, charted daily.",
    tooltip:
      "Sum of Vacant Unrented property units (including Ready and Not Ready) plus the sum of Occupied property Notice Unrented units, divided by the sum of property units. Based on data ingested daily via our Yardi Voyager integration."
  },
  inq_exe: {
    chartLabel:
      "The property conversion rate over weeks in date range selected, charted weekly.",
    tooltip:
      "Sum of New Leases divided by sum of Leads, in date range selected. Funnel event-based analysis, not Lead cohort-based analysis (coming soon). Based on data ingested daily via our Yardi Voyager integration."
  },
  lease_cd_rate: {
    chartLabel:
      "The property Cancellation & Denial rate over weeks in date range selected, charted weekly.",
    tooltip:
      "Sum of Cancellations plus Denials, divided by sum of Lease Applications. Based on data ingested daily via our Yardi Voyager integration."
  },
  leased_rate: {
    chartLabel:
      "The most recent property leased rate in date range selected, charted daily.",
    tooltip:
      "Sum of Occupied property units (including those On Notice) plus the sum of Vacant Rented units, divided by the sum of property units. Based on data ingested daily via our Yardi Voyager integration."
  },
  occupancy_rate: {
    chartLabel:
      "The most recent property occupancy rate in date range selected, charted daily.",
    tooltip:
      "Sum of Occupied property units (including those On Notice), divided by the sum of property units. Based on data ingested daily via our Yardi Voyager integration."
  },
  // Upper funnel
  usvs: {
    chartLabel:
      "The sum of property New Website Visitor traffic generated in date range selected, charted daily.",
    tooltip:
      "Based on property website 'New Users' data ingested daily via our Google Analytics integration. "
  },
  usv_inq: {
    chartLabel:
      "The property conversion rate over weeks in date range selected, charted weekly.",
    tooltip:
      "Sum of Leads divided by sum of New Website Visitors, in date range selected. Funnel event-based analysis, not Lead cohort-based analysis (coming soon). "
  },
  inquiries: {
    chartLabel:
      "The sum of property Leads generated in date range selected, charted daily.",
    tooltip:
      "Based on property ILS/Guest Card data ingested daily via our Yardi Voyager integration."
  },
  inq_tou: {
    chartLabel:
      "The property conversion rate over weeks in date range selected, charted weekly.",
    tooltip:
      "Sum of Tours divided by sum of Leads, in date range selected. Funnel event-based analysis, not Lead cohort-based analysis (coming soon). "
  },
  // Middle funnel
  tours: {
    chartLabel:
      "The sum of property Tours completed in date range selected, charted daily.",
    tooltip:
      "Based on property ILS/Guest Card data ingested daily via our Yardi Voyager integration."
  },
  tou_app: {
    chartLabel:
      "The property conversion rate over weeks in date range selected, charted weekly.",
    tooltip:
      "Sum of Lease Applications divided by sum of Tours, in date range selected. Funnel event-based analysis, not Lead cohort-based analysis (coming soon). "
  },
  lease_applications: {
    chartLabel:
      "The sum of property Lease Applications received in date range selected, charted daily.",
    tooltip:
      "Based on Property ILS/Guest Card data ingested daily via our Yardi Voyager integration."
  },
  app_approvals: {
    chartLabel:
      "The property conversion rate over weeks in date range selected, charted weekly.",
    tooltip:
      "Sum of Approved Leases divided by sum of Lease Applications, in date range selected. Funnel event-based analysis, not Lead cohort-based analysis (coming soon). "
  },
  // Lower funnel
  approvals: {
    chartLabel:
      "The sum of property Approved Leases generated in date range selected, charted daily.",
    tooltip:
      "Based on property ILS/Guest Card data ingested daily via our Yardi Voyager integration."
  },
  approved_exe: {
    chartLabel:
      "The property conversion rate over weeks in date range selected, charted weekly.",
    tooltip:
      "Sum of New Leases divided by sum of Approved Leases, in date range selected. Funnel event-based analysis, not Lead cohort-based analysis (coming soon). "
  },
  leases_executed: {
    chartLabel:
      "The sum of property New Leases secured in date range selected, charted daily.",
    tooltip:
      "Based on property ILS/Guest Card data ingested daily via our Yardi Voyager integration."
  },
  exe_ins: {
    chartLabel:
      "The property conversion rate over weeks in date range selected, charted weekly.",
    tooltip:
      "Sum of Move Ins divided by sum of New Leases, in date range selected. Funnel event-based analysis, not Lead cohort-based analysis (coming soon). "
  },
  move_ins: {
    chartLabel:
      "The sum of property Move-Ins completed in date range selected, charted daily.",
    tooltip:
      "Based on property data ingested daily via our Yardi Voyager integration."
  },
  // Retention
  lease_renewals: {
    chartLabel:
      "The sum of resident Renewals captured in date range selected, charted daily.",
    tooltip:
      "Based on property data ingested daily via our Yardi Voyager integration."
  },
  lease_vacation_notices: {
    chartLabel:
      "The sum of resident Notices to Vacate received in date range selected, charted daily.",
    tooltip:
      "Based on property data ingested daily via our Yardi Voyager integration."
  },
  move_outs: {
    chartLabel:
      "The sum of resident Move Outs completed in date range selected, charted daily.",
    tooltip:
      "Based on property data ingested daily via our Yardi Voyager integration."
  }
};
