import { createAjaxAction } from "./helpers";

export default {
  fetch: createAjaxAction(
    "AJAX_GET_COMPLETE_ACCOUNT",
    "/users/complete-account/"
  ),
  post: body =>
    createAjaxAction(
      "AJAX_POST_COMPLETE_ACCOUNT",
      "/users/complete-account/"
    )({ body }),
  redirect: url => ({
    type: "COMPLETE_ACCOUNT_REDIRECT",
    url
  }),
  clearErrors: () => ({ type: "COMPLETE_ACCOUNT_CLEAR_ERRORS" })
};
