import React from "react";
import { Box, Icon, Text } from "concrete-ui";

export default ({ message, ...props }) => (
  <Box alignItems="center" {...props}>
    <Icon.CheckCircle width={4} height={4} $color="green.1" />
    <Text
      textSize={2}
      $color="text.green.0"
      $fontWeight="regular"
      ml={2}
      mr={5}
    >
      {message}
    </Text>
  </Box>
);
