import React from "react";
import EnhancedLink from "./../enhanced_link";
import checkFlag from "../../utils/posthog";

import "./top_navigation.scss";
import { Dropdown, Icon, Item, Menu } from "concrete-ui";

class AdminButton extends React.PureComponent {
  render() {
    return (
      <>
        <Dropdown
          size="small"
          label="Admin"
          ml={4}
          width={"6rem"}
          maxHeight={6}
          icon={<Icon.Settings $color="white.1" />}
        >
          {({ props }) => (
            <Menu {...props} width="9.5rem">
              <EnhancedLink
                className="top-navigation__button"
                to="/admin-properties"
              >
                <Item size="small">Properties</Item>
              </EnhancedLink>
              <EnhancedLink
                className="top-navigation__button"
                to="/admin-connections"
              >
                <Item size="small">Connections</Item>
              </EnhancedLink>
              <EnhancedLink className="top-navigation__button" to="/expenses">
                <Item size="small">Expenses</Item>
              </EnhancedLink>
              <EnhancedLink
                className="top-navigation__button"
                to="/referral-sources/match"
              >
                <Item size="small">Referral Sources</Item>
              </EnhancedLink>
            </Menu>
          )}
        </Dropdown>
      </>
    );
  }
}

export default AdminButton;
