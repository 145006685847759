import { filters, propertyActions } from "../actions";

export const fetchWidgetData = store => next => async action => {
  next(action);
  if (action.type === "AJAX_GET_PROPERTY_GROUP_SUCCESS") {
    const { propertyGroup } = store.getState();
    const properties = propertyGroup.table_data.map(data => data.id);
    next(filters.update({ members: properties }));
    if (properties.length !== 0) {
      const _startDate = new Date(propertyGroup.date_selection?.start_date);
      const _endDate = new Date(propertyGroup.date_selection?.end_date);
      const params = {
        property_ids: properties.join(","),
        kpis: [
          "occupancy_rate",
          "leased_rate",
          "exposure_rate",
          "inq_exe",
          "lease_cd_rate",
          "usvs",
          "inquiries",
          "tours",
          "lease_applications",
          "approvals",
          "leases_executed"
        ].join(","),
        start: `${_startDate.getFullYear()}-${_startDate.getMonth() +
          1}-${_startDate.getDate()}`,
        end: `${_endDate.getFullYear()}-${_endDate.getMonth() +
          1}-${_endDate.getDate()}`,
        // we use the 'combined' option since its a group
        combined: true
      };
      next(propertyActions.requestWidgetData(params));
    }
  }
};
