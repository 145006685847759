import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import { ChevronRight } from "../../icons";
import InviteModal from "../../containers/invite_modal";
import Container from "../container";
import DateRangeSelector from "../date_range_selector";
import Select from "../select";
import InsightsButton from "../details_button/insights_button";
import TabNavigator from "../tab_navigator";
import RenderIf from "../render_if";
import DetailsButton from "../details_button";
import Loader from "../loader";
import UserIconList from "../user_icon_list";
import ViewMembersModal from "../members_modal/view_members";
import ChannelAnalysis from "../channel_analysis";
import PropertyPerformance from "./widgets_performance";
import PropertyUpperFunnel from "./widgets_upper_funnel";
import PropertyMiddleFunnel from "./widgets_middle_funnel";
import PropertyLowerFunnel from "./widgets_lower_funnel";
import PropertyRetention from "./widgets_retention";
import PropertyDetails from "./property_details";
import checkFlag from "../../utils/posthog";
import { Text } from "concrete-ui";
import "./property_page.scss";

const track = (evt, args = {}) => {
  return undefined;
};

export default class PropertyPage extends React.PureComponent {
  static propTypes = {
    onChangeDateRange: PropTypes.func.isRequired,
    onChangeProperty: PropTypes.func.isRequired,
    onChangeTab: PropTypes.func.isRequired,
    onOpenInvite: PropTypes.func.isRequired,
    onCATabsToggle: PropTypes.func.isRequired,
    onCAShowDistributedSource: PropTypes.func.isRequired,
    date_selection: PropTypes.shape({
      preset: PropTypes.string.isRequired,
      start_date: PropTypes.instanceOf(Date).isRequired,
      end_date: PropTypes.instanceOf(Date).isRequired
    }),
    property: PropTypes.object,
    groupProperties: PropTypes.arrayOf(PropTypes.object),
    members: PropTypes.arrayOf(PropTypes.object),
    custom_tags: PropTypes.arrayOf(PropTypes.object),
    selectedIndex: PropTypes.number,
    isAdmin: PropTypes.bool,
    widgetReady: PropTypes.bool,
    isFetching: PropTypes.bool,
    isCATableLoading: PropTypes.bool,
    CATableData: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    CADistributedTableData: PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.object
    ]),
    CATableSummaryData: PropTypes.object,
    CASummaryData: PropTypes.object,
    CARankingsData: PropTypes.object,
    CADistributedRankingsData: PropTypes.object,
    CAExposureRankingsData: PropTypes.object,
    expensesLastUpdated: PropTypes.object, // date
    tabId: PropTypes.string,
    attributionsLastUpdated: PropTypes.string, // date
    byExpenseFilters: PropTypes.array
  };

  static defaultProps = {
    property: {},
    groupProperties: [],
    widgetReady: false,
    newWidgetData: [],
    CATableData: [],
    CADistributedTableData: [],
    loaderState: true
  };

  constructor(props) {
    super(props);
    this.state = {
      isShowDetails: false,
      isInsightsActive: false,
      isViewMembers: false
    };
  }

  // TODO We should not be relying on table data to get property options. Hack for now.
  get propertyOptions() {
    return this.props.groupProperties.map(entry => ({
      label: entry.name,
      value: entry.id
    }));
  }

  get currentPropertyOption() {
    const { property } = this.props;
    if (!property.public_id) {
      return { value: null };
    }
    return { label: property.name, value: property.public_id };
  }

  toggleInsights = () => {
    const isInsightsActive = !this.state.isInsightsActive;
    track(isInsightsActive ? "Insights Activated" : "Insights Deactivated", {
      component: "PropertyPage"
    });
    this.setState({ isInsightsActive });
  };

  toggleDetails = () => {
    const isShowDetails = !this.state.isShowDetails;
    track(isShowDetails ? "Show Details" : "Hide Details", {
      component: "PropertyPage"
    });

    this.setState({ isShowDetails });
  };

  openMembersModal = () => this.setState({ isViewMembers: true });
  closeMembersModal = () => this.setState({ isViewMembers: false });

  onChangeTabHandler = selectedIndex => {
    this.props.onChangeTab(selectedIndex);
  };

  render() {
    const {
      date_selection,
      property,
      members,
      custom_tags,
      isAdmin,
      newWidgetData,
      loaderState,
      tabId
    } = this.props;
    const { isInsightsActive, isShowDetails } = this.state;
    const propertyId = property.public_id;
    const propertyName = property.name;
    const onChannelAnalysisTab = tabId === "channel_analysis";
    return (
      <>
        <div className="property-wrapper">
          <Container className="property">
            <div className="property__banner">
              <div className="property__header">
                <div className="property__nav">
                  <Link
                    className="property__group-link"
                    to={`/property-group/${property.group_id}/performance`}
                  >
                    <div className="property__group-name">
                      {property.group_name}
                    </div>
                  </Link>
                  <div className="property__chevron">
                    <ChevronRight />
                  </div>
                  <Select
                    className="property__select-property"
                    size="small"
                    theme="default"
                    options={this.propertyOptions}
                    onChange={this.props.onChangeProperty}
                    value={this.currentPropertyOption}
                  />
                </div>
                <div className="property__row">
                  <div className="property__title">
                    <span>{property.name}</span>
                  </div>
                  <DetailsButton
                    id="property-page__details-button"
                    className="property__view-details"
                    active={false}
                    onClick={this.toggleDetails}
                  >
                    {isShowDetails ? "Hide Details" : "View Details"}
                  </DetailsButton>
                  <div className="property__section-right">
                    <RenderIf condition={!onChannelAnalysisTab}>
                      <InsightsButton
                        id="rmb-property__insights-button"
                        className="property__insights property__insights--right-space"
                        onClick={this.toggleInsights}
                        active={isInsightsActive}
                        disabled={onChannelAnalysisTab}
                      />
                    </RenderIf>
                    {!checkFlag("invite-users") ? null : (
                      <UserIconList
                        id="rmb-property-page__user-icon-list"
                        className="property__members"
                        theme="property"
                        tooltipPlacement="bottom"
                        tooltipTheme="dark"
                        users={members}
                        isAdmin={isAdmin}
                        onClick={this.openMembersModal}
                        onAdd={this.props.onOpenInvite}
                      />
                    )}
                  </div>
                </div>
                {property.type === "SyntheticProject" && (
                  <Text
                    style={{
                      color: "#006EFF",
                      fontStyle: "italic",
                      marginLeft: "5px"
                    }}
                  >
                    ({property.all_project_names})
                  </Text>
                )}
                <RenderIf condition={isShowDetails}>
                  {() => (
                    <PropertyDetails
                      id="rmb-property-page__property-details"
                      property={property}
                      custom_tags={custom_tags}
                      isAdmin={isAdmin}
                    />
                  )}
                </RenderIf>
              </div>
              <div className="property__control-row">
                <DateRangeSelector
                  id="rb-property-page__date-range-selector"
                  startDate={date_selection.start_date}
                  endDate={date_selection.end_date}
                  presetValue={date_selection.preset}
                  onChange={this.props.onChangeDateRange}
                />
              </div>
            </div>
          </Container>
        </div>
        <TabNavigator
          className="property__tabs-link"
          selectedIndex={this.props.selectedIndex}
          onChange={this.onChangeTabHandler}
        >
          {checkFlag("channel-analysis") ? (
            <TabNavigator.Tab label="Channel Analysis">
              <div className="property__widgets">
                <ChannelAnalysis
                  loading={loaderState}
                  tableLoading={this.props.isCATableLoading}
                  tableData={this.props.CATableData}
                  distributedTableData={this.props.CADistributedTableData}
                  tableSummaryData={this.props.CATableSummaryData}
                  expensesLastUpdated={this.props.expensesLastUpdated}
                  summaryData={this.props.CASummaryData}
                  onTabsToggle={this.props.onCATabsToggle}
                  onShowDistributedSource={this.props.onCAShowDistributedSource}
                  startDate={date_selection.start_date}
                  endDate={date_selection.end_date}
                  propertyName={property.name}
                  propertyId={propertyId}
                  attributionsLastUpdated={this.props.attributionsLastUpdated}
                  rankingsData={this.props.CARankingsData}
                  distributedRankingsData={this.props.CADistributedRankingsData}
                  exposureRankingsData={this.props.CAExposureRankingsData}
                  byExpenseFilters={this.props.byExpenseFilters}
                />
              </div>
            </TabNavigator.Tab>
          ) : null}
          <TabNavigator.Tab label="Performance">
            <div className="property__widgets">
              <PropertyPerformance
                id="rb-property-page__property-performance-tab"
                date_selection={date_selection}
                isShowInsights={isInsightsActive}
                propertyId={propertyId}
                propertyName={propertyName}
                newWidgetData={newWidgetData}
                loaderState={loaderState}
              />
            </div>
          </TabNavigator.Tab>
          <TabNavigator.Tab label="Upper Funnel">
            <div className="property__widgets">
              <PropertyUpperFunnel
                id="rb-property-page__property-upper-funnel-tab"
                date_selection={date_selection}
                isShowInsights={isInsightsActive}
                propertyId={propertyId}
                propertyName={propertyName}
                newWidgetData={newWidgetData}
                loaderState={loaderState}
              />
            </div>
          </TabNavigator.Tab>
          <TabNavigator.Tab label="Middle Funnel">
            <div className="property__widgets">
              <PropertyMiddleFunnel
                id="rb-property-page__property-middle-funnel-tab"
                date_selection={date_selection}
                isShowInsights={isInsightsActive}
                propertyId={propertyId}
                propertyName={propertyName}
                newWidgetData={newWidgetData}
                loaderState={loaderState}
              />
            </div>
          </TabNavigator.Tab>
          <TabNavigator.Tab label="Lower Funnel">
            <div className="property__widgets">
              <PropertyLowerFunnel
                id="rb-property-page__property-lower-funnel-tab"
                date_selection={date_selection}
                isShowInsights={isInsightsActive}
                propertyId={propertyId}
                propertyName={propertyName}
                newWidgetData={newWidgetData}
                loaderState={loaderState}
              />
            </div>
          </TabNavigator.Tab>
          {checkFlag("retention") ? (
            <TabNavigator.Tab label="Retention">
              <div className="property__widgets">
                <PropertyRetention
                  id="rb-property-page__property-retention-tab"
                  date_selection={date_selection}
                  isShowInsights={isInsightsActive}
                  propertyId={propertyId}
                  propertyName={propertyName}
                  newWidgetData={newWidgetData}
                  loaderState={loaderState}
                />
              </div>
            </TabNavigator.Tab>
          ) : null}
        </TabNavigator>
        <ViewMembersModal
          id="rb-property-page__property-view-members-modal"
          isOpen={this.state.isViewMembers}
          property_name={property.name}
          members={members}
          onClose={this.closeMembersModal}
        />
        <InviteModal />
      </>
    );
  }
}
