import React from "react";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  Flex,
  Icon,
  RadioGroup,
  Select,
  ToggleSwitch,
  Tooltip,
  Text
} from "concrete-ui";
import _intersection from "lodash/intersection";

import { getEndDatesByPreset, getStartDatesByPreset } from "../../utils/dates";
import useValidation from "../../utils/validator_hook";
import Breadcrumbs from "../breadcrumbs";
import {
  CreateForm,
  CreateFormHeader,
  CreateFormSection
} from "../create_form";
import DateRangeSelector from "../date_range_selector";
import Loader from "../loader";
import { ErrorItem, SuccessItem } from "../message_items";
import MultiSelect from "../multi_select";
import { createReportConfig } from "./validators";

import "./create_report_form.scss";

const contentOptions = [
  { label: "Performance", value: "performance" },
  { label: "Funnel Volumes", value: "funnel_volumes" },
  {
    label: "Funnel Conversion Rates",
    value: "funnel_conversion_rates"
  },
  { label: "Channel Volumes", value: "channel_volumes" },
  {
    label: "Channel Conversion Rates",
    value: "channel_conversion_rates"
  },
  { label: "Channel Cost Pers", value: "channel_cost_pers" },
  {
    label: "Channel Revenue and ROI",
    value: "channel_revenue_roi"
  },
  { label: "Channel Units", value: "channel_units" }
];

const channelAnalysisContentValues = [
  "channel_volumes",
  "channel_conversion_rates",
  "channel_cost_pers",
  "channel_revenue_roi",
  "channel_units"
];

const orientationOptions = [
  {
    value: "portrait",
    id: "portrait",
    label: "Portrait (Vertical)",
    name: "orientation",
    isDisabled: false
  },
  {
    value: "landscape",
    id: "landscape",
    label: "Landscape (Horizontal)",
    name: "orientation",
    isDisabled: false
  }
];

const dateRangePresets = [
  "last_week",
  "last_month",
  "month_to_date",
  "last_quarter",
  "quarter_to_date",
  "last_year",
  "year_to_date",
  "custom"
];

export const CreateReportBreadcrumb = () => {
  return (
    <>
      <Icon.Report size={4} $color="inherit" />
      <Text ml={1} textSize={3} $fontWeight="regular" $color="inherit">
        Create Report
      </Text>
    </>
  );
};

const CommonMessage = ({ success, isFormErrors }) => {
  let item = null;
  if (isFormErrors) {
    item = (
      <ErrorItem message="Please correct the errors above before continuing." />
    );
  } else if (success === true) {
    item = <SuccessItem message="Your report was successfully sent" />;
  } else if (success === false) {
    item = (
      <ErrorItem message="Oops, something went wrong. Please contact Support." />
    );
  }
  return item;
};

const checkDistributedEnabled = values =>
  _intersection(channelAnalysisContentValues, values).length > 0;

const CreateReportForm = ({
  userEmail,
  properties = [],
  confirm = v => v,
  initialData = {},
  isLoading = false,
  success = null,
  distributedSourcesByProject = {}
}) => {
  const defaultDatesPreset = "last_week";
  const datesReportingDay = "M";
  const defaultData = {
    property_id: null,
    start_date: getStartDatesByPreset(datesReportingDay)[defaultDatesPreset],
    end_date: getEndDatesByPreset(datesReportingDay)[defaultDatesPreset],
    content: contentOptions.map(item => item.value),
    show_distribution: true,
    orientation: "landscape"
  };
  const { errors, getFormProps, getItemProps, setFormItems } = useValidation(
    createReportConfig,
    {
      ...defaultData,
      ...initialData
    },
    confirm
  );

  const breadcrumbs = [
    {
      text: <CreateReportBreadcrumb />
    }
  ];

  const propertyProps = getItemProps("property_id");
  const startDateProps = getItemProps("start_date");
  const endDateProps = getItemProps("end_date");
  const contentProps = getItemProps("content");
  const showDistributedProps = getItemProps("show_distribution");
  const orientationProps = getItemProps("orientation");
  const isDistributedEnabled =
    checkDistributedEnabled(contentProps.value) &&
    propertyProps.value in distributedSourcesByProject;
  const fieldsContainerProps = { width: "31rem" };
  const showDistributionToggleValue = () => {
    if (!isDistributedEnabled) {
      if (showDistributedProps.value) {
        showDistributedProps.onChange(false);
      }
    }
    return showDistributedProps.value;
  };
  const onSelectProperty = property => {
    if (
      checkDistributedEnabled(contentProps.value) &&
      property in distributedSourcesByProject
    ) {
      showDistributedProps.onChange(true);
    }
    propertyProps.onChange(property);
  };
  return (
    <>
      <Loader isVisible={isLoading} />
      <Box bg="gray.1" width="100%" display="block">
        <Breadcrumbs breadcrumbs={breadcrumbs} />
      </Box>
      <Box width="62.5rem" mx="auto" mb="12rem">
        <CreateForm formProps={getFormProps()}>
          <CreateFormHeader>
            <Text textSize={4} $color="text.white.1" $fontWeight="medium">
              Create Report
            </Text>
          </CreateFormHeader>
          <CreateFormSection
            number="1"
            titleText="Choose Property"
            titleSummary="Choose a Property from your Portfolio to report on."
            fieldsContainerProps={fieldsContainerProps}
          >
            <Select
              width="100%"
              size="large"
              items={properties}
              {...propertyProps}
              onSelect={onSelectProperty}
              error={!!errors.property_id}
              errorMessage={errors.property_id}
            />
          </CreateFormSection>
          <CreateFormSection
            number="2"
            titleText="Choose Date Range"
            titleSummary="Choose the date range you want to report on."
            fieldsContainerProps={fieldsContainerProps}
          >
            <DateRangeSelector
              className="create_report_form__date_range"
              theme="form-field"
              presets={dateRangePresets}
              reportingDay={datesReportingDay}
              presetValue={defaultDatesPreset}
              startDate={startDateProps.value}
              endDate={endDateProps.value}
              onChange={(preset, startDate, endDate) =>
                setFormItems({ start_date: startDate, end_date: endDate })
              }
            />
          </CreateFormSection>
          <CreateFormSection
            number="3"
            titleText="Choose Content"
            titleSummary="Choose the content you want to include in your report."
            fieldsContainerProps={{
              ...fieldsContainerProps,
              flexDirection: "column"
            }}
          >
            <MultiSelect
              width="100%"
              placeholder="Select Content"
              value={contentProps.value}
              options={contentOptions}
              onChange={contentProps.onChange}
              onBlur={contentProps.onBlur}
              errorMessage={errors.content}
            />
            <Flex mt={4}>
              <Box mr={4} alignItems="center">
                <Text textSize={2} $color="text.gray.1">
                  Show Distributed Source Attribution
                </Text>
                <Tooltip
                  placement="top"
                  size="small"
                  title="Toggle ‘on’ to report on Channel Analysis sources that property website Lead sources were distributed to. Admins must distribute at least one property website lead source to enable this toggle."
                  ml={2}
                />
              </Box>
              <ToggleSwitch
                name="show_distribution"
                hasLabel={false}
                disabled={!isDistributedEnabled}
                value={showDistributionToggleValue()}
                onChange={showDistributedProps.onChange}
              />
            </Flex>
          </CreateFormSection>
          <CreateFormSection
            number="4"
            titleText="Choose Orientation"
            titleSummary="Choose the orientation you want for your report."
            fieldsContainerProps={fieldsContainerProps}
          >
            <RadioGroup
              title=""
              options={orientationOptions}
              activeRadioId={orientationProps.value}
              itemsProps={{}}
              errorMessage=""
              onChange={v => () => orientationProps.onChange(v)}
            />
          </CreateFormSection>
          <CreateFormSection
            number="5"
            titleText="Send Report"
            titleSummary="Click the ‘Send Report' button to send the report to your email address."
            fieldsContainerProps={{
              ...fieldsContainerProps,
              flexDirection: "column"
            }}
          >
            <Box>
              <Text textSize={2} $color="text.gray.1">
                A report will be sent to:
              </Text>
              <Text ml="0.5rem" textSize={2} $color="text.white.1">
                {userEmail}
              </Text>
            </Box>
            <Flex mt={4} alignItems="center" justifyContent="flex-end">
              <CommonMessage
                success={success}
                isFormErrors={Object.keys(errors).length > 0}
              />
              <Button
                size="large"
                variant="primary"
                type="submit"
                style={{ flex: "0 0 auto" }}
              >
                Send Report
              </Button>
            </Flex>
          </CreateFormSection>
        </CreateForm>
      </Box>
    </>
  );
};

CreateReportForm.propTypes = {
  userEmail: PropTypes.string.isRequired,
  initialData: PropTypes.object,
  properties: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any.isRequired,
      label: PropTypes.any
    })
  ),
  confirm: PropTypes.func,
  isLoading: PropTypes.bool,
  success: PropTypes.bool
};

export default CreateReportForm;
