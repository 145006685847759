import PropTypes from "prop-types";
import React from "react";
import Tags from "../../containers/tags";
import RenderIf from "../render_if";
import "./property_details.scss";

const defaultSymbol = "\u2014";

const valueOrDefault = v => {
  return v || defaultSymbol; // em dash
};

const PropertyDetails = ({ property, custom_tags, isAdmin }) => (
  <div className="property-details">
    <div className="property-details__row">
      <div className="property-details__block">
        <div className="property-details__field">
          <div className="property-details__label">Owner</div>
          <div className="property-details__value">
            {valueOrDefault(property.property_owner)}
          </div>
        </div>
        <div className="property-details__field">
          <div className="property-details__label">Stage</div>
          <div className="property-details__value">
            {valueOrDefault(property.lease_stage)}
          </div>
        </div>
        <div className="property-details__field">
          <div className="property-details__label">Address</div>
          <div className="property-details__value">
            {valueOrDefault(property.address)}
          </div>
        </div>
      </div>
      <div className="property-details__block">
        <div className="property-details__field">
          <div className="property-details__label">Class</div>
          <div className="property-details__value">
            {valueOrDefault(property.building_class)}
          </div>
        </div>
        <div className="property-details__field">
          <div className="property-details__label">Number of Units</div>
          <div className="property-details__value">
            {valueOrDefault(property.total_units)}
          </div>
        </div>
        <div className="property-details__field">
          <div className="property-details__label">Year Built</div>
          <div className="property-details__value">
            {valueOrDefault(property.year_built)}
          </div>
        </div>
      </div>
      <div className="property-details__block">
        <div className="property-details__field">
          <div className="property-details__label">Category</div>
          <div className="property-details__value">
            {valueOrDefault(property.property_type)}
          </div>
        </div>
        <div className="property-details__field">
          <div className="property-details__label">Style</div>
          <div className="property-details__value">
            {valueOrDefault(property.property_style)}
          </div>
        </div>
        <div className="property-details__field">
          <div className="property-details__label">Website</div>
          <div className="property-details__value">
            <RenderIf condition={!!property.url}>
              <a
                className="property-details__url"
                target="_blank"
                href={property.url}
              >
                View ↗
              </a>
            </RenderIf>
            <RenderIf condition={!property.url}>{defaultSymbol}</RenderIf>
          </div>
        </div>
      </div>
    </div>
    <Tags />
  </div>
);
PropertyDetails.propTypes = {
  property: PropTypes.object.isRequired,
  custom_tags: PropTypes.array.isRequired,
  isAdmin: PropTypes.bool
};

export default PropertyDetails;
