export const ZENDESK_REVENUE_ROI_DATA_LIMITATION =
  "https://remarkablyhelp.zendesk.com/hc/en-us/articles/4417370779283-Why-am-I-missing-some-historical-Gross-Rent-Projected-New-Lease-Revenue-and-ROI-data-for-some-Sources-";

export const ZENDESK_DISTRIBUTION_SUGGESTION =
  "https://remarkablyhelp.zendesk.com/hc/en-us/articles/4416275934099-Why-and-how-should-I-distribute-the-Property-Website-Lead-source-for-Channel-Analysis-";

export const ZENDESK_HOW_TO_MATCH_REFERRAL_SOURCES =
  "https://remarkablyhelp.zendesk.com/hc/en-us/articles/4416098651283-Why-and-how-should-I-match-my-referral-sources-for-Channel-Analysis-";

export const ZENDESK_HOW_TO_DISTRIBUTE_REFERRAL_SOURCES =
  "https://remarkablyhelp.zendesk.com/hc/en-us/articles/4416275934099-Why-and-how-should-I-distribute-the-Property-Website-Lead-source-for-Channel-Analysis-";

export const ZENDESK_RANKINGS_TOP_SOURCES =
  "https://remarkablyhelp.zendesk.com/hc/en-us/articles/5357185223059-How-are-Top-and-Bottom-Sources-ranked-";

export const ZENDESK_RANKINGS_BOTTOM_SOURCES =
  "https://remarkablyhelp.zendesk.com/hc/en-us/articles/5357185223059-How-are-Top-and-Bottom-Sources-ranked-";

export const ZENDESK_RANKINGS_TOP_EXPOSURE_SOURCES =
  "https://remarkablyhelp.zendesk.com/hc/en-us/articles/5358484049939-How-are-Best-Sources-for-Exposure-ranked-";

export const ZENDESK_CHANNEL_ANALYSIS_FILTER_BY_EXPENSES =
  "https://remarkablyhelp.zendesk.com/hc/en-us/articles/7980716277395-How-does-Filtering-by-Expenses-in-Channel-Analysis-work-";

export const ZENDESK_ZERO_BUDGETED_EXPENSE_MARKETING_RETURN =
  "https://remarkablyhelp.zendesk.com/hc/en-us/articles/5357185223059-How-are-Top-and-Bottom-Sources-ranked-";

export const ZENDESK_NWV_SUMMARY_DIFFERENCE =
  "https://remarkablyhelp.zendesk.com/hc/en-us/articles/9005694282515-How-Do-Channel-Analysis-New-Website-Visitor-Volumes-Get-Calculated-";

export const ZENDESK_FILTER_PORTFOLIO =
  "https://remarkablyhelp.zendesk.com/hc/en-us/articles/9910100224659-How-can-I-filter-my-portfolio-for-a-segment-specific-view-";

export const ZENDESK_MANAGE_PERMISSIONS =
  "https://remarkablyhelp.zendesk.com/hc/en-us/articles/9918276703635-How-can-I-set-and-manage-user-permissions-for-properties-";

export const ZENDESK_DELETE_PMS =
  "https://remarkablyhelp.zendesk.com/hc/en-us/articles/10727844109715-How-can-I-delete-a-PMS-instance-being-used-to-connect-properties-to-data-sources-";

export const ZENDESK_PROPERTY_BACKFILL =
  "https://remarkablyhelp.zendesk.com/hc/en-us/articles/11252834121363-How-does-data-backfilling-work-";

export const ZENDESK_DATA_CONNECTION_TEST_HOW_TO =
  "https://remarkablyhelp.zendesk.com/hc/en-us/articles/12179775467411-How-does-property-data-connection-Testing-work-";

export const ZENDESK_HELP = "https://remarkablyhelp.zendesk.com/hc/en-us";
