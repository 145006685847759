import { all, call, put, takeEvery } from "redux-saga/effects";

import { networking } from "../../redux_base/actions";
import { checkStatus, handleError } from "./network";
import { sisenseFetch } from "../../utils/sisense_api";

function* request(action) {
  try {
    yield put(networking.startFetching(action.branch));
    yield put({ type: `${action.baseActionType}_REQUEST` });
    const response = yield call(
      sisenseFetch,
      action.url,
      action.method,
      action.body
    );
    yield checkStatus(response);
    yield put({
      type: `${action.baseActionType}_SUCCESS`,
      payload: response.data
    });
    yield put(networking.success());
  } catch (e) {
    yield put(networking.stopFetching());
    yield put({ type: `${action.baseActionType}_ERROR`, payload: e });
    yield handleError(action)(e);
  }
}

function* fetchSisenseSaga() {
  yield takeEvery("FETCH_SISENSE_API", request);
}

export default function*() {
  yield all([fetchSisenseSaga()]);
}
