export const expenseTooltips = {
  budgetedExpense: {
    one_time:
      "The one-time dollar amount budgeted for this property, expense, lead source(s), and vendor. Budgeted expenses are different from actual expenses in that they’re planned, estimated values, not incurred, precise values. Budgeted expenses are often planned annually, and sometimes adjusted quarterly.",
    recurring:
      "The recurring dollar amount budgeted for this property, expense, lead source(s), and vendor. Budgeted expenses are different from actual expenses in that they’re planned, estimated values, not incurred, precise values. Budgeted expenses are often planned annually, and sometimes adjusted quarterly.",
    pay_for_performance: {
      description:
        "For expenses related to paid performance events, such as leads or new leases. This expense type can only be selected for expenses associated with one (1) lead source.",
      budgetedExpensePerEvent:
        "The dollar amount budgeted for paid performance events related to this property, expense, lead source, and vendor.",
      budgetedNumberOfEventsPerMonth:
        "The number of paid performance events estimated or desired to occur per month related to this property, expense, lead source and vendor.",
      budgetedTotalPerMonth:
        "Budgeted expenses are different from actual expenses in that they’re planned, estimated values, not incurred, precise values. Budgeted expenses are often planned annually, and sometimes adjusted quarterly."
    }
  },
  actualExpense: {
    amount:
      "Actual expenses are different from budgeted expenses in that they’re invoiced and/or paid amounts. Actual expenses are often incurred monthly, and may differ from budgeted expense values.",
    one_time: {
      button:
        "Add the one-time dollar amount paid for this property, expense, and lead source(s) to this vendor as that information is available. Actual expenses entries are optional and can be added monthly, quarterly, or less frequently."
    },
    recurring: {
      button:
        "Add the recurring dollar amount paid for this property, expense, and lead source(s) to this vendor as that information is available. Actual expenses entries are optional and can be added monthly, quarterly, or less frequently."
    },
    pay_for_performance: {
      button:
        "Add the performance-based dollar amount paid for this property, expense, and lead source to this vendor as that information is available. Actual expenses entries are optional and can be added monthly, quarterly, or less frequently."
    },
    automated:
      "Added automatically by Remarkably, based on paid event occurrence."
  },
  vendorName:
    "The name of the vendor who will invoice you and/or who you will pay for this expense and associated lead source(s). Vendor names are different from expense names in that they represent companies or contractors, not marketing expense categories or tactics.",
  expenseName:
    "The name of the expense for this property and associated lead source(s). Expense names are different from vendor names in that they are flexible and can represent marketing expense categories or tactics at the desired level of granularity.",
  paidLeadSources:
    "Dollar sign icons beside lead sources in the drop-down list indicate that expenses have been associated with those sources for this property."
};

export const YARDI_CONNECTION_MISSING =
  "Please connect this property to Yardi Voyager to enable expense additions.";
