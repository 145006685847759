export class TableDataGroup {
  constructor(data, summaryData, tabs) {
    this._data = data;
    this._summaryData = summaryData;
    this._tabs = tabs;
  }

  getTabsByColumn() {
    const tabsByColumn = {};

    for (let t of this._tabs) {
      for (let c of t.columns) {
        if (!tabsByColumn[c]) {
          tabsByColumn[c] = [];
        }
        tabsByColumn[c].push(t.id);
      }
    }

    return tabsByColumn;
  }

  getDataByTabs() {
    const data = this._data;
    const tabsByColumn = this.getTabsByColumn();
    const result = {};

    for (let i = 0; i < data.length; i++) {
      const dataItem = data[i];
      for (let k of Object.keys(dataItem)) {
        const tabs = tabsByColumn[k];
        if (!tabs) {
          continue;
        }
        for (const t of tabs) {
          if (!result[t]) {
            result[t] = [];
          }
          if (!result[t][i]) {
            result[t].push({});
          }
          result[t][i][k] = dataItem[k];
        }
      }
    }

    return result;
  }

  getSummaryByTabs() {
    const data = this._summaryData;
    const tabsByColumn = this.getTabsByColumn();
    const result = {};

    for (let k of Object.keys(data)) {
      const tabs = tabsByColumn[k];
      if (!tabs) {
        continue;
      }
      for (let t of tabs) {
        if (!result[t]) {
          result[t] = {};
        }
        result[t][k] = data[k];
      }
    }

    return result;
  }

  getAllDataByTabs() {
    const dataByTabs = this.getDataByTabs();
    const summaryByTabs = this.getSummaryByTabs();
    const result = {};

    for (let k of Object.keys(dataByTabs)) {
      result[k] = [summaryByTabs[k], ...dataByTabs[k]];
    }

    return result;
  }
}
