import React from "react";

import { Button } from "concrete-ui";
import RenderIf from "../render_if";

export const CompanyInfo = ({ data, onOpenCompanyModal, showErrorMessage }) => {
  let buttonLabel = "Enter Company info";
  if (data?.company) {
    buttonLabel = "Edit Company info";
  }
  return (
    <div>
      <RenderIf condition={!!data?.company}>
        <div className="complete-account__field-value">
          <div className="complete-account__field-label">Company</div>
          <div className="complete-account__value">{data?.company?.label}</div>
        </div>
      </RenderIf>
      <div className="complete-account__section-label">
        {showErrorMessage()}
      </div>
      <Button
        type="button"
        variant="functional"
        size="large"
        onClick={onOpenCompanyModal}
        onKeyPress={onOpenCompanyModal}
        tabIndex="0"
      >
        {buttonLabel}
      </Button>
    </div>
  );
};
