import React from "react";

import { getDisplayName } from "../../utils/misc";
import renderWrapper from "./base_container";

/**
 * HOC component that returns login page instead of `WrappedComponent` if user is not logged in.
 * Otherwise returns page with `WrappedComponent`.
 * @param WrappedComponent
 * @returns

 * NOTE: Use this component for redirecting to login page component instead of `renderWrapper` directly.
 */
export function withLogin(WrappedComponent, auth = true, nav = true) {
  return class extends React.PureComponent {
    static displayName = `withLogin(${getDisplayName(WrappedComponent)})`;

    renderComponent = () => {
      return <WrappedComponent {...this.props} />;
    };

    render() {
      return renderWrapper(this.renderComponent, auth, nav);
    }
  };
}
