import {
  formatDateWithTokens,
  formatPercent,
  valueOr
} from "../../utils/formatters";

export function getCsvFilename(
  property,
  kpi,
  startDate,
  endDate,
  description = null
) {
  let start = formatDateWithTokens(startDate, "yyyy-MM-dd");
  let end = formatDateWithTokens(endDate, "yyyy-MM-dd");
  let property_label = property.replace(/\s/g, "_");
  let pre_kpi_label = kpi.replace(/(?:_|\b)(\w)/g, function(key) {
    return key.toUpperCase();
  });
  let kpi_label = pre_kpi_label.includes("_To_")
    ? pre_kpi_label.replaceAll("_To_", "_to_")
    : pre_kpi_label;
  if (description) {
    let description_format = description.replace(/\s/g, "_");
    return `Remarkably_${property_label}_${kpi_label}_${description_format}_${start}-${end}`;
  }
  return `Remarkably_${property_label}_${kpi_label}_${start}-${end}`;
}

export function csvPercentageFormat(value) {
  // was SUPER DANGEROUS ---->  eval(`dataPoint.${accessor}`);
  return valueOr(value, "\u2011%", formatPercent);
}

export function structurePropertyKpiData(data) {
  let responseData = [];
  if (data) {
    responseData = data.map(item => ({
      count: item.count,
      percentage: item.percentage?.toFixed(4).toString(),
      source_name: item.source_name,
      value: item.value
    }));
  }
  return responseData;
}

export function structurePropertyNwvKpiData(data) {
  let responseData = [];
  if (data) {
    responseData = data.map(item => ({
      avg_session_duration: item.avg_session_duration,
      bounce_rate: item.bounce_rate?.toFixed(4).toString(),
      bounces: item.bounces,
      id: item.id,
      nwv_count: item.nwv_count,
      nwv_percentage: item.nwv_percentage,
      nwv_source_name: item.nwv_source_name,
      source_name: item.source_name,
      total_nwv: item.total_nwv
    }));
  }
  return responseData;
}
