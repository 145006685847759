import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Flex,
  Text,
  TextInput,
  Tooltip,
  Icon,
  Select,
  Button,
  Checkbox,
  Link
} from "concrete-ui";
import ConfirmModal from "../confirm_modal";

import useValidation from "../../utils/validator_hook";
import Breadcrumbs from "../breadcrumbs";
import {
  CreateForm,
  CreateFormHeader,
  CreateFormSection
} from "../create_form";
import { ErrorItem } from "../message_items";
import { dataConnectionConfig } from "./validators";
import AdminBreadcrumb from "../admin_breadcrumb";
import {
  concreteErrorVariant,
  PROPERTY_DATA_CONNECTION_INTEGRATION_OPTIONS,
  GOOGLE_ANALYTICS_PROVIDER_OPTIONS,
  EDIT_CONNECTION_MODAL_CONTENT,
  ZENDESK_HOW_TO_CONFIGURE_YARDI,
  ZENDESK_HOW_TO_CONFIGURE_GA,
  SINGLE_DOMAIN_MULTI_PROPERTIES_INFO,
  SUPPORT_EMAIL
} from "../../constants";
import EnhancedLink from "../enhanced_link";
import _find from "lodash/find";
import RenderIf from "../render_if";

export const inputTextWithTooltip = (title, tooltip) => (
  <Box alignItems="center">
    {title}{" "}
    <Tooltip title={tooltip} ml={2} size="small">
      <Icon.Tooltip size={3} />
    </Tooltip>
  </Box>
);

const AdminDataConnectionForm = ({
  onCancel = v => v,
  onConfirm = v => v,
  initialData = {},
  showError = false,
  propertiesDetails = [],
  pmsInstances = [],
  dataConnections = []
}) => {
  const mapFormFields = property => ({
    public_id: property.public_id
  });

  const [isModalOpen, setModal] = useState(false);
  const [hasConnection, setHasConnection] = useState(false);

  const defaultData = {
    public_id: null,
    integration_source: null,
    version_instance: null,
    id_code: "",
    setup_done: false
  };

  const getProperty = publicId => {
    const prop = _find(propertiesDetails, ["public_id", publicId]);
    return prop ? mapFormFields(prop) : {};
  };

  const {
    errors,
    getFormProps,
    getItemProps,
    setFormItems,
    formItems,
    hasChanges
  } = useValidation(
    dataConnectionConfig,
    {
      ...defaultData,
      ...initialData
    },
    onConfirm
  );

  const confirmModal = () => {
    setModal(false);
    onConfirm(formItems);
  };

  const openModal = hasInitialData => {
    if (Object.keys(hasInitialData).length) {
      setModal(true);
    } else {
      onConfirm(formItems);
    }
  };

  const mapPropertySelect = ({ public_id, name }) => ({
    value: public_id,
    label: name
  });

  const getPMSInstanceOptions = () => {
    const response = pmsInstances.map(instance => ({
      value: instance.id,
      label: instance.name
    }));
    return response;
  };

  const isYardiSelected = formItems["integration_source"]
    ? formItems["integration_source"] === "yardi"
    : false;

  const propertyProps = getItemProps("public_id");

  const onChangePropertySelect = value => {
    propertyProps.onChange(value);
    setHasConnection(false);
    setFormItems({
      ...defaultData,
      ...getProperty(value)
    });
  };

  const breadcrumbs = [
    { text: <AdminBreadcrumb />, link: "" },
    { text: "Connections", link: "/admin-connections" },
    { text: "Add Property Data Connection", link: "" }
  ];

  const fieldsContainerProps = { width: "31rem" };
  const integrationSourceProps = getItemProps("integration_source");
  const instanceOrVersionProps = getItemProps("version_instance");
  const checkboxProps = getItemProps("setup_done");
  const linkStyles = {
    "text-decoration-line": "underline",
    "text-underline-offset": "5px"
  };

  const onChangeIntegration = value => {
    integrationSourceProps.onChange(value);
    checkboxProps.onChange(false);
    if (formItems["public_id"]) {
      checkExistingConnection(value);
    }
  };

  const getCodeIdentifierSectionValues = () => {
    let response = {
      title: "Add <Code or ID>",
      fieldLabel: "<Code or ID>",
      description:
        "Add the <Yardi Voyager Code> or <Google Analytics ID> for this property."
    };
    if (formItems["integration_source"] === "yardi") {
      response["title"] = "Add Yardi Voyager Code";
      response["enable_title"] = "Enable Remarkably in PMS";
      response["description"] = "Add the Yardi Voyager Code for this property.";
      response["checkbox_text"] =
        "I have configured and saved Remarkably access details.";
      response["checkbox_link"] = ZENDESK_HOW_TO_CONFIGURE_YARDI;
      response["fieldLabel"] = "Yardi Voyager Code";
    } else if (formItems["integration_source"] === "google_analytics") {
      response["title"] = "Add Google Analytics ID";
      response["enable_title"] = "Enable Remarkably in Google Analytics";
      response["description"] =
        "Add the Google Analytics ID for this property.";
      response["checkbox_text"] =
        "I have added and granted correct permissions for Remarkably.";
      response["checkbox_link"] = ZENDESK_HOW_TO_CONFIGURE_GA;
      response["fieldLabel"] = inputTextWithTooltip(
        "Google Analytics ID",
        "The ID is available in Google Analytics Admin (lower left hand corner), in View Settings (far right hand column)."
      );
    }
    return response;
  };

  const getSingleDomainMultiPropertiesText = () => {
    if (
      formItems["integration_source"] === "google_analytics" &&
      formItems["landing_page_plus_query_string"]
    ) {
      return (
        <Text mt={3} textSize={2} $color="green.0" $fontWeight="regular">
          {SINGLE_DOMAIN_MULTI_PROPERTIES_INFO} Please contact{" "}
          <Text
            as="a"
            textSize={2}
            style={linkStyles}
            href={`mailto:${SUPPORT_EMAIL}`}
            $color="text.blue.0"
          >
            Support
          </Text>
          .
        </Text>
      );
    }

    return null;
  };

  const getIntegrationOptions = () => {
    if (formItems["integration_source"] === "yardi") {
      return getPMSInstanceOptions();
    } else if (formItems["integration_source"] === "google_analytics") {
      return GOOGLE_ANALYTICS_PROVIDER_OPTIONS;
    }
    return [];
  };

  const checkExistingConnection = value => {
    const existingConnections = dataConnections.filter(conn => {
      return (
        conn.public_id === formItems["public_id"] &&
        conn.integration_source === value
      );
    });
    if (existingConnections.length > 0) {
      const connection = existingConnections[0];
      setFormItems({
        id_code: connection.id_code,
        version_instance: connection.version_instance
      });
      setHasConnection(true);
    } else {
      setHasConnection(false);
    }
  };

  return (
    <>
      <Box bg="gray.1" width="100%" display="block">
        <Breadcrumbs breadcrumbs={breadcrumbs} />
      </Box>
      <Box width="62.5rem" mx="auto" mb="12rem">
        <CreateForm formProps={getFormProps()}>
          <CreateFormHeader>
            <Text textSize={4} $color="text.white.1" $fontWeight="medium">
              Add Property Data Connection
            </Text>
          </CreateFormHeader>
          <CreateFormSection
            number="1"
            titleText="Choose Property"
            titleSummary="Select a property you’ve already added to Remarkably."
            fieldsContainerProps={fieldsContainerProps}
          >
            <Flex flexBasis="100%" alignItems="center">
              <Select
                size="large"
                label="Property"
                items={propertiesDetails.map(mapPropertySelect)}
                {...propertyProps}
                onSelect={onChangePropertySelect}
                error={!!errors.public_id}
                errorMessage={errors.public_id}
                width="auto"
                flexBasis="calc(50% - 0.5rem)"
                mr={4}
              />
              <Flex flexDirection="column">
                <Text
                  mt={2}
                  textSize={2}
                  $color="text.white.1"
                  $fontWeight="regular"
                >
                  Don't see a property listed?
                </Text>
                <EnhancedLink to="/admin-properties">
                  <Text style={linkStyles} $color="text.blue.0" textSize={1}>
                    Add a property
                  </Text>
                </EnhancedLink>
              </Flex>
            </Flex>
          </CreateFormSection>
          <CreateFormSection
            number="2"
            titleText="Choose Integration"
            titleSummary="Select from existing integrations and data sources currently available."
            fieldsContainerProps={fieldsContainerProps}
          >
            <Flex flexWrap="wrap" flexGrow="1">
              <Flex flexBasis="100%" mt={3}>
                <Select
                  size="large"
                  placeholder="Select Integration"
                  items={PROPERTY_DATA_CONNECTION_INTEGRATION_OPTIONS}
                  {...integrationSourceProps}
                  onSelect={onChangeIntegration}
                  error={!!errors.integration_source}
                  errorMessage={errors.integration_source}
                  flexBasis="calc(50% - 0.5rem)"
                  mr={4}
                />
                <RenderIf condition={hasConnection}>
                  <Flex flexDirection="column" mr="-50px">
                    <Text
                      textSize={1}
                      $color="text.orange.0"
                      $fontWeight="regular"
                    >
                      <p>Oops, this connection has already been added.</p>
                      <p>Add a different one, edit this one, or cancel.</p>
                    </Text>
                  </Flex>
                </RenderIf>
              </Flex>
              <Flex flexBasis="100%" mt={3}>
                <Select
                  size="large"
                  placeholder="Select Instance or Version"
                  items={getIntegrationOptions()}
                  {...instanceOrVersionProps}
                  onSelect={instanceOrVersionProps.onChange}
                  error={!!errors.version_instance}
                  errorMessage={errors.version_instance}
                  flexBasis="calc(50% - 0.5rem)"
                  mr={4}
                />
                <RenderIf condition={isYardiSelected}>
                  <Flex flexDirection="column" mt="-8px">
                    <Text
                      textSize={1}
                      $color="text.white.1"
                      $fontWeight="regular"
                    >
                      Don't see an instance listed?
                    </Text>
                    <EnhancedLink to="/admin-connections/add-pms-instance">
                      <Text
                        style={linkStyles}
                        $color="text.blue.0"
                        textSize={1}
                      >
                        Add a PMS Instance
                      </Text>
                    </EnhancedLink>
                  </Flex>
                </RenderIf>
              </Flex>
            </Flex>
          </CreateFormSection>
          {formItems["integration_source"] && (
            <CreateFormSection
              number="3"
              titleText={getCodeIdentifierSectionValues()["enable_title"]}
              titleSummary="Confirm the following task has been completed for successful setup."
            >
              <Checkbox {...checkboxProps}>
                <Text textSize={2} ml={2} mr={1} $fontWeight="regular">
                  {getCodeIdentifierSectionValues()["checkbox_text"]}
                </Text>
                <Link
                  href={getCodeIdentifierSectionValues()["checkbox_link"]}
                  style={linkStyles}
                  $color="text.blue.0"
                  target="_blank"
                >
                  Learn more.
                </Link>
              </Checkbox>
            </CreateFormSection>
          )}
          <CreateFormSection
            number={!formItems["integration_source"] ? "3" : "4"}
            titleText={getCodeIdentifierSectionValues()["title"]}
            titleSummary={getCodeIdentifierSectionValues()["description"]}
            fieldsContainerProps={{
              ...fieldsContainerProps,
              flexDirection: "column"
            }}
          >
            <Flex>
              <TextInput
                size="large"
                label={getCodeIdentifierSectionValues()["fieldLabel"]}
                {...getItemProps("id_code")}
                variant={errors.id_code && concreteErrorVariant}
                message={errors.id_code}
                width="auto"
                flexBasis="calc(50% - 0.5rem)"
                mr={4}
              />
            </Flex>
            {getSingleDomainMultiPropertiesText()}
            <Flex mt={6} alignItems="center" justifyContent="flex-end">
              {showError && (
                <ErrorItem message="Oops, something went wrong. Please contact Support." />
              )}
              <Button
                variant="secondary"
                size="large"
                type="button"
                mr={3}
                onClick={onCancel}
                style={{ flex: "0 0 auto" }}
              >
                Cancel
              </Button>
              <Button
                disabled={!hasChanges || !formItems["setup_done"]}
                size="large"
                variant="primary"
                type="button"
                style={{ flex: "0 0 auto" }}
                onClick={() => openModal(initialData)}
              >
                Save and Test
              </Button>
            </Flex>
          </CreateFormSection>
        </CreateForm>
      </Box>
      {isModalOpen && (
        <ConfirmModal
          title="Save Changes?"
          content={EDIT_CONNECTION_MODAL_CONTENT}
          addBackground={true}
          confirmButtonText="Confirm"
          cancelButtonText="Cancel"
          onConfirm={confirmModal}
          onClose={() => setModal(false)}
        />
      )}
    </>
  );
};

AdminDataConnectionForm.propTypes = {
  initialData: PropTypes.object,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  apiError: PropTypes.bool,
  propertiesDetails: PropTypes.array,
  pmsInstances: PropTypes.array
};

export default AdminDataConnectionForm;
