import React from "react";

import { formatDateString } from "../../utils/formatters";
import AdminConnectionsPMSMiniMenu from "../../components/admin_connections/admin_connections_pms_mini_menu";
import AdminPropertyConnectionsMiniMenu from "../../components/admin_connections/admin_property_connections_mini_menu";
import StatusDropdown, {
  CONNECTION_STATUS_LABELS
} from "../../components/admin_connections/status_dropdown";

export default (
  selectedIndex,
  pmsMiniMenuProps,
  connectionMiniMenuProps,
  statusDropdownProps,
  ConnectionStatusCustomHeader
) => {
  const result = [
    {
      tableColumns: [
        {
          Header: "PMS Instance Name",
          colWidth: "20rem",
          accessor: "name"
        },
        {
          Header: "PMS URL",
          colWidth: "14rem",
          accessor: "url"
        },
        {
          Header: "Database Name",
          colWidth: "14rem",
          accessor: "db_name"
        },
        {
          Header: "Server Name",
          colWidth: "14rem",
          accessor: "server_name"
        },
        {
          Header: "Platform",
          colWidth: "14rem",
          accessor: "platform"
        },
        {
          Header: "User Name",
          colWidth: "14rem",
          accessor: "username"
        },
        {
          Header: "Added or Updated By",
          colWidth: "16rem",
          accessor: "added_updated_by"
        },
        {
          Header: "Last Updated",
          colWidth: "14rem",
          accessor: "last_updated",
          ValueComponent: ({ data }) => (
            <AdminConnectionsPMSMiniMenu data={data} {...pmsMiniMenuProps} />
          )
        }
      ],
      hiddenColumns: []
    },
    {
      tableColumns: [
        {
          Header: "Property",
          colWidth: "14rem",
          accessor: "property"
        },
        {
          Header: "Integration",
          colWidth: "14rem",
          accessor: "integration"
        },
        {
          Header: "Version or Instance",
          colWidth: "14rem",
          accessor: "version_instance"
        },
        {
          Header: "Integration Source",
          colWidth: "14rem",
          accessor: "integration_source"
        },
        {
          Header: "ID or Code",
          colWidth: "12rem",
          accessor: "id_code"
        },
        {
          Header: ConnectionStatusCustomHeader,
          colWidth: "19rem",
          accessor: "connection_status",
          csvFormatter: value => CONNECTION_STATUS_LABELS[value],
          ValueComponent: ({ data }) => {
            return (
              <StatusDropdown connectionData={data} {...statusDropdownProps} />
            );
          }
        },
        {
          Header: "Last Backfilled Period",
          colWidth: "18rem",
          accessor: "last_backfill_period",
          valueFormatter: value =>
            value && value.length === 2
              ? `${formatDateString(value[0])} → ${formatDateString(value[1])}`
              : null
        },
        {
          Header: "Added or Updated By",
          colWidth: "16rem",
          accessor: "added_updated_by"
        },
        {
          Header: "Last Updated",
          colWidth: "14rem",
          accessor: "last_updated",
          ValueComponent: ({ data }) => (
            <AdminPropertyConnectionsMiniMenu
              data={data}
              {...connectionMiniMenuProps}
            />
          )
        }
      ],
      hiddenColumns: ["version_instance", "integration_source"]
    }
  ];

  return result[selectedIndex];
};
