import cn from "classnames";
import React from "react";
import PropTypes from "prop-types";

import Select from "../select";
import {
  menuListConstructor,
  MenuPortal,
  OptionRemove
} from "../select/select_components";

export default class SelectRole extends React.PureComponent {
  static propTypes = {
    member: PropTypes.object.isRequired,
    adminCount: PropTypes.number.isRequired,
    property: PropTypes.object.isRequired,
    roleOptions: PropTypes.array.isRequired,
    components: PropTypes.object.isRequired,
    role: PropTypes.object.isRequired,
    openRemoveModal: PropTypes.func.isRequired,
    openChangeRoleModal: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      menuOpen: false
    };
  }

  static selectStyle = {
    singleValue: provided => ({ ...provided, right: 10 }),
    menuList: provided => ({ ...provided, overflow: "initial" }),
    menuPortal: provided => ({ ...provided, zIndex: 1000, height: 0 })
  };

  closeMenuOnScroll = () => this.state.menuOpen;

  openMenuHandler = () => {
    this.setState({ menuOpen: true });
  };

  closeMenuHandler = () => {
    this.setState({ menuOpen: false });
  };

  canRemove = () => {
    return !(this.props.adminCount === 1 && this.props.member.role === "admin");
  };

  renderRemoveButton = () => {
    return (
      <OptionRemove
        isDisabled={!this.canRemove()}
        onClick={this.onRemoveHandler}
      />
    );
  };

  onRemoveHandler = () => {
    if (this.canRemove()) {
      this.props.openRemoveModal(this.props.property, this.props.member);
      this.setState({ menuOpen: false });
    }
  };

  onChangeHandler = option => {
    this.props.openChangeRoleModal(
      option.value,
      this.props.property,
      this.props.member
    );
  };

  handleInputChange = input => {
    this.setState({ menuOpen: !!input });
  };

  render() {
    console.log("MENU IS OPEN: ", this.menuIsOpen);
    const { components, roleOptions, role } = this.props;
    return (
      <Select
        className="invite-modal__select-role"
        theme="transparent"
        size="small"
        styles={SelectRole.selectStyle}
        components={{
          ...components,
          MenuList: menuListConstructor(this.renderRemoveButton()),
          MenuPortal
        }}
        options={roleOptions}
        isOptionDisabled={this.isOptionDisabled}
        defaultValue={role}
        value={role}
        menuPortalTarget={document.body}
        onChange={this.onChangeHandler}
        closeMenuOnScroll={this.closeMenuOnScroll}
        menuIsOpen={this.state.menuOpen}
        onMenuOpen={this.openMenuHandler}
        onMenuClose={this.closeMenuHandler}
        onInputChange={this.handleInputChange}
        menuPosition="absolute"
      />
    );
  }
}
