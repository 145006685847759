import React from "react";
import PropTypes from "prop-types";
import {
  Button,
  Flex,
  Icon,
  Link,
  Text,
  ToggleSwitch,
  Tooltip
} from "concrete-ui";

import RenderIf from "../render_if";
import { referralSourcesTooltips } from "../referral_sources/tooltips";

function ReferralSourcesSave(props) {
  const [checked, setChecked] = React.useState(props.checked);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    setChecked(props.checked);
  }, [props.checked]);
  React.useEffect(() => {
    setLoading(false);
  }, [props.saveCompleted]);

  function onSaveHandler() {
    setLoading(true);
    props.onSave(checked);
  }
  const haveChanges = props.haveChanges || checked !== props.checked;
  return (
    <Flex
      px={4}
      py={4}
      justifyContent="space-between"
      width="100%"
      height="5.5rem"
    >
      <RenderIf condition={props.saveCompleted}>
        <Flex alignItems="center" maxWidth="13.5rem">
          <Icon.Check color="text.green.0" size={5} width={5} height={5} />
          <Text textSize={2} $fontWeight="regular" $color="text.white.1" ml={2}>
            {props.saveTxt}
          </Text>
        </Flex>
      </RenderIf>
      <RenderIf condition={!props.saveCompleted}>
        <Flex alignItems="center" my={1} ml={2}>
          <Text textSize={2} $fontWeight="regular" $color="text.gray.1">
            Unbounced Visitors Only
          </Text>
          <ToggleSwitch
            mx={2}
            value={checked}
            name="unbounced_visitors_only"
            onChange={value => setChecked(value)}
            hasLabel={false}
          />
          <Tooltip
            size="small"
            title={referralSourcesTooltips["nwv"]["unbouncedVisitorsOnly"]}
            placement="top"
            ml={2}
          >
            <Icon.Tooltip size="1rem" color="text.white.1" />
          </Tooltip>
        </Flex>
      </RenderIf>
      <RenderIf condition={haveChanges}>
        <Button
          variant="primary"
          size="large"
          my={2}
          mr={2}
          ml="auto"
          onClick={onSaveHandler}
          disabled={loading}
        >
          Save
        </Button>
      </RenderIf>
    </Flex>
  );
}

ReferralSourcesSave.propTypes = {
  haveChanges: PropTypes.bool,
  checked: PropTypes.bool,
  toggleTxt: PropTypes.string,
  saveCompleted: PropTypes.bool,
  saveTxt: PropTypes.string.isRequired,
  onSave: PropTypes.func
};

ReferralSourcesSave.defaultProps = {
  haveChanges: true,
  checked: false,
  saveCompleted: false,
  toggleTxt: "",
  onSave: () => {}
};

export default ReferralSourcesSave;
