import React from "react";
import IconBase from "./icon_base";

const AddWhite = props => (
  <IconBase viewBox="0 0 16 16" fill="none" {...props}>
    <circle cx="8" cy="7.99927" r="8" fill="#F5FAF7" />
    <path
      d="M10.7217 8.34004H8.34077V10.721C8.34077 10.9843 8.12744 11.1972 7.86458 11.1972C7.60173 11.1972 7.38839 10.9843 7.38839 10.721V8.34004H5.00744C4.74458 8.34004 4.53125 8.12718 4.53125 7.86385C4.53125 7.60052 4.74458 7.38766 5.00744 7.38766H7.38839V5.00671C7.38839 4.74337 7.60173 4.53052 7.86458 4.53052C8.12744 4.53052 8.34077 4.74337 8.34077 5.00671V7.38766H10.7217C10.9846 7.38766 11.1979 7.60052 11.1979 7.86385C11.1979 8.12718 10.9846 8.34004 10.7217 8.34004Z"
      fill="#20272E"
    />
  </IconBase>
);

export default AddWhite;
