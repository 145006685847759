export const data = {
  total_investment: {
    budget: 10756,
    actual: 10000
  },
  new_lease_revenue: {
    budget: 250343
  },
  avg_cpl: {
    budget: 70,
    actual: 80.19
  },
  avg_cpt: {
    actual: 600
  },
  avg_cpla: {
    budget: 1808456,
    actual: 1800234.1
  },
  avg_cpnl: {
    budget: 1000,
    actual: 899.9
  }
};
