import { createAjaxAction, URLS } from "./helpers";

const actions = {
  isActivated: createAjaxAction(
    "AJAX_USER_IS_ACTIVATED",
    "/users/",
    publicId => `${publicId}/is-activated/`
  ),
  requestAlertsAssociates: createAjaxAction(
    "AJAX_GET_USER_ASSOCIATES",
    "/associates/"
  ),
  collectError: err => ({
    type: "USER_COLLECT_ERROR",
    err
  }),
  getUserProfile: () =>
    createAjaxAction("AJAX_GET_USER_PROFILE", `${URLS.user_profile}/`)()
};

export default actions;
