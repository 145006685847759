export const PROPERTY_CHART_TEXT = {
  // Performance
  exposure: {
    tooltip:
      "Sum of Vacant Unrented property units (including Ready and Not Ready) plus the sum of Occupied property Notice Unrented units, divided by the sum of property units. Based on data ingested daily via our Yardi Voyager integration."
  },
  leads_to_lease: {
    tooltip:
      "Sum of New Leases divided by sum of Leads, in date range selected. Funnel event-based analysis, not Lead cohort-based analysis (coming soon). Based on data ingested daily via our Yardi Voyager integration."
  },
  cancellation_and_denial: {
    tooltip:
      "Sum of Cancellations plus Denials, divided by sum of Lease Applications. Based on data ingested daily via our Yardi Voyager integration."
  },
  leased: {
    tooltip:
      "Sum of Occupied property units (including those On Notice) plus the sum of Vacant Rented units, divided by the sum of property units. Based on data ingested daily via our Yardi Voyager integration."
  },
  occupied: {
    tooltip:
      "Sum of Occupied property units (including those On Notice), divided by the sum of property units. Based on data ingested daily via our Yardi Voyager integration."
  }
};
