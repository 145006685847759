import React, { useEffect, useMemo } from "react";
import { Flex } from "concrete-ui";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { withLogin } from "../shared/login";
import Loader from "../../components/loader";
import _find from "lodash/find";
import { propertyActions } from "../../redux_base/actions";
import AdminPropertiesForm from "../../components/admin_properties_form";

const AdminPropertiesFormContainer = ({
  match,
  history,
  // reducer props
  isPostSuccessful,
  isLoadingPropDetails,
  apiError,
  dispatch,
  // data
  propertiesDetails = []
}) => {
  const { publicId } = match.params;
  const initialPropertyData = useMemo(
    () => _find(propertiesDetails, ["public_id", publicId]) || {},
    [propertiesDetails, publicId]
  );

  useEffect(() => {
    if (!propertiesDetails.length) {
      dispatch(propertyActions.requestPropertiesDetails());
    }
  }, []);

  useEffect(() => {
    if (isPostSuccessful) {
      dispatch(propertyActions.updateState({ isPostSuccessful: false }));
      history.push("/admin-properties");
    }
  }, [isPostSuccessful]);

  // cancel
  const onCancel = () => history.push("/admin-properties");

  // submit
  const onSubmit = data => {
    if (publicId) {
      dispatch(propertyActions.patchPropertyDetails(data, publicId));
    } else {
      dispatch(propertyActions.postPropertyDetails(data));
    }
  };

  return (
    <Flex flexDirection="column" pb={"12rem"}>
      <AdminPropertiesForm
        initialData={initialPropertyData}
        onConfirm={onSubmit}
        onCancel={onCancel}
        apiError={apiError}
      />
      <Loader isVisible={isLoadingPropDetails} />
    </Flex>
  );
};

const mapState = state => ({
  ...state.network,
  ...state.property
});

export default withRouter(
  connect(mapState)(withLogin(AdminPropertiesFormContainer))
);
