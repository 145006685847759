import React, { useState } from "react";
import PropTypes from "prop-types";
import { Flex, Table, Text, Tooltip, Icon } from "concrete-ui";
import _sortBy from "lodash/sortBy";

import ConfirmModal from "../confirm_modal";
import ExpenseMiniMenu from "../expense_mini_menu";
import { formatCurrency, formatDateString } from "../../utils/formatters";
import { nullDashDisplay } from "../../constants";
import { dateFormat, dateParse } from "../../utils/dates";
import { expenseTooltips } from "../expense_entry_form/tooltips";
import RenderIf from "../render_if";

const ExpenseTable = ({
  data,
  loading,
  checkEditingEnabled,
  onCopy = v => v,
  onEdit = v => v,
  onDelete = v => v
}) => {
  const leadSourceValueFormatter = value => {
    if (!Array.isArray(value) || !value.length) {
      return nullDashDisplay;
    }
    if (value.length === 1) {
      return value[0];
    }
    const [displayValue, ...tooltipElements] = _sortBy(value);
    const tooltipText = tooltipElements.join(", ");
    return (
      <Flex>
        <Text textSize={2} $fontWeight="regular" $color="text.white.1">
          {displayValue} + {tooltipElements.length}
        </Text>
        <Tooltip ml={2} title={tooltipText} size="mini" placement="right">
          <Icon.Tooltip size={3} my="auto" />
        </Tooltip>
      </Flex>
    );
  };
  const leadSourceCSVFormatter = value => {
    if (!Array.isArray(value) || !value.length) {
      return nullDashDisplay;
    }
    return `"${_sortBy(value).join(",")}"`;
  };
  const expenseColumns = [
    {
      Header: "Property",
      accessor: "property",
      colWidth: "14rem",
      valueFormatter: value => value || nullDashDisplay
    },
    {
      Header: "Expense Name",
      accessor: "expense_name",
      colWidth: "12.5rem"
    },
    {
      Header: "Lead Source(s)",
      accessor: "lead_sources",
      colWidth: "14rem",
      csvFormatter: leadSourceCSVFormatter,
      valueFormatter: leadSourceValueFormatter
    },
    {
      Header: "Vendor",
      accessor: "vendor",
      colWidth: "9rem",
      valueFormatter: value => value || nullDashDisplay
    },
    {
      Header: "Budgeted\nExpense",
      accessor: "cost_budgeted",
      colWidth: "8.25rem",
      valueFormatter: value => formatCurrency(value)
    },
    {
      Header: "Frequency",
      accessor: "frequency",
      colWidth: "9rem",
      valueFormatter: value => value || nullDashDisplay
    },
    {
      Header: "Last Actual\nExpense",
      accessor: "last_actual_expense",
      colWidth: "11rem",
      ValueComponent: ({ data }) => {
        if (!data.last_actual_expense) {
          return nullDashDisplay;
        }
        return (
          <Flex>
            <Text textSize={2} $fontWeight="regular" $color="text.white.1">
              {formatDateString(data.last_actual_expense)}
            </Text>
            <RenderIf condition={data.last_actual_expense_automated}>
              <Tooltip
                ml={2}
                title={expenseTooltips["actualExpense"]["automated"]}
                size="mini"
                placement="right"
              >
                <Icon.Tooltip size={3} my="auto" />
              </Tooltip>
            </RenderIf>
          </Flex>
        );
      }
    },
    {
      Header: "Creator",
      accessor: "user",
      colWidth: "13.5rem",
      divider: true,
      ValueComponent: ({ data }) => {
        const [isModalOpen, setModal] = useState(false);
        const modalText =
          "Are you sure you want to delete this expense, including all associated budgeted and actual expense entries? This action can't be undone.";
        return (
          <Flex justifyContent="space-between">
            <Flex flexDirection="column">
              <Text textSize={1} $fontWeight="regular" $color="text.white.1">
                {data.user}
              </Text>
              <Text textSize={1} $fontWeight="regular" $color="text.gray.1">
                {`Updated ${formatDateString(data.last_updated)}`}
              </Text>
            </Flex>
            <ExpenseMiniMenu
              disabled={
                typeof checkEditingEnabled === "function" &&
                !checkEditingEnabled(data.id)
              }
              onCopy={() => onCopy(data.id)}
              onEdit={() => onEdit(data.id)}
              onDelete={() => setModal(true)}
            />
            {isModalOpen && (
              <ConfirmModal
                title="Delete Expense?"
                content={modalText}
                addBackground={true}
                confirmButtonText="Delete"
                cancelButtonText="Cancel"
                onConfirm={() => {
                  onDelete(data.id);
                  setModal(false);
                }}
                onClose={() => setModal(false)}
              />
            )}
          </Flex>
        );
      }
    },
    {
      Header: "Last Updated",
      accessor: "last_updated",
      colWidth: "1rem",
      isVisible: false,
      sortMethod: (a, b) => {
        let a_date = dateParse(a);
        let b_date = dateParse(b);
        if (a_date < b_date) {
          return 1;
        } else if (a_date > b_date) {
          return -1;
        } else {
          return 0;
        }
      },
      valueFormatter: value =>
        value ? formatDateString(value) : nullDashDisplay
    }
  ];

  const getCsvFileName = () => {
    const date = dateFormat(new Date(), "yyyy-MM-dd");
    return `Remarkably_Marketing_Expenses_${date}`;
  };

  return (
    <Flex minWidth="86rem">
      <Table
        width="100%"
        data={data}
        loading={loading}
        columns={expenseColumns}
        getCsvFilename={getCsvFileName}
        initialSortBy={[{ id: "last_updated", desc: true }]}
        hiddenColumns={["last_updated"]}
        pageSizes={[
          { label: "10", value: 10 },
          { label: "20", value: 20 },
          { label: "All", value: "all" }
        ]}
      />
    </Flex>
  );
};

ExpenseTable.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      property_id: PropTypes.string.isRequired,
      expense_name: PropTypes.string.isRequired,
      last_actual_expense: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.instanceOf(Date)
      ]),
      cost_budgeted: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      last_expense_cost: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
      ]),
      last_actual_expense_automated: PropTypes.bool,
      budgeted_events_number: PropTypes.number,
      vendor: PropTypes.string.isRequired,
      user_id: PropTypes.string.isRequired,
      last_updated: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.instanceOf(Date)
      ]).isRequired,
      lead_sources: PropTypes.arrayOf(PropTypes.string)
    })
  ),
  loading: PropTypes.bool,
  checkEditingEnabled: PropTypes.func,
  onCopy: PropTypes.func,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func
};

ExpenseTable.defaultProps = {
  tableData: [],
  loading: false,
  onCopy: () => {},
  onEdit: () => {},
  onDelete: () => {}
};

export default ExpenseTable;
