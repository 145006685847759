import React from "react";
import posthog from "posthog-js";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { initFeatureFlags } from "../../utils/posthog";
import { posthogActions, posthogState } from "../../redux_base/actions";

class PosthogContainer extends React.PureComponent {
  static propTypes = {
    user_id: PropTypes.string,
    email: PropTypes.string,
    flagsLoaded: PropTypes.bool
  };

  constructor(props) {
    super(props);

    initFeatureFlags(process.env.FEATURE_FLAGS.split(","));
  }

  componentDidUpdate(prevProps) {
    const { user_id, email } = this.props;
  }

  render() {
    return null;
  }
}

const mapState = state => ({
  ...state.user,
  flagsLoaded: state.posthog.flagsLoaded
});

export default connect(mapState)(PosthogContainer);
