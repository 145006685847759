import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { alertsActions } from "../../redux_base/actions";
import EnhancedLink from "./../enhanced_link";

import "./top_navigation.scss";
import { Button, Icon } from "concrete-ui";
import AlertLabel from "../alert_label";

class AlertButton extends React.PureComponent {
  static propTypes = {
    newEventsCount: PropTypes.number
  };

  componentDidMount() {
    if (!this.props.newEventsCount) {
      this.props.dispatch(alertsActions.getAlerts());
      this.props.dispatch(alertsActions.getNotifications());
    }
  }

  render() {
    return (
      <EnhancedLink className="top-navigation__button" to="/alerts">
        <Button variant="secondary" size="small" ml={4}>
          <AlertLabel
            label={this.props.newEventsCount}
            type="circle"
            size="big"
            position="top-left"
          />
          <Icon.Alerts color="white.1" />
          Alerts
        </Button>
      </EnhancedLink>
    );
  }
}

const mapState = ({ network, alerts }) => ({
  ...network,
  newEventsCount: alerts?.newEventsCount
});

export default withRouter(connect(mapState)(AlertButton));
