import React from "react";
import PropTypes from "prop-types";
import { TransitionMotion, presets, spring } from "react-motion";
import { Flex, Box, Icon, Text } from "concrete-ui";

const Banner = ({
  id,
  message,
  backgroundColor = "gray.5",
  textColor = "text.white.1",
  onClose,
  ...otherProps
}) => {
  const onCloseHandler = () => {
    onClose && onClose(id);
  };

  return (
    <Box
      display="flex"
      height={6}
      width="100%"
      maxWidth="86rem"
      margin="0 auto"
      backgroundColor={backgroundColor}
      borderWidth="1px"
      borderColor="gray.4"
      borderStyle="solid"
      borderRadius="2"
      alignItems="center"
      {...otherProps}
    >
      <Flex mx={5} flexGrow="1">
        <Text
          textSize={1}
          $color={textColor}
          $fontWeight="regular"
          textAlign="center"
          width="100%"
          ellipsis
          dangerouslySetInnerHTML={{ __html: message }}
        ></Text>
      </Flex>
      <Icon.Close
        mr={5}
        color={textColor}
        cursor="pointer"
        size={3}
        onClick={onCloseHandler}
      />
    </Box>
  );
};

Banner.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  message: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string,
  textColor: PropTypes.string,
  onClose: PropTypes.func.isRequired
};

const BannerList = ({ banners = [], onClose }) => {
  const getDefaultStyles = () => {
    return banners.map(data => ({
      key: `banner-${data.id}`,
      data,
      style: { maxHeight: 0, opacity: 1, marginTop: 0 }
    }));
  };

  const getStyles = () => {
    return banners.map(data => ({
      key: `banner-${data.id}`,
      data,
      style: {
        marginTop: spring(8, presets.gentle),
        maxHeight: spring(100, presets.gentle),
        opacity: spring(1, presets.gentle)
      }
    }));
  };

  const willEnter = () => ({
    marginTop: 0,
    maxHeight: 0,
    opacity: 1
  });

  const willLeave = () => ({
    marginTop: spring(0),
    maxHeight: spring(0),
    opacity: spring(0)
  });

  const getBanners = styles => (
    <>
      {styles.map(
        ({ key, style, data: { id, message, backgroundColor, textColor } }) => (
          <Banner
            id={id}
            key={key}
            message={message}
            style={style}
            onClose={onClose}
            backgroundColor={backgroundColor}
            textColor={textColor}
          />
        )
      )}
    </>
  );

  return (
    <Box width="100vw" flexDirection="column" zIndex={51}>
      <TransitionMotion
        defaultStyles={getDefaultStyles()}
        styles={getStyles()}
        willLeave={willLeave}
        willEnter={willEnter}
      >
        {getBanners}
      </TransitionMotion>
    </Box>
  );
};

BannerList.propTypes = {
  banners: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      message: PropTypes.string,
      backgroundColor: PropTypes.string,
      textColor: PropTypes.string
    })
  ).isRequired,
  onClose: PropTypes.func.isRequired
};

export default BannerList;
