import { DELIMITER } from "../utils/sisense_alerts";

export function getKpiFromAlert(alertName, kpis) {
  if (!alertName) {
    return {};
  }
  const [x, kpiName, ...y] = alertName.split(DELIMITER);
  let kpi = {};
  for (let i = 0; i < kpis.length; i++) {
    const { label } = kpis[i];
    if (kpiName === label) {
      kpi = kpis[i];
    }
  }
  return kpi;
}

export function getNameFromAlert(alertName, nameList) {
  /**
   * find Property Group / Property name for an alert
   *
   * @param {string}    alertName   The full name/title of the alert
   * @param {[string]}  nameList    List of all the Property Group / Property Names
   *
   * @return {string}         The longest matching string
   * @return {Void}           Null if no matching string or no alertName
   */

  let response = null;
  if (alertName) {
    const matches = nameList.filter(n => alertName.includes(n));
    let sortMatches = matches.sort((a, b) => b.length - a.length);
    if (sortMatches.length) {
      response = sortMatches[0];
    }
  }
  return response;
}
