const initialState = {
  success: null,
  isLoading: false,
  distributedSourcesByProject: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "AJAX_POST_CREATE_REPORT_REQUEST": {
      return { ...state, success: null, isLoading: true };
    }
    case "AJAX_POST_CREATE_REPORT_SUCCESS": {
      return { ...state, success: true, isLoading: false };
    }
    case "AJAX_POST_CREATE_REPORT_ERROR": {
      return {
        ...state,
        success: false,
        isLoading: false
      };
    }
    case "AJAX_GET_REPORT_SETTINGS_SUCCESS": {
      return {
        ...state,
        distributedSourcesByProject:
          action.payload["distributedSourcesByProject"]
      };
    }
    default:
      return state;
  }
};
