import React from "react";
import _cloneDeep from "lodash/cloneDeep";
import _isEmpty from "lodash/isEmpty";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  Checkbox,
  Flex,
  Icon,
  Item,
  Text,
  RadioGroup
} from "concrete-ui";
import RenderIf from "../render_if";

import "./dropdown_filters.scss";

export default class DropdownFilters extends React.Component {
  static categoryType = PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      options: PropTypes.array
    })
  );
  static propTypes = {
    size: PropTypes.oneOf(["small", "large"]),
    menuPlacement: PropTypes.oneOf(["middle", "left"]),
    sections: DropdownFilters.categoryType.isRequired,
    onApply: PropTypes.func.isRequired,
    dropdownTitle: PropTypes.string,
    buttonText: PropTypes.string,
    containerProps: PropTypes.object,
    selectType: PropTypes.oneOf(["checkbox", "radio"]),
    disabled: PropTypes.bool,
    initialFilters: PropTypes.object
  };
  static defaultProps = {
    size: "small",
    menuPlacement: "middle",
    dropdownTitle: "Filters",
    buttonText: "Filter",
    selectType: "checkbox",
    disabled: false,
    initialFilters: {}
  };

  get defaultInitialFilters() {
    let initialFilters = {};
    this.props.sections.forEach(section => (initialFilters[section.id] = []));
    return initialFilters;
  }

  constructor(props) {
    super(props);
    this.state = {
      open: false,
      openCategory: "",
      // TODO: Consider using Set or Map for `filters` value instead of Array
      // that would allow to avoid passing through whole Array every time when we want to check if value is in filter
      // and also simplify the code
      filters: !_isEmpty(props.initialFilters)
        ? _cloneDeep(props.initialFilters)
        : this.defaultInitialFilters
    };
    this.state.quantity = this.getTotalFilterQuantity();
    this.toggleOpenCategory = this.toggleOpenCategory.bind(this);
    this.onSelection = this.onSelection.bind(this);
  }

  toggleMenu = () => this.setState({ open: !this.state.open });

  toggleOpenCategory(categoryId) {
    if (categoryId === this.state.openCategory) {
      this.setState({ openCategory: "" });
    } else {
      this.setState({ openCategory: categoryId });
    }
  }

  onSelection(categoryId, selection, remove) {
    const updatedList = this.state.filters;
    if (this.props.selectType == "radio") {
      if (remove) {
        updatedList[categoryId] = [];
      } else {
        updatedList[categoryId] = [selection];
      }
    }
    if (this.props.selectType == "checkbox") {
      if (
        !updatedList[categoryId].some(
          current => current.value === selection.value
        )
      ) {
        updatedList[categoryId] = [...updatedList[categoryId], selection];
      } else {
        updatedList[categoryId] = updatedList[categoryId].filter(
          current => current.value !== selection.value
        );
      }
    }
    this.setState({ filters: updatedList });
  }

  clearFilters = e => {
    const updatedList = this.defaultInitialFilters;
    this.setState({ filters: updatedList });
  };

  clearHandler = () => {
    const updatedList = this.defaultInitialFilters;
    const quantity = 0;
    this.setState({ filters: updatedList, quantity }, () => {
      this.props.onApply(_cloneDeep(updatedList));
    });
  };

  getTotalFilterQuantity = () => {
    let count = 0;
    Object.values(this.state.filters).map(value => {
      count += value.length;
    });
    return count;
  };

  getMenuProps = () => {
    const props = {};
    if (this.props.menuPlacement === "middle") {
      props.left = "50%";
      props.style = { transform: "translateX(-50%)" };
    }
    if (this.props.menuPlacement === "left") {
      props.right = 0;
    }
    return props;
  };

  onApplyHandler = () => {
    const quantity = this.getTotalFilterQuantity();
    this.setState({ open: false, quantity }, () => {
      this.props.onApply(_cloneDeep(this.state.filters));
    });
  };

  render() {
    const {
      sections,
      size,
      dropdownTitle,
      buttonText,
      containerProps,
      selectType,
      disabled
    } = this.props;
    const { open, openCategory, filters, quantity } = this.state;
    return (
      <Box position="relative" {...containerProps}>
        {!!quantity && (
          <Button
            variant="secondary"
            size={size}
            mr={4}
            onClick={this.clearHandler}
          >
            Clear
          </Button>
        )}
        <Flex>
          <Button
            onClick={this.toggleMenu}
            variant="secondary"
            size={size}
            selected={!!quantity}
            disabled={disabled}
          >
            <Icon.Filters width={4} height={4} $color="text.white.1" />
            {buttonText}
            {quantity !== 0 && (
              <Box
                borderRadius="50%"
                size={4}
                justifyContent="center"
                alignItems="center"
                backgroundColor="white.1"
                ml={2}
              >
                <Text textSize={1} $color="text.gray.0" $fontWeight={500}>
                  {quantity}
                </Text>
              </Box>
            )}
          </Button>
          {open && (
            <Box
              position="absolute"
              top="100%"
              {...this.getMenuProps()}
              flexDirection="column"
              width="15.625rem"
              mt={2}
              backgroundColor="gray.3"
              border="1px solid"
              borderColor="gray.4"
              borderRadius={1}
              zIndex={100}
              boxShadow="level.2"
            >
              <Box
                borderBottom="1px solid"
                borderBottomColor="gray.4"
                backgroundColor="gray.2"
              >
                <Flex
                  width="100%"
                  padding={3}
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Button
                    variant="secondary"
                    size="small"
                    onClick={this.clearFilters}
                  >
                    Clear
                  </Button>
                  <Text textSize={2} $fontWeight={500} $color="text.white.1">
                    {dropdownTitle}
                  </Text>
                  <Button
                    variant="primary"
                    size="small"
                    onClick={this.onApplyHandler}
                  >
                    Apply
                  </Button>
                </Flex>
              </Box>
              <Box flexDirection="column" maxHeight="246px" overflow="auto">
                {sections.map(category => (
                  <Box
                    curpos="pointer"
                    borderBottom="1px solid"
                    borderColor="gray.4"
                    key={category.id}
                  >
                    <DropdownFilter
                      onToggleCategory={this.toggleOpenCategory}
                      category={category}
                      categoryIsOpen={category.id === openCategory}
                      onSelection={this.onSelection}
                      selected={filters[category.id]}
                      selectType={selectType}
                    />
                  </Box>
                ))}
              </Box>
            </Box>
          )}
        </Flex>
      </Box>
    );
  }
}

class DropdownFilter extends React.Component {
  static propTypes = {
    category: PropTypes.object,
    categoryIsOpen: PropTypes.bool,
    onToggleCategory: PropTypes.func,
    selected: PropTypes.array,
    onSelection: PropTypes.func,
    selectType: PropTypes.string
  };

  getSelectedOptions = option => {
    return this.props.selected.some(current => current.value === option.value);
  };

  onToggleCategory = () => {
    this.props.onToggleCategory(this.props.category.id);
  };

  render() {
    const {
      category,
      categoryIsOpen,
      selected,
      onSelection,
      selectType
    } = this.props;
    const options = category["options"];
    const iconClass = categoryIsOpen
      ? "dropdown-filters__arrow-up"
      : "dropdown-filters__arrow-down";
    return (
      <Flex width="100%" flexDirection="column" zIndex={0}>
        <Item p={2} m={0} size="large" onClick={this.onToggleCategory}>
          <Text
            ml={1}
            textSize={2}
            lineHeight={2}
            $color="text.white.1"
            className="dropdown-filters__text"
          >
            {category.title}
          </Text>
          <Flex ml="auto">
            {selected.length > 0 ? (
              <Text
                textSize={2}
                lineHeight={2}
                $color="text.white.1"
                $fontWeight={500}
                mr={1}
              >
                {selected.length}
              </Text>
            ) : null}
            <Icon.ChevronRight color="text.white.1" className={iconClass} />
          </Flex>
        </Item>
        {categoryIsOpen && (
          <Box
            p={0}
            forwardedAs="ul"
            flexDirection="column"
            backgroundColor="gray.2"
          >
            <RenderIf condition={selectType == "checkbox"}>
              {options.map(option => (
                <Box
                  forwardedAs="li"
                  alignItems="center"
                  borderColor="gray.4"
                  key={option.value}
                >
                  <Checkbox
                    px={2}
                    width="100%"
                    name={option.value}
                    label={option.label}
                    value={this.getSelectedOptions(option)}
                    onChange={() => onSelection(category.id, option)}
                    className="dropdown-filters__text"
                  />
                </Box>
              ))}
            </RenderIf>
            <RenderIf condition={selectType == "radio"}>
              <RadioGroup
                title={""}
                options={options}
                activeRadioId={selected.length ? selected[0].id : null}
                errorMessage=""
                itemsProps={{ flexDirection: "column" }}
                onChange={value => () => {
                  const selectionOption = options.find(
                    entry => entry.id === value
                  );
                  onSelection(category.id, selectionOption);
                }}
              />
            </RenderIf>
          </Box>
        )}
      </Flex>
    );
  }
}
