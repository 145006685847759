import React, { useEffect } from "react";
import { connect } from "react-redux";
import BannerList from "../../components/banners";
import { banners as actions } from "../../redux_base/actions";

const BannersContainer = ({ banners, dispatch }) => {
  useEffect(() => {
    dispatch(actions.requestBanners());
  }, []);

  const onCloseHandler = id => {
    const updatedBanners = banners.map(banner => {
      if (banner.id === id) {
        banner.closed = true;
      }
      return banner;
    });
    dispatch(actions.update(updatedBanners));
  };

  const filteredBanners = banners.filter(banner => !banner.closed);

  return <BannerList banners={filteredBanners} onClose={onCloseHandler} />;
};

const mapState = state => ({
  ...state.banners
});

export default connect(mapState)(BannersContainer);
