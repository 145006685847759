import React, { useEffect } from "react";
import { Button, Container, Divider, Flex, Icon } from "concrete-ui";
import PropTypes from "prop-types";
import _find from "lodash/find";

import AdminBreadcrumb from "../admin_breadcrumb";
import Breadcrumbs from "../breadcrumbs";
import { frequencyOpts } from "../expense_entry_form/options";
import EnhancedLink from "../enhanced_link";
import ExpenseTable from "../expense_table";
import ExpensesFilter from "./filters";
import FilterTags from "./filter_tags";
import { calculateCostBudgeted } from "./utils";

function Expenses({ expenses, properties, ...props }) {
  const [data, setData] = React.useState(expenses);
  const [filters, setFilters] = React.useState({});
  const dropdownFilters = React.useRef(null);

  useEffect(() => {
    setData(expenses);
  }, [expenses]);

  const mapTableData = data => {
    return {
      id: data.id,
      property: _find(properties, ["property_id", data.property_id])
        ?.property_name,
      expense_name: data.expense_name,
      lead_sources: data.lead_sources,
      last_actual_expense: data.last_actual_expense,
      last_actual_expense_automated: data.last_actual_expense_automated,
      cost_budgeted: calculateCostBudgeted(data),
      frequency: _find(frequencyOpts, ["value", data.frequency])?.label,
      vendor: data.vendor?.label,
      user: data.user_id?.label,
      last_updated: data.last_updated
    };
  };

  const removeTag = ({ type, value }) => {
    dropdownFilters.current.onSelection(type, { value });
    dropdownFilters.current.onApplyHandler();
  };

  const tagTypes = [
    {
      id: "property_id",
      title: "Property"
    },
    {
      id: "expense_name",
      title: "Expense Name"
    },
    {
      id: "expense_type",
      title: "Expense Type"
    },
    {
      id: "frequency",
      title: "Frequency"
    },
    {
      id: "vendor",
      title: "Vendor"
    },
    {
      id: "user_id",
      title: "Creator"
    },
    {
      id: "lead_source",
      title: "Lead Source"
    }
  ];

  const breadcrumbs = [
    { text: <AdminBreadcrumb />, link: "" },
    { text: "Expenses", link: "" }
  ];

  return (
    <>
      <Container>
        <Flex alignItems="center" height={13}>
          <Breadcrumbs
            breadcrumbs={breadcrumbs}
            containerProps={{ variant: "fullwidth" }}
          />
          <EnhancedLink to="/expenses/add">
            <Button
              textDecoration="none"
              variant="secondary"
              size="small"
              ml={5}
            >
              <Icon.Plus $color="text.white.1" />
              Add Expenses
            </Button>
          </EnhancedLink>
        </Flex>
      </Container>
      <Divider variant="horizontal" bg="gray.3" />
      <Container>
        <Flex justifyContent="flex-end" alignItems="center" height={12}>
          <ExpensesFilter
            ref={dropdownFilters}
            expenses={expenses}
            properties={properties}
            onSetFilters={filters => setFilters(filters)}
            onUpdateExpenses={filteredExpenses => setData(filteredExpenses)}
          />
        </Flex>
      </Container>
      <Divider variant="horizontal" bg="gray.3" />
      <Container>
        <Flex pt={4}>
          <FilterTags
            filters={filters}
            tagTypes={tagTypes}
            onRemoveTag={removeTag}
          />
        </Flex>
        <Flex pt={5} pb="8rem">
          <ExpenseTable data={data.map(mapTableData)} {...props} />
        </Flex>
      </Container>
    </>
  );
}
Expenses.propTypes = {
  expenses: PropTypes.arrayOf(PropTypes.object)
};
Expenses.defaultProps = {
  expenses: []
};

export default Expenses;
