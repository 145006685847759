export const REVENUE_ROI_DATA_LIMITATION_MSG =
  "A Yardi Voyager integration limitation may impact Revenue and ROI reports including dates prior to February 23, 2022.";

export const INCLUDES_AUTOMATED_ACTUAL_EXPENSES_MSG =
  "Includes actual expenses added automatically by Remarkably, based on pay-for-performance event occurrence.";

export const FILTER_BY_EXPENSES_TOOLTIP =
  "Default view includes Sources with Budgeted and Missing expenses; filter by available options for refined views and rankings.";

export const ZERO_BUDGETED_EXPENSE_MARKETING_RETURN_MSG =
  "Due to $0 Expense for this Source. Here, this value is considered a highest and best result.";

export const PROJECTED_MARKETING_RETURN_INFO =
  "Total of Projected New Lease Revenue (Gross Rent per Month multiplied by the Number of Months in New Lease)" +
  " minus Expenses, divided by Expenses.";

export const nwvSummaryDifferenceMsg = diff =>
  `Total is ${diff} more than the sum of matched and distributed values shown below.`;

export const getNWVMatchedMsg = matchedCount =>
  `Matched total of ${matchedCount} website referral sources.`;

export const getNWVDistributedMsg = distributedCount =>
  `Distributed total of ${distributedCount} website referral sources.`;
