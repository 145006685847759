import React from "react";
import _intersection from "lodash/intersection";
import _isEqual from "lodash/isEqual";
import PropTypes from "prop-types";

import { concreteErrorVariant } from "../../constants";
import {
  Button,
  TextInput,
  Box,
  Flex,
  Icon,
  InputWrapper,
  Text
} from "concrete-ui";
import { userConfig } from "./validators";
import useValidation from "../../utils/validator_hook";

export const Profile = ({
  user,
  profile,
  onSave = () => ({}),
  success = false,
  apiErrors = {}
}) => {
  const initialValues = {
    first_name: profile.first_name,
    last_name: profile.last_name
  };

  const { errors: clientErrors, getFormProps, getItemProps } = useValidation(
    userConfig,
    initialValues,
    onSave
  );

  const errors = {
    ...clientErrors,
    ...apiErrors
  };

  const showErrorMessage = () => {
    const errorFields = Object.keys(errors);
    if (!errorFields.length) {
      return;
    }
    const message = "Please review highlighted fields above.";
    return (
      <Flex alignItems="center" mx={6}>
        <Text textSize={2} $fontWeight="regular" $color="text.orange.0">
          {message}
        </Text>
      </Flex>
    );
  };

  const showSuccessMessage = () => (
    <Flex alignItems="center" mx={6}>
      <Icon.Check width={4} height={4} mr={2} $color="green.1" />
      <Text textSize={2} $fontWeight="regular">
        General info has been saved.
      </Text>
    </Flex>
  );

  const showUserMessage = () => {
    if (success) {
      return showSuccessMessage();
    } else if (Object.keys(errors).length) {
      return showErrorMessage();
    }

    return null;
  };

  const getRoleLabel = () => {
    return user?.is_property_admin ? "Admin" : "Member";
  };

  return (
    <Box display="block" flexGrow="1">
      <form autoComplete="off" {...getFormProps()}>
        <Flex px={7} pt={5} pb={6} flexDirection="column">
          <Flex flex="1" my={5}>
            <TextInput
              size="large"
              label="First Name"
              {...getItemProps("first_name")}
              mr={4}
              variant={errors["first_name"] && concreteErrorVariant}
            />
            <TextInput
              size="large"
              label="Last Name"
              {...getItemProps("last_name")}
              variant={errors["last_name"] && concreteErrorVariant}
            />
          </Flex>
          <Flex flex="1" my={5}>
            <InputWrapper
              label={{
                message: "Email"
              }}
              width="100%"
              mr={4}
            >
              <Text textSize={2} $fontWeight="regular">
                {user?.email}
              </Text>
            </InputWrapper>
            <InputWrapper
              label={{
                message: "User Role"
              }}
              width="100%"
            >
              <Text textSize={2} $fontWeight="regular">
                {getRoleLabel()}
              </Text>
            </InputWrapper>
          </Flex>
          <Flex alignItems="center" justifyContent="flex-end" my={5}>
            {showUserMessage()}
            <Button variant="primary" size="large" type="submit">
              Save
            </Button>
          </Flex>
        </Flex>
      </form>
    </Box>
  );
};

Profile.propTypes = {
  profile: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string
  }),
  user: PropTypes.shape({
    email: PropTypes.string,
    is_property_admin: PropTypes.bool
  }),
  getRoleLabel: PropTypes.func
};

Profile.defaultProps = {
  profile: {
    first_name: "",
    last_name: ""
  }
};

export default Profile;
