import React from "react";
import PropTypes from "prop-types";
import { Box, Container, Divider, Flex, Link, Text } from "concrete-ui";

import DistributedSourceLeadSources from "../distribution_lead_sources";
import checkFlag from "../../utils/posthog";
import DistributionNWV from "../distribution_nwv";
import ReferralSourcesProperties from "../referral_sources_properties";
import { DISTRIBUTION_NO_PROPS_AVAILABLE } from "../referral_sources/tooltips";
import {
  HEADERS,
  SELECT_DISTRIBUTE_NWV,
  SELECT_DISTRIBUTE_LEAD_SOURCE
} from "../distribution_tab/const";
import ReferralSourcesNWV2 from "../referral_sources_nwv2";
import { TabViews } from "../referral_sources/constants";
import { ZENDESK_HOW_TO_DISTRIBUTE_REFERRAL_SOURCES } from "../../constants";

class DistributionTab extends React.PureComponent {
  static propTypes = {
    portfolios: ReferralSourcesProperties.portfoliosType,
    selectedPortfolio: PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string
    }),
    properties: ReferralSourcesProperties.propertiesType,
    fetchingProperties: PropTypes.bool,
    leadSources: PropTypes.array,
    nwvSources: DistributionNWV.sourcesType,
    fetchProperties: PropTypes.func,
    undistributedSources: PropTypes.array,
    distributedSources: PropTypes.array,
    fetchingLeadSources: PropTypes.bool,
    fetchingLeadSourcesOptions: PropTypes.bool,
    fetchLeadSources: PropTypes.func,
    fetchNWV: PropTypes.func,
    fetchingNWVs: PropTypes.bool,
    onSave: PropTypes.func,
    saveCompleted: PropTypes.bool,
    selectLeadSource: PropTypes.func,
    clearPropertyState: PropTypes.func,
    leadSourcesAttribution: PropTypes.object,
    unbouncedVisitorsOnly: PropTypes.bool,
    distSourceSelectionOptions: PropTypes.instanceOf(Set),
    onSelectFilter: PropTypes.func
  };

  static defaultProps = {
    portfolios: [],
    selectedPortfolio: null,
    properties: [],
    fetchingProperties: false,
    leadSources: [],
    nwvSources: [],
    leadSourcesAttribution: {},
    distSourceSelectionOptions: new Set([]),
    fetchingLeadSources: false,
    fetchingLeadSourcesOptions: false,
    selectLeadSource: () => {},
    fetchProperties: () => {},
    fetchLeadSources: () => {},
    selectProperty: () => {},
    fetchNWV: () => {},
    onSave: () => {},
    clearPropertyState: () => {},
    saveCompleted: false
  };

  static initialState = {
    selectedProperty: null,
    selectedLeadSource: null,
    validExpenses: []
  };

  state = DistributionTab.initialState;

  componentWillUnmount() {
    this.props.clearPropertyState();
  }

  filterLeadSources(leadSources = []) {
    const response = leadSources.filter(key =>
      this.props.distSourceSelectionOptions.has(key.label)
    );
    return response;
  }

  onChangePortfolio = portfolio => {
    this.setState(
      { selectedPortfolio: portfolio, ...DistributionTab.initialState },
      () => {
        this.props.fetchProperties(this.state.selectedPortfolio);
      }
    );
  };

  onChangeProperty = property => {
    this.setState({ selectedProperty: property }, () => {
      this.props.selectProperty(this.state.selectedProperty);
    });
  };

  onSelectLeadSource = selectedLeadSource => {
    this.setState({ selectedLeadSource }, () => {
      this.props.selectLeadSource(
        this.state.selectedProperty,
        this.state.selectedLeadSource
      );
    });
  };

  onSaveHandler = ({ sources, unbouncedVisitorsOnly }) => {
    this.props.onSave(
      this.state.selectedProperty,
      this.state.selectedLeadSource,
      sources,
      unbouncedVisitorsOnly
    );
  };

  render() {
    const selectedLeadSource = this.state.selectedLeadSource;
    return (
      <>
        <Box flexDirection="column">
          <Container>
            <Flex py={6} maxWidth={600} flexDirection="column">
              <Text textSize={2} $fontWeight="regular" $color="text.gray.2">
                Distribute property website Lead Sources to New Website Visitor
                referral sources. This enables the 'Show Distributed Source
                Attribution’ toggle and weighted attribution view in Channel
                Analysis.{"  "}
                <Link
                  $color="text.gray.1"
                  variant="underline"
                  target="_blank"
                  href={ZENDESK_HOW_TO_DISTRIBUTE_REFERRAL_SOURCES}
                  ml={1}
                >
                  Learn More.
                </Link>
              </Text>
            </Flex>
          </Container>
          <Divider variant="horizontal" bg="gray.4" flexShrink={0} />
        </Box>
        <Box flexGrow={1} minHeight={0}>
          <ReferralSourcesProperties
            portfolios={this.props.portfolios}
            selectedPortfolio={this.props.selectedPortfolio}
            properties={this.props.properties}
            fetchingProperties={this.props.fetchingProperties}
            selectedProperty={this.state.selectedProperty}
            onChangePortfolio={this.onChangePortfolio}
            onChangeProperty={this.onChangeProperty}
            noPropertiesAvailableMessage={DISTRIBUTION_NO_PROPS_AVAILABLE}
            tab={"distribution"}
          />
          <Divider variant="vertical" bg="gray.4" flexShrink={0} />
          <DistributedSourceLeadSources
            undistributedSources={this.filterLeadSources(
              this.props.undistributedSources
            )}
            distributedSources={this.filterLeadSources(
              this.props.distributedSources
            )}
            fetchingLeadSources={this.props.fetchingLeadSources}
            fetchingLeadSourcesOptions={this.props.fetchingLeadSourcesOptions}
            onSelect={this.onSelectLeadSource}
            selectedProperty={this.state.selectedProperty}
          />
          <Divider variant="vertical" bg="gray.4" flexShrink={0} />
          {checkFlag("matching-new-filter-sort") ? (
            <ReferralSourcesNWV2
              sources={this.props.nwvSources}
              distribute={true}
              fetchingNWVs={this.props.fetchingNWVs}
              headers={HEADERS}
              nwvTitle={SELECT_DISTRIBUTE_NWV}
              leadSourceTitle={SELECT_DISTRIBUTE_LEAD_SOURCE}
              isSelectLeadSource={!!selectedLeadSource}
              selectedOnTop={
                this.props.leadSourcesAttribution[selectedLeadSource]
                  ?.distributed
              }
              unbouncedVisitorsOnly={this.props.unbouncedVisitorsOnly}
              saveCompleted={this.props.saveCompleted}
              onSave={this.onSaveHandler}
              onSelectFilter={this.props.onSelectFilter}
              tabView={TabViews.Distribution}
            />
          ) : (
            <DistributionNWV
              sources={this.props.nwvSources}
              headers={HEADERS}
              isSelectLeadSource={!!selectedLeadSource}
              distributedOnTop={
                this.props.leadSourcesAttribution[selectedLeadSource]
                  ?.distributed
              }
              saveCompleted={this.props.saveCompleted}
              onSave={this.onSaveHandler}
              unbouncedVisitorsOnly={this.props.unbouncedVisitorsOnly}
            />
          )}
        </Box>
      </>
    );
  }
}

export default DistributionTab;
