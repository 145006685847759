const SOURCES = [{ name: "Webapp", server: "LocalHost", type: "cube" }];
const DATA_SOURCE = {
  address: "LocalHost",
  database: "aWebapp",
  fullname: "LocalHost/Webapp",
  id: "aLOCALHOST_aWEBAPP",
  title: "Webapp"
};
const SISENSE_KPI = {
  leased_rate: "leased_rate",
  exposure_rate: "exposure_rate",
  occupied_rate: "occupied_rate",
  nwv: "nwv",
  leads: "leads",
  tours: "tours",
  lease_applications: "lease_applications",
  leases_executed: "leases_executed",
  move_ins: "move_ins",
  nwv_leads: "nwv_leads",
  leads_tou: "leads_tou",
  tou_app: "tou_app",
  app_exe: "app_exe",
  exe_ins: "exe_ins",
  lease_cds: "lease_cds",
  vacation_notices: "vacation_notices",
  renewals: "renewals",
  move_outs: "move_outs",
  app_approved: "app_approved",
  approved_lease: "approved_lease",
  approved_exe: "approved_exe"
  // lead_new_lease: "Lead > New Lease"
};
const SISENSE_KPI_LABEL = {
  [SISENSE_KPI.leased_rate]: "Leased %",
  [SISENSE_KPI.exposure_rate]: "Exposed %",
  [SISENSE_KPI.occupied_rate]: "Occupied %",
  [SISENSE_KPI.nwv]: "New Website Visitors",
  [SISENSE_KPI.leads]: "Leads",
  [SISENSE_KPI.tours]: "Tours",
  [SISENSE_KPI.lease_applications]: "Lease Applications",
  [SISENSE_KPI.leases_executed]: "New Leases",
  [SISENSE_KPI.move_ins]: "Move Ins",
  [SISENSE_KPI.nwv_leads]: "New Website Visitors > Leads %",
  [SISENSE_KPI.leads_tou]: "Leads > Tours %",
  [SISENSE_KPI.tou_app]: "Tours > Lease Applications %",
  [SISENSE_KPI.exe_ins]: "New Leases > Move Ins %",
  [SISENSE_KPI.lease_cds]: "Cancellation and Denials Rate %",
  [SISENSE_KPI.vacation_notices]: "Notices to Vacate",
  [SISENSE_KPI.renewals]: "Renewals",
  [SISENSE_KPI.move_outs]: "Move Outs",
  [SISENSE_KPI.app_approved]: "Lease Applications > Approved Leases %",
  [SISENSE_KPI.approved_lease]: "Approved Leases",
  [SISENSE_KPI.approved_exe]: "Approved Leases > New Leases %"

  // [SISENSE_KPI.lead_new_lease]: "Lead > New Lease"
};

function getTimeFilter(timeRange) {
  const timeFilter = {
    day: { filter: { last: { count: 1, offset: 0 } }, level: "days" },
    week: { filter: { last: { count: 7, offset: 0 } }, level: "days" },
    month: { filter: { last: { count: 1, offset: 0 } }, level: "months" }
  };
  return {
    jaql: {
      datatype: "datetime",
      dim: "[projects_period.first_day_of_week]",
      ...timeFilter[timeRange],
      merged: true,
      table: "projects_period"
    },
    panel: "scope"
  };
}

function getPropertyJaqlFilters(propertyId, timeRange) {
  let response = [getPropertyFilter(propertyId)];
  if (timeRange !== "day") {
    response.push(getTimeFilter(timeRange));
  }
  return response;
}

function getPropertiesJaqlFilter(propertiesId, timeRange) {
  let response = [getPropertiesFilter(propertiesId)];
  if (timeRange !== "day") {
    response.push(getTimeFilter(timeRange));
  }
  return response;
}

function getPropertyFilter(propertyId) {
  return {
    disabled: false,
    isCascading: false,
    asDimension: false,
    title: "public_id",
    value: propertyId,
    jaql: {
      collapsed: true,
      column: "public_id",
      datatype: "text",
      dim: "[projects_project.public_id]",
      filter: {
        explicit: true,
        members: [propertyId],
        multiSelection: true
      },
      merged: true,
      panel: "scope",
      table: "projects_project",
      title: "public_id"
    },
    panel: "scope"
  };
}

function getPropertiesFilter(propertiesIds) {
  return {
    disabled: false,
    isCascading: false,
    asDimension: false,
    title: "public_id",
    jaql: {
      collapsed: true,
      column: "public_id",
      datatype: "text",
      dim: "[projects_project.public_id]",
      filter: {
        explicit: true,
        members: propertiesIds,
        multiSelection: true
      },
      merged: true,
      panel: "scope",
      table: "projects_project",
      title: "public_id"
    },
    panel: "scope"
  };
}

function jaqlLeasedRate(propertyId, timeRange) {
  return {
    sources: SOURCES,
    kpi: {
      datasource: DATA_SOURCE,
      metadata: {
        dimensions: [],
        filters: getPropertyJaqlFilters(propertyId, timeRange),
        measures: [
          {
            jaql: {
              context: {
                "[3E8FD-CC4]": {
                  table: "projects_period",
                  column: "leased_units_start",
                  dim: "[projects_period.leased_units_start]",
                  datatype: "numeric",
                  agg: "sum",
                  title: "Total leased_units_start"
                },
                "[89448-9D6]": {
                  table: "projects_property",
                  column: "total_units",
                  dim: "[projects_property.total_units]",
                  datatype: "numeric",
                  agg: "sum",
                  title: "Total total_units"
                },
                "[0FDE0-B68]": {
                  table: "projects_period",
                  column: "DateRank",
                  dim: "[projects_period.DateRank]",
                  datatype: "numeric",
                  title: "DateRank",
                  filter: {
                    top: 1,
                    by: {
                      type: "measure",
                      formula: "[3D9FF-D71]",
                      context: {
                        "[3D9FF-D71]": {
                          table: "projects_period",
                          column: "DateRank",
                          dim: "[projects_period.DateRank]",
                          datatype: "numeric",
                          agg: "max",
                          title: "Max DateRank"
                        }
                      },
                      title: "[Max DateRank]"
                    }
                  }
                }
              },
              formula: "([3E8FD-CC4], [0FDE0-B68]) / [89448-9D6]",
              title: SISENSE_KPI_LABEL.leased_rate,
              type: "measure"
            }
          }
        ]
      }
    }
  };
}

function jaqlExposureRate(propertyId, timeRange) {
  return {
    sources: SOURCES,
    kpi: {
      datasource: DATA_SOURCE,
      metadata: {
        dimensions: [],
        filters: getPropertyJaqlFilters(propertyId, timeRange),
        measures: [
          {
            jaql: {
              type: "measure",
              formula:
                "IF(ISNULL([69BCB-6B0]), NULL,(([49010-5CE], [B21A2-639])/[16B54-FFE]))\n",
              context: {
                "[16B54-FFE]": {
                  table: "projects_property",
                  column: "total_units",
                  dim: "[projects_property.total_units]",
                  datatype: "numeric",
                  agg: "max",
                  title: "Max total_units"
                },
                "[69BCB-6B0]": {
                  table: "projects_period",
                  column: "exposure",
                  dim: "[projects_period.exposure]",
                  datatype: "numeric",
                  title: "Total exposure",
                  agg: "sum"
                },
                "[49010-5CE]": {
                  table: "projects_period",
                  column: "exposure",
                  dim: "[projects_period.exposure]",
                  datatype: "numeric",
                  title: "Max exposure",
                  agg: "max"
                },
                "[2369A-091]": {
                  table: "projects_period",
                  column: "exposure",
                  dim: "[projects_period.exposure]",
                  datatype: "numeric",
                  title: "exposure"
                },
                "[B21A2-639]": {
                  table: "projects_period",
                  column: "DateRank",
                  dim: "[projects_period.DateRank]",
                  datatype: "numeric",
                  title: "DateRank"
                }
              },
              title: SISENSE_KPI_LABEL.exposure_rate
            }
          }
        ]
      }
    }
  };
}

function jaqlOccupiedRate(propertyId, timeRange) {
  return {
    sources: SOURCES,
    kpi: {
      datasource: DATA_SOURCE,
      metadata: {
        dimensions: [],
        filters: getPropertyJaqlFilters(propertyId, timeRange),
        measures: [
          {
            jaql: {
              context: {
                "[9E0F6-585]": {
                  table: "projects_period",
                  column: "occupied_units_start",
                  dim: "[projects_period.occupied_units_start]",
                  datatype: "numeric",
                  agg: "sum",
                  title: "Total occupied_units_start"
                },
                "[00949-7B6]": {
                  table: "projects_property",
                  column: "total_units",
                  dim: "[projects_property.total_units]",
                  datatype: "numeric",
                  agg: "sum",
                  title: "Total total_units"
                },
                "[3910B-241]": {
                  table: "projects_period",
                  column: "DateRank",
                  dim: "[projects_period.DateRank]",
                  datatype: "numeric",
                  title: "DateRank",
                  filter: {
                    top: 1,
                    by: {
                      type: "measure",
                      formula: "[3D9FF-D71]",
                      context: {
                        "[3D9FF-D71]": {
                          table: "projects_period",
                          column: "DateRank",
                          dim: "[projects_period.DateRank]",
                          datatype: "numeric",
                          agg: "max",
                          title: "Max DateRank"
                        }
                      },
                      title: "[Max DateRank]"
                    }
                  }
                }
              },
              formula: "([9E0F6-585], [3910B-241]) / [00949-7B6]",
              title: SISENSE_KPI_LABEL.occupied_rate,
              type: "measure"
            },
            source: "value"
          }
        ]
      }
    }
  };
}

function jaqlNWV(propertyId, timeRange) {
  return {
    sources: SOURCES,
    kpi: {
      datasource: DATA_SOURCE,
      metadata: {
        dimensions: [],
        filters: getPropertyJaqlFilters(propertyId, timeRange),
        measures: [
          {
            jaql: {
              agg: "sum",
              column: "usvs",
              datatype: "numeric",
              dim: "[projects_period.usvs]",
              table: "projects_period",
              title: SISENSE_KPI_LABEL.nwv
            },
            source: "value"
          }
        ]
      }
    }
  };
}

function jaqlLeads(propertyId, timeRange) {
  return {
    sources: SOURCES,
    kpi: {
      datasource: DATA_SOURCE,
      metadata: {
        dimensions: [],
        filters: getPropertyJaqlFilters(propertyId, timeRange),
        measures: [
          {
            jaql: {
              agg: "sum",
              column: "inquiries",
              datatype: "numeric",
              dim: "[projects_period.inquiries]",
              table: "projects_period",
              title: SISENSE_KPI_LABEL.leads
            },
            source: "value"
          }
        ]
      }
    }
  };
}

function jaqlTours(propertyId, timeRange) {
  return {
    sources: SOURCES,
    kpi: {
      datasource: DATA_SOURCE,
      metadata: {
        dimensions: [],
        filters: getPropertyJaqlFilters(propertyId, timeRange),
        measures: [
          {
            jaql: {
              agg: "sum",
              column: "tours",
              datatype: "numeric",
              dim: "[projects_period.tours]",
              table: "projects_period",
              title: SISENSE_KPI_LABEL.tours
            },
            source: "value"
          }
        ]
      }
    }
  };
}

function jaqlLeaseApplications(propertyId, timeRange) {
  return {
    sources: SOURCES,
    kpi: {
      datasource: DATA_SOURCE,
      metadata: {
        dimensions: [],
        filters: getPropertyJaqlFilters(propertyId, timeRange),
        measures: [
          {
            jaql: {
              agg: "sum",
              column: "lease_applications",
              datatype: "numeric",
              dim: "[projects_period.lease_applications]",
              table: "projects_period",
              title: SISENSE_KPI_LABEL.lease_applications
            },
            source: "value"
          }
        ]
      }
    }
  };
}

function jaqlLeasesExecuted(propertyId, timeRange) {
  return {
    sources: SOURCES,
    kpi: {
      datasource: DATA_SOURCE,
      metadata: {
        dimensions: [],
        filters: getPropertyJaqlFilters(propertyId, timeRange),
        measures: [
          {
            jaql: {
              agg: "sum",
              column: "leases_executed",
              datatype: "numeric",
              dim: "[projects_period.leases_executed]",
              table: "projects_period",
              title: SISENSE_KPI_LABEL.leases_executed
            },
            source: "value"
          }
        ]
      }
    }
  };
}

function jaqlMoveIns(propertyId, timeRange) {
  return {
    sources: SOURCES,
    kpi: {
      datasource: DATA_SOURCE,
      metadata: {
        dimensions: [],
        filters: getPropertyJaqlFilters(propertyId, timeRange),
        measures: [
          {
            jaql: {
              agg: "sum",
              column: "move_ins",
              datatype: "numeric",
              dim: "[projects_period.move_ins]",
              table: "projects_period",
              title: SISENSE_KPI_LABEL.move_ins
            },
            source: "value"
          }
        ]
      }
    }
  };
}

function jaqlNwvLeads(propertyId, timeRange) {
  return {
    sources: SOURCES,
    kpi: {
      datasource: DATA_SOURCE,
      metadata: {
        dimensions: [],
        filters: getPropertyJaqlFilters(propertyId, timeRange),
        measures: [
          {
            jaql: {
              context: {
                "[4AA93-CD0]": {
                  column: "inquiries",
                  datatype: "numeric",
                  dim: "[projects_period.inquiries]",
                  table: "projects_period",
                  title: "inquiries"
                },
                "[7FEAB-7F8]": {
                  column: "usvs",
                  datatype: "numeric",
                  dim: "[projects_period.usvs]",
                  table: "projects_period",
                  title: "Usvs"
                }
              },
              formula: "SUM( [4AA93-CD0] )/sum([7FEAB-7F8])",
              title: SISENSE_KPI_LABEL.nwv_leads,
              type: "measure"
            },
            source: "value"
          }
        ]
      }
    }
  };
}

function jaqlLeadsTou(propertyId, timeRange) {
  return {
    sources: SOURCES,
    kpi: {
      datasource: DATA_SOURCE,
      metadata: {
        dimensions: [],
        filters: getPropertyJaqlFilters(propertyId, timeRange),
        measures: [
          {
            jaql: {
              context: {
                "[4C09E-B9D]": {
                  column: "inquiries",
                  datatype: "numeric",
                  dim: "[projects_period.inquiries]",
                  table: "projects_period",
                  title: "Inquiries"
                },
                "[DCB11-20A]": {
                  column: "tours",
                  datatype: "numeric",
                  dim: "[projects_period.tours]",
                  table: "projects_period",
                  title: "tours"
                }
              },
              formula: "SUM([DCB11-20A]) /sum([4C09E-B9D])",
              title: SISENSE_KPI_LABEL.leads_tou,
              type: "measure"
            },
            source: "value"
          }
        ]
      }
    }
  };
}

function jaqlTouApp(propertyId, timeRange) {
  return {
    sources: SOURCES,
    kpi: {
      datasource: DATA_SOURCE,
      metadata: {
        dimensions: [],
        filters: getPropertyJaqlFilters(propertyId, timeRange),
        measures: [
          {
            jaql: {
              context: {
                "[BC58D-8E9]": {
                  table: "projects_period",
                  column: "lease_applications",
                  dim: "[projects_period.lease_applications]",
                  datatype: "numeric",
                  title: "lease_applications"
                },
                "[816BD-17F]": {
                  table: "projects_period",
                  column: "tours",
                  dim: "[projects_period.tours]",
                  datatype: "numeric",
                  title: "Tours"
                }
              },
              formula: "SUM ([BC58D-8E9]) /sum([816BD-17F])",
              title: SISENSE_KPI_LABEL.tou_app,
              type: "measure"
            },
            format: {
              nullzero: true
            },
            source: "value"
          }
        ]
      }
    }
  };
}

function jaqlAppExe(propertyId, timeRange) {
  return {
    sources: SOURCES,
    kpi: {
      datasource: DATA_SOURCE,
      metadata: {
        dimensions: [],
        filters: getPropertyJaqlFilters(propertyId, timeRange),
        measures: [
          {
            jaql: {
              context: {
                "[0AC0D-96C]": {
                  table: "projects_period",
                  column: "lease_applications",
                  dim: "[projects_period.lease_applications]",
                  datatype: "numeric",
                  agg: "sum",
                  title: "Total lease_applications"
                },
                "[789BE-177]": {
                  table: "projects_period",
                  column: "leases_executed",
                  dim: "[projects_period.leases_executed]",
                  datatype: "numeric",
                  agg: "sum",
                  title: "Total leases_executed"
                }
              },
              formula: "[789BE-177]/[0AC0D-96C]",
              title: SISENSE_KPI_LABEL.app_exe,
              type: "measure"
            },
            format: {
              nullzero: false
            },
            source: "value"
          }
        ]
      }
    }
  };
}

function jaqlExeIns(propertyId, timeRange) {
  return {
    sources: SOURCES,
    kpi: {
      datasource: DATA_SOURCE,
      metadata: {
        dimensions: [],
        filters: getPropertyJaqlFilters(propertyId, timeRange),
        measures: [
          {
            jaql: {
              context: {
                "[EE7A9-349]": {
                  table: "projects_period",
                  column: "leases_executed",
                  dim: "[projects_period.leases_executed]",
                  datatype: "numeric",
                  title: "leases_executed"
                },
                "[CF4C0-962]": {
                  table: "projects_period",
                  column: "move_ins",
                  dim: "[projects_period.move_ins]",
                  datatype: "numeric",
                  title: "move_ins"
                }
              },
              formula: "sum([CF4C0-962])/SUM([EE7A9-349])",
              title: SISENSE_KPI_LABEL.exe_ins,
              type: "measure"
            },
            format: {
              nullzero: true
            },
            source: "value"
          }
        ]
      }
    }
  };
}

function jaqlLeaseCDs(propertyId, timeRange) {
  return {
    sources: SOURCES,
    kpi: {
      datasource: DATA_SOURCE,
      metadata: {
        dimensions: [],
        filters: getPropertyJaqlFilters(propertyId, timeRange),
        measures: [
          {
            jaql: {
              context: {
                "[3CC63-135]": {
                  column: "lease_applications",
                  datatype: "numeric",
                  dim: "[projects_period.lease_applications]",
                  table: "projects_period",
                  title: "Lease Applications"
                },
                "[FCD66-4D0]": {
                  column: "lease_cds",
                  datatype: "numeric",
                  dim: "[projects_period.lease_cds]",
                  table: "projects_period",
                  title: "lease_cds"
                }
              },
              formula: "sum([FCD66-4D0])/sum([3CC63-135])",
              title: SISENSE_KPI_LABEL.lease_cds,
              type: "measure"
            },
            format: {
              nullzero: true
            },
            source: "value"
          }
        ]
      }
    }
  };
}

function jaqlVacationNotices(propertyId, timeRange) {
  return {
    sources: SOURCES,
    kpi: {
      datasource: DATA_SOURCE,
      metadata: {
        dimensions: [],
        filters: getPropertyJaqlFilters(propertyId, timeRange),
        measures: [
          {
            jaql: {
              agg: "sum",
              column: "lease_vacation_notices",
              datatype: "numeric",
              dim: "[projects_period.lease_vacation_notices]",
              table: "projects_period",
              title: SISENSE_KPI_LABEL.vacation_notices
            },
            source: "value"
          }
        ]
      }
    }
  };
}

function jaqlRenewals(propertyId, timeRange) {
  return {
    sources: SOURCES,
    kpi: {
      datasource: DATA_SOURCE,
      metadata: {
        dimensions: [],
        filters: getPropertyJaqlFilters(propertyId, timeRange),
        measures: [
          {
            jaql: {
              agg: "sum",
              column: "lease_renewals",
              datatype: "numeric",
              dim: "[projects_period.lease_renewals]",
              table: "projects_period",
              title: SISENSE_KPI_LABEL.renewals
            },
            source: "value"
          }
        ]
      }
    }
  };
}

function jaqlMoveOuts(propertyId, timeRange) {
  return {
    sources: SOURCES,
    kpi: {
      datasource: DATA_SOURCE,
      metadata: {
        dimensions: [],
        filters: getPropertyJaqlFilters(propertyId, timeRange),
        measures: [
          {
            jaql: {
              agg: "sum",
              column: "move_outs",
              datatype: "numeric",
              dim: "[projects_period.move_outs]",
              table: "projects_period",
              title: SISENSE_KPI_LABEL.move_outs
            },
            source: "value"
          }
        ]
      }
    }
  };
}

function jaqlApproved(propertyId, timeRange) {
  return {
    sources: SOURCES,
    kpi: {
      datasource: DATA_SOURCE,
      metadata: {
        dimensions: [],
        filters: getPropertyJaqlFilters(propertyId, timeRange),
        measures: [
          {
            jaql: {
              table: "projects_period",
              column: "approvals",
              dim: "[projects_period.approvals]",
              datatype: "numeric",
              agg: "sum",
              title: SISENSE_KPI_LABEL.approved_lease
            },
            source: "value"
          }
        ]
      }
    }
  };
}

function jaqlApprovedExe(propertyId, timeRange) {
  return {
    sources: SOURCES,
    kpi: {
      datasource: DATA_SOURCE,
      metadata: {
        dimensions: [],
        filters: getPropertyJaqlFilters(propertyId, timeRange),
        measures: [
          {
            jaql: {
              type: "measure",
              formula: "SUM([12AB9-945])/SUM([D2304-658])",
              context: {
                "[2A0D7-66B]": {
                  table: "projects_period",
                  column: "leases_executed",
                  dim: "[projects_period.leases_executed]",
                  datatype: "numeric",
                  agg: "sum",
                  title: "Total leases_executed"
                },
                "[D2304-658]": {
                  table: "projects_period",
                  column: "leases_executed",
                  dim: "[projects_period.leases_executed]",
                  datatype: "numeric",
                  title: "leases_executed"
                },
                "[12AB9-945]": {
                  table: "projects_period",
                  column: "approvals",
                  dim: "[projects_period.approvals]",
                  datatype: "numeric",
                  title: "Approvals"
                }
              },
              title: SISENSE_KPI_LABEL.approved_exe
            },
            source: "value"
          }
        ]
      }
    }
  };
}

function jaqlAppApproved(propertyId, timeRange) {
  return {
    sources: SOURCES,
    kpi: {
      datasource: DATA_SOURCE,
      metadata: {
        dimensions: [],
        filters: getPropertyJaqlFilters(propertyId, timeRange),
        measures: [
          {
            jaql: {
              type: "measure",
              formula: "sum([7A2C1-BFB])/SUM([D6B0E-5BB])",
              context: {
                "[44B4A-1AD]": {
                  table: "projects_period",
                  column: "lease_applications",
                  dim: "[projects_period.lease_applications]",
                  datatype: "numeric",
                  agg: "sum",
                  title: "Total lease_applications"
                },
                "[D6B0E-5BB]": {
                  table: "projects_period",
                  column: "lease_applications",
                  dim: "[projects_period.lease_applications]",
                  datatype: "numeric",
                  title: "lease_applications"
                },
                "[7A2C1-BFB]": {
                  table: "projects_period",
                  column: "approvals",
                  dim: "[projects_period.approvals]",
                  datatype: "numeric",
                  title: "Approvals"
                }
              },
              title: SISENSE_KPI_LABEL.app_approved
            },
            source: "value"
          }
        ]
      }
    }
  };
}

function jaqlLeasedRateGroup(propertiesIds, timeRange) {
  return {
    sources: SOURCES,
    kpi: {
      datasource: DATA_SOURCE,
      metadata: {
        dimensions: [],
        filters: getPropertiesJaqlFilter(propertiesIds, timeRange),
        measures: [
          {
            jaql: {
              context: {
                "[5C6C6-FB8]": {
                  table: "projects_period",
                  column: "leased_units_start",
                  dim: "[projects_period.leased_units_start]",
                  datatype: "numeric",
                  agg: "max",
                  title: "Max leased_units_start"
                },
                "[EDB33-B63]": {
                  table: "projects_property",
                  column: "total_units",
                  dim: "[projects_property.total_units]",
                  datatype: "numeric",
                  agg: "max",
                  title: "Max total_units"
                },
                "[01C07-405]": {
                  table: "projects_period",
                  column: "project_id",
                  dim: "[projects_period.project_id]",
                  datatype: "text",
                  merged: true,
                  title: "project_id"
                },
                "[A06AA-115]": {
                  table: "projects_period",
                  column: "DateRank",
                  dim: "[projects_period.DateRank]",
                  datatype: "numeric",
                  title: "DateRank",
                  filter: {
                    top: 1,
                    by: {
                      type: "measure",
                      formula: "[B9A3B-5AA]",
                      context: {
                        "[B9A3B-5AA]": {
                          table: "projects_period",
                          column: "DateRank",
                          dim: "[projects_period.DateRank]",
                          datatype: "numeric",
                          agg: "max",
                          title: "Max DateRank"
                        }
                      },
                      title: "[Max DateRank]"
                    }
                  }
                }
              },
              formula:
                "SUM( [01C07-405] , ([5C6C6-FB8] , [A06AA-115])    )/SUM( [01C07-405]  , ([EDB33-B63] , [A06AA-115])    )",
              title: SISENSE_KPI_LABEL.leased_rate,
              type: "measure"
            },
            source: "value"
          }
        ]
      }
    }
  };
}

function jaqlExposureRateGroup(propertiesIds, timeRange) {
  return {
    sources: SOURCES,
    kpi: {
      datasource: DATA_SOURCE,
      metadata: {
        dimensions: [],
        filters: getPropertiesJaqlFilter(propertiesIds, timeRange),
        measures: [
          {
            jaql: {
              type: "measure",
              formula:
                "IF(ISNULL([113ED-E23]), NULL,(SUM( [CD8D2-970] , ([9B70E-DC2] , [B1F29-2E0])    ))/SUM( [CD8D2-970]  , ([7C508-FE6], [B1F29-2E0])    ))",
              context: {
                "[7C508-FE6]": {
                  table: "projects_property",
                  column: "total_units",
                  dim: "[projects_property.total_units]",
                  datatype: "numeric",
                  agg: "max",
                  title: "Max total_units"
                },
                "[113ED-E23]": {
                  table: "projects_period",
                  column: "exposure",
                  dim: "[projects_period.exposure]",
                  datatype: "numeric",
                  agg: "sum",
                  title: "Total exposure"
                },
                "[9B70E-DC2]": {
                  table: "projects_period",
                  column: "exposure",
                  dim: "[projects_period.exposure]",
                  datatype: "numeric",
                  agg: "max",
                  title: "Max exposure"
                },
                "[CD8D2-970]": {
                  table: "projects_period",
                  column: "project_id",
                  dim: "[projects_period.project_id]",
                  datatype: "text",
                  merged: true,
                  title: "project_id"
                },
                "[B1F29-2E0]": {
                  table: "projects_period",
                  column: "DateRank",
                  dim: "[projects_period.DateRank]",
                  datatype: "numeric",
                  title: "DateRank",
                  filter: {
                    top: 1,
                    by: {
                      type: "measure",
                      formula: "[B9A3B-5AA]",
                      context: {
                        "[B9A3B-5AA]": {
                          table: "projects_period",
                          column: "DateRank",
                          dim: "[projects_period.DateRank]",
                          datatype: "numeric",
                          agg: "max",
                          title: "Max DateRank"
                        }
                      },
                      title: "[Max DateRank]"
                    }
                  }
                }
              },
              title: SISENSE_KPI_LABEL.exposure_rate
            }
          }
        ]
      }
    }
  };
}

function jaqlOccupiedRateGroup(propertiesIds, timeRange) {
  return {
    sources: SOURCES,
    kpi: {
      datasource: DATA_SOURCE,
      metadata: {
        dimensions: [],
        filters: getPropertiesJaqlFilter(propertiesIds, timeRange),
        measures: [
          {
            jaql: {
              context: {
                "[7CDB9-C28]": {
                  table: "projects_period",
                  column: "occupied_units_start",
                  dim: "[projects_period.occupied_units_start]",
                  datatype: "numeric",
                  agg: "max",
                  title: "Max occupied_units_start"
                },
                "[F8DC9-580]": {
                  table: "projects_property",
                  column: "total_units",
                  dim: "[projects_property.total_units]",
                  datatype: "numeric",
                  agg: "max",
                  title: "Max total_units"
                },
                "[6197B-172]": {
                  table: "projects_period",
                  column: "project_id",
                  dim: "[projects_period.project_id]",
                  datatype: "text",
                  merged: true,
                  title: "project_id"
                },
                "[B8AD3-143]": {
                  table: "projects_period",
                  column: "DateRank",
                  dim: "[projects_period.DateRank]",
                  datatype: "numeric",
                  title: "DateRank",
                  filter: {
                    top: 1,
                    by: {
                      type: "measure",
                      formula: "[42F6F-78B]",
                      context: {
                        "[42F6F-78B]": {
                          table: "projects_period",
                          column: "DateRank",
                          dim: "[projects_period.DateRank]",
                          datatype: "numeric",
                          agg: "max",
                          title: "Max DateRank"
                        }
                      },
                      title: "[Max DateRank]"
                    }
                  }
                }
              },
              formula:
                "SUM( [6197B-172]  , ([7CDB9-C28] , [B8AD3-143])    )/SUM( [6197B-172]  , ([F8DC9-580] , [B8AD3-143])    )",

              title: SISENSE_KPI_LABEL.occupied_rate,
              type: "measure"
            },
            source: "value"
          }
        ]
      }
    }
  };
}

function jaqlLeadsGroup(propertiesIds, timeRange) {
  return {
    sources: SOURCES,
    kpi: {
      datasource: DATA_SOURCE,
      metadata: {
        dimensions: [],
        filters: getPropertiesJaqlFilter(propertiesIds, timeRange),
        measures: [
          {
            jaql: {
              agg: "sum",
              column: "inquiries",
              datatype: "numeric",
              dim: "[projects_period.inquiries]",
              table: "projects_period",
              title: SISENSE_KPI_LABEL.leads
            },
            source: "value"
          }
        ]
      }
    }
  };
}

function jaqlToursGroup(propertiesIds, timeRange) {
  return {
    sources: SOURCES,
    kpi: {
      datasource: DATA_SOURCE,
      metadata: {
        dimensions: [],
        filters: getPropertiesJaqlFilter(propertiesIds, timeRange),
        measures: [
          {
            jaql: {
              agg: "sum",
              column: "tours",
              datatype: "numeric",
              dim: "[projects_period.tours]",
              table: "projects_period",
              title: SISENSE_KPI_LABEL.tours
            },
            source: "value"
          }
        ]
      }
    }
  };
}

function jaqlLeaseApplicationsGroup(propertiesIds, timeRange) {
  return {
    sources: SOURCES,
    kpi: {
      datasource: DATA_SOURCE,
      metadata: {
        dimensions: [],
        filters: getPropertiesJaqlFilter(propertiesIds, timeRange),
        measures: [
          {
            jaql: {
              agg: "sum",
              column: "lease_applications",
              datatype: "numeric",
              dim: "[projects_period.lease_applications]",
              table: "projects_period",
              title: SISENSE_KPI_LABEL.lease_applications
            },
            source: "value"
          }
        ]
      }
    }
  };
}

function jaqlApprovedGroup(propertiesIds, timeRange) {
  return {
    sources: SOURCES,
    kpi: {
      datasource: DATA_SOURCE,
      metadata: {
        dimensions: [],
        filters: getPropertiesJaqlFilter(propertiesIds, timeRange),
        measures: [
          {
            jaql: {
              agg: "sum",
              column: "approvals",
              datatype: "numeric",
              dim: "[projects_period.approvals]",
              table: "projects_period",
              title: SISENSE_KPI_LABEL.approved_lease
            },
            source: "value"
          }
        ]
      }
    }
  };
}

function jaqlLeasesExecutedGroup(propertiesIds, timeRange) {
  return {
    sources: SOURCES,
    kpi: {
      datasource: DATA_SOURCE,
      metadata: {
        dimensions: [],
        filters: getPropertiesJaqlFilter(propertiesIds, timeRange),
        measures: [
          {
            jaql: {
              agg: "sum",
              column: "leases_executed",
              datatype: "numeric",
              dim: "[projects_period.leases_executed]",
              table: "projects_period",
              title: SISENSE_KPI_LABEL.leases_executed
            },
            source: "value"
          }
        ]
      }
    }
  };
}

const PROPERTY_KPIS = {
  [SISENSE_KPI.leased_rate]: jaqlLeasedRate,
  [SISENSE_KPI.exposure_rate]: jaqlExposureRate,
  [SISENSE_KPI.occupied_rate]: jaqlOccupiedRate,
  [SISENSE_KPI.nwv]: jaqlNWV,
  [SISENSE_KPI.leads]: jaqlLeads,
  [SISENSE_KPI.tours]: jaqlTours,
  [SISENSE_KPI.lease_applications]: jaqlLeaseApplications,
  [SISENSE_KPI.leases_executed]: jaqlLeasesExecuted,
  [SISENSE_KPI.move_ins]: jaqlMoveIns,
  [SISENSE_KPI.nwv_leads]: jaqlNwvLeads,
  [SISENSE_KPI.leads_tou]: jaqlLeadsTou,
  [SISENSE_KPI.tou_app]: jaqlTouApp,
  [SISENSE_KPI.app_exe]: jaqlAppExe,
  [SISENSE_KPI.exe_ins]: jaqlExeIns,
  [SISENSE_KPI.lease_cds]: jaqlLeaseCDs,
  [SISENSE_KPI.vacation_notices]: jaqlVacationNotices,
  [SISENSE_KPI.renewals]: jaqlRenewals,
  [SISENSE_KPI.move_outs]: jaqlMoveOuts,
  [SISENSE_KPI.app_approved]: jaqlAppApproved,
  [SISENSE_KPI.approved_lease]: jaqlApproved,
  [SISENSE_KPI.approved_exe]: jaqlApprovedExe
};

const PROPERTY_GROUP_KPIS = {
  [SISENSE_KPI.leased_rate]: jaqlLeasedRateGroup,
  [SISENSE_KPI.occupied_rate]: jaqlOccupiedRateGroup,
  [SISENSE_KPI.exposure_rate]: jaqlExposureRateGroup,
  [SISENSE_KPI.leads]: jaqlLeadsGroup,
  [SISENSE_KPI.tours]: jaqlToursGroup,
  [SISENSE_KPI.lease_applications]: jaqlLeaseApplicationsGroup,
  [SISENSE_KPI.approved_lease]: jaqlApprovedGroup,
  [SISENSE_KPI.leases_executed]: jaqlLeasesExecutedGroup
};

export const getAlertJAQL = data => {
  const { type, kpi, timeRange, propertyId, propertiesIds } = data;
  let jaql;
  if (type === "group") {
    jaql = PROPERTY_GROUP_KPIS[kpi](propertiesIds, timeRange);
  } else {
    jaql = PROPERTY_KPIS[kpi](propertyId, timeRange);
  }
  return jaql;
};
