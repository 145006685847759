import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import PropertyPage from "../../components/property_page";
import {
  filters,
  propertyActions,
  propertyGroup
} from "../../redux_base/actions";
import checkFlag from "../../utils/posthog";
import { withLogin } from "../shared/login";
import { dateFormat } from "../../utils/dates";

const getTabs = () => {
  let tabs = [];
  if (checkFlag("channel-analysis")) {
    tabs = [
      "channel_analysis",
      "performance",
      "upper_funnel",
      "middle_funnel",
      "lower_funnel",
      "retention"
    ];
  } else {
    tabs = [
      "performance",
      "upper_funnel",
      "middle_funnel",
      "lower_funnel",
      "retention"
    ];
  }

  return tabs;
};

class PropertyPageContainer extends React.PureComponent {
  componentDidMount() {
    const { publicId, tabId } = this.props.match.params;
    const { date_selection } = this.props;
    const _tabs = getTabs();
    if (!tabId || !_tabs.includes(tabId)) {
      this.props.history.push(`/property/${publicId}/${_tabs[0]}`);
    }
    this.props.dispatch(
      propertyActions.requestNewWidgetData(publicId, date_selection)
    );
    this.props.dispatch(filters.update({ members: [publicId] }));
    this.props.dispatch(propertyActions.requestProperty(publicId));
    this.props.dispatch(propertyActions.getExpensesLastUpdated(publicId));
    this.props.dispatch(
      propertyActions.getChannelAnalysisKpis(
        publicId,
        dateFormat(date_selection.start_date, "yyyy-MM-dd"),
        dateFormat(date_selection.end_date, "yyyy-MM-dd")
      )
    );
    this.publicId = publicId;
    window.scrollTo(0, 0);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { publicId, tabId } = this.props.match.params;
    const { date_selection, dispatch } = this.props;
    const _tabs = getTabs();
    if (!tabId || !_tabs.includes(tabId)) {
      this.props.history.push(`/property/${publicId}/performance`);
    }
    // Only update if the selected property or date range has changed

    if (publicId !== this.publicId) {
      dispatch(propertyActions.getExpensesLastUpdated(publicId));
    }

    if (
      date_selection !== prevProps.date_selection ||
      publicId !== this.publicId
    ) {
      this.publicId = publicId;
      dispatch(propertyActions.requestProperty(publicId));
      dispatch(filters.update({ members: [publicId] }));
      this.props.dispatch(
        propertyActions.requestNewWidgetData(publicId, date_selection)
      );
      this.props.dispatch(
        propertyActions.getChannelAnalysisKpis(
          publicId,
          dateFormat(date_selection.start_date, "yyyy-MM-dd"),
          dateFormat(date_selection.end_date, "yyyy-MM-dd")
        )
      );
      window.scrollTo(0, 0);
    }
  }

  onChangeDateRange = (preset, startDate, endDate) => {
    let date_selection = {
      preset: preset,
      start_date: startDate,
      end_date: endDate
    };
    this.publicId &&
      this.props.dispatch(
        propertyActions.requestNewWidgetData(this.publicId, date_selection)
      );
    this.props.dispatch(
      propertyGroup.update({ date_selection: date_selection })
    );
  };

  onChangeProperty = option => {
    this.props.history.push(`/property/${option.value}/performance`);
  };

  onChangeTab = selectedIndex => {
    const { publicId } = this.props.match.params;
    const _tabs = getTabs();
    const tabId = _tabs[selectedIndex] || _tabs[0];
    this.props.history.push(`/property/${publicId}/${tabId}`);
    const { date_selection } = this.props;
    this.publicId &&
      this.props.dispatch(
        propertyActions.requestNewWidgetData(this.publicId, date_selection)
      );
  };

  openInvite = () => {
    this.props.dispatch(propertyActions.openInvite());
  };

  onCATabsToggle = () => {};

  onCAShowDistributedSource = () => {};

  render() {
    const { tabId } = this.props.match.params;
    const _tabs = getTabs();
    const selectedIndex = _tabs.findIndex(t => t === tabId);
    return (
      <PropertyPage
        {...this.props}
        tabId={tabId}
        selectedIndex={selectedIndex}
        onOpenInvite={this.openInvite}
        onChangeDateRange={this.onChangeDateRange}
        onChangeProperty={this.onChangeProperty}
        onChangeTab={this.onChangeTab}
        onCATabsToggle={this.onCATabsToggle}
        onCAShowDistributedSource={this.onCAShowDistributedSource}
      />
    );
  }
}

const mapState = state => ({
  ...state.network,
  ...state.propertyGroup,
  ...state.property,
  ...state.filters,
  ...state.user,
  ...state.newWidgetData,
  ...state.loaderState
});

export default withRouter(connect(mapState)(withLogin(PropertyPageContainer)));
