import React from "react";

import IconBase from "./icon_base";

const ok = props => (
  <IconBase viewBox="0 0 14 14" {...props}>
    <rect x="4" y="3" width="6" height="7" fill="white" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 7C0 3.13506 3.13506 0 7 0C10.8649 0 14 3.13506 14 7C14 10.8649 10.8649 14 7 14C3.13506 14 0 10.8649 0 7ZM9.94652 5.04456L6.87461 9.57471C6.78379 9.70919 6.63884 9.8 6.49564 9.8C6.35243 9.8 6.19526 9.72141 6.09397 9.62187C5.99268 9.52232 4.29169 7.81784 4.29169 7.81784C4.16944 7.69559 4.16944 7.49476 4.29169 7.37251L4.73702 6.92718C4.85926 6.80493 5.0601 6.80493 5.1806 6.92718L6.35418 8.09901L8.90392 4.33727C9.00172 4.19407 9.19906 4.15739 9.34226 4.25345L9.86444 4.60796C10.0059 4.70402 10.0443 4.90136 9.94652 5.04456Z"
      fill="currentColor"
    />
  </IconBase>
);

export default ok;
