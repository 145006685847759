import React from "react";
import PropTypes from "prop-types";

import DateRangeSelector from "../date_range_selector";
import Breadcrumbs from "../breadcrumbs";
import { Button, Icon, ValueLoader, Text } from "concrete-ui";
import _isEmpty from "lodash/isEmpty";
import Container from "../container";
import KpiWidget from "../kpi_graph";
import RenderIf from "../render_if";
import { KPI_HUMAN_LABEL, KPI_MATCHER, CHART_TYPE } from "../../constants";
import ApprovedLeases from "./approved_leases";
import Leads from "./leads";
import NewLeases from "./new_leases";
import NewWebsiteVisitors from "./nwv";
import LeaseApplications from "./lease_applications";
import Tours from "./tours";
import { formatTitleValue, getTitleValue } from "../property_page/widget-utils";
import "./property_kpi_page.scss";

class PropertyKPIPage extends React.PureComponent {
  static propTypes = {
    kpi: PropTypes.string.isRequired,
    startDate: PropTypes.instanceOf(Date).isRequired,
    endDate: PropTypes.instanceOf(Date).isRequired,
    datePreset: PropTypes.string.isRequired,
    onChangeDateRange: PropTypes.func.isRequired,
    property: PropTypes.shape({
      public_id: PropTypes.string.isRequired,
      group_id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      group_name: PropTypes.string.isRequired
    }),
    dataSource: PropTypes.string,
    kpiData: PropTypes.object.isRequired,
    widgetData: PropTypes.array,
    loaderState: PropTypes.bool
  };

  state = {
    isShowNarration: false
  };

  getTitle() {
    const { kpi } = this.props;
    return KPI_HUMAN_LABEL[kpi];
  }

  getHeaderTitle() {
    const { widgetData } = this.props;
    const kpi = KPI_MATCHER[this.props.kpi];

    if (
      this.props.loaderState ||
      typeof widgetData === "undefined" ||
      widgetData.length === 0
    ) {
      return <ValueLoader textSize={5} width="20rem" />;
    }
    const chartType = CHART_TYPE[this.props.kpi];
    const kpiValue = getTitleValue(kpi, widgetData);
    const formattedKpiValue = formatTitleValue(kpi, kpiValue);
    return (
      <Text textSize={5} fontWeight="medium">
        {this.getTitle()}
        {" \u2014 "}
        {formattedKpiValue}
        {chartType == "percentage" ? "%" : null}
      </Text>
    );
  }

  getNavLinks() {
    const { group_name, group_id, public_id, name } = this.props.property;
    return [
      { text: group_name, link: `/property-group/${group_id}/performance` },
      { text: name, link: `/property/${public_id}` },
      { text: this.getTitle() }
    ];
  }

  kpiComponents = {
    approved_leases: ApprovedLeases,
    leads: Leads,
    new_website_visitors: NewWebsiteVisitors,
    lease_applications: LeaseApplications,
    new_leases: NewLeases,
    tours: Tours
  };

  renderSpecificParts = () => {
    const Component = this.kpiComponents[this.props.kpi];
    if (
      Component &&
      !_isEmpty(this.props.kpiData) &&
      !_isEmpty(this.props.property)
    ) {
      return <Component {...this.props} />;
    }
    return null;
  };

  onToggleInsights = () => {
    this.setState({ isShowNarration: !this.state.isShowNarration });
  };

  render() {
    const { kpi, startDate, endDate, datePreset, dataSource } = this.props;
    return (
      <div className="property-kpi-page">
        <Container className="property-kpi-page__head">
          <div>
            <Breadcrumbs
              className="property-kpi-page__nav"
              breadcrumbs={this.getNavLinks()}
            />
            <div className="property-kpi-page__title">
              {this.getHeaderTitle()}
            </div>
          </div>
          <RenderIf condition={!!dataSource}>
            <div className="property-kpi-page__data-source">
              <div className="property-kpi-page__data-source-title">
                <>Data Source — </>
              </div>
              <div className="property-kpi-page__data-source-value">
                {dataSource}
              </div>
            </div>
          </RenderIf>
        </Container>
        <div className="property-kpi-page__divider" />
        <Container className="property-kpi-page__controls">
          <div>
            <Button
              variant={this.state.isShowNarration ? "primary" : "secondary"}
              selected={this.state.isShowNarration}
              size="small"
              onClick={this.onToggleInsights}
            >
              <Icon.Insight color="white.1" />
              Insights
            </Button>
          </div>
          <div>
            <DateRangeSelector
              startDate={startDate}
              endDate={endDate}
              presetValue={datePreset}
              theme="modern"
              dateFormat="MMM d"
              onChange={this.props.onChangeDateRange}
            />
          </div>
        </Container>
        <div className="property-kpi-page__divider" />
        <div className="property-kpi-page__background">
          <Container className="property-kpi-page__graph">
            <KpiWidget
              kpi={kpi}
              startDate={startDate}
              endDate={endDate}
              isShowNarration={this.state.isShowNarration}
              name={this.props.property.name}
              widgetData={this.props.widgetData}
              isFetching={this.props.loaderState}
            />
          </Container>
          {this.renderSpecificParts()}
        </div>
      </div>
    );
  }
}

export default PropertyKPIPage;
