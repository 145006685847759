import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Flex } from "concrete-ui";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import _find from "lodash/find";

import { withLogin } from "../shared/login";
import Loader from "../../components/loader";
import { pmsDataPropTypes } from "../admin_connections";
import AdminDataConnectionForm from "../../components/admin_data_connections_form";
import { connectionsActions, propertyActions } from "../../redux_base/actions";

const AdminDataConnectionFormContainer = ({
  match,
  history,
  dispatch,
  // reducer props
  isPostSuccessful,
  pmsDataLoading,
  isLoadingPropDetails,
  apiError,
  addNewConnectionError,
  // data
  pmsData,
  dataConnections,
  dataConnectionsLoading,
  propertiesDetails = []
}) => {
  const [isNewForm, setIsNewForm] = useState(true);
  useEffect(() => {
    if (!pmsData.length) {
      dispatch(connectionsActions.requestPMSInstances());
    }
    if (!dataConnections.length) {
      dispatch(connectionsActions.requestDataConnections());
    }
    if (!propertiesDetails.length) {
      dispatch(propertyActions.requestPropertiesDetails());
    }
  }, []);

  useEffect(() => {
    if (isPostSuccessful) {
      history.push("/admin-connections");
    }
  }, [isPostSuccessful]);

  const getInitialData = () => {
    const { integration_id } = match.params;
    if (integration_id !== undefined && dataConnections.length) {
      const paramsArray = integration_id.split("-");
      const integrationSource = paramsArray[0];
      const id = paramsArray[1];
      const response = _find(dataConnections, function(o) {
        return (
          o.id === parseInt(id) && o.integration_source === integrationSource
        );
      });
      return response;
    }
    return {};
  };

  // cancel
  const onCancel = () => history.push("/admin-connections/data_connections");

  // submit
  const onSubmit = data => {
    setIsNewForm(false);
    dispatch(connectionsActions.updateState({ dataConnectionsLoading: true }));
    dispatch(connectionsActions.postDataConnection(data, history));
  };

  return (
    <Flex flexDirection="column" pb={"12rem"}>
      <AdminDataConnectionForm
        initialData={getInitialData()}
        propertiesDetails={propertiesDetails}
        onConfirm={onSubmit}
        onCancel={onCancel}
        showError={apiError || (!isNewForm && addNewConnectionError)}
        pmsInstances={pmsData}
        dataConnections={dataConnections}
      />
      <Loader
        isVisible={
          pmsDataLoading || isLoadingPropDetails || dataConnectionsLoading
        }
      />
    </Flex>
  );
};

AdminDataConnectionFormContainer.propTypes = {
  pmsData: pmsDataPropTypes.isRequired,
  apiError: PropTypes.bool,
  pmsDataLoading: PropTypes.bool,
  isLoadingPropDetails: PropTypes.bool,
  dataConnections: PropTypes.array,
  dataConnectionsLoading: PropTypes.bool,
  addNewConnectionError: PropTypes.bool
};

const mapState = state => ({
  ...state.network,
  ...state.connections,
  ...state.property
});

export default withRouter(
  connect(mapState)(withLogin(AdminDataConnectionFormContainer))
);
