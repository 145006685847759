import React, { Component } from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import { Container } from "concrete-ui";

import EnhancedLink from "../enhanced_link";

import "./breadcrumbs.scss";

export class Breadcrumbs extends Component {
  static propTypes = {
    className: PropTypes.string,
    containerProps: PropTypes.object,
    breadcrumbs: PropTypes.arrayOf(
      PropTypes.shape({
        text: PropTypes.node,
        link: PropTypes.string
      })
    ).isRequired
  };

  render() {
    const { breadcrumbs, className, containerProps } = this.props;
    const classNames = cn("breadcrumbs", className);
    return (
      <div className={classNames}>
        <Container display="flex" alignItems="center" {...containerProps}>
          {breadcrumbs.map((item, index) => (
            <span className="breadcrumbs__item" key={index}>
              {item.link ? (
                <EnhancedLink
                  style={{ color: "inherit", textDecoration: "inherit" }}
                  to={item.link}
                >
                  {item.text}
                </EnhancedLink>
              ) : (
                item.text
              )}
            </span>
          ))}
        </Container>
      </div>
    );
  }
}

export default Breadcrumbs;
