import React from "react";
import { useHistory } from "react-router-dom";

import { PROPERTY_CHART_PROPS } from "../../constants";
import { _exploreHandler } from "../../utils/widget";
import Narratives from "../narratives";

import { PROPERTY_CHART_TEXT } from "./chart_text";
import { ChartHOC } from "./widget-utils";
import "./property_page.scss";

const category = "upper_funnel";

const exploreHandler = _exploreHandler(category);

const PropertyUpperFunnel = React.forwardRef((props, ref) => {
  const history = useHistory();
  const {
    date_selection,
    isShowInsights,
    propertyId,
    propertyName,
    newWidgetData,
    loaderState
  } = props;

  const exploreOnClick = exploreHandler(propertyId)(history);
  const { start_date, end_date } = date_selection;
  return (
    <div className="property__widgets-container" ref={ref}>
      <div className="property__title">Upper Funnel</div>
      <div className="property__widgets two_column">
        <div>
          <ChartHOC
            decimalPlaces={0}
            kpi="usvs"
            exploreArgs={["property", "nwv"]}
            newWidgetData={newWidgetData}
            loaderState={loaderState}
            exploreOnClick={exploreOnClick}
            chartLabel={PROPERTY_CHART_TEXT["usvs"]["chartLabel"]}
            titleTooltipDescription={PROPERTY_CHART_TEXT["usvs"]["tooltip"]}
            dateRange={{ start: start_date, end: end_date }}
            {...PROPERTY_CHART_PROPS["usvs"]}
          />
          <div className="widget__container">
            <Narratives
              kpiId="nwv"
              view="property"
              isShowWidget={isShowInsights}
              name={propertyName}
            />
          </div>
        </div>
        <div>
          <ChartHOC
            kpi="usv_inq"
            exploreArgs={["property", "nwv_leads"]}
            newWidgetData={newWidgetData}
            loaderState={loaderState}
            exploreOnClick={exploreOnClick}
            chartLabel={PROPERTY_CHART_TEXT["usv_inq"]["chartLabel"]}
            titleTooltipDescription={PROPERTY_CHART_TEXT["usv_inq"]["tooltip"]}
            dateRange={{ start: start_date, end: end_date }}
            {...PROPERTY_CHART_PROPS["usv_inq"]}
          />

          <div className="widget__container">
            <Narratives
              kpiId="nwv_leads"
              view="property"
              isShowWidget={isShowInsights}
              name={propertyName}
            />
          </div>
        </div>
      </div>
      <div className="property__widgets two_column">
        <div>
          <ChartHOC
            decimalPlaces={0}
            kpi="inquiries"
            exploreArgs={["property", "leads"]}
            newWidgetData={newWidgetData}
            loaderState={loaderState}
            exploreOnClick={exploreOnClick}
            chartLabel={PROPERTY_CHART_TEXT["inquiries"]["chartLabel"]}
            titleTooltipDescription={
              PROPERTY_CHART_TEXT["inquiries"]["tooltip"]
            }
            dateRange={{ start: start_date, end: end_date }}
            {...PROPERTY_CHART_PROPS["inquiries"]}
          />
          <div className="widget__container">
            <Narratives
              kpiId="leads"
              view="property"
              isShowWidget={isShowInsights}
              name={propertyName}
            />
          </div>
        </div>
        <div>
          <ChartHOC
            kpi="inq_tou"
            exploreArgs={["property", "leads_to_tours"]}
            newWidgetData={newWidgetData}
            loaderState={loaderState}
            exploreOnClick={exploreOnClick}
            chartLabel={PROPERTY_CHART_TEXT["inq_tou"]["chartLabel"]}
            titleTooltipDescription={PROPERTY_CHART_TEXT["inq_tou"]["tooltip"]}
            dateRange={{ start: start_date, end: end_date }}
            {...PROPERTY_CHART_PROPS["inq_tou"]}
          />
          <div className="widget__container">
            <Narratives
              kpiId="leads_to_tours"
              view="property"
              isShowWidget={isShowInsights}
              name={propertyName}
            />
          </div>
        </div>
      </div>
    </div>
  );
});

export default PropertyUpperFunnel;
