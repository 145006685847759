import ReactGa from "react-ga";
import _isObject from "lodash/isObject";
import _get from "lodash/get";
import _set from "lodash/set";
import {
  createPassword,
  uiStrings,
  accountSettings,
  auth,
  token as tokenActions,
  networking,
  propertyGroup,
  filters,
  alertsActions,
  user,
  createFEUrl
} from "../actions";
import {
  getPropertiesData,
  updateCompanyData,
  updateOfficeData,
  updateUserProfileData,
  updateReportsSettingsData
} from "../../api/account_settings";
import { URLS, createAPIUrl } from "../actions/helpers";
import { axiosDelete, axiosGet, axiosPatch, axiosPost } from "../../utils/api";
import { isExpired, formatDateToURLParam } from "../../utils/dates";
import { getPayloadFromJWT, qsStringify } from "../../utils/misc";
import { setCookie } from "../../utils/cookies";
import posthog from "posthog-js";
import checkFlag from "../../utils/posthog";

export const trackActions = _ => next => action => {
  const EXCLUDE_LIST = ["UPDATE_FILTERS", "UPDATE_SISENSE_APP"];
  if (!EXCLUDE_LIST.includes(action.type)) {
    if (posthog.__loaded && posthog?.capture) {
      const _action = { ...action };
      const { type } = action;
      delete _action.type;
    }
  } else if (action.type === "UPDATE_SISENSE_APP") {
  }
  next(action);
};

export const trackFilterChange = _ => next => action => {
  if (action.type === "UPDATE_FILTERS") {
    if (posthog?.capture && action.filters) {
    }
    next(action);
  } else {
    next(action);
  }
};
export const trackUser = _ => next => action => {
  if (action.type === "AJAX_GET_ACCOUNT_SETTINGS_SUCCESS") {
    const { user } = action.payload;
    const { user_id } = user;
    if (window.analytics?.identify) {
      window.analytics.identify(user_id);
    }
  }
  next(action);
};
// Here we create a middleware that intercepts
// actions representing a request for data from
// the api

function startFetchingState(store) {
  let x = store.getState();
  let { isFetching } = x.network;
  if (!isFetching || isFetching === false) {
    store.dispatch(networking.startFetching());
  }
}

export const fetchCreatePassword = store => next => action => {
  if (action.type === "API_CREATE_PASSWORD") {
    const hash = action.hash;
    const url = createAPIUrl(`/users/create-password/`);
    store.dispatch(networking.startFetching());
    if (action.data) {
      action.data.user_id = hash;
      axiosPost(url, action.data)
        .then(response => {
          store.dispatch(networking.stopFetching());
          if (response.status === 200) {
            const email = response.data.email;
            const password = action.data.password;
            const redirect_url = createFEUrl("/users/complete-account");
            next(auth.login({ email, password, redirect_url }));
          } else {
            throw response;
          }
        })
        .catch(e => {
          store.dispatch(networking.stopFetching());
          console.log("-----> ERROR", e);
        });
    } else {
      const url = createAPIUrl(`/create-password/`);
      axiosGet(url)
        .then(response => {
          store.dispatch(networking.stopFetching());
          next(createPassword.set(response.data));
        })
        .catch(e => {
          store.dispatch(networking.stopFetching());
          console.log("-----> ERROR", e);
        });
    }
  } else {
    next(action);
  }
};

export const fetchResetPassword = store => next => action => {
  if (action.type === "API_RESET_PASSWORD") {
    const url = createAPIUrl(`/users/reset-password-confirm/`);
    const successUrl = createFEUrl("/users/password-success/");
    if (action.data) {
      const { email, ...postParams } = action.data;
      axiosPost(url, postParams)
        .then(response => {
          if (response.status === 204) {
            window.location.replace(successUrl);
          } else if (response.status === 401) {
            const expiredUrl = createFEUrl(
              `/users/password-expired?email=${email}`
            );
            window.location.replace(expiredUrl);
          } else {
            throw response;
          }
        })
        .catch(e => console.log("-----> ERROR", e));
    }
  } else {
    next(action);
  }
};

export const fetchPasswordRules = store => next => action => {
  if (action.type == "CREATE_PASSWORD_FETCH_RULES") {
    axiosGet(createAPIUrl("/users/password-rules/"))
      .then(response => {
        const rules = response.data?.rules || [];
        next(createPassword.set({ rules }));
      })
      .catch(e => console.log("-----> ERROR", e));
  } else {
    next(action);
  }
};

export const sendGaEvent = _ => next => action => {
  switch (action.type) {
    case "GA_EVENT": {
      ReactGa.event(action.event);
      break;
    }
    default:
      next(action);
  }
};

export const startNetworkFetch = _ => next => action => {
  switch (action.type) {
    case "NETWORK_START_FETCH":
      switch (action.branch) {
        default:
          next(action);
          break;
      }
    default:
      next(action);
  }
};

export const logoutMiddleware = store => next => action => {
  if (action.type === "LOGOUT") {
    next(auth.destroySession());
  } else {
    next(action);
  }
};

export const fetchInviteModal = store => next => action => {
  if (action.type === "API_INVITE_RESEND") {
    const url = createAPIUrl(`/users/${action.hash}/resend-invite/`);
    axiosGet(url)
      .then(response => {
        if (response.status === 200) {
          action.callback(response.data);
        } else {
          throw response;
        }
      })
      .catch(e => console.log("-----> ERROR", e));
  } else {
    next(action);
  }
};

export const fetchUIString = store => next => action => {
  if (action.type === "API_UI_STRINGS") {
    const url = createAPIUrl(`/localization/${qsStringify(action.data)}`);
    axiosGet(url)
      .then(response => {
        if (response.status === 200) {
          next(uiStrings.set(response.data));
        } else if (response.status === 208) {
          next(action);
        } else {
          throw response;
        }
      })
      .catch(e => console.log("-----> ERROR", e));
  } else {
    next(action);
  }
};

export const updateAccountProfile = store => next => action => {
  const accountApiMap = {
    API_ACCOUNT_PROFILE_USER: updateUserProfileData,
    API_ACCOUNT_PROFILE_COMPANY: updateCompanyData,
    API_ACCOUNT_PROFILE_OFFICE: updateOfficeData
  };
  const callApi = accountApiMap[action.type];
  if (callApi) {
    if (action.data) {
      startFetchingState(store);
      callApi(action.data)
        .then(response => {
          if (response.status === 200 && !response.data?.errors) {
            action.callback(response.data);
          } else {
            throw response;
          }
        })
        .then(() => next(networking.stopFetching()))
        .catch(e => {
          if (e.response.data?.errors && _isObject(e.response.data?.errors)) {
            next(networking.stopFetching());
            action.onError(e.response.data.errors);
          } else {
            console.log("-----> ERROR", e);
          }
          next(networking.stopFetching());
        });
    }
  } else {
    next(action);
  }
};

export const updateReportsSettings = store => next => action => {
  if (action.type === "API_ACCOUNT_REPORTS") {
    if (action.data) {
      updateReportsSettingsData(action.data)
        .then(response => {
          if (response.status === 200) {
            action.callback(response.data);
          } else {
            throw response;
          }
        })
        .catch(e => console.log("-----> ERROR", e));
    }
  } else {
    next(action);
  }
};

export const fetchAccountProperties = store => next => action => {
  if (action.type === "API_ACCOUNT_REPORT_PROPERTIES") {
    getPropertiesData(action.data)
      .then(response => {
        if (response.status === 200) {
          next(accountSettings.set(response.data));
        } else {
          throw response;
        }
      })
      .catch(e => console.log("-----> ERROR", e));
  } else {
    next(action);
  }
};

let refresh_in_progress = false;
let paused_actions = [];
export const refreshToken = store => next => action => {
  if (action.type === "REFRESH_TOKEN") {
    const { token } = store.getState();
    const { refresh } = token;

    paused_actions.push(action.failedAction);
    if (refresh_in_progress) {
      return;
    }
    refresh_in_progress = true;

    axiosPost(action.url, { refresh })
      .then(response => {
        if (response.status === 401) {
          console.log("EXPIRED SESSION TOKENS, LOGGING OUT...");
          next(auth.clearToken());
          paused_actions = [];
        } else {
          refresh_in_progress = false;
          next(tokenActions.update({ refresh, access: response.data.access }));
          let action;
          while ((action = paused_actions.shift())) {
            next(action);
          }
        }
      })
      .catch(e => console.log("REFRESH TOKEN ERROR", e));
  } else {
    next(action);
  }
};

export const login = store => next => action => {
  if (action.type === "LOGIN") {
    const apiUrl = createAPIUrl(URLS.login);
    axiosPost(apiUrl, action.body)
      .then(response => {
        console.log("LOGIN RESPONSE");
        console.log(response.status);
        if (response.status === 401) {
          console.log("BAD LOGIN");
          next(auth.loginError());
        } else {
          next(
            tokenActions.update({
              refresh: response.data.refresh,
              access: response.data.access,
              rememberMe: action.body.rememberMe
            })
          );
          next(user.getUserProfile());

          setCookie("rmb-session", true);

          setTimeout(() => {
            console.log(`redirect url: ${action.redirect_url}`);
            if (action.redirect_url) {
              window.location.href = action.redirect_url;
            } else {
              window.location.reload();
            }
          });
        }
      })
      .catch(e => console.log("REFRESH TOKEN ERROR", e));
  } else if (action.type == "LOGIN_ERROR") {
    next(auth.clearToken());
  } else {
    next(action);
  }
};

export const deleteAlert = store => next => async action => {
  if (action.type === "DELETE_ALERT") {
    const { alertId } = action;
    try {
      const apiUrl = createAPIUrl(`${URLS.alerts}${alertId}`);
      const response = await axiosDelete(apiUrl);
      if (response.status === 200) {
        next({
          type: "DELETE_ALERT_SUCCESS",
          alertId: alertId
        });
      } else {
        next({
          type: "DELETE_ALERT_FAILURE"
        });
      }
    } catch (e) {
      console.log(e);
      next({
        type: "DELETE_ALERT_FAILURE"
      });
    }
  } else {
    next(action);
  }
};

export const updateAlertSubscriptionPreference = store => next => async action => {
  if (action.type === "ALERT_UPDATE_SUBSCRIPTION_PREFERENCE") {
    const { alertId, alert, emailOn, notificationOn } = action;
    try {
      const alertsUrl = createAPIUrl(`${URLS.alerts}${alertId}/subscribers/`);
      let params = {
        full_subscribers: alert["full_subscribers"],
        subscribers: alert["subscribers"],
        emailOn: emailOn,
        notificationOn: notificationOn
      };
      const response = await axiosPost(alertsUrl, params);
      if (response.status === 200) {
        const scope = alert["alert_scope"];
        next({
          type: "ALERT_UPDATE_SUBSCRIPTION_PREFERENCE_SUCCESS",
          alertId,
          scope,
          emailOn,
          notificationOn
        });
      } else {
        next({
          type: "ALERT_UPDATE_SUBSCRIPTION_PREFERENCE_FAILURE"
        });
      }
      console.log(response);
    } catch (e) {
      console.log(e);
      next({
        type: "ALERT_UPDATE_SUBSCRIPTION_PREFERENCE_FAILURE"
      });
    }
  }
  next(action);
};

export const markAlertsNotificationSeen = store => next => async action => {
  if (action.type === "ALERTS_NOTIFICATION_SEEN") {
    const { eventIds } = action;
    try {
      const apiUrl = createAPIUrl(`${URLS.alerts}notifications/`);
      const response = await axiosPatch(apiUrl, eventIds);
      if (response.status === 200) {
        next({
          type: "ALERTS_NOTIFICATION_SEEN_SUCCESS",
          eventIds
        });
      } else {
        next({
          type: "ALERTS_NOTIFICATION_SEEN_FAILURE"
        });
      }
    } catch (e) {
      console.log(e);
      next({
        type: "ALERTS_NOTIFICATION_SEEN_FAILURE"
      });
    }
  } else {
    next(action);
  }
};

export const fetchNewWidgetData = store => next => async action => {
  if (action.type === "GET_NEW_WIDGET_DATA_FOR_PROPERTY") {
    next({
      type: "NEW_WIDGET_DATA_FOR_PROPERTY_LOADER"
    });
    const { body } = action;
    const { start_date, end_date } = body.dateSelection;
    const { publicId } = body;
    const newApiUrl = createAPIUrl(URLS.widget_data);
    const _startDate = new Date(start_date);
    const _endDate = new Date(end_date);
    const timezoneOffset = _endDate.getTimezoneOffset();
    const limit = 30;
    const propertyID = publicId;
    const kpiList = [
      "app_exe",
      "app_approvals",
      "approvals",
      "approved_exe",
      "exe_ins",
      "exposure_rate",
      "inq_exe",
      "inq_tou",
      "inquiries",
      "lease_applications",
      "lease_cd_rate",
      "lease_cds",
      "lease_renewals",
      "lease_vacation_notices",
      "leased_rate",
      "leases_executed",
      "move_ins",
      "move_outs",
      "occupancy_rate",
      "tou_app",
      "tou_app_perc",
      "tours",
      "usv_inq",
      "usvs"
    ];
    let params = {
      property_ids: propertyID,
      kpis: kpiList.join(","),
      start: formatDateToURLParam(_startDate),
      end: formatDateToURLParam(_endDate),
      timezone: timezoneOffset,
      limit: limit,
      combined: false
    };

    try {
      const Response = await axiosGet(newApiUrl, {
        params: params
      });
      if (Response.status === 200) {
        next({
          type: "GET_NEW_WIDGET_DATA_FOR_PROPERTY_SUCCESS",
          data: Response.data
        });
      } else {
        next({
          type: "GET_NEW_WIDGET_DATA_FOR_PROPERTY_ERROR"
        });
      }
    } catch (e) {
      next({
        type: "GET_NEW_WIDGET_DATA_FOR_PROPERTY_ERROR"
      });
    }
  } else {
    next(action);
  }
};

export const cacheApiCalls = store => next => action => {
  let newAction = action;
  const cachedActions = process.env.CACHED_ACTIONS || [];
  if (cachedActions.includes(action.baseActionType)) {
    const cacheKey = JSON.stringify(action);
    const state = store.getState();
    const data = _get(state.cacheApiCalls, [cacheKey], {});
    const type = `${action.baseActionType}_SUCCESS`;
    let ttl = { hours: 1 };
    if (process.env.CACHED_TTL) {
      ttl = JSON.parse(process.env.CACHED_TTL);
    }
    if (data.payload && !isExpired(data.creationDate, ttl)) {
      newAction = {
        type,
        payload: data.payload,
        params: action.params
      };
    } else {
      _set(newAction, ["params", "cacheKey"], cacheKey);
    }
  }
  // response data caching
  const cacheKey = _get(action, ["params", "cacheKey"]);
  if (cacheKey && action.type.includes("_SUCCESS")) {
    next({
      type: "UPDATE_CACHE",
      cacheKey,
      payload: action.payload
    });
  }
  next(newAction);
};

const sisense_fail_actions = [];
export const waitSisenseDownloads = store => next => action => {
  if (action.type === "SISENSE_STORE_ACTION") {
    sisense_fail_actions.push(action.failedAction);
  } else if (action.type === "UPDATE_SISENSE_APP") {
    next(action);
    let fail_action;
    while ((fail_action = sisense_fail_actions.shift())) {
      next(fail_action);
    }
  } else {
    next(action);
  }
};
