import axios from "axios";

export function sisenseFetch(url, method, data) {
  const config = {
    method: method,
    withCredentials: true,
    url,
    data
  };
  return axios(config);
}
