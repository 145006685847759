import React from "react";
import _isEqual from "lodash/isEqual";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropertyGroupPage from "../../components/property_group_page";
import { propertyGroup } from "../../redux_base/actions";
import { withLogin } from "../shared/login";
import { formatDateToURLParam } from "../../utils/dates";

class PropertyGroupContainer extends React.PureComponent {
  componentDidMount() {
    const groupId = this.props.selected_property_group?.group_id;
    if (groupId) {
      this.requestGroups();
      this.requestTags(groupId);
    }
    this.updatePageLink();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const groupId = this.props.selected_property_group?.group_id;
    if (
      !_isEqual(prevProps.date_selection, this.props.date_selection) ||
      !_isEqual(prevProps.filters, this.props.filters) ||
      !_isEqual(prevProps.selected_property_group?.group_id, groupId)
    ) {
      this.requestGroups();
    }
    if (
      !!groupId &&
      !_isEqual(prevProps.selected_property_group?.group_id, groupId)
    ) {
      this.requestTags(groupId);
    }
    this.updatePageLink();
  }

  get loadingProperties() {
    return this.props.fetchingProperties || this.props.fetchingPropertyGroups;
  }

  get loadingWidget() {
    return (
      this.props.fetchingProperties ||
      this.props.fetchingPropertyGroups ||
      this.props.fetchingWidgetData
    );
  }

  updatePageLink = () => {
    if (
      this.props.selected_property_group?.group_id &&
      (!this.props.match.params.groupId || !this.props.match.params.tabId)
    ) {
      this.props.history.push(
        `/property-group/${this.props.selected_property_group.group_id}/performance`
      );
    }
  };

  onChangeDateRange = dateSelection => {
    this.props.dispatch(
      propertyGroup.update({ date_selection: dateSelection })
    );
  };

  onChangeFilters = filters => {
    this.props.dispatch(propertyGroup.update({ filters: filters }));
  };

  onChangeTab = tabId => {
    if (this.props.selected_property_group?.group_id) {
      this.props.history.push(
        `/property-group/${this.props.selected_property_group.group_id}/${tabId}`
      );
    } else {
      this.props.history.push(`/property-group/`);
    }
  };

  requestGroups = () => {
    const params = {
      group_id: this.props.selected_property_group.group_id,
      start: formatDateToURLParam(this.props.date_selection.start_date),
      end: formatDateToURLParam(this.props.date_selection.end_date),
      ...this.props.filters
    };
    this.props.dispatch(propertyGroup.requestGroups(params));
  };

  requestTags = groupId => {
    this.props.dispatch(propertyGroup.requestPortfolioTags(groupId));
  };

  render() {
    return (
      <PropertyGroupPage
        date_selection={this.props.date_selection}
        kpi_order_performance={this.props.kpi_order_performance}
        table_data={this.props.table_data}
        table_summary={this.props.table_summary}
        selected_property_group={this.props.selected_property_group}
        loadingProperties={this.loadingProperties}
        loadingWidget={this.loadingWidget}
        new_widget_data={this.props.new_widget_data}
        tabId={this.props.match.params.tabId}
        onChangeDateRange={this.onChangeDateRange}
        onChangeFilters={this.onChangeFilters}
        onChangeTab={this.onChangeTab}
        filters={this.props.filters}
        owners={this.props.owners}
        cities={this.props.cities}
        states={this.props.states}
        lease_stages={this.props.lease_stages}
        classes={this.props.classes}
        categories={this.props.categories}
        styles={this.props.styles}
        tags={this.props.tags}
      />
    );
  }
}

const mapState = state => ({
  ...state.network,
  ...state.propertyGroup,
  user: state.user,
  new_widget_data: state.properties.new_widget_data,
  fetchingWidgetData: state.properties.fetchingWidgetData
});

export default withRouter(connect(mapState)(withLogin(PropertyGroupContainer)));
