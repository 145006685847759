export const PMS_URL_TOOLTIP_TEXT =
  "URL should look something like this: https://www.iyardi.com/1234name/";

export const PMS_SERVER_NAME_TOOLTIP_TEXT =
  "Will be the same as the Database Name for Yardi-hosted clients on Voyager 7S.";

export const PROPERTY_DATA_CONNECTION_INTEGRATION_OPTIONS = [
  {
    value: "google_analytics",
    label: "Google Analytics"
  },
  {
    value: "yardi",
    label: "Yardi Voyager"
  }
];

export const GOOGLE_ANALYTICS_PROVIDER_OPTIONS = [
  {
    value: "google_analytics_ua",
    label: "Google Universal Analytics"
  },
  {
    value: "google_analytics_ga4",
    label: "Google Analytics 4"
  }
];

export const EDIT_PMS_MODAL_CONTENT = num =>
  `Are you sure you want to save changes to this PMS instance? Currently, ${num} properties are connected to this instance and will be updated immediately.`;

export const getPMSDeleteTooltipText = connectionsCount =>
  `This instance can’t be deleted at this time because it’s connected to ${connectionsCount} properties.`;

export const EDIT_CONNECTION_MODAL_CONTENT =
  "Are you sure you want to save changes to this connection and initiate a new connection test? Historical data from the previous, edited connection will remain unchanged.";

export const DATA_CONNECTION_TEST_SUCCESS =
  "After a successful connection test, data is automatically pulled in daily and historical backfilling is available via this button menu.";

export const DATA_CONNECTION_TEST_FAILED =
  "A successful connection test is required for data to be automatically pulled in daily, and for backfilling data. Try again via this button menu, then";

export const DATA_CONNECTION_TEST_PENDING =
  "A successful connection test is required for data to be automatically pulled in daily, and for backfilling data. Test the connection via this button menu.";

export const DATA_CONNECTION_BACKFILL_FAILED =
  "Try again via this button menu, then";

export const ZENDESK_HOW_TO_CONFIGURE_YARDI =
  "https://remarkablyhelp.zendesk.com/hc/en-us/articles/11250523820947-How-do-I-configure-Yardi-Voyager-so-Admins-can-add-data-connections-in-Remarkably-";

export const ZENDESK_HOW_TO_CONFIGURE_GA =
  "https://remarkablyhelp.zendesk.com/hc/en-us/articles/11251672795539-How-do-I-configure-Google-Analytics-so-Admins-can-add-data-connections-in-Remarkably-";

export const SINGLE_DOMAIN_MULTI_PROPERTIES_INFO =
  "This connection is for a single domain website with multiple properties and has an additional URL query configured. Questions or need changes made?";

export const DELETE_CONNECTION_MODAL_TEXT =
  "Are you sure you want to delete this connection? The connection has been used successfully to test, pull in, and/or backfill data for this property. Confirming will stop any data pulls from this source immediately.";

export const DELETE_CONNECTION_MODAL_GA_DATA_TEXT = [
  "Are you sure you want to delete this connection? It's successfully pulled in and/or backfilled data for this property. Also, matches and/or distributions are associated with this connection.",
  "Confirming will stop data pulls from this source immediately and may delete your saved matches and distributions. If possible, consider editing this connection instead."
];

export const DELETE_CONNECTION_MODAL_PMS_DATA_TEXT = [
  "Are you sure you want to delete this connection? It's successfully pulled in and/or backfilled data for this property. Also, expenses, matches or distributions are associated with this connection.",
  "Confirming will stop data pulls from this source immediately and may delete some or all of your associations. If possible, consider editing this connection instead."
];
