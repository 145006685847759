export const adminPropertiesConfig = {
  fields: {
    name: {
      validators: {
        isRequired: {
          message: "This field is required."
        }
      }
    },
    address_1: {
      validators: {
        isRequired: {
          message: "This field is required."
        }
      }
    },
    country: {
      validators: {
        isRequired: {
          message: "This field is required."
        }
      }
    },
    state: {
      validators: {
        isRequired: {
          message: "This field is required."
        }
      }
    },
    city: {
      validators: {
        isRequired: {
          message: "This field is required."
        }
      }
    },
    zip: {
      validators: {
        isRequired: {
          message: "This field is required."
        }
      }
    },
    billing_email: {
      validators: {
        isRequired: {
          message: "This field is required."
        },
        isEmail: {
          message: "Enter a correct email format."
        }
      }
    }
  }
};
