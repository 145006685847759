import PropTypes from "prop-types";
import React from "react";
import cn from "classnames";

import { getEndDatesByPreset, getStartDatesByPreset } from "../../utils/dates";
import DateRange from "../date_range";
import Select from "../select";

import "./date_range_selector.scss";

export default class DateRangeSelector extends React.PureComponent {
  static presets = [
    "custom",
    "last_week",
    "last_two_weeks",
    "month_to_date",
    "last_30_days",
    "last_month",
    "quarter_to_date",
    "last_quarter",
    "year_to_date",
    "last_year"
  ];

  static propTypes = {
    startDate: PropTypes.instanceOf(Date),
    endDate: PropTypes.instanceOf(Date),
    onChange: PropTypes.func,
    reportingDay: PropTypes.string,
    presetValue: PropTypes.oneOf(DateRangeSelector.presets),
    presets: PropTypes.arrayOf(PropTypes.oneOf(DateRangeSelector.presets)),
    dateFormat: PropTypes.string,
    minRange: PropTypes.number,
    theme: PropTypes.oneOf(["", "form-field", "modern"]),
    className: PropTypes.string
  };
  static defaultProps = {
    presetValue: "last_30_days",
    reportingDay: "M",
    theme: "",
    onChange: () => {}
  };

  static availableOptions = [
    { label: "Last Week", value: "last_week" },
    { label: "Last Two Weeks", value: "last_two_weeks" },
    { label: "Last 30 Days", value: "last_30_days" },
    { label: "This Month To Date", value: "month_to_date" },
    { label: "Last Month", value: "last_month" },
    { label: "This Quarter To Date", value: "quarter_to_date" },
    { label: "Last Quarter", value: "last_quarter" },
    { label: "This Year To Date", value: "year_to_date" },
    { label: "Last Year", value: "last_year" },
    { label: "Custom", value: "custom" }
  ];

  constructor(props) {
    super(props);
    this.dayPicker = React.createRef();
    this.state = { preset: props.presetValue };
  }

  componentDidUpdate(prevProps) {
    const { presetValue } = this.props;
    if (presetValue !== prevProps.presetValue) {
      this.setState({ preset: presetValue });
    }
  }

  get presetValue() {
    return DateRangeSelector.availableOptions.find(
      o => o.value === this.state.preset
    );
  }

  get selectThemeProps() {
    const { theme } = this.props;
    const props = { theme };
    if (theme === "modern") {
      props["theme"] = "transparent";
      props["size"] = "small";
    } else if (theme === "") {
      props["theme"] = "default";
    }
    return props;
  }

  getOptions() {
    const { presets } = this.props;
    if (!presets) {
      return DateRangeSelector.availableOptions;
    }
    const optionsMap = {};
    for (let item of DateRangeSelector.availableOptions) {
      optionsMap[item.value] = item;
    }
    return presets.map(p => optionsMap[p]);
  }

  onChangePreset = option => {
    const preset = option.value;
    const { reportingDay } = this.props;
    let startDate = getStartDatesByPreset(reportingDay)[preset];
    let endDate = getEndDatesByPreset(reportingDay)[preset];
    if (preset === "custom") {
      startDate = this.props.startDate;
      endDate = this.props.endDate;
      this.dayPicker.current.showDayPicker();
    }
    this.props.onChange(preset, startDate, endDate);
    this.setState({ preset });
  };

  onChangeDate = (startDate, endDate) => {
    const preset = "custom";
    this.setState({ preset });
    this.props.onChange(preset, startDate, endDate);
  };

  render() {
    let {
      startDate,
      endDate,
      reportingDay,
      dateFormat,
      theme,
      className
    } = this.props;
    const { preset } = this.state;
    if (!startDate) {
      startDate = getStartDatesByPreset(reportingDay)[preset];
    }
    if (!endDate) {
      endDate = getEndDatesByPreset(reportingDay)[preset];
    }
    const classes = cn(
      "date-range-selector",
      {
        [`date-range-selector--${theme}`]: theme
      },
      className
    );
    return (
      <div className={classes}>
        <Select
          className="date-range-selector__select"
          {...this.selectThemeProps}
          options={this.getOptions()}
          value={this.presetValue}
          onChange={this.onChangePreset}
        />
        <DateRange
          className="date-range-selector__data-picker"
          startDate={startDate}
          endDate={endDate}
          onChange={this.onChangeDate}
          ref={this.dayPicker}
          dateFormat={dateFormat}
          theme={theme}
          minRange={this.props.minRange}
        />
      </div>
    );
  }
}
