import { isValidHTTPURL } from "./misc";

const emailRegex = /\S+@\S+\.\S+/;
export const calendarDayRegex = /^0?[1-9]$|^[1-2][0-9]$|^3[0-1]$/;

export const validators = {
  isRequired: config => value =>
    !value && value !== 0 ? config.message : null,

  minLength: config => value =>
    value && config.value && value.length < config.value
      ? config.message
      : null,

  maxLength: config => value =>
    config.value && value && value.length > config.value
      ? config.message
      : null,

  regex: config => value =>
    (typeof value === "string" && value.match(config.value)) ||
    (config.excludeEmptyString && !value)
      ? null
      : config.message,

  moreThan: config => value =>
    value && value > config.value ? null : config.message,

  maxFileSize: config => value =>
    value && value.size > config.value ? config.message : null,

  isNumber: config => value => (!isNaN(value) ? null : config.message),

  isNumberWithDecimals: config => value => {
    const precisionRegex = config.precision ? `{0,${config.precision}}` : "+";
    const regex = `^(\\d+\\.\\d${precisionRegex}|\\d+|\\.\\d${precisionRegex})$`;
    return !isNaN(value) &&
      String(value)
        .trim()
        .match(new RegExp(regex))
      ? null
      : config.message;
  },

  isPositiveInteger: config => value =>
    !isNaN(value) && parseInt(value >= 0) ? null : config.message,

  condition: config => value => {
    const condValue = config.when();
    if (condValue && config.then) {
      return validateField(value, config.then);
    } else if (!condValue && config.else) {
      return validateField(value, config.else);
    }

    return null;
  },

  isEmail: config => value =>
    typeof value === "string" && value.match(emailRegex)
      ? null
      : config.message,

  isURL: config => value =>
    typeof value === "string" && isValidHTTPURL(value) ? null : config.message,

  oneOf: config => value =>
    config.value.indexOf(value) >= 0 ? null : config.message,

  customValidation: config => value => config.function(value)
};

export const validateField = (value, itemConfig) => {
  for (let validatorName in itemConfig) {
    const validatorConfig = itemConfig[validatorName];
    const validator = validators[validatorName];
    const configuredValidator = validator(validatorConfig);
    const errorMessage = configuredValidator(value);

    if (errorMessage) {
      return errorMessage;
    }
  }
  return null;
};

export const validateFields = (values, itemConfigs) => {
  const errors = {};
  for (let name in itemConfigs) {
    const itemConfig = itemConfigs[name].validators;
    if (!itemConfig) {
      continue;
    }
    const itemType = itemConfigs[name].type;
    const value = values[name];
    let errorMessage = null;
    switch (itemType) {
      case "object":
        for (let valueKey in itemConfig) {
          errorMessage = validateField(
            value?.[valueKey] || "",
            itemConfig[valueKey]
          );
          if (errorMessage) {
            break;
          }
        }
        break;
      case "array":
        for (let arrayValue of value || []) {
          errorMessage = validateField(arrayValue || "", itemConfig);
          if (errorMessage) {
            break;
          }
        }
        break;
      case "object_array":
        errorMessage = {};
        (value || []).forEach((arrayValue, index) => {
          let itemErrorMessage = {};
          for (let valueKey in itemConfig) {
            const errorString = validateField(
              arrayValue?.[valueKey] || "",
              itemConfig[valueKey]
            );
            if (errorString) {
              itemErrorMessage[valueKey] = errorString;
            }
          }
          if (Object.keys(itemErrorMessage).length) {
            errorMessage[index] = itemErrorMessage;
          }
        });
        if (!Object.keys(errorMessage).length) {
          errorMessage = null;
        }
        break;
      default:
        errorMessage = validateField(value, itemConfig);
    }
    if (errorMessage) {
      errors[name] = errorMessage;
    }
  }

  return errors;
};
