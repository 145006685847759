export const alertConfig = (
  timeRangeValues,
  anomalyTimeRangeValues,
  thresholdValues
) => formItems => ({
  propertyGroup: {
    validators: {
      condition: {
        when: () => formItems.propertyTabIndex === 0,
        then: {
          isRequired: {
            message: "Property group is required."
          }
        }
      }
    }
  },
  propertyGroupKPI: {
    validators: {
      condition: {
        when: () => formItems.propertyTabIndex === 0,
        then: {
          isRequired: {
            message: "Property group KPI is required."
          }
        }
      }
    }
  },
  property: {
    validators: {
      condition: {
        when: () => formItems.propertyTabIndex === 1,
        then: {
          isRequired: {
            message: "Property is required."
          }
        }
      }
    }
  },
  propertyKPI: {
    validators: {
      condition: {
        when: () => formItems.propertyTabIndex === 1,
        then: {
          isRequired: {
            message: "Property KPI is required."
          }
        }
      }
    }
  },
  timeRange: {
    validators: {
      condition: {
        when: () => formItems.alertTabIndex === 0,
        then: {
          oneOf: {
            value: timeRangeValues,
            message: "Invalid time range."
          }
        },
        else: {
          oneOf: {
            value: anomalyTimeRangeValues,
            message: "Invalid time range."
          }
        }
      },
      isRequired: {
        message: "Time range is required."
      }
    }
  },
  alertTabIndex: {
    validators: {
      isRequired: {
        message: "Alert tab index is required."
      },
      oneOf: {
        value: [0, 1],
        message: "Invalid alert tab index."
      }
    }
  },
  threshold: {
    validators: {
      condition: {
        when: () => formItems.alertTabIndex === 0,
        then: {
          oneOf: {
            value: thresholdValues,
            message: "Invalid threshold."
          },
          isRequired: {
            message: "Threshold is required."
          }
        }
      }
    }
  },
  thresholdValue: {
    validators: {
      condition: {
        when: () => formItems.alertTabIndex === 0,
        then: {
          isRequired: {
            message: "Threshold is required."
          }
        }
      }
    }
  },
  thresholdValue2: {
    validators: {
      condition: {
        when: () =>
          formItems.threshold === "between" && formItems.alertTabIndex === 0,
        then: {
          isRequired: {
            message: "Second threshold value is required."
          },
          moreThan: {
            value: formItems.thresholdValue,
            message: "Second threshold value must be bigger than first."
          }
        }
      }
    }
  },
  users: {
    type: "array"
  }
});
