import React from "react";
import PropTypes from "prop-types";
import { Box, Container, Divider, Flex, Link, Text } from "concrete-ui";

import checkFlag from "../../utils/posthog";
import LeadSourcesColumn from "../referral_sources_lead_sources";
import ReferralSourcesNWV from "../referral_sources_nwv";
import ReferralSourcesProperties from "../referral_sources_properties";
import { MATCHING_NO_PROPS_AVAILABLE } from "../referral_sources/tooltips";
import {
  HEADERS,
  SELECT_MATCH_NWV,
  SELECT_MATCH_LEAD_SOURCE
} from "../referral_sources_tab/const";
import ReferralSourcesNWV2 from "../referral_sources_nwv2";
import { TabViews } from "../referral_sources/constants";
import { ZENDESK_HOW_TO_MATCH_REFERRAL_SOURCES } from "../../constants";

class ReferralSourcesTab extends React.PureComponent {
  static propTypes = {
    portfolios: ReferralSourcesProperties.portfoliosType,
    selectedPortfolio: PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string
    }),
    properties: ReferralSourcesProperties.propertiesType,
    fetchingProperties: PropTypes.bool,
    unmatchedSources: LeadSourcesColumn.leadSourcesType,
    matchedSources: LeadSourcesColumn.leadSourcesType,
    fetchingLeadSources: PropTypes.bool,
    fetchingLeadSourcesOptions: PropTypes.bool,
    nwv: ReferralSourcesNWV.nwvType,
    fetchingNWVs: PropTypes.bool,
    unbouncedVisitorsOnly: PropTypes.bool,
    saveCompleted: PropTypes.bool,
    leadSourcesAttribution: PropTypes.object,
    fetchProperties: PropTypes.func,
    selectProperty: PropTypes.func,
    selectLeadSource: PropTypes.func,
    clearPropertyState: PropTypes.func,
    onSave: PropTypes.func,
    onSelectFilter: PropTypes.func,
    distSourceSelectionOptions: PropTypes.instanceOf(Set)
  };

  static defaultProps = {
    portfolios: [],
    selectedPortfolio: null,
    properties: [],
    fetchingProperties: false,
    nwv: [],
    leadSourcesAttribution: {},
    unbouncedVisitorsOnly: false,
    distSourceSelectionOptions: new Set([]),
    fetchingLeadSources: false,
    fetchingLeadSourcesOptions: false,
    saveCompleted: false,
    fetchProperties: () => {},
    selectProperty: () => {},
    selectLeadSource: () => {},
    clearPropertyState: () => {},
    onSave: () => {}
  };

  static initialState = {
    selectedProperty: null,
    selectedLeadSource: null
  };

  state = ReferralSourcesTab.initialState;

  componentWillUnmount() {
    this.props.clearPropertyState();
  }

  onChangePortfolio = portfolio => {
    this.setState(
      { selectedPortfolio: portfolio, ...ReferralSourcesTab.initialState },
      () => {
        this.props.fetchProperties(this.state.selectedPortfolio);
      }
    );
  };

  onChangeProperty = property => {
    this.setState({ selectedProperty: property }, () => {
      this.props.selectProperty(this.state.selectedProperty);
    });
  };

  onSelectLeadSource = selectedLeadSource => {
    this.setState({ selectedLeadSource }, () => {
      this.props.selectLeadSource(
        this.state.selectedProperty,
        this.state.selectedLeadSource
      );
    });
  };

  onSaveHandler = ({ sources, unbouncedVisitorsOnly }) => {
    this.props.onSave(
      this.state.selectedProperty,
      this.state.selectedLeadSource,
      sources,
      unbouncedVisitorsOnly
    );
  };

  isSelectedDistributionLead = () =>
    this.props.distSourceSelectionOptions.has(this.state.selectedLeadSource);

  render() {
    const selectedLeadSource = this.state.selectedLeadSource;
    return (
      <>
        <Box flexDirection="column">
          <Container>
            <Flex py={6} maxWidth={600}>
              <Text textSize={2} $fontWeight="regular" $color="text.gray.2">
                Match Lead Sources with New Website Visitor referral sources,
                sources, where applicable, to view New Website Visitors by
                Source in Channel Analysis.{"  "}
                <Link
                  $color="text.gray.1"
                  variant="underline"
                  target="_blank"
                  href={ZENDESK_HOW_TO_MATCH_REFERRAL_SOURCES}
                  ml={1}
                >
                  Learn More.
                </Link>
              </Text>
            </Flex>
          </Container>
          <Divider variant="horizontal" bg="gray.4" flexShrink={0} />
        </Box>
        <Box flexGrow={1} minHeight={0}>
          <ReferralSourcesProperties
            portfolios={this.props.portfolios}
            selectedPortfolio={this.props.selectedPortfolio}
            properties={this.props.properties}
            fetchingProperties={this.props.fetchingProperties}
            selectedProperty={this.state.selectedProperty}
            onChangePortfolio={this.onChangePortfolio}
            onChangeProperty={this.onChangeProperty}
            noPropertiesAvailableMessage={MATCHING_NO_PROPS_AVAILABLE}
            tab={"matching"}
          />
          <Divider variant="vertical" bg="gray.4" flexShrink={0} />
          <LeadSourcesColumn
            unmatchedSources={this.props.unmatchedSources}
            matchedSources={this.props.matchedSources}
            fetchingLeadSources={this.props.fetchingLeadSources}
            fetchingLeadSourcesOptions={this.props.fetchingLeadSourcesOptions}
            onSelect={this.onSelectLeadSource}
            selectedProperty={this.state.selectedProperty}
            distSourceSelectionOptions={this.props.distSourceSelectionOptions}
          />
          <Divider variant="vertical" bg="gray.4" flexShrink={0} />
          {checkFlag("matching-new-filter-sort") ? (
            <ReferralSourcesNWV2
              sources={this.props.nwv}
              match={true}
              fetchingNWVs={this.props.fetchingNWVs}
              headers={HEADERS}
              nwvTitle={SELECT_MATCH_NWV}
              leadSourceTitle={SELECT_MATCH_LEAD_SOURCE}
              isSelectLeadSource={!!selectedLeadSource}
              selectedOnTop={
                !!this.props.leadSourcesAttribution[selectedLeadSource]?.matched
              }
              unbouncedVisitorsOnly={this.props.unbouncedVisitorsOnly}
              saveCompleted={this.props.saveCompleted}
              onSave={this.onSaveHandler}
              onSelectFilter={this.props.onSelectFilter}
              tabView={TabViews.Match}
              isSelectedDistributionLead={this.isSelectedDistributionLead()}
            />
          ) : (
            <ReferralSourcesNWV
              sources={this.props.nwv}
              headers={HEADERS}
              isSelectLeadSource={!!selectedLeadSource}
              matchedOnTop={
                !!this.props.leadSourcesAttribution[selectedLeadSource]?.matched
              }
              unbouncedVisitorsOnly={this.props.unbouncedVisitorsOnly}
              saveCompleted={this.props.saveCompleted}
              onSave={this.onSaveHandler}
            />
          )}
        </Box>
      </>
    );
  }
}

export default ReferralSourcesTab;
