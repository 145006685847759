const initialState = {
  sub_portfolio: {
    size: 10,
    options: [
      { label: "10", value: 10 },
      { label: "20", value: 20 },
      { label: "All", value: "all" }
    ]
  },
  alerts: {
    size: 10,
    options: [
      { label: "10", value: 10 },
      { label: "20", value: 20 },
      { label: "All", value: "all" }
    ]
  },
  kpi: {
    size: 5,
    options: [
      { label: "5", value: 5 },
      { label: "10", value: 10 },
      { label: "20", value: 20 },
      { label: "All", value: "all" }
    ]
  }
};

export default (state = initialState, action) => {
  let newState = {};
  switch (action.type) {
    case "SET_PAGINATION_SIZE": {
      newState = {
        ...state
      };
      newState[action.paginationType].size = action.paginationSize;
      break;
    }
    default:
      return state;
  }
  return newState;
};
