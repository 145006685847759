export const PROPERTY_ATTRIBUTES_TAB_TOOLTIP =
  "Attributes enable users to filter and report on properties in specific ways.";

export const PROPERTY_ATTRIBUTES_AVAILABLE_COUNTRIES = [
  {
    value: "CA",
    label: "Canada"
  },
  {
    value: "US",
    label: "United States"
  }
];
