export const referralSourcesTooltips = {
  leadSources:
    "Selection options shown reflect property-specific, all-time Lead sources from Yardi Voyager that have been associated with expenses.",
  nwv: {
    unbouncedVisitorsOnly:
      "When selected, only New Website Visitors who did not immediately bounce after landing on your website will be included in report metrics."
  }
};

export const MATCHING_NO_PROPS_AVAILABLE =
  "There are no properties available for matching.";
export const DISTRIBUTION_NO_PROPS_AVAILABLE =
  "There are no properties available for distribution.";
export const MATCHING_NWV_ALREADY_DISTRIBUTED =
  "This website referral source is not available for matching because it has already been distributed to.";
export const MATCHING_NWV_ALREADY_MATCHED =
  "This website referral source is not available for matching because it has already been matched.";
export const DISTRIBUTION_NWV_ALREADY_MATCHED =
  "This website referral source is not available for distribution because it has already been matched.";
export const DISTRIBUTION_NWV_ALREADY_DISTRIBUTED =
  "This website referral source is not available for distribution because it has already been distributed.";

export const sharedAnalyticsProviderMessage = tab => {
  let word = "matched" ? tab === "matching" : "distributed";
  return `This property shares a Google Analytics ID with other properties and can’t be ${word} separately.`;
};
export const noAnalyticsConnectionMessage = tab => {
  return `Please connect this property to Google Analytics to enable ${tab}. `;
};
export const noPMSConnectionMessage = tab => {
  return `Please connect this property to Yardi Voyager to enable ${tab}. `;
};
export const noAnalyticsOrPMSConnectionMessage = tab => {
  return `Please connect this property to Yardi Voyager and Google Analytics to enable ${tab}. `;
};
