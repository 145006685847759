import React from "react";
import { Box, Text } from "concrete-ui";

import Container from "../container";
import {
  formatDateString,
  formatPercent,
  valueOr
} from "../../utils/formatters";
import DynamicBoxes from "./dynamic_boxes";
import PaginationTable from "../pagination_table";
import {
  csvPercentageFormat,
  getCsvFilename,
  structurePropertyKpiData
} from "./utils";
import { nullDashDisplay } from "../../constants";

function LeaseApplications(props) {
  const { kpiData } = props;
  const {
    error,
    flag,
    estimated_lease_applications,
    applications_to_leases,
    estimated_days,
    avg_lease_applications,
    estimated_date,
    exposure_offset
  } = kpiData;
  let exposureOffset = exposure_offset || 0;
  exposureOffset = Math.round(exposureOffset);
  const estimatedLeaseApplications = valueOr(
    estimated_lease_applications,
    nullDashDisplay
  );
  const applicationsToLeases = valueOr(
    applications_to_leases,
    "\u2011%",
    formatPercent
  );
  const estimatedDays = valueOr(estimated_days, nullDashDisplay);
  const avgLeaseApplications = valueOr(avg_lease_applications, "-");
  const date = valueOr(
    estimated_date,
    nullDashDisplay,
    formatDateString,
    "MMMM dd, yyyy"
  );
  const boxes = [
    {
      header: `Estimated Lease Applications to Achieve ${0 +
        exposureOffset}% Exposure`,
      value: estimatedLeaseApplications,
      footer: `Based on Average Lease\u00A0Applications\u00A0→\u00A0New\u00A0Leases (${applicationsToLeases})`
    },
    {
      header: `Estimated Days to Achieve ${0 + exposureOffset}% Exposure`,
      value: estimatedDays,
      footer: `Based on Average Lease Applications Per Day (${avgLeaseApplications})`
    },
    {
      header: `Estimated Date of ${0 + exposureOffset}% Exposure`,
      value: date,
      footer: "Based on Average Lease Applications Performance"
    }
  ];
  const columns = [
    {
      Header: "Source",
      accessor: "source_name"
    },
    {
      Header: "Lease Applications",
      accessor: "count"
    },
    {
      Header: "Percentage of Total",
      accessor: "percentage",
      csvFormatter: csvPercentageFormat,
      ValueComponent: ({ data, textProps }) => (
        <Box alignItems="center">
          <Text {...textProps}>
            {valueOr(data.percentage, "\u2011%", formatPercent)}
          </Text>
        </Box>
      )
    }
  ];

  return (
    <div>
      <Container>
        <div className="property-group__table--middle kpi-table">
          <PaginationTable
            data={structurePropertyKpiData(kpiData.table_data) || []}
            columns={columns}
            className="property-group-table"
            tableType="kpi"
            csvFileName={getCsvFilename(
              props.property.name,
              props.kpi,
              props.startDate,
              props.endDate,
              "Referral_Sources"
            )}
          />
        </div>
      </Container>
      <DynamicBoxes error={error} boxes={boxes} flag={flag} />
    </div>
  );
}

export default LeaseApplications;
