import React from "react";
import { withRouter } from "react-router-dom";
import "./property_selector.scss";
import Select from "../select";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { propertyGroup } from "../../redux_base/actions";
import _isEmpty from "lodash/isEmpty";
import _get from "lodash/get";

class PropertySelector extends React.PureComponent {
  static propTypes = {
    property_groups: PropTypes.arrayOf(
      PropTypes.shape({
        group_id: PropTypes.string.isRequired,
        group_name: PropTypes.string.isRequired,
        group_company: PropTypes.string
      })
    ),
    selected_property_group: PropTypes.object
  };

  static defaultProps = {
    property_groups: [],
    selected_property_group: {}
  };

  constructor(props) {
    super(props);
    if (_isEmpty(this.props.property_groups)) {
      this.props.dispatch(propertyGroup.requestGroupsList());
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (_isEmpty(this.props.selected_property_group)) {
      this.updateSelectedGroup();
    }
  }

  get propertyGroupViewOptions() {
    let response = [];
    this.props.property_groups.map(group => {
      let group_entry = {
        label: group.group_name,
        value: group.group_id
      };
      response.push(group_entry);
    });
    return response;
  }

  changePropertyView = event => {
    const selectedGroup = this.props.property_groups.find(
      group => group.group_id === event.value
    );
    if (selectedGroup) {
      this.props.dispatch(
        propertyGroup.update({
          selected_property_group: selectedGroup
        })
      );
      this.props.history.push(`/property-group/${event.value}/performance`);
    }
  };

  updateSelectedGroup = () => {
    const pathname = window.location.pathname;
    let groupId = _get(pathname.match(/\/property-group\/(.*)\/(.*)$/), 1);
    if (
      !groupId &&
      this.props.property_groups &&
      this.props.property_groups.length > 0
    ) {
      groupId = this.props.property_groups[0].group_id;
    }
    const selectedGroup = this.props.property_groups.find(
      group => group.group_id === groupId
    );
    if (selectedGroup) {
      this.props.dispatch(
        propertyGroup.update({
          selected_property_group: selectedGroup
        })
      );
    }
  };

  get selectValue() {
    if (!_isEmpty(this.props.selected_property_group)) {
      return {
        label: this.props.selected_property_group.group_name,
        value: this.props.selected_property_group.value
      };
    }
    return null;
  }

  render() {
    return (
      <Select
        size={"small"}
        className={"property-selector"}
        options={this.propertyGroupViewOptions}
        onChange={this.changePropertyView}
        value={this.selectValue}
        placeholder={"Select Property Group..."}
      />
    );
  }
}

const mapState = state => ({
  selected_property_group: state.propertyGroup?.selected_property_group,
  property_groups: state.propertyGroup?.property_groups
});

export default withRouter(connect(mapState)(PropertySelector));
