import React from "react";
import { connect } from "react-redux";
import checkFlag from "../../utils/posthog";
import Tag from "../../components/tag";
import { propertyActions } from "../../redux_base/actions";
import AddTagField from "../add_tag_field";

class Tags extends React.Component {
  componentDidMount() {
    const { public_id } = this.props.property;
    this.props.dispatch(propertyActions.getTags(public_id));
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { public_id } = this.props.property;
    if (public_id !== prevProps.property.public_id) {
      this.props.dispatch(propertyActions.getTags(public_id));
    }
  }

  onRemoveHandler = tagId => {
    const { public_id } = this.props.property;
    this.props.dispatch(propertyActions.removeTag(public_id, tagId));
  };

  render() {
    const { custom_tags, isAdmin } = this.props;
    const tags = custom_tags.map(t => (
      <Tag
        name={t.tag_name}
        tagId={t.tag_id}
        isAdmin={isAdmin}
        onRemove={this.onRemoveHandler}
        key={t.tag_id}
      />
    ));
    if (isAdmin) {
      tags.push(<AddTagField key="add-tag-field" />);
    }
    if (!tags.length) {
      return null;
    }
    return (
      <div className="property-details__row property-details__tags tags">
        <div className="tags__label">Custom Tags</div>
        <div className="tags__items">{tags}</div>
      </div>
    );
  }
}

const mapState = state => ({
  ...state.property
});

export default connect(mapState)(Tags);
