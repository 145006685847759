import React, { useEffect, useMemo } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";

import { withLogin } from "../shared/login";
import { propertyActions, reportBudgetActions } from "../../redux_base/actions";
import CreateBudgetForm from "../../components/create_budget_form";

const CreateBudgetFormContainer = ({
  dispatch,
  properties,
  propertyKPIsIsLoading,
  user,
  isLoading,
  success
}) => {
  useEffect(() => {
    if (!propertyKPIsIsLoading) {
      dispatch(propertyActions.requestProperties());
    }
  }, []);

  const mapPropertySelect = ({ property_id, property_name }) => ({
    value: property_id,
    label: property_name
  });
  const propertyList = useMemo(
    () => Object.values(properties).map(mapPropertySelect),
    [properties]
  );

  const sendReport = formData => {
    const data = {
      ...formData
    };
    dispatch(reportBudgetActions.createBudgetReport(data));
  };

  return (
    <CreateBudgetForm
      userEmail={user.email}
      properties={propertyList}
      confirm={sendReport}
      isLoading={isLoading}
      success={success}
    />
  );
};

const mapState = state => ({
  properties: state.properties.items,
  propertyKPIsIsLoading: state.properties.propertyKPIsIsLoading,
  user: state.user,
  isLoading: state.reportBudget.isLoading,
  success: state.reportBudget.success
});

export default withRouter(
  connect(mapState)(withLogin(CreateBudgetFormContainer))
);
