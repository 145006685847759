const initialState = {
  fetchingProject: true,
  fetchingReports: true,
  project: false,
  reports: false,
  isAddTagInput: false,
  suggestedTags: []
};

const reducer = (state = initialState, action) => {
  let p, project, members;
  switch (action.type) {
    case "AJAX_GET_PROJECT_OVERALL_REQUEST":
      return initialState;
    case "AJAX_GET_PROJECT_OVERALL_SUCCESS":
      return {
        ...state,
        fetchingProject: false,
        project: action.payload
      };
    case "AJAX_GET_PROJECT_OVERALL_FAILURE":
      return {
        ...state,
        fetchingProject: false
      };
    case "AJAX_GET_PROJECT_REPORTS_REQUEST":
      return {
        ...state,
        fetchingReports: true
      };
    case "AJAX_GET_PROJECT_REPORTS_SUCCESS":
      return {
        ...state,
        fetchingReports: false,
        reports: action.payload
      };
    case "STOP_FETCHING_PROJECT_REPORTS":
    case "AJAX_GET_PROJECT_REPORTS_FAILURE":
      return {
        ...state,
        fetchingReports: false
      };
    case "AJAX_POST_CREATE_TAG_SUCCESS": {
      const { property_group_tags } = action.payload;
      project = { ...state.project, property_group_tags };
      return { ...state, project, isAddTagInput: false };
    }
    case "AJAX_POST_REMOVE_TAG_FROM_PROJECT_SUCCESS": {
      const { property_group_tags } = action.payload;
      project = { ...state.project, property_group_tags };
      return { ...state, project };
    }
    default:
      return state;
  }
};

export default reducer;
