import { COUNTRY_CODE_REGEX } from "../../constants";

export const MAX_AVATAR_SIZE = 3 * 1024 * 1024; // Bytes in 3MB

const usPhoneRegex = /^\([0-9]{3}\)\s[0-9]{3}-[0-9]{4}$/;
const genericPhoneRegex = /^(?=.*[0-9])[- +()0-9]{8,15}$/;
const invalidPhoneMessage = "Phone should match format (XXX) XXX-XXXX";
export const passwordsNotMatchMessage = "New passwords don’t match.";

const securityConfig = formItems => ({
  fields: {
    old_password: {
      validators: {
        condition: {
          when: () => !!formItems.password,
          then: {
            isRequired: {
              message: "Old password is required."
            }
          }
        }
      }
    },
    confirm_password: {
      validators: {
        condition: {
          when: () => !!formItems.password,
          then: {
            isRequired: {
              message: "Confirm password is required."
            },
            oneOf: {
              value: [formItems.password, null],
              message: passwordsNotMatchMessage
            }
          }
        }
      }
    }
  }
});

const userConfig = formItems => ({
  fields: {
    first_name: {
      validators: {
        maxLength: {
          value: 255,
          message: "First name is too long."
        },
        isRequired: {
          message: "First name is required."
        }
      }
    },
    last_name: {
      validators: {
        maxLength: {
          value: 255,
          message: "Last name is too much length"
        },
        isRequired: {
          message: "Last name is required."
        }
      }
    }
  }
});

export { userConfig, securityConfig };
