import { URLS } from "./helpers";

export * from "./helpers";
export { default as accountSettings } from "./account_settings";
export { default as analyticsActions } from "./analytics";
export { default as narrativesActions } from "./narratives";
export { default as expenseActions } from "./expenses";
export { default as dashboard } from "./dashboard";
export { default as notifications } from "./notifications";
export { default as portfolio } from "./portfolio";
export { default as propertyGroup } from "./property_group";
export { default as alertsActions } from "./alerts";
export { default as projectActions } from "./project";
export { default as projectReports } from "./project_reports";
export { default as inviteModal } from "./invite_modal";
export { default as viewMembersModal } from "./view_members";
export { default as user } from "./user";
export { default as paginationSettings } from "./pagination_settings";
export { companyActions } from "./crm";
export { default as propertyActions } from "./property";
export { default as referralSourcesActions } from "./referral_sources";
export { default as posthogActions } from "./posthog";
export { default as reportActions } from "./report";
export { default as reportBudgetActions } from "./report_budget";
export { default as banners } from "./banners";
export { default as connectionsActions } from "./connections";
export { default as resetPasswordActions } from "./reset_password";

export const addressModal = {
  open: (data, addresses) => ({
    type: "ADDRESS_MODAL_SHOW",
    data,
    addresses
  }),
  close: {
    type: "ADDRESS_MODAL_HIDE"
  }
};

export const networking = {
  startFetching: branch => ({
    type: "NETWORK_START_FETCH",
    branch
  }),
  stopFetching: () => ({
    type: "NETWORK_STOP_FETCH"
  }),
  fail: message => ({
    type: "NETWORK_FETCH_FAIL",
    message
  }),
  success: () => ({
    type: "NETWORK_FETCH_SUCCESS"
  })
};

export const createPassword = {
  set: newState => ({
    type: "CREATE_PASSWORD_SET_STATE",
    newState
  }),
  redirect: url => ({
    type: "CREATE_PASSWORD_REDIRECT",
    url
  }),
  getRules: newState => ({
    type: "CREATE_PASSWORD_FETCH_RULES",
    newState
  }),
  setPassword: newState => ({
    type: "API_CREATE_PASSWORD",
    ...newState
  }),
  resetPassword: newState => ({
    type: "API_RESET_PASSWORD",
    data: newState
  })
};

export const resendSetPasswordEmail = {
  set: newState => ({
    type: "SEND_PASSWORD_RESET_EMAIL",
    data: newState
  })
};

export const token = {
  update: x => ({
    type: "UPDATE_TOKEN",
    token: x
  }),
  refresh: failedAction => ({
    type: "REFRESH_TOKEN",
    url: `${URLS.base}${URLS.ver}${URLS.refresh}`,
    failedAction
  })
};

export const event = {
  ga: x => ({
    type: "GA_EVENT",
    event: x
  })
};

export const pageMeta = {
  title: title => ({
    type: "UPDATE_PAGE_TITLE",
    title
  })
};

// api actions...

export const auth = {
  login: ({ email, password, rememberMe, redirect_url }) => ({
    type: "LOGIN",
    body: { email, password, rememberMe },
    redirect_url: redirect_url
  }),
  logout: () => ({
    type: "LOGOUT"
  }),
  persistToken: token => ({
    type: "UPDATE_TOKEN",
    token
  }),
  destroySession: () => ({
    type: "DESTROY_SESSION"
  }),
  loginError: () => ({
    type: "LOGIN_ERROR"
  })
};

export const createResendEmail = {
  set: ({ resendEmail, redirect_url }) => ({
    type: "SEND_EMAIL_SET_STATE",
    newState: resendEmail,
    url: redirect_url
  })
};

export const uiStrings = {
  fetch: (version, language) => ({
    type: "API_UI_STRINGS",
    data: { version, language }
  }),
  set: data => ({
    type: "UI_STRINGS_SET_STATE",
    data
  })
};

export const sisense = {
  update: app => ({ type: "UPDATE_SISENSE_APP", app }),
  storeAction: failedAction => ({ type: "SISENSE_STORE_ACTION", failedAction })
};

export const filters = {
  update: filters => ({ type: "UPDATE_FILTERS", filters })
};
