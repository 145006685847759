import React from "react";
import PropTypes from "prop-types";

import "./narratives.scss";

class WidgetNarrationLoader extends React.PureComponent {
  static propTypes = {
    size: PropTypes.oneOf(["small", "regular"]),
    isVisible: PropTypes.bool
  };

  static defaultProps = {
    size: "small",
    isVisible: false
  };

  static loaderSize = {
    small: LoaderSmall,
    regular: LoaderRegular
  };
  render() {
    if (this.props.isVisible) {
      const Loader = WidgetNarrationLoader.loaderSize[this.props.size];
      return <Loader props />;
    } else {
      return null;
    }
  }
}

function LoaderSmall() {
  return (
    <svg
      width="437"
      height="176"
      viewBox="0 0 437 176"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="narratives-loader"
    >
      <rect width="437" height="176" fill="currentColor" />
      <defs>
        <mask id="narratives-loader__mask-element--narration-small">
          <rect x="32" y="20" width="290" height="16" rx="2" fill="#798796" />
          <rect x="32" y="92" width="290" height="16" rx="2" fill="#798796" />
          <rect x="16" y="68" width="174" height="16" rx="2" fill="#798796" />
          <rect x="32" y="44" width="371" height="16" rx="2" fill="#798796" />
          <circle cx="20" cy="28" r="4" fill="#798796" />
          <circle cx="20" cy="100" r="4" fill="#798796" />
          <circle cx="20" cy="52" r="4" fill="#798796" />

          <path
            fill="hsla(210,12%,83%,.7)"
            className="narratives-loader__loader-mask"
            d="M89 0V240.5H0L61.5 0H89Z"
          />
        </mask>
      </defs>

      <rect
        mask="url(#narratives-loader__mask-element--narration-small)"
        x="32"
        y="20"
        width="290"
        height="16"
        rx="2"
        fill="#798796"
      />
      <rect
        mask="url(#narratives-loader__mask-element--narration-small)"
        x="32"
        y="92"
        width="290"
        height="16"
        rx="2"
        fill="#798796"
      />
      <rect
        mask="url(#narratives-loader__mask-element--narration-small)"
        x="16"
        y="68"
        width="174"
        height="16"
        rx="2"
        fill="#798796"
      />
      <rect
        mask="url(#narratives-loader__mask-element--narration-small)"
        x="32"
        y="44"
        width="371"
        height="16"
        rx="2"
        fill="#798796"
      />
      <circle
        mask="url(#narratives-loader__mask-element--narration-small)"
        cx="20"
        cy="28"
        r="4"
        fill="#798796"
      />
      <circle
        mask="url(#narratives-loader__mask-element--narration-small)"
        cx="20"
        cy="100"
        r="4"
        fill="#798796"
      />
      <circle
        mask="url(#narratives-loader__mask-element--narration-small)"
        cx="20"
        cy="52"
        r="4"
        fill="#798796"
      />
    </svg>
  );
}

function LoaderRegular() {
  return (
    <svg
      width="672"
      height="104"
      viewBox="0 0 672 104"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="narratives-loader"
    >
      <rect width="672" height="104" fill="currentColor" />
      <defs>
        <mask id="narratives-loader__mask-element--narration-regular">
          <circle cx="20" cy="28" r="4" fill="#798796" />
          <circle cx="20" cy="52" r="4" fill="#798796" />
          <circle cx="20" cy="76" r="4" fill="#798796" />
          <rect x="32" y="20" width="290" height="16" rx="2" fill="#798796" />
          <rect x="32" y="68" width="174" height="16" rx="2" fill="#798796" />
          <rect x="32" y="44" width="371" height="16" rx="2" fill="#798796" />

          <path
            fill="hsla(210,12%,83%,.7)"
            className="narratives-loader__loader-mask"
            d="M89 0V240.5H0L61.5 0H89Z"
          />
        </mask>
      </defs>
      <circle
        mask="url(#narratives-loader__mask-element--narration-regular)"
        cx="20"
        cy="28"
        r="4"
        fill="#798796"
      />
      <circle
        mask="url(#narratives-loader__mask-element--narration-regular)"
        cx="20"
        cy="52"
        r="4"
        fill="#798796"
      />
      <circle
        mask="url(#narratives-loader__mask-element--narration-regular)"
        cx="20"
        cy="76"
        r="4"
        fill="#798796"
      />
      <rect
        mask="url(#narratives-loader__mask-element--narration-regular)"
        x="32"
        y="20"
        width="290"
        height="16"
        rx="2"
        fill="#798796"
      />
      <rect
        mask="url(#narratives-loader__mask-element--narration-regular)"
        x="32"
        y="68"
        width="174"
        height="16"
        rx="2"
        fill="#798796"
      />
      <rect
        mask="url(#narratives-loader__mask-element--narration-regular)"
        x="32"
        y="44"
        width="371"
        height="16"
        rx="2"
        fill="#798796"
      />
    </svg>
  );
}

export default WidgetNarrationLoader;
