export const PORTFOLIO_CHART_TEXT = {
  occupancy_rate: {
    chartLabel:
      "The most recent portfolio occupancy rate in date range selected, charted daily.",
    tooltip:
      "Sum of Occupied portfolio units (including those On Notice), divided by the sum of portfolio units. Based on property data ingested daily via our Yardi Voyager integration."
  },
  leased_rate: {
    chartLabel:
      "The most recent portfolio leased rate in date range selected, charted daily.",
    tooltip:
      "Sum of Occupied portfolio units (including those On Notice) plus the sum of Vacant Rented units, divided by the sum of portfolio units. Based on property data ingested daily via our Yardi Voyager integration."
  },
  exposure_rate: {
    chartLabel:
      "The most recent portfolio exposure rate in date range selected, charted daily.",
    tooltip:
      "Sum of Vacant Unrented portfolio units (including Ready and Not Ready) plus the sum of Occupied portfolio Notice Unrented units, divided by the sum of portfolio units. Based on property data ingested daily via our Yardi Voyager integration."
  },
  inq_exe: {
    chartLabel:
      "The portfolio conversion rate over the date range selected, charted weekly."
  },
  lease_cd_rate: {
    chartLabel:
      "The portfolio Cancellation & Denial rate over the date range selected, charted weekly."
  },
  usvs: {
    chartLabel:
      "The sum of portfolio New Website Visitor traffic generated in date range selected, charted daily.",
    tooltip:
      "Based on property website 'New Users' data ingested daily via our Google Analytics integration."
  },
  inquiries: {
    chartLabel:
      "The sum of portfolio Leads generated in date range selected, charted daily.",
    tooltip:
      "Based on property ILS/Guest Card data ingested daily via our Yardi Voyager integration."
  },
  tours: {
    chartLabel:
      "The sum of portfolio Tours completed in date range selected, charted daily.",
    tooltip:
      "Based on property ILS/Guest Card data ingested daily via our Yardi Voyager integration."
  },
  lease_applications: {
    chartLabel:
      "The sum of portfolio Lease Applications received in date range selected, charted daily.",
    tooltip:
      "Based on property ILS/Guest Card data ingested daily via our Yardi Voyager integration."
  },
  approvals: {
    chartLabel:
      "The sum of portfolio Approved Leases generated in date range selected, charted daily.",
    tooltip:
      "Based on property ILS/Guest Card data ingested daily via our Yardi Voyager integration."
  },
  leases_executed: {
    chartLabel:
      "The sum of portfolio New Leases secured in date range selected, charted daily.",
    tooltip:
      "Based on property ILS/Guest Card data ingested daily via our Yardi Voyager integration."
  }
};
