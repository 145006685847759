import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

import { resetPasswordActions } from "../../../redux_base/actions";
import ResetPasswordExpired from "../../../components/reset_password_expired";

const ExpiredPasswordContainer = ({
  dispatch,
  history,
  isPostSuccessful,
  location
}) => {
  const searchParams = new URLSearchParams(location.search);
  if (!searchParams.has("email")) {
    history.push("/");
  }

  const email = searchParams.get("email");

  useEffect(() => {
    if (isPostSuccessful) {
      dispatch(resetPasswordActions.updateState({ isPostSuccessful: false }));
      history.push("/users/password-resend");
    }
  }, [isPostSuccessful]);

  const resendResetPassword = () => {
    dispatch(
      resetPasswordActions.resetPassword({
        email: email
      })
    );
  };

  return <ResetPasswordExpired resendResetPassword={resendResetPassword} />;
};

ExpiredPasswordContainer.propTypes = {
  isPostSuccessful: PropTypes.bool
};

const mapState = state => ({
  isPostSuccessful: state.resetPassword.isPostSuccessful
});

export default withRouter(connect(mapState)(ExpiredPasswordContainer));
