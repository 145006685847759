import React from "react";
import {
  Flex,
  TextInput,
  Icon,
  DateRangeSelector,
  RadioGroup
} from "concrete-ui";
import { ActualExpenseList } from "../actual-expense-list";
import { contractTermsOpts, paidEventTypeOpts } from "../options";
import {
  dateToDatePicker,
  dateToValidation,
  inputNumberFormatter,
  inputStringFormatter,
  inputTextWithTooltip
} from "../utils";
import { calculateCostBudgeted } from "../../expenses/utils";
import { concreteErrorVariant } from "../../../constants";
import { expenseTooltips } from "../tooltips";

export const PayForPerformanceExpense = ({
  formItems,
  getItemProps,
  errors
}) => {
  const costBudgetedProps = getItemProps("cost_budgeted", {
    formatInputToValidator: inputNumberFormatter,
    formatValidatorToInput: inputStringFormatter
  });
  const budgetedEventProps = getItemProps("budgeted_events_number", {
    formatInputToValidator: inputNumberFormatter,
    formatValidatorToInput: inputStringFormatter
  });
  const suggestedExpenseValue = calculateCostBudgeted(formItems);
  const budgetedEventsTotal = inputTextWithTooltip(
    `Budgeted Total Per Month: $${suggestedExpenseValue}`,
    expenseTooltips["budgetedExpense"]["pay_for_performance"][
      "budgetedTotalPerMonth"
    ]
  );
  const paidEventTypeProps = getItemProps("paid_event_type");
  const contractTermsProps = getItemProps("terms");
  const startDateProps = getItemProps("start_date", {
    formatInputToValidator: dateToValidation,
    formatValidatorToInput: dateToDatePicker
  });
  const endDateProps = getItemProps("end_date", {
    formatInputToValidator: dateToValidation,
    formatValidatorToInput: dateToDatePicker
  });
  const actualExpenseProps = getItemProps("actual_expenses");

  return (
    <>
      <RadioGroup
        title="Paid Event Type"
        options={paidEventTypeOpts}
        onChange={value => () => paidEventTypeProps.onChange(value)}
        activeRadioId={paidEventTypeProps.value}
        itemsProps={{ flexDirection: "column" }}
        errorMessage={errors.paid_event_type}
      />
      <TextInput
        mt={5}
        size="large"
        iconLeft={<Icon.Dollar />}
        label={inputTextWithTooltip(
          "Budgeted Expense Per Event",
          expenseTooltips["budgetedExpense"]["pay_for_performance"][
            "budgetedExpensePerEvent"
          ]
        )}
        {...costBudgetedProps}
        variant={errors.cost_budgeted && concreteErrorVariant}
        message={errors.cost_budgeted}
      />
      <TextInput
        mt={5}
        size="large"
        label={inputTextWithTooltip(
          "Budgeted Number Of Events Per Month",
          expenseTooltips["budgetedExpense"]["pay_for_performance"][
            "budgetedNumberOfEventsPerMonth"
          ]
        )}
        help={budgetedEventsTotal}
        {...budgetedEventProps}
        variant={errors.budgeted_events_number && concreteErrorVariant}
        message={errors.budgeted_events_number}
      />
      <DateRangeSelector
        startOptions={{
          initialDate: startDateProps.value,
          onDateSelect: startDateProps.onChange,
          label: "Contract Start Date",
          startOrEnd: "start",
          ml: -3,
          errorMessage: errors.start_date
        }}
        endOptions={{
          initialDate: endDateProps.value,
          onDateSelect: endDateProps.onChange,
          label: "Contract End Date",
          startOrEnd: "end",
          mr: -3,
          errorMessage: errors.end_date
        }}
        mb={6}
        mt={5}
      />
      <RadioGroup
        mt={4}
        mb={6}
        title="Contract Terms"
        options={contractTermsOpts}
        onChange={value => () => contractTermsProps.onChange(value)}
        activeRadioId={contractTermsProps.value}
        itemsProps={{ flexDirection: "column" }}
        errorMessage={errors.terms}
      />
      <ActualExpenseList
        onChange={actualExpenseProps.onChange}
        formItems={formItems}
        suggestedExpenseValue={suggestedExpenseValue}
        errors={errors}
      />
    </>
  );
};
