import PropTypes from "prop-types";
import React from "react";

import { Close } from "../../icons";
import { Button } from "concrete-ui";
import Collapsible from "../collapsible";
import ModalWindow from "../modal_window";
import Select, { SelectSearch } from "../select";
import {
  InputWithPlaceholder,
  MenuWithDescription,
  MultiValueComponents,
  OptionWithDescription
} from "../select/select_components";
import UserRow, { UserRowPending } from "../user_row";
import UserIconList from "../user_icon_list";
import RenderIf from "../render_if";

import OptionUsers from "./option";
import SelectRole from "./select";
import "./members_modal.scss";
import _isEmpty from "lodash/isEmpty";
import { SUPPORT_EMAIL } from "../../constants";
import { Tick, Error, Error2 } from "../../icons";
import MembersConfirmModal from "../../components/members_confirm_modal";

export default class InviteModal extends React.PureComponent {
  static propTypes = {
    isOpen: PropTypes.bool,
    properties: PropTypes.arrayOf(
      PropTypes.shape({
        public_id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        members: PropTypes.array.isRequired
      })
    ).isRequired,
    membersSearch: PropTypes.array,
    isRemoveMemberOpen: PropTypes.bool,
    isChangeRoleOpen: PropTypes.bool,
    isMembersLoading: PropTypes.bool,
    onClose: PropTypes.func,
    onLoadUsers: PropTypes.func,
    onChangeRole: PropTypes.func,
    onRemoveMember: PropTypes.func,
    onRemoveProperty: PropTypes.func,
    onInviteMembers: PropTypes.func,
    onOpenRemove: PropTypes.func,
    onCloseRemove: PropTypes.func,
    onOpenChangeRole: PropTypes.func,
    onCloseChangeRole: PropTypes.func
  };

  static defaultProps = {
    isOpen: false,
    isRemoveMemberOpen: false,
    isChangeRoleOpen: false,
    isMembersLoading: false,
    membersSearch: [],
    onChangeRole() {},
    onOpenRemove() {},
    onInviteMembers() {},
    onRemoveProperty() {},
    onClose() {}
  };

  static roleOptions = [
    {
      label: "Admin",
      description:
        "Users can manage tags, expenses and sources and invite users.",
      value: "admin"
    },
    {
      label: "Member",
      description:
        "Users can sort, filter, and download content and create reports.",
      value: "member"
    }
  ];

  static initRole = InviteModal.roleOptions[1];

  static selectRoleComponents = {
    Menu: MenuWithDescription,
    Option: OptionWithDescription
  };

  static selectUsersComponents = {
    ...MultiValueComponents,
    Option: OptionUsers,
    Input: InputWithPlaceholder,
    Placeholder: () => null,
    IndicatorsContainer: () => null
  };

  static searchStyle = {
    noOptionsMessage: () => {}
  };

  constructor(props) {
    super(props);
    this.selectSearchRef = React.createRef();
    this.state = {
      selectedMembers: [],
      inviteRole: InviteModal.initRole,
      remove: {},
      changeRole: {}
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!this.props.isOpen && prevProps.isOpen) {
      this.setState({ inviteRole: InviteModal.initRole, selectedMembers: [] });
    }
  }

  get inviteIsDisabled() {
    return !this.state.selectedMembers.length;
  }

  removeProperty = e => {
    e.stopPropagation();
    const propertyId = e.target.dataset?.propertyId;
    const selectedProperties = this.props.properties.filter(
      p => p.public_id !== propertyId
    );
    this.props.onRemoveProperty(selectedProperties);
  };

  openModal = () => {
    this.selectSearchRef.current.focus();
  };

  selectMember = members => {
    this.setState({
      selectedMembers: members || []
    });
  };

  formatOptionLabel = data => data.account_name || data.value;

  noOptionsMessage = () => (
    <div className="invite-modal__user-not-found">User not found</div>
  );

  renderTitle = () => {
    let propertyName;
    if (this.props.properties.length === 1) {
      propertyName = this.props.properties[0].name;
    } else {
      propertyName = `${this.props.properties.length} properties`;
    }
    return (
      <div className="invite-modal__title">Invite to&nbsp;{propertyName}</div>
    );
  };

  isOptionDisabled = (option, member, adminCount) => {
    return (
      adminCount === 1 && option.value === "member" && member.role === "admin"
    );
  };

  openRemove = (property, member) => {
    this.setState({ remove: { property, member } });
    this.props.onOpenRemove();
  };

  openChangeRole = (role, property, member) => {
    this.setState({ changeRole: { role, property, member } });
    this.props.onOpenChangeRole();
  };

  renderMembers = property => {
    if (!property || !property.members) {
      return null;
    }
    const adminCount = property.members.filter(m => m.role === "admin").length;
    return property.members.map(member => {
      const role = InviteModal.roleOptions.find(r => r.value === member.role);
      const roleOptions = InviteModal.roleOptions.map(option => {
        const isDisabled = false;
        return {
          ...option,
          isDisabled
        };
      });
      const { is_active, ...memberData } = member;
      return (
        <div className="invite-modal__member" key={member.user_id}>
          <UserRow
            className="invite-modal__user-row invite-modal__user-row--large"
            {...memberData}
          />
          {/* Commented out until we find a way to distinguish inactive vs pending */}
          {/*<RenderIf condition={!is_active}>*/}
          {/*  <UserRowPending*/}
          {/*    className="invite-modal__user-row invite-modal__user-row--large"*/}
          {/*    {...memberData}*/}
          {/*  />*/}
          {/*</RenderIf>*/}
          <SelectRole
            member={memberData}
            property={property}
            role={role}
            adminCount={adminCount}
            components={InviteModal.selectRoleComponents}
            roleOptions={roleOptions}
            openRemoveModal={this.openRemove}
            openChangeRoleModal={this.openChangeRole}
          />
        </div>
      );
    });
  };

  renderProperty = () => {
    return this.props.properties.map(property => (
      <Collapsible
        className="invite-modal__collapsible"
        isOpen={false}
        renderChild={true}
        trigger={this.renderPropertyRow(property)}
        key={property.public_id}
      >
        <div className="invite-modal__collapsible-members">
          {this.renderMembers(property)}
        </div>
      </Collapsible>
    ));
  };

  renderPropertyRow = property => {
    const numberMembers = property.members?.length;
    return (
      <div className="invite-modal__trigger">
        <div className="invite-modal__collapsible-container">
          <Collapsible.Icon className="invite-modal__collapsible-icon" />
          <div>
            <div className="invite-modal__property-name">{property.name}</div>
            <div className="invite-modal__users-count">
              {numberMembers} {numberMembers !== 1 ? "Users" : "User"}
            </div>
          </div>
        </div>
        <div className="invite-modal__collapsible-container">
          <UserIconList users={property.members} isAdmin={false} />
          <Close
            className="invite-modal__collapsible-close"
            data-property-id={property.public_id}
            onClick={this.removeProperty}
          />
        </div>
      </div>
    );
  };

  renderPropertyOrMembers = () => {
    if (this.props.properties.length === 1) {
      return this.renderMembers(this.props.properties[0]);
    } else {
      return this.renderProperty();
    }
  };

  renderInviteMessages = () => {
    let rows = [];
    if (this.props.inviteCount === 1) {
      rows.push(
        <div
          className="invite-modal__container--messages--sent"
          key="successfulInvite"
        >
          <span className="invite-modal__tick">
            <Tick />
          </span>
          <span>{this.props.inviteCount} invite was sent successfully.</span>
        </div>
      );
    } else if (this.props.inviteCount > 1) {
      rows.push(
        <div
          className="invite-modal__container--messages--sent"
          key="successfulInvite"
        >
          <span className="invite-modal__tick">
            <Tick />
          </span>
          <span>{this.props.inviteCount} invites were sent successfully.</span>
        </div>
      );
    }
    if (!_isEmpty(this.props.inviteErrorMessages)) {
      this.props.inviteErrorMessages.map((message, index) => {
        rows.push(
          <div className="invite-modal__container--messages--error" key={index}>
            <span className="invite-modal__tick">
              <Error2 />
            </span>
            <span>{message}</span>
          </div>
        );
      });
      if (this.props.inviteRequestError) {
        rows.push(
          <div
            className="invite-modal__container--messages--error"
            key="inviteErrors"
          >
            Please contact{" "}
            <a href={`mailto:${SUPPORT_EMAIL}`}>Remarkably Support</a> for
            further assistance.
          </div>
        );
      }
    }
    return <div className="invite-modal__container--messages">{rows}</div>;
  };

  changeInviteRoleHandler = roleOption => {
    this.setState({ inviteRole: roleOption });
  };

  inviteMembers = () => {
    this.props.onInviteMembers(
      this.props.properties,
      this.state.selectedMembers,
      this.state.inviteRole.value
    );
  };

  removeMember = () => {
    const { property, member } = this.state.remove;
    this.props.onRemoveMember(property, member);
  };

  changeRole = () => {
    const { role, property, member } = this.state.changeRole;
    this.props.onChangeRole(role, property, member);
  };

  render() {
    const { isOpen, onClose } = this.props;
    return (
      <>
        <ModalWindow
          className="invite-modal"
          open={isOpen}
          onClose={onClose}
          onEntered={this.openModal}
        >
          <ModalWindow.Head className="invite-modal__header">
            {this.renderTitle()}
          </ModalWindow.Head>
          <ModalWindow.Body>
            <div className="invite-modal__container invite-modal__container--select">
              <SelectSearch
                className="invite-modal__select-users"
                theme="transparent"
                size="small"
                placeholder="Enter Email..."
                noOptionsMessage={this.noOptionsMessage}
                styles={InviteModal.searchStyle}
                isMulti={true}
                isAsync={false}
                components={InviteModal.selectUsersComponents}
                onInputChange={this.props.onLoadUsers}
                options={this.props.membersSearch}
                isLoading={this.props.isMembersLoading}
                isCreatable={true}
                onChange={this.selectMember}
                selectSearchRef={this.selectSearchRef}
              />
              <Select
                className="invite-modal__select-role"
                theme="default"
                size="small"
                components={InviteModal.selectRoleComponents}
                options={InviteModal.roleOptions}
                value={this.state.inviteRole}
                onChange={this.changeInviteRoleHandler}
              />
            </div>
            <div className="invite-modal__container invite-modal__container--users">
              {this.renderPropertyOrMembers()}
            </div>
            <div className="invite-modal__container">
              {this.renderInviteMessages()}
              <div className="invite-modal__container--button">
                <Button variant="secondary" size="large" onClick={onClose}>
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  size="large"
                  ml="5"
                  disabled={this.inviteIsDisabled}
                  onClick={this.inviteMembers}
                >
                  Send Invite
                </Button>
              </div>
            </div>
          </ModalWindow.Body>
        </ModalWindow>
        {this.props.isRemoveMemberOpen && (
          <MembersConfirmModal
            type={"remove"}
            onCloseConfirm={this.props.onCloseRemove}
            confirm={this.removeMember}
            user={this.state.remove.member.account_name}
            property={this.state.remove.property.name}
          />
        )}
        {this.props.isChangeRoleOpen && (
          <MembersConfirmModal
            type={"changeRole"}
            onCloseConfirm={this.props.onCloseChangeRole}
            confirm={this.changeRole}
            user={this.state.changeRole.member.account_name}
            property={this.state.changeRole.property.name}
            role={this.state.changeRole.role}
          />
        )}
      </>
    );
  }
}
