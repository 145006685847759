const reducer = (state = { flagsLoaded: false }, action) => {
  switch (action.type) {
    case "POSTHOG_FLAGS_LOADED": {
      return { flagsLoaded: true };
    }
    default:
      return state;
  }
};

export default reducer;
