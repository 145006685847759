const initialState = {
  order: [],
  selected: [],
  items: {},
  isLoading: false,
  fetchingMemberProperty: false,
  kpiPropertiesOptions: [],
  propertyKPIsIsLoading: false,
  new_widget_data: [{ data: [], forecast_data: [] }],
  attributions: [],
  fetchingAttributions: true,
  attributionsSaveCompleted: false,
  fetchingWidgetData: false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "DASHBOARD_UPDATE_STORE":
      return {
        ...state,
        ...action.payload
      };
    case "AJAX_GET_DASHBOARD_PROPERTIES_REQUEST": {
      return {
        ...state,
        isLoading: false
      };
    }
    case "AJAX_GET_DASHBOARD_PROPERTIES_SUCCESS": {
      const items = {};
      const order = action.payload.properties.map(p => {
        items[p.property_id] = p;
        return p.property_id;
      });
      return {
        ...state,
        items,
        order,
        isLoading: true
      };
    }
    case "AJAX_GET_DASHBOARD_PROPERTIES_ERROR": {
      return {
        ...initialState,
        isLoading: false
      };
    }
    case "AJAX_GET_PROPERTIES_REQUEST": {
      return {
        ...state,
        propertyKPIsIsLoading: false
      };
    }
    case "AJAX_GET_PROPERTIES_SUCCESS": {
      const { properties, groups_kpis, properties_kpis } = action.payload;
      const items = { ...state.items };
      properties.forEach(p => (items[p.property_id] = p));
      return {
        ...state,
        items,
        kpiPropertiesOptions: properties_kpis,
        kpiGroupsOptions: groups_kpis,
        propertyKPIsIsLoading: true
      };
    }
    case "AJAX_GET_PROPERTIES_FAILURE": {
      return {
        ...state,
        propertyKPIsIsLoading: false
      };
    }
    case "AJAX_POST_PROPERTY_MEMBERS_REQUEST": {
      return {
        ...state,
        fetchingMemberProperty: true
      };
    }
    case "AJAX_GET_ATTRIBUTIONS_REQUEST": {
      return {
        ...state,
        fetchingAttributions: true
      };
    }
    case "AJAX_GET_ATTRIBUTIONS_SUCCESS": {
      return {
        ...state,
        attributions: action.payload,
        fetchingAttributions: false
      };
    }
    case "AJAX_GET_ATTRIBUTIONS_FAILURE": {
      return {
        ...state,
        attributions: [],
        fetchingAttributions: false
      };
    }
    case "PROPERTY_CLEAR_ATTRIBUTIONS": {
      return {
        ...state,
        attributions: []
      };
    }
    case "AJAX_POST_ATTRIBUTIONS_REQUEST": {
      return {
        ...state,
        attributionsSaveCompleted: false
      };
    }
    case "AJAX_POST_ATTRIBUTIONS_SUCCESS": {
      return {
        ...state,
        attributionsSaveCompleted: true
      };
    }
    case "PROPERTY_SET_ATTRIBUTIONS_SAVE": {
      return {
        ...state,
        attributionsSaveCompleted: action.payload
      };
    }
    case "AJAX_GET_WIDGET_DATA_REQUEST":
      return {
        ...state,
        fetchingWidgetData: true
      };
    case "AJAX_GET_WIDGET_DATA_SUCCESS":
      return {
        ...state,
        new_widget_data: [action.payload],
        fetchingWidgetData: false
      };
    case "AJAX_GET_WIDGET_DATA_ERROR":
      return {
        ...state,
        new_widget_data: [],
        fetchingWidgetData: false
      };
    default:
      return state;
  }
};

export default reducer;
