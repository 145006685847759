import cn from "classnames";
import _chunk from "lodash/chunk";
import PropTypes from "prop-types";
import React from "react";

import { Button } from "concrete-ui";
import Input from "../input";
import Panel from "../panel";

import "./add_tag_input.scss";

export default class AddTagInput extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    suggestedTags: PropTypes.object,
    onChange: PropTypes.func,
    onCreateTag: PropTypes.func,
    onBlur: PropTypes.func
  };
  static defaultProps = {
    suggestedTags: {},
    onChange() {},
    onCreateTag() {},
    onBlur() {}
  };
  state = { value: "" };

  inputRef = input => {
    this.input = input;
  };

  componentDidMount() {
    this.input.node.focus();
  }

  onChange = e => {
    const value = e.target.value;
    this.props.onChange(value);
    this.setState({ value });
  };

  onBlur = () => this.props.onBlur(this.state.value);

  createTag = () => this.props.onCreateTag(this.state.value);

  onEnter = e => {
    if (e.key === "Enter") {
      this.createTag();
    }
  };

  renderTagName = word => {
    const { value } = this.state;
    if (!word || !value) {
      return word;
    }
    const valueRe = new RegExp(`(${value})`, "i");
    const parts = word.split(valueRe);
    if (parts.length <= 1) {
      return word;
    }
    return parts.reduce((acc, p, index) => {
      let w;
      if (p.match(valueRe)) {
        w = (
          <span className="add-tag-input__suggestion-tag-name-word" key={index}>
            {p}
          </span>
        );
      } else {
        w = p;
      }
      return [...acc, w];
    });
  };

  renderSuggestedTags = () => {
    const userNames = Object.keys(this.props.suggestedTags);
    if (!userNames.length) {
      return;
    }
    const tagBlocks = [];
    for (let name of userNames) {
      const suggestedTags = this.props.suggestedTags[name];
      const tagsGroups = _chunk(suggestedTags, 3);
      const tags = tagsGroups.map((tagsGroup, index) => {
        const tagsItems = tagsGroup.map(tag => (
          <div
            className="add-tag-input__suggestion-tag"
            onClick={() => this.props.onCreateTag(tag.tag_name)}
            key={tag.tag_id}
          >
            <div className="add-tag-input__suggestion-tag-name">
              {this.renderTagName(tag.tag_name)}
            </div>
            <div className="add-tag-input__suggestion-tag-count">
              {tag.count}
            </div>
          </div>
        ));
        return (
          <div className="add-tag-input__suggestion-tag-group" key={index}>
            {tagsItems}
          </div>
        );
      });
      tagBlocks.push(
        <div className="add-tag-input__suggestion-tag-block" key={name}>
          <div className="add-tag-input__suggestion-label">
            Created by {name}
          </div>
          <div>{tags}</div>
        </div>
      );
    }
    return <div className="add-tag-input__suggestion-tags">{tagBlocks}</div>;
  };

  render() {
    const classes = cn("add-tag-input", this.props.className);
    const text = Object.keys(this.props.suggestedTags).length
      ? "Suggested Tags"
      : "No suggestions found";
    return (
      <div className={classes}>
        <Input
          className="add-tag-input__input"
          placeholder="e.g. Southwestern, 2020 Fund"
          theme="simple"
          onChange={this.onChange}
          onKeyUp={this.onEnter}
          onBlur={this.onBlur}
          value={this.state.value}
          ref={this.inputRef}
        />
        {this.state.value ? (
          <Panel className="add-tag-input__suggestion">
            <div className="add-tag-input__suggestion-content">
              <div className="add-tag-input__suggestion-head">{text}</div>
              {this.renderSuggestedTags()}
            </div>
            <div className="add-tag-input__suggestion-controls">
              <Button variant="primary" size="small" onClick={this.createTag}>
                Create new tag
              </Button>
            </div>
          </Panel>
        ) : null}
      </div>
    );
  }
}
