import React, { useState } from "react";
import PropTypes from "prop-types";
import { Flex, Icon, Item, Text } from "concrete-ui";

import { formatDateString } from "../../utils/formatters";
import { DATETIME_FORMAT } from "../../constants";
import MiniMenu from "../mini_menu";
import ConfirmModal from "../confirm_modal";
import RenderIf from "../render_if";

function AdminPropertiesMiniMenu(props) {
  const {
    onEdit,
    disableDeactivate,
    onDeactivateProperty,
    editButtonLabel,
    includeDeactivateOption,
    data
  } = props;
  const [isModalOpen, setModal] = useState(false);
  const modalText =
    "Are you sure you want to deactivate this property? This can’t be undone. Confirm you want to end data connections and platform access as of the next billing cycle.";
  return (
    <Flex justifyContent="space-between" alignItems="center">
      <Text textSize={2} $fontWeight="regular" $color="text.white.1">
        {data.last_updated
          ? formatDateString(data.last_updated, DATETIME_FORMAT)
          : "\u2014"}
      </Text>
      <MiniMenu>
        <Item size="small" onClick={onEdit}>
          <Icon.Edit size={3} />
          {editButtonLabel}
        </Item>
        <RenderIf condition={includeDeactivateOption}>
          <MiniMenu.Divider />
          <Item
            disabled={disableDeactivate}
            size="small"
            variant="danger"
            onClick={disableDeactivate ? false : () => setModal(true)}
          >
            <Icon.Trash size={3} $color="text.orange.0" />
            Deactivate Property
          </Item>
        </RenderIf>
      </MiniMenu>
      {isModalOpen && (
        <ConfirmModal
          title="Deactivate Property?"
          content={modalText}
          addBackground={true}
          confirmButtonText="Confirm"
          cancelButtonText="Cancel"
          onConfirm={() => {
            onDeactivateProperty(data.public_id);
            setModal(false);
          }}
          onClose={() => setModal(false)}
        />
      )}
    </Flex>
  );
}
AdminPropertiesMiniMenu.propTypes = {
  onEdit: PropTypes.func,
  onDeactivate: PropTypes.func
};
AdminPropertiesMiniMenu.defaultProps = {
  onEdit: () => {},
  onDeactivate: () => {}
};

export default AdminPropertiesMiniMenu;
