const initialState = {
  analyticsProvider: {}
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "AJAX_GET_PROPERTY_ANALYTICS_PROVIDER_SUCCESS":
      let provider = action.payload[0]["fields"]["provider"];
      let identifier = action.payload[0]["fields"]["identifier"];
      return {
        ...state,
        analyticsProvider: {
          provider: provider,
          identifier: identifier
        }
      };
    case "AJAX_GET_PROPERTY_ANALYTICS_PROVIDER_ERROR":
      return {
        ...state,
        error: action.payload
      };
    case "CLEAR_PROPERTY_ANALYTICS_PROVIDER": {
      return { ...state, analyticsProvider: {} };
    }

    default:
      return state;
  }
};

export default reducer;
