import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  Checkbox,
  Flex,
  Link,
  Text,
  TextInput,
  Tooltip,
  Icon
} from "concrete-ui";

import ConfirmModal from "../confirm_modal";
import useValidation from "../../utils/validator_hook";
import Breadcrumbs from "../breadcrumbs";
import {
  CreateForm,
  CreateFormHeader,
  CreateFormSection
} from "../create_form";
import { ErrorItem } from "../message_items";
import pmsValidators from "./validators";
import AdminBreadcrumb from "../admin_breadcrumb";
import {
  concreteErrorVariant,
  EDIT_PMS_MODAL_CONTENT,
  PMS_SERVER_NAME_TOOLTIP_TEXT,
  PMS_URL_TOOLTIP_TEXT,
  ZENDESK_HOW_TO_CONFIGURE_YARDI
} from "../../constants";

export const inputTextWithTooltip = (title, tooltip) => (
  <Box alignItems="center">
    {title}{" "}
    <Tooltip title={tooltip} ml={2} size="small">
      <Icon.Tooltip size={3} />
    </Tooltip>
  </Box>
);

const AdminPMSInstanceForm = ({
  onCancel = v => v,
  onConfirm = v => v,
  initialData = {},
  apiError = false,
  connectionsCount = 0,
  editing = false
}) => {
  const [isModalOpen, setModal] = useState(false);

  const defaultData = {
    name: "",
    url: "",
    db_name: "",
    server_name: "",
    platform: "",
    username: "",
    password: "",
    setup_done: false
  };

  const shouldOpenModal = changed => {
    return (
      connectionsCount > 0 &&
      Object.keys(changed).reduce(
        (cur, key) => cur || (key === "name" ? false : changed[key]),
        false
      )
    );
  };

  const confirmForm = (data, changed) => {
    if (shouldOpenModal(changed)) {
      setModal(true);
    } else {
      onConfirm(data);
    }
  };
  const {
    errors,
    getFormProps,
    getItemProps,
    hasChanges,
    formItems
  } = useValidation(
    pmsValidators(editing),
    {
      ...defaultData,
      ...initialData
    },
    confirmForm
  );

  const confirmModal = () => {
    setModal(false);
    onConfirm(formItems);
  };

  const breadcrumbs = [
    { text: <AdminBreadcrumb />, link: "" },
    { text: "Connections", link: "/admin-connections" },
    { text: "Add PMS Instance", link: "" }
  ];

  const checkboxProps = getItemProps("setup_done");
  const linkStyles = {
    "text-decoration-line": "underline",
    "text-underline-offset": "5px"
  };
  const fieldsContainerProps = { width: "31rem" };

  return (
    <>
      <Box bg="gray.1" width="100%" display="block">
        <Breadcrumbs breadcrumbs={breadcrumbs} />
      </Box>
      <Box width="62.5rem" mx="auto" mb="12rem">
        <CreateForm formProps={getFormProps()}>
          <CreateFormHeader>
            <Text textSize={4} $color="text.white.1" $fontWeight="medium">
              Add PMS Instance
            </Text>
          </CreateFormHeader>
          <CreateFormSection
            number="1"
            titleText="Add PMS Instance Name"
            titleSummary="Add a PMS instance name that’s unique and meaningful to your company."
            fieldsContainerProps={fieldsContainerProps}
          >
            <TextInput
              size="large"
              label="PMS Instance Name"
              {...getItemProps("name")}
              variant={errors.name && concreteErrorVariant}
              message={errors.name}
              flexBasis="80%"
            />
          </CreateFormSection>
          <CreateFormSection
            number="2"
            titleText="Enable Remarkably in PMS"
            titleSummary="Confirm the following task has been completed for successful setup."
            fieldsContainerProps={fieldsContainerProps}
          >
            <Checkbox {...checkboxProps}>
              <Text textSize={2} ml={2} mr={1} $fontWeight="regular">
                I can see and select Remarkably as an interface partner.
              </Text>
              <Link
                href={ZENDESK_HOW_TO_CONFIGURE_YARDI}
                style={linkStyles}
                $color="text.blue.0"
                target="_blank"
              >
                Learn more.
              </Link>
            </Checkbox>
          </CreateFormSection>
          <CreateFormSection
            number="3"
            titleText="Add PMS Instance Details"
            titleSummary="Add PMS instance details required for properties using this instance to connect to PMS data."
            fieldsContainerProps={{
              ...fieldsContainerProps,
              flexDirection: "column"
            }}
          >
            <Flex flexWrap="wrap">
              <Flex flexBasis="100%" mt={3}>
                <TextInput
                  size="large"
                  label={inputTextWithTooltip("PMS URL", PMS_URL_TOOLTIP_TEXT)}
                  {...getItemProps("url")}
                  variant={errors.url && concreteErrorVariant}
                  message={errors.url}
                  flexBasis="calc(50% - 0.5rem)"
                  mr={4}
                />
                <TextInput
                  size="large"
                  label="Database Name"
                  {...getItemProps("db_name")}
                  variant={errors.db_name && concreteErrorVariant}
                  message={errors.db_name}
                  flexBasis="calc(50% - 0.5rem)"
                />
              </Flex>
              <Flex flexBasis="100%" mt={3}>
                <TextInput
                  size="large"
                  label={inputTextWithTooltip(
                    "Server Name",
                    PMS_SERVER_NAME_TOOLTIP_TEXT
                  )}
                  {...getItemProps("server_name")}
                  variant={errors.server_name && concreteErrorVariant}
                  message={errors.server_name}
                  flexBasis="calc(50% - 0.5rem)"
                  mr={4}
                />
                <TextInput
                  size="large"
                  label="Platform"
                  {...getItemProps("platform")}
                  variant={errors.platform && concreteErrorVariant}
                  message={errors.platform}
                  flexBasis="calc(50% - 0.5rem)"
                />
              </Flex>
              <Flex flexBasis="100%" mt={3}>
                <TextInput
                  size="large"
                  label="User Name"
                  {...getItemProps("username")}
                  variant={errors.username && concreteErrorVariant}
                  message={errors.username}
                  flexBasis="calc(50% - 0.5rem)"
                  mr={4}
                />
                <TextInput
                  size="large"
                  type="password"
                  showPasswordOption={true}
                  placeholder={editing && "********"}
                  label="Password"
                  {...getItemProps("password")}
                  variant={errors.password && concreteErrorVariant}
                  message={errors.password}
                  flexBasis="calc(50% - 0.5rem)"
                />
              </Flex>
            </Flex>
            <Flex mt={6} alignItems="center" justifyContent="flex-end">
              {apiError && (
                <ErrorItem message="Oops, something went wrong. Please contact Support." />
              )}
              <Button
                variant="secondary"
                size="large"
                type="button"
                mr={3}
                onClick={onCancel}
                style={{ flex: "0 0 auto" }}
              >
                Cancel
              </Button>
              <Button
                disabled={!hasChanges || !formItems["setup_done"]}
                size="large"
                variant="primary"
                type="submit"
                style={{ flex: "0 0 auto" }}
              >
                Save
              </Button>
            </Flex>
          </CreateFormSection>
        </CreateForm>
      </Box>
      {shouldOpenModal && isModalOpen && (
        <ConfirmModal
          title="Save Changes?"
          content={EDIT_PMS_MODAL_CONTENT(connectionsCount)}
          addBackground
          confirmButtonText="Confirm"
          cancelButtonText="Cancel"
          onConfirm={confirmModal}
          onClose={() => setModal(false)}
        />
      )}
    </>
  );
};

AdminPMSInstanceForm.propTypes = {
  initialData: PropTypes.object,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  apiError: PropTypes.bool,
  connectionsCount: PropTypes.number,
  editing: PropTypes.bool
};

export default AdminPMSInstanceForm;
