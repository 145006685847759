import React from "react";
import PropTypes from "prop-types";
import { Icon, Item } from "concrete-ui";

import MiniMenu from "../mini_menu";

function ExpenseMiniMenu(props) {
  const { onEdit, onCopy, onDelete, disabled } = props;
  return (
    <MiniMenu disabled={disabled}>
      <Item size="small" onClick={onEdit}>
        <Icon.Edit size={3} />
        Edit Expense
      </Item>
      <Item size="small" onClick={onCopy}>
        <Icon.Copy size={3} />
        Duplicate Expense
      </Item>
      <MiniMenu.Divider />
      <Item size="small" variant="danger" onClick={onDelete}>
        <Icon.Trash size={3} $color="text.orange.0" />
        Delete Expense
      </Item>
    </MiniMenu>
  );
}
ExpenseMiniMenu.propTypes = {
  onEdit: PropTypes.func,
  onCopy: PropTypes.func,
  onDelete: PropTypes.func,
  disabled: PropTypes.bool
};
ExpenseMiniMenu.defaultProps = {
  onEdit: () => {},
  onCopy: () => {},
  onDelete: () => {}
};

export default ExpenseMiniMenu;
