import { connect } from "react-redux";
import React from "react";
import { Link } from "react-router-dom";
import { auth } from "../../redux_base/actions";
import EnhancedLink from "../enhanced_link";
import { withRouter } from "react-router-dom";
import { Dropdown, Menu, Item, Icon, Text } from "concrete-ui";

import "./user_menu.scss";

export class UserMenu extends React.PureComponent {
  doLogout = () => {
    this.props.dispatch(auth.logout());
    this.props.history.push({ pathname: "/" });
  };

  dropdownMenu = props => {
    return (
      <Menu {...props} width="9.5rem">
        <EnhancedLink to="/account-settings">
          <Item size="small">Profile & Password</Item>
        </EnhancedLink>
        <Link to="#" onClick={this.doLogout}>
          <Item size="small">
            <Icon.Logout size={3} $color="text.gray.1" /> Log Out
          </Item>
        </Link>
      </Menu>
    );
  };

  render() {
    const { user, ...props } = this.props;
    const { account_name } = user;
    return (
      <Dropdown
        size="small"
        label={<Text style={{ whiteSpace: "nowrap" }}>{account_name}</Text>}
        width="auto"
        maxHeight={6}
        {...props}
      >
        {({ props }) => this.dropdownMenu(props)}
      </Dropdown>
    );
  }
}

const mapState = ({ user }) => {
  return {
    user
  };
};

export default connect(mapState)(withRouter(UserMenu));
