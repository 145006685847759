import React, { Component } from "react";
import logger from "../../utils/logger";
const errorLogger = logger()("error_boundary.js")("error");

export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true });
    errorLogger("Render error in a child component!");
    errorLogger(error);
    errorLogger(info);
  }

  render() {
    if (this.state.hasError) {
      return <p>Fatal Error Rendering this Component!!</p>;
    }
    return this.props.children;
  }
}
