import React, { useState } from "react";
import PropTypes from "prop-types";
import { Flex, Icon, Item, Text } from "concrete-ui";

import { formatDateString } from "../../../utils/formatters";
import {
  DATETIME_FORMAT,
  DELETE_CONNECTION_MODAL_GA_DATA_TEXT,
  DELETE_CONNECTION_MODAL_PMS_DATA_TEXT,
  DELETE_CONNECTION_MODAL_TEXT
} from "../../../constants";
import MiniMenu from "../../mini_menu";
import ConfirmModal from "../../confirm_modal";

const ALLOWED_CONNECTION_STATUSES_FOR_DELETION = [
  "test_failed",
  "testing",
  "test_pending"
];

const DELETE_MODAL_TEXTS_MAP = {
  google_analytics: DELETE_CONNECTION_MODAL_GA_DATA_TEXT,
  yardi: DELETE_CONNECTION_MODAL_PMS_DATA_TEXT
};

const AdminPropertyConnectionsMiniMenu = ({ data, onEdit, onDelete }) => {
  const [isModalOpen, setModal] = useState(false);

  const getModalText = () => {
    if (
      !data.connection_status ||
      ALLOWED_CONNECTION_STATUSES_FOR_DELETION.includes(data.connection_status)
    ) {
      return null;
    } else if (
      data.has_expenses ||
      data.has_matching ||
      data.has_distribution
    ) {
      return DELETE_MODAL_TEXTS_MAP[data.integration_source];
    }
    return DELETE_CONNECTION_MODAL_TEXT;
  };

  return (
    <Flex justifyContent="space-between" alignItems="center">
      <Text textSize={2} $fontWeight="regular" $color="text.white.1">
        {data.last_updated
          ? formatDateString(data.last_updated, DATETIME_FORMAT)
          : "\u2014"}
      </Text>
      <MiniMenu>
        <Item
          size="small"
          onClick={() => onEdit(data.id, data.integration_source)}
        >
          <Icon.Edit size={3} />
          Edit Connection
        </Item>
        <MiniMenu.Divider />
        <Item
          size="small"
          variant="danger"
          onClick={() => {
            !!getModalText()
              ? setModal(true)
              : onDelete(data.id, data.integration_source);
          }}
        >
          <Icon.Trash size={3} $color="text.orange.0" />
          Delete Connection
        </Item>
      </MiniMenu>
      {isModalOpen && (
        <ConfirmModal
          title="Delete Connection?"
          content={getModalText()}
          addBackground={true}
          confirmButtonText="Confirm"
          cancelButtonText="Cancel"
          onConfirm={() => {
            onDelete(data.id, data.integration_source);
            setModal(false);
          }}
          onClose={() => setModal(false)}
        />
      )}
    </Flex>
  );
};

AdminPropertyConnectionsMiniMenu.propTypes = {
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  data: PropTypes.object
};

AdminPropertyConnectionsMiniMenu.defaultProps = {
  onEdit: () => {},
  onDelete: () => {},
  data: {}
};

export default AdminPropertyConnectionsMiniMenu;
