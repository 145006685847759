import React from "react";

import IconBase from "./icon_base";

const IconChevronUp = props => (
  <IconBase viewBox="0 0 24 24" {...props} width="24" height="24">
    <path
      fill="#F5FAF7"
      d="M8.125 15l3.88-3.88 3.88 3.88a.996.996 0 101.41-1.41L12.705 9a.996.996 0 00-1.41 0l-4.59 4.59a.996.996 0 000 1.41c.39.38 1.03.39 1.42 0z"
    ></path>
  </IconBase>
);

export default IconChevronUp;
