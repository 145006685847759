import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";

import { CHART_TYPE, KPI_MATCHER, PROPERTY_CHART_PROPS } from "../../constants";
import { csvDownloadHandler } from "../../utils/widget";
import ErrorBoundary from "../error_boundary";
import { ChartHOC } from "../property_page/widget-utils";
import Narratives from "../narratives";

import { PROPERTY_CHART_TEXT } from "./chart_text";
import "./kpi_graph.scss";

class KPIGraph extends React.Component {
  static propTypes = {
    kpi: PropTypes.string.isRequired,
    startDate: PropTypes.instanceOf(Date).isRequired,
    endDate: PropTypes.instanceOf(Date).isRequired,
    isShowNarration: PropTypes.bool.isRequired,
    onClick: PropTypes.func,
    onChangeState: PropTypes.func
  };

  static defaultProps = {
    onChangeState() {},
    widgetData: []
  };

  render() {
    let {
      kpi,
      name,
      startDate,
      endDate,
      isShowNarration,
      widgetData,
      isFetching
    } = this.props;

    const classes = cn("kpi-graph", {
      "kpi-graph--show-narration": isShowNarration
    });

    const titleTooltip =
      PROPERTY_CHART_TEXT[kpi] && PROPERTY_CHART_TEXT[kpi]["tooltip"];
    return (
      <ErrorBoundary>
        <div className={classes}>
          <div
            className="kpi-graph__block-new"
            style={isShowNarration ? { width: "65%" } : { width: "100%" }}
          >
            {
              <ChartHOC
                decimalPlaces={CHART_TYPE[kpi] === "percentage" ? 2 : 0}
                kpi={KPI_MATCHER[kpi]}
                newWidgetData={widgetData}
                loaderState={isFetching}
                onDownloadHandler={() =>
                  csvDownloadHandler(name)(widgetData[0].data)(
                    kpi,
                    startDate,
                    endDate
                  )
                }
                titleTooltipDescription={titleTooltip}
                dateRange={{ start: startDate, end: endDate }}
                {...PROPERTY_CHART_PROPS[KPI_MATCHER[kpi]]}
              />
            }
          </div>
          <div className="kpi-graph__narration">
            <Narratives
              kpiId={kpi}
              view="property"
              isShowWidget={isShowNarration}
              name={name}
            />
          </div>
        </div>
      </ErrorBoundary>
    );
  }
}

export default KPIGraph;
