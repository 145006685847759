import { Box, Flex, Tooltip, Rankings, Text } from "concrete-ui";
import PropTypes from "prop-types";
import React from "react";
import {
  RANKINGS_BOTTOM_SOURCES_STRINGS,
  RANKINGS_EXPOSURE_TOP_SOURCES_STRINGS,
  RANKINGS_NO_ITEMS_STRING,
  RANKINGS_TOP_SOURCES_STRINGS,
  ZENDESK_RANKINGS_BOTTOM_SOURCES,
  ZENDESK_RANKINGS_TOP_EXPOSURE_SOURCES,
  ZENDESK_RANKINGS_TOP_SOURCES
} from "../../constants";
import _isEmpty from "lodash/isEmpty";

const RankingsTitle = ({ title, tooltipText, tooltipLink }) => (
  <Box alignItems="center">
    <Text textSize={3}>{title}</Text>
    <Tooltip
      title={tooltipText}
      linkText="Learn more."
      link={tooltipLink}
      placement="bottom"
      size="small"
      ml={2}
      tooltipProps={{
        onClick: e => e.stopPropagation()
      }}
      linkProps={{
        target: "_blank"
      }}
    />
  </Box>
);
RankingsTitle.propTypes = {
  title: PropTypes.string.isRequired,
  tooltipText: PropTypes.string.isRequired,
  tooltipLink: PropTypes.string.isRequired
};

class ChannelAnalysisRankings extends React.PureComponent {
  static RankDataType = PropTypes.arrayOf(
    PropTypes.shape({
      rank: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      source: PropTypes.string.isRequired,
      distributed_sources: PropTypes.arrayOf(PropTypes.string),
      config: PropTypes.shape({
        bedrooms: PropTypes.string,
        bathrooms: PropTypes.string
      })
    })
  );

  static RankingsDataType = PropTypes.shape({
    top: ChannelAnalysisRankings.RankDataType,
    bottom: ChannelAnalysisRankings.RankDataType
  });

  static ExposureRankingsDataType = PropTypes.shape({
    items: ChannelAnalysisRankings.RankDataType,
    message: PropTypes.string
  });

  static propTypes = {
    data: ChannelAnalysisRankings.RankingsDataType,
    exposureData: ChannelAnalysisRankings.ExposureRankingsDataType,
    showDistributed: PropTypes.bool,
    loading: PropTypes.bool
  };

  static defaultProps = {
    data: {}
  };

  mapRankings = item => ({
    rank: item.rank,
    label: item.source
  });

  getExposureLabel = item => {
    const configSuffix = ` (${item.config.bedrooms} x ${item.config.bathrooms})`;
    if (
      this.props.showDistributed &&
      item.distributed_sources &&
      item.distributed_sources.length
    ) {
      if (item.distributed_sources.length === 1) {
        return item.distributed_sources[0] + configSuffix;
      }
      const [displayElement, ...tooltipElements] = item.distributed_sources;

      return (
        <Flex alignItems="center">
          {displayElement} + {tooltipElements.length}
          <Tooltip
            title={tooltipElements.join(", ")}
            placement="right"
            size="small"
            ml={2}
            mr={2}
          />
          {configSuffix}
        </Flex>
      );
    }

    return item.source + configSuffix;
  };

  mapExposureRankings = item => ({
    rank: item.rank,
    label: this.getExposureLabel(item)
  });

  render() {
    const { data, loading, exposureData, ...otherProps } = this.props;
    const exposureMessage = !_isEmpty(exposureData?.items)
      ? null
      : RANKINGS_EXPOSURE_TOP_SOURCES_STRINGS.message[exposureData?.message] ??
        RANKINGS_NO_ITEMS_STRING;
    return (
      <Flex mb={6} {...otherProps}>
        <Rankings
          title={
            <RankingsTitle
              title={RANKINGS_TOP_SOURCES_STRINGS["title"]}
              tooltipText={RANKINGS_TOP_SOURCES_STRINGS["tooltip"]}
              tooltipLink={ZENDESK_RANKINGS_TOP_SOURCES}
            />
          }
          items={data["top"] ? data["top"].map(this.mapRankings) : []}
          message={
            !data["top"]?.length ? RANKINGS_TOP_SOURCES_STRINGS.noItems : null
          }
          loading={loading}
          loadingRows={3}
          mr={6}
          height="12.875rem"
        />
        <Rankings
          title={
            <RankingsTitle
              title={RANKINGS_BOTTOM_SOURCES_STRINGS["title"]}
              tooltipText={RANKINGS_BOTTOM_SOURCES_STRINGS["tooltip"]}
              tooltipLink={ZENDESK_RANKINGS_BOTTOM_SOURCES}
            />
          }
          items={data["bottom"] ? data["bottom"].map(this.mapRankings) : []}
          message={
            !data["bottom"]?.length
              ? RANKINGS_BOTTOM_SOURCES_STRINGS.noItems
              : null
          }
          loading={loading}
          loadingRows={3}
          mr={6}
          height="12.875rem"
        />
        <Rankings
          title={
            <RankingsTitle
              title={RANKINGS_EXPOSURE_TOP_SOURCES_STRINGS["title"]}
              tooltipText={RANKINGS_EXPOSURE_TOP_SOURCES_STRINGS["tooltip"]}
              tooltipLink={ZENDESK_RANKINGS_TOP_EXPOSURE_SOURCES}
            />
          }
          items={exposureData?.items.map(this.mapExposureRankings) || []}
          message={exposureMessage}
          loading={loading}
          loadingRows={3}
          height="12.875rem"
        />
      </Flex>
    );
  }
}

export default ChannelAnalysisRankings;
