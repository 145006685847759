import React from "react";
import IconBase from "./icon_base";

const Flash = props => (
  <IconBase viewBox="0 0 10 20" fill="none" {...props}>
    <path
      d="M0.370728 1.67545V10.6754C0.370728 11.2254 0.820728 11.6754 1.37073 11.6754H3.37073V18.8254C3.37073 19.3354 4.04073 19.5154 4.30073 19.0754L9.49073 10.1754C9.88073 9.50545 9.40073 8.67545 8.63073 8.67545H6.37073L8.86073 2.02545C9.11073 1.37545 8.63073 0.675446 7.93073 0.675446H1.37073C0.820728 0.675446 0.370728 1.12545 0.370728 1.67545Z"
      fill="currentColor"
    />
  </IconBase>
);

export default Flash;
