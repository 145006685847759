const initialState = {
  notifications: []
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "NETWORK_FETCH_FAIL": {
      const { errorCode, url } = action.message;
      const notifications = [
        {
          id: Date.now(),
          message: `[${errorCode}]: A request to ${url} failed.`
        },
        ...state.notifications
      ];
      return {
        ...state,
        notifications
      };
    }
    case "NOTIFICATIONS_UPDATE": {
      const notifications = action.notifications;
      return {
        ...state,
        notifications
      };
    }
    default:
      return state;
  }
};

export default reducer;
