import _cloneDeep from "lodash/cloneDeep";
import { combineReducers } from "redux";

import accountSettings from "./account_settings";
import alerts from "./alerts";
import analytics from "./analytics";
import assetManagers from "./asset_managers";
import narratives from "./narratives";
import cacheApiCalls from "./cache_api_calls";
import companyAddress from "./company_address";
import companyModal from "./company_modal";
import dashboard from "./dashboard";
import expenses from "./expenses";
import funds from "./funds";
import geo from "./geo";
import locations from "./locations";
import notifications from "./notifications";
import paginationSettings from "./pagination_settings";
import portfolio from "./portfolio";
import projectReports from "./project_reports";
import properties from "./properties";
import property from "./property";
import propertyGroup from "./property_group";
import propertyManagers from "./property_managers";
import referral_sources from "./referral_sources";
import user from "./user";
import windowsModal from "./windows_modal";
import posthog from "./posthog";
import report from "./report";
import reportBudget from "./report_budget";
import banners from "./banners";
import connections from "./connections";
import resetPassword from "./reset_password";

const FILTER_SEED = {
  propertyGroup: undefined,
  properties: undefined,
  startDate: undefined,
  endDate: undefined
};

const initState = {
  tutorialView: {}
};

const token = (
  state = { refresh: null, access: null, rememberMe: false },
  action
) => {
  let newState = {};
  switch (action.type) {
    case "UPDATE_TOKEN": {
      newState = { ...action.token };
      break;
    }
    case "CLEAR_TOKEN": {
      newState = {};
      break;
    }
    default:
      newState = state;
  }
  return newState;
};

const tutorial = (state = initState, action) => {
  let newState = {};
  switch (action.type) {
    case "TUTORIAL_SET_STATE": {
      newState = { ...state, tutorialView: action.newState };
      break;
    }
    default:
      newState = state;
  }
  return newState;
};

const network = (state = { isFetching: false, errors: [] }, action) => {
  let newState = {};
  switch (action.type) {
    // we might want to add networking.startFetching to src/js/redux_base/reducers/index.js:post
    case "AJAX_POST_CREATE_ALERT_REQUEST":
    case "AJAX_POST_UPDATE_ALERT_REQUEST":
    case "AJAX_POST_DELETE_ALERT_REQUEST":
    case "NETWORK_START_FETCH": {
      newState = { ...state, isFetching: true };
      break;
    }
    case "AJAX_POST_UPDATE_ALERT_ERROR":
    case "NETWORK_STOP_FETCH": {
      newState = { ...state, isFetching: false };
      break;
    }
    case "NETWORK_FETCH_FAIL": {
      newState = { ...state };
      newState.errors.push({ message: action.message, timestamp: new Date() });
      break;
    }
    case "NETWORK_FETCH_SUCCESS": {
      newState = { ...state, isFetching: false };
      break;
    }
    default:
      newState = state;
  }
  return newState;
};

const createPassword = (state = {}, action) => {
  let newState = {};
  switch (action.type) {
    case "CREATE_PASSWORD_SET_STATE": {
      newState = { ...state, ...action.newState };
      break;
    }
    case "CREATE_PASSWORD_REDIRECT": {
      window.location.replace(action.url);
      break;
    }
    default:
      newState = state;
  }
  return newState;
};

const completeAccount = (
  state = {
    companyAddresses: [],
    company_roles: [],
    office_types: [],
    is_completed: false,
    errors: null
  },
  action
) => {
  let newState = {};
  switch (action.type) {
    case "AJAX_GET_COMPLETE_ACCOUNT_SUCCESS": {
      const data = action.newState || action.payload;
      const {
        us_state_list,
        gb_county_list,
        office_countries,
        ...otherData
      } = data;
      newState = { ...state, ...otherData };
      break;
    }
    case "AJAX_POST_COMPLETE_ACCOUNT_SUCCESS": {
      newState = { ...state, ...action.payload };
      break;
    }
    case "AJAX_POST_COMPLETE_ACCOUNT_FORM_ERRORS": {
      newState = { ...state, errors: action.payload };
      break;
    }
    case "COMPLETE_ACCOUNT_CLEAR_ERRORS": {
      newState = { ...state, errors: null };
      break;
    }
    case "COMPLETE_ACCOUNT_REDIRECT": {
      window.location.replace(action.url);
      break;
    }
    default:
      newState = state;
  }
  return newState;
};

const pageMeta = (state = { title: "Remarkably" }, action) => {
  let newState = undefined;

  switch (action.type) {
    case "UPDATE_PAGE_TITLE": {
      newState = { ...state, title: action.title };
    }
    default:
      newState = state;
  }
  return newState;
};

const uiStrings = (
  state = {
    strings: {},
    language: "en_us",
    version: {}
  },
  action
) => {
  let newState = {};
  switch (action.type) {
    case "UI_STRINGS_SET_STATE": {
      newState = _cloneDeep(state);
      const { language, strings, version } = action.data;
      newState.strings[language] = strings;
      newState.version[language] = version;
      break;
    }
    default:
      newState = state;
  }
  return newState;
};

const addressModal = (state = {}, action) => {
  let newState = {};
  switch (action.type) {
    case "ADDRESS_MODAL_SHOW": {
      newState = {
        ...state,
        data: action.data,
        addresses: action.addresses
      };
      break;
    }
    default: {
      newState = { ...state };
    }
  }
  return newState;
};

const sisense = (state = { app: undefined }, action) => {
  let newState = {};

  switch (action.type) {
    case "UPDATE_SISENSE_APP": {
      newState = { ...state, app: action.app };
      break;
    }
    default: {
      newState = { ...state };
    }
  }
  return newState;
};

const filters = (state = { filters: {} }, action) => {
  let newState;
  switch (action.type) {
    case "UPDATE_FILTERS": {
      const filters = { ...state.filters, ...action.filters };
      newState = { ...state, filters };
      break;
    }
    default: {
      newState = { ...state };
    }
  }
  return newState;
};

const appReducer = combineReducers({
  accountSettings,
  addressModal,
  alerts,
  analytics,
  assetManagers,
  narratives,
  cacheApiCalls,
  companyAddress,
  companyModal,
  completeAccount,
  createPassword,
  dashboard,
  expenses,
  filters,
  funds,
  geo,
  locations,
  network,
  notifications,
  posthog,
  pageMeta,
  paginationSettings,
  portfolio,
  projectReports,
  properties,
  property,
  propertyGroup,
  propertyManagers,
  referral_sources,
  report,
  reportBudget,
  sisense,
  token,
  tutorial,
  uiStrings,
  user,
  windowsModal,
  banners,
  connections,
  resetPassword
});

const rootReducer = (state, action) => {
  //clear all data in redux store to initial
  if (action.type === "DESTROY_SESSION") state = {};
  return appReducer(state, action);
};

export default rootReducer;
