import React from "react";
import PageChrome from "../../components/page_chrome";

import { callIfFunction } from "../../utils/misc";
import AuthWrapper from "./auth_wrapper";

const renderWrapper = (child, auth = true, nav = true) => {
  if (auth) {
    if (nav) {
      return (
        <AuthWrapper>
          {() => <PageChrome>{callIfFunction(child)}</PageChrome>}
        </AuthWrapper>
      );
    }
    return <AuthWrapper>{child}</AuthWrapper>;
  }
  return child;
};

export default renderWrapper;
