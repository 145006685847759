const reducer = (state = [], action) => {
  let newState = undefined;

  switch (action.type) {
    case "AJAX_GET_DASHBOARD_PROPERTIES_SUCCESS": {
      newState = action.payload.locations;
      break;
    }
    default: {
      newState = state;
    }
  }
  return newState;
};

export default reducer;
