import React from "react";
import PropTypes from "prop-types";

import DropdownFilters from "../dropdown_filters";

class NwvFilters extends React.PureComponent {
  static propTypes = {
    onApplyFilterHandler: PropTypes.func.isRequired,
    containerProps: PropTypes.object,
    filters: PropTypes.object
  };

  constructor() {
    super();
    this.dropdownFilterCategories = [
      {
        id: "filterBy",
        title: "Filter By",
        options: this.getFilterByOptions()
      },
      {
        id: "sortBy",
        title: "Sort By",
        options: this.getSortByOptions()
      }
    ];
  }
  getFilterByOptions = () => [
    {
      label: "Suggestions Only",
      value: "suggestions"
    }
  ];

  getSortByOptions = () => [
    {
      label: "Highest Volume",
      value: "highest_volume"
    },
    {
      label: "Most Recent",
      value: "most_recent"
    }
  ];

  onApplyFilter = filters => {
    const result = Object.values(filters).reduce((arr, filter) => {
      return arr.concat(filter ? filter.map(item => item.value) : []);
    }, []);
    this.props.onApplyFilterHandler(result);
  };

  dropdownFiltersRef = dropdownFilters => {
    this.dropdownFilters = dropdownFilters;
  };

  render() {
    return (
      <DropdownFilters
        size="large"
        buttonText="Filter and Sort"
        dropdownTitle="Options"
        ref={this.dropdownFiltersRef}
        onApply={this.onApplyFilter}
        sections={this.dropdownFilterCategories}
        containerProps={this.props.containerProps}
      />
    );
  }
}

export default NwvFilters;
