import React, { Component } from "react";
import cn from "classnames";
import PropTypes from "prop-types";

import Container from "../container";
import "./tab_navigator.scss";

export const Tab = ({ label, children }) => (
  <div className="tab-navigator__content">{children}</div>
);

const mapChildren = (selectedIndex, handleNavClick) => (child, index) => {
  return (
    <div
      className={cn("tab-navigator__nav-item", {
        "tab-navigator__nav-item--active": selectedIndex === index
      })}
      key={index}
      onClick={event => {
        return handleNavClick(index, event);
      }}
    >
      {child.props.label}
    </div>
  );
};

export class TabNavigator extends Component {
  static propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
    onChange: PropTypes.func.isRequired,
    selectedIndex: PropTypes.number.isRequired,
    withoutContainer: PropTypes.bool,
    theme: PropTypes.oneOf(["small"])
  };

  static defaultProps = {
    withoutContainer: false
  };

  handleNavClick = (index, event) => {
    const { onChange, selectedIndex } = this.props;
    event.preventDefault();
    if (onChange && index !== selectedIndex) {
      onChange(index);
    }
  };

  renderTabContent = childrenArray => {
    const { selectedIndex, withoutContainer } = this.props;
    if (withoutContainer) {
      return childrenArray[selectedIndex];
    } else {
      return (
        <Container className="tab-navigator__container">
          {childrenArray[selectedIndex]}
        </Container>
      );
    }
  };

  render() {
    const { className, children, selectedIndex, theme } = this.props;
    let childrenArray = Array.isArray(children) ? children : [children];
    childrenArray = childrenArray.filter(v => v != null);
    const classes = cn("tab-navigator", className, {
      [`tab-navigator--${theme}`]: theme
    });
    return (
      <div className={classes}>
        <Container className="tab-navigator__navs">
          {childrenArray.map(mapChildren(selectedIndex, this.handleNavClick))}
        </Container>
        <div className="tab-navigator__divider" />
        {this.renderTabContent(childrenArray)}
      </div>
    );
  }
}

TabNavigator.Tab = Tab;

export default TabNavigator;
