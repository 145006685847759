import cn from "classnames";
import Panel from "../panel";
import Close from "../../icons/close";
import React from "react";
import PropTypes from "prop-types";

import "./tag.scss";

export const Tag = ({ name, tagId, isAdmin, theme, onRemove }) => {
  const classes = cn("tag", {
    "tag--admin": isAdmin,
    [`tag--${theme}`]: theme
  });
  const onRemoveHandler = () => {
    onRemove(tagId);
  };
  return (
    <Panel className={classes}>
      <div className="tag__name">{name}</div>
      {isAdmin ? (
        <div className="tag__remove" onClick={onRemoveHandler}>
          <Close className="tag__remove-icon" />
        </div>
      ) : null}
    </Panel>
  );
};
Tag.propTypes = {
  name: PropTypes.node.isRequired,
  onRemove: PropTypes.func,
  isAdmin: PropTypes.bool,
  theme: PropTypes.oneOf(["", "small"])
};
Tag.defaultProps = {
  isAdmin: false,
  onRemove() {},
  theme: ""
};

export default React.memo(Tag);
