import React from "react";
import { connect } from "react-redux";
import { auth } from "../../redux_base/actions";
import { getCookie } from "../../utils/cookies";

class SessionGate extends React.PureComponent {
  componentDidMount() {
    const { token } = this.props;

    if (!token.rememberMe) {
      window.onload = () => {
        const session = getCookie("rmb-session");
        if (!session && token.access) {
          this.props.dispatch(auth.logout());
        }
      };
    }
  }
  render() {
    return this.props.children;
  }
}

const mapState = ({ token }) => ({ token });

export default connect(mapState)(SessionGate);
