import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import _isNull from "lodash/isNull";
import _isObject from "lodash/isObject";

import CompanyModal from "../../components/company_modal";
import { companyActions } from "../../redux_base/actions";

class CompanyModalContainer extends React.PureComponent {
  static propTypes = {
    onClose: PropTypes.func,
    onSave: PropTypes.func,
    suggestion: PropTypes.object
  };

  static defaultProps = {
    onClose() {}
  };

  state = {
    company: {}
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (_isObject(prevProps.suggestion) && _isNull(this.props.suggestion)) {
      this.onAddNew({ company: this.state.company });
    }
  }

  onClose = () => {
    this.props.onClose();
    this.props.dispatch(companyActions.clearCompanyState());
  };

  onSave = () => values => {
    if (values?.company?.isNew) {
      const companyName = values?.company?.value;
      this.setState({ company: values?.company }, () => {
        this.props.dispatch(companyActions.getRelatedCompany(companyName));
      });
    } else {
      this.props.onSave(values);
    }
  };

  onAddNew = values => {
    this.props.onSave(values);
    this.props.dispatch(companyActions.clearCompanyState());
  };

  onConfirmSuggestion = () => () => {
    const values = {
      company: {
        label: this.props.suggestion.name,
        value: this.props.suggestion.id
      }
    };
    this.props.onSave(values);
    this.props.dispatch(companyActions.clearCompanyState());
  };

  render() {
    return (
      <CompanyModal
        {...this.props}
        onSave={this.onSave}
        suggestion={this.props.suggestion}
        onAddNew={this.onAddNew}
        onConfirmSuggestion={this.onConfirmSuggestion}
        onClose={this.onClose}
      />
    );
  }
}

const mapState = state => ({ ...state.companyModal });

export default connect(mapState)(CompanyModalContainer);
